import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createTextVNode as _createTextVNode, withKeys as _withKeys, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-container" }
const _hoisted_2 = {
  key: 0,
  class: "add-asset-form"
}
const _hoisted_3 = { class: "grid grid-cols-3 gap-y-2.5 gap-x-4" }
const _hoisted_4 = ["value"]
const _hoisted_5 = {
  key: 0,
  class: "selected-results"
}
const _hoisted_6 = ["value"]
const _hoisted_7 = {
  key: 0,
  class: "selected-results"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "col-span-2" }
const _hoisted_10 = { class: "col-span-2" }
const _hoisted_11 = {
  key: 0,
  class: "selected-results"
}
const _hoisted_12 = { class: "flex mt-4 items-center" }
const _hoisted_13 = { class: "flex mr-4" }
const _hoisted_14 = { class: "flex" }
const _hoisted_15 = { class: "col-span-3 grid" }
const _hoisted_16 = { class: "col-span-3" }
const _hoisted_17 = { class: "col-span-3 grid" }
const _hoisted_18 = { class: "label-with-icon" }
const _hoisted_19 = { class: "label-with-icon justify-end" }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { class: "col-span-2 row-span-2 grid" }
const _hoisted_22 = { class: "col-span-3 mt-2" }
const _hoisted_23 = ["value"]
const _hoisted_24 = {
  key: 0,
  class: "selected-results"
}
const _hoisted_25 = { key: 0 }
const _hoisted_26 = ["value"]
const _hoisted_27 = {
  key: 0,
  class: "selected-results"
}
const _hoisted_28 = { key: 0 }
const _hoisted_29 = ["value"]
const _hoisted_30 = {
  key: 0,
  class: "selected-results"
}
const _hoisted_31 = { key: 0 }
const _hoisted_32 = { class: "form-error-toast" }

import { ASSET_ADD_FORM_VALUES } from '@/grapql/assetAddFormValues';
import { useQuery } from '@vue/apollo-composable';
import { computed, ref } from 'vue';
import { XMarkIcon, InformationCircleIcon } from '@heroicons/vue/24/outline';
import { useMultipleSelect, useMultipleSelectForSources } from '@/composables/useMultipleSelect';
import Datepicker from 'vue3-datepicker';
import { NEW_ASSET_FIELDS } from '@/models/newAssetFormFields';
import { useFormFieldsCreationNewAsset } from '@/composables/useFormFieldsCreationNewAsset';
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import FormErrorToast from '@/components/FormErrorToast.vue';
import DropdownSearchableMultiple from '@/components/DropdownSearchableMultiple.vue';
import DropdownSearchableWithFilter from './DropdownSearchableWithFilter.vue';
import FilesUploadFormField from './FilesUploadFormField.vue';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import PartyAddNew from '@/components/PartyAddNew.vue';


const tempLinks = '';
const tempPatentsIds = '';

export default /*@__PURE__*/_defineComponent({
  __name: 'TechnologyAssetsAddNewPatent',
  props: ['errorsFields', 'formIsValid', 'formSubmited', 'errorsDuringMutation'],
  setup(__props) {


const tempNewTag = ref('');
const showTagInput = ref(false);

// Inizialize the form
const { result } = useQuery(ASSET_ADD_FORM_VALUES);

const inizilizedData = computed(() => result?.value ?? null)
const formInizializationValues = computed<{ [key: string]: DropdownSearchableOptionInterface[] }>(() => useFormInizializationNewAsset(inizilizedData.value, NEW_ASSET_FIELDS.PATENT.fieldsToQuery));

// Create Fields for the form
const handleInputChange = (event: Event) => (event.target as HTMLInputElement).value;

const formFieldOptions = {
    fields: NEW_ASSET_FIELDS.PATENT.formFileds,
    base: NEW_ASSET_FIELDS.PATENT.graphqlValue,
    mutation: 'assetCreationModule/setSingleAssetValue'
};
const formFields = useFormFieldsCreationNewAsset(formFieldOptions);
const onEditorReady = (event: Event) => { return event };


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (formInizializationValues.value)
      ? (_openBlock(), _createElementBlock("form", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[70] || (_cache[70] = _createElementVNode("div", { class: "form-separator col-span-3" }, [
              _createElementVNode("p", { class: "form-subtitle" }, "Patent Info")
            ], -1)),
            _createElementVNode("div", {
              class: _normalizeClass({ 'error-form-field': __props.errorsFields.includes('nickname') && __props.formSubmited })
            }, [
              _cache[38] || (_cache[38] = _createElementVNode("label", null, "Nickname *", -1)),
              _withDirectives(_createElementVNode("input", {
                class: "w-full",
                type: "text",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(formFields)['nickname'].value) = $event))
              }, null, 512), [
                [_vModelText, _unref(formFields)['nickname'].value]
              ])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["col-span-2", { 'error-form-field': __props.errorsFields.includes('title') && __props.formSubmited }])
            }, [
              _cache[39] || (_cache[39] = _createElementVNode("label", null, "Title *", -1)),
              _withDirectives(_createElementVNode("input", {
                class: "w-full",
                type: "text",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(formFields)['title'].value) = $event))
              }, null, 512), [
                [_vModelText, _unref(formFields)['title'].value]
              ])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["col-span-3 grid", { 'error-form-field': __props.errorsFields.includes('abstract') && __props.formSubmited }])
            }, [
              _cache[40] || (_cache[40] = _createElementVNode("label", null, "Abstract *", -1)),
              _createVNode(_unref(QuillEditor), {
                content: _unref(formFields)['abstract'].value ? _unref(formFields)['abstract'].value : '<p><br></p>',
                "content-type": "html",
                "onUpdate:content": _cache[2] || (_cache[2] = content => _unref(formFields)['abstract'].value = content),
                onReady: _cache[3] || (_cache[3] = ($event: any) => (onEditorReady($event)))
              }, null, 8, ["content"])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass({ 'error-form-field': __props.errorsFields.includes('patentId') && __props.formSubmited })
            }, [
              _cache[41] || (_cache[41] = _createElementVNode("label", null, "Patent ID *", -1)),
              _withDirectives(_createElementVNode("input", {
                class: "w-full",
                type: "text",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(formFields)['patentId'].value) = $event))
              }, null, 512), [
                [_vModelText, _unref(formFields)['patentId'].value]
              ])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass({ 'error-form-field': __props.errorsFields.includes('status') && __props.formSubmited })
            }, [
              _cache[43] || (_cache[43] = _createElementVNode("label", null, "Status *", -1)),
              _withDirectives(_createElementVNode("select", {
                class: "w-full",
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(formFields)['status'].value) = $event))
              }, [
                _cache[42] || (_cache[42] = _createElementVNode("option", {
                  selected: "",
                  value: null
                }, null, -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formInizializationValues.value['patentStatusesOptions'], (status) => {
                  return (_openBlock(), _createElementBlock("option", {
                    value: Number(status.id),
                    key: status.id
                  }, _toDisplayString(status.name), 9, _hoisted_4))
                }), 128))
              ], 512), [
                [_vModelSelect, _unref(formFields)['status'].value]
              ])
            ], 2),
            _createElementVNode("div", null, [
              _cache[44] || (_cache[44] = _createElementVNode("label", null, [
                _createTextVNode("Patent IDs "),
                _createElementVNode("span", null, "(Press Enter to add an ID)")
              ], -1)),
              _withDirectives(_createElementVNode("input", {
                class: "w-full",
                type: "text",
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((tempPatentsIds) = $event)),
                onKeyup: _cache[7] || (_cache[7] = _withKeys(($event: any) => {_unref(formFields)['patentIds'].value.push(handleInputChange($event)); tempPatentsIds = ''}, ["enter"]))
              }, null, 544), [
                [_vModelText, tempPatentsIds]
              ]),
              (_unref(formFields)['patentIds'].value.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(formFields)['patentIds'].value, (link, index) => {
                      return (_openBlock(), _createElementBlock("span", { key: index }, [
                        _createTextVNode(_toDisplayString(link) + " ", 1),
                        _createVNode(_unref(XMarkIcon), {
                          class: "icon icon-x-small thick inline",
                          onClick: ($event: any) => (_unref(formFields)['patentIds'].value.splice(index, 1))
                        }, null, 8, ["onClick"])
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _cache[46] || (_cache[46] = _createElementVNode("label", null, [
                _createTextVNode("Sources "),
                _createElementVNode("span", null, "(One or more)")
              ], -1)),
              _createElementVNode("select", {
                class: "w-full",
                onInput: _cache[8] || (_cache[8] = ($event: any) => (_unref(formFields)['sources'].value = _unref(useMultipleSelectForSources)(Number(handleInputChange($event)), 'add', _unref(formFields)['sources'].value)))
              }, [
                _cache[45] || (_cache[45] = _createElementVNode("option", {
                  selected: "",
                  disabled: ""
                }, null, -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formInizializationValues.value['sourcesOptions'], (source) => {
                  return (_openBlock(), _createElementBlock("option", {
                    value: source.id,
                    key: source.id
                  }, _toDisplayString(source.name), 9, _hoisted_6))
                }), 128))
              ], 32),
              (_unref(formFields)['sources'].value.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formInizializationValues.value['sourcesOptions'], (sourceOption, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(formFields)['sources'].value, (source, index) => {
                          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                            (source.source == sourceOption.id)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                                  _createTextVNode(_toDisplayString(sourceOption.name) + " ", 1),
                                  _createVNode(_unref(XMarkIcon), {
                                    class: "icon icon-x-small thick inline",
                                    onClick: ($event: any) => (_unref(formFields)['sources'].value = _unref(useMultipleSelectForSources)(source.source, 'remove', _unref(formFields)['sources'].value))
                                  }, null, 8, ["onClick"])
                                ]))
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 128))
                      ], 64))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[47] || (_cache[47] = _createElementVNode("label", null, "Url", -1)),
              _withDirectives(_createElementVNode("input", {
                class: "w-full",
                type: "text",
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(formFields)['url'].value) = $event))
              }, null, 512), [
                [_vModelText, _unref(formFields)['url'].value]
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _cache[48] || (_cache[48] = _createElementVNode("label", null, [
                _createTextVNode("Links "),
                _createElementVNode("span", null, "(Press Enter to add a link)")
              ], -1)),
              _withDirectives(_createElementVNode("input", {
                class: "w-full",
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((tempLinks) = $event)),
                type: "text",
                onKeyup: _cache[11] || (_cache[11] = _withKeys(($event: any) => {_unref(formFields)['links'].value.push(handleInputChange($event)); tempLinks = ''}, ["enter"]))
              }, null, 544), [
                [_vModelText, tempLinks]
              ]),
              (_unref(formFields)['links'].value.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(formFields)['links'].value, (link, index) => {
                      return (_openBlock(), _createElementBlock("span", { key: index }, [
                        _createTextVNode(_toDisplayString(link) + " ", 1),
                        _createVNode(_unref(XMarkIcon), {
                          class: "icon icon-x-small thick inline",
                          onClick: ($event: any) => (_unref(formFields)['links'].value.splice(index, 1))
                        }, null, 8, ["onClick"])
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _withDirectives(_createElementVNode("input", {
                  class: "shrink-0",
                  type: "checkbox",
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_unref(formFields)['isPrepatent'].value) = $event)),
                  "true-value": true,
                  "false-value": null
                }, null, 512), [
                  [_vModelCheckbox, _unref(formFields)['isPrepatent'].value]
                ]),
                _cache[49] || (_cache[49] = _createElementVNode("label", null, "Prepatent", -1))
              ]),
              _createElementVNode("div", _hoisted_14, [
                _withDirectives(_createElementVNode("input", {
                  class: "shrink-0",
                  type: "checkbox",
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_unref(formFields)['isPrepatent'].value) = $event)),
                  "true-value": false,
                  "false-value": null
                }, null, 512), [
                  [_vModelCheckbox, _unref(formFields)['isPrepatent'].value]
                ]),
                _cache[50] || (_cache[50] = _createElementVNode("label", null, "Not Prepatent", -1))
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _cache[51] || (_cache[51] = _createElementVNode("label", null, "Description", -1)),
              _createVNode(_unref(QuillEditor), {
                content: _unref(formFields)['description'].value ? _unref(formFields)['description'].value : '<p><br></p>',
                "content-type": "html",
                "onUpdate:content": _cache[14] || (_cache[14] = content => _unref(formFields)['description'].value = content),
                onReady: _cache[15] || (_cache[15] = ($event: any) => (onEditorReady($event)))
              }, null, 8, ["content"])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _cache[52] || (_cache[52] = _createElementVNode("label", null, [
                    _createTextVNode("Claims "),
                    _createElementVNode("span", null, "(Click on add button to create a list)")
                  ], -1)),
                  _createElementVNode("span", {
                    onClick: _cache[16] || (_cache[16] = ($event: any) => (_unref(formFields)['claims'].value.push(null))),
                    class: "icon-span"
                  }, "+ Add claim")
                ]),
                _createVNode(_unref(QuillEditor), {
                  content: _unref(formFields)['claims'].value[0] ? _unref(formFields)['claims'].value[0] : '<p><br></p>',
                  "content-type": "html",
                  "onUpdate:content": _cache[17] || (_cache[17] = content => _unref(formFields)['claims'].value[0] = content),
                  onReady: _cache[18] || (_cache[18] = ($event: any) => (onEditorReady($event)))
                }, null, 8, ["content"])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(formFields)['claims'].value, (claim, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "col-span-3 mb-2"
                }, [
                  (index != 0)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("span", {
                            onClick: ($event: any) => (_unref(formFields)['claims'].value.splice(index, 1)),
                            class: "icon-span remove"
                          }, "x Remove this claim", 8, _hoisted_20)
                        ]),
                        _createVNode(_unref(QuillEditor), {
                          content: _unref(formFields)['claims'].value[index],
                          "onUpdate:content": ($event: any) => ((_unref(formFields)['claims'].value[index]) = $event),
                          "content-type": "html",
                          onReady: _cache[19] || (_cache[19] = ($event: any) => (onEditorReady($event)))
                        }, null, 8, ["content", "onUpdate:content"])
                      ], 64))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]),
            _cache[71] || (_cache[71] = _createElementVNode("div", { class: "form-separator col-span-3" }, [
              _createElementVNode("p", { class: "form-subtitle" }, "Patent Assessment")
            ], -1)),
            _createElementVNode("div", null, [
              _cache[53] || (_cache[53] = _createElementVNode("label", null, [
                _createTextVNode("Materials "),
                _createElementVNode("span", null, "(One or more)")
              ], -1)),
              (formInizializationValues.value['materialsOptions'])
                ? (_openBlock(), _createBlock(DropdownSearchableMultiple, {
                    key: 0,
                    "options-list": formInizializationValues.value['materialsOptions'],
                    modelValue: _unref(formFields)['materials'].value,
                    "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_unref(formFields)['materials'].value) = $event))
                  }, null, 8, ["options-list", "modelValue"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _cache[54] || (_cache[54] = _createElementVNode("label", null, [
                _createTextVNode("Properties "),
                _createElementVNode("span", null, "(One or more)")
              ], -1)),
              (formInizializationValues.value['propertiesOptions'])
                ? (_openBlock(), _createBlock(DropdownSearchableMultiple, {
                    key: 0,
                    "options-list": formInizializationValues.value['propertiesOptions'],
                    modelValue: _unref(formFields)['properties'].value,
                    "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_unref(formFields)['properties'].value) = $event))
                  }, null, 8, ["options-list", "modelValue"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _cache[55] || (_cache[55] = _createElementVNode("label", null, [
                _createTextVNode("Applications "),
                _createElementVNode("span", null, "(One or more)")
              ], -1)),
              (formInizializationValues.value['applicationTypesOptions'])
                ? (_openBlock(), _createBlock(DropdownSearchableMultiple, {
                    key: 0,
                    "options-list": formInizializationValues.value['applicationTypesOptions'],
                    modelValue: _unref(formFields)['applicationTypes'].value,
                    "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_unref(formFields)['applicationTypes'].value) = $event))
                  }, null, 8, ["options-list", "modelValue"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _cache[56] || (_cache[56] = _createElementVNode("label", null, [
                _createTextVNode("Certifications & standards "),
                _createElementVNode("span", null, "(One or more)")
              ], -1)),
              (formInizializationValues.value['standardsOptions'])
                ? (_openBlock(), _createBlock(DropdownSearchableMultiple, {
                    key: 0,
                    "options-list": formInizializationValues.value['standardsOptions'],
                    modelValue: _unref(formFields)['standards'].value,
                    "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_unref(formFields)['standards'].value) = $event))
                  }, null, 8, ["options-list", "modelValue"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _cache[57] || (_cache[57] = _createElementVNode("label", null, [
                _createTextVNode("Processing Techniques "),
                _createElementVNode("span", null, "(One or more)")
              ], -1)),
              (formInizializationValues.value['processingTechniquesOptions'])
                ? (_openBlock(), _createBlock(DropdownSearchableMultiple, {
                    key: 0,
                    "options-list": formInizializationValues.value['processingTechniquesOptions'],
                    modelValue: _unref(formFields)['processingTechniques'].value,
                    "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_unref(formFields)['processingTechniques'].value) = $event))
                  }, null, 8, ["options-list", "modelValue"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _cache[58] || (_cache[58] = _createElementVNode("label", null, [
                _createTextVNode("TRL "),
                _createElementVNode("span", null, "Number between 1 and 9")
              ], -1)),
              _withDirectives(_createElementVNode("input", {
                class: "w-full",
                type: "number",
                min: "1",
                max: "9",
                "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_unref(formFields)['trl'].value) = $event))
              }, null, 512), [
                [
                  _vModelText,
                  _unref(formFields)['trl'].value,
                  void 0,
                  { number: true }
                ]
              ])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _cache[59] || (_cache[59] = _createElementVNode("label", null, "Notes", -1)),
              _createVNode(_unref(QuillEditor), {
                content: _unref(formFields)['notes'].value ? _unref(formFields)['notes'].value : '<p><br></p>',
                "content-type": "html",
                "onUpdate:content": _cache[26] || (_cache[26] = content => _unref(formFields)['notes'].value = content),
                onReady: _cache[27] || (_cache[27] = ($event: any) => (onEditorReady($event)))
              }, null, 8, ["content"])
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createVNode(FilesUploadFormField, { "asset-type": "patent" })
            ]),
            _cache[72] || (_cache[72] = _createElementVNode("div", { class: "form-separator col-span-3" }, [
              _createElementVNode("p", { class: "form-subtitle" }, "Patent Dates")
            ], -1)),
            _createElementVNode("div", null, [
              _cache[60] || (_cache[60] = _createElementVNode("label", null, "Publication Date", -1)),
              _createVNode(_unref(Datepicker), {
                class: "w-full",
                "upper-limit": new Date(),
                clearable: true,
                "input-format": 'dd-MMM-yyyy',
                modelValue: _unref(formFields)['publicationDate'].value,
                "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_unref(formFields)['publicationDate'].value) = $event))
              }, null, 8, ["upper-limit", "modelValue"])
            ]),
            _createElementVNode("div", null, [
              _cache[61] || (_cache[61] = _createElementVNode("label", null, "Priority Date", -1)),
              _createVNode(_unref(Datepicker), {
                class: "w-full",
                "upper-limit": new Date(),
                clearable: true,
                "input-format": 'dd-MMM-yyyy',
                modelValue: _unref(formFields)['priorityDate'].value,
                "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_unref(formFields)['priorityDate'].value) = $event))
              }, null, 8, ["upper-limit", "modelValue"])
            ]),
            _createElementVNode("div", null, [
              _cache[62] || (_cache[62] = _createElementVNode("label", null, "Expiry Date", -1)),
              _createVNode(_unref(Datepicker), {
                class: "w-full",
                clearable: true,
                "input-format": 'dd-MMM-yyyy',
                modelValue: _unref(formFields)['expiryDate'].value,
                "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_unref(formFields)['expiryDate'].value) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", null, [
              _cache[63] || (_cache[63] = _createElementVNode("label", null, "Granted Date", -1)),
              _createVNode(_unref(Datepicker), {
                class: "w-full",
                "upper-limit": new Date(),
                clearable: true,
                "input-format": 'dd-MMM-yyyy',
                modelValue: _unref(formFields)['grantedDate'].value,
                "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_unref(formFields)['grantedDate'].value) = $event))
              }, null, 8, ["upper-limit", "modelValue"])
            ]),
            _cache[73] || (_cache[73] = _createElementVNode("div", { class: "form-separator col-span-3" }, [
              _createElementVNode("p", { class: "form-subtitle" }, "Patent Participants")
            ], -1)),
            _createElementVNode("div", null, [
              _createVNode(DropdownSearchableWithFilter, {
                modelValue: _unref(formFields)['inventors'].value,
                "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_unref(formFields)['inventors'].value) = $event)),
                "filter-criteria": 'partyType',
                "options-list": formInizializationValues.value['partiesOptions'],
                "select-label": 'Inventors',
                "filter-options": [{ id: 1, name: 'Individual' }, { id: 2, name: 'University' }, { id: 3, name: 'Organization' }],
                "filter-label": 'Filter Inventors by:',
                "two-columns": false,
                "is-multiple": true
              }, null, 8, ["modelValue", "options-list"]),
              _createVNode(PartyAddNew, { "party-type": "inventor" })
            ]),
            _createElementVNode("div", null, [
              _createVNode(DropdownSearchableWithFilter, {
                modelValue: _unref(formFields)['assignees'].value,
                "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_unref(formFields)['assignees'].value) = $event)),
                "filter-criteria": 'partyType',
                "options-list": formInizializationValues.value['partiesOptions'],
                "select-label": 'Assignees',
                "filter-options": [{ id: 1, name: 'Individual' }, { id: 2, name: 'University' }, { id: 3, name: 'Organization' }],
                "filter-label": 'Filter Assignees by:',
                "two-columns": false,
                "is-multiple": true
              }, null, 8, ["modelValue", "options-list"]),
              _createVNode(PartyAddNew, { "party-type": "assignee" })
            ]),
            _createElementVNode("div", null, [
              _createVNode(DropdownSearchableWithFilter, {
                modelValue: _unref(formFields)['owners'].value,
                "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_unref(formFields)['owners'].value) = $event)),
                "filter-criteria": 'partyType',
                "options-list": formInizializationValues.value['partiesOptions'],
                "select-label": 'Owners',
                "filter-options": [{ id: 1, name: 'Individual' }, { id: 2, name: 'University' }, { id: 3, name: 'Organization' }],
                "filter-label": 'Filter Owners by:',
                "two-columns": false,
                "is-multiple": true
              }, null, 8, ["modelValue", "options-list"]),
              _createVNode(PartyAddNew, { "party-type": "owner" })
            ]),
            _cache[74] || (_cache[74] = _createElementVNode("div", { class: "form-separator col-span-3" }, [
              _createElementVNode("p", { class: "form-subtitle" }, "Patent Territories")
            ], -1)),
            _createElementVNode("div", null, [
              _cache[65] || (_cache[65] = _createElementVNode("label", null, [
                _createTextVNode("Pending Territories "),
                _createElementVNode("span", null, "(One or more)")
              ], -1)),
              _createElementVNode("select", {
                class: "w-full",
                onInput: _cache[35] || (_cache[35] = ($event: any) => (_unref(formFields)['pendingTerritories'].value = _unref(useMultipleSelect)(Number(handleInputChange($event)), 'add', _unref(formFields)['pendingTerritories'].value)))
              }, [
                _cache[64] || (_cache[64] = _createElementVNode("option", {
                  disabled: "",
                  selected: ""
                }, null, -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formInizializationValues.value['countriesOptions'], (country) => {
                  return (_openBlock(), _createElementBlock("option", {
                    value: country.id,
                    key: country.id
                  }, _toDisplayString(country.name) + " (" + _toDisplayString(country.code) + ")", 9, _hoisted_23))
                }), 128))
              ], 32),
              (_unref(formFields)['pendingTerritories'].value.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formInizializationValues.value['countriesOptions'], (country, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                        (_unref(formFields)['pendingTerritories'].value.includes(Number(country.id)))
                          ? (_openBlock(), _createElementBlock("span", _hoisted_25, [
                              _createTextVNode(_toDisplayString(country.code) + " ", 1),
                              _createVNode(_unref(XMarkIcon), {
                                class: "icon icon-x-small thick inline",
                                onClick: ($event: any) => (_unref(formFields)['pendingTerritories'].value = _unref(useMultipleSelect)(Number(country.id), 'remove', _unref(formFields)['pendingTerritories'].value))
                              }, null, 8, ["onClick"])
                            ]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _cache[67] || (_cache[67] = _createElementVNode("label", null, [
                _createTextVNode("Granted Territories "),
                _createElementVNode("span", null, "(One or more)")
              ], -1)),
              _createElementVNode("select", {
                class: "w-full",
                onInput: _cache[36] || (_cache[36] = ($event: any) => (_unref(formFields)['grantedTerritories'].value = _unref(useMultipleSelect)(Number(handleInputChange($event)), 'add', _unref(formFields)['grantedTerritories'].value)))
              }, [
                _cache[66] || (_cache[66] = _createElementVNode("option", {
                  disabled: "",
                  selected: ""
                }, null, -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formInizializationValues.value['countriesOptions'], (country) => {
                  return (_openBlock(), _createElementBlock("option", {
                    value: country.id,
                    key: country.id
                  }, _toDisplayString(country.name) + " (" + _toDisplayString(country.code) + ")", 9, _hoisted_26))
                }), 128))
              ], 32),
              (_unref(formFields)['grantedTerritories'].value.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formInizializationValues.value['countriesOptions'], (country, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                        (_unref(formFields)['grantedTerritories'].value.includes(Number(country.id)))
                          ? (_openBlock(), _createElementBlock("span", _hoisted_28, [
                              _createTextVNode(_toDisplayString(country.code) + " ", 1),
                              _createVNode(_unref(XMarkIcon), {
                                class: "icon icon-x-small thick inline",
                                onClick: ($event: any) => (_unref(formFields)['grantedTerritories'].value = _unref(useMultipleSelect)(Number(country.id), 'remove', _unref(formFields)['grantedTerritories'].value))
                              }, null, 8, ["onClick"])
                            ]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _cache[69] || (_cache[69] = _createElementVNode("label", null, [
                _createTextVNode("Expired Territories "),
                _createElementVNode("span", null, "(One or more)")
              ], -1)),
              _createElementVNode("select", {
                class: "w-full",
                onInput: _cache[37] || (_cache[37] = ($event: any) => (_unref(formFields)['expiredTerritories'].value = _unref(useMultipleSelect)(Number(handleInputChange($event)), 'add', _unref(formFields)['expiredTerritories'].value)))
              }, [
                _cache[68] || (_cache[68] = _createElementVNode("option", {
                  disabled: "",
                  selected: ""
                }, null, -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formInizializationValues.value['countriesOptions'], (country) => {
                  return (_openBlock(), _createElementBlock("option", {
                    value: country.id,
                    key: country.id
                  }, _toDisplayString(country.name) + " (" + _toDisplayString(country.code) + ")", 9, _hoisted_29))
                }), 128))
              ], 32),
              (_unref(formFields)['expiredTerritories'].value.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formInizializationValues.value['countriesOptions'], (country, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                        (_unref(formFields)['expiredTerritories'].value.includes(Number(country.id)))
                          ? (_openBlock(), _createElementBlock("span", _hoisted_31, [
                              _createTextVNode(_toDisplayString(country.code) + " ", 1),
                              _createVNode(_unref(XMarkIcon), {
                                class: "icon icon-x-small thick inline",
                                onClick: ($event: any) => (_unref(formFields)['expiredTerritories'].value = _unref(useMultipleSelect)(Number(country.id), 'remove', _unref(formFields)['expiredTerritories'].value))
                              }, null, 8, ["onClick"])
                            ]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_32, [
      _createVNode(FormErrorToast, {
        "is-showing": !!__props.errorsDuringMutation,
        title: "Warning!",
        subtitle: __props.errorsDuringMutation,
        "toast-type": "warning"
      }, null, 8, ["is-showing", "subtitle"]),
      _createVNode(FormErrorToast, {
        "is-showing": __props.formSubmited && !__props.formIsValid,
        title: "The following fields are mandatory:",
        subtitle: __props.errorsFields.join(', ')
      }, null, 8, ["is-showing", "subtitle"])
    ])
  ]))
}
}

})