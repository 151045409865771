import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/icons/check-table.svg'
import _imports_1 from '@/assets/icons/close.svg'


const _hoisted_1 = {
  key: 0,
  class: "flex flex-wrap bg-white text-sm table-container"
}
const _hoisted_2 = { class: "flex items-strech sticky top-0 z-30" }
const _hoisted_3 = { class: "uppercase font-normal" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "p-2 w-72 sticky left-0 z-20 border-r bg-white" }
const _hoisted_6 = { class: "uppercase text-charcoal" }
const _hoisted_7 = { class: "text-xs text-zinc-400 font-light capitalize" }
const _hoisted_8 = {
  key: 0,
  class: "w-56 border-r text-center p-2 text-charcoal flex items-center justify-center z-10"
}
const _hoisted_9 = {
  key: 1,
  class: "w-56 border-r text-center p-2 text-charcoal flex items-center justify-center z-10"
}
const _hoisted_10 = { class: "p-2 w-72 border-r bg-white sticky left-0 z-20" }
const _hoisted_11 = { class: "uppercase text-charcoal" }
const _hoisted_12 = { class: "text-xs text-zinc-400 font-light capitalize" }
const _hoisted_13 = {
  key: 0,
  class: "w-56 border-r text-charcoal text-center p-2 z-10 flex flex-col items-center justify-center"
}
const _hoisted_14 = { class: "text-charcoal font-semibold" }
const _hoisted_15 = ["innerHTML"]
const _hoisted_16 = {
  key: 1,
  class: "w-56 border-r text-center p-2 text-charcoal z-10 flex items-center justify-center"
}
const _hoisted_17 = {
  key: 1,
  class: "wip-page"
}

import { useQuery } from '@vue/apollo-composable';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { ASSET_DATA_FOR_COMPARISON_TABLE } from '@/grapql/assetsPropertiesForComparisonTable';
import { AssetComparisonTableDataInterface, PropertyInterface, QuantitativePropertyInterface, rawAssetDataInterface } from '@/models/assetsComparisonTableInterfaces';
import {useConverterForQuantitativeProperties} from '@/composables/useConverterForQuantitativeProperties';


export default /*@__PURE__*/_defineComponent({
  __name: 'TechnologyAssetsComparisonTable',
  setup(__props) {

const store = useStore();

// Get values from store
const assetsIdToCompareFromStore = computed(() => store.state.assetsModule.assetsToCompare.length > 0 ? store.state.assetsModule.assetsToCompare.map(asset => asset.id) : []);
const propertiesToCompareFromStore = computed(() => store.getters['assetsModule/getComparisonProperties']);
const quantitativePropertiesToCompareFromStore = computed(() => store.getters['assetsModule/getComparisonQuantitativeProperties']);

// Assign values from store to a reactive and modifiable variable
const assetsIdsToCompare = ref(assetsIdToCompareFromStore.value);
const propertiesToCompare = ref<PropertyInterface[]>(propertiesToCompareFromStore.value);
const quantitativePropertiesToCompare = ref<QuantitativePropertyInterface[]>(quantitativePropertiesToCompareFromStore.value);
const assetsToCompare = ref<AssetComparisonTableDataInterface[]>(null);


// Watch for changes in the store 
watch([propertiesToCompareFromStore, quantitativePropertiesToCompareFromStore, assetsIdToCompareFromStore], ([newpropertiesToCompareFromStore, newquantitativePropertiesToCompareFromStore, newassetsIdToCompareFromStore]) => {
    propertiesToCompare.value = newpropertiesToCompareFromStore;
    quantitativePropertiesToCompare.value = newquantitativePropertiesToCompareFromStore;
    if (newassetsIdToCompareFromStore) {
        assetsIdsToCompare.value = newassetsIdToCompareFromStore;
        getAssetsData(); 
    }
})


const useAssetDataToCompareTable = (assetData: rawAssetDataInterface) => {
    const parsedData = ref<AssetComparisonTableDataInterface>({
        id: assetData.id,
        nickname: assetData.nickname,
        title: assetData.title,
        assetProperties: assetData.assetProperties.edges.length > 0 ? assetData.assetProperties.edges.map(edge => edge.node.property) : [],
        assetQuantitativeProperties: assetData.assetQuantitativeProperties.edges.length > 0 ? useConverterForQuantitativeProperties(assetData.assetQuantitativeProperties.edges) : []
    })
    return parsedData.value
}

const getAssetsData = () => {
    const assetsData = ref([]);
    assetsIdsToCompare.value.map( assetId => {
        const { onResult } = useQuery(ASSET_DATA_FOR_COMPARISON_TABLE, {id: assetId});
        onResult(queryResult => {
           if (queryResult.data) {
            const parsedData = useAssetDataToCompareTable(queryResult.data.asset);
            assetsData.value.push(parsedData);
            }
        })
        
    })
    assetsToCompare.value = assetsData.value
}

getAssetsData();   



return (_ctx: any,_cache: any) => {
  return (assetsToCompare.value && (!!propertiesToCompare.value.length || !!quantitativePropertiesToCompare.value.length))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "w-72 bg-grey-light bg-charcoal sticky left-0 z-40 h-20" }, null, -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(assetsToCompare.value, (asset) => {
            return (_openBlock(), _createElementBlock("div", {
              key: asset.id,
              class: "bg-charcoal text-white w-56 border-r p-4 flex flex-col items-center sticky top-0 h-20"
            }, [
              _createElementVNode("p", _hoisted_3, _toDisplayString(asset.nickname), 1),
              _createElementVNode("p", {
                class: "text-xs font-light text-center text-ellipsis overflow-hidden",
                innerHTML: asset.title
              }, null, 8, _hoisted_4)
            ]))
          }), 128))
        ]),
        (!!propertiesToCompare.value.length)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex w-full uppercase text-white bg-danger-light py-1 px-3 text-sm font-medium sticky top-20 left-0 z-30" }, "Properties", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(propertiesToCompare.value, (property) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: property.id,
                  class: "flex divide-y divide-slate-200"
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("p", _hoisted_6, _toDisplayString(property.name), 1),
                    _createElementVNode("p", _hoisted_7, _toDisplayString(property.className) + " / " + _toDisplayString(property.category), 1)
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(assetsToCompare.value, (asset) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: asset.id
                    }, [
                      (asset.assetProperties.some(prop => prop.id == property.id))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[1] || (_cache[1] = [
                            _createElementVNode("img", {
                              class: "w-6",
                              src: _imports_0
                            }, null, -1)
                          ])))
                        : (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[2] || (_cache[2] = [
                            _createElementVNode("img", {
                              class: "w-6",
                              src: _imports_1
                            }, null, -1)
                          ])))
                    ], 64))
                  }), 128))
                ]))
              }), 128))
            ], 64))
          : _createCommentVNode("", true),
        (!!quantitativePropertiesToCompare.value.length)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex w-full uppercase text-white bg-danger-light py-1 px-3 text-sm font-medium sticky top-20 left-0 z-30" }, "Quantitative Properties", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(quantitativePropertiesToCompare.value, (property) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: property.id,
                  class: "flex divide-y divide-slate-200"
                }, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("p", _hoisted_11, _toDisplayString(property.name), 1),
                    _createElementVNode("p", _hoisted_12, _toDisplayString(property.category), 1)
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(assetsToCompare.value, (asset) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: asset.id
                    }, [
                      (asset.assetQuantitativeProperties.some(prop => prop.id == property.id))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                            _createElementVNode("p", _hoisted_14, _toDisplayString(asset.assetQuantitativeProperties.find(prop => prop.id == property.id).value), 1),
                            (asset.assetQuantitativeProperties.find(prop => prop.id == property.id).extraInformation)
                              ? (_openBlock(), _createElementBlock("p", {
                                  key: 0,
                                  innerHTML: asset.assetQuantitativeProperties.find(prop => prop.id == property.id).extraInformation,
                                  class: "text-xs text-zinc-400 font-light capitalize"
                                }, null, 8, _hoisted_15))
                              : _createCommentVNode("", true)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_16, "-"))
                    ], 64))
                  }), 128))
                ]))
              }), 128))
            ], 64))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_17, _cache[5] || (_cache[5] = [
        _createElementVNode("p", null, "comparison table", -1),
        _createElementVNode("p", { class: "wip-text" }, "No assets or criteria selection", -1)
      ])))
}
}

})