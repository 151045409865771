<template>
    <div class="col-span-1">
        <label>Category Type</label>
        <select v-model="target.targetCategoryType" class="w-full">
            <option :value="null"></option>
            <option v-for="category in targetCategoryTypes" :key="category.id" :value="category.id">{{ category.name }}
            </option>
        </select>
    </div>
    <div class="col-span-2">
        <label>Property</label>
        <DropdownSearchableSingleVue  v-model="target.targetProperty" :options-list="properties" />
    </div>
    <div v-if="!onEdit" class="col-span-2"></div>
    <div :class="{'col-span-1': !onEdit, 'col-span-2': onEdit}">
        <label>Creation Date</label>
        <Datepicker class="w-full" :upper-limit="new Date()" :input-format="'dd-MMM-yyyy'" v-model="target.creationDate" />
    </div>
    <div class="col-span-1">
        <label>Value Type</label>
        <select v-model="target.targetValueType" class="w-full mb-3">
            <option v-for="valueType in valueTypes" :key="valueType.id" :value="valueType.id">{{ valueType.description }}
            </option>
        </select>
    </div>
    <!-- VALUES BASED ON VALUE TYPE --> 
    <div class="col-span-1" v-if="target.targetValueType && isValueTypeBoolean(target.targetValueType)">
        <label>Value</label>
        <div class="flex items-center">
            <input type="radio" id="yes" value="true" v-model="target.targetValue1" />
            <label for="yes" class="mt-2 mr-3">Yes</label>
            <input type="radio" id="No" value="false" v-model="target.targetValue1" />
            <label for="No" class="mt-2">No</label>
        </div>
    </div>
    <div v-else class="flex justify-between">
        <div :class="target.targetValueType && isValueTypeBetween(target.targetValueType) ? 'w-1/2' : 'w-full'"
            class="mr-1">
            <label>Value</label>
            <input type="text" v-model="target.targetValue1" class="w-full" />
        </div>
        <div v-if="target.targetValueType && isValueTypeBetween(target.targetValueType)" class="w-1/2">
            <label>Value 2</label>
            <input type="text" v-model="target.targetValue2" class="w-full mb-3" />
        </div>
    </div>
    <div class="col-span-1">
        <div v-if="!(target.targetValueType && isValueTypeBoolean(target.targetValueType))">
            <label>Unit</label>
            <select v-model="target.targetValueUnit" class="w-full">
                <option :value="null"></option>
                <option v-for="unitOption in units" :key="unitOption.id" :value="unitOption.id">{{ unitOption.abbreviation
                }} - {{ unitOption.name }}
                </option>
            </select>
        </div>
    </div>
    <div v-if="onEdit && isUserAllowToSeeExtraInfo" class="col-span-1">
        <label>Current Value</label>
        <input type="text" v-model="target.currentValue" class="w-full" />
    </div>
    <div v-if="targetToEdit" class="col-span-1 mb-12">
        <button class="black-button-with-icon w-full" @click.prevent="$emit('editedTarget', target)">
            <CheckIcon class="icon icon-x-small light thick mr-2" />
            Save this changes
        </button>
    </div>
    <div v-else class="col-span-1">
        <button class="black-button-with-icon" @click.prevent="$emit('newTarget', target)" :disabled="!targetIsComplete">
            <CheckIcon class="icon icon-x-small light thick mr-2" />
            Add this property
        </button>
    </div>
    <div v-if="targetIsComplete" class="col-span-5">
        <div class="disclaimer-target items-center flex mb-7" :class="{'mt-3': !onEdit, '-mt-6': onEdit}">
            <InformationCircleIcon class="icon icon-small thick mr-2" />
            <div>
                <p class="font-medium" v-if="showDisclaimerBetween">Remember to
                    fill both values and the second one should be bigger
                    than the first one and then</p>
                <p> Click the button to add this property to this program version</p>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { ProgramVersionTargetsInterface } from '@/models/programVersionsInterface';
import { computed, ref, defineProps, defineEmits, onMounted } from 'vue';
import { CheckIcon, InformationCircleIcon } from '@heroicons/vue/24/outline';
import Datepicker from 'vue3-datepicker';
import DropdownSearchableSingleVue from '@/components/DropdownSearchableSingle.vue'
import moment from 'moment';
import { useStore } from 'vuex';

export interface ValueTypesInterface {
    id: number,
    name: string,
    symbol: string,
    description: string
}

export interface UnitTypesInterface {
    id: number
    name: string,
    abbreviation: string,
}

const props = defineProps<{
    onEdit?: boolean,
    targetToEdit?: ProgramVersionTargetsInterface,
    units: UnitTypesInterface[],
    properties: DropdownSearchableOptionInterface[],
    valueTypes: ValueTypesInterface[],
    targetCategoryTypes: { id: number, name: string }[],
}>();

defineEmits(['newTarget', 'editedTarget']);

const store = useStore();
const isUserAllowToSeeExtraInfo = computed(() => store.getters['authModule/isUserAllowToSeeExtraInfo'])

const isValueTypeBetween = (targetValueId: number) => props.valueTypes.find(vt => vt.id == targetValueId).name == 'Between';
const isValueTypeBoolean = (targetValueId: number) => props.valueTypes.find(vt => vt.id == targetValueId).name == 'Boolean';

const target = ref({
    id: null,
    creationDate: new Date,
    targetCategoryType: null,
    targetProperty: null,
    targetValueType: null,
    targetValue1: null,
    targetValue2: null,
    targetValueUnit: null,
    currentValue: null, 
})

// Validate Form
const targetIsComplete = computed<boolean>(() => target.value.targetValue1 && target.value.targetCategoryType && target.value.targetProperty && target.value.targetValue1 && target.value.targetValueType);

const showDisclaimerBetween = computed<boolean>(() =>
    target.value.targetValueType && isValueTypeBetween(target.value.targetValueType) && (!target.value.targetValue2 || target.value.targetValue2 < target.value.targetValue1)
);

onMounted(() => {
    if (props.targetToEdit) {
        target.value = {
            id: props.targetToEdit ? props.targetToEdit.id : null,
            creationDate: props.targetToEdit ? moment(props.targetToEdit.creationDate).toDate() : new Date,
            targetCategoryType: props.targetToEdit ? props.targetToEdit.targetCategoryType : null,
            targetProperty: props.targetToEdit ? props.targetToEdit.targetProperty : null,
            targetValueType: props.targetToEdit ? props.targetToEdit.targetValueType : null,
            targetValue1: props.targetToEdit ? props.targetToEdit.targetValue1 : null,
            targetValue2: props.targetToEdit ? props.targetToEdit.targetValue2 : null,
            targetValueUnit: props.targetToEdit ? props.targetToEdit.targetValueUnit : null,
            currentValue: props.targetToEdit ? props.targetToEdit.currentValue : null, 
        }
    }
})

</script>
<style lang="scss" scoped>
input[type=radio] {
    width: 16px;
    height: 16px;
    border-radius: 5px;
    margin-top: 0.5rem;

    &:checked {
        background-image: url("@/assets/icons/black-check-box.svg");
    }
}

.disclaimer-target {
    background-color: $grey-modal;
    padding: 1rem;
}
</style>