import { createStore } from "vuex";
import authModule from "./modules/authModule";
import runGroupModule from "./modules/runGroupModule";
import runModule from "./modules/runModule";
import runCreateModule from "./modules/runCreateModule";
import runSensitivityModule from "./modules/runSensitivityModule";
import runDoubleSensitivityModule from "./modules/runDoubleSensitivityModule";

const teaModules= {
  runGroupModule,
  runModule,
  runCreateModule,
  runSensitivityModule,
  runDoubleSensitivityModule,
};
const teaStore = createStore({
  modules: {authModule,...teaModules},
});


export {teaStore, teaModules};


