import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/icons/checked.png'
import _imports_1 from '@/assets/icons/remove.png'


const _hoisted_1 = {
  key: 0,
  class: "info-box"
}
const _hoisted_2 = { class: "card-title" }
const _hoisted_3 = ["href"]
const _hoisted_4 = {
  key: 1,
  class: "card-content full"
}
const _hoisted_5 = { class: "card-title" }
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "card-title" }
const _hoisted_9 = { class: "card-title" }
const _hoisted_10 = {
  key: 0,
  src: _imports_0,
  alt: "item.title"
}
const _hoisted_11 = {
  key: 1,
  src: _imports_1,
  alt: "item.title"
}
const _hoisted_12 = { class: "card-title" }
const _hoisted_13 = { class: "card-title" }


import { PriceContentType } from '@/models/technologyAssetInterface';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssetDetailPriceContent',
  props: {
    priceContent: {}
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_ctx.priceContent)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.priceContent, (item) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: item.title
          }, [
            (!!item.content)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (item.type && item.type === 'link')
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(["card-content", {'extra-width': item.extraWidth}])
                      }, [
                        _createElementVNode("p", _hoisted_2, _toDisplayString(item.title), 1),
                        (typeof(item.content) == 'string' )
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              class: "url",
                              href: item.content,
                              target: "_blank"
                            }, "External link", 8, _hoisted_3))
                          : _createCommentVNode("", true)
                      ], 2))
                    : (item.type && item.type === 'linkList' && item.content.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createElementVNode("p", _hoisted_5, _toDisplayString(item.title), 1),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.content, (link) => {
                            return (_openBlock(), _createElementBlock(_Fragment, { key: link }, [
                              (link.startsWith('https://'))
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 0,
                                    class: "url linkItem text-ellipsis overflow-hidden",
                                    href: link.toString(),
                                    target: "_blank"
                                  }, _toDisplayString(link), 9, _hoisted_6))
                                : (_openBlock(), _createElementBlock("a", {
                                    key: 1,
                                    class: "url linkItem text-ellipsis overflow-hidden",
                                    href: `https://${link.toString()}`,
                                    target: "_blank"
                                  }, _toDisplayString(link), 9, _hoisted_7))
                            ], 64))
                          }), 128))
                        ]))
                      : (item.type && item.type === 'list' && item.content.length)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 2,
                            class: _normalizeClass(["card-content", {'extra-width': item.extraWidth}])
                          }, [
                            _createElementVNode("p", _hoisted_8, _toDisplayString(item.title), 1),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.content, (itemInList) => {
                              return (_openBlock(), _createElementBlock("p", { key: itemInList }, _toDisplayString(itemInList), 1))
                            }), 128))
                          ], 2))
                        : (item.type && item.type === 'boolean')
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 3,
                              class: _normalizeClass(["card-content", {'extra-width': item.extraWidth}])
                            }, [
                              _createElementVNode("span", _hoisted_9, _toDisplayString(item.title), 1),
                              (item.content)
                                ? (_openBlock(), _createElementBlock("img", _hoisted_10))
                                : (_openBlock(), _createElementBlock("img", _hoisted_11))
                            ], 2))
                          : (item.content === 'RESEARCH PROJECT')
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 4,
                                class: _normalizeClass(["card-content", {'extra-width': item.extraWidth}])
                              }, [
                                _createElementVNode("p", _hoisted_12, _toDisplayString(item.title), 1),
                                _cache[0] || (_cache[0] = _createTextVNode(" External Opportunity "))
                              ], 2))
                            : (_openBlock(), _createElementBlock("div", {
                                key: 5,
                                class: _normalizeClass(["card-content", {'extra-width': item.extraWidth}])
                              }, [
                                _createElementVNode("p", _hoisted_13, _toDisplayString(item.title), 1),
                                _createTextVNode(" " + _toDisplayString(item.content), 1)
                              ], 2))
                ], 64))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})