<template>
  <div class="border p-0 m-4">
    <div @click="toggleNewAnalysis" class="hover:bg-gray-50 p-4 pl-2 pr-2 border-b flex items-center cursor-pointer">
      <ChevronRightIcon class="icon icon-small inline-block mr-2" :class="{ 'rotate-90': !newAnalysisToggled }" />
      NEW SENSITIVITY ANALYSIS
    </div>
    <div :class="{ hidden: newAnalysisToggled }" class="p-4">
      <div>
        <FormErrorToast
          style="margin: 0 0 1rem 0"
          class="block"
          :is-showing="!!calculateError"
          title="Warning!"
          :subtitle="calculateError"
          toast-type="warning"
        />
      </div>
      <div>
        <FormErrorToast
          style="margin: 0 0 1rem 0"
          class="block"
          :is-showing="formSubmited && !formIsValid"
          title="The following fields have validation errors:"
          :subtitle="errorsFields.join(', ')"
        />
      </div>
      <form class="form-container" v-if="formInizializationValues">
        <div class="grid grid-cols-3 gap-y-3 gap-x-4">
          <div class="col-span-1 sm:col-span-3 md:col-span-1">
            <RadioGroup
              v-model="formFields['electricity'].value"
              class="col-span-4 mb-0 mt-1"
              :class="{ 'error-form-field': errorsFields.includes('electricity') && formSubmited }"
            >
              <RadioGroupLabel class="mb-1">Try different price for Electricity ?</RadioGroupLabel>
              <div class="flex w-full items-center justify-stretch">
                <RadioGroupOption as="template" v-for="yesNo in yesNos" :key="yesNo.value" :value="yesNo.key" v-slot="{ active, checked }">
                  <div
                    :class="[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white ']"
                    class="relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"
                  >
                    <div class="flex w-full items-center justify-between">
                      <div class="flex items-center">
                        <div class="text-sm">
                          <RadioGroupLabel as="p" :class="checked ? 'text-white' : 'text-gray-500'" class="font-base">
                            {{ yesNo.value }}
                          </RadioGroupLabel>
                        </div>
                      </div>
                      <div v-show="checked" class="shrink-0 text-white items-right">
                        <svg class="h-5 w-5" viewBox="0 0 24 24" fill="none">
                          <circle cx="12" cy="12" r="12" fill="#fff" fill-opacity="0.2" />
                          <path d="M7 13l3 3 7-7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </RadioGroupOption>
              </div>
            </RadioGroup>
            <div class="col-span-4 border border-gray-100 border-solid p-1 pt-3 pb-1 -mt-3 bg-gray-50">
              <div v-if="fields.electricity" class="w-full" :class="{ 'error-form-field': errorsFields.includes('electricityPrice') && formSubmited }">
                <label>New Electricity Price *</label>
                <input class="w-full" type="number" min="0.00" max="100.00" v-model="formFields['electricityPrice'].value" />
              </div>
              <p class="text-xs text-gray-500 py-2 block">The reference price for electricity used is {{ formInizializationValues["electricity"] }}</p>
            </div>
          </div>
          <div class="col-span-1 sm:col-span-3 md:col-span-1">
            <RadioGroup
              v-model="formFields['water'].value"
              class="col-span-4 mb-0 mt-1"
              :class="{ 'error-form-field': errorsFields.includes('water') && formSubmited }"
            >
              <RadioGroupLabel class="mb-1">Try different price for Water ?</RadioGroupLabel>
              <div class="flex w-full items-center justify-stretch">
                <RadioGroupOption as="template" v-for="yesNo in yesNos" :key="yesNo.value" :value="yesNo.key" v-slot="{ active, checked }">
                  <div
                    :class="[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white ']"
                    class="relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"
                  >
                    <div class="flex w-full items-center justify-between">
                      <div class="flex items-center">
                        <div class="text-sm">
                          <RadioGroupLabel as="p" :class="checked ? 'text-white' : 'text-gray-500'" class="font-base">
                            {{ yesNo.value }}
                          </RadioGroupLabel>
                        </div>
                      </div>
                      <div v-show="checked" class="shrink-0 text-white items-right">
                        <svg class="h-5 w-5" viewBox="0 0 24 24" fill="none">
                          <circle cx="12" cy="12" r="12" fill="#fff" fill-opacity="0.2" />
                          <path d="M7 13l3 3 7-7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </RadioGroupOption>
              </div>
            </RadioGroup>
            <div class="col-span-4 border border-gray-100 border-solid p-1 pt-3 pb-1 -mt-3 bg-gray-50">
              <div v-if="fields.water" class="w-full" :class="{ 'error-form-field': errorsFields.includes('waterPrice') && formSubmited }">
                <label>New Water Price *</label>
                <input class="w-full" type="number" min="0.00" max="100.00" v-model="formFields['waterPrice'].value" />
              </div>
              <p class="text-xs text-gray-500 py-2 block">The reference price for electricity used is {{ formInizializationValues["water"] }}</p>
            </div>
          </div>
          <div class="col-span-1 sm:col-span-3 md:col-span-1">
            <RadioGroup
              v-model="formFields['co2'].value"
              class="col-span-4 mb-0 mt-1"
              :class="{ 'error-form-field': errorsFields.includes('co2') && formSubmited }"
            >
              <RadioGroupLabel class="mb-1">Try different price for CO2 ?</RadioGroupLabel>
              <div class="flex w-full items-center justify-stretch">
                <RadioGroupOption as="template" v-for="yesNo in yesNos" :key="yesNo.value" :value="yesNo.key" v-slot="{ active, checked }">
                  <div
                    :class="[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white ']"
                    class="relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"
                  >
                    <div class="flex w-full items-center justify-between">
                      <div class="flex items-center">
                        <div class="text-sm">
                          <RadioGroupLabel as="p" :class="checked ? 'text-white' : 'text-gray-500'" class="font-base">
                            {{ yesNo.value }}
                          </RadioGroupLabel>
                        </div>
                      </div>
                      <div v-show="checked" class="shrink-0 text-white items-right">
                        <svg class="h-5 w-5" viewBox="0 0 24 24" fill="none">
                          <circle cx="12" cy="12" r="12" fill="#fff" fill-opacity="0.2" />
                          <path d="M7 13l3 3 7-7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </RadioGroupOption>
              </div>
            </RadioGroup>
            <div class="col-span-4 border border-gray-100 border-solid p-1 pt-3 pb-1 -mt-3 bg-gray-50">
              <div v-if="fields.co2" class="w-full" :class="{ 'error-form-field': errorsFields.includes('co2Price') && formSubmited }">
                <label>New CO2 Price *</label>
                <input class="w-full" type="number" min="0.00" max="100.00" v-model="formFields['co2Price'].value" />
              </div>
              <p class="text-xs text-gray-500 py-2 block">The reference price for electricity used is {{ formInizializationValues["co2"] }}</p>
            </div>
          </div>

          <div class="col-span-3 text-center mt-2">
            <div v-if="!isReadyForOutput" class="col-span-4">
              <span v-if="isOutputLoading || !isAtleastOneValueSelected" class="button green-accept cursor-not-allowed opacity-75 hover:shadow-none" disabled>{{
                isOutputLoading ? "Loading Outputs . . ." : "Show Outputs"
              }}</span>
              <span v-else @click="showOutputs" class="button green-accept cursor-pointer">Show Outputs</span>
            </div>
          </div>
          <div v-if="isReadyForOutput && sensitivityOutput" class="col-span-3 text-center mt-2">
            <div class="overflow-hidden">
              <SensitivityChart class="w-full border" :data="sensitivityOutput" />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, defineProps, onMounted } from "vue";
import { useStore } from "vuex";
import { ChevronRightIcon } from "@heroicons/vue/24/solid";
import { RUN_SENSITIVITY_ADD_FORM_VALUES } from "@/tea/graphql/queries/runQueries";
import { useTeaQuery } from "@/tea/composables/apollo/useTeaQuery";
import { NEW_RUN_SENSITIVITY_FIELDS } from "@/tea/models/runSensitivity";
import { useFormFieldsCreationNewRunSensitivity } from "@/tea/composables/runs/useFormFieldsCreationNewRunSensitivity";
import { useFormInizializationNewRunSensitivity } from "@/tea/composables/runs/useFormInizializationNewRunSensitivity";
import { DropdownSearchableOptionInterface } from "@/models/dropdownSearchableMultipleOptionInterface";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import FormErrorToast from "@/components/FormErrorToast.vue";
import SensitivityChart from "@/tea/components/runs/charts/SensitivityChart.vue";

const yesNos = [
  { key: true, value: "Yes" },
  { key: false, value: "No" },
];

const props = defineProps(["runId"]);
const store = useStore();

onMounted(() => {
  store.commit("runSensitivityModule/SET_RUN_ID", props.runId);
});

const newAnalysisToggled = ref(false);
const toggleNewAnalysis = () => {
  newAnalysisToggled.value = !newAnalysisToggled.value;
};

// Inizialize the form
const { result } = useTeaQuery(RUN_SENSITIVITY_ADD_FORM_VALUES, { id: props.runId });

const inizilizedData = computed(() => result?.value ?? null);
const formInizializationValues = computed<{ [key: string]: DropdownSearchableOptionInterface[] }>(() =>
  useFormInizializationNewRunSensitivity(inizilizedData.value, NEW_RUN_SENSITIVITY_FIELDS.fieldsToQuery)
);
const getFormFields = Object.keys(NEW_RUN_SENSITIVITY_FIELDS.formFields).map((key) => {
  const fieldConfig = NEW_RUN_SENSITIVITY_FIELDS.formFields[key];
  return {
    [key]: Array.isArray(fieldConfig.onChangeResetFields) ? fieldConfig.onChangeResetFields : [],
  };
});

const formFieldOptions = {
  fields: getFormFields,
  mutation: "runSensitivityModule/SET_SINGLE_RUN_VALUE",
};

const formFields = useFormFieldsCreationNewRunSensitivity(formFieldOptions);

const create = computed(() => store.getters["runSensitivityModule/create"]);
const fields = computed(() => create.value.fields);
const formSubmited = computed(() => create.value.isFormSubmitted);
const isReadyForOutput = computed(() => create.value.isReadyForOutput);
const isOutputLoading = computed(() => create.value.isOutputLoading);
const calculateError = computed(() => create.value.calculateError);
const errorsDuringMutation = computed(() => create.value.createError);

const sensitivityOutput = computed(() => create.value.sensitivityOutput);

const valuesSubmitted = computed(() => fields.value);

function getMissingFields(fieldName, fieldValue, validation) {
  const missingFields = [];
  // 1. check if required
  if (validation.required === true) {
    if (fieldValue === undefined || fieldValue === null || fieldValue === "") {
      missingFields.push(fieldName);
    }
  }

  // 2. check if validtion has valueValidation property set and aloso field has value, then check if value is truthy
  if (validation.valueValidation && fieldValue !== undefined && fieldValue !== null && fieldValue !== "") {
    const { type, values } = validation.valueValidation;
    if (type === "array") {
      if (!values.includes(fieldValue)) {
        missingFields.push(fieldName);
      }
    } else if (type === "range") {
      if (fieldValue < values[0] || fieldValue > values[1]) {
        missingFields.push(fieldName);
      }
    } else if (type === "equal") {
      if (fieldValue !== values) {
        missingFields.push(fieldName);
      }
    } else if (type === "min") {
      if (fieldValue < values) {
        missingFields.push(fieldName);
      }
    }
  }

  // 3. check dependants
  if (validation.dependants) {
    const { dependants } = validation;
    for (const condition of dependants) {
      let doCheckUp = false;
      const { ifValue, ifValueType, ifValueReference, fields, not, is } = condition;
      // Check if the field associated with the condition is truthy
      //check if there is ifValueType equals to options
      //check if it has not property
      //check if it has is property
      if (ifValueType === "option") {
        if (formInizializationValues.value[ifValueReference]) {
          let selectedItem = formInizializationValues.value[ifValueReference].find((item) => item.name === ifValue);
          if (selectedItem) {
            if (selectedItem.id == fieldValue) {
              doCheckUp = true;
            }
          }
        }
      } else if (fieldValue === ifValue) {
        doCheckUp = true;
      }

      if (not && doCheckUp) {
        for (const notCondition of not) {
          let notIfFieldValue = valuesSubmitted.value[notCondition.ifField];
          if (notCondition.ifValueType === "option") {
            if (formInizializationValues.value[notCondition.ifValueReference]) {
              let selectedItem = formInizializationValues.value[notCondition.ifValueReference].find((item) => item.name === notCondition.ifValue);
              if (selectedItem) {
                if (selectedItem.id === notIfFieldValue) {
                  //do not do checkup because it does not passes the not condition
                  doCheckUp = false;
                }
              }
            }
          } else if (notIfFieldValue === notCondition.ifValue) {
            //do not do checkup because it does not passes the not condition
            doCheckUp = false;
          }
        }
      }

      if (is && doCheckUp) {
        for (const isCondition of is) {
          let isIfFieldValue = valuesSubmitted.value[isCondition.ifField];
          if (isCondition.ifValueType === "option") {
            if (formInizializationValues.value[isCondition.ifValueReference]) {
              let selectedItem = formInizializationValues.value[isCondition.ifValueReference].find((item) => item.name === isCondition.ifValue);
              if (selectedItem) {
                if (selectedItem.id !== isIfFieldValue) {
                  //do not do checkup because it does not passes the not condition
                  doCheckUp = false;
                }
              }
            }
          } else if (isIfFieldValue !== isCondition.ifValue) {
            //do not do checkup because it does not passes the not condition
            doCheckUp = false;
          }
        }
      }

      if (doCheckUp) {
        for (const dependantField of fields) {
          // Check if the field is missing
          const dependantValue = valuesSubmitted.value[dependantField];
          if (dependantValue === undefined || dependantValue === null || dependantValue === "") {
            missingFields.push(dependantField);
          }
        }
      }
    }
  }

  // Add more validation logic here if needed.
  return Array.from(new Set(missingFields)); //Remove duplicates
}
const errorsFields = computed(() => {
  const missingFields = [];
  for (const fieldName of Object.keys(NEW_RUN_SENSITIVITY_FIELDS.formFields)) {
    const validation = NEW_RUN_SENSITIVITY_FIELDS.formFields[fieldName];
    const fieldValue = valuesSubmitted.value[fieldName];
    missingFields.push(...getMissingFields(fieldName, fieldValue, validation));
  }
  return Array.from(new Set(missingFields)); //Remove duplicates
});

const formIsValid = computed(() => {
  return errorsFields.value.length === 0;
});

const isAtleastOneValueSelected = computed(() => {
  return create.value.fields.electricity || create.value.fields.water || create.value.fields.co2;
});

const showOutputs = async () => {
  store.commit("runSensitivityModule/SET_CREATE_MUTATION", { key: "isFormSubmitted", value: true });
  if (formIsValid.value && isAtleastOneValueSelected.value) {
    // get outputs now
    const mutation = NEW_RUN_SENSITIVITY_FIELDS.calculateMutation;
    await store
      .dispatch(mutation)
      .then(() => {
        store.commit("runSensitivityModule/SET_CREATE_MUTATION", { key: "isReadyForOutput", value: true });
        store.commit("runSensitivityModule/SET_CREATE_MUTATION", { key: "canSubmit", value: true });
        store.commit("runSensitivityModule/SET_CREATE_MUTATION", { key: "createError", value: true });
      })
      .catch((error) => {
        console.log(error);
      });
  }
};
</script>
<style scoped lang="scss"></style>
