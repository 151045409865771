<template>
    <div class="page-padding">
        <BenchmarkHeader title="Benchmark" @toogle-edit-on-table="editinOnTable = !editinOnTable" />
        <BenchmarkTable :editOn="editinOnTable"/>
    </div>
</template>
<script setup lang="ts">
import BenchmarkHeader from '@/components/BenchmarkHeader.vue';
import BenchmarkTable from '@/components/BenchmarkTable.vue';
import {ref} from 'vue';

const editinOnTable = ref(false);

</script>