import { useMutation } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { provideApolloClient } from "@vue/apollo-composable";
import apolloClient from "@/utils/apolloClient";
import { POSITION, useToast } from 'vue-toastification';
import { format } from "date-fns";
import { OtherTechnologyAssetType, PatentAssetType, ResearchPaperAssetType } from "@/models/technologyAssetInterface";
import { uploadFiles } from "@/composables/useUploadFiles";
import { OTHER_TECHNOLOGY_CREATE, PATENT_CREATE, RESEARCH_PAPER_CREATE, RESEARCH_PROJECT_CREATE } from "@/grapql/assetCreateMutations";

provideApolloClient(apolloClient);
const toast = useToast()

const getDefaultState = () => { return { 
    asset: {
        patent: {
            nickname: null,
            title: null,
            patentId: null,
            status: null,
            abstract: null,
            patentIds: [],
            isPrepatent: null,
            trl: null,
            properties: [], 
            materials: [], 
            tags: [], 
            applicationTypes: [], 
            url: null, 
            links: [], 
            standards: [],
            processingTechniques: [],
            notes: null,
            description: null, 
            claims: [], 
            sources: [], 
            publicationDate: null,
            priorityDate: null,
            expiryDate: null,
            grantedDate: null,
            inventors: [], 
            assignees: [], 
            owners: [], 
            pendingTerritories: [], 
            grantedTerritories: [], 
            expiredTerritories: [], 
        },
        researchPaper: {
            nickname: null,
            title: null,
            abstract: null, 
            description: null,
            publicationDate: null,
            url: null, 
            links: [],
            sources: [],
            applicationTypes: [],
            trl: null,
            tags: [],
            notes: null,
            materials: [],
            properties: [],
            standards: [],
            processingTechniques: [],
            authors: [],
            journal: null
        },
        researchProject: {
            nickname: null,
            title: null,
            abstract: null, 
            description: null,
            publicationDate: null,
            url: null, 
            links: [],
            sources: [],
            applicationTypes: [],
            trl: null,
            tags: [],
            notes: null,
            materials: [],
            properties: [],
            standards: [],
            processingTechniques: [],
            owner: null
        },
        otherTechnology: {
            nickname: null,
            title: null,
            abstract: null, 
            description: null,
            publicationDate: null,
            url: null, 
            links: [],
            sources: [],
            applicationTypes: [],
            trl: null,
            tags: [],
            notes: null,
            materials: [],
            properties: [],
            standards: [],
            processingTechniques: [],
            owners: [],
            otherTechnologyType: null,
            previouslyProcured: null,
            targetLayers: null,
            formTypes: []
        }
    },
    newAssetUrl: null,
    errorsDuringMutation: {
        patent: null,
        researchPaper: null,
        researchProject: null,
        otherTechnology: null
    },
    filesToUpload: {
        assetType: '',
        files: []
    },
    showFilesUploadLoader: false,
    }
    
}
const state = getDefaultState();

const mutations = { 
    setSingleAssetValue(state, payload:{assetType: string, assetField: string , value: Date | number | string | number[] | null}) {
        state.asset[payload.assetType][payload.assetField] = payload.value;
    },
    resetAssetValues(state) {
        Object.assign(state, getDefaultState());
    },
    setNewAssetUrl(state, newAssetUrl:string) {
        state['newAssetUrl'] = newAssetUrl;
    },
    setErrorOnCreationResponse(state, payload:{type:string, error:string}) {
        if (payload.error.includes('already exists')) {
            state.errorsDuringMutation[payload.type] = 'This asset already exists'
        } else {
            state.errorsDuringMutation[payload.type] = payload.error;
        }
    },
    clearErrorOnSuccessfulCreation(state, payload:{ type:string}){
        state.errorsDuringMutation[payload.type] = null
    },
    onSuccessfulCreation(state, payload:{ type:string, newAssetUrl:string}){
        Object.assign(state, getDefaultState());
        state.errorsDuringMutation[payload.type] = null
        state.newAssetUrl = payload.newAssetUrl;
    },
    setFilesToUpload(state, payload: {assetType: string, files: [File]}) {
        state.filesToUpload = payload;
    },
    toggleShowFilesUploadLoader(state) {
        state.showFilesUploadLoader = !state.showFilesUploadLoader;
    },
    autofillResearchPaper(state,data){
        state.asset.researchPaper.nickname = data.nickname;
        state.asset.researchPaper.title = data.title;
        state.asset.researchPaper.abstract = data.abstract;
        state.asset.researchPaper.journal = data.journal;
        state.asset.researchPaper.doiId = data.doiId;
        if(data.publicationDate){
            state.asset.researchPaper.publicationDate = new Date(data.publicationDate);
        }
        state.asset.researchPaper.authors = data.authors;
        state.asset.researchPaper.description = data.description;

        // set this, so that backend can remove temp data of rpses
        state.asset.researchPaper.tempUuid = data.tempUuid;
    },
    restoreNewOtherTechCreated(state) {
        state.newOtherTechCreated = false;
    }
    
}

const actions = { 

    async patentCreate({state, commit}) {
        return new Promise<void>((resolve, reject) => {
            const { mutate: patentCreate, onDone, onError } = useMutation(PATENT_CREATE, {awaitRefetchQueries:true, refetchQueries: ['getPatents', 'getAllTotals']});
            state.asset['patent'].claims = state.asset['patent'].claims.filter(claim => claim != null);
            const patentInput: PatentAssetType = Object.assign({}, state.asset['patent']);
            Object.entries(patentInput).map(([key, value]) => {
                patentInput[key] = key.includes('Date') && value ? format(value, 'yyyy-MM-dd') : value;
            });
            patentCreate({input: patentInput});
            onDone(async (result) => { 
                if (state.filesToUpload.files.length && state.filesToUpload.assetType == 'patent') {
                    commit('toggleShowFilesUploadLoader');
                    await uploadFiles(state.filesToUpload.files, result.data.patentCreate.patent.id).then(() =>  commit('toggleShowFilesUploadLoader'));                    }
                    toast.success("New patent created", { position: POSITION.BOTTOM_LEFT, timeout: 1524 });
                    await commit('onSuccessfulCreation', {type: 'patent', newAssetUrl: `patent-${result.data.patentCreate.patent.id}`});
                    resolve();
                });
            onError((error) => {
                commit('setErrorOnCreationResponse', {type: 'patent', error: error.message});
                reject();
            })
        })
    }, 

    async researchPaperCreate({state, commit}) {
        return new Promise<void>((resolve, reject) => {
            const { mutate: researchPaperCreate, onDone, onError } = useMutation(RESEARCH_PAPER_CREATE, { awaitRefetchQueries:true, refetchQueries: ['getResearchPapers', 'getAllTotals'] } );
            const researchPaperInput: ResearchPaperAssetType = Object.assign({}, state.asset['researchPaper']);
            Object.entries(researchPaperInput).map(([key, value]) => {
                researchPaperInput[key] = key.includes('Date') && value ? format(value, 'yyyy-MM-dd') : value;
            });
            researchPaperCreate({input: researchPaperInput});
            onDone(async (result) => { 
                if (state.filesToUpload.files.length && state.filesToUpload.assetType == 'researchPaper') {
                    commit('toggleShowFilesUploadLoader');
                    await uploadFiles(state.filesToUpload.files, result.data.researchPaperCreate.researchPaper.id).then(() =>  commit('toggleShowFilesUploadLoader'));
                }
                toast.success("New research paper created", { position: POSITION.BOTTOM_LEFT, timeout: 1524 });
                await commit('onSuccessfulCreation', {type: 'researchPaper', newAssetUrl: `research_paper-${result.data.researchPaperCreate.researchPaper.id}`});
                resolve();
            });
            onError((error) => {
                commit('setErrorOnCreationResponse', {type: 'researchPaper', error: error.message});
                reject();
            })
        })
    },

    async researchProjectCreate({state, commit}) {
        return new Promise<void>((resolve, reject) => {
            const { mutate: researchProjectCreate, onDone, onError } = useMutation(RESEARCH_PROJECT_CREATE, { awaitRefetchQueries:true, refetchQueries: ['getResearchProjects', 'getAllTotals'] } );
            const researchProjectInput: ResearchPaperAssetType = Object.assign({}, state.asset['researchProject']);
            Object.entries(researchProjectInput).map(([key, value]) => {
                researchProjectInput[key] = key.includes('Date') && value ? format(value, 'yyyy-MM-dd') : value;
            });
            researchProjectCreate({input: researchProjectInput});
            onDone(async (result) => { 
                if (state.filesToUpload.files.length && state.filesToUpload.assetType == 'researchProject') {
                    commit('toggleShowFilesUploadLoader');
                    await uploadFiles(state.filesToUpload.files, result.data.researchProjectCreate.researchProject.id).then(() =>  commit('toggleShowFilesUploadLoader'));
                }
                toast.success("New research project created", { position: POSITION.BOTTOM_LEFT, timeout: 1524 });
                await commit('onSuccessfulCreation', {type: 'researchProject', newAssetUrl: `research_project-${result.data.researchProjectCreate.researchProject.id}`});
                resolve();
            });
            onError((error) => {
                commit('setErrorOnCreationResponse', {type: 'researchProject', error: error.message});
                reject();
            })
        })
    },

    async otherTechnologyCreate({state, commit}) {
        return new Promise<void>((resolve, reject) => {
            const { mutate: otherTechnologyCreate, onDone, onError } = useMutation(OTHER_TECHNOLOGY_CREATE, { awaitRefetchQueries:true, refetchQueries: ['getOtherTechnologies', 'getAllTotals', 'getLastNumbersFromQ'] } );
            const otherTechnologyInput: OtherTechnologyAssetType = Object.assign({}, state.asset['otherTechnology']);
            Object.entries(otherTechnologyInput).map(([key, value]) => {
                otherTechnologyInput[key] = key.includes('Date') && value ? format(value, 'yyyy-MM-dd') : value;
            });
            otherTechnologyCreate({input: otherTechnologyInput});
            onDone(async (result) => { 
                if (state.filesToUpload.files.length && state.filesToUpload.assetType == 'otherTechnology') {
                    commit('toggleShowFilesUploadLoader');
                    await uploadFiles(state.filesToUpload.files, result.data.otherTechnologyCreate.otherTechnology.id).then(() =>  commit('toggleShowFilesUploadLoader'));
                }
                toast.success("New other technology type created", { position: POSITION.BOTTOM_LEFT, timeout: 1524 });
                await commit('onSuccessfulCreation', {type: 'otherTechnology', newAssetUrl: `other-${result.data.otherTechnologyCreate.otherTechnology.id}`});
                resolve();
            });
            onError((error) => {
                commit('setErrorOnCreationResponse', {type: 'otherTechnology', error: error.message});
                reject();
            })
        })
    },

    async researchPaperAutofill({state, commit},tempUuid:string) {
        return new Promise<void>((resolve, reject) => {
            const { mutate: researchPaperAutofill, onDone } = useMutation(gql`
                mutation researchPaperAutofill($input: ResearchPaperAutofillMutationInput! ) {
                    researchPaperAutofill (input: $input) {
                        researchPaperAutofill{
                            tempUuid
                            nickname
                            title
                            journal
                            doiId
                            publicationDate
                            authors
                            abstract
                            description
                        }
                    }
                } `, { awaitRefetchQueries:true, refetchQueries: ['getAssets'] } );
            try {
                
                researchPaperAutofill({input: {"tempUuid":tempUuid}});
                onDone(async (result) => { 
                    if (result.data && result.data?.researchPaperAutofill) {
                    commit('autofillResearchPaper',result.data.researchPaperAutofill.researchPaperAutofill)
                    toast.success("Research Paper Information Auto Filled", { position: POSITION.BOTTOM_LEFT, timeout: 1524 });
                    resolve();
                    }else{
                        console.log('data is empty');
                        reject('Data is empty.')
                    }
                })
            } catch(error) {
                console.log(error);
                reject();
            }
        })
    },



}
const getters = {

    getFiles(state) { 
        return state.filesToUpload
    },

    getShowFilesUploadLoader(state) {
        return state.showFilesUploadLoader
    }
}

export default {
    namespaced: true,
    state, 
    mutations,
    getters,
    actions
}
