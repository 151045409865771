import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex items-center justify-center"
}
const _hoisted_2 = { class: "grid grid-flow-col gap-6 p-6" }
const _hoisted_3 = {
  key: 1,
  class: "flex items-center justify-center"
}
const _hoisted_4 = { class: "grid grid-flow-col gap-6 p-6" }

import {ref} from 'vue';
import {ShieldCheckIcon, ClipboardDocumentListIcon, LightBulbIcon } from '@heroicons/vue/24/outline';
import RegulationsBox from "@/components/ai/RegulationsBox.vue";
import ProductBriefBox from "@/components/ai/ProductBriefBox.vue";
import InnovationBox from "@/components/ai/InnovationBox.vue";
import {AI_FORM_TYPE} from "@/models/aiModels";


export default /*@__PURE__*/_defineComponent({
  __name: 'AIFlowBoxes',
  props: {
    showRegulations: { type: Boolean },
    showProductBrief: { type: Boolean },
    showInnovation: { type: Boolean },
    showSmallIcons: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;
const selectedFormType = ref('null');

const handleFormTypeSelection = (value:AI_FORM_TYPE)=>{
    selectedFormType.value = value;
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showSmallIcons)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.showRegulations)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (handleFormTypeSelection(_unref(AI_FORM_TYPE).Regulations))),
                  class: "border text-sm border-gray-200 hover:bg-gray-200 p-2 pb-1 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out cursor-pointer"
                }, [
                  _createVNode(_unref(ShieldCheckIcon), { class: "icon icon-small mb-2 blue inline-block" }),
                  _cache[6] || (_cache[6] = _createTextVNode(" Check Regulations "))
                ]))
              : _createCommentVNode("", true),
            (_ctx.showProductBrief)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (handleFormTypeSelection(_unref(AI_FORM_TYPE).ProductBrief))),
                  class: "border text-sm border-gray-200 hover:bg-gray-200 p-2 pb-1 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out cursor-pointer"
                }, [
                  _createVNode(_unref(ClipboardDocumentListIcon), { class: "icon icon-small mb-2 inline-block" }),
                  _cache[7] || (_cache[7] = _createTextVNode(" Product Recommender "))
                ]))
              : _createCommentVNode("", true),
            (_ctx.showInnovation)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (handleFormTypeSelection(_unref(AI_FORM_TYPE).Innovation))),
                  class: "border text-sm border-gray-200 hover:bg-gray-200 p-2 pb-1 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out cursor-pointer"
                }, [
                  _createVNode(_unref(LightBulbIcon), { class: "icon icon-small mb-2 orange-light inline-block" }),
                  _cache[8] || (_cache[8] = _createTextVNode(" Innovation "))
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.showRegulations)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (handleFormTypeSelection(_unref(AI_FORM_TYPE).Regulations))),
                  class: "border border-gray-200 hover:bg-gray-200 p-6 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out cursor-pointer"
                }, [
                  _createVNode(_unref(ShieldCheckIcon), { class: "icon icon-m mb-2 blue" }),
                  _cache[9] || (_cache[9] = _createElementVNode("p", { class: "text-gray-700" }, "Check Regulations", -1))
                ]))
              : _createCommentVNode("", true),
            (_ctx.showProductBrief)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (handleFormTypeSelection(_unref(AI_FORM_TYPE).ProductBrief))),
                  class: "border border-gray-200 hover:bg-gray-200 p-6 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out cursor-pointer"
                }, [
                  _createVNode(_unref(ClipboardDocumentListIcon), { class: "icon icon-m mb-2" }),
                  _cache[10] || (_cache[10] = _createElementVNode("p", { class: "text-gray-700" }, "Product Recommender", -1))
                ]))
              : _createCommentVNode("", true),
            (_ctx.showInnovation)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (handleFormTypeSelection(_unref(AI_FORM_TYPE).Innovation))),
                  class: "border border-gray-200 hover:bg-gray-200 p-6 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out cursor-pointer"
                }, [
                  _createVNode(_unref(LightBulbIcon), { class: "icon icon-m mb-2 orange-light" }),
                  _cache[11] || (_cache[11] = _createElementVNode("p", { class: "text-gray-700" }, "Innovation", -1))
                ]))
              : _createCommentVNode("", true)
          ])
        ])),
    _createElementVNode("div", null, [
      (selectedFormType.value == _unref(AI_FORM_TYPE).Regulations)
        ? (_openBlock(), _createBlock(RegulationsBox, { key: 0 }))
        : _createCommentVNode("", true),
      (selectedFormType.value == _unref(AI_FORM_TYPE).ProductBrief)
        ? (_openBlock(), _createBlock(ProductBriefBox, { key: 1 }))
        : _createCommentVNode("", true),
      (selectedFormType.value == _unref(AI_FORM_TYPE).Innovation)
        ? (_openBlock(), _createBlock(InnovationBox, { key: 2 }))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})