import gql from "graphql-tag";


export const PROGRAM_VERSION_UPDATE_PARTIAL = gql`
    mutation programVersionUpdatePartial($input: ProgramVersionUpdatePartialMutationInput!) {
        programVersionUpdatePartial (input: $input) {
            programVersion {
                id
            }
        }
    }
`

export const DESIGN_CREATE_WITH_LAYERS = gql`
    mutation designCreateWithLayers($input: DesignCreateWithLayersMutationInput!) {
        designCreateWithLayers (input: $input) {
            design {
                id
            }
        }
    }
`

export const DESIGN_UPDATE_PARTIAL = gql`
    mutation designUpdatePartial($input: DesignUpdatePartialMutationInput!) {
        designUpdatePartial (input: $input) {
            design {
                id
                layers {
                    edges {
                        node {
                            layerOrder
                            id
                        }
                    }
                }
            }
        }
    }
`

export const TARGET_DELETE = gql`
    mutation programVersionTargetDelete($input: ProgramVersionTargetDeleteMutationInput!) {
        programVersionTargetDelete (input: $input) {
            programVersionTarget {
                id
            }
        }
    }
`

export const TEAM_DELETE = gql`
    mutation programVersionTeamDelete($input: ProgramVersionTeamDeleteMutationInput!) {
        programVersionTeamDelete (input: $input) {
            programVersionTeam {
                id
            }
        }
    }
`

export const LAYER_DELETE = gql`
    mutation layerDelete($input: LayerDeleteMutationInput!) {
        layerDelete (input: $input) {
            layer {
                id
            }
        }
    }
`
