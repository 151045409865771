import { NEW_ASSET_FIELDS } from "@/models/newAssetFormFields";
import { ref } from "vue";


export const useFormValidationNewAsset = (formValues: { [x: string]: unknown; }, formType: string) => {
    const valid = ref(false);
    const missingFields = ref([]);
    const mandatoryFields = Object.values(NEW_ASSET_FIELDS).find(naf => naf.graphqlValue === formType).mandatoryFormFields;
    valid.value = mandatoryFields.every(mf => !!formValues[mf]);
    missingFields.value = valid.value ? [] : mandatoryFields.filter(mf => !formValues[mf])
    
    return { valid, missingFields }
}