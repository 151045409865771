
<template>
    <div class="flex items-center pl-4">
        <AdjustmentsVerticalIcon class="icon icon-small" />
        <p>Filter assets where: </p> 
    </div> 
    <div class="filter-rules p-4"> 
        <div v-for="(condition, index) in allConditions" :key="index" class="first-rule items-start flex mb-4">
            <!-- Logical Condition -->
            <select v-if="index !== 0" v-model="condition.logicalOperator" class="w-auto ml-0 mr-2"> 
                <template v-for="logicalOperator in logicalOperators" :key="logicalOperator">
                    <option :value="logicalOperator">{{ logicalOperator }}</option> 
                </template>
            </select>
            <!-- column Value --> 
            <select v-model.lazy="condition.searchableValue" @change="onSearcheableValueChange(index)" class="w-full ml-0"> 
                <option disabled :value="null">Select property</option>
                <template v-for="column in columns" :key="column.name">
                    <option v-if="column.filtrable" :disabled="(allConditions.some((condition) => condition.searchableValue == column)) && (column.hasQuery || column.field == 'assetType')" :value="column">{{ column.label }}</option> 
                </template>
            </select>
            <!-- Lookup operators based on column selection -->
            <select v-if="condition.searchableValue && (condition.searchableValue.hasQuery || condition.searchableValue.field === 'assetType')" v-model="condition.lookupOperatorsForDropDown" class="w-28 mx-2"> 
                <template v-for="lookupOperator in lookupOperatorsForDropDown" :key="lookupOperator.value">
                    <option :value="lookupOperator.value">{{ lookupOperator.label }}</option> 
                </template>
            </select>
            <select v-else v-model="condition.lookupOperator" class="w-28 mx-2"> 
                <template v-for="lookupOperator in lookupOperators" :key="lookupOperator.value">
                    <option  :value="lookupOperator.value">{{ lookupOperator.label }}</option> 
                </template>
            </select>
            <!-- Search value - Free Text or dropdown   --> 
            <div v-if="condition.searchableValue && condition.searchableValue.hasQuery" class="w-full ml-0 min-w-[40%]">
                <DropdownSearchableMultiple 
                    :options-list="optionsValues[`${condition.searchableValue.field}Options`]" 
                    v-model="condition.multipleValuesToSearch"
                    :select-other-than-id="'name'"
                />
            </div>
            <!-- Search value - Free text  --> 
            <template v-else> 
                <input class="w-full min-w-[40%]" type="text" v-model="condition.valueToSearch"  />
            </template>
            <div class="remove-condition ml-2" @click="removeCondition(index)">
                <TrashIcon class="icon icon-x-small thick light"/>
            </div>
        </div>
    </div>
    <div class="flex items-center justify-between p-4 pt-2 pb-2">
        <button class="add-condition" @click="addCondition">
            <PlusCircleIcon class="icon icon-small light thick mr-2" /> Add condition
        </button>
        <button v-if="allConditions.length > 1" class="add-condition" @click="clearAllConditions">
            <XCircleIcon class="icon icon-small light thick mr-2" /> Clear all
        </button>
    </div>
</template>
<script setup lang="ts">
import { AdjustmentsVerticalIcon, PlusCircleIcon, TrashIcon, XCircleIcon } from '@heroicons/vue/24/outline'
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import { ASSETS_FILTER_OPTIONS } from '@/grapql/assetsFilterOptionsQueries';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { ColumnAssetInterface, FilterConditionInterface, LookupOperatorsInterface } from '@/models/technologyAssetsInterface';
import { useQuery } from '@vue/apollo-composable';
import { computed, onMounted, onUpdated } from 'vue';
import { useStore } from 'vuex';
import DropdownSearchableMultiple from './DropdownSearchableMultiple.vue';

const store = useStore();
const columns = computed<ColumnAssetInterface[]>(() => store.getters['assetsModule/getAssetsTableColumns']);
const allConditions = computed<FilterConditionInterface[]>(() => store.state.assetsModule.filterFormConditions);
const logicalOperators = computed<string[]>(() => store.state.assetsModule.logicalOperators);
const lookupOperators = computed<LookupOperatorsInterface[]>(() => store.state.assetsModule.lookupOperators);
const lookupOperatorsForDropDown = computed<LookupOperatorsInterface[]>(() => store.state.assetsModule.lookupOperatorsForDropDown);

// Inizialize the form with options values 
const fieldsToQuery = computed<string[]>(() => columns.value.filter((col:ColumnAssetInterface) => col.hasQuery ).map((col:ColumnAssetInterface) => { return col.field }) );
const { result } = useQuery(ASSETS_FILTER_OPTIONS);
const inizilizedData = computed(() => result?.value ?? null);
const optionsValues = computed<{[key: string]: DropdownSearchableOptionInterface[]}>(() => useFormInizializationNewAsset(inizilizedData.value, fieldsToQuery.value));

const onSearcheableValueChange = (index: number) => {
    allConditions.value[index].valueToSearch = '';
    allConditions.value[index].multipleValuesToSearch = [];
}

const addCondition = () => {
    const newCondition:FilterConditionInterface =  {
        searchableValue: null,
        lookupOperator: lookupOperators.value[0].value,
        valueToSearch: '',
        logicalOperator: logicalOperators.value[0],
        lookupOperatorsForDropDown: lookupOperatorsForDropDown.value[0].value,
        multipleValuesToSearch: []
    }
    allConditions.value.push(newCondition);
}

const removeCondition = (index: number) => allConditions.value.splice(index, 1);
    
const inizializeFilter = () => {
    if (allConditions.value.length == 0) { 
        addCondition();
    } 
}

const clearAllConditions = () => {
    allConditions.value.splice(0,allConditions.value.length);
    inizializeFilter();
}

onMounted(() => {
    inizializeFilter();
})

onUpdated(() => {
    inizializeFilter();
})

</script>
<style lang="scss" scoped>
    .add-condition, .remove-condition {
        display: flex;
        align-items: center;
        border-radius: 7px;
        cursor: pointer;
        background-color: $font-color;
        color: $grey-super-light;
    }
    .add-condition {
        padding: 5px 12px 5px 6px;
        font-size: 0.8rem;
        justify-content: space-between;
    }
    .remove-condition {
        justify-content: center;
        align-items: center;
        padding: 6px;

    }
</style>