import gql from "graphql-tag";

export const ASSESSMENT_HIGH_LEVEL_OPTIONS = gql`
  query getAssessmentHighLevelOptions {
    tags (orderBy: "name") {
      edges {
        node {
          id
          name
        }
      }
    }
    materials {
      edges {
        node {
          id
          name
          acronym
        }
      }
    }
    properties {
      edges {
        node {
          id
          name
          category
          className
        }
      }
    }
    applicationTypes {
      edges {
        node {
          id
          name
        }
      }
    }
    processingTechniques {
      edges {
        node {
          id
          name
        }
      }
    }
    standards {
      edges {
        node {
          id
          code
          title
        }
      }
    }
    assetEvaluationStatuses {
      edges {
        node {
          id
          name
        }
      }
    }
    assetFormTypes {
      edges {
        node {
          id
          name
        }
      }
    }
    layerTypes {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
