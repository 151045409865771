<template>
  <Chart :options="chartOptions"></Chart>
</template>
<script setup>
import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting';
import { Chart } from 'highcharts-vue';
import coloraxisInit from "highcharts/modules/coloraxis";
import moreInit from "highcharts/highcharts-more";

exportingInit(Highcharts);
coloraxisInit(Highcharts);
moreInit(Highcharts);

const chartOptions =  {

chart: {
  type: 'bubble',
  plotBorderWidth: 1,
  zoomType: 'xy',
  style: {
    fontFamily: 'Montserrat'
},
},

legend: {
  enabled: false
},

title: {
  text: '<p style="font-size: 1.1rem; color: rgb(55, 65, 81); text-transform: uppercase; font-weight: 500; fill: rgb(55, 65, 81);">Heat sealing tests</p>'
},

xAxis: {
  gridLineWidth: 1,
  title: {
    text: 'Sample name'
  },
  labels: {
    format: '{value}'
  },
  plotLines: [{
    color: 'black',
    dashStyle: 'dot',
    width: 2,
    value: 65,
    zIndex: 3
  }]
},

yAxis: {
  startOnTick: false,
  endOnTick: false,
  title: {
    text: 'Sealing temperature (°C)'
  },
  labels: {
    format: '{value}'
  },
  maxPadding: 0.2,
  plotLines: [{
    color: 'black',
    dashStyle: 'dot',
    width: 2,
    value: 50,
    zIndex: 3
  }]
},

tooltip: {
  useHTML: true,
  headerFormat: '<table>',
  pointFormat: '<tr><th colspan="2"><h3>PM#{point.name}</h3></th></tr>' +
    '<tr><th>Sealing temperature (°C):</th><td>{point.y}</td></tr>' +
    '<tr><th>Sealing force (N/15mm):</th><td>{point.z}</td></tr>' +
    '<tr><th>Paper content:</th><td>{point.p_txt}</td></tr>' +
    '<tr><th>Recyclable:</th><td>{point.rc}</td></tr>',
  footerFormat: '</table>',
  followPointer: true
},

plotOptions: {
  series: {
   colorKey: 'pc',
   minSize: '1%',
   maxSize: '70%',
   zMin: 0,  
   zMax: 500,
    
  }
},
 colorAxis: [{
      layout: 'horizontal',
      minColor: '#fff',
      maxColor: '#c7c3c3'
  },{
      layout: 'horizontal',
      minColor: '#ffbaba',
      maxColor: '#ff7b7b'
  }, {
      layout: 'horizontal',
      minColor: '#ff5252',
      maxColor: '#ff5252'
  }, {
      layout: 'horizontal',
      minColor: '#ff0000',
      maxColor: '#ff0000'
  }, {
      layout: 'horizontal',
      minColor: '#a70000',
      maxColor: '#a70000'
  }],

series: [
  {
      colorAxis: 0,
      data: [{"x": 207, "y": 140, "z": 8.5, "pc": 0, "p_txt": "NaN", "name": 207, "rc": "No Info"}, {"x": 208, "y": 140, "z": 8.7, "pc": 0, "p_txt": "NaN", "name": 208, "rc": "No Info"}, {"x": 209, "y": 140, "z": 8.8, "pc": 0, "p_txt": "NaN", "name": 209, "rc": "No Info"}]
  },{
      colorAxis: 1,
      data: []
  },{
      colorAxis: 2,
      data: 
      []
  },{
      colorAxis: 3,
      data: [{"x": 125, "y": 130, "z": 3.4, "pc": 89.23564039, "p_txt": "89.2%", "name": 125, "rc": "No Info"}, {"x": 137, "y": 130, "z": 3.2, "pc": 90.61097879, "p_txt": "90.6%", "name": 137, "rc": "No Info"}, {"x": 137, "y": 130, "z": 3.3, "pc": 90.61097879, "p_txt": "90.6%", "name": 137, "rc": "No Info"}, {"x": 211, "y": 140, "z": 4.2, "pc": 85.36289098, "p_txt": "85.4%", "name": 211, "rc": "No Info"}, {"x": 212, "y": 140, "z": 4.4, "pc": 85.36289098, "p_txt": "85.4%", "name": 212, "rc": "No Info"}, {"x": 213, "y": 140, "z": 4.5, "pc": 84.54135338, "p_txt": "84.5%", "name": 213, "rc": "No Info"}, {"x": 214, "y": 140, "z": 4.4, "pc": 84.54135338, "p_txt": "84.5%", "name": 214, "rc": "No Info"}]
  },{
      colorAxis: 4,
      data: [{"x": 210, "y": 140, "z": 3.0, "pc": 97.85379813, "p_txt": "97.9%", "name": 210, "rc": "No Info"}, {"x": 215, "y": 140, "z": 3.1, "pc": 96.42399385, "p_txt": "96.4%", "name": 215, "rc": "No Info"}]
  },
]

}
</script>
<style scoped>

.highcharts-figure,
.highcharts-data-table table {
    min-width: 310px;
    max-width: 800px;
    margin: 1em auto;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}
</style>