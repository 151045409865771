import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue';
import AssetDetailHeader from './AssetDetailHeader.vue';
import AccordionList from '@/components/AccordionList.vue';
import AccordionListEditable from '@/components/AccordionListEditable.vue';
import { useStore } from 'vuex';
import { AssetDetailMainDataType } from '@/models/technologyAssetInterface';
    

export default /*@__PURE__*/_defineComponent({
  __name: 'AssetDetailMainSection',
  props: {
    assetDetailMainData: {}
  },
  setup(__props: any) {

const store = useStore();


const editModeOn = computed(() => store.getters["assetDetailModule/isAssetMainEditable"]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.assetDetailMainData)
      ? (_openBlock(), _createBlock(AssetDetailHeader, {
          key: 0,
          title: _ctx.assetDetailMainData.title,
          "users-activity": _ctx.assetDetailMainData.activity
        }, null, 8, ["title", "users-activity"]))
      : _createCommentVNode("", true),
    (_ctx.assetDetailMainData && _ctx.assetDetailMainData.accordionList)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (!editModeOn.value)
            ? (_openBlock(), _createBlock(AccordionList, {
                key: 0,
                "data-for-accordion-list": _ctx.assetDetailMainData.accordionList
              }, null, 8, ["data-for-accordion-list"]))
            : _createCommentVNode("", true),
          (editModeOn.value)
            ? (_openBlock(), _createBlock(AccordionListEditable, {
                key: 1,
                "data-for-accordion-list": _ctx.assetDetailMainData.accordionList
              }, null, 8, ["data-for-accordion-list"]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}
}

})