import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex justify-center px-20" }
const _hoisted_2 = {
  key: 2,
  class: "border border-gray-200 shadow-md rounded p-4 mb-4 w-full"
}
const _hoisted_3 = { class: "mb-2 mt-5" }
const _hoisted_4 = { class: "flex" }

import { FaceSmileIcon, PencilSquareIcon, PlayIcon  } from '@heroicons/vue/24/outline';
import { computed,ref } from 'vue';
import { useStore } from 'vuex';
import { REGULATIONS_MODIFICATION_FORM_FIELDS } from '@/models/aiModels';
import { isNullOrEmptyArray } from "@/utils/utils";



export default /*@__PURE__*/_defineComponent({
  __name: 'RegulationsConfirm',
  setup(__props) {

const store = useStore();

const handleConfirm = async ()=>{
    await store.dispatch("aiModule/askRegulationsConfirm");
}

const showChangeForm = ref(false);
const handleChange = ()=>{
    showChangeForm.value = true;
}


// Create Fields for the form
const fieldsFromConstant = computed<string[]>(() => REGULATIONS_MODIFICATION_FORM_FIELDS.formFields.map(formField =>  { return formField.mutationValue} ));
const formFieldOptions = {
    fields: fieldsFromConstant.value,
    base: REGULATIONS_MODIFICATION_FORM_FIELDS.graphqlValue,
    mutation: 'aiModule/setRegulationsModifySingleValue',
};
const formFields = (formFieldOptions:{fields:string[], base:string, mutation:string}) => {
    const object = {};
    formFieldOptions.fields.map(fieldName => {
            object[fieldName] = computed({
                get() {
                    return store.state.aiModule.ask.regulationsModify[fieldName];
                },
                set(value) {
                    store.commit(formFieldOptions.mutation, { field: fieldName , value: value});
                }
            })
    });
    return object;
}
const vModels = formFields(formFieldOptions);


const formSubmited = ref(false);
const valuesSubmitted = computed(() => store.state.aiModule.ask.regulationsModify);
const formIsValid = computed(() => REGULATIONS_MODIFICATION_FORM_FIELDS.mandatoryFormFields.every(mf => {
    return !isNullOrEmptyArray(valuesSubmitted.value[mf]);
}));
const errorFields = computed(() => REGULATIONS_MODIFICATION_FORM_FIELDS.mandatoryFormFields.filter(mf => {
    return isNullOrEmptyArray(valuesSubmitted.value[mf]);
}) );


const handleAsk = async () => {
    formSubmited.value = true;
    if(formIsValid.value){
        formSubmited.value=false;
        await store.dispatch("aiModule/askRegulationsModify");
    }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!showChangeForm.value)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "button cancel mx-4 active:shadow-inner",
          onClick: handleConfirm
        }, [
          _createVNode(_unref(FaceSmileIcon), { class: "icon icon-small inline-block" }),
          _cache[1] || (_cache[1] = _createTextVNode(" continue with response "))
        ]))
      : _createCommentVNode("", true),
    (!showChangeForm.value)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "button cancel mx-4 active:shadow-inner",
          onClick: handleChange
        }, [
          _createVNode(_unref(PencilSquareIcon), { class: "icon icon-small inline-block" }),
          _cache[2] || (_cache[2] = _createTextVNode(" change some variables "))
        ]))
      : _createCommentVNode("", true),
    (showChangeForm.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(["mb-2", { 'error-form-field': errorFields.value.includes('paperContentPercentage') && formSubmited.value }])
          }, [
            _cache[3] || (_cache[3] = _createElementVNode("label", { class: "block" }, "Paper Content %", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "number",
              class: "w-full",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(vModels)['paperContentPercentage'].value) = $event)),
              min: "0",
              max: "100"
            }, null, 512), [
              [_vModelText, _unref(vModels)['paperContentPercentage'].value]
            ])
          ], 2),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", {
                onClick: handleAsk,
                class: "ml-auto py-2 px-5 rounded-none hover:shadow-md active:shadow-inner button cancel",
                type: "button"
              }, [
                _createVNode(_unref(PlayIcon), { class: "icon icon-small" })
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})