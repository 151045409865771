<template>
    <div class="program-detail-card mt-4 mr-4">
        <div class="flex justify-between items-center mb-3">
            <p class="cards-title">system design</p>
            <button v-if="alternativeDesigns.length > 1" @click="isOpen = !isOpen" class="button">generate alternatives</button>
        </div>
        <div class="layer-design">
            <div class="desc-column">
                <div v-for="layer in layersInfo.layers" :key="layer.id"  class="layers-desc">
                    {{ layer.description }}
                </div>
            </div>
            <LayersDesignGenerator :is-mockup="true" :layersData="alternativeDesigns[0].layersData" class="layers-image" />
            <div class="text-column">
                <div v-for="layer in layersInfo.layers" :key="layer.id"  class="layers-info">
                    <p class="name">{{ layer.name }}</p>
                    <p class="grammage">{{  layer.grammage }}</p>
                </div>
            </div>
        </div>
    </div>
    <ProgramPrototypeDesignModal :isOpen="isOpen" :alternative-designs="alternativeDesigns" :targets="programTargets" @close-modal="isOpen = false"/>
</template>
<script setup lang="ts">
import { defineProps, PropType, ref } from 'vue';
import ProgramPrototypeDesignModal from './ProgramPrototypeDesignModal.vue';
import { layersInfoInterface, programTargetInterface, prototypesDesignsInfoInterface } from '@/models/programsInterface';
import LayersDesignGenerator from './LayersDesignGenerator.vue';

defineProps({
    layersInfo: { type: Object as PropType<layersInfoInterface>},
    programTargets:  { type: Array as PropType<programTargetInterface[]>},
    alternativeDesigns: { type: Array as PropType<prototypesDesignsInfoInterface[]>},
})

const isOpen = ref(false);

</script>
<style lang="scss" scoped>
    .layers-image {
        max-width: 260px;
        display: inline-flex;
    }
    .layer-design {
        display: flex;
        align-items: stretch;
    }
    .layers-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .grammage {
            color: #7db5b6;
            font-weight: 500;
            text-align: right;
        }
        .name {
            font-weight: 500;
            text-align: left;
        }
    }
    .desc-column, .text-column {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 2.5rem 0 3rem;
        text-align: right;
        font-size: 0.9rem;
        flex-basis: 30%;
    }
    
</style>