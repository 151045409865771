import { gql } from "graphql-tag";

export const ME_QUERY = gql`
  query getUserInfo {
    me {
      id
      lastLogin
      firstName
      lastName
      dateJoined
      username
      email
      fullName
    }
  }
`;
