<template>
    <div v-if="selectedTab" class="tabs-container justify-between items-center flex mb-6 border-b">
        <div class="flex items-center">
            <template v-for="option in tabsOptions" :key="option.key">
                <div @click="changeSelection(option)" class="option px-3 py-2 text-sm mr-4 font-light relative" :class="{ 'selected': option == selectedTab }"> {{ option.displayValue }} 
                    <span v-if="totals" class="ml-1"> ({{ totals[option.allQueryName].totalCount }}) </span>
                </div>
            </template>
        </div>
        <p v-if="totals" class="text-black  py-2 text-sm font-medium uppercase"> Total Assets: {{ totals.assets.totalCount }} </p>
    </div>

</template>
<script setup lang="ts">
import { TOTAL_ASSETS_BY_TYPE } from '@/grapql/assetsQuery';
import { ASSET_TYPES } from '@/models/technologyAssetConstants';
import { AssetTypeConstantInterface } from '@/models/technologyAssetsInterface';
import { useQuery } from '@vue/apollo-composable';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const tabsOptions = ref([ASSET_TYPES.OTHER, ASSET_TYPES.PATENT, ASSET_TYPES.RESEARCH_PAPER, ASSET_TYPES.RESEARCH_PROJECT])
const selectedTab = computed( () => store.getters['assetsModule/getSelectedTab']);

const { result } = useQuery(TOTAL_ASSETS_BY_TYPE)
const totals = computed(() => result.value ?? null)

const changeSelection = (option: AssetTypeConstantInterface) => {
   store.commit('assetsModule/setTablePage', {page: 1})
   store.commit('assetsModule/updateTabSelection', {selectedTab: option})
   
}


</script>
<style lang="scss">
.option {
    color: $font-color-light;
    cursor: pointer;

    &.selected {
        color: $one-five-blue;
        font-weight: 500;

        &::after {
            content: "";
            width: 100%;
            height: 3px;
            background: $one-five-blue;
            position: absolute;
            bottom: -2px;
            left: 0;
        }
    }
}
</style>