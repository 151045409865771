import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "filter-box paper shadow-md rounded-lg mb-6 bg-peach" }
const _hoisted_2 = { class: "filter-title flex justify-between px-4 py-3" }
const _hoisted_3 = {
  key: 0,
  class: "w-full text-center"
}
const _hoisted_4 = {
  key: 1,
  class: "w-full text-center"
}
const _hoisted_5 = {
  key: 0,
  class: "filter-options flex flex-col px-3 py-4 text-sm"
}
const _hoisted_6 = { class: "flex justify-between pr-4" }
const _hoisted_7 = { class: "flex mt-1 mb-3 flex-wrap" }
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "flex justify-between pr-4" }
const _hoisted_10 = { class: "flex mt-1 mb-3 flex-wrap" }
const _hoisted_11 = ["value"]

import { ref } from 'vue';
import { EllipsisHorizontalIcon } from '@heroicons/vue/20/solid';
import { MagnifyingGlassCircleIcon } from '@heroicons/vue/24/outline';


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterHeatSeals',
  props: ['layerIndex'],
  emits: ['filterLayer'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const showHeatSealFilters = ref(false);
const treatmentsOptions = [{label: 'Fully biobased', value: 10}, {label: 'Partially biobased', value: 6}, {label: 'Petrol based', value: 46}]
const selectedtreatments = ref([]);

const coatingOptions = [{label: 'Extrusion', value: 1}, {label: 'Lamination', value: 2}, {label: 'Dispersion', value: 3}, {label: 'Other', value: 4}]
const selectedCoating = ref([]);

const filteredTreatment = ref(null);
const filteredCoating = ref(null);

const onFilterLayer = (filterType: string) => {
    if (filterType == 'treatment') {
        const newTotal = selectedtreatments.value.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        setTimeout(() => {
            filteredTreatment.value = newTotal
            emit('filterLayer', { layerIndex: props.layerIndex, newTotal: filteredTreatment.value, rowNumber: 2 })
        }, 1000)
    }
    if (filterType == 'coating') {
        const newTotal = selectedCoating.value.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        setTimeout(() => {
            filteredCoating.value = newTotal
            emit('filterLayer', { layerIndex: props.layerIndex, newTotal: filteredCoating.value, rowNumber: 4 })
        }, 500)
    }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[6] || (_cache[6] = _createElementVNode("div", null, [
        _createElementVNode("p", { class: "title" }, "coating"),
        _createElementVNode("p", { class: "subtitle" }, "Heat Seal filtering")
      ], -1)),
      _createVNode(_unref(EllipsisHorizontalIcon), {
        class: "icon icon-small white",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (showHeatSealFilters.value = !showHeatSealFilters.value))
      })
    ]),
    _createElementVNode("div", {
      class: "filter-numbers flex cursor-pointer",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (showHeatSealFilters.value = !showHeatSealFilters.value))
    }, [
      _cache[7] || (_cache[7] = _createElementVNode("p", { class: "w-full text-center" }, "56", -1)),
      (filteredTreatment.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(filteredTreatment.value), 1))
        : _createCommentVNode("", true),
      (filteredCoating.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(filteredCoating.value), 1))
        : _createCommentVNode("", true)
    ]),
    (showHeatSealFilters.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _cache[8] || (_cache[8] = _createElementVNode("p", { class: "uppercase font-medium" }, "Treatment", -1)),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(treatmentsOptions, (treatment) => {
                return _createElementVNode("div", {
                  key: treatment.value,
                  class: "filter-option flex items-center flex-nowrap"
                }, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    value: treatment.value,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((selectedtreatments).value = $event))
                  }, null, 8, _hoisted_8), [
                    [_vModelCheckbox, selectedtreatments.value]
                  ]),
                  _createElementVNode("label", null, _toDisplayString(treatment.label), 1)
                ])
              }), 64))
            ]),
            _createVNode(_unref(MagnifyingGlassCircleIcon), {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (onFilterLayer('treatment'))),
              class: "icon icon-m shrink-0"
            })
          ]),
          _cache[9] || (_cache[9] = _createElementVNode("p", { class: "uppercase font-medium" }, "Coating techniques", -1)),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(coatingOptions, (coating) => {
                return _createElementVNode("div", {
                  key: coating.value,
                  class: "filter-option flex items-center"
                }, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    value: coating.value,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((selectedCoating).value = $event))
                  }, null, 8, _hoisted_11), [
                    [_vModelCheckbox, selectedCoating.value]
                  ]),
                  _createElementVNode("label", null, _toDisplayString(coating.label), 1)
                ])
              }), 64))
            ]),
            _createVNode(_unref(MagnifyingGlassCircleIcon), {
              onClick: _cache[5] || (_cache[5] = ($event: any) => (onFilterLayer('coating'))),
              class: "shrink-0 icon icon-m"
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})