import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "border p-0 m-4" }
const _hoisted_2 = {
  key: 0,
  class: "form-container"
}
const _hoisted_3 = { class: "grid grid-cols-4 gap-y-4 gap-x-4" }
const _hoisted_4 = ["value"]
const _hoisted_5 = {
  key: 0,
  class: "col-span-4 border border-gray-100 border-solid p-1 pt-3 pb-1 -mt-4 bg-gray-50"
}
const _hoisted_6 = {
  key: 1,
  class: "w-full flex"
}
const _hoisted_7 = ["value"]
const _hoisted_8 = {
  key: 1,
  class: "col-span-4 border border-gray-100 border-solid p-1 pt-3 pb-1 -mt-4 bg-gray-50"
}
const _hoisted_9 = {
  key: 1,
  class: "w-full flex"
}
const _hoisted_10 = { class: "col-span-4 text-center mt-2" }
const _hoisted_11 = {
  key: 0,
  class: "col-span-4"
}
const _hoisted_12 = {
  key: 0,
  class: "button green-accept cursor-not-allowed opacity-75 hover:shadow-none",
  disabled: ""
}
const _hoisted_13 = {
  key: 2,
  class: "col-span-4 text-center mt-2"
}
const _hoisted_14 = { class: "overflow-hidden" }

import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { ChevronRightIcon } from "@heroicons/vue/24/solid";
import { RUN_DOUBLE_SENSITIVITY_ADD_FORM_VALUES } from "@/tea/graphql/queries/runQueries";
import { useTeaQuery } from "@/tea/composables/apollo/useTeaQuery";
import { NEW_RUN_DOUBLE_SENSITIVITY_FIELDS } from "@/tea/models/runDoubleSensitivity";
import { useFormFieldsCreationNewRunDoubleSensitivity } from "@/tea/composables/runs/useFormFieldsCreationNewRunDoubleSensitivity";
import { useFormInizializationNewRunDoubleSensitivity } from "@/tea/composables/runs/useFormInizializationNewRunDoubleSensitivity";
import { DropdownSearchableOptionInterface } from "@/models/dropdownSearchableMultipleOptionInterface";
import FormErrorToast from "@/components/FormErrorToast.vue";
import DoubleSensitivityChart from "@/tea/components/runs/charts/DoubleSensitivityChart.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'RunDoubleSensitivityCreate',
  props: ["runId"],
  setup(__props) {

const yesNos = [
  { key: true, value: "Yes" },
  { key: false, value: "No" },
];

const props = __props;
const store = useStore();

onMounted(() => {
  store.commit("runDoubleSensitivityModule/SET_RUN_ID", props.runId);
});

const newAnalysisToggled = ref(false);
const toggleNewAnalysis = () => {
  newAnalysisToggled.value = !newAnalysisToggled.value;
};

// Inizialize the form
const { result } = useTeaQuery(RUN_DOUBLE_SENSITIVITY_ADD_FORM_VALUES, { id: props.runId });
store.commit("runDoubleSensitivityModule/SET_SINGLE_RUN_VALUE",{field:"firstValue",value:"asdf",resetFields:[]})
const inizilizedData = computed(() => result?.value ?? null);
const formInizializationValues = computed<{ [key: string]: DropdownSearchableOptionInterface[] }>(() =>
  useFormInizializationNewRunDoubleSensitivity(inizilizedData.value, NEW_RUN_DOUBLE_SENSITIVITY_FIELDS.fieldsToQuery)
);
const getFormFields = Object.keys(NEW_RUN_DOUBLE_SENSITIVITY_FIELDS.formFields).map((key) => {
  const fieldConfig = NEW_RUN_DOUBLE_SENSITIVITY_FIELDS.formFields[key];
  return {
    [key]: Array.isArray(fieldConfig.onChangeResetFields) ? fieldConfig.onChangeResetFields : [],
  };
});

const formFieldOptions = {
  fields: getFormFields,
  mutation: "runDoubleSensitivityModule/SET_SINGLE_RUN_VALUE",
};

const formFields = useFormFieldsCreationNewRunDoubleSensitivity(formFieldOptions);

const create = computed(() => store.getters["runDoubleSensitivityModule/create"]);
const fields = computed(() => create.value.fields);
const formSubmited = computed(() => create.value.isFormSubmitted);
const isReadyForOutput = computed(() => create.value.isReadyForOutput);
const isOutputLoading = computed(() => create.value.isOutputLoading);
const calculateError = computed(() => create.value.calculateError);
const errorsDuringMutation = computed(() => create.value.createError);

const doubleSensitivityOutput = computed(() => create.value.doubleSensitivityOutput);

const isFirstVariableEWC = computed(() => {
  const vals = ["Electricity", "Water", "CO2"];
  return vals.includes(fields.value.firstVariable);
});
const isSecondVariableEWC = computed(() => {
  const vals = ["Electricity", "Water", "CO2"];
  return vals.includes(fields.value.secondVariable);
});
const firstValueText = computed(() => {
  switch (fields.value.firstVariable) {
    case "Electricity":
      return "Electricity (€/kwh)";
    case "Water":
      return "Water (€/m3)";
    case "CO2":
      return "CO2 (€/kg)";
    default:
      return "";
  }
});

const secondValueText = computed(() => {
  switch (fields.value.secondVariable) {
    case "Electricity":
      return "Electricity (€/kwh)";
    case "Water":
      return "Water (€/m3)";
    case "CO2":
      return "CO2 (€/kg)";
    default:
      return "";
  }
});

const valuesSubmitted = computed(() => fields.value);

function getMissingFields(fieldName, fieldValue, validation) {
  const missingFields = [];
  // 1. check if required
  if (validation.required === true) {
    if (fieldValue === undefined || fieldValue === null || fieldValue === "") {
      missingFields.push(fieldName);
    }
  }

  // 2. check if validtion has valueValidation property set and aloso field has value, then check if value is truthy
  if (validation.valueValidation && fieldValue !== undefined && fieldValue !== null && fieldValue !== "") {
    const { type, values } = validation.valueValidation;
    if (type === "array") {
      if (!values.includes(fieldValue)) {
        missingFields.push(fieldName);
      }
    } else if (type === "range") {
      if (fieldValue < values[0] || fieldValue > values[1]) {
        missingFields.push(fieldName);
      }
    } else if (type === "equal") {
      if (fieldValue !== values) {
        missingFields.push(fieldName);
      }
    } else if (type === "min") {
      if (fieldValue < values) {
        missingFields.push(fieldName);
      }
    }
  }

  // 3. check dependants
  if (validation.dependants) {
    const { dependants } = validation;
    for (const condition of dependants) {
      let doCheckUp = false;
      const { ifValue, ifValueType, ifValueReference, fields, not, is } = condition;
      // Check if the field associated with the condition is truthy
      //check if there is ifValueType equals to options
      //check if it has not property
      //check if it has is property
      if (ifValueType === "option") {
        if (formInizializationValues.value[ifValueReference]) {
          let selectedItem = formInizializationValues.value[ifValueReference].find((item) => item.name === ifValue);
          if (selectedItem) {
            if (selectedItem.id == fieldValue) {
              doCheckUp = true;
            }
          }
        }
      } else if (fieldValue === ifValue) {
        doCheckUp = true;
      }

      if (not && doCheckUp) {
        for (const notCondition of not) {
          let notIfFieldValue = valuesSubmitted.value[notCondition.ifField];
          if (notCondition.ifValueType === "option") {
            if (formInizializationValues.value[notCondition.ifValueReference]) {
              let selectedItem = formInizializationValues.value[notCondition.ifValueReference].find((item) => item.name === notCondition.ifValue);
              if (selectedItem) {
                if (selectedItem.id === notIfFieldValue) {
                  //do not do checkup because it does not passes the not condition
                  doCheckUp = false;
                }
              }
            }
          } else if (notIfFieldValue === notCondition.ifValue) {
            //do not do checkup because it does not passes the not condition
            doCheckUp = false;
          }
        }
      }

      if (is && doCheckUp) {
        for (const isCondition of is) {
          let isIfFieldValue = valuesSubmitted.value[isCondition.ifField];
          if (isCondition.ifValueType === "option") {
            if (formInizializationValues.value[isCondition.ifValueReference]) {
              let selectedItem = formInizializationValues.value[isCondition.ifValueReference].find((item) => item.name === isCondition.ifValue);
              if (selectedItem) {
                if (selectedItem.id !== isIfFieldValue) {
                  //do not do checkup because it does not passes the not condition
                  doCheckUp = false;
                }
              }
            }
          } else if (isIfFieldValue !== isCondition.ifValue) {
            //do not do checkup because it does not passes the not condition
            doCheckUp = false;
          }
        }
      }

      if (doCheckUp) {
        for (const dependantField of fields) {
          // Check if the field is missing
          const dependantValue = valuesSubmitted.value[dependantField];
          if (dependantValue === undefined || dependantValue === null || dependantValue === "") {
            missingFields.push(dependantField);
          }
        }
      }
    }
  }

  // Add more validation logic here if needed.
  return Array.from(new Set(missingFields)); //Remove duplicates
}
const errorsFields = computed(() => {
  const missingFields = [];
  for (const fieldName of Object.keys(NEW_RUN_DOUBLE_SENSITIVITY_FIELDS.formFields)) {
    const validation = NEW_RUN_DOUBLE_SENSITIVITY_FIELDS.formFields[fieldName];
    const fieldValue = valuesSubmitted.value[fieldName];
    missingFields.push(...getMissingFields(fieldName, fieldValue, validation));
  }
  return Array.from(new Set(missingFields)); //Remove duplicates
});

const formIsValid = computed(() => {
  return errorsFields.value.length === 0;
});

const showOutputs = async () => {
  store.commit("runDoubleSensitivityModule/SET_CREATE_MUTATION", { key: "isFormSubmitted", value: true });
  if (formIsValid.value) {
    // get outputs now
    const mutation = NEW_RUN_DOUBLE_SENSITIVITY_FIELDS.calculateMutation;
    await store
      .dispatch(mutation)
      .then(() => {
        store.commit("runDoubleSensitivityModule/SET_CREATE_MUTATION", { key: "isReadyForOutput", value: true });
        store.commit("runDoubleSensitivityModule/SET_CREATE_MUTATION", { key: "canSubmit", value: true });
        store.commit("runDoubleSensitivityModule/SET_CREATE_MUTATION", { key: "createError", value: true });
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: toggleNewAnalysis,
      class: "hover:bg-gray-50 p-4 pl-2 pr-2 border-b flex items-center cursor-pointer"
    }, [
      _createVNode(_unref(ChevronRightIcon), {
        class: _normalizeClass(["icon icon-small inline-block mr-2", { 'rotate-90': !newAnalysisToggled.value }])
      }, null, 8, ["class"]),
      _cache[8] || (_cache[8] = _createTextVNode(" NEW DOUBLE SENSITIVITY ANALYSIS "))
    ]),
    _createElementVNode("div", {
      class: _normalizeClass([{ hidden: newAnalysisToggled.value }, "p-4"])
    }, [
      _createElementVNode("div", null, [
        _createVNode(FormErrorToast, {
          style: {"margin":"0 0 1rem 0"},
          class: "block",
          "is-showing": !!calculateError.value,
          title: "Warning!",
          subtitle: calculateError.value,
          "toast-type": "warning"
        }, null, 8, ["is-showing", "subtitle"])
      ]),
      _createElementVNode("div", null, [
        _createVNode(FormErrorToast, {
          style: {"margin":"0 0 1rem 0"},
          class: "block",
          "is-showing": formSubmited.value && !formIsValid.value,
          title: "The following fields have validation errors:",
          subtitle: errorsFields.value.join(', ')
        }, null, 8, ["is-showing", "subtitle"])
      ]),
      (formInizializationValues.value)
        ? (_openBlock(), _createElementBlock("form", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", {
                class: _normalizeClass(["col-span-4", { 'error-form-field': errorsFields.value.includes('firstVariable') && formSubmited.value }])
              }, [
                _cache[9] || (_cache[9] = _createElementVNode("label", null, "First Variable to explore *", -1)),
                _withDirectives(_createElementVNode("select", {
                  class: "w-full",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(formFields)['firstVariable'].value) = $event))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formInizializationValues.value['variablesOptions'], (opt) => {
                    return (_openBlock(), _createElementBlock("option", {
                      value: opt.name,
                      key: opt.id
                    }, _toDisplayString(opt.name), 9, _hoisted_4))
                  }), 128))
                ], 512), [
                  [_vModelSelect, _unref(formFields)['firstVariable'].value]
                ])
              ], 2),
              (fields.value.firstVariable)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (isFirstVariableEWC.value)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(["w-full", { 'error-form-field': errorsFields.value.includes('firstValue') && formSubmited.value }])
                        }, [
                          _createElementVNode("label", null, "Reference price you want to use for " + _toDisplayString(firstValueText.value) + " *", 1),
                          _withDirectives(_createElementVNode("input", {
                            class: "w-full",
                            type: "number",
                            min: "0.00",
                            max: "100.00",
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(formFields)['firstValue'].value) = $event))
                          }, null, 512), [
                            [_vModelText, _unref(formFields)['firstValue'].value]
                          ])
                        ], 2))
                      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["w-1/2", { 'error-form-field': errorsFields.value.includes('firstValueRangeMin') && formSubmited.value }])
                          }, [
                            _createElementVNode("label", null, "Minimum price you want to use for " + _toDisplayString(fields.value.firstVariable) + " *", 1),
                            _withDirectives(_createElementVNode("input", {
                              class: "w-full",
                              type: "number",
                              min: "0.00",
                              max: "100.00",
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(formFields)['firstValueRangeMin'].value) = $event))
                            }, null, 512), [
                              [_vModelText, _unref(formFields)['firstValueRangeMin'].value]
                            ])
                          ], 2),
                          _createElementVNode("div", {
                            class: _normalizeClass(["w-1/2 ml-4", { 'error-form-field': errorsFields.value.includes('firstValueRangeMax') && formSubmited.value }])
                          }, [
                            _createElementVNode("label", null, "Maximum price you want to use for " + _toDisplayString(fields.value.firstVariable) + " *", 1),
                            _withDirectives(_createElementVNode("input", {
                              class: "w-full",
                              type: "number",
                              min: "0.00",
                              max: "100.00",
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(formFields)['firstValueRangeMax'].value) = $event))
                            }, null, 512), [
                              [_vModelText, _unref(formFields)['firstValueRangeMax'].value]
                            ])
                          ], 2)
                        ]))
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(["col-span-4", { 'error-form-field': errorsFields.value.includes('secondVariable') && formSubmited.value }])
              }, [
                _cache[10] || (_cache[10] = _createElementVNode("label", null, "Second Variable to explore *", -1)),
                _withDirectives(_createElementVNode("select", {
                  class: "w-full",
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(formFields)['secondVariable'].value) = $event))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formInizializationValues.value['variablesOptions'], (opt) => {
                    return (_openBlock(), _createElementBlock("option", {
                      value: opt.name,
                      key: opt.id
                    }, _toDisplayString(opt.name), 9, _hoisted_7))
                  }), 128))
                ], 512), [
                  [_vModelSelect, _unref(formFields)['secondVariable'].value]
                ])
              ], 2),
              (fields.value.secondVariable)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    (isSecondVariableEWC.value)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(["w-full", { 'error-form-field': errorsFields.value.includes('secondValue') && formSubmited.value }])
                        }, [
                          _createElementVNode("label", null, "Reference price you want to use for " + _toDisplayString(secondValueText.value) + " *", 1),
                          _withDirectives(_createElementVNode("input", {
                            class: "w-full",
                            type: "number",
                            min: "0.00",
                            max: "100.00",
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(formFields)['secondValue'].value) = $event))
                          }, null, 512), [
                            [_vModelText, _unref(formFields)['secondValue'].value]
                          ])
                        ], 2))
                      : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["w-1/2", { 'error-form-field': errorsFields.value.includes('secondValueRangeMin') && formSubmited.value }])
                          }, [
                            _createElementVNode("label", null, "Minimum price you want to use for " + _toDisplayString(fields.value.secondVariable) + " *", 1),
                            _withDirectives(_createElementVNode("input", {
                              class: "w-full",
                              type: "number",
                              min: "0.00",
                              max: "100.00",
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(formFields)['secondValueRangeMin'].value) = $event))
                            }, null, 512), [
                              [_vModelText, _unref(formFields)['secondValueRangeMin'].value]
                            ])
                          ], 2),
                          _createElementVNode("div", {
                            class: _normalizeClass(["w-1/2 ml-4", { 'error-form-field': errorsFields.value.includes('secondValueRangeMax') && formSubmited.value }])
                          }, [
                            _createElementVNode("label", null, "Maximum price you want to use for " + _toDisplayString(fields.value.secondVariable) + " *", 1),
                            _withDirectives(_createElementVNode("input", {
                              class: "w-full",
                              type: "number",
                              min: "0.00",
                              max: "100.00",
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(formFields)['secondValueRangeMax'].value) = $event))
                            }, null, 512), [
                              [_vModelText, _unref(formFields)['secondValueRangeMax'].value]
                            ])
                          ], 2)
                        ]))
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_10, [
                (!isReadyForOutput.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      (isOutputLoading.value)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(isOutputLoading.value ? "Loading Outputs . . ." : "Show Outputs"), 1))
                        : (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            onClick: showOutputs,
                            class: "button green-accept cursor-pointer"
                          }, "Show Outputs"))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (isReadyForOutput.value && doubleSensitivityOutput.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createVNode(DoubleSensitivityChart, {
                        class: "w-full border",
                        data: doubleSensitivityOutput.value
                      }, null, 8, ["data"])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}
}

})