import gql from "graphql-tag";

export const BENCHMARK_CREATE = gql`
    mutation benchmarkCreate($input: UserWorkspaceCreateMutationInput!) {
        userWorkspaceCreate (input: $input) {
            userWorkspace {
                id
                title
                value
            }
        }
  }
`

export const BENCHMARK_LIST = gql`
    query workspaceTables($filter: UserWorkspaceNodeFilterInputType!) {
        userWorkspaces(filter: $filter) {
            edges {
                node {
                    id
                    title
                    value
                    workspaceType
                    createdAt
                    createdBy {
                        username
                    }
                }
            }
        }
    }    
`

export const BENCHMARK_TABLE = gql`
    query userWorkspace($id: ID!) {
        userWorkspace(id: $id) {
            id
            title
            value
        }
    }    
`

export const BENCHMARK_TABLE_UPDATE = gql`
    mutation benchmarkUpdate($input: UserWorkspaceUpdateMutationInput!) {
        userWorkspaceUpdate(input: $input) {
            userWorkspace {
                id
                title
                value
                workspaceType
            }
        }
    }
`