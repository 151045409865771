<template>
    <div>
        <span v-html="typedText" class="whitespace-pre-line"></span>
        <HeartBeat v-if="isHeartBeating" style="display: inline-block !important;"/>
    </div>
</template>
<script lang="ts" setup>
import { ref,defineProps,watch,computed} from 'vue';
import HeartBeat from './HeartBeat.vue';
import { useStore } from 'vuex';

const store = useStore();

const props =defineProps(['text','messageId']);

const textWords = computed(()=> props.text?.split(' '));

const typedText = ref('');
const index = ref(0);

const pulsing = computed(()=> index.value < textWords.value?.length || store.state.aiModule.pulsing == props.messageId );

const intervalId = ref(null);

const typingSpeed = computed(()=> store.state.aiModule.typingSpeed);

const isStopped = computed(()=> store.state.aiModule.isStopped);
const isSubscriptionActive = computed(()=> store.getters["aiModule/getIsSubscriptionActive"]);

const typing = computed(()=> store.state.aiModule.typing);

const isHeartBeating = computed(()=>{
    return typing.value && isSubscriptionActive.value && index.value < textWords.value?.length;
});

// Watch for changes in props.answer
watch(async() => props.text, (newValue, oldValue) => {
    if (newValue !== oldValue) {
        clearInterval(intervalId.value);
        typeWords();
    }
});

const typeWords = ()=>
{

    intervalId.value = setInterval(() => {

        if(!isSubscriptionActive.value){
            clearInterval(intervalId.value);
            store.commit('aiModule/setTyping',false);
        }else{

            store.commit('aiModule/setTyping',true);
        
            typedText.value += textWords.value[index.value] + ' ';
            index.value++;
            window.scrollTo(0, document.body.scrollHeight);
            if(index.value == textWords.value.length) {
                clearInterval(intervalId.value);
                store.commit('aiModule/setTyping',false);
            }
        }
    }, typingSpeed.value);

}   
typeWords();

</script>
<style scoped>
</style>