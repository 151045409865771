<template>
    <div class="form-container">  
        <form class="add-asset-form" v-if="formInizializationValues">
            <!-- Invclude Research Paper Autofill Service -->
            <ResearchPaperAutofillModal @research-paper-autofilled="handleResearchPaperAutofilled" />  
            <div class="grid grid-cols-3 gap-y-2.5 gap-x-4">
                <div class="form-separator col-span-3"><p class="form-subtitle">Research paper Info</p></div>
                <div :class="{'error-form-field': errorsFields.includes('nickname') && formSubmited }"> 
                    <label>Nickname *</label>
                    <input class="w-full" type="text" required v-model="formFields['nickname'].value">
                </div>
                <div class="col-span-2" :class="{'error-form-field': errorsFields.includes('title') && formSubmited }">
                    <label>Title *</label>
                    <input class="w-full" type="text" required v-model="formFields['title'].value">
                </div>
                <div>
                    <label>Doi Id</label>
                    <input class="w-full" type="text" v-model="formFields['doiId'].value">
                </div>
                <div class="col-span-2">
                    <label>Journal</label>
                    <input class="w-full" type="text" v-model="formFields['journal'].value">
                </div>
                <div class="col-span-3 grid" :class="{'error-form-field': errorsFields.includes('abstract') && formSubmited }">
                    <label>Abstract *</label>
                    <QuillEditor 
                        :content="formFields['abstract'].value ? formFields['abstract'].value : '<p><br></p>'"
                        content-type="html"
                        @update:content="content => formFields['abstract'].value = content"
                        @ready="onEditorReady($event)"
                    />                   
                </div>
                <div class="col-span-3 grid">
                    <label>Description</label>
                    <QuillEditor 
                        :content="formFields['description'].value ? formFields['description'].value : '<p><br></p>'"
                        content-type="html"
                        @update:content="content => formFields['description'].value = content"
                        @ready="onEditorReady($event)"
                       />
                </div>
                <div>
                    <label>Sources <span>(One or more)</span></label>
                    <select class="w-full" @input="formFields['sources'].value = useMultipleSelectForSources(Number(handleInputChange($event)), 'add', formFields['sources'].value)">
                        <option selected disabled></option>
                        <option v-for="source in formInizializationValues['sourcesOptions']" :value="source.id"  :key="source.id">{{source.name}}</option>
                    </select>
                    <div class="selected-results" v-if="formFields['sources'].value.length">
                        <template v-for="(sourceOption, index) in formInizializationValues['sourcesOptions']" :key="index">
                            <template v-for="(source, index) in formFields['sources'].value" :key="index">
                                <span v-if="source.source == sourceOption.id">
                                    {{sourceOption.name}}
                                    <XMarkIcon class="icon icon-x-small thick inline" @click="formFields['sources'].value = useMultipleSelectForSources(source.source, 'remove', formFields['sources'].value)"/>
                                </span>
                            </template>
                        </template>
                    </div>
                </div>
                <div>
                    <label>Url</label>
                    <input class="w-full" type="text" v-model="formFields['url'].value">
                </div>
                <div>
                    <label>Links <span>(Press Enter to add a link)</span></label> 
                    <input class="w-full" v-model="tempLinks" type="text" @keyup.enter="formFields['links'].value.push(handleInputChange($event)); tempLinks = ''" />
                    <div class="selected-results" v-if="formFields['links'].value.length">
                        <span v-for="(link, index) in formFields['links'].value" :key="index">
                            {{link}}
                            <XMarkIcon class="icon icon-x-small thick inline" @click="formFields['links'].value.splice(index, 1)"/>
                        </span>
                    </div>
                </div>
                <div class="form-separator col-span-3"><p class="form-subtitle">Research paper Participants</p></div>
                <div class="col-span-2"> 
                    <DropdownSearchableWithFilter 
                        v-model="formFields['authors'].value"
                        :filter-criteria="'partyType'"
                        :options-list="formInizializationValues['partiesOptions']"
                        :select-label="'Authors'"
                        :filter-options="[{id: 1, name: 'Individual'},{id: 2, name: 'University'},{id: 3, name: 'Organization'}]"
                        :filter-label="'Filter Authors by:'"
                        :two-columns="true"
                        :is-multiple="true"
                    />
                </div>
                <div class="mt-3"><PartyAddNew party-type="author" /></div>
                <div class="form-separator col-span-3"><p class="form-subtitle">Research Paper Assessment</p></div>
                <div>
                    <label>Materials <span>(One or more)</span></label>
                    <DropdownSearchableMultiple v-if="formInizializationValues['materialsOptions']" :options-list="formInizializationValues['materialsOptions']" v-model="formFields['materials'].value" />
                </div> 
                <div>
                    <label>Properties <span>(One or more)</span></label>
                    <DropdownSearchableMultiple v-if="formInizializationValues['propertiesOptions']" :options-list="formInizializationValues['propertiesOptions']" v-model="formFields['properties'].value" />
                </div>
                <div>
                    <label>Applications <span>(One or more)</span> </label>
                    <DropdownSearchableMultiple v-if="formInizializationValues['applicationTypesOptions']" :options-list="formInizializationValues['applicationTypesOptions']" v-model="formFields['applicationTypes'].value" />
                </div>
                <div>
                    <label>Certifications & standards <span>(One or more)</span> </label>
                    <DropdownSearchableMultiple v-if="formInizializationValues['standardsOptions']" :options-list="formInizializationValues['standardsOptions']" v-model="formFields['standards'].value" />
                </div>
                <div>
                    <label>Processing Techniques <span>(One or more)</span> </label>
                    <DropdownSearchableMultiple v-if="formInizializationValues['processingTechniquesOptions']" :options-list="formInizializationValues['processingTechniquesOptions']" v-model="formFields['processingTechniques'].value" />
                </div>
                <!-- <div>
                    <label>Tags <span>(One or more)</span></label> 
                    <DropdownSearchableMultiple v-if="formInizializationValues['tagsOptions']"
                        :options-list="formInizializationValues['tagsOptions']" v-model="formFields['tags'].value" select-other-than-id="name"/>
                    <div class="label-with-icon">
                        <span @click="showTagInput = true" class="icon-span">+ Create new tag</span>
                        <div v-if="showTagInput" class="tooltip">
                            <InformationCircleIcon class="icon"/>
                            <span class="tooltiptext info">Type the new tag and press enter to create and add it</span>
                        </div>
                    </div>
                    <input v-if="showTagInput" class="w-full" type="text" v-model="tempNewTag" @keyup.enter.prevent="formFields['tags'].value.push(handleInputChange($event)); tempNewTag = ''" />
                </div> -->
                <div>
                    <label>TRL <span>Number between 1 and 9</span></label>
                    <input class="w-full" type="number" min="1" max="9" v-model.number="formFields['trl'].value">
                </div>
                <div class="col-span-2 row-span-2 grid">
                    <label>Notes</label>
                    <QuillEditor 
                        :content="formFields['notes'].value ? formFields['notes'].value : '<p><br></p>'"
                        content-type="html"
                        @update:content="content => formFields['notes'].value = content"
                        @ready="onEditorReady($event)"
                    />
                </div>
                <div class="col-span-3 mt-2">
                    <FilesUploadFormField asset-type="researchPaper"/>
                </div>
                <div class="form-separator col-span-3"><p class="form-subtitle">Research paper Dates</p></div>
                <div>
                    <label>Publication Date</label> 
                    <Datepicker class="w-full" :upper-limit="new Date()" :clearable="true" :input-format="'dd-MMM-yyyy'" v-model="formFields['publicationDate'].value" />
                </div>
            </div>
        </form>
        <div class="form-error-toast">
            <FormErrorToast :is-showing="formSubmited && !formIsValid" title="The following fields are mandatory:"
                :subtitle="errorsFields.join(', ')" />
        </div>
    </div>
</template>
<script setup lang="ts">
import { ASSET_ADD_FORM_VALUES } from '@/grapql/assetAddFormValues';
import { useQuery } from '@vue/apollo-composable';
import { computed, defineProps, ref } from 'vue';
import { XMarkIcon, InformationCircleIcon } from '@heroicons/vue/24/outline';
import { useMultipleSelect, useMultipleSelectForSources } from '@/composables/useMultipleSelect';
import Datepicker from 'vue3-datepicker';
import { NEW_ASSET_FIELDS } from '@/models/newAssetFormFields';
import { useFormFieldsCreationNewAsset } from '@/composables/useFormFieldsCreationNewAsset';
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import FormErrorToast from '@/components/FormErrorToast.vue';
import DropdownSearchableMultiple from '@/components/DropdownSearchableMultiple.vue';
import DropdownSearchableWithFilter from './DropdownSearchableWithFilter.vue';
import FilesUploadFormField from './FilesUploadFormField.vue';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import PartyAddNew from '@/components/PartyAddNew.vue';
import ResearchPaperAutofillModal from '@/components/ResearchPaperAutofillModal.vue';

defineProps(['errorsFields', 'formIsValid', 'formSubmited']);
const tempLinks = '';
const tempNewTag = ref('');
const showTagInput = ref(false);
// Inizialize the form
const { result, refetch } = useQuery(ASSET_ADD_FORM_VALUES);

const inizilizedData = computed(() => result?.value ?? null)
const formInizializationValues = computed<{[key: string]: DropdownSearchableOptionInterface[]}>(() => useFormInizializationNewAsset(inizilizedData.value, NEW_ASSET_FIELDS.RESEARCH_PAPER.fieldsToQuery));

// Create Fields for the form
const handleInputChange = (event: Event) => (event.target as HTMLInputElement).value;
const formFieldOptions = {
    fields: NEW_ASSET_FIELDS.RESEARCH_PAPER.formFileds,
    base: NEW_ASSET_FIELDS.RESEARCH_PAPER.graphqlValue,
    mutation: 'assetCreationModule/setSingleAssetValue'
};
const formFields = useFormFieldsCreationNewAsset(formFieldOptions);
const onEditorReady = (event: Event) => {return event};

const handleResearchPaperAutofilled = () =>{
    refetch();
}
</script>
