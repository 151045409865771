import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "program-detail-card mb-4" }
const _hoisted_2 = {
  key: 0,
  class: "py-3 mr-6"
}
const _hoisted_3 = {
  key: 1,
  class: "empty-box add"
}

import { ProgramVersionTeamInterface } from '@/models/programVersionsInterface';
import { computed } from 'vue';
import AvatarsActivity from '@/components/AvatarsActivity.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramVersionTeamCard',
  props: {
    programVersionTeam: {}
  },
  setup(__props: any) {

const props = __props

const teamAsAvatarts = computed(() => {
    const teamAsAvatarts = [];
    props.programVersionTeam.map((teamMember: ProgramVersionTeamInterface) => {
        const newAvatar = {
            title: `${teamMember.teamRoleType.name} - ${teamMember.teamRoleType.category}`,
            personName: `${teamMember.user.firstName.toUpperCase()} ${teamMember.user.lastName.toUpperCase()}`,
            initials: `${teamMember.user.firstName.charAt(0)}${teamMember.user.lastName.charAt(0)}`,
            color: '#5ea2a4',
            twoLines: true
        }
        teamAsAvatarts.push(newAvatar);
    })
    return teamAsAvatarts;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("p", { class: "cards-title" }, "team", -1)),
    (_ctx.programVersionTeam.length && teamAsAvatarts.value.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(teamAsAvatarts.value, (avatar, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "team-member"
            }, [
              _createVNode(AvatarsActivity, {
                "users-activity": [avatar],
                orientation: "right"
              }, null, 8, ["users-activity"])
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3))
  ]))
}
}

})