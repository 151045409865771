import gql from "graphql-tag";

export const PRODUCT_BRIEF_CREATE = gql`
mutation productBriefCreate($input: ProductBriefCreateMutationInput!) {
    productBriefCreate(input: $input) {
      productBrief {
        id
      }
    }
  }
`