<template lang="">
    <div class="wip-page">
        <p>Research projects</p> 
        <p class="wip-text">Work in progress</p>
    </div>
</template>
<script lang="ts">
export default {
    
}
</script>
<style lang="">
    
</style>