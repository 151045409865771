export const PROGRAMS_INFO = [
    {
        id: '1',
        name: 'Ferrari', 
        version: 'Ferrari V0.9',
        leadTeam: [{
            title: 'TL',
            personName: 'Arda Yildirim',
            initials: 'AY'
        },
        {
            title: 'BDM',
            personName: 'Yelda Demirdögen',
            initials: 'YD'
        }],
        description: 'Doypack with zipper. Good barrier, highly recyclable, mechanically strong, and paper monomaterial packaging for non-food (dishwasher tabs). ',
        status: {
            label: 'on time',
            value: 'ok'
        },
        stage: '7',
        application: 'Dishwasher tab packaging ("Finish" tabs)',
        productImage: 'sachet.png',
        productBrief: '',
        targets: [
            {
                label: 'OTR',
                value: '<1 cc/(m²*day) at 23℃ 50% RH',
            },
            {
                label: 'WVTR / water resistance',
                value: '<2.5 g/(m²*day) at 38℃ 90% RH',
            },
            {
                label: 'Sealing type & strength',
                value: 'Heat seal at 120-180℃ with seal strength 25N/25mm and lamination strength >1.5N/15mm',
            },
            {
                label: 'Minimum paper content (%)',
                value: '90',
            },
            {
                label: 'Recyclability (>80% repulp)',
                value: 'Yes',
            },
            {
                label: 'Biodegradability',
                value: 'No',
            }
        ],
        productionTechniques: [
            {
                label: 'Oxygen barrier',
                value: 'Dispersion coating (DC): Reverse Rotogravure / Flexogravure / T chamber. Physical vapor deposition (PVD)',
            },
            {
                label: 'Water (vapor) barrier',
                value: 'Physical vapor deposition (PVD), Extrusion coating (EC) + Lamination',
            },
            {
                label: 'Sealant layer',
                value: 'Extrusion coating + Lamination',
            },
        ],
        layersInfo: {
            img: 'ferrari-layers.png',
            layers: [
                {
                    id: 1,
                    name: 'Bio-PE',
                    grammage: '10-12 g/m²',
                    description: 'Bio-based polyethylene (PE) heat-seal layer',
                    assets: [
                        {
                            assetName: 'Bio-PE',
                            assetLink: null
                        }
                    ] 
                },
                {
                    id: 2,
                    name: 'AI or AIOx',
                    grammage: '50mm',
                    description: 'Metallised barrier layer',
                    assets: null,
                },
                {
                    id: 3,
                    name: 'EVA/PVS',
                    grammage: '2-3 g/m²',
                    description: 'Prime layer',
                    assets: [
                        {
                            assetName: 'PatentA',
                            assetLink: null
                        }
                    ] 
                },
                {
                    id: 4,
                    name: 'Paper',
                    grammage: '110 g/m²',
                    description: 'Paper substrate',
                    assets: null
                }
            ]
        },
        prototypesDesignsInfo: [
            {
                id: 1, 
                successScore: 0.78,
                linkId: 1,
                layersData: [{ 
                    thickness: 15, 
                    color: "#c9b9b0", 
                    id: 4,
                    layerName: 'Paper',
                    layerDescription: 'Paper substrate',
                    grammage: '110 g/m²'
                }, 
                { 
                    thickness: 1, 
                    color: "#5fa2a5", 
                    id: 3,
                    layerName: 'EVA/PVS',
                    layerDescription: 'Prime layer',
                    grammage: '2/3 g/m²'
                }, 
                { 
                    thickness: 2, 
                    color: "#b9dfd9", 
                    id: 2,
                    layerName: 'AI or AIOx',
                    layerDescription: 'Metallised barrier layer',
                    grammage: '50 mm'
                },
                { 
                    thickness:  4, 
                    color: "#1c70b2", 
                    id: 1,
                    layerName: 'Bio-PE',
                    layerDescription: 'Bio-based polyethylene (PE) heat-seal layer',
                    grammage: '10/12 g/m²'
                }]
            },
            {
                id: 2, 
                successScore: 0.50,
                layersData: [{ 
                    thickness: 15, 
                    color: "#c9b9b0", 
                    id: 4,
                    layerName: 'Paper',
                    layerDescription: 'Paper substrate',
                    grammage: '110 g/m²'
                }, 
                { 
                    thickness: 2, 
                    color: "#b9dfd9", 
                    id: 2,
                    layerName: 'AI or AIOx',
                    layerDescription: 'Metallised barrier layer',
                    grammage: '50 mm'
                },
                { 
                    thickness:  4, 
                    color: "#1c70b2", 
                    id: 1,
                    layerName: 'Lignin',
                    layerDescription: 'Thermoplastic lignin',
                    grammage: '5-8 g/m²'
                }]
            },
            {
                id: 3, 
                successScore: 0.30,
                layersData: [{ 
                    thickness: 15, 
                    color: "#c9b9b0", 
                    id: 4,
                    layerName: 'Paper',
                    layerDescription: 'Paper substrate',
                    grammage: '110 g/m²'
                }, 
                { 
                    thickness: 1, 
                    color: "#5fa2a5", 
                    id: 3,
                    layerName: 'EVA/PVS',
                    layerDescription: 'Prime layer',
                    grammage: '2/3 g/m²'
                }, 
                { 
                    thickness: 2, 
                    color: "#b9dfd9", 
                    id: 2,
                    layerName: 'AI or AIOx',
                    layerDescription: 'Metallised barrier layer',
                    grammage: '50 mm'
                },
                { 
                    thickness:  4, 
                    color: "#1c70b2", 
                    id: 1,
                    layerName: 'PHA/PBS',
                    layerDescription: 'PHA/PBS blend',
                    grammage: '6-8 g/m²'
                }]
            }
        ]
    },
    {
        id: '2',
        name: 'McLaren', 
        version: 'McLaren',
        leadTeam: [{
            title: 'TL',
            personName: 'Gregor Dahl',
            initials: 'GD'
        },
        {
            title: 'BDM',
            personName: 'Yelda Demirdögen',
            initials: 'YD'
        }],
        description: 'Flexible paper packaging with high barrier for food and non-food applications',
        status: {
            label: 'delayed',
            value: 'delayed'
        },
        stage: '5',
        application: '',
        productImage: 'ketchup.png',
        productBrief: 'https://one-five.atlassian.net/wiki/spaces/PD/pages/995754033/McLaren+Summary+Develey',
        targets: [
            {
                label: 'OTR',
                value: '<1 cc/(m²*day) at 23℃ 50% RH',
            },
            {
                label: 'WVTR / water resistance',
                value: '<1 g/(m²*day) at 23℃ 50% RH',
            },
            {
                label: 'Monomaterial (>95% fibre)',
                value: 'No',
            },
            {
                label: 'Recyclability (>80% repulp)',
                value: 'No',
            },
            {
                label: 'Biodegradability',
                value: 'Yes. Soil & Marine',
            }
        ],
        productionTechniques: [
            {
                label: 'Oxygen barrier',
                value: 'DC - PVD',
            },
            {
                label: 'Water (vapor) barrier',
                value: 'PVD, DC or EC',
            },
            {
                label: 'Sealant layer',
                value: 'DC or EC',
            },
        ],
        layersInfo: {
            img: 'ferrari-layers.png',
            layers: [
                {
                    id: 1,
                    name: 'PHBH/PBS',
                    grammage: '10 g/m²',
                    description: 'Heat Seal layer',
                    assets: null,
                },
                {
                    id: 2,
                    name: 'Metallisation (Vac-depostied Al)',
                    grammage: '0.2 g/m²',
                    description: 'Metallised barrier layer',
                    assets: null,
                },
                {
                    id: 3,
                    name: 'BioCHIC',
                    grammage: '8 g/m²',
                    description: 'Oxygen barrier layer',
                    assets: [
                        {
                            assetName: 'Biochic Films',
                            assetLink: "other-5"
                        }
                    ] 
                },
                {
                    id: 4,
                    name: 'Paper',
                    grammage: '80 gsm',
                    description: 'Paper substrate (Köhler or B&B)',
                    assets: null
                }
            ]
        },
        prototypesDesignsInfo: [
            {
                id: 1, 
                successScore: 0.78,
                layersData: [{ 
                    thickness: 15, 
                    color: "#c9b9b0", 
                    id: 4,
                    layerName: 'Paper',
                    layerDescription: 'Paper substrate (Köhler or B&B)',
                    grammage: '110 g/m²'
                }, 
                { 
                    thickness: 2, 
                    color: "#5fa2a5", 
                    id: 3,
                    layerName: 'BioCHIC',
                    layerDescription: 'Oxygen barrier layer',
                    grammage: '8 g/m²'
                }, 
                { 
                    thickness: 1, 
                    color: "#b9dfd9", 
                    id: 2,
                    layerName: 'Vac-depostied Al',
                    layerDescription: 'Metallised barrier layer',
                    grammage: '0.2 g/m²'
                },
                { 
                    thickness:  3, 
                    color: "#1c70b2", 
                    id: 1,
                    layerName: 'PHBH/PBS',
                    layerDescription: 'Heat Seal layer',
                    grammage: '10 g/m²'
                }]
            },
            {
                id: 2, 
                successScore: 0.34,
                layersData: [{ 
                    thickness: 15, 
                    color: "#c9b9b0", 
                    id: 4,
                    layerName: 'Paper',
                    layerDescription: 'Paper substrate (Köhler or B&B)',
                    grammage: '110 g/m²'
                }, 
                { 
                    thickness: 2, 
                    color: "#5fa2a5", 
                    id: 3,
                    layerName: 'Zein',
                    layerDescription: 'Thermoplastic Zein',
                    grammage: '6-8 g/m²'
                }, 
                { 
                    thickness: 1, 
                    color: "#b9dfd9", 
                    id: 2,
                    layerName: 'Vac-depostied Al',
                    layerDescription: 'Metallised barrier layer',
                    grammage: '0.2 g/m²'
                },
                { 
                    thickness:  3, 
                    color: "#1c70b2", 
                    id: 1,
                    layerName: 'PHBH/PBS',
                    layerDescription: 'Heat Seal layer',
                    grammage: '10 g/m²'
                }]
            },
            {
                id: 3, 
                successScore: 0.1,
                layersData: [{ 
                    thickness: 15, 
                    color: "#c9b9b0", 
                    id: 4,
                    layerName: 'Paper',
                    layerDescription: 'Paper substrate (Köhler or B&B)',
                    grammage: '110 g/m²'
                }, 
                { 
                    thickness: 2, 
                    color: "#5fa2a5", 
                    id: 3,
                    layerName: 'Zein',
                    layerDescription: 'Thermoplastic Zein',
                    grammage: '6-8 g/m²'
                }, 
                { 
                    thickness: 1, 
                    color: "#b9dfd9", 
                    id: 2,
                    layerName: 'Vac-depostied Al',
                    layerDescription: 'Metallised barrier layer',
                    grammage: '0.2 g/m²'
                },
                { 
                    thickness:  3, 
                    color: "#1c70b2", 
                    id: 1,
                    layerName: 'EVA',
                    layerDescription: 'Ethyl vinyl acetate (EVA)',
                    grammage: '10 g/m²'
                }]
            }
        ]
    },
    {
        id: '3',
        name: 'Alpine', 
        version: 'Alpine',
        leadTeam: [{
            title: 'TL',
            personName: 'Mara Jensen',
            initials: 'MJ'
        },
        {
            title: 'BDM',
            personName: 'Yang Zhao',
            initials: 'YZ'
        }],
        description: 'Aseptic liquid carton packaging (drink cartons)',
        status: {
            label: 'on time',
            value: 'ok'
        },
        stage: '5',
        application: 'Liquid drink carton (food)',
        productImage: '',
        productBrief: 'https://one-five.atlassian.net/wiki/spaces/PD/pages/1179353089/JDA+Project+Plan+SIG',
        targets: [
            {
                label: 'Feedstock',
                value: 'Bio-based, non-food competing',
            },
            {
                label: 'Shelf-life',
                value: '>6 months',
            },
            {
                label: 'Monomaterial (>95% fibre)',
                value: 'No',
            },
            {
                label: 'Recyclability (>80% repulp)',
                value: 'Yes',
            },
            {
                label: 'Biodegradability',
                value: 'No',
            }
        ],
        productionTechniques: [
            {
                label: 'Oxygen barrier',
                value: 'Extrusion coating (hot melt coating)',
            },
            
        ],
        layersInfo: {
            img: 'alpine-layers.png',
            layers: [
                {
                    id: 1,
                    name: 'PHBHx/PBS blend',
                    grammage: '8-10  g/m²',
                    description: 'OTR barrier',
                    assets: [
                        {
                            assetName: 'A kind of thermal insulation roof that is suitable for the existing flat roof deck.',
                            assetLink: 'patent-2'
                        },
                        {
                            assetName: 'Asset B',
                            assetLink: null
                        }
                    ] 
                },
                {
                    id: 2,
                    name: 'SIG Supply',
                    grammage: 'n/a',
                    description: 'Liquid packaging board',
                    assets: null,
                },
            ]
        },
        prototypesDesignsInfo: [
            {
                id: 1, 
                successScore: 0.78,
                layersData: [{ 
                    thickness: 15, 
                    color: "#5fa2a5", 
                    id: 4,
                    layerName: 'SIG Supply',
                    layerDescription: 'Liquid packaging board',
                    grammage: 'n/a'
                }, 
                { 
                    thickness: 1, 
                    color: "#b9dfd9", 
                    id: 3,
                    layerName: 'PHBHx/PBS blend',
                    layerDescription: 'OTR barrier',
                    grammage: '8-10  g/m²'
                }]
            },
            {
                id: 2, 
                successScore: 0.44,
                layersData: [{ 
                    thickness: 15, 
                    color: "#5fa2a5", 
                    id: 4,
                    layerName: 'SIG Supply',
                    layerDescription: 'Liquid packaging board',
                    grammage: 'n/a'
                }, 
                { 
                    thickness: 1, 
                    color: "#5fa2a5", 
                    id: 2,
                    layerName: 'Primer',
                    layerDescription: 'Prime layer',
                    grammage: '2/3 g/m²'
                }, 
                { 
                    thickness: 1, 
                    color: "#b9dfd9", 
                    id: 3,
                    layerName: 'Zein',
                    layerDescription: 'Thermoplastic Zein',
                    grammage: '6-10 g/m²'
                }]
            },
            {
                id: 3, 
                successScore: 0.23,
                layersData: [{ 
                    thickness: 15, 
                    color: "#5fa2a5", 
                    id: 4,
                    layerName: 'SIG Supply',
                    layerDescription: 'Liquid packaging board',
                    grammage: 'n/a'
                }, 
                { 
                    thickness: 1, 
                    color: "#5fa2a5", 
                    id: 2,
                    layerName: 'Primer',
                    layerDescription: 'Prime layer',
                    grammage: '2/3 g/m²'
                },
                { 
                    thickness: 1, 
                    color: "#b9dfd9", 
                    id: 3,
                    layerName: 'BioCHIC',
                    layerDescription: 'BioCHIC layer',
                    grammage: '8 g/m²'
                }]
            }
        ]
    },
    {
        id: '4',
        name: 'Merdeces', 
        version: 'Mercedes',
        leadTeam: [{
            title: 'TL',
            personName: 'Jan Dethloff',
            initials: 'JD'
        },
        {
            title: 'BDM',
            personName: 'Yang Zhao',
            initials: 'YZ'
        }],
        description: 'Heat-sealable paper package for toilet & kitchen paper rolls.',
        status: {
            label: 'on time',
            value: 'ok'
        },
        stage: '5',
        application: 'Toilet paper wrapping (non-food)',
        productImage: '',
        productBrief: 'https://docs.google.com/presentation/d/1HMymIQ214ttK5qvGk2ic0qNJFPMn0mZOmlhMB_v3jcw/edit#slide=id.g1328380d5b6_0_0',
        targets: [
            {
                label: 'Sealing type & strength',
                value: 'Heat sealable >5N/15mm',
            },
            {
                label: 'Feedstock',
                value: 'Non-food competing, lignin valorisation from WEPA',
            },
            {
                label: 'Recyclability (>80% repulp)',
                value: 'Yes',
            },
            {
                label: 'Biodegradability',
                value: 'No',
            }
        ],
        productionTechniques: [
            {
                label: 'Oxygen barrier',
                value: 'Patterned coating via printing (gravure) (wet coating, dispersion coating)',
            },
            {
                label: 'Water (vapor) barrier',
                value: 'Physical vapor deposition (PVD), Extrusion coating (EC) + Lamination',
            },
            {
                label: 'Sealant layer',
                value: 'Extrusion coating + Lamination',
            },
        ],
        layersInfo: {
            img: 'mercedes-layers.png',
            layers: [
                {
                    id: 1,
                    name: 'Plasticized lignin',
                    grammage: '6 gsm',
                    description: 'Heat-sealable seam',
                    assets: [
                        {
                            assetName: 'A kind of thermal insulation roof that is suitable for the existing flat roof deck.',
                            assetLink: 'patent-2'
                        },
                        {
                            assetName: 'Asset B',
                            assetLink: null
                        }
                    ] 
                },
                {
                    id: 2,
                    name: 'Flexible paper',
                    grammage: '45 gsm',
                    description: 'Flexible paper body',
                    assets: null,
                },
            ]
        },
        prototypesDesignsInfo: [
            {
                id: 1, 
                successScore: 0.78,
                layersData: [{ 
                    thickness: 15, 
                    color: "#c9b9b0", 
                    id: 4,
                    layerName: 'Flexible paper',
                    layerDescription: 'Flexible paper body',
                    grammage: '45 gsm'
                }, 
                { 
                    thickness: 2, 
                    color: "#5fa2a5", 
                    id: 3,
                    layerName: 'Plasticized lignin',
                    layerDescription: 'Heat-sealable seam',
                    grammage: '6 gsm'
                }]
            },
            {
                id: 2, 
                successScore: 0.33,
                layersData: [{ 
                    thickness: 15, 
                    color: "#c9b9b0", 
                    id: 3,
                    layerName: 'Paper',
                    layerDescription: 'Paper substrate',
                    grammage: '110 g/m²'
                }, 
                { 
                    thickness: 1, 
                    color: "#5fa2a5", 
                    id: 2,
                    layerName: 'Primer',
                    layerDescription: 'Prime layer',
                    grammage: '2/3 g/m²'
                }, 
                { 
                    thickness: 3, 
                    color: "#b9dfd9", 
                    id: 1,
                    layerName: 'PHBH/PBS',
                    layerDescription: 'PHBH/PBS Adhesive Layer',
                    grammage: '10 g/m²'
                }]
            },
            {
                id: 3, 
                successScore: 0.1,
                layersData: [{ 
                    thickness: 15, 
                    color: "#c9b9b0", 
                    id: 2,
                    layerName: 'Paper',
                    layerDescription: 'Paper substrate',
                    grammage: '110 g/m²'
                }, 
                { 
                    thickness: 3, 
                    color: "#b9dfd9", 
                    id: 1,
                    layerName: 'Thermoplastic Starch',
                    layerDescription: 'Thermoplastic starch Adhesive Layer',
                    grammage: '6-8 g/m²'
                }]
            }
        ]
    },
    {
        id: "5",
        name: "Porsche",
        version: "Porsche v1",
        leadTeam: [
          {
            title: "TL",
            personName: "Gregor Dahl",
            initials: "GD"
          },
          {
            title: "PM",
            personName: "Lillian Huang",
            initials: "LH"
          }
        ],
        description: "Heat-sealable low-barrier sachet",
        status: {
          label: "delayed",
          value: "delayed"
        },
        stage: "5",
        application: "4-side sachets for dry foods",
        productImage: "ketchup.png",
        productBrief: "https://docs.google.com/presentation/d/1iTlzruj4t5RzM_zAV9a4bFUw89ptDu4iJXJkjTv_d_I/edit#slide=id.g252aafd1fff_0_13",
        targets: [
          {
            label: "OTR",
            value: "<1 cc/(m²*day) at 23℃ 50% RH"
          },
          {
            label: "WVTR",
            value: "<10 g/(m²*day) at 38℃ 90% RH"
          },
          {
            label: "Sealing type & strength",
            value: "Heat seal at 100℃ with seal strength >2.5N/15mm"
          },
          {
            label: "Recyclability",
            value: "CEPI v2 certifiable for standard paper mill recycling"
          },
          {
            label: "Biodegradability",
            value: "None"
          }
        ],
        productionTechniques: [
          {
            label: "Top coat",
            value: "Dispersion coating (Rotogravure)"
          },
          {
            label: "Primer",
            value: "Dispersion coating (Rotogravure)"
          }
        ],
        layersInfo: {
          img: "porsche_v1_layers.png",
          layers: [
            {
              id: 1,
              name: "CH BAR 3600",
              grammage: "10-12 g/m²",
              description: "Polyacrylate-based heat-seal and water vapour barrier layer",
              assets: null
            },
            {
              id: 2,
              name: "Hydrolac",
              grammage: "3-4 g/m²",
              description: "EVOH-based oxygen barrier layer",
              assets: null
            },
            {
              id: 3,
              name: "PackPro 7.0 80gsm",
              grammage: "80 g/m²",
              description: "Paper substrate",
              assets: null
            }
          ]
        },
        prototypesDesignsInfo: [
            {
                id: 1, 
                successScore: 0.78,
                layersData: [{ 
                    thickness: 15, 
                    color: "#c9b9b0", 
                    id: 4,
                    layerName: 'PackPro 7.0 80gsm',
                    layerDescription: 'Paper substrate',
                    grammage: '80 g/m²'
                }, 
                { 
                    thickness: 1, 
                    color: "#5fa2a5", 
                    id: 3,
                    layerName: 'Hydrolac',
                    layerDescription: 'EVOH-based oxygen barrier layer',
                    grammage: '3-4 g/m²'
                }, 
                { 
                    thickness: 4, 
                    color: "#b9dfd9", 
                    id: 2,
                    layerName: 'CH BAR 3600',
                    layerDescription: 'Polyacrylate-based heat-seal and water vapour barrier layer',
                    grammage: '10-12 g/m²'
                },]
            },
        ]
    },
    {
        id: "6",
        name: "BMW",
        version: "BMW v1",
        leadTeam: [
          {
            title: "TL",
            personName: "Gregor Dahl",
            initials: "GD"
          },
          {
            title: "PM",
            personName: "Yang Zhao",
            initials: "YZ"
          }
        ],
        description: "Heat-sealable translucent overwrapper",
        status: {
          label: "on time",
          value: "ok"
        },
        stage: "8",
        application: "Overwrapper for non-foods or secondary packaging for primary-packed foods",
        productImage: "",
        productBrief: "",
        targets: [
          {
            label: "OTR",
            value: "None"
          },
          {
            label: "WVTR",
            value: "None"
          },
          {
            label: "Sealing type & strength",
            value: "Heat seal at 120-180℃ with seal strength >2.5N/15mm"
          },
          {
            label: "Recyclability",
            value: "CEPI v2 certifiable for standard paper mill recycling"
          },
          {
            label: "Biodegradability",
            value: "None"
          }
        ],
        productionTechniques: [
          {
            label: "Top coat",
            value: "Extrusion coating"
          }
        ],
        layersInfo: {
          img: "bmw_v1_layers.png",
          layers: [
            {
              id: 1,
              name: "Bio-PE",
              grammage: "12-14 g/m²",
              description: "Bio-based polyethylene (PE) heat-seal layer",
              assets: [
                {
                  assetName: "Bio-PE",
                  assetLink: null
                }
              ]
            },
            {
              id: 2,
              name: "Translucent paper",
              grammage: "60 g/m²",
              description: "Paper substrate",
              assets: null
            }
          ]
        },
        prototypesDesignsInfo: [
            {
                id: 1, 
                successScore: 0.78,
                layersData: [{ 
                    thickness: 15, 
                    color: "#E7D6CC", 
                    id: 2,
                    layerName: 'Translucent paper',
                    layerDescription: 'Paper substrate',
                    grammage: '60 g/m²'
                }, 
                { 
                    thickness: 4, 
                    color: "#5fa2a5", 
                    id: 1,
                    layerName: 'Bio-PE',
                    layerDescription: 'Bio-based polyethylene (PE) heat-seal layer',
                    grammage: '12-14 g/m²'
                }, 
                ]
            },
        ]
    }
    
]