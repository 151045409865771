<template> 
    <div v-if="filesToShow.length && filesToShow.some(f => !f.node.deleted)" class="already-uploaded-files mt-3 px-2">
        <label v-if="showLabel">Files</label> 
        <template v-for="(file, index) in filesToShow" :key="index">
            <div v-if="!file.node.deleted" class="file-preview">
                <a :href="file.node.file.url" target="_blank" class="flex items-center break-all">
                    <DocumentCheckIcon class="icon thick icon-file" />
                    {{ file.node.file.name.replace('assets/', '') }}
                </a>
                <TrashIcon v-if="filesRemoval" class="icon icon-x-small shrink-0" @click="emit('filesToRemove', file.node)" title="Remove file" /> 
            </div>
        </template>
    </div> 
</template>
    
    
<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';
import { DocumentCheckIcon, TrashIcon } from '@heroicons/vue/24/outline';

defineProps(['filesToShow', 'filesRemoval', 'showLabel']);
const emit = defineEmits(['filesToRemove']);

</script>

<style lang="scss" scoped>
</style>