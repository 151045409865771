import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "action-icons" }


import SearchAsYouType from "@/components/SearchAsYouType.vue";
import RunAddNew from "@/tea/components/runs/RunAddNew.vue";
import { useStore } from "vuex";

export default /*@__PURE__*/_defineComponent({
  __name: 'RunHeaderActions',
  props: {"runGroupId":{type:Number}},
  emits: ["onSearchText", "onCreated"],
  setup(__props, { emit: __emit }) {

const store = useStore();

const props= __props
const emit = __emit;
store.commit("runCreateModule/SET_RUN_GROUP_ID", props.runGroupId);

const onSearchText = (searchValue: string) => emit("onSearchText", searchValue);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(RunAddNew, {
      onOnCreated: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onCreated')))
    }),
    _createVNode(SearchAsYouType, { onSearchText: onSearchText })
  ]))
}
}

})