<template>
    <div>
        <div v-if="showSmallIcons" class="flex items-center justify-center">
            <div class="grid grid-flow-col gap-6 p-6">
                <div v-if="showRegulations" @click="handleFormTypeSelection(AI_FORM_TYPE.Regulations)" class="border text-sm border-gray-200 hover:bg-gray-200 p-2 pb-1 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out cursor-pointer">
                    <ShieldCheckIcon class="icon icon-small mb-2 blue inline-block" /> Check Regulations
                </div>
                <div v-if="showProductBrief" @click="handleFormTypeSelection(AI_FORM_TYPE.ProductBrief)" class="border text-sm border-gray-200 hover:bg-gray-200 p-2 pb-1 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out cursor-pointer">
                    <ClipboardDocumentListIcon class="icon icon-small mb-2 inline-block" /> Product Recommender
                </div>
                <div v-if="showInnovation" @click="handleFormTypeSelection(AI_FORM_TYPE.Innovation)" class="border text-sm border-gray-200 hover:bg-gray-200 p-2 pb-1 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out cursor-pointer">
                    <LightBulbIcon class="icon icon-small mb-2 orange-light inline-block"/> Innovation
                </div>
            </div>
        </div>
        <div v-else class="flex items-center justify-center">      
            <div class="grid grid-flow-col gap-6 p-6">
                <div v-if="showRegulations" @click="handleFormTypeSelection(AI_FORM_TYPE.Regulations)" class="border border-gray-200 hover:bg-gray-200 p-6 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out cursor-pointer">
                    <ShieldCheckIcon class="icon icon-m mb-2 blue" />
                    <p class="text-gray-700">Check Regulations</p>
                </div>
                <div v-if="showProductBrief" @click="handleFormTypeSelection(AI_FORM_TYPE.ProductBrief)" class="border border-gray-200 hover:bg-gray-200 p-6 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out cursor-pointer">
                    <ClipboardDocumentListIcon class="icon icon-m mb-2" />
                    <p class="text-gray-700">Product Recommender</p>
                </div>
                <div v-if="showInnovation" @click="handleFormTypeSelection(AI_FORM_TYPE.Innovation)" class="border border-gray-200 hover:bg-gray-200 p-6 rounded-lg shadow-lg transform hover:scale-105 transition duration-300 ease-in-out cursor-pointer">
                    <LightBulbIcon class="icon icon-m mb-2 orange-light"/>
                    <p class="text-gray-700">Innovation</p>
                </div>
            </div>
        </div>
        <div>
            <RegulationsBox v-if="selectedFormType == AI_FORM_TYPE.Regulations" />
            <ProductBriefBox v-if="selectedFormType == AI_FORM_TYPE.ProductBrief" />
            <InnovationBox v-if="selectedFormType == AI_FORM_TYPE.Innovation" />
        </div>
    </div>
</template>
<script lang="ts" setup>
import {ref, defineProps} from 'vue';
import {ShieldCheckIcon, ClipboardDocumentListIcon, LightBulbIcon } from '@heroicons/vue/24/outline';
import RegulationsBox from "@/components/ai/RegulationsBox.vue";
import ProductBriefBox from "@/components/ai/ProductBriefBox.vue";
import InnovationBox from "@/components/ai/InnovationBox.vue";
import {AI_FORM_TYPE} from "@/models/aiModels";

const props = defineProps<{
    showRegulations?: boolean;
    showProductBrief?: boolean;
    showInnovation?: boolean;
    showSmallIcons?: boolean;
}>();
const selectedFormType = ref('null');

const handleFormTypeSelection = (value:AI_FORM_TYPE)=>{
    selectedFormType.value = value;
}

</script>