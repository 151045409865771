import gql from "graphql-tag";

export const RUNS_QUERY = gql`
  query GetRuns($runGroup:Int!,$first: Int, $after: String) {
    runs(runGroup:$runGroup,first: $first, after: $after) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edgeCount
      edges {
        node {
          id
          species
          compound
          place{name}
          landCapex
          residualBiomass
          extractionMethod{name}
          targetCompoundCapacity
          biomass
          costBiomass
          operatingDays
          extractionBufferConc
          targetCompoundPrice
          bycompoundXPrice
          finalMec
          finalCapex
          finalOpex
          npvYear
          
          createdAt
          createdBy {
            fullName
          }
        }
      }
    }
  }
`;

export const RUN_QUERY = gql`
  query GetRun($id: ID!) {
    run(id: $id) {
      id
      createdAt
      createdBy {
        id
        firstName
        lastName
        fullName
      }
      updatedAt
      updatedBy {
        id
        firstName
        lastName
        fullName
      }
      species
      compound
    }
  }
`;

export const RUN_ADD_FORM_VALUES = gql`
query GetRunAddFormValues {
    places {
        edges {
            node {
                id
                name
            }
        }
    }
    organisms {
        edges {
            node {
                id
                name
            }
        }
    }
    cultivationSystems {
        edges {
            node {
                id
                name
                species{id}
            }
        }
    }
    ocsGoodCombos {
        edges {
            node {
                id
                organism{id}
                cultivationSystem{id}
            }
        }
    }
    harvestingMethods {
        edges {
            node {
                id
                name
            }
        }
    }
    shmGoodCombos {
        edges {
            node {
                id
                species{id}
                harvestingMethod{id}
            }
        }
    }
    flocculants {
        edges {
            node {
                id
                name
            }
        }
    }
    extractionMethods {
        edges {
            node {
                id
                name
            }
        }
    }
    solvents {
        edges {
            node {
                id
                name
            }
        }
    }
    dryingMethods {
        edges {
            node {
                id
                name
            }
        }
    }
}`



export const RUN_SENSITIVITY_ADD_FORM_VALUES = gql`
query GetRunSensitivityAddFormValues($id:ID!) {
    runSensitivityPrices(id:$id){
        electricity
        water
        co2
    }
}`

export const RUN_DOUBLE_SENSITIVITY_ADD_FORM_VALUES = gql`
query GetRunDoubleSensitivityAddFormValues($id:ID!) {
    runDoubleSensitivityVariables(id:$id){
        variables
    }
}`