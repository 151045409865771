import { POSITION, useToast } from "vue-toastification";
import { RUN_CREATE_MUTATION } from "@/tea/graphql/mutations/runMutations";
import { useTeaMutation } from "@/tea/composables/apollo/teaUseMutation";
import { useTeaQuery } from "@/tea/composables/apollo/useTeaQuery";
import { RUNS_QUERY } from "@/tea/graphql/queries/runQueries";
import { RUN_DELETE_MUTATION,RUN_UPDATE_MUTATION } from "@/tea/graphql/mutations/runMutations";
import { useRawRunToStateType } from "@/tea/composables/runs/useRawRunToStateType";

const getDefaultState = () => {
  return {
    runGroupId:null,
    repo: {
      runs: [],
      totalCount: 0,
      edgeCount: 0,
      loading: true,
      pageInfo: null,
      error: null,
    },
    error: null,
  };
};

const state = getDefaultState();

const mutations = {
  SET_RUN_GROUP_ID(state,id){
    state.runGroupId=id;
  },
  SET_REPO_RUNS(state, runs) {
    state.repo.error = null;
    state.repo.runs = runs;
  },
  APPEND_REPO_RUNS(state, newRuns) {
    state.repo.runs = [...state.repo.runs, ...newRuns];
  },
  SET_REPO_TOTAL_COUNT(state, totalCount) {
    state.repo.totalCount = totalCount;
  },
  SET_REPO_EDGE_COUNT(state) {
    state.repo.edgeCount = state.repo.runs.length;
  },
  SET_REPO_PAGE_INFO(state, pageInfo) {
    state.repo.pageInfo = pageInfo;
  },
  SET_REPO_LOADING(state, loading) {
    state.repo.loading = loading;
  },
  SET_REPO_ERROR(state, message) {
    state.repo.error = message;
  },
};

const actions = {
  // LOAD RUNs
  async loadRuns({ state, commit }, loadMore = false) {
    commit("SET_REPO_LOADING", true);
    try {
      const { result, onResult, onError } = useTeaQuery(
        RUNS_QUERY,
        {
          runGroup: state.runGroupId,
          first: 20,
          after: loadMore && state.repo.pageInfo ? state.repo.pageInfo.endCursor : "",
        },
        { fetchPolicy: "network-only" }
      );

      // Handle if result is not already loaded from cache
      // if (!result.value || state.repo.runs.length == 0) {
      onResult((queryResult) => {
        if (queryResult.data && queryResult.data.runs) {
          const runs = queryResult.data.runs.edges.map((edge) => edge.node);
          if (loadMore) {
            commit("APPEND_REPO_RUNS", runs);
          } else {
            commit("SET_REPO_RUNS", runs);
          }
          commit("SET_REPO_PAGE_INFO", queryResult.data.runs.pageInfo);
          commit("SET_REPO_TOTAL_COUNT", queryResult.data.runs.totalCount);
          commit("SET_REPO_EDGE_COUNT");
          commit("SET_REPO_LOADING", false);
        } else {
          // Handle the case when the data is missing in the result
        }
      });
      onError((error) => {
        commit("SET_REPO_ERROR", error.message);
      });
      // }
    } catch (error) {
      commit("SET_REPO_ERROR", error.message);
    } finally {
      commit("SET_REPO_LOADING", false);
    }
  },

  //lOAD MORE RUN GROUPS
  async loadMoreRuns({ dispatch }) {
    dispatch("loadRuns", true);
  },
};

const getters = {
  repo(state) {
    return state.repo;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
