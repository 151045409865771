<template>
    <template v-if="benchmarkCriteria.length && !loading">
    <div class="flex table-container">
        <div class="flex flex-col sticky left-0 z-20 border-2 border-r-0 border-black">
            <p class="p-2 w-72 h-24 bg-white uppercase text-sm flex justify-center items-center">criteria</p>
            <template v-for="criteria in benchmarkCriteria" :key="criteria.criteria">
                <div class="flex flex-col p-2 w-72 h-14 border-r bg-white justify-center border-t">
                    <p class="uppercase text-sm">{{ criteria.criteria }}</p>
                    <p class="text-xs text-zinc-400 font-light capitalize">{{ criteria.category }}</p>
                </div>
            </template>
        </div>
        <div class="flex sticky left-72 z-20" v-if="targetValuesForBenchmark"> 
            <div class="flex flex-col w-56 border-2 border-black bg-pink divide-y divide-slate-200">
                <p class="w-54 h-24 uppercase text-sm flex justify-center items-center">Target Values</p>
                <div v-for="(target, index) in targetValuesForBenchmark.values" :key="index" class="flex justify-center items-center h-14">
                    <template v-if="target.criteria.category == 'Commercial Value' && editOn">
                        <input v-model="target.value" class="h-full w-full border-0 text-center" />
                    </template>
                    <template v-else>
                        <p v-if="target.value">{{ target.value }}</p>
                        <p v-else>-</p>
                    </template>
                </div>
            </div>
        </div>
        <div class="flex border-2 border-l-0 border-black">
            <div v-for="extPack in externalPackages" :key="extPack.name" class="flex flex-col divide-y divide-slate-200 w-64">
                <div class="flex flex-col p-2 h-24 border-r bg-white justify-center items-center">
                    <p class="uppercase text-sm text-center">{{ extPack.name }}</p>
                    <p class="text-xs text-zinc-400 font-light capitalize text-center break-all">{{ extPack.description }}</p>
                </div>
                <template v-for="value in extPack.values" :key="value.criteria.criteria">
                    <BenchmarkTableCel :cel-value="value" :on-edit="editOn" @color-change="(colour) => {value.colour = colour;}" @value-change="(newValue) => {value.value = newValue; }"/>
                </template>
            </div>   
        </div>
        <div class="flex border-2 border-l-0 border-black">
            <div v-for="intPack in internalPackages" :key="intPack.name" class="flex flex-col divide-y divide-slate-200 w-64">
                <div class="flex flex-col p-2 h-24 border-r bg-white justify-center items-center">
                    <p class="uppercase text-sm text-center">{{ intPack.name }}</p>
                    <p class="text-xs text-zinc-400 font-light capitalize text-center break-all">{{ intPack.description }}</p>
                </div>
                <template v-for="value in intPack.values" :key="value.criteria.criteria">
                    <BenchmarkTableCel :cel-value="value" :on-edit="editOn" @value-change="(newValue) => {value.value = newValue; }" @color-change="(colour) => {value.colour = colour; }" />
                </template>
            </div>
        </div>
    </div>
</template>
<template v-else>
    <div class="wip-page"><p>Sorry :( No values here</p><p class="wip-text">Please go back</p></div>
</template>
</template>
<script setup lang="ts">
import { useStore } from 'vuex';
import {computed, defineProps, onMounted, ref} from 'vue';
import { BenchmarkSolutionsInterface, StoreBenchmarkData } from '@/models/benchmarkModels';
import BenchmarkTableCel from '@/components/BenchmarkTableCel.vue';
import { useRoute } from 'vue-router';
import { useQuery } from '@vue/apollo-composable';
import { BENCHMARK_TABLE } from '@/grapql/userWorkspaceQueryMutations';

defineProps<{ editOn: boolean}>();
const store = useStore();
const route = useRoute();

const benchmarkData = computed<StoreBenchmarkData>(() => store.getters['programVersionDetailModule/getBenchmarkData']);
const benchmarkCriteria = computed(() =>  benchmarkData?.value?.benchmarkTable?.criteriaList ?? []);
const targetValuesForBenchmark = computed<BenchmarkSolutionsInterface>(() => benchmarkData?.value?.benchmarkTable?.targetValues ?? null)
const externalPackages = computed<BenchmarkSolutionsInterface[]>(() => benchmarkData?.value?.benchmarkTable?.externalPacks ?? [])
const internalPackages = computed<BenchmarkSolutionsInterface[]>(() => benchmarkData?.value?.benchmarkTable?.internalPacks ?? [])
const loading = ref(false);


onMounted(() =>  {
    const benchmarId = computed(() => store.getters['programVersionDetailModule/getBenchmarkId'])
    if(!benchmarId.value && route.params.title != 'create') {
        const tableId = route.params.title.toString().split('-').shift();
        const { onResult, loading } = useQuery(BENCHMARK_TABLE, {id: tableId });
        if (loading) {
            loading.value = true
        }
        onResult(async (result)=> {
            loading.value = false
            if (result?.data && result?.data?.userWorkspace) {
                store.commit('programVersionDetailModule/setBenchmarkValues', {id: result?.data?.userWorkspace.id, tableData: JSON.parse(result?.data?.userWorkspace.value), tableTitle: result?.data?.userWorkspace.title});
            }
        })
        
    }
})


</script>
<style lang="scss" scoped>
    .table-container {
        max-width: 90vw;
        // max-height: 80vh;
        overflow: auto;
        margin-top: 3rem;
        width: -moz-min-content;
        width: min-content;
    }
    input:focus {
        border: none
    }
    
</style>