import gql from "graphql-tag";

export const RUN_GROUP_CREATE_MUTATION = gql`
  mutation runGroupCreate($input: RunGroupCreateMutationInput!) {
    runGroupCreate(input: $input) {
      runGroup {
        id
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
        name
        description
        runs {
          pageInfo {
            endCursor
            startCursor
            hasNextPage
            hasPreviousPage
          }
          totalCount
          edgeCount
          edges {
            node {
              id
              species
            }
          }
        }
      }
    }
  }
`;

export const RUN_GROUP_UPDATE_MUTATION = gql`
  mutation runGroupUpdate($input: RunGroupUpdateMutationInput!) {
    runGroupUpdate(input: $input) {
      runGroup {
        id
      }
    }
  }
`;

export const RUN_GROUP_DELETE_MUTATION = gql`
  mutation runGroupDelete($input: RunGroupDeleteMutationInput!) {
    runGroupDelete(input: $input) {
      runGroup {
        id
      }
    }
  }
`;
