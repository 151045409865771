<template>
    <div class="page-padding">
        <TitleGoBackHeader title="product Playground" :show-add="true" />
        <div class="flex flex-col">
            <template v-for="brief in briefs" :key="brief.id">
                <div @click="goToDetail(brief.id)"
                    class="bg-white cursor-pointer flex items-center shadow-md justify-between border px-6 py-6 mb-6 rounded-lg">
                    <div class="flex flex-col w-4/5 justify-center">
                        <p class="uppercase font-semibold mb-2 text-gray-700">{{ brief.title }}</p>
                        <p class="text-sm text-gray-700">{{ brief.description }}</p>
                    </div>
                    <div :class="`image-container ${brief.imageColor}`">
                        <img :src="require(`@/assets/images/${brief.image}`)" class="max-w-16">
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script setup lang="ts">
import TitleGoBackHeader from '@/components/TitleGoBackHeader.vue';
import { BRIEFS_DATA } from '@/components/playgroundDemo/briefsData';
import router from '@/router';

const briefs = BRIEFS_DATA;
const goToDetail = (briefId: number) => router.push({ name: 'PlaygroundDetail', params: { id: briefId } });
</script>
<style lang="scss">
.image-container {
    position: relative;
    background: #faf8f6;
    border-radius: 50%;
    height: 120px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>