import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {PlusIcon } from "@heroicons/vue/24/outline";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import ModalSlots from "@/components/ModalSlots.vue";
import { NEW_RUN_GROUP_FIELDS } from "@/tea/models/runGroup";
import RunGroupAddNewForm from "@/tea/components/rungroups/RunGroupAddNewForm.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'RunGroupAddNew',
  emits: ["onCreated"],
  setup(__props, { emit: __emit }) {

const store = useStore();
const isOpen = ref(false);
const collapsed = computed(() => store.getters["authModule/isSidebarCollapsed"]);
const toggleSidebar = () => store.commit("authModule/toogleSidebar");
const closeModal = () => {
  isOpen.value = false;
  formSubmited.value = false;
};

const openModal = () => {
  if (!collapsed.value) {
    toggleSidebar();
  }
  isOpen.value = true;
};
const emit = __emit;
const formSubmited = ref(false);
const create = computed(() => store.getters["runGroupModule/create"]);

const valuesSubmitted = computed(() => create.value.runGroup);
const formValid = computed(() => NEW_RUN_GROUP_FIELDS.mandatoryFormFields.every((mf) => !!valuesSubmitted.value[mf]));
const errorFields = computed(() => NEW_RUN_GROUP_FIELDS.mandatoryFormFields.filter((mf) => !valuesSubmitted.value[mf]));
const queryError = computed(() => create.value.error);

const createNewRunGroup = async () => {
  formSubmited.value = true;
  if (formValid.value) {
    formSubmited.value = false;
    const mutation = NEW_RUN_GROUP_FIELDS.createMutation;
    await store
      .dispatch(mutation)
      .then(() => {
        emit("onCreated");
        closeModal();
      })
      .catch((error) => {
        // console.log(error);
      });
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["round-action-icon", { active: isOpen.value }])
    }, [
      _createVNode(_unref(PlusIcon), {
        class: "icon icon-small",
        onClick: openModal
      })
    ], 2),
    _createVNode(ModalSlots, {
      show: isOpen.value,
      onCloseModal: closeModal,
      onClickCreate: createNewRunGroup,
      dialogClass: "create-run-group-modal"
    }, {
      header: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("p", null, "Create new Run Group", -1)
      ])),
      body: _withCtx(() => [
        _createVNode(RunGroupAddNewForm, {
          "form-is-valid": formValid.value,
          "form-submited": formSubmited.value,
          "errors-fields": errorFields.value,
          "query-error": queryError.value
        }, null, 8, ["form-is-valid", "form-submited", "errors-fields", "query-error"])
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}
}

})