import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "icon-container warning"
}
const _hoisted_2 = {
  key: 1,
  class: "icon-container"
}


    import { XMarkIcon } from '@heroicons/vue/24/outline';
    import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
    import { TransitionRoot } from '@headlessui/vue'
    
export default /*@__PURE__*/_defineComponent({
  __name: 'FormErrorToast',
  props: {
    title: {},
    subtitle: {},
    isShowing: { type: Boolean },
    toastType: {}
  },
  setup(__props: any) {

    
    

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(TransitionRoot), {
    show: _ctx.isShowing,
    enter: "transition-opacity duration-75",
    "enter-from": "opacity-0",
    "enter-to": "opacity-100",
    leave: "transition-opacity duration-150",
    "leave-from": "opacity-100",
    "leave-to": "opacity-0",
    class: _normalizeClass(["error-toast", { warning: _ctx.toastType && _ctx.toastType === 'warning'}])
  }, {
    default: _withCtx(() => [
      (_ctx.toastType && _ctx.toastType === 'warning')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_unref(ExclamationTriangleIcon), { class: "icon icon-x-small icon-warning" })
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_unref(XMarkIcon), { class: "icon icon-x-small icon-error" })
          ])),
      _createElementVNode("div", {
        class: _normalizeClass(["error-content", { warning: _ctx.toastType && _ctx.toastType === 'warning'}])
      }, [
        _createElementVNode("p", null, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.subtitle), 1)
      ], 2)
    ]),
    _: 1
  }, 8, ["show", "class"]))
}
}

})