import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "info-box md:flex"
}
const _hoisted_2 = {
  key: 0,
  class: "card-content w-full md:w-2/3 px-4 mb-4"
}
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = {
  key: 1,
  class: "card-content md:w-1/3 px-4 mb-4"
}
const _hoisted_5 = { class: "card-title" }
const _hoisted_6 = {
  key: 0,
  class: "card-content w-full md:w-1/3 px-4 mb-4"
}
const _hoisted_7 = { class: "card-title" }
const _hoisted_8 = ["value", "onKeyup"]
const _hoisted_9 = {
  key: 1,
  class: "card-content w-full md:w-2/3 px-4 mb-4"
}
const _hoisted_10 = { class: "card-title" }
const _hoisted_11 = ["value", "onKeyup"]
const _hoisted_12 = {
  key: 2,
  class: "card-content w-full md:w-1/3 px-4 mb-4"
}
const _hoisted_13 = { class: "card-title" }

import { computed } from 'vue';
import { useStore } from 'vuex';
import { NEW_RUN_GROUP_FIELDS } from "@/tea/models/runGroup";

export default /*@__PURE__*/_defineComponent({
  __name: 'RunGroupDetailInfoContent',
  props: ['infoContent', 'editable'],
  setup(__props) {


const store = useStore();

const fields = computed(() => Object.values(NEW_RUN_GROUP_FIELDS.formFields).map(f => f.mutationValue));

const handleInputChange = (event: Event) => (event.target as HTMLInputElement).value;

const commitValue = (item) => {
    store.commit('runGroupModule/SET_UPDATE_SINGLE_RUN_GROUP_VALUE', {mutationValue: item.mutationValue, content: item.content })
}


return (_ctx: any,_cache: any) => {
  return (__props.infoContent)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.infoContent, (item) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: item.title
          }, [
            (!__props.editable && !!item.content)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (item.type === 'textarea')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createElementVNode("p", _hoisted_3, _toDisplayString(item.title), 1),
                        _createTextVNode(" " + _toDisplayString(item.content), 1)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("p", _hoisted_5, _toDisplayString(item.title), 1),
                        _createTextVNode(" " + _toDisplayString(item.content), 1)
                      ]))
                ], 64))
              : (__props.editable && ( !item.mutationValue || fields.value.includes(item.mutationValue) ))
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (item.editable && item.type === 'text')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("p", _hoisted_7, _toDisplayString(item.title), 1),
                          _createElementVNode("input", {
                            value: item.content,
                            class: "w-full",
                            type: "text",
                            onKeyup: ($event: any) => {item.content = handleInputChange($event); commitValue(item)}
                          }, null, 40, _hoisted_8)
                        ]))
                      : (item.editable && item.type === 'textarea')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            _createElementVNode("p", _hoisted_10, _toDisplayString(item.title), 1),
                            _createElementVNode("textarea", {
                              disabled: false,
                              value: item.content,
                              class: "w-full",
                              onKeyup: ($event: any) => {item.content = handleInputChange($event); commitValue(item)}
                            }, null, 40, _hoisted_11)
                          ]))
                        : (!!item.content)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                              _createElementVNode("p", _hoisted_13, _toDisplayString(item.title), 1),
                              _createTextVNode(" " + _toDisplayString(item.content), 1)
                            ]))
                          : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})