import gql from "graphql-tag";

export const PRODUCT_BRIEF_QUERY = gql`
  query getProductBrief($id: ID!) {
    productBrief(id: $id) {
      id
      product
      objective
      markets {
        edges {
          node {
            id
            name
            code
          }
        }
      }
      client {
        id
        nickname
        sdgs {
          edges {
            node {
              id
              name
            }
          }
        }
        sustainableClaims {
          edges {
            node {
              id
              name
              category
              className
            }
          }
        }
      }
      targets {
        edges {
          node {
            id
            targetProperty {
              id
              name
              className
              category
            }
            targetValue1
            targetValue2
            targetValueType {
              id
              name
              symbol
              description
            }
            targetValueUnit {
              id
              abbreviation
              name
            }
          }
        }
      }
      materials {
        edges {
          node
          {
            id
            material {
              id
              category
              className
              name
            }
            value1
            value2
            valueUnit {
              id
              name
              abbreviation
            }
            valueType {
              id
              name
              symbol
              description
            }
          }
        }
      }
    }
  }
`;