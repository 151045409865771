import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "fixed flex items-end right-8 top-8 z-50" }
const _hoisted_2 = { class: "action-icons" }

import {computed} from "vue";
import { ArrowUturnLeftIcon, ArrowPathIcon, StopCircleIcon } from '@heroicons/vue/24/outline';
import router from '@/router';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'AIChatHeader',
  setup(__props) {

const store = useStore();

const handleBack = () => {router.back();};
const handleReset = () => {store.commit("aiModule/resetStore");};
const handleStop = () => {store.dispatch("aiModule/stopSubscription");};


const isStarted = computed(()=> store.state.aiModule.isStarted);
const isSubscriptionActive = computed(()=> store.getters["aiModule/getIsSubscriptionActive"]);
const typing = computed(()=> store.state.aiModule.typing);

const isCompleted = computed(()=> store.getters["aiModule/getIsCompleted"]);

const isInProgress = computed(()=>{
    return (isSubscriptionActive.value || typing.value) && !isCompleted.value;
});
const showReset = computed(()=>{
    return (!isSubscriptionActive.value || !typing.value) && isStarted.value;

});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "round-action-icon",
        onClick: handleBack
      }, [
        _createVNode(_unref(ArrowUturnLeftIcon), { class: "icon icon-small" })
      ]),
      (showReset.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "round-action-icon",
            onClick: handleReset
          }, [
            _createVNode(_unref(ArrowPathIcon), { class: "icon icon-small" })
          ]))
        : _createCommentVNode("", true),
      (isInProgress.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "round-action-icon",
            onClick: handleStop
          }, [
            _createVNode(_unref(StopCircleIcon), { class: "icon icon-small heartbeat" })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})