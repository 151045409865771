export const NEW_ASSET_FIELDS = {
    PATENT: {
        graphqlValue: 'patent',
        formFileds: ['nickname', 'title', 'patentId', 'status', 'abstract', 'patentIds', 'isPrepatent','trl','properties','materials','tags','applicationTypes','standards', 'processingTechniques', 'url','links','notes','description','claims','sources','publicationDate','priorityDate','expiryDate','grantedDate','inventors','assignees','owners','pendingTerritories','grantedTerritories','expiredTerritories','assetPrice.quantity','assetPrice.unitType','assetPrice.price','assetPrice.unitTypeSize','assetPrice.notes'],
        mandatoryFormFields: ['nickname', 'title', 'patentId', 'status', 'abstract'],
        fieldsToQuery: ['properties','materials','tags','applicationTypes', 'sources', 'parties', 'countries', 'patentStatuses', 'standards', 'processingTechniques'],
        createMutation: 'assetCreationModule/patentCreate'
    },
    RESEARCH_PAPER: {
        graphqlValue: 'researchPaper',
        formFileds: ['nickname', 'doiId', 'title', 'journal', 'abstract', 'description', 'publicationDate', 'url', 'links', 'sources', 'applicationTypes', 'trl', 'tags', 'notes', 'materials', 'properties', 'authors', 'journal', 'standards', 'processingTechniques','assetPrice.quantity','assetPrice.unitType','assetPrice.price','assetPrice.unitTypeSize','assetPrice.notes'],
        mandatoryFormFields: ['nickname', 'title', 'abstract'],
        fieldsToQuery: ['sources', 'applicationTypes', 'tags', 'parties', 'materials', 'properties', 'standards', 'processingTechniques'],
        createMutation: 'assetCreationModule/researchPaperCreate'
    },
    RESEARCH_PROJECT: {
        graphqlValue: 'researchProject',
        formFileds: ['nickname', 'title', 'abstract', 'description', 'publicationDate', 'url', 'links', 'sources', 'applicationTypes', 'trl', 'tags', 'notes', 'materials', 'properties', 'owner', 'standards', 'processingTechniques','assetPrice.quantity','assetPrice.unitType','assetPrice.price','assetPrice.unitTypeSize','assetPrice.notes'],
        mandatoryFormFields: ['nickname', 'title', 'abstract', 'owner'],
        fieldsToQuery: ['sources', 'applicationTypes', 'tags', 'materials', 'parties', 'properties', 'standards', 'processingTechniques'],
        createMutation: 'assetCreationModule/researchProjectCreate'
    },
    OTHER: {
        graphqlValue: 'otherTechnology',
        formFileds: ['nickname', 'title', 'abstract', 'description', 'publicationDate', 'url', 'links', 'sources', 'applicationTypes', 'trl', 'tags', 'notes', 'materials', 'properties', 'owners', 'standards', 'processingTechniques', 'targetLayers', 'formTypes', 'otherTechnologyType', 'previouslyProcured','assetPrice.quantity','assetPrice.unitType','assetPrice.price','assetPrice.unitTypeSize','assetPrice.notes'],
        mandatoryFormFields: ['nickname', 'title', 'abstract', 'otherTechnologyType'],
        fieldsToQuery: ['sources', 'applicationTypes', 'tags', 'materials', 'properties', 'standards', 'processingTechniques', 'assetFormTypes', 'layerTypes', 'otherTechnologyTypes'],
        createMutation: 'assetCreationModule/otherTechnologyCreate'
    }
}

export interface formFieldsOptionsInteface {
    id: number;
    name: string;
}