import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-7 gap-2" }
const _hoisted_2 = {
  key: 0,
  class: "col-span-5 program-detail-card mb-4 relative"
}
const _hoisted_3 = {
  key: 0,
  class: "status-text wip"
}
const _hoisted_4 = {
  key: 1,
  class: "status-text archived"
}
const _hoisted_5 = { class: "cards-title ml-4 mt-10" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "mt-10 mb-4 px-2 grid grid-cols-9 items-center justify-around" }
const _hoisted_8 = { class: "col-span-2 flex flex-col justify-evenly pb-12 pt-4 items-end uppercase font-normal text-xs text-right" }
const _hoisted_9 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_10 = {
  key: 1,
  class: "mb-2 text-white"
}
const _hoisted_11 = {
  key: 0,
  class: "col-span-3 mt-4 justify-center flex"
}
const _hoisted_12 = { class: "col-span-4 flex flex-col justify-evenly pt-4 pb-12 text-sm" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = {
  key: 1,
  class: "text-white"
}
const _hoisted_15 = {
  key: 2,
  class: "inline-block text-black font-medium"
}
const _hoisted_16 = { class: "col-span-5 border-t" }
const _hoisted_17 = { class: "grid grid-flow-col divide-x" }
const _hoisted_18 = { class: "py-3 pl-6 flex flex-col" }
const _hoisted_19 = { class: "font-medium text-black" }
const _hoisted_20 = {
  key: 0,
  class: "py-3 pl-6 flex flex-col"
}
const _hoisted_21 = { class: "font-medium text-black" }
const _hoisted_22 = { class: "py-3 pl-6 flex flex-col" }
const _hoisted_23 = { class: "font-medium text-black" }
const _hoisted_24 = { class: "col-span-2 program-detail-card mb-4 mr-4" }
const _hoisted_25 = { class: "grid grid-rows-7 h-full" }
const _hoisted_26 = { class: "row-span-3 flex border-b" }
const _hoisted_27 = { class: "w-5/6 flex flex-col justify-center" }
const _hoisted_28 = { class: "flex flex-col items-center justify-center leading-3 py-1" }
const _hoisted_29 = { class: "text-black font-medium" }
const _hoisted_30 = { class: "flex flex-col items-center justify-center leading-3 py-1" }
const _hoisted_31 = { class: "text-black font-medium" }
const _hoisted_32 = { class: "flex flex-col items-center justify-center leading-3 py-1" }
const _hoisted_33 = { class: "text-black font-medium" }
const _hoisted_34 = { class: "flex flex-col items-center justify-center leading-3 py-1" }
const _hoisted_35 = { class: "text-black font-medium" }
const _hoisted_36 = { class: "flex flex-col items-center justify-center leading-3 py-1" }
const _hoisted_37 = { class: "text-black font-medium" }
const _hoisted_38 = { class: "flex flex-col items-center justify-center leading-3 py-1" }
const _hoisted_39 = { class: "text-black font-medium" }
const _hoisted_40 = { class: "row-span-3 flex border-b" }
const _hoisted_41 = { class: "w-5/6 flex flex-col justify-center" }
const _hoisted_42 = { class: "flex flex-col items-center justify-center leading-3 py-1" }
const _hoisted_43 = { class: "text-black font-medium" }
const _hoisted_44 = { class: "flex flex-col items-center justify-center leading-3 py-1" }
const _hoisted_45 = { class: "text-black font-medium" }
const _hoisted_46 = { class: "flex flex-col items-center justify-center leading-3 py-1" }
const _hoisted_47 = { class: "text-black font-medium" }
const _hoisted_48 = { class: "flex flex-col items-center justify-center leading-3 py-1" }
const _hoisted_49 = { class: "text-black font-medium" }
const _hoisted_50 = { class: "flex flex-col items-center justify-center leading-3 py-1" }
const _hoisted_51 = { class: "text-black font-medium" }
const _hoisted_52 = { class: "flex flex-col items-center justify-center leading-3 py-1" }
const _hoisted_53 = { class: "text-black font-medium" }
const _hoisted_54 = { class: "row-span-1 flex" }
const _hoisted_55 = { class: "w-5/6 flex flex-col justify-between" }
const _hoisted_56 = { class: "text-black font-medium" }

import { computed, ref } from "vue";
import LayersDesignGenerator from "./LayersDesignGenerator.vue";
import { layerDataInterface } from "@/models/programVersionsInterface";
import { useRouter } from "vue-router";
import { calcThickness } from "@/composables/useLayerThicknessToVisualThickness";
import { HasuraDesignsInterface, HasuraLayersInterface } from '@/models/hasuraModels';
import { useQuery } from "@vue/apollo-composable";
import { BENCHLINK_ASSET_QUERY } from "@/grapql/assetsQuery";



const hsConditions = "<= 0.5s - <= 180°C - 350-450 kPa";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramVersionDesignFromBenchling',
  props: {
    designData: {}
  },
  setup(__props: any) {

const props = __props;

const router = useRouter();

const parsedLayers = computed<HasuraLayersInterface[]>(() => props.designData && props.designData.layers != "" ? JSON.parse(props.designData.layers) : []);

const parsedUsefulLayers = computed<HasuraLayersInterface[]>(() => parsedLayers.value.filter(layer => layer.description != "NAN" && layer.gm_value != 'NAN')) 

const parsedLayersForText = computed(() => parsedLayers.value.toReversed());

const colors = ['#d4c7c1', '#76b3b6', '#eea39b', '#badfd9','#5fa2a6', '#2a87d3', '#7d91bc']

const parsedLayersData = computed<layerDataInterface[]>(() => parsedLayers.value.length ?
    parsedUsefulLayers.value.map((layer, index) => ({
        thickness: layer.gm_value == "nan" 
            ? 3
            : calcThickness(layer.gm_value),
        color: colors[layer.layer_order -1 ],
        id: layer.layer_order,
        position: parsedLayers.value.length - index,
    }))
    : []
);

const goToAsset = (assetNickname: string) => {
    const assetId = ref(null);
    const parsedNickname = assetNickname.includes('_') ? assetNickname.slice(0, assetNickname.indexOf('_')) : assetNickname;
    const {onResult} = useQuery(BENCHLINK_ASSET_QUERY, {filter: {nickname: {icontains: parsedNickname}}} )
    onResult(queryResults => {
        if(queryResults.data) {
            assetId.value = queryResults.data && queryResults.data.otherTechnologies.edges.length ? queryResults.data.otherTechnologies.edges[0].node.id : null;
            if (assetId.value) {
                router.push({ name: "TechnologyAssetDetail", params: { id: `other-${assetId.value}` }})
            }
        }
    }) 
};




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.designData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass(["status-dot", [_ctx.designData.locked_design == 'No' ? 'archived' : 'wip']])
          }, null, 2),
          (_ctx.designData.locked_design == 'Yes' )
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, "work in progress"))
            : (_ctx.designData.locked_design == 'No')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, "archived"))
              : _createCommentVNode("", true),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.designData.pro_mat_name), 1),
          (_ctx.designData.description)
            ? (_openBlock(), _createElementBlock("p", {
                key: 2,
                innerHTML: _ctx.designData.description,
                class: "px-4 text-xs -mt-2"
              }, null, 8, _hoisted_6))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(parsedLayersForText.value, (layer) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: layer.layer_order
                }, [
                  (layer.layer_type != 'NAN')
                    ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(layer.layer_type), 1))
                    : (_openBlock(), _createElementBlock("p", _hoisted_10, "."))
                ], 64))
              }), 128))
            ]),
            (parsedLayersData.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createVNode(LayersDesignGenerator, { "layers-data": parsedLayersData.value }, null, 8, ["layers-data"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_12, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(parsedLayersForText.value, (layer) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: layer.layer_order,
                  class: "mb-2 flex justify-between mr-8"
                }, [
                  (layer.description != 'NAN')
                    ? (_openBlock(), _createElementBlock("p", {
                        key: 0,
                        onClick: ($event: any) => (goToAsset(layer.description)),
                        class: "mr-2 inline-block font-medium cursor-pointer text-black underline underline-offset-2"
                      }, _toDisplayString(layer.description), 9, _hoisted_13))
                    : (_openBlock(), _createElementBlock("p", _hoisted_14, ".")),
                  (layer.gm_value != 'nan')
                    ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(layer.gm_value) + " gsm", 1))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.designData.paper_content) + " %", 1),
                _cache[0] || (_cache[0] = _createElementVNode("p", { class: "uppercase font-normal text-xs" }, "Paper content", -1))
              ]),
              (_ctx.designData.sealing_sides)
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.designData.sealing_sides), 1),
                    _cache[1] || (_cache[1] = _createElementVNode("p", { class: "uppercase font-normal text-xs" }, "sealing sides", -1))
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("p", _hoisted_23, _toDisplayString(Number(_ctx.designData.total_grammage).toFixed(2)), 1),
                _cache[2] || (_cache[2] = _createElementVNode("p", { class: "uppercase font-normal text-xs" }, "total grammage", -1))
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_24, [
      _createElementVNode("div", _hoisted_25, [
        _createElementVNode("div", _hoisted_26, [
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "w-1/6 flex justify-center items-center border-r border-dashed" }, [
            _createElementVNode("p", { class: "-rotate-90 font-medium text-black font-medium text-black" }, "OTR")
          ], -1)),
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("div", {
              class: _normalizeClass([{'hidden': !_ctx.designData.min_otr_23_0}, "flex justify-around"])
            }, [
              _createElementVNode("div", _hoisted_28, [
                _cache[3] || (_cache[3] = _createElementVNode("p", { class: "lowercase text-xs text-gray-400" }, "MIN", -1)),
                _createElementVNode("p", _hoisted_29, _toDisplayString(Number(_ctx.designData.min_otr_23_0).toFixed(1)), 1)
              ]),
              _createElementVNode("div", _hoisted_30, [
                _cache[4] || (_cache[4] = _createElementVNode("p", { class: "lowercase text-xs text-gray-400" }, "max", -1)),
                _createElementVNode("p", _hoisted_31, _toDisplayString(Number(_ctx.designData.max_otr_23_0).toFixed(1)), 1)
              ])
            ], 2),
            _createElementVNode("p", {
              class: _normalizeClass([{'hidden': !_ctx.designData.min_otr_23_0}, "text-xs border-y text-center text-black"])
            }, "T: 23°C - RH: 0%", 2),
            _createElementVNode("div", {
              class: _normalizeClass([{'hidden': !_ctx.designData.min_otr_23_50}, "flex justify-around"])
            }, [
              _createElementVNode("div", _hoisted_32, [
                _cache[5] || (_cache[5] = _createElementVNode("p", { class: "lowercase text-xs text-gray-400" }, "MIN", -1)),
                _createElementVNode("p", _hoisted_33, _toDisplayString(Number(_ctx.designData.min_otr_23_50).toFixed(1)), 1)
              ]),
              _createElementVNode("div", _hoisted_34, [
                _cache[6] || (_cache[6] = _createElementVNode("p", { class: "lowercase text-xs text-gray-400" }, "max", -1)),
                _createElementVNode("p", _hoisted_35, _toDisplayString(Number(_ctx.designData.max_otr_23_50).toFixed(1)), 1)
              ])
            ], 2),
            _createElementVNode("p", {
              class: _normalizeClass([{'hidden': !_ctx.designData.min_otr_23_50}, "text-xs border-y text-center"])
            }, "T: 23°C - RH: 50%", 2),
            _createElementVNode("div", {
              class: _normalizeClass([{'hidden': !_ctx.designData.min_otr_38_90}, "flex justify-around"])
            }, [
              _createElementVNode("div", _hoisted_36, [
                _cache[7] || (_cache[7] = _createElementVNode("p", { class: "lowercase text-xs text-gray-400" }, "MIN", -1)),
                _createElementVNode("p", _hoisted_37, _toDisplayString(Number(_ctx.designData.min_otr_38_90).toFixed(1)), 1)
              ]),
              _createElementVNode("div", _hoisted_38, [
                _cache[8] || (_cache[8] = _createElementVNode("p", { class: "lowercase text-xs text-gray-400" }, "max", -1)),
                _createElementVNode("p", _hoisted_39, _toDisplayString(Number(_ctx.designData.max_otr_38_90).toFixed(1)), 1)
              ])
            ], 2),
            _createElementVNode("p", {
              class: _normalizeClass([{'hidden': !_ctx.designData.min_otr_38_90}, "text-xs border-t border-b-0 text-center"])
            }, "T: 38°C - RH: 90%", 2)
          ])
        ]),
        _createElementVNode("div", _hoisted_40, [
          _cache[16] || (_cache[16] = _createElementVNode("div", { class: "w-1/6 flex justify-center items-center border-r border-dashed" }, [
            _createElementVNode("p", { class: "-rotate-90 font-medium text-black" }, "WVTR")
          ], -1)),
          _createElementVNode("div", _hoisted_41, [
            _createElementVNode("div", {
              class: _normalizeClass([{'hidden': !_ctx.designData.min_wvtr_23_50}, "flex justify-around"])
            }, [
              _createElementVNode("div", _hoisted_42, [
                _cache[10] || (_cache[10] = _createElementVNode("p", { class: "lowercase text-xs text-gray-400" }, "MIN", -1)),
                _createElementVNode("p", _hoisted_43, _toDisplayString(Number(_ctx.designData.min_wvtr_23_50).toFixed(1)), 1)
              ]),
              _createElementVNode("div", _hoisted_44, [
                _cache[11] || (_cache[11] = _createElementVNode("p", { class: "lowercase text-xs text-gray-400" }, "max", -1)),
                _createElementVNode("p", _hoisted_45, _toDisplayString(Number(_ctx.designData.max_wvtr_23_50).toFixed(1)), 1)
              ])
            ], 2),
            _createElementVNode("p", {
              class: _normalizeClass([{'hidden': !_ctx.designData.min_wvtr_23_50}, "text-xs border-y text-center"])
            }, "T: 23°C - RH: 50%", 2),
            _createElementVNode("div", {
              class: _normalizeClass([{'hidden': !_ctx.designData.min_wvtr_23_85}, "flex justify-around"])
            }, [
              _createElementVNode("div", _hoisted_46, [
                _cache[12] || (_cache[12] = _createElementVNode("p", { class: "lowercase text-xs text-gray-400" }, "MIN", -1)),
                _createElementVNode("p", _hoisted_47, _toDisplayString(Number(_ctx.designData.min_wvtr_23_85).toFixed(1)), 1)
              ]),
              _createElementVNode("div", _hoisted_48, [
                _cache[13] || (_cache[13] = _createElementVNode("p", { class: "lowercase text-xs text-gray-400" }, "max", -1)),
                _createElementVNode("p", _hoisted_49, _toDisplayString(Number(_ctx.designData.max_wvtr_23_85).toFixed(1)), 1)
              ])
            ], 2),
            _createElementVNode("p", {
              class: _normalizeClass([{'hidden': !_ctx.designData.min_wvtr_23_85}, "text-xs border-y text-center"])
            }, "T: 23°C - RH: 85%", 2),
            _createElementVNode("div", {
              class: _normalizeClass([{'hidden': !_ctx.designData.min_wvtr_38_90}, "flex justify-around"])
            }, [
              _createElementVNode("div", _hoisted_50, [
                _cache[14] || (_cache[14] = _createElementVNode("p", { class: "lowercase text-xs text-gray-400" }, "MIN", -1)),
                _createElementVNode("p", _hoisted_51, _toDisplayString(Number(_ctx.designData.min_wvtr_38_90).toFixed(1)), 1)
              ]),
              _createElementVNode("div", _hoisted_52, [
                _cache[15] || (_cache[15] = _createElementVNode("p", { class: "lowercase text-xs text-gray-400" }, "max", -1)),
                _createElementVNode("p", _hoisted_53, _toDisplayString(Number(_ctx.designData.max_wvtr_38_90).toFixed(1)), 1)
              ])
            ], 2),
            _createElementVNode("p", {
              class: _normalizeClass([{'hidden': !_ctx.designData.min_wvtr_38_90}, "text-xs border-y text-center"])
            }, "T: 38°C - RH: 90%", 2)
          ])
        ]),
        _createElementVNode("div", _hoisted_54, [
          _cache[17] || (_cache[17] = _createElementVNode("div", { class: "w-1/6 flex justify-center items-center border-r border-dashed" }, [
            _createElementVNode("p", { class: "-rotate-90 font-medium text-black" }, "HS")
          ], -1)),
          _createElementVNode("div", _hoisted_55, [
            _createElementVNode("div", {
              class: _normalizeClass([{'hidden': !_ctx.designData.hs_max_tech_reps}, "flex flex-col items-center justify-center h-full py-1"])
            }, [
              _createElementVNode("p", _hoisted_56, _toDisplayString(Number(_ctx.designData.hs_max_tech_reps).toFixed(1)), 1)
            ], 2),
            _createElementVNode("p", {
              class: _normalizeClass([{'hidden': !_ctx.designData.hs_max_tech_reps}, "text-xs border-t text-center uppercase"])
            }, _toDisplayString(hsConditions), 2)
          ])
        ])
      ])
    ])
  ]))
}
}

})