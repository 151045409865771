import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-padding" }

import TechnologyAssetsComparisonTableHeader from '@/components/TechnologyAssetsComparisonTableHeader.vue';
import TechnologyAssetsComparisonTable from '@/components/TechnologyAssetsComparisonTable.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'TechnologyAssetsComparisonView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(TechnologyAssetsComparisonTableHeader, { title: "Comparison table" }),
    _createVNode(TechnologyAssetsComparisonTable)
  ]))
}
}

})