<template>
  <div class="col-span-4">
    <div class="grid grid-cols-4 gap-y-4 gap-x-4">
      <!-- extraction method -->
      <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('extractionMethod') && formSubmited }">
        <label>Which kind of extraction method are you going to use? *</label>
        <select class="w-full" v-model="formFields['extractionMethod'].value">
          <option selected :value="null"></option>
          <option
            v-for="extractionMethod in formInizializationValues['extractionMethodsOptions']"
            :value="Number(extractionMethod.id)"
            :key="extractionMethod.id"
          >
            {{ extractionMethod.name }}
          </option>
        </select>
      </div>

      <div v-if="isExtractionMethodPhysical" class="col-span-4">
        <!-- ###if residual biomass or mechanical harvesting is used extraction buffer is required for physical extraction -->
        <div v-if="fields.residualBiomass || isHarvestingMethodMechanical || isSystemSSF">
          <div class="col-span-4 mb-2" :class="{ 'error-form-field': errorsFields.includes('extractionBufferConc') && formSubmited }">
            <label>Concentration extraction buffer (L/kg biomass) *</label>
            <input class="w-full" type="number" v-model="formFields['extractionBufferConc'].value" />
            <span class="text-xs text-gray-400"
              >Note: if you chose Solid-state fermentor (SSF) as cultivation system the term 'biomass' stands for 'substrate'</span
            >
          </div>
          <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('extractionBufferPrice') && formSubmited }">
            <label>Price extraction buffer (€/L) *</label>
            <input class="w-full" type="number" v-model="formFields['extractionBufferPrice'].value" />
          </div>
        </div>
      </div>
      <div v-else-if="isExtractionMethodChemical || isExtractionMethodBoth" class="col-span-4">
        <div v-if="isExtractionMethodBoth" class="col-span-4">
          <!-- ###add extBuffer_conc and extBuffer_price in case it is used -->
          <RadioGroup
            v-model="formFields['extractionBuffer'].value"
            class="col-span-4"
            :class="{ 'error-form-field': errorsFields.includes('extractionBuffer') && formSubmited }"
          >
            <RadioGroupLabel class="mb-1">Are you going to use an extraction buffer? *</RadioGroupLabel>
            <div class="flex w-full items-center justify-stretch">
              <RadioGroupOption as="template" v-for="yesNo in yesNos" :key="yesNo.value" :value="yesNo.key" v-slot="{ active, checked }">
                <div
                  :class="[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white ']"
                  class="relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"
                >
                  <div class="flex w-full items-center justify-between">
                    <div class="flex items-center">
                      <div class="text-sm">
                        <RadioGroupLabel as="p" :class="checked ? 'text-white' : 'text-gray-500'" class="font-base">
                          {{ yesNo.value }}
                        </RadioGroupLabel>
                      </div>
                    </div>
                    <div v-show="checked" class="shrink-0 text-white items-right">
                      <svg class="h-5 w-5" viewBox="0 0 24 24" fill="none">
                        <circle cx="12" cy="12" r="12" fill="#fff" fill-opacity="0.2" />
                        <path d="M7 13l3 3 7-7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </div>
                  </div>
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>
          <div v-if="fields.extractionBuffer" class="col-span-4 mt-4">
            <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('extractionBufferConc') && formSubmited }">
              <label>Concentration extraction buffer (L/kg biomass) *</label>
              <input class="w-full" type="number" v-model="formFields['extractionBufferConc'].value" />
              <span class="text-xs text-gray-400"
                >Note: if you chose Solid-state fermentor (SSF) as cultivation system the term 'biomass' stands for 'substrate'</span
              >
            </div>
            <div class="col-span-4 mt-2" :class="{ 'error-form-field': errorsFields.includes('extractionBufferPrice') && formSubmited }">
              <label>Price extraction buffer (€/L) *</label>
              <input class="w-full" type="number" v-model="formFields['extractionBufferPrice'].value" />
            </div>
          </div>
        </div>

        <RadioGroup
          v-model="formFields['solvent'].value"
          class="col-span-4 mb-0 mt-4"
          :class="{ 'error-form-field': errorsFields.includes('solvent') && formSubmited }"
        >
          <RadioGroupLabel class="mb-1">Solvent *</RadioGroupLabel>
          <div class="flex w-full items-center justify-stretch">
            <RadioGroupOption
              as="template"
              v-for="solvent in formInizializationValues['solventsOptions']"
              :key="solvent.id"
              :value="solvent.id"
              v-slot="{ active, checked }"
            >
              <div
                :class="[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white ']"
                class="relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"
              >
                <div class="flex w-full items-center justify-between">
                  <div class="flex items-center">
                    <div class="text-sm">
                      <RadioGroupLabel as="p" :class="checked ? 'text-white' : 'text-gray-500'" class="font-base">
                        {{ solvent.name }}
                      </RadioGroupLabel>
                    </div>
                  </div>
                  <div v-show="checked" class="shrink-0 text-white items-right">
                    <svg class="h-5 w-5" viewBox="0 0 24 24" fill="none">
                      <circle cx="12" cy="12" r="12" fill="#fff" fill-opacity="0.2" />
                      <path d="M7 13l3 3 7-7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>

        <div v-if="fields.solvent !== null" class="col-span-4 flex border border-gray-100 border-solid p-2 pt-3 pb-2 -mt-3 mb-4 bg-gray-50">
          <div v-if="isSolventAcetone" class="grow grid grid-cols-1 gap-y-4 gap">
            <p class="text-xs text-gray-500 pt-3 block">
              <LightBulbIcon class="icon icon-small inline-block" /> The reference price of acetone is 0.45 €/L. If this reference does not correspond please
              select "Another solvent"
            </p>
          </div>
          <div v-else-if="isSolventEthanol" class="grow grid grid-cols-1 gap-y-4 gap-x-4">
            <p class="text-xs text-gray-500 pt-3 block">
              <LightBulbIcon class="icon icon-small inline-block" /> The reference price of ethanol is 0.55 €/L. If this reference does not correspond please
              select "Another solvent"
            </p>
          </div>
          <div v-else-if="isSolventAnotherSolvent" class="grow grid grid-cols-1 gap-y-4 gap-x-4 pt-2">
            <div class="col-span-1" :class="{ 'error-form-field': errorsFields.includes('otherSolventPrice') && formSubmited }">
              <label>Price solvent (€/L) *</label>
              <input class="w-full" type="number" v-model="formFields['otherSolventPrice'].value" />
            </div>
          </div>
        </div>
        <div class="col-span-1 mb-4" :class="{ 'error-form-field': errorsFields.includes('solventConc') && formSubmited }">
          <label>Solvent concentration (L/kg biomass) *</label>
          <input class="w-full" type="number" v-model="formFields['solventConc'].value" />
          <span class="text-xs text-gray-400"
            >Note: if you chose Solid-state fermentor (SSF) as cultivation system the term 'biomass' stands for 'substrate'</span
          >
        </div>

        <!-- ########additional solvents########   -->
        <RadioGroup
          v-model="formFields['moreSolvents'].value"
          class="col-span-4 mb-0 mt-2"
          :class="{ 'error-form-field': errorsFields.includes('moreSolvents') && formSubmited }"
        >
          <RadioGroupLabel class="mb-1">How many additional solvents are you going to use? *</RadioGroupLabel>
          <div class="flex w-full items-center justify-stretch">
            <RadioGroupOption as="template" v-for="solvent in moreSolvents" :key="solvent" :value="solvent" v-slot="{ active, checked }">
              <div
                :class="[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white ']"
                class="relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"
              >
                <div class="flex w-full items-center justify-between">
                  <div class="flex items-center">
                    <div class="text-sm">
                      <RadioGroupLabel as="p" :class="checked ? 'text-white' : 'text-gray-500'" class="font-base">
                        {{ solvent }}
                      </RadioGroupLabel>
                    </div>
                  </div>
                  <div v-show="checked" class="shrink-0 text-white items-right">
                    <svg class="h-5 w-5" viewBox="0 0 24 24" fill="none">
                      <circle cx="12" cy="12" r="12" fill="#fff" fill-opacity="0.2" />
                      <path d="M7 13l3 3 7-7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>
        <div v-if="showmoreSolventX" class="col-span-4 flex border border-gray-100 border-solid p-1 pt-5 pb-1 -mt-3 bg-gray-50">
          <div v-if="showmoreSolventX" class="grow grid grid-cols-1 gap-y-4 gap-x-4">
            <div class="col-span-1" :class="{ 'error-form-field': errorsFields.includes('moreSolventXConc') && formSubmited }">
              <label>Solvent #2 concentration (L/kg biomass) *</label>
              <input class="w-full" type="number" v-model="formFields['moreSolventXConc'].value" />
            </div>
            <div class="col-span-1" :class="{ 'error-form-field': errorsFields.includes('moreSolventXPrice') && formSubmited }">
              <label>Price solvent #2 (€/L) *</label>
              <input class="w-full" type="number" v-model="formFields['moreSolventXPrice'].value" />
            </div>
          </div>
          <div v-if="showmoreSolventY" class="grow grid grid-cols-1 gap-y-4 gap-x-4 ml-4">
            <div class="col-span-1" :class="{ 'error-form-field': errorsFields.includes('moreSolventYConc') && formSubmited }">
              <label>Solvent #3 concentration (L/kg biomass) *</label>
              <input class="w-full" type="number" v-model="formFields['moreSolventYConc'].value" />
            </div>
            <div class="col-span-1" :class="{ 'error-form-field': errorsFields.includes('moreSolventYPrice') && formSubmited }">
              <label>Price solvent #3 (€/L) *</label>
              <input class="w-full" type="number" v-model="formFields['moreSolventYPrice'].value" />
            </div>
          </div>
        </div>
      </div>

      <!-- drying method -->
      <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('dryMethod') && formSubmited }">
        <label>Which kind of drying method are you going to use? *</label>
        <select class="w-full" v-model="formFields['dryMethod'].value">
          <option selected :value="null"></option>
          <option v-for="dryMethod in formInizializationValues['dryingMethodsOptions']" :value="Number(dryMethod.id)" :key="dryMethod.id">
            {{ dryMethod.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, defineProps } from "vue";
import { useFormFieldsCreationNewRun } from "@/tea/composables/runs/useFormFieldsCreationNewRun";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { useStore } from "vuex";
import { NEW_RUN_FIELDS } from "@/tea/models/run";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import { LightBulbIcon } from "@heroicons/vue/24/outline";
const yesNos = [
  { key: true, value: "Yes" },
  { key: false, value: "No" },
];
const moreSolvents = [0, 1, 2];

const store = useStore();

const props = defineProps(["errorsFields", "formSubmited", "formInizializationValues"]);

const fields = computed(() => store.getters["runCreateModule/fields"]);

const isExtractionMethodPhysical = computed(() => {
  const emId = fields.value.extractionMethod;
  if (props.formInizializationValues["extractionMethodsOptions"] && emId != null) {
    if (props.formInizializationValues["extractionMethodsOptions"].find((item) => item.name == "Physical").id == emId) {
      return true;
    } else {
      return false;
    }
  }
  return null;
});
const isExtractionMethodChemical = computed(() => {
  const emId = fields.value.extractionMethod;
  if (props.formInizializationValues["extractionMethodsOptions"] && emId != null) {
    if (props.formInizializationValues["extractionMethodsOptions"].find((item) => item.name == "Chemical").id == emId) {
      return true;
    } else {
      return false;
    }
  }
  return null;
});
const isExtractionMethodBoth = computed(() => {
  const emId = fields.value.extractionMethod;
  if (props.formInizializationValues["extractionMethodsOptions"] && emId != null) {
    if (props.formInizializationValues["extractionMethodsOptions"].find((item) => item.name == "Both").id == emId) {
      return true;
    } else {
      return false;
    }
  }
  return null;
});

const isHarvestingMethodMechanical = computed(() => {
  const fId = fields.value.harvestMethod;
  if (props.formInizializationValues["harvestingMethodsOptions"] && fId != null) {
    if (props.formInizializationValues["harvestingMethodsOptions"].find((item) => item.name == "Mechanical").id == fId) {
      return true;
    } else {
      return false;
    }
  }
  return null;
});

const isSystemSSF = computed(() => {
  const csId = fields.value.cultivationSystem;
  if (props.formInizializationValues["cultivationSystemsOptions"] && csId != null) {
    if (props.formInizializationValues["cultivationSystemsOptions"].find((item) => item.name == "Solid-state fermentor (SSF)").id == csId) {
      return true;
    } else {
      // set biomass_target to 'Target compound'
      return false;
    }
  }
  return null;
});

const isSolventAcetone = computed(() => {
  const sId = fields.value.solvent;
  if (props.formInizializationValues["solventsOptions"] && sId != null) {
    if (props.formInizializationValues["solventsOptions"].find((item) => item.name == "Acetone").id == sId) {
      return true;
    } else {
      return false;
    }
  }
  return null;
});
const isSolventEthanol = computed(() => {
  const sId = fields.value.solvent;
  if (props.formInizializationValues["solventsOptions"] && sId != null) {
    if (props.formInizializationValues["solventsOptions"].find((item) => item.name == "Ethanol").id == sId) {
      return true;
    } else {
      return false;
    }
  }
  return null;
});
const isSolventAnotherSolvent = computed(() => {
  const sId = fields.value.solvent;
  if (props.formInizializationValues["solventsOptions"] && sId != null) {
    if (props.formInizializationValues["solventsOptions"].find((item) => item.name == "Another solvent").id == sId) {
      return true;
    } else {
      return false;
    }
  }
  return null;
});

const showmoreSolventX = computed(() => {
  const ms = Number(fields.value.moreSolvents);
  if (props.formInizializationValues["solventsOptions"] && ms > 0) {
    return true;
  }
  return false;
});
const showmoreSolventY = computed(() => {
  const ms = Number(fields.value.moreSolvents);
  if (props.formInizializationValues["solventsOptions"] && ms == 2) {
    return true;
  }
  return false;
});

const getFormFields = Object.keys(NEW_RUN_FIELDS.formFields).map((key) => {
  const fieldConfig = NEW_RUN_FIELDS.formFields[key];
  return {
    [key]: Array.isArray(fieldConfig.onChangeResetFields) ? fieldConfig.onChangeResetFields : [],
  };
});
const formFieldOptions = {
  fields: getFormFields,
  mutation: "runCreateModule/SET_SINGLE_RUN_VALUE",
};
const formFields = useFormFieldsCreationNewRun(formFieldOptions);
</script>
