import gql from "graphql-tag";

export const RUN_GROUPS_QUERY = gql`
  query GetRunGroups($first: Int, $after: String) {
    runGroups(first: $first, after: $after) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edgeCount
      edges {
        node {
          id
          name
          description
          createdAt
          createdBy {
            fullName
          }
        }
      }
    }
  }
`;

export const RUN_GROUP_QUERY = gql`
  query GetRunGroup($id: ID!) {
    runGroup(id: $id) {
      id
      createdAt
      createdBy {
        id
        firstName
        lastName
        fullName
      }
      updatedAt
      updatedBy {
        id
        firstName
        lastName
        fullName
      }
      name
      description
      runs {
        pageInfo {
          endCursor
          startCursor
          hasNextPage
          hasPreviousPage
        }
        totalCount
        edgeCount
        edges {
          node {
            id
            species
          }
        }
      }
    }
  }
`;
