import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-7 gap-2 mt-4" }
const _hoisted_2 = { class: "col-span-5 program-detail-card mb-4 relative" }
const _hoisted_3 = { class: "cards-title ml-4 mt-10" }
const _hoisted_4 = { class: "mb-4 px-2 grid grid-cols-9 items-center justify-around" }
const _hoisted_5 = { class: "col-span-9 mt-4 justify-center flex" }
const _hoisted_6 = {
  class: "layers",
  viewBox: "-100 -45 350 120",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_7 = ["d", "fill", "filter"]
const _hoisted_8 = ["d"]
const _hoisted_9 = ["d"]
const _hoisted_10 = ["y1", "y2"]
const _hoisted_11 = ["y"]
const _hoisted_12 = ["y"]
const _hoisted_13 = ["y1", "y2"]
const _hoisted_14 = ["href"]
const _hoisted_15 = ["y"]
const _hoisted_16 = { class: "col-span-5 border-t" }
const _hoisted_17 = { class: "grid grid-flow-col divide-x" }
const _hoisted_18 = { class: "py-3 pl-6 flex flex-col" }
const _hoisted_19 = { class: "font-medium text-black" }
const _hoisted_20 = {
  key: 0,
  class: "py-3 pl-6 flex flex-col"
}
const _hoisted_21 = { class: "py-3 pl-6 flex flex-col" }
const _hoisted_22 = { class: "font-medium text-black" }
const _hoisted_23 = { class: "col-span-2 program-detail-card mb-4" }
const _hoisted_24 = { class: "grid grid-rows-7 h-full" }
const _hoisted_25 = {
  key: 0,
  class: "row-span-3 flex border-b"
}
const _hoisted_26 = { class: "w-5/6 flex flex-col justify-center" }
const _hoisted_27 = { class: "flex justify-around" }
const _hoisted_28 = { class: "flex flex-col items-center justify-center leading-3 py-1" }
const _hoisted_29 = { class: "text-black font-medium" }
const _hoisted_30 = { class: "flex flex-col items-center justify-center leading-3 py-1" }
const _hoisted_31 = { class: "text-black font-medium" }
const _hoisted_32 = { class: "text-xs border-y text-center text-black" }
const _hoisted_33 = {
  key: 1,
  class: "row-span-3 flex border-b"
}
const _hoisted_34 = { class: "w-5/6 flex flex-col justify-center" }
const _hoisted_35 = { class: "flex justify-around" }
const _hoisted_36 = { class: "flex flex-col items-center justify-center leading-3 py-1" }
const _hoisted_37 = { class: "text-black font-medium" }
const _hoisted_38 = { class: "flex flex-col items-center justify-center leading-3 py-1" }
const _hoisted_39 = { class: "text-black font-medium" }
const _hoisted_40 = { class: "text-xs border-y text-center text-black" }
const _hoisted_41 = {
  key: 2,
  class: "row-span-3 flex border-b"
}
const _hoisted_42 = { class: "w-5/6 flex flex-col justify-between" }
const _hoisted_43 = { class: "flex justify-around" }
const _hoisted_44 = { class: "flex flex-col items-center justify-center leading-3 py-1" }
const _hoisted_45 = { class: "text-black font-medium" }
const _hoisted_46 = { class: "flex flex-col items-center justify-center leading-3 py-1" }
const _hoisted_47 = { class: "text-black font-medium" }
const _hoisted_48 = { class: "text-xs border-y text-center text-black" }
const _hoisted_49 = {
  key: 3,
  class: "row-span-3 flex"
}
const _hoisted_50 = { class: "w-5/6 flex flex-col justify-between" }
const _hoisted_51 = { class: "flex justify-around" }
const _hoisted_52 = { class: "flex flex-col items-center justify-center leading-3 py-1" }
const _hoisted_53 = { class: "text-black font-medium text-xs" }
const _hoisted_54 = { class: "flex flex-col items-center justify-center leading-3 py-1" }
const _hoisted_55 = { class: "text-black font-medium text-xs" }
const _hoisted_56 = { class: "flex flex-col items-center justify-center leading-3 py-1" }
const _hoisted_57 = { class: "text-black font-medium text-xs" }
const _hoisted_58 = { class: "text-xs border-y text-center text-black" }

import { ref,computed } from 'vue';
const startingPoint = 50;
const inclination = 18;


export default /*@__PURE__*/_defineComponent({
  __name: 'AIChatSandwichRenderer',
  props: ['content'],
  setup(__props) {

const props = __props;

// Create a computed property to modify and return the entire content
const sandwich = computed(() => {
  // Clone the entire content object to avoid mutating the prop directly
  const contentClone = { ...props.content };

  // Modify the compositions array within the cloned content
  contentClone.composition = contentClone.composition.map((item,index) => ({
    ...item,
    offset: index * baseOffset.value,
    thickness: item.thickness ?? 2,
    color: colors[index % colors.length],
  }));

  // Return the modified content object
  return contentClone;
});


const colors = ['#1c70b2', '#badfd9', '#5fa2a6', '#d4c7c1', '#2a87d3', '#7d91bc'];
const baseOffset = computed(() => {
  const layersCount = props.content.composition.length;

  if (layersCount < 4) {
    return 14;
  } else if (layersCount < 8) {
    return 12;
  } else {
    return 10;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(sandwich.value.sample), 1),
      _cache[3] || (_cache[3] = _createElementVNode("p", { class: "px-4 text-xs -mt-2" }, ".", -1)),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(), _createElementBlock("svg", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sandwich.value.composition, (layer, index) => {
              return (_openBlock(), _createElementBlock("g", {
                key: index,
                class: "layer"
              }, [
                _createElementVNode("g", {
                  style: _normalizeStyle(`--offset-y:-${layer.offset}px;`)
                }, [
                  _createElementVNode("path", {
                    d: `M0,${startingPoint - layer.offset} v-${layer.thickness} L50,${startingPoint - layer.offset - layer.thickness - inclination} L100,${startingPoint - layer.offset - layer.thickness} v${layer.thickness} L50,${startingPoint - layer.offset + inclination} Z`,
                    fill: layer.color,
                    filter: layer.color
                  }, null, 8, _hoisted_7),
                  _createElementVNode("path", {
                    d: `M0,${startingPoint - layer.offset} v-${layer.thickness} L50,${startingPoint - layer.offset + inclination - layer.thickness} v${layer.thickness} Z`
                  }, null, 8, _hoisted_8),
                  _createElementVNode("path", {
                    d: `M50,${startingPoint - layer.offset + inclination} v-${layer.thickness} L100,${startingPoint - layer.offset - layer.thickness} v${layer.thickness} Z`
                  }, null, 8, _hoisted_9)
                ], 4),
                _createElementVNode("line", {
                  x1: -10,
                  y1: startingPoint - layer.offset - (layer.thickness / 2),
                  x2: -100,
                  y2: startingPoint - layer.offset - (layer.thickness / 2),
                  stroke: "black",
                  "stroke-width": "0.1",
                  "stroke-dasharray": "3,6"
                }, null, 8, _hoisted_10),
                _createElementVNode("text", {
                  x: -100,
                  y: startingPoint - layer.offset - (layer.thickness / 2) + -1
                }, _toDisplayString(layer.layerType), 9, _hoisted_11),
                _createElementVNode("text", {
                  x: -10,
                  y: startingPoint - layer.offset - (layer.thickness / 2) + -1,
                  "text-anchor": "end"
                }, ". gsm", 8, _hoisted_12),
                _createElementVNode("line", {
                  x1: 110,
                  y1: startingPoint - layer.offset - (layer.thickness / 2),
                  x2: 250,
                  y2: startingPoint - layer.offset - (layer.thickness / 2),
                  stroke: "black",
                  "stroke-width": "0.1",
                  "stroke-dasharray": "3,6"
                }, null, 8, _hoisted_13),
                _createElementVNode("a", {
                  href: layer.link,
                  target: "_blank"
                }, [
                  _createElementVNode("text", {
                    x: 110,
                    y: startingPoint - layer.offset - (layer.thickness / 2) + -1
                  }, _toDisplayString(layer.layerName), 9, _hoisted_15)
                ], 8, _hoisted_14)
              ]))
            }), 128))
          ]))
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("p", _hoisted_19, _toDisplayString(sandwich.value.paperContent) + " %", 1),
            _cache[0] || (_cache[0] = _createElementVNode("p", { class: "uppercase font-normal text-xs" }, "Paper content", -1))
          ]),
          (sandwich.value.sealingSides)
            ? (_openBlock(), _createElementBlock("div", _hoisted_20, _cache[1] || (_cache[1] = [
                _createElementVNode("p", { class: "font-medium text-black" }, ".", -1),
                _createElementVNode("p", { class: "uppercase font-normal text-xs" }, "sealing sides", -1)
              ])))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("p", _hoisted_22, _toDisplayString(sandwich.value.gsmTotal), 1),
            _cache[2] || (_cache[2] = _createElementVNode("p", { class: "uppercase font-normal text-xs" }, "total grammage", -1))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_23, [
      _createElementVNode("div", _hoisted_24, [
        (sandwich.value.otr)
          ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "w-1/6 flex justify-center items-center border-r border-dashed" }, [
                _createElementVNode("p", { class: "-rotate-90 font-medium text-black" }, "OTR")
              ], -1)),
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("div", _hoisted_28, [
                    _cache[4] || (_cache[4] = _createElementVNode("p", { class: "lowercase text-xs text-gray-400" }, "MIN", -1)),
                    _createElementVNode("p", _hoisted_29, _toDisplayString(sandwich.value.otr.min), 1)
                  ]),
                  _createElementVNode("div", _hoisted_30, [
                    _cache[5] || (_cache[5] = _createElementVNode("p", { class: "lowercase text-xs text-gray-400" }, "max", -1)),
                    _createElementVNode("p", _hoisted_31, _toDisplayString(sandwich.value.otr.max), 1)
                  ])
                ]),
                _createElementVNode("p", _hoisted_32, _toDisplayString(sandwich.value.otr.condition), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        (sandwich.value.wvtr)
          ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "w-1/6 flex justify-center items-center border-r border-dashed" }, [
                _createElementVNode("p", { class: "-rotate-90 font-medium text-black" }, "WVTR")
              ], -1)),
              _createElementVNode("div", _hoisted_34, [
                _createElementVNode("div", _hoisted_35, [
                  _createElementVNode("div", _hoisted_36, [
                    _cache[7] || (_cache[7] = _createElementVNode("p", { class: "lowercase text-xs text-gray-400" }, "MIN", -1)),
                    _createElementVNode("p", _hoisted_37, _toDisplayString(sandwich.value.wvtr.min), 1)
                  ]),
                  _createElementVNode("div", _hoisted_38, [
                    _cache[8] || (_cache[8] = _createElementVNode("p", { class: "lowercase text-xs text-gray-400" }, "max", -1)),
                    _createElementVNode("p", _hoisted_39, _toDisplayString(sandwich.value.wvtr.max), 1)
                  ])
                ]),
                _createElementVNode("p", _hoisted_40, _toDisplayString(sandwich.value.wvtr.condition), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        (sandwich.value.heatSeal)
          ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
              _cache[12] || (_cache[12] = _createElementVNode("div", { class: "w-1/6 flex justify-center items-center border-r border-dashed" }, [
                _createElementVNode("p", { class: "-rotate-90 font-medium text-black" }, "HS")
              ], -1)),
              _createElementVNode("div", _hoisted_42, [
                _createElementVNode("div", _hoisted_43, [
                  _createElementVNode("div", _hoisted_44, [
                    _cache[10] || (_cache[10] = _createElementVNode("p", { class: "lowercase text-xs text-gray-400" }, "MIN", -1)),
                    _createElementVNode("p", _hoisted_45, _toDisplayString(sandwich.value.heatSeal.min), 1)
                  ]),
                  _createElementVNode("div", _hoisted_46, [
                    _cache[11] || (_cache[11] = _createElementVNode("p", { class: "lowercase text-xs text-gray-400" }, "max", -1)),
                    _createElementVNode("p", _hoisted_47, _toDisplayString(sandwich.value.heatSeal.max), 1)
                  ])
                ]),
                _createElementVNode("p", _hoisted_48, _toDisplayString(sandwich.value.heatSeal.condition), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        (sandwich.value.lastCoatingTechnique)
          ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
              _cache[16] || (_cache[16] = _createElementVNode("div", { class: "w-1/6 flex justify-center items-center border-r border-dashed" }, [
                _createElementVNode("p", {
                  class: "-rotate-90 font-medium text-black",
                  title: "Last Coating"
                }, "LC")
              ], -1)),
              _createElementVNode("div", _hoisted_50, [
                _createElementVNode("div", _hoisted_51, [
                  _createElementVNode("div", _hoisted_52, [
                    _cache[13] || (_cache[13] = _createElementVNode("p", { class: "lowercase text-xs text-gray-400" }, "SPEED", -1)),
                    _createElementVNode("p", _hoisted_53, _toDisplayString(sandwich.value.lastCoatingTechnique.speed), 1)
                  ]),
                  _createElementVNode("div", _hoisted_54, [
                    _cache[14] || (_cache[14] = _createElementVNode("p", { class: "lowercase text-xs text-gray-400" }, "TEMP", -1)),
                    _createElementVNode("p", _hoisted_55, _toDisplayString(sandwich.value.lastCoatingTechnique.meltingTemperature), 1)
                  ]),
                  _createElementVNode("div", _hoisted_56, [
                    _cache[15] || (_cache[15] = _createElementVNode("p", { class: "lowercase text-xs text-gray-400" }, "PRESSURE", -1)),
                    _createElementVNode("p", _hoisted_57, _toDisplayString(sandwich.value.lastCoatingTechnique.meltPressure), 1)
                  ])
                ]),
                _createElementVNode("p", _hoisted_58, _toDisplayString(sandwich.value.lastCoatingTechnique.technique), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})