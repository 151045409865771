import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/icons/layers.gif'


const _hoisted_1 = { class: "designer-options-container" }
const _hoisted_2 = {
  key: 0,
  class: "waiting-for-creation"
}

import ModalSlots from "@/components/ModalSlots.vue";
import { TransitionRoot } from '@headlessui/vue'
import { ref, onUpdated, PropType } from "vue";
import ProgramPrototypeDesignsAndFeedback from "./ProgramPrototypeDesignsAndFeedback.vue";
import { programTargetInterface, prototypesDesignsInfoInterface } from "@/models/programsInterface";
import ProgramPrototypeDesignModalRequirements from "./ProgramPrototypeDesignModalRequirements.vue";




export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramPrototypeDesignModal',
  props: {
    isOpen: {type: Boolean},
    targets:  { type: Array as PropType<programTargetInterface[]>},
    alternativeDesigns: { type: Array as PropType<prototypesDesignsInfoInterface[]> }
},
  emits: ['closeModal'],
  setup(__props) {





const showLoader = ref(true);

const selectProto = () => console.log('select')

onUpdated(() => {
    if (showLoader.value) {
        setTimeout(() => {
            showLoader.value = false
        }, 3000)
    } else {  setTimeout(() => {
            showLoader.value = true
        }, 500)}
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalSlots, {
    show: __props.isOpen,
    "hide-footer": true,
    onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeModal'))),
    dialogClass: "prototype-designer-modal"
  }, {
    header: _withCtx(() => _cache[1] || (_cache[1] = [
      _createElementVNode("p", null, "prototype designer", -1)
    ])),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (showLoader.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[2] || (_cache[2] = [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "creating prototypes"
              }, null, -1),
              _createElementVNode("div", { class: "pb-3" }, [
                _createElementVNode("p", { class: "creating" }, "Creating prototypes"),
                _createElementVNode("p", null, "This new proptotype designs will be based on your requirements and will be ready in a few seconds")
              ], -1)
            ])))
          : _createCommentVNode("", true),
        _createVNode(_unref(TransitionRoot), {
          appear: "",
          show: !showLoader.value,
          enter: "transition-opacity duration-500",
          "enter-from": "opacity-0",
          "enter-to": "opacity-100"
        }, {
          default: _withCtx(() => [
            _createVNode(ProgramPrototypeDesignModalRequirements, { targets: __props.targets }, null, 8, ["targets"]),
            _createVNode(ProgramPrototypeDesignsAndFeedback, { "alternative-designs": __props.alternativeDesigns }, null, 8, ["alternative-designs"])
          ]),
          _: 1
        }, 8, ["show"])
      ]),
      _createElementVNode("button", { onClick: selectProto })
    ]),
    _: 1
  }, 8, ["show"]))
}
}

})