<template>
    <div  class="relative flex justify-center items-center h-14 p-2 border-r" :style="{backgroundColor: bgColour }"> 
        <template v-if="celValue.criteria.category == 'Commercial Value' && onEdit">
            <input v-model="celValueToChange" class="h-full w-full text-center z-10 bg-transparent" @change="handleValueChange" />
        </template>
        <div v-else-if="celValue.value && celValue.value2" class="flex justify-between">
            <div class="flex flex-col items-center mr-3">
                <p>{{ celValue.value }}</p>
                <p class="text-xs font-light">{{ celValue.conditions }}</p>
            </div>
            <div class="flex flex-col items-center ml-3">
                <p>{{ celValue.value2 }}</p>
                <p class="text-xs font-light">{{ celValue.conditions2 }}</p>
            </div>
        </div>
        <div v-else-if="celValue.value"  class="flex flex-col items-center">
            <p>{{ celValue.value }}</p>
            <p class="text-xs font-light">{{ celValue.conditions }}</p>
        </div>
        <template v-else>
            <p>-</p>
        </template> 
        <div v-if="onEdit && (celValue.value || celValue.criteria.category == 'Commercial Value')" class="flex absolute z-10 top-1 right-1">
            <input type="radio" class="white" value="#ffffff" v-model="celColour" @change="celColour = null" />
            <input type="radio" class="green" value="#b8d9d6" v-model="celColour" @change="handleColorSelection" />
            <input type="radio" class="yellow" value="#fbeabd" v-model="celColour" @change="handleColorSelection"/>
            <input type="radio" class="red" value="#D585B4" v-model="celColour" @change="handleColorSelection"/>
        </div>
    </div> 
</template>
<script setup lang="ts">
import {defineProps, computed, ref, defineEmits} from 'vue';
import { BenchamarkValue } from '@/models/benchmarkModels';

const props = defineProps<{ 
    onEdit: boolean,
    celValue: BenchamarkValue
}>();
const emit = defineEmits(['colorChange', 'valueChange']);

const celColour = ref(props.celValue.colour);
const celValueToChange = ref(props.celValue.value);

const bgColour = computed(() => celColour.value ?? '#fff');

const handleColorSelection = () => emit('colorChange', celColour.value);
const handleValueChange = () => emit('valueChange', celValueToChange.value);

</script>
<style lang="scss" scoped>
input:focus {
    border: none;
    background-color: transparent;
}
   
input[type=radio]{
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: none;
    margin-right: 5px;
    &.red {
        background-color: #D585B4;
    }
    &.green {
        background-color: #b8d9d6;
    }
    &.yellow {
        background-color: #fbeabd;
    }
    &.white {
        background-color: white;
    }
    &:checked {
        border: 3px solid #fafafa;
        background-image: none
    }
}
    
</style>