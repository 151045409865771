export function useNodeContent(edgesList: any[], paramToExtract:string, type?:string):string | number[] {
    if (type && type == 'number') {
        return edgesList.length != 0 ?  edgesList.map(edge => edge.node[paramToExtract]) : null
    }
    else return edgesList.length != 0 ?  edgesList.map(edge => edge.node[paramToExtract].toString()).join(', ') : null
} 

export function useNodeContentToString(edgesList: any[], paramToExtract:string):string {
    return edgesList.length != 0 ?  edgesList.map(edge => edge.node[paramToExtract].toString()).join(', ') : null
} 

export interface SourceInterface {
    source: number,
    externalSourceId: string
}
export interface assetSourceInterface {
    externalSourceId: string,
    source: {
        id: number,
        name: string
    }
}
export function useNodeContentOnSources(sourceList: Array<assetSourceInterface>) {
    const sources:Array<SourceInterface> = [];
    sourceList.map(s => {
        const newSource:SourceInterface = {
            source: s.source.id,
            externalSourceId: s.externalSourceId
        };
        sources.push(newSource)
    })
    return sources
}