import gql from "graphql-tag";


export const PROGRAM_CREATE = gql`
    mutation programCreate($input: ProgramCreateMutationInput!) {
        programCreate (input: $input) {
            program {
                id
            }
        }
    }
`

export const PROGRAM_VERSION_CREATE_WITH_TEAM_AND_TARGETS = gql`
    mutation programVersionCreateWithTargetsAndTeam($input: ProgramVersionCreateWithTargetAndTeamMutationInput!) {
        programVersionCreateWithTargetsAndTeam (input: $input) {
            programVersion {
                id
            }
        }
    }
`

