import { useMutation } from "@vue/apollo-composable";

// Create a custom hook with default options
export function useTeaMutation(mutation, customOptions = {}) {
  const defaultOptions = {
    // Define your default options here
    context: { uri: process.env.VUE_APP_TEA_ROOT_API },
  };

  // Combine the default options with custom options
  const options = { ...defaultOptions, ...customOptions };

  // Use the useMutation function with the combined options
  return useMutation(mutation, options);
}

export function useTeaAuthMutation(mutation, customOptions = {}) {
  const defaultOptions = {
    // Define your default options here
    context: { uri: process.env.VUE_APP_TEA_AUTH_API },
  };

  // Combine the default options with custom options
  const options = { ...defaultOptions, ...customOptions };

  // Use the useMutation function with the combined options
  return useMutation(mutation, options);
}

export default { useTeaAuthMutation, useTeaMutation };
