<template>
    <!-- Mockup section - TBR after real is complete -->
    <template v-if="isMockup">
        <div class="programs-dashboard">
            <template v-for="program in programs" :key="program.id">
                <ProgramDashboardCard :program-mockup="program" />
            </template>
        </div>
    </template>

    <!-- Real section  -->
    <template v-else>
        <div class="flex">
            <ProgramsDashboardSidebarFilter :program-list="programList" @filtered-programs-change="handleFilterPrograms"
                :is-filter-closed="isFilterClosed" @filter-toggle="handleFilterToggle" />
            <div class="programs-dashboard-container" :class="{ 'closed': isFilterClosed }">
                <div v-if="loading" class="loading-gif"></div>

                <div v-else-if="filteredProgramVersions.length === 0" class="empty-box add"></div>

                <div v-else-if="programVersions && programVersions.length > 0" class="programs-dashboard">
                    <template v-for="program in filteredProgramVersions" :key="program.id">
                        <ProgramDashboardCard :program-version="program"
                            :program-version-applications="program.applicationTypes" />
                    </template>
                </div>
            </div>
        </div>
    </template>
</template>
<script setup lang="ts">
import ProgramDashboardCard from './ProgramDashboardCard.vue';
import { computed, defineProps, ref } from 'vue';
import { ProgramVersionsInterface } from '@/models/programVersionsInterface';
import { PROGRAM_VERSIONS_QUERY } from '@/grapql/programVersionsQuery';
import { useQuery } from '@vue/apollo-composable';
import { PROGRAMS_INFO } from '@/models/programsInfo';
import ProgramsDashboardSidebarFilter from './ProgramsDashboardSidebarFilter.vue';
import { ProgramSimpleInterface } from '@/models/programsInterface';

defineProps({
    isMockup: {
        type: Boolean,
        required: true
    }
})

// To be removded after develop is done
const programs = PROGRAMS_INFO;

const { result, loading } = useQuery(PROGRAM_VERSIONS_QUERY);
const queryResult = computed(() => result?.value ?? null);

const programVersions = computed<ProgramVersionsInterface[]>(() => queryResult?.value && queryResult?.value?.programVersions?.edges.length > 0 ? queryResult?.value?.programVersions?.edges.map((program: { node: ProgramVersionsInterface }) => program.node) : []);

const programList = computed<ProgramSimpleInterface[]>(() => queryResult?.value && queryResult?.value?.programs?.edges.length > 0 ? queryResult?.value?.programs?.edges.map((program: { node: ProgramSimpleInterface }) => program.node) : []);

const idsToFilterBy = ref([]);
const handleFilterPrograms = (updatedIds) => {
    idsToFilterBy.value = updatedIds
}

const isFilterClosed = ref(false);

const handleFilterToggle = () => {
    isFilterClosed.value = !isFilterClosed.value;
};

const filteredProgramVersions = computed(() => idsToFilterBy.value.length > 0 ? programVersions.value.filter(programVerion => idsToFilterBy.value.includes(programVerion.program.id)) : programVersions.value);

</script>
<style lang="scss">
.programs-dashboard {
    display: flex;
    padding-left: 2rem;
    align-items: stretch;
    flex-wrap: wrap;

}

.programs-dashboard-container {
    width: 80%;

    &.closed {
        width: 96%;
    }
}
</style>