import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "layer-voting-card" }
const _hoisted_2 = { class: "design-content" }
const _hoisted_3 = { class: "score" }
const _hoisted_4 = { class: "layers-info p-4" }
const _hoisted_5 = { class: "uppercase text-sm font-medium" }
const _hoisted_6 = { class: "font-light text-sm mb-2" }
const _hoisted_7 = { class: "font-medium" }
const _hoisted_8 = { class: "action-buttons" }
const _hoisted_9 = {
  key: 0,
  class: "feedback-input"
}

import {computed, ref} from 'vue'; 
import LayersDesignGenerator from './LayersDesignGenerator.vue';
import { HandThumbDownIcon, HandThumbUpIcon} from '@heroicons/vue/24/solid';
import { designFeedbackInterface, prototypesDesignsInfoInterface } from '@/models/programsInterface';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import router from '@/router';
import { useInitialsToUserLogo } from '@/composables/useInitialsToUserLogo';


export default /*@__PURE__*/_defineComponent({
  __name: 'LayersDesignVotingCard',
  props: {
    prototypeDesignInfo: {}
  },
  emits: ['feedback'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;
const store = useStore();
const route = useRoute();

const user = computed( () => { return store.state.authModule.user });
const initials = computed(() => useInitialsToUserLogo(user.value.firstName, user.value.lastName));
const scoreColor = computed(() => props.prototypeDesignInfo.successScore < 0.5 ? "#acabab" : props.prototypeDesignInfo.successScore <= 0.70 ? "#7d91bc" : "#8bb9a7");
const isLike = ref(false);
const isDislike = ref(false);
const feedback = ref(null);
const feedbackSubmited = ref(false);

const programId = computed(() => route.params.id)

const goToLink = () => props.prototypeDesignInfo.linkId ? router.push({ name: 'ProgramDetailWIP', params: { id: props.prototypeDesignInfo.linkId } }) : null;

const handleThumDown = () => { isDislike.value = !isDislike.value; isLike.value = false; }
const handleThumUp = () => { isLike.value = !isLike.value; isDislike.value = false; }

const cancelFeedback = () => {
    isDislike.value = false; 
    isLike.value = false; 
    feedback.value = null; 
    feedbackSubmited.value = false;
}
const submitFeedback = () => { 
    sendFeedbackToBeStorage();
    cancelFeedback();
}   

const sendFeedbackToBeStorage = () => {
    const feedbackToBeStorage:designFeedbackInterface = {
        programId: programId.value,
        designId: props.prototypeDesignInfo.id,
        feedback: feedback.value,
        who: initials.value
    }
    emit('feedback', feedbackToBeStorage)
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("p", null, "Success Score", -1)),
        _createElementVNode("p", {
          class: "score-value",
          style: _normalizeStyle({color: scoreColor.value})
        }, _toDisplayString(_ctx.prototypeDesignInfo.successScore), 5)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["prototype", { 'dislike': isDislike.value }])
      }, [
        _createVNode(LayersDesignGenerator, {
          "layers-data": _ctx.prototypeDesignInfo.layersData.toReversed(),
          class: "cursor-pointer",
          onClick: goToLink
        }, null, 8, ["layers-data"]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([..._ctx.prototypeDesignInfo.layersData].toReversed(), (layer, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: layer.id
            }, [
              _createElementVNode("p", _hoisted_5, "Layer " + _toDisplayString(index + 1), 1),
              _createElementVNode("p", _hoisted_6, [
                _createTextVNode(_toDisplayString(layer.layerDescription) + " - ", 1),
                _createElementVNode("span", _hoisted_7, _toDisplayString(layer.layerName) + " (" + _toDisplayString(layer.grammage) + ")", 1)
              ])
            ], 64))
          }), 128))
        ])
      ], 2),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("button", {
          onClick: handleThumUp,
          class: _normalizeClass({ 'like': isLike.value})
        }, [
          _createVNode(_unref(HandThumbUpIcon), { class: "icon icon-small" })
        ], 2),
        _createElementVNode("button", {
          onClick: handleThumDown,
          class: _normalizeClass({ 'dislike': isDislike.value })
        }, [
          _createVNode(_unref(HandThumbDownIcon), { class: "icon icon-small" })
        ], 2)
      ]),
      (isDislike.value || isLike.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _withDirectives(_createElementVNode("textarea", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((feedback).value = $event)),
              rows: "5",
              placeholder: "Would you like to leave some feedback?"
            }, null, 512), [
              [_vModelText, feedback.value]
            ]),
            _createElementVNode("div", { class: "cta" }, [
              _createElementVNode("button", { onClick: cancelFeedback }, "Cancel"),
              _createElementVNode("button", {
                onClick: submitFeedback,
                class: "submit"
              }, "Submit")
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})