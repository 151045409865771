import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "accordion-item"
}
const _hoisted_2 = ["onClick"]

import { ref } from 'vue';
    import { ChevronRightIcon } from '@heroicons/vue/24/solid';
    import RunGroupDetailInfoContent from "@/tea/components/rungroups/RunGroupDetailInfoContent.vue";
    
export default /*@__PURE__*/_defineComponent({
  __name: 'AccordionList',
  props: ['dataForAccordionList'],
  setup(__props) {

    const props = __props;
    const expandedField = ref(props.dataForAccordionList.filter(item => item.expanded).map(ei => ei.title));
    const toggleExpandedField = (title:string) => {
        const titlePos = expandedField.value.indexOf(title);
        titlePos == -1 ? expandedField.value.push(title) : expandedField.value.splice(titlePos, 1);
    }

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.dataForAccordionList, (item) => {
    return (_openBlock(), _createElementBlock("div", {
      key: item.title
    }, [
      (item.content)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (item.content)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: ($event: any) => (toggleExpandedField(item.title)),
                  class: "title"
                }, [
                  _createVNode(_unref(ChevronRightIcon), {
                    class: _normalizeClass(["icon", {'face-down': expandedField.value.includes(item.title)}])
                  }, null, 8, ["class"]),
                  _createElementVNode("p", null, _toDisplayString(item.title), 1)
                ], 8, _hoisted_2))
              : _createCommentVNode("", true),
            (item.title === 'Info')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass([{'visible': expandedField.value.includes(item.title)}, "content"])
                }, [
                  _createVNode(RunGroupDetailInfoContent, {
                    "info-content": item.content,
                    editable: false
                  }, null, 8, ["info-content"])
                ], 2))
              : (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: _normalizeClass([{'visible': expandedField.value.includes(item.title)}, "content"])
                }, _toDisplayString(item.content), 3))
          ]))
        : _createCommentVNode("", true)
    ]))
  }), 128))
}
}

})