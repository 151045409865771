<template lang="">
    <div class="test">
        404 - Page not found
    </div>
</template>
<script lang="ts">
export default {
    
}
</script>
<style lang="scss">
    
</style>