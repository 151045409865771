export const PROGRAM_STAGES = [
    {
        stageNumber: '1',
        stageName: 'Commercial Qualifiation'
    },
    {
        stageNumber: '2',
        stageName: 'Technical Qualifiation'
    },
    {
        stageNumber: '3',
        stageName: 'Tech selection & DDS'
    },
    {
        stageNumber: '4',
        stageName: 'Proposal & negotiations'
    },
    {
        stageNumber: '5',
        stageName: 'Prototype'
    },
    {
        stageNumber: '6',
        stageName: 'Licencing'
    },
    {
        stageNumber: '7',
        stageName: 'Pilot'
    },
    {
        stageNumber: '8',
        stageName: 'Scale up'
    },
    {
        stageNumber: '9',
        stageName: 'Key account management'
    }
]