<template> 
    <div class="new-related-programme">
        <div class="edit-assessment-buttons"> 
            <div @click="handleAdd" class="on-green"><CheckIcon class="icon"/><p>Save Changes</p></div>
            <div @click="handleCancelAdd"><XMarkIcon class="icon"/><p>cancel</p></div> 
        </div> 
        <div v-if="newLinkErrorMessagge || formErrorValue" class="error-message mt-4">{{ newLinkErrorMessagge }} {{ formErrorValue }}</div>
        <p class="title">New related programme</p>
        <div class="mb-4 mt-4">
            <label>Programme</label>
            <select v-model="program" class="w-full">
                <option v-for="program in optionsListForDropdown['programsOptions']" :key="program.id" :value="program.id">
                    {{ program.name }}
                </option>
            </select>
        </div>
        <div class="mb-4">
            <label>Link Type</label>
            <select v-model="linkType" class="w-full">
                <option v-for="linkType in optionsListForDropdown['assetProgramLinkTypesOptions']" :key="linkType.id" :value="linkType.id">
                    {{ linkType.name }}
                </option>
            </select>
        </div>
    </div>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { CheckIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { ASSET_PROGRAM_LINK_FORM_VALUES } from '@/grapql/assetProgramLinkFormQueries';
import { useQuery } from '@vue/apollo-composable';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';


const store = useStore();
const assetId = computed(() => store.getters["assetDetailModule/getAssetId"]);

const { result } = useQuery(ASSET_PROGRAM_LINK_FORM_VALUES);
const inizilizedData = computed(() => result?.value ?? null );
const optionsListForDropdown = computed<{[key: string]: DropdownSearchableOptionInterface[]}>(() => useFormInizializationNewAsset(inizilizedData.value, ['programs', 'assetProgramLinkTypes', 'assetEvaluationStatuses']));

const program =  ref(null);
const linkType =  ref(null);

const formErrorValue = ref(null);
const newLinkErrorMessagge = computed(() => store.state.assetDetailModule.errorsDuringMutation.programsAddLink)

const handleCancelAdd = () => store.commit('assetDetailModule/setEditableProgramsAddOff' );

const handleAdd = async () => {
    const createNewLinkPayload = ref({
        asset: Number(assetId.value),
        program: Number(program.value),
        linkType: Number(linkType.value)
    })
    if (!createNewLinkPayload.value.program || !createNewLinkPayload.value.linkType) {
        formErrorValue.value = "Oops! You need to complete all fields"
    }
    else {
        formErrorValue.value = null
        await store.dispatch('assetDetailModule/assetProgramLinkCreate', createNewLinkPayload.value);
    }
    
}

</script>
<style lang="scss" scoped>
    .new-related-programme {
        margin-bottom: 2rem;
        .title {
            font-weight: 600;
            text-transform: uppercase;
            color: #6d6d6d;
            font-size: 0.9rem;
            margin: 1.5rem 0 -0.5rem;
        }
    }
</style>