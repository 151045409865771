import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between mb-8" }
const _hoisted_2 = {
  key: 0,
  class: "requirements"
}

import { ChevronUpIcon } from '@heroicons/vue/20/solid';
import { programTargetInterface } from "@/models/programsInterface";
import { PropType, ref} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramPrototypeDesignModalRequirements',
  props: { targets:  { type: Array as PropType<programTargetInterface[]>},},
  setup(__props) {



const showRequirements = ref(false);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("p", { class: "inner-title" }, "Based on requirements these are possible solutions", -1)),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (showRequirements.value = !showRequirements.value)),
        class: "collapsible"
      }, [
        _cache[1] || (_cache[1] = _createTextVNode("See Requirements ")),
        _createVNode(_unref(ChevronUpIcon), {
          class: _normalizeClass(["chevron h-5 w-5 ml-1", {'up': showRequirements.value}])
        }, null, 8, ["class"])
      ])
    ]),
    (showRequirements.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.targets, (target, index) => {
            return (_openBlock(), _createElementBlock("p", { key: index }, [
              _createTextVNode(_toDisplayString(target.label) + ": ", 1),
              _createElementVNode("span", null, _toDisplayString(target.value), 1)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})