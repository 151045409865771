import gql from "graphql-tag";


export const PATENT_CREATE = gql`
    mutation patentCreate($input: PatentCreateMutationInput! ) {
        patentCreate (input: $input) {
            patent { id }
        }
    }
`

export const RESEARCH_PAPER_CREATE = gql`
    mutation researchPaperCreate($input: ResearchPaperCreateMutationInput! ) {
        researchPaperCreate (input: $input) {
            researchPaper { id }
        }
    }
`

export const RESEARCH_PROJECT_CREATE = gql`
    mutation researchProjectCreate($input: ResearchProjectCreateMutationInput! ) {
        researchProjectCreate (input: $input) {
            researchProject { id }
        }
    } 
`

export const OTHER_TECHNOLOGY_CREATE = gql`
    mutation otherTechnologyCreate($input: OtherTechnologyCreateMutationInput! ) {
        otherTechnologyCreate (input: $input) {
            otherTechnology { id }
        }
    }
`

 