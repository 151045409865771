import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "name mb-2" }
const _hoisted_2 = {
  key: 0,
  class: "mb-4 text-sm italic text-neutral-400"
}
const _hoisted_3 = { class: "flex items-center justify-between flex-wrap" }
const _hoisted_4 = {
  key: 0,
  class: "w-full"
}
const _hoisted_5 = { class: "value" }

import { AssetProcessingTechniqueInterface } from '@/models/assetAssessmentInterfaces';

import AssetProcessingTechniqueProperties from './AssetProcessingTechniqueProperties.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssetDetailAssessmentInDepthProcessingTechniques',
  props: {
    processingTechniquesData: {}
  },
  setup(__props: any) {






return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.processingTechniquesData, (processingTechnique) => {
    return (_openBlock(), _createElementBlock("div", {
      key: processingTechnique.id,
      class: "pt-info"
    }, [
      _createElementVNode("p", _hoisted_1, _toDisplayString(processingTechnique.processingTechnique.name), 1),
      (processingTechnique.processingTechnique.description)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(processingTechnique.processingTechnique.description), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        (processingTechnique.processingTechnique.category)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _cache[0] || (_cache[0] = _createElementVNode("p", { class: "label" }, "Category", -1)),
              _createElementVNode("p", _hoisted_5, _toDisplayString(processingTechnique.processingTechnique.category), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(AssetProcessingTechniqueProperties, {
        "asset-processing-technique-id": processingTechnique.id,
        "asset-processing-technique-properties": processingTechnique.assetProcessingTechniqueProperties,
        "processing-technique-id": processingTechnique.processingTechnique.id
      }, null, 8, ["asset-processing-technique-id", "asset-processing-technique-properties", "processing-technique-id"])
    ]))
  }), 128))
}
}

})