<template> 
    <LoaderPage v-if="showUploadLoader" gif-position="bottom"/>
    <div>
        <FilesUpload label-text="Files to Add" @files-to-upload="onFilesToUpload"/> 
        
        <div v-if="filesToRemove.length" class="files-to-remove">
            <label>File to Remove</label>
            <div v-for="(file, index) in filesToRemove" :key="index" class="upload-file-preview">
            <a :href="file.file.url" target="_blank" class="card-content line-through">
                <DocumentMinusIcon class="icon shrink-0 icon-file thick remove" />
                {{ file.file.name.replace('assets/', '') }}
            </a>
            <ArrowUturnLeftIcon class="icon icon-x-small shrink-0" @click="undoRemoval(file)"/> 
            </div>
        </div>

        <AssetFilesList v-if="filesToShow" :files-to-show="filesToShowWhithOutRemovals" :files-removal="true" @files-to-remove="onFilesToRemove"/>
        
    </div>
</template>

<script setup lang="ts">
import { computed, defineProps, ref, unref } from 'vue';
import FilesUpload from './FilesUpload.vue';
import AssetFilesList from './AssetFilesList.vue';
import LoaderPage from '@/components/LoaderPage.vue';
import {  ArrowUturnLeftIcon , DocumentMinusIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';

const props = defineProps(['filesToShow']);
const filesToShowWhithOutRemovals = computed(() => props.filesToShow.filter(file => !filesIdsToRemove.value.includes(file.node.id)))
const filesToRemove = ref([]);
const filesIdsToRemove = ref([]);
const store = useStore();
const showUploadLoader = computed(() => store.getters['assetDetailModule/getShowFilesUploadLoader'])
console.log(showUploadLoader)
const onFilesToRemove = (fileToRemove) => {
    filesIdsToRemove.value.push(fileToRemove.id);
    filesToRemove.value.push(fileToRemove);
    store.commit('assetDetailModule/setFilesToRemove', unref(filesIdsToRemove.value));
}

const undoRemoval = (fileToReAdd) => {
    filesIdsToRemove.value.splice(filesIdsToRemove.value.indexOf(fileToReAdd.id), 1);
    filesToRemove.value.splice(filesToRemove.value.indexOf(fileToReAdd), 1);
    store.commit('assetDetailModule/setFilesToRemove', unref(filesIdsToRemove.value));
} 

const onFilesToUpload = (fileToUpload) => {
    store.commit('assetDetailModule/setFilesToAdd', unref(fileToUpload));
}
</script>
<style lang="scss">

</style>