<template>
    <div class="section-container">
        <div class="search-container">
            <template v-for="(condition, index) in formConditions" :key="index" >
                <div v-if="index == 0" class="search-condition first">
                    <p>Filter polymers where:</p> 
                    <select v-model="condition.property" class="">
                        <option disabled value="">Property</option>
                        <option v-for="(option, index) in propertiesList" :key="index" :value="option.name">{{ option.name }}</option>
                    </select>
                    <!-- <DropdownSearchableSingle :options-list="propertiesList" v-model="condition.property" select-other-than-id="name" class="w-full mr-2" /> -->
                    <select v-model="condition.relation" class="auto">
                        <option disabled value="">Condition</option>
                        <option v-for="(option, index) in relationsList" :key="index" :value="option.operator">{{ option.name }}</option>
                    </select>
                    <input type="text" v-model="condition.value" placeholder="Value" class="w-6/12"/>
                </div>
                <div v-else class="search-condition">
                    <select v-model="condition.condition" class="auto">
                        <option disabled value="">And/Or</option>
                        <option v-for="(option, index) in conditionsList" :key="index">{{ option }}</option>
                    </select>
                    <select v-model="condition.property" class="">
                        <option disabled value="">Property</option>
                        <option v-for="(option, index) in propertiesList" :key="index" :value="option.name">{{ option.name }}</option>
                    </select>
                    <!-- <DropdownSearchableSingle :options-list="propertiesList" v-model="condition.property" select-other-than-id="name" class="w-full mr-2" /> -->
                    <select v-model="condition.relation" class="auto">
                        <option disabled value="">Condition</option>
                        <option v-for="(option, index) in relationsList" :key="index" :value="option.operator">{{ option.name }}</option>
                    </select>
                    <input type="text" v-model="condition.value" placeholder="Value"/>
                    <div class="delete-icon" @click="removeCondition(index)">
                        <TrashIcon class="icon icon-x-small red"   />
                    </div>
                    
                </div>
            </template>
            <div class="actions-container">
                <div @click="addCondition">+ Add new condition</div>
            </div>
        </div>
    <div class="apply-filter mr-2" @click="applyFilters">Filter</div>
    <div class="apply-filter clear" @click="clearAllFields">Clear</div>
</div>
</template>
<script setup lang="ts">
import { onMounted, ref, defineEmits, defineProps, PropType } from 'vue';
import { TrashIcon } from '@heroicons/vue/24/outline';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
// import DropdownSearchableSingle from '@/components/DropdownSearchableSingle.vue'

const emit = defineEmits(['applyFilter', 'clearFilter'])
defineProps({propertiesList: { type: Array as PropType<DropdownSearchableOptionInterface[]>}, })

interface formConditionInterface {
    condition: string;
    property: string;
    relation: string;
    value: string | number
}
interface relationInterface {
    operator: string,
    name: string
}

const relationsList:relationInterface[] = [
    {
        operator: 'includes',
        name: 'Contains',
    },
    {
        operator: 'Notincludes',
        name: 'Does not contain',
    },
    {
        operator: '==',
        name: 'Is',
    },
    {
        operator: '!==',
        name: 'Is no',
    },
    {
        operator: '>=',
        name: 'Is bigger than',
    },
    {
        operator: '<=',
        name: 'Is lower than',
    }  
]

    // 'Does not contains','Is', 'Is not', 'Is bigger than', 'Is lower than']
const conditionsList:string[] = ['and', 'or']

const formConditions = ref<formConditionInterface[]>([]);
const firstCondition:formConditionInterface = {
        condition: '',
        property: '',
        relation: '',
        value: '',
}

const addCondition = () => {
    const newCondition:formConditionInterface = {
        condition: '',
        property: '',
        relation: '',
        value: '',
    }
    formConditions.value.push(newCondition)
}
const removeCondition = (position:number) => formConditions.value.splice(position, 1)
const clearAllFields = () => {
    formConditions.value = []; 
    addCondition();
    emit('clearFilter')
}

const applyFilters = () => emit('applyFilter', formConditions.value)

onMounted(() => {
    formConditions.value.push(firstCondition)
})

</script>
<style lang="scss" scoped>
.section-container {
    display: flex;
    justify-content: space-between;
    align-items: start;
}
.apply-filter {
    padding: 0.5rem 1.2rem;
    text-transform: uppercase;
    margin-top: 1rem;
    text-align: center;
    background: $green-bg-text;
    border-radius: 4px;
    color: white;
    font-size: 0.9rem;
    font-weight: 400;
    cursor: pointer;
    &.clear {
        background-color: #B77155;
        
    }
}
.search-container {
    padding: 1rem 0.5rem;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    color: $font-color-light;
    font-weight: 300;
    width: 85%;
}
.actions-container {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    font-weight: 500;
    div {
        cursor: pointer;
    }
}
.search-condition {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem 0 0 7.8rem;
    > * {
        width: 100%;
        margin-right: 0.5rem;
        &:last-child {
            margin-right: 0;
        }
        &.auto {
            width: auto;
        }
    }
    > p {
        flex-shrink: 0;
        width: auto;
    }
    &.first {margin: 0;}
    .delete-icon {
        border: 1px solid #ddd;
        border-radius: 4px;
        width: auto;
        background: #fff;
        padding: 4px;
    }
}
    
</style>