<template>
    <template v-if="assetsToCompare && (!!propertiesToCompare.length || !!quantitativePropertiesToCompare.length)">
        <div class="flex flex-wrap bg-white text-sm table-container">
            <div class="flex items-strech sticky top-0 z-30">
                <div class="w-72 bg-grey-light bg-charcoal sticky left-0 z-40 h-20"></div>
                <template v-for="asset in assetsToCompare" :key="asset.id">
                    <div class="bg-charcoal text-white w-56 border-r p-4 flex flex-col items-center sticky top-0 h-20">
                        <p class="uppercase font-normal">{{ asset.nickname }}</p>    
                        <p class="text-xs font-light text-center text-ellipsis overflow-hidden" v-html="asset.title"></p>
                    </div>
                </template>
            </div>
            
            <template v-if="!!propertiesToCompare.length">
                <div class="flex w-full uppercase text-white bg-danger-light py-1 px-3 text-sm font-medium sticky top-20 left-0 z-30">Properties</div>
                <template v-for="property in propertiesToCompare" :key="property.id">
                    <div class="flex divide-y divide-slate-200">
                        <div class="p-2 w-72 sticky left-0 z-20 border-r bg-white">
                           <p class="uppercase text-charcoal ">{{ property.name }}</p> 
                            <p class="text-xs text-zinc-400 font-light capitalize">{{ property.className }} / {{ property.category }}</p>
                        </div>
                        <template v-for="asset in assetsToCompare" :key="asset.id">
                            <template v-if="asset.assetProperties.some(prop => prop.id == property.id)">
                                <div class="w-56 border-r text-center p-2 text-charcoal flex items-center justify-center z-10">
                                    <img class="w-6" src="@/assets/icons/check-table.svg">
                                </div>
                            </template>
                            <template v-else>
                                <div class="w-56 border-r text-center p-2 text-charcoal flex items-center justify-center z-10">
                                    <img class="w-6" src="@/assets/icons/close.svg">
                                </div>
                            </template>
                        </template>
                    </div>
                </template>
            </template>

            <template v-if="!!quantitativePropertiesToCompare.length">
                <div class="flex w-full uppercase text-white bg-danger-light py-1 px-3 text-sm font-medium sticky top-20 left-0 z-30">Quantitative Properties</div>
                <template v-for="property in quantitativePropertiesToCompare" :key="property.id">
                    <div class="flex divide-y divide-slate-200">
                        <div class="p-2 w-72 border-r bg-white sticky left-0 z-20">
                           <p class="uppercase text-charcoal ">{{ property.name }}</p> 
                            <p class="text-xs text-zinc-400 font-light capitalize">{{ property.category }}</p>
                        </div>
                        <template v-for="asset in assetsToCompare" :key="asset.id">
                            <template v-if="asset.assetQuantitativeProperties.some(prop => prop.id == property.id)">
                                <div class="w-56 border-r text-charcoal text-center p-2 z-10 flex flex-col items-center justify-center">
                                    <p class="text-charcoal font-semibold">{{ asset.assetQuantitativeProperties.find(prop => prop.id == property.id).value }}</p>
                                    <p v-if="asset.assetQuantitativeProperties.find(prop => prop.id == property.id).extraInformation" 
                                        v-html="asset.assetQuantitativeProperties.find(prop => prop.id == property.id).extraInformation"
                                        class="text-xs text-zinc-400 font-light capitalize">
                                    </p>
                                </div>
                            </template>
                            <template v-else>
                                <div class="w-56 border-r text-center p-2 text-charcoal z-10 flex items-center justify-center">-</div>
                            </template>
                        </template> 
                    </div>
                </template>
            </template>
         </div>
    </template>
    <template v-else>
        <div class="wip-page">
            <p>comparison table</p> 
            <p class="wip-text">No assets or criteria selection</p>
        </div>
    </template>
</template>
<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { ASSET_DATA_FOR_COMPARISON_TABLE } from '@/grapql/assetsPropertiesForComparisonTable';
import { AssetComparisonTableDataInterface, PropertyInterface, QuantitativePropertyInterface, rawAssetDataInterface } from '@/models/assetsComparisonTableInterfaces';
import {useConverterForQuantitativeProperties} from '@/composables/useConverterForQuantitativeProperties';

const store = useStore();

// Get values from store
const assetsIdToCompareFromStore = computed(() => store.state.assetsModule.assetsToCompare.length > 0 ? store.state.assetsModule.assetsToCompare.map(asset => asset.id) : []);
const propertiesToCompareFromStore = computed(() => store.getters['assetsModule/getComparisonProperties']);
const quantitativePropertiesToCompareFromStore = computed(() => store.getters['assetsModule/getComparisonQuantitativeProperties']);

// Assign values from store to a reactive and modifiable variable
const assetsIdsToCompare = ref(assetsIdToCompareFromStore.value);
const propertiesToCompare = ref<PropertyInterface[]>(propertiesToCompareFromStore.value);
const quantitativePropertiesToCompare = ref<QuantitativePropertyInterface[]>(quantitativePropertiesToCompareFromStore.value);
const assetsToCompare = ref<AssetComparisonTableDataInterface[]>(null);


// Watch for changes in the store 
watch([propertiesToCompareFromStore, quantitativePropertiesToCompareFromStore, assetsIdToCompareFromStore], ([newpropertiesToCompareFromStore, newquantitativePropertiesToCompareFromStore, newassetsIdToCompareFromStore]) => {
    propertiesToCompare.value = newpropertiesToCompareFromStore;
    quantitativePropertiesToCompare.value = newquantitativePropertiesToCompareFromStore;
    if (newassetsIdToCompareFromStore) {
        assetsIdsToCompare.value = newassetsIdToCompareFromStore;
        getAssetsData(); 
    }
})


const useAssetDataToCompareTable = (assetData: rawAssetDataInterface) => {
    const parsedData = ref<AssetComparisonTableDataInterface>({
        id: assetData.id,
        nickname: assetData.nickname,
        title: assetData.title,
        assetProperties: assetData.assetProperties.edges.length > 0 ? assetData.assetProperties.edges.map(edge => edge.node.property) : [],
        assetQuantitativeProperties: assetData.assetQuantitativeProperties.edges.length > 0 ? useConverterForQuantitativeProperties(assetData.assetQuantitativeProperties.edges) : []
    })
    return parsedData.value
}

const getAssetsData = () => {
    const assetsData = ref([]);
    assetsIdsToCompare.value.map( assetId => {
        const { onResult } = useQuery(ASSET_DATA_FOR_COMPARISON_TABLE, {id: assetId});
        onResult(queryResult => {
           if (queryResult.data) {
            const parsedData = useAssetDataToCompareTable(queryResult.data.asset);
            assetsData.value.push(parsedData);
            }
        })
        
    })
    assetsToCompare.value = assetsData.value
}

getAssetsData();   


</script>
<style lang="scss" scoped>
.table-container {
    max-width: 90vw;
    max-height: 80vh;;
    overflow: auto;
    margin-top: 3rem;
    width: min-content;
}
</style>