import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect } from "vue"

const _hoisted_1 = { class: "border border-gray-200 shadow-md rounded px-8 pt-6 pb-8 mb-4 grid grid-cols-2" }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "m-2 mt-5 col-span-2" }
const _hoisted_6 = { class: "flex" }

import { computed,ref} from 'vue';
import { useStore } from 'vuex';
import { PlayIcon } from '@heroicons/vue/24/outline';
import { PRODUCT_BRIEF_FORM_FIELDS } from '@/models/aiModels';
import RangeInput from "@/components/shared/RangeInput.vue";
import { useQuery } from '@vue/apollo-composable';
import { PRODUCTBRIEF_FEED_DATA } from '@/grapql/aiQuery';
import {useDynamicFormModels} from '@/composables/shared/useDynamicFormModels';
import {useFormValidation} from '@/composables/shared/useFormValidation';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductBriefBox',
  setup(__props) {

const store = useStore();

const vModels = useDynamicFormModels({
    fields:PRODUCT_BRIEF_FORM_FIELDS.formFields, 
    module: "aiModule",
    base: PRODUCT_BRIEF_FORM_FIELDS.graphqlValue,
    mutation:'setProductBriefSingleValue'
});

const formSubmited = ref(false);
const valuesSubmitted = computed(() => store.state.aiModule.ask['productBrief']);

const {isFormValid, errorFields} = useFormValidation(PRODUCT_BRIEF_FORM_FIELDS.formFields,valuesSubmitted.value);

const handleAsk = async () => {
    formSubmited.value = true;
    if(isFormValid.value){
        formSubmited.value=false;
        await store.dispatch("aiModule/askProductBrief");
    }
}

// get data tables values
const dropdowns = ref({'otrConditions':[],'wvtrConditions':[],'aiModels':[]});
const {onResult, onError} = useQuery(PRODUCTBRIEF_FEED_DATA);

onError(async error => {
    console.log(error);
});
onResult(async queryResult => {
    if (queryResult.data) {
        dropdowns.value.otrConditions = queryResult.data.otrConditions || [];
        dropdowns.value.wvtrConditions = queryResult.data.wvtrConditions || [];
        dropdowns.value.aiModels = queryResult.data.aiModels || [];
    }
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["m-2", { 'error-form-field': _unref(errorFields).includes('totalGsm') && formSubmited.value }])
    }, [
      _cache[8] || (_cache[8] = _createElementVNode("label", { class: "block" }, [
        _createTextVNode("Total GSM "),
        _createElementVNode("small", null, "(g/m2)")
      ], -1)),
      _createVNode(RangeInput, {
        modelValue: _unref(vModels)['totalGsm'].value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(vModels)['totalGsm'].value) = $event)),
        min: 0
      }, null, 8, ["modelValue"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["m-2", { 'error-form-field': _unref(errorFields).includes('paperContent') && formSubmited.value }])
    }, [
      _cache[9] || (_cache[9] = _createElementVNode("label", { class: "block" }, [
        _createTextVNode("Paper Content * "),
        _createElementVNode("small", null, "(%)")
      ], -1)),
      _createVNode(RangeInput, {
        modelValue: _unref(vModels)['paperContent'].value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(vModels)['paperContent'].value) = $event)),
        min: 0,
        max: 100
      }, null, 8, ["modelValue"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["m-2", { 'error-form-field': _unref(errorFields).includes('wvtr') && formSubmited.value }])
    }, [
      _cache[10] || (_cache[10] = _createElementVNode("label", { class: "block" }, [
        _createTextVNode("WVTR "),
        _createElementVNode("small", null, "(g/m2*24h)")
      ], -1)),
      _withDirectives(_createElementVNode("input", {
        type: "number",
        class: "w-full",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(vModels)['wvtr'].value) = $event)),
        min: "0"
      }, null, 512), [
        [_vModelText, _unref(vModels)['wvtr'].value]
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["m-2", { 'error-form-field': _unref(errorFields).includes('otr') && formSubmited.value }])
    }, [
      _cache[11] || (_cache[11] = _createElementVNode("label", { class: "block" }, [
        _createTextVNode("OTR "),
        _createElementVNode("small", null, "(cm3/m2*24h)")
      ], -1)),
      _withDirectives(_createElementVNode("input", {
        type: "number",
        class: "w-full",
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(vModels)['otr'].value) = $event)),
        min: "0"
      }, null, 512), [
        [_vModelText, _unref(vModels)['otr'].value]
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["m-2", { 'error-form-field': _unref(errorFields).includes('wvtrConditions') && formSubmited.value }])
    }, [
      _cache[13] || (_cache[13] = _createElementVNode("label", { class: "block" }, [
        _createTextVNode("WVTR Conditions "),
        _createElementVNode("small", null, "(°C : g/m²/day)")
      ], -1)),
      _withDirectives(_createElementVNode("select", {
        class: "w-full",
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(vModels)['wvtrConditions'].value) = $event))
      }, [
        _cache[12] || (_cache[12] = _createElementVNode("option", {
          value: "null",
          selected: ""
        }, "select wvtr conditions", -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dropdowns.value.wvtrConditions, (condition) => {
          return (_openBlock(), _createElementBlock("option", {
            key: condition,
            value: condition
          }, _toDisplayString(condition), 9, _hoisted_2))
        }), 128))
      ], 512), [
        [_vModelSelect, _unref(vModels)['wvtrConditions'].value]
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["m-2", { 'error-form-field': _unref(errorFields).includes('otrConditions') && formSubmited.value }])
    }, [
      _cache[15] || (_cache[15] = _createElementVNode("label", { class: "block" }, [
        _createTextVNode("OTR Conditions "),
        _createElementVNode("small", null, "(°C : cc/m²/day)")
      ], -1)),
      _withDirectives(_createElementVNode("select", {
        class: "w-full",
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(vModels)['otrConditions'].value) = $event))
      }, [
        _cache[14] || (_cache[14] = _createElementVNode("option", {
          value: "null",
          selected: ""
        }, "select otr conditions", -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dropdowns.value.otrConditions, (condition) => {
          return (_openBlock(), _createElementBlock("option", {
            key: condition,
            value: condition
          }, _toDisplayString(condition), 9, _hoisted_3))
        }), 128))
      ], 512), [
        [_vModelSelect, _unref(vModels)['otrConditions'].value]
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["m-2", { 'error-form-field': _unref(errorFields).includes('heatSeal') && formSubmited.value }])
    }, [
      _cache[16] || (_cache[16] = _createElementVNode("label", { class: "block" }, [
        _createTextVNode("Heat Seal "),
        _createElementVNode("small", null, "(N/15mm)")
      ], -1)),
      _createVNode(RangeInput, {
        modelValue: _unref(vModels)['heatSeal'].value,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(vModels)['heatSeal'].value) = $event)),
        min: 0,
        max: 10
      }, null, 8, ["modelValue"])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["m-2", { 'error-form-field': _unref(errorFields).includes('model') && formSubmited.value }])
    }, [
      _cache[18] || (_cache[18] = _createElementVNode("label", { class: "block" }, "Model *", -1)),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(vModels)['model'].value) = $event)),
        class: "w-full"
      }, [
        _cache[17] || (_cache[17] = _createElementVNode("option", {
          value: "null",
          selected: ""
        }, "select an ai model", -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dropdowns.value.aiModels, (model) => {
          return (_openBlock(), _createElementBlock("option", {
            key: model,
            value: model
          }, _toDisplayString(model), 9, _hoisted_4))
        }), 128))
      ], 512), [
        [_vModelSelect, _unref(vModels)['model'].value]
      ])
    ], 2),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          onClick: handleAsk,
          class: "ml-auto py-2 px-5 rounded-none hover:shadow-md active:shadow-inner button cancel",
          type: "button"
        }, [
          _createVNode(_unref(PlayIcon), { class: "icon icon-small" })
        ])
      ])
    ])
  ]))
}
}

})