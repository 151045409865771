import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "title-menu-header" }
const _hoisted_2 = { class: "view-title" }
const _hoisted_3 = { class: "action-icons" }

import TechnologyAssetsFilterModal from '@/components/TechnologyAssetsFilterModal.vue';
  import TechnologyAssetsAddNew from '@/components/TechnologyAssetsAddNew.vue';
  import SearchAsYouType from '@/components/SearchAsYouType.vue';
  
 
  
export default /*@__PURE__*/_defineComponent({
  __name: 'TechnologyAssetsRepoHeader',
  props: {
    title: String
  },
  emits: ['filterRefetch', 'quickSearchRefetch'],
  setup(__props) {

  
  
  

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(__props.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(TechnologyAssetsAddNew),
      _createVNode(TechnologyAssetsFilterModal, {
        onFilterRefetch: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('filterRefetch')))
      }),
      _createVNode(SearchAsYouType, {
        onQuickSearchRefetch: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('quickSearchRefetch')))
      })
    ])
  ]))
}
}

})