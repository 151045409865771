import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center mt-3" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "flex items-center rounded-full px-4 py-2 bg-moss shadow-md cursor-pointer" }
const _hoisted_4 = { class: "grid grid-cols-1 divide-y" }
const _hoisted_5 = { class: "flex items-center justify-between" }
const _hoisted_6 = { class: "flex items-center justify-between" }
const _hoisted_7 = { class: "flex items-center rounded-full p-2 bg-moss shadow-md cursor-pointer" }
const _hoisted_8 = {
  key: 0,
  class: "funnel-container bg-white shadow-md border px-2 py-2 mt-4 rounded-lg w-full"
}
const _hoisted_9 = { class: "flex items-center justify-center mt-1" }
const _hoisted_10 = { class: "number" }
const _hoisted_11 = {
  key: 0,
  class: "flex items-center justify-center mt-1"
}
const _hoisted_12 = { class: "number" }
const _hoisted_13 = {
  key: 1,
  class: "w-1/3 m-2"
}
const _hoisted_14 = {
  key: 1,
  class: "flex items-center justify-center mt-1"
}
const _hoisted_15 = { class: "number" }
const _hoisted_16 = {
  key: 1,
  class: "w-1/4 m-2"
}
const _hoisted_17 = {
  key: 2,
  class: "flex items-center justify-center mt-1"
}
const _hoisted_18 = { class: "number" }
const _hoisted_19 = {
  key: 1,
  class: "w-1/5 m-2"
}
const _hoisted_20 = { class: "flex items-center justify-center" }
const _hoisted_21 = { class: "flex items-center rounded-full text-white bg-moss p-1 w-1/4 m-2 uppercase font-funnel" }
const _hoisted_22 = { class: "number final font-bold mr-3" }

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import LayerIcon from '@/components/icons/LayerIcon.vue';
import { computed, ref } from 'vue';
import { FUNNEL_LAYERS } from './briefsData';
import { FunnelIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import FunnelFilters from './FunnelFilters.vue';
import PerformanceCards from './PerformanceCards.vue';
import { LayerInterface } from './playgroundModels';


export default /*@__PURE__*/_defineComponent({
  __name: 'FunnelCard',
  props: ['briefData'],
  setup(__props) {



const funnelLayers = FUNNEL_LAYERS;
const layers = ref<LayerInterface[]>([]);
const showFilters = ref(false);

const secondRow = ref(null);
const thirdRow = ref(null);
const fourthRow = ref(null);

const handleFilter = (filterLayer: {layerIndex: number, newTotal: number, rowNumber: number}) => {
    if (filterLayer.rowNumber == 2) {
        secondRow.value = secondRow.value ? secondRow.value : Array(layers.value.length);
        layers.value.map(({total, ...rest}, index) => {
            if (index == filterLayer.layerIndex) {
                secondRow.value[filterLayer.layerIndex] = {
                    total: filterLayer.newTotal,
                    ...rest
                }
            }
        })
    }
    if (filterLayer.rowNumber == 3) {
        thirdRow.value = thirdRow.value ? thirdRow.value : Array(layers.value.length);
        layers.value.map(({total, ...rest}, index) => {
            if (index == filterLayer.layerIndex) {
                thirdRow.value[filterLayer.layerIndex] = {
                    total: filterLayer.newTotal,
                    ...rest
                }
            }
        })
    }
    if (filterLayer.rowNumber == 4) {
        fourthRow.value = fourthRow.value ? fourthRow.value : Array(layers.value.length);
        layers.value.map(({total, ...rest}, index) => {
            if (index == filterLayer.layerIndex) {
                fourthRow.value[filterLayer.layerIndex] = {
                    total: filterLayer.newTotal,
                    ...rest
                }
            }
        })
    }
}

const addLayer = (layerId: number) => {
    const selectedLayer = funnelLayers.find(layer => layer.id == layerId);
    layers.value.push(selectedLayer)
}

const removeLayer = (index: number) => layers.value.splice(index, 1)

const finalCombinations = computed(() => {
    if (fourthRow.value) {
        return fourthRow.value.reduce((accumulator, currentValue) => accumulator * currentValue.total,1)
    }
    if (thirdRow.value) {
        return thirdRow.value.reduce((accumulator, currentValue) => accumulator * currentValue.total,1)
    }
    if (secondRow.value) {
        return secondRow.value.reduce((accumulator, currentValue) => accumulator * currentValue.total,1)
    }
    else {
        return layers.value.reduce((accumulator, currentValue) => accumulator * currentValue.total,1) 
    }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[8] || (_cache[8] = _createElementVNode("p", { class: "uppercase font-semibold text-gray-700" }, [
        _createTextVNode(" Technology Assets Repository "),
        _createElementVNode("span", { class: "text-sm normal-case font-normal" }, "(Select some assets to start the process)")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_unref(Popover), { class: "relative mr-4" }, {
          default: _withCtx(() => [
            _createVNode(_unref(PopoverButton), { class: "flex items-center" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(LayerIcon, { class: "icon icon-small white mr-2" }),
                  _cache[5] || (_cache[5] = _createElementVNode("p", { class: "text-white text-xs uppercase" }, "Add layer", -1))
                ])
              ]),
              _: 1
            }),
            _createVNode(_unref(PopoverPanel), { class: "absolute z-10 bg-moss rounded top-12 right-0 min-w-72 text-white" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", null, [
                    _cache[6] || (_cache[6] = _createElementVNode("p", { class: "uppercase py-2 font-medium text-xs pl-2 border-b" }, " substrate", -1)),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("p", {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (addLayer(1))),
                        class: "w-1/2 py-3 font-normal text-xs text-center cursor-pointer border-r"
                      }, " Paper "),
                      _createElementVNode("p", {
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (addLayer(2))),
                        class: "w-1/2 py-3 font-normal text-xs text-center cursor-pointer"
                      }, " Plastic ")
                    ])
                  ]),
                  _createElementVNode("div", null, [
                    _cache[7] || (_cache[7] = _createElementVNode("p", { class: "uppercase py-2 font-medium text-xs pl-2 border-b border-gray-300" }, " coating", -1)),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("p", {
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (addLayer(3))),
                        class: "w-1/2 py-3 font-normal text-xs text-center cursor-pointer border-r"
                      }, " Barrier Technology "),
                      _createElementVNode("p", {
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (addLayer(4))),
                        class: "w-1/2 py-3 font-normal text-xs text-center cursor-pointer"
                      }, " Heat Seals ")
                    ])
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_unref(FunnelIcon), {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (showFilters.value = !showFilters.value)),
            class: "icon thick icon-small white"
          })
        ])
      ])
    ]),
    (layers.value.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(layers.value, (layer, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass(`flex items-center w-full rounded-full p-1  m-2 uppercase font-funnel relative ${layer.color}`)
              }, [
                _createElementVNode("span", _hoisted_10, _toDisplayString(layer.total), 1),
                _createElementVNode("p", null, _toDisplayString(layer.name), 1),
                _createVNode(_unref(XMarkIcon), {
                  onClick: ($event: any) => (removeLayer(index)),
                  class: "absolute right-3 icon white thick icon-small"
                }, null, 8, ["onClick"])
              ], 2))
            }), 128))
          ]),
          (secondRow.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(secondRow.value, (layer, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                    layer
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(`flex items-center w-1/3 rounded-full p-1 m-2 uppercase font-funnel relative ${layer.color}`)
                        }, [
                          _createElementVNode("span", _hoisted_12, _toDisplayString(layer.total), 1),
                          _createElementVNode("p", null, _toDisplayString(layer.name), 1),
                          _createVNode(_unref(XMarkIcon), {
                            onClick: ($event: any) => (removeLayer(index)),
                            class: "absolute right-3 icon white thick icon-small"
                          }, null, 8, ["onClick"])
                        ], 2))
                      : (_openBlock(), _createElementBlock("div", _hoisted_13))
                  ], 64))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (thirdRow.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(thirdRow.value, (layer, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                    layer
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(`flex items-center w-1/4 rounded-full p-1 m-2 uppercase font-funnel relative ${layer.color}`)
                        }, [
                          _createElementVNode("span", _hoisted_15, _toDisplayString(layer.total), 1),
                          _createElementVNode("p", null, _toDisplayString(layer.name), 1),
                          _createVNode(_unref(XMarkIcon), {
                            onClick: ($event: any) => (removeLayer(index)),
                            class: "absolute right-3 icon white thick icon-small"
                          }, null, 8, ["onClick"])
                        ], 2))
                      : (_openBlock(), _createElementBlock("div", _hoisted_16))
                  ], 64))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (fourthRow.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fourthRow.value, (layer, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                    layer
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(`flex items-center w-1/5 rounded-full p-1 m-2 uppercase font-funnel relative ${layer.color}`)
                        }, [
                          _createElementVNode("span", _hoisted_18, _toDisplayString(layer.total), 1),
                          _createElementVNode("p", null, _toDisplayString(layer.name), 1),
                          _createVNode(_unref(XMarkIcon), {
                            onClick: ($event: any) => (removeLayer(index)),
                            class: "absolute right-3 icon white thick icon-small"
                          }, null, 8, ["onClick"])
                        ], 2))
                      : (_openBlock(), _createElementBlock("div", _hoisted_19))
                  ], 64))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("span", _hoisted_22, _toDisplayString(finalCombinations.value), 1),
              _cache[9] || (_cache[9] = _createElementVNode("p", null, "final combinations", -1))
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (showFilters.value)
      ? (_openBlock(), _createBlock(FunnelFilters, {
          key: 1,
          layers: layers.value,
          onFilterLayer: handleFilter
        }, null, 8, ["layers"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["transition-opacity duration-1000 delay-300", [layers.value.length > 0 ? 'opacity-100' : 'opacity-0']])
    }, [
      _createVNode(PerformanceCards, { "brief-data": __props.briefData }, null, 8, ["brief-data"])
    ], 2)
  ], 64))
}
}

})