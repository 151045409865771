import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "already-uploaded-files mt-3 px-2"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "file-preview"
}
const _hoisted_4 = ["href"]


import { DocumentCheckIcon, TrashIcon } from '@heroicons/vue/24/outline';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssetFilesList',
  props: ['filesToShow', 'filesRemoval', 'showLabel'],
  emits: ['filesToRemove'],
  setup(__props, { emit: __emit }) {


const emit = __emit;


return (_ctx: any,_cache: any) => {
  return (__props.filesToShow.length && __props.filesToShow.some(f => !f.node.deleted))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (__props.showLabel)
          ? (_openBlock(), _createElementBlock("label", _hoisted_2, "Files"))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.filesToShow, (file, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
            (!file.node.deleted)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("a", {
                    href: file.node.file.url,
                    target: "_blank",
                    class: "flex items-center break-all"
                  }, [
                    _createVNode(_unref(DocumentCheckIcon), { class: "icon thick icon-file" }),
                    _createTextVNode(" " + _toDisplayString(file.node.file.name.replace('assets/', '')), 1)
                  ], 8, _hoisted_4),
                  (__props.filesRemoval)
                    ? (_openBlock(), _createBlock(_unref(TrashIcon), {
                        key: 0,
                        class: "icon icon-x-small shrink-0",
                        onClick: ($event: any) => (emit('filesToRemove', file.node)),
                        title: "Remove file"
                      }, null, 8, ["onClick"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})