<template>
    <div class="flex items-center justify-between mb-8">
        <p class="inner-title">Based on requirements these are possible solutions</p>
        <button @click="showRequirements = !showRequirements" class="collapsible">See Requirements <ChevronUpIcon class="chevron h-5 w-5 ml-1" :class="{'up': showRequirements}"/></button>
    </div>
    <div class="requirements" v-if="showRequirements">
        <template v-for="(target, index) in targets" :key="index">
            <p>{{ target.label }}: <span>{{ target.value }}</span></p>
        </template>
    </div>
</template>
<script setup lang="ts">
import { ChevronUpIcon } from '@heroicons/vue/20/solid';
import { programTargetInterface } from "@/models/programsInterface";
import { PropType, defineProps, ref} from "vue";

defineProps({ targets:  { type: Array as PropType<programTargetInterface[]>},});

const showRequirements = ref(false);

</script>
<style lang="scss" scoped>
.collapsible {
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 20px;
    padding: 4px 16px;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 0.85rem;
    .chevron{
        transition: all 0.3s ease-out;
        &.up {
        transform: rotate(180deg);
        }
    }
}
.inner-title {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1rem;
}
.requirements {
    display: flex;
    margin-bottom: 2rem;
    margin-top: -3rem;
    flex-direction: column;
    border-right: 1px solid #ddd;
    padding-right: 1rem;
    padding-bottom: 1rem;
    padding-top: 2rem;
    border-bottom: 1px solid #ddd;
    p {
        text-align: right;
        text-transform: uppercase;
        font-size: 0.9rem;
        font-weight: 300;
        span {
            font-weight: 500;
            text-transform: none;
            font-size: 0.9rem;
            margin-left: 0.5rem;

        }
    }

}
</style>