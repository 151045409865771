<template>
    <div v-for="item in dataForAccordionList" :key="item.title">
        <div v-if="item.content" class="accordion-item">
            <div v-if="item.content" @click="toggleExpandedField(item.title)" class="title">
                <ChevronRightIcon class="icon" :class="{'face-down': expandedField.includes(item.title)}"/>
                <p> {{item.title}} </p> 
            </div>
            <div v-if="item.title === 'Info'" :class="{'visible': expandedField.includes(item.title)}" class="content">
                <RunGroupDetailInfoContent :info-content="item.content" :editable="false" />
            </div>
            <div v-else :class="{'visible': expandedField.includes(item.title)}" class="content">{{ item.content }}</div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { defineProps, ref } from 'vue';
    import { ChevronRightIcon } from '@heroicons/vue/24/solid';
    import RunGroupDetailInfoContent from "@/tea/components/rungroups/RunGroupDetailInfoContent.vue";
    const props = defineProps(['dataForAccordionList']);
    const expandedField = ref(props.dataForAccordionList.filter(item => item.expanded).map(ei => ei.title));
    const toggleExpandedField = (title:string) => {
        const titlePos = expandedField.value.indexOf(title);
        titlePos == -1 ? expandedField.value.push(title) : expandedField.value.splice(titlePos, 1);
    }
</script>