import store from "@/store";
import { computed } from "vue";
import { generateStatePathGetter } from "@/utils/utils";

/**
 * Generates a set of computed properties for form fields, which are used for two-way data binding in Vue components.
 * 
 * This function dynamically creates Vue's `computed` properties for each field specified in the `fields` array. Each computed property:
 * - Retrieves the current value of the field from the Vuex store, based on the provided `module`, `base`, and `mutationValue`.
 * - Normalizes the value before committing any changes to the Vuex store to ensure consistent state updates.
 * 
 * @param {Object} params - The configuration object for generating the form models.
 * @param {Array} params.fields - An array of field objects, each containing a `mutationValue` property, representing the names of the fields.
 * @param {string} params.module - The Vuex store module where the state is located.
 * @param {string} params.base - The base path to the nested state object within the Vuex store.
 * @param {string} params.mutation - The Vuex mutation type used to update the state.
 * 
 * @returns {Object} An object where each key is a `mutationValue` from the `fields` array, and each value is a Vue `computed` property.
 * 
 * Each computed property:
 * - Uses `generateStatePathGetter(store, module, base)` to access the nested state object.
 * - Retrieves the field value using `field.mutationValue` as the key.
 * - Normalizes and commits changes to the Vuex store when the value is set, ensuring values are handled correctly and avoiding invalid states.
 */
export function useDynamicFormModels({fields, module, base, mutation}){
    return fields.reduce((acc, field) => {
        acc[field.mutationValue] = computed({
            get: () =>{
                // Access the nested state object by invoking the function returned by generateStatePathGetter(store, module, base).
                // Then, retrieve the value of the specific field using the field's mutationValue as the key.
                // The function generateStatePathGetter returns another function, which, when called, traverses the Vuex store to get the desired nested state object.
                return generateStatePathGetter(store,module,base)()[field.mutationValue];
            },
            set: (value) => {
                // Normalize the value to handle cases where it might be null, undefined, or "none".
                // If the value is null, undefined, or the string "none", we use a fallback value defined by `field?.nullValue`.
                // If `field?.nullValue` is not provided, we default to `null`. Otherwise, we use the provided `value`.
                const normalizedValue = value== "null" || value === null || value === undefined || value === "none" ? (field?.nullValue??null) : value;
                
                // Construct the mutation type using module and mutation
                const mutationType = `${module}/${mutation}`;

                // Commit the mutation with the normalized value
                store.commit(mutationType, { field: field.mutationValue, value: normalizedValue });
            }
        });
        return acc;  // <-- Ensure that acc is returned here
    }, {});
}
