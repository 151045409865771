import { provideApolloClient } from "@vue/apollo-composable";
import apolloClient from "@/utils/apolloClient";
import { AssetTypeConstantInterface, FilterConditionInterface } from "@/models/technologyAssetsInterface";
import { ASSET_TYPES } from "@/models/technologyAssetConstants";
import { AssetSimpreInterface } from "@/models/assetsComparisonTableInterfaces";

provideApolloClient(apolloClient);

const urlDynamicClass = (row) => {
  return row.url
    ? "font-light text-sm td-border text-center td-with-link"
    : "font-light text-center text-sm td-border";
};

const createConditionForBE = (condition: FilterConditionInterface) => {
  let newCondition = {};
  // There are multiple conditions, like materials or values with dropdown menus
  if (condition.multipleValuesToSearch.length > 0) {
    if (condition.lookupOperatorsForDropDown == 'must') {
      newCondition = {
        [condition.searchableValue.field]: {
          name: { must: condition.multipleValuesToSearch },
        },
      }
    }
    if (condition.lookupOperatorsForDropDown == 'in') {
      newCondition = {
        [condition.searchableValue.field]: {
          name: { in: condition.multipleValuesToSearch },
        },
      }
    }
  }
  else {
    if(condition.searchableValue.field === 'owners') {
      newCondition = {
        [condition.searchableValue.field]: {'nickname': {
          [condition.lookupOperator]: condition.valueToSearch,
        }},
      }
    } else {
      newCondition = {
        [condition.searchableValue.field]: {
          [condition.lookupOperator]: condition.valueToSearch,
        },
      }
    }
    
  }
  return newCondition;
}

const isConditionValid = (condition: FilterConditionInterface) => {
  return (
    condition.searchableValue
    && (condition.searchableValue.hasQuery || condition.searchableValue.field == 'assetType') 
    && !!condition.multipleValuesToSearch.length) 
  || 
  (
    condition.searchableValue
    && (!condition.searchableValue.hasQuery && condition.searchableValue.field != 'assetType') 
    && condition.valueToSearch != ''
  )
}

const getDefaultState = () => {
  return {
    goodTableColumns: [
      {
        label: "Nickname",
        field: "nickname",
        hidden: false,
        tdClass: "font-medium text-sm td-border",
        thClass: "good-table-th-class ",
        width: "250px",
        filtrable: true,
        sortable: true,
      },
      {
        label: "Title",
        field: "title",
        hidden: false,
        html: true,
        tdClass: "font-light text-sm td-border",
        thClass: "good-table-th-class",
        width: "250px",
        filtrable: true,
        sortable: true,
      },
      {
        label: "Abstract",
        field: "abstract",
        html: true,
        hidden: false,
        tdClass: "font-light text-sm td-border",
        thClass: "good-table-th-class",
        width: "450px",
        filtrable: true,
        sortable: true,
      },
      {
        label: "Supplier",
        field: "owners",
        html: true,
        hidden: false,
        tdClass: "font-light text-sm td-border",
        thClass: "good-table-th-class",
        width: "250px",
        filtrable: true,
        sortable: true,
      },
      {
        label: "Files",
        field: "files",
        html: true,
        hidden: false,
        tdClass: "font-light text-sm td-border",
        thClass: "good-table-th-class",
        width: "250px",
        filtrable: false,
        sortable: true,
      },
      {
        label: "Description",
        field: "description",
        html: true,
        hidden: true,
        tdClass: "font-light text-sm td-border",
        thClass: "good-table-th-class",
        width: "450px",
        filtrable: true,
        sortable: true,
      },
      {
        label: "Type",
        field: "assetType",
        hidden: true,
        tdClass: "font-light text-sm td-border",
        thClass: "good-table-th-class",
        filtrable: false,
        width: "250px",
        sortable: false,
      },
      {
        label: "Source",
        field: "sources",
        hidden: true,
        tdClass: "font-light text-sm td-border",
        thClass: "good-table-th-class",
        filtrable: true,
        hasQuery: true,
        width: "250px",
        sortable: true,
      },
      {
        label: "Publication Date",
        field: "publicationDate",
        hidden: true,
        tdClass: "font-light text-sm td-border",
        thClass: "good-table-th-class",
        width: "250px",
        sortable: true,
      },
      {
        label: "Tags",
        field: "tags",
        hidden: true,
        tdClass: "font-light text-sm td-border",
        thClass: "good-table-th-class",
        filtrable: false,
        hasQuery: true,
        width: "250px",
        sortable: true,
      },
      {
        label: "Applications",
        field: "applicationTypes",
        hidden: false,
        tdClass: "font-light text-sm td-border",
        thClass: "good-table-th-class",
        filtrable: true,
        hasQuery: true,
        width: "250px",
        sortable: true,
      },
      {
        label: "Materials",
        field: "materials",
        hidden: false,
        tdClass: "font-light text-sm td-border",
        thClass: "good-table-th-class",
        filtrable: true,
        hasQuery: true,
        width: "250px",
        sortable: true,
      },
      {
        label: "Properties",
        field: "properties",
        hidden: false,
        tdClass: "font-light text-sm td-border",
        thClass: "good-table-th-class",
        createSnippet: false,
        filtrable: true,
        hasQuery: true,
        width: "250px",
        sortable: true,
      },
      {
        label: "Processing Techniques",
        field: "processingTechniques",
        hidden: false,
        tdClass: "font-light text-sm td-border",
        thClass: "good-table-th-class",
        filtrable: true,
        hasQuery: true,
        width: "250px",
        sortable: true,
      },
      {
        label: "Notes",
        field: "notes",
        html: true,
        hidden: true,
        tdClass: "font-light text-sm td-border",
        thClass: "good-table-th-class",
        createSnippet: false,
        filtrable: true,
        width: "250px",
        sortable: true,
      },
      {
        label: "Url",
        field: "url",
        html: true,
        hidden: false,
        tdClass: urlDynamicClass,
        thClass: "good-table-th-class",
        width: "80px",
      },
      {
        label: "Standards",
        field: "standards",
        hidden: true,
        tdClass: "font-light text-sm td-border",
        thClass: "good-table-th-class",
        filtrable: true,
        hasQuery: true,
        width: "250px",
        sortable: true,
      },
    ],
    lookupOperators: [
      {
        label: "Is equal to",
        value: "iexact",
      },
      {
        label: "Contains",
        value: "icontains",
      },
      {
        label: "Starts with",
        value: "istartswith",
      },
      {
        label: "Ends with",
        value: "iendswith",
      },
    ],
    lookupOperatorsForDropDown: [
      {
        label: "Includes all",
        value: "must"
      },
      {
        label: "At least one",
        value: "in"
      }
    ],
    filterModalIsOpen: false,
    logicalOperators: ["or", "and"],
    quickSearchFilter: {},
    filterFormConditions: [],
    selectedProgramsForFilter: [],
    selectedLayerTypeForFilter: [],
    filter: {},
    sortValue: "id",
    sortDirection: "-",
    selectedTab: ASSET_TYPES.OTHER,
    tablePerPage: 10,
    tablePage: 1,
    comparisonCriteriaModalIsOpen: false, 
    assetsToCompare: [],
    comparisonProperties: [],
    comparisonQuantitativeProperties: [],
  };
};

const state = getDefaultState();

export const mutations = {
  setQuickSearchFilterCriteria(state, payload: { searchText: string }) {
    const searchFilter = {
      or: [
        { nickname: { icontains: payload.searchText } },
        { title: { icontains: payload.searchText } },
        { abstract: { icontains: payload.searchText } },
        { assetType: { icontains: payload.searchText } },
        { sources: { name: { icontains: payload.searchText } } },
        { applicationTypes: { name: { icontains: payload.searchText } } },
        { tags: { name: { icontains: payload.searchText } } },
        { description: { icontains: payload.searchText } },
        { notes: { icontains: payload.searchText } },
        { materials: { name: { icontains: payload.searchText } } },
        { properties: { name: { icontains: payload.searchText } } },
        { processingTechniques: { name: { icontains: payload.searchText } } },
        { standards: { title: { icontains: payload.searchText } } },
      ],
    };
    state.quickSearchFilter = searchFilter;
  },

  setSelectedLayerTypeForFilter(state, layers) {
    state.selectedLayerTypeForFilter = layers;
  },

  setSelectedProgramsForFilter(state, programs) {
    state.selectedProgramsForFilter = programs
  },

  transfromFormConditionsIntoFilters(state) {
    if (state.filterFormConditions.length == 1) {
      // If only one and valid condition => create it and push it into filter
      const condition:FilterConditionInterface = state.filterFormConditions[0]
      const validCondition = isConditionValid(condition);
      if (validCondition) {
        state.filter = createConditionForBE(condition);
      }
    }
    if (state.filterFormConditions.length > 1) {
      state.filter = { or: [], and: [] };
      // Only valid conditions
      const validConditions = state.filterFormConditions.filter((condition: FilterConditionInterface) => isConditionValid(condition));
      // Remove first condition and put ir on AND/OR array based on the second condition
      const firstFormCondition = validConditions.shift();
      state.filter[validConditions[0].logicalOperator].push(createConditionForBE(firstFormCondition));
      // Add the rest of the conditions in the correct array based on their condition for multiple or simple 
      validConditions.map((condition: FilterConditionInterface) => {
        state.filter[condition.logicalOperator].push(createConditionForBE(condition));
      });
    }
  },

  addSelectedLayerTypesAsFilter(state) {
    if(state.selectedLayerTypeForFilter.length > 0) {
      const newCondition = {targetLayers: {name: {in: state.selectedLayerTypeForFilter}}};
      const keys = Object.keys(state.filter);
      if (keys.length == 0) {
        state.filter = newCondition;
      }
      if(keys.length > 0 && keys.includes('and')) {
        const index = state.filter.and.findIndex(condition => Object.keys(condition).includes('targetLayers'));
        index != -1 ? state.filter.and[index] = newCondition : state.filter.and.push(newCondition);
      }
      if(keys.length > 0 && !keys.includes('and')) {
        const oldCondition = state.filter;
        state.filter =  keys.includes('targetLayers') ? newCondition : { and: [oldCondition, newCondition] }
      }
    }
  },

  addSelectedProgramsAsFilter(state) {
    if(state.selectedProgramsForFilter.length > 0) {
      const newCondition = {linkedPrograms: {name: {in: state.selectedProgramsForFilter}}};
      const keys = Object.keys(state.filter);
      if (keys.length == 0) {
        state.filter = newCondition;
      }
      if(keys.length > 0 && keys.includes('and')) {
        const index = state.filter.and.findIndex(condition => Object.keys(condition).includes('linkedPrograms'));
        index != -1 ? state.filter.and[index] = newCondition : state.filter.and.push(newCondition);
      }
      if(keys.length > 0 && !keys.includes('and')) {
        const oldCondition = state.filter;
        state.filter =  keys.includes('linkedPrograms') ? newCondition : { and: [oldCondition, newCondition] }
      }
    }
  },

  restoreQuickSearchFilterCriteria(state) {
    state.quickSearchFilter = {};
    state.sortDirection = "-";
    state.sortValue = "id";
  },
  setSortCriteria(state, payload: { sortValue: string; sortDirection: string }) {
    state.sortValue = payload.sortValue;
    state.sortDirection = payload.sortDirection;
  },
  setSortValue(state, payload: { sortValue: string }) {
    state.sortValue = payload.sortValue;
  },
  setSortDirection(state, payload: { sortDirection: string }) {
    state.sortDirection = payload.sortDirection;
  },

  showHideColumn(state, payload: { columnValue: string }) {
    const newColumns = state.goodTableColumns.map((column) => {
      if (column.label === payload.columnValue) {
        return { ...column, hidden: !column.hidden };
      }
      return column;
    });
    state.goodTableColumns = newColumns;
  },
  hideAllColumns(state) {
    const newColumns = state.goodTableColumns.map((column) => {
      return { ...column, hidden: true };
    });
    state.goodTableColumns = newColumns;
  },
  showAllColumns(state) {
    const newColumns = state.goodTableColumns.map((column) => {
      return { ...column, hidden: false };
    });
    state.goodTableColumns = newColumns;
  },
  
  restoreAllValues(state) {
        state.goodTableColumns = getDefaultState().goodTableColumns;
        state.sortDirection = getDefaultState().sortDirection;
        state.sortValue = getDefaultState().sortValue;
        state.filter = getDefaultState().filter;
        state.filterFormConditions = getDefaultState().filterFormConditions;
        state.selectedStatusForFilter = getDefaultState().selectedLayerTypeForFilter;
        state.selectedProgramsForFilter = getDefaultState().selectedProgramsForFilter;
  },
  closeModal(state) {
    state.filterModalIsOpen = false;
  },
  openModal(state) {
    state.filterModalIsOpen = true;
  },
  updateTabSelection(state, payload: {selectedTab: AssetTypeConstantInterface}) {
    state.selectedTab = payload.selectedTab;
  },
  setTableServerOptions(state, payload: {perPage: number, page:number}) {
    state.tablePerPage = payload.perPage;
    state.tablePage = payload.page
  },
  setTablePage(state, payload: {page: number}) {
    state.tablePage = payload.page
  },
  setTablePerPage(state, payload: {perPage: number}) {
    state.tablePerPage = payload.perPage
  },
 
  closeComparisonCriteriaModal(state) {
    state.comparisonCriteriaModalIsOpen = false
  },
  openComparisonCriteriaModal(state) {
    state.comparisonCriteriaModalIsOpen = true
  },
  setSelectedAssetForComparison(state, payload: {assetsToCompare: AssetSimpreInterface[]}) {
    state.assetsToCompare = payload.assetsToCompare;
  },
  setSelectedPropertiesForComparison(state, payload: {properties, quantitativeProperties}){
    state.comparisonProperties = payload.properties;
    state.comparisonQuantitativeProperties = payload.quantitativeProperties;
  }
};

const actions = {};

const getters = {
  getAssetsTableColumns(state) {

    return state.goodTableColumns;
  },

  getSortValue(state) {
    return state.sortValue;
  },

  getSortCriteria(state) {
    const criteria =
      state.sortDirection == "-"
        ? `${state.sortDirection}${state.sortValue}`
        : state.sortValue;
    return criteria;
  },

  getTotalAssets(state) {
    return state.totalAssets
  },
  getSelectedTab(state) {
    return state.selectedTab
  },
  getTablePage(state){
    return state.tablePage
  },
  getTablePerPage(state){
    return state.tablePerPage
  },
  getComparisonProperties(state) {
    return state.comparisonProperties
  },
  getComparisonQuantitativeProperties(state) {
    return state.comparisonQuantitativeProperties
  },
  getAssetsToCompare(state) {
    return state.assetsToCompare
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
