import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

import RunGroupDetailHeader from "@/tea/components/rungroups/RunGroupDetailHeader.vue";
import { computed,ref } from "vue";
import AccordionList from "@/tea/components/rungroups/AccordionList.vue";
import AccordionListEditable from "@/tea/components/rungroups/AccordionListEditable.vue";
import { useStore } from "vuex";
import RunRepo from "@/tea/components/runs/RunRepo.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'RunGroupDetail',
  props: ["runGroupDetail","runGroupId"],
  emits: ["onRunSearchText"],
  setup(__props) {

const store = useStore();


const update =computed(()=> store.getters["runGroupModule/update"]);
const editModeOn = computed(() => update.value.editable);

const runCreated=ref('')
const handleOnRunCreated=()=>{
  runCreated.value=Date.now().toString()
}

const runSearchTerm = ref('')
const handleOnRunSearchText=(value)=>{
  runSearchTerm.value= value;
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(RunGroupDetailHeader, {
      title: __props.runGroupDetail.value.title,
      "users-activity": __props.runGroupDetail.value.activity,
      onOnRunCreated: handleOnRunCreated,
      onOnRunSearchText: handleOnRunSearchText,
      runGroupId: __props.runGroupId
    }, null, 8, ["title", "users-activity", "runGroupId"]),
    (!editModeOn.value)
      ? (_openBlock(), _createBlock(AccordionList, {
          key: 0,
          "data-for-accordion-list": __props.runGroupDetail.value.accordionList
        }, null, 8, ["data-for-accordion-list"]))
      : (_openBlock(), _createBlock(AccordionListEditable, {
          key: 1,
          "data-for-accordion-list": __props.runGroupDetail.value.accordionList
        }, null, 8, ["data-for-accordion-list"])),
    _createElementVNode("div", null, [
      _createVNode(RunRepo, {
        runCreated: runCreated.value,
        searchTerm: runSearchTerm.value,
        runGroupId: __props.runGroupId
      }, null, 8, ["runCreated", "searchTerm", "runGroupId"])
    ])
  ]))
}
}

})