import { ref } from "vue";
import { lastAssetsFromQInterface } from "./useQToGetLasNumbersForAssets";

export interface HasuraLastNumbers {
    compound: number,
    heatseal: number, 
    metal: number, 
    paper: number, 
    plastic_film: number, 
    primer: number, 
    tie: number 
}

export const LAYERS_FROM_Q = ['Compound', 'Heat Seal', 'Metal', 'Substrate', 'Primer', 'Tie'];

export interface ParsedLastNumbers {
    layerType: string, 
    material?: string, 
    nicknameToPropose: string
}

export const useHasuraAndQToProposeNicknames = (hasuraLastNumbers:HasuraLastNumbers, qLastNumbers: lastAssetsFromQInterface, selectedLayerName: string, materialNames: string[]) => {
    
    const nickname = ref('');
    const helpText = ref('');
    const disableInput = ref(true);

    const createNickname = () =>  {
        let newNickname = '';
        if (selectedLayerName == LAYERS_FROM_Q[0]) {
            newNickname = qLastNumbers.compound && qLastNumbers.compound > hasuraLastNumbers?.compound ? `${LAYERS_FROM_Q[0]}#${qLastNumbers.compound +1}` : `${LAYERS_FROM_Q[0]}#${hasuraLastNumbers.compound +1}` 
        }
        if (selectedLayerName == LAYERS_FROM_Q[1]) {
            newNickname = qLastNumbers.heatseal && qLastNumbers.heatseal > hasuraLastNumbers?.heatseal ? `Heat-Seal#${qLastNumbers.heatseal +1}` : `Heat-Seal#${hasuraLastNumbers.heatseal +1}` 
        }
        if (selectedLayerName == LAYERS_FROM_Q[2]) {
            newNickname = qLastNumbers.metal && qLastNumbers.metal > hasuraLastNumbers?.metal ? `${LAYERS_FROM_Q[2]}#${qLastNumbers.metal +1}` : `${LAYERS_FROM_Q[2]}#${hasuraLastNumbers.metal +1}` 
        }
        if (selectedLayerName == LAYERS_FROM_Q[4]) {
            newNickname = qLastNumbers.primer && qLastNumbers.primer > hasuraLastNumbers?.primer ? `${LAYERS_FROM_Q[4]}#${qLastNumbers.primer +1}` : `${LAYERS_FROM_Q[4]}#${hasuraLastNumbers.primer +1}` 
        }
        if (selectedLayerName == LAYERS_FROM_Q[5]) {
            newNickname = qLastNumbers.tie && qLastNumbers.tie > hasuraLastNumbers?.tie ? `${LAYERS_FROM_Q[5]}#${qLastNumbers.tie +1}` : `${LAYERS_FROM_Q[5]}#${hasuraLastNumbers.tie +1}` 
        }
        return newNickname
    
    }

    if (!selectedLayerName) {
        nickname.value = '';
        helpText.value = 'Please select a LAYER FUNCTIONALITY to get a propsal for the nickname';
        disableInput.value = true;
        return {nickname, helpText, disableInput}
    }

    if (!LAYERS_FROM_Q.includes(selectedLayerName)) {        
        nickname.value = '';
        helpText.value = 'No rules YET for this type of layer'; 
        disableInput.value = false;
        return {nickname, helpText, disableInput}
    }

    if (selectedLayerName != 'Substrate') {
        nickname.value = createNickname();
        helpText.value = 'Please use this nickname to keep consistency '; 
        disableInput.value = false
    }

    if (selectedLayerName == 'Substrate' && materialNames.length == 0) {
        nickname.value = '';
        helpText.value = 'Plase select a MATERIAL for the substrate, like paper or plastic film'; 
        disableInput.value = true;
        return {nickname, helpText, disableInput} 
    }

    if (selectedLayerName == 'Substrate' && materialNames.length > 0 ) {
        if(materialNames.includes('Paper')) {
            nickname.value = qLastNumbers.paper && qLastNumbers.paper > hasuraLastNumbers?.paper ? `PAP${(qLastNumbers.paper +1).toString().padStart(3, '0')}` : `PAP${(hasuraLastNumbers.paper +1).toString().padStart(3, '0')}`;
            helpText.value = 'Please use this nickname to keep consistency '; 
            disableInput.value = false;
            return {nickname, helpText, disableInput}
        }
        if(materialNames.includes('Plastic film')) {
            nickname.value = qLastNumbers.plastic_film && qLastNumbers.plastic_film > hasuraLastNumbers?.plastic_film ? `Plastic-Film#${qLastNumbers.plastic_film +1}` : `Plastic-Film#${hasuraLastNumbers.plastic_film +1}`;
            helpText.value = 'Please use this nickname to keep consistency '; 
            disableInput.value = false;
            return {nickname, helpText, disableInput}
        }
        else {
            nickname.value = '';
            helpText.value = 'No rules YET for this type of layer'; 
            disableInput.value = false;
            return {nickname, helpText, disableInput}
        }
    }

    return {nickname, helpText, disableInput}
    
}