<template>
    <div class="flex items-center pl-4 mb-4">
        <p>Select assets linked to: </p> 
    </div> 
    <div v-if="programs" class="flex px-4 flex-wrap"> 
        <div v-for="program in programs" :key="program.id"> 
            <input type="checkbox" v-model="selectedPrograms" @change="setValueInStore" :value="program.name" :id="program.name" class="hidden peer">
            <label :for="program.name" class="transition-colors duration-200 ease-in-out peer-checked:bg-red-300 peer-checked:text-white cursor-pointer border m-1 px-3 py-2 rounded-lg" >  {{ program.name }} </label>
       </div>
    </div>
</template>
<script setup lang="ts">
import { PROGRAMS_OPTIONS } from '@/grapql/assetsFilterOptionsQueries';
import { useQuery } from '@vue/apollo-composable';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

export interface programVersionInterface {
    id: number, 
    name: string
}

const store = useStore();
const { result } = useQuery(PROGRAMS_OPTIONS);
const queryResult = computed(() => result?.value ?? null);

const programs = computed<programVersionInterface[]>(() => queryResult.value && queryResult.value.programs.edges.length ? queryResult.value.programs.edges.map((edge) => edge.node) : null);

const selectedPrograms = ref([]);

const setValueInStore = () => store.commit('assetsModule/setSelectedProgramsForFilter', selectedPrograms.value );

onMounted(() => {
    const programsFromStore = store.state.assetsModule.selectedProgramsForFilter;
    selectedPrograms.value = programsFromStore
})
</script>
<style lang="scss">
   
</style>