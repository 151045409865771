<template>
    <div class="grid grid-cols-7 gap-2 mt-4">
        <!-- DESIGN CARD -->
        <div class="col-span-5 program-detail-card mb-4 relative">
            <!-- Status and title, subtile -->
            <p class="cards-title ml-4 mt-10">{{sandwich.sample}}</p>
            <p class="px-4 text-xs -mt-2">.</p>
            
            <!-- Design and info -->
            <div class="mb-4 px-2 grid grid-cols-9 items-center justify-around">
                
                <div class="col-span-9 mt-4 justify-center flex">
                    <svg class="layers" viewBox="-100 -45 350 120" xmlns="http://www.w3.org/2000/svg">
                        <g v-for="(layer, index) in sandwich.composition" :key="index" class="layer">
                            <!-- Fixed offset between layers -->
                            <g :style="`--offset-y:-${layer.offset}px;`">
                                <!-- Define paths for each layer -->
                                <path :d="`M0,${startingPoint - layer.offset} v-${layer.thickness} L50,${startingPoint - layer.offset - layer.thickness - inclination} L100,${startingPoint - layer.offset - layer.thickness} v${layer.thickness} L50,${startingPoint - layer.offset + inclination} Z`" :fill="layer.color" :filter="layer.color"></path>
                                <path :d="`M0,${startingPoint - layer.offset} v-${layer.thickness} L50,${startingPoint - layer.offset + inclination - layer.thickness} v${layer.thickness} Z`"></path>
                                <path :d="`M50,${startingPoint - layer.offset + inclination} v-${layer.thickness} L100,${startingPoint - layer.offset - layer.thickness} v${layer.thickness} Z`"></path>
                            </g>
                            
                            <!-- Left side lines and text -->
                            <line 
                                :x1="-10" 
                                :y1="startingPoint - layer.offset - (layer.thickness / 2)" 
                                :x2="-100" 
                                :y2="startingPoint - layer.offset - (layer.thickness / 2)" 
                                stroke="black" 
                                stroke-width="0.1"
                                stroke-dasharray="3,6"
                            ></line>
                            <text 
                                :x="-100" 
                                :y="startingPoint - layer.offset - (layer.thickness / 2) + -1" 
                            >{{ layer.layerType }}</text>
                            <text 
                                :x="-10" 
                                :y="startingPoint - layer.offset - (layer.thickness / 2) + -1" 
                                text-anchor="end"
                            >. gsm</text>

                            <!-- Right side lines and text -->
                            <line 
                                :x1="110" 
                                :y1="startingPoint - layer.offset - (layer.thickness / 2)" 
                                :x2="250" 
                                :y2="startingPoint - layer.offset - (layer.thickness / 2)" 
                                stroke="black" 
                                stroke-width="0.1"
                                stroke-dasharray="3,6"
                            ></line>
                            <a :href="layer.link" target="_blank">
                            <text
                                :x="110" 
                                :y="startingPoint - layer.offset - (layer.thickness / 2) + -1" 
                            >{{ layer.layerName }}</text>
                            </a>
                        </g>
                    </svg>
                </div>
            </div>
            <div class="col-span-5 border-t">
                <div class="grid grid-flow-col divide-x">
                    <div class="py-3 pl-6 flex flex-col">
                        <p class="font-medium text-black">{{sandwich.paperContent}} %</p>
                        <p class="uppercase font-normal text-xs">Paper content</p>
                    </div>
                    <div v-if="sandwich.sealingSides" class="py-3 pl-6 flex flex-col">
                        <p class="font-medium text-black">.</p>
                        <p class="uppercase font-normal text-xs">sealing sides</p>
                    </div>
                    <div class="py-3 pl-6 flex flex-col">
                        <p class="font-medium text-black">{{sandwich.gsmTotal}}</p>
                        <p class="uppercase font-normal text-xs">total grammage</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- OTR - WVTR - HS -->
        <div class="col-span-2 program-detail-card mb-4">
            <div class="grid grid-rows-7 h-full">
                <!-- OTR -->
                <div v-if="sandwich.otr" class="row-span-3 flex border-b">
                    <div class="w-1/6 flex justify-center items-center border-r border-dashed">
                        <p class="-rotate-90 font-medium text-black">OTR</p>
                    </div>
                    <div class="w-5/6 flex flex-col justify-center">
                        <div class="flex justify-around">
                            <div class="flex flex-col items-center justify-center leading-3 py-1">
                                <p class="lowercase text-xs text-gray-400">MIN</p>
                                <p class="text-black font-medium">{{ sandwich.otr.min }}</p> 
                            </div> 
                            <div class="flex flex-col items-center justify-center leading-3 py-1">
                                <p class="lowercase text-xs text-gray-400">max</p>
                                <p class="text-black font-medium">{{ sandwich.otr.max }}</p> 
                            </div> 
                        </div>
                        <p  class="text-xs border-y text-center text-black">{{ sandwich.otr.condition }}</p>
                    </div>
                </div>
                <!-- WVTR -->
                <div v-if="sandwich.wvtr" class="row-span-3 flex border-b">
                    <div class="w-1/6 flex justify-center items-center border-r border-dashed">
                        <p class="-rotate-90 font-medium text-black">WVTR</p>
                    </div>
                    <div class="w-5/6 flex flex-col justify-center">
                        <div class="flex justify-around">
                            <div class="flex flex-col items-center justify-center leading-3 py-1">
                                <p class="lowercase text-xs text-gray-400">MIN</p>
                                <p class="text-black font-medium">{{sandwich.wvtr.min}}</p> 
                            </div> 
                            <div class="flex flex-col items-center justify-center leading-3 py-1">
                                <p class="lowercase text-xs text-gray-400">max</p>
                                <p class="text-black font-medium">{{sandwich.wvtr.max}}</p> 
                            </div> 
                        </div>
                        <p  class="text-xs border-y text-center text-black">{{sandwich.wvtr.condition}}</p>
                    </div>
                </div>
                <!-- HS -->
                <div v-if="sandwich.heatSeal" class="row-span-3 flex border-b">
                    <div class="w-1/6 flex justify-center items-center border-r border-dashed">
                        <p class="-rotate-90 font-medium text-black">HS</p>
                    </div>
                    <div class="w-5/6 flex flex-col justify-between">
                        <div class="flex justify-around">
                            <div class="flex flex-col items-center justify-center leading-3 py-1">
                                <p class="lowercase text-xs text-gray-400">MIN</p>
                                <p class="text-black font-medium">{{sandwich.heatSeal.min}}</p> 
                            </div> 
                            <div class="flex flex-col items-center justify-center leading-3 py-1">
                                <p class="lowercase text-xs text-gray-400">max</p>
                                <p class="text-black font-medium">{{sandwich.heatSeal.max}}</p> 
                            </div> 
                        </div>
                        <p  class="text-xs border-y text-center text-black">{{sandwich.heatSeal.condition}}</p>
                    </div>
                </div>
                <div v-if="sandwich.lastCoatingTechnique" class="row-span-3 flex">
                    <div class="w-1/6 flex justify-center items-center border-r border-dashed">
                        <p class="-rotate-90 font-medium text-black" title="Last Coating">LC</p>
                    </div>
                    <div class="w-5/6 flex flex-col justify-between">
                        <div class="flex justify-around">
                            <div class="flex flex-col items-center justify-center leading-3 py-1">
                                <p class="lowercase text-xs text-gray-400">SPEED</p>
                                <p class="text-black font-medium text-xs">{{sandwich.lastCoatingTechnique.speed}}</p> 
                            </div> 
                            <div class="flex flex-col items-center justify-center leading-3 py-1">
                                <p class="lowercase text-xs text-gray-400">TEMP</p>
                                <p class="text-black font-medium text-xs">{{sandwich.lastCoatingTechnique.meltingTemperature}}</p> 
                            </div> 
                            <div class="flex flex-col items-center justify-center leading-3 py-1">
                                <p class="lowercase text-xs text-gray-400">PRESSURE</p>
                                <p class="text-black font-medium text-xs">{{sandwich.lastCoatingTechnique.meltPressure}}</p> 
                            </div> 
                        </div>
                        <p  class="text-xs border-y text-center text-black">{{sandwich.lastCoatingTechnique.technique}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script lang="ts" setup>
import { defineProps,ref,computed } from 'vue';
const props = defineProps(['content']);

// Create a computed property to modify and return the entire content
const sandwich = computed(() => {
  // Clone the entire content object to avoid mutating the prop directly
  const contentClone = { ...props.content };

  // Modify the compositions array within the cloned content
  contentClone.composition = contentClone.composition.map((item,index) => ({
    ...item,
    offset: index * baseOffset.value,
    thickness: item.thickness ?? 2,
    color: colors[index % colors.length],
  }));

  // Return the modified content object
  return contentClone;
});


const colors = ['#1c70b2', '#badfd9', '#5fa2a6', '#d4c7c1', '#2a87d3', '#7d91bc'];
const baseOffset = computed(() => {
  const layersCount = props.content.composition.length;

  if (layersCount < 4) {
    return 14;
  } else if (layersCount < 8) {
    return 12;
  } else {
    return 10;
  }
});
const startingPoint = 50;
const inclination = 18;

</script>
<style lang="scss" scoped>
    .layers {
        min-width: 100%;
        max-width: 100%;
        display: inline-flex;
        overflow: hidden;
    }

    text {
        font-weight: normal;
        font-size: 0.4rem;
        fill: rgba(0 0 0 / 50%);
    }
    a text:hover{
        fill: black; 
        text-decoration: underline
    }

    path:nth-child(2) {
        fill: rgba(0 0 0 / 20%);
    }

    path:nth-child(3){
        fill: rgba(255 255 255 / 20%);
    }

</style>