import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "asset-material" }
const _hoisted_2 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_3 = { class: "flex justify-between items-center flex-wrap" }
const _hoisted_4 = {
  key: 0,
  class: "w-1/2"
}
const _hoisted_5 = { class: "value" }
const _hoisted_6 = {
  key: 1,
  class: "w-1/2"
}
const _hoisted_7 = { class: "value" }
const _hoisted_8 = {
  key: 0,
  class: "lowercase"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { key: 1 }

import { AssetMaterialInterface } from '@/models/assetAssessmentInterfaces';
import { PencilSquareIcon } from '@heroicons/vue/24/outline';
import { ref} from 'vue';
import AssetMaterialPropertiesForm from './AssetMaterialPropertiesForm.vue';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssetMaterialProperties',
  props: {
    assetMaterial: {}
  },
  setup(__props: any) {

const props = __props;
const store = useStore();
const onEdit = ref(false);

const handleEdit = () => {
   store.commit('assetDetailModule/setEditableOn', {sectionToMakeEditbale: 'inDepth' });
   onEdit.value = true;
}

const onCancelEdition = () => {
   onEdit.value = false;
   store.commit('assetDetailModule/setErrorOnAssetMaterialUpdateResponse', {error: null, id: props.assetMaterial.id})
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("p", null, "material properties", -1)),
      (!onEdit.value)
        ? (_openBlock(), _createBlock(_unref(PencilSquareIcon), {
            key: 0,
            class: "icon",
            onClick: handleEdit
          }))
        : _createCommentVNode("", true)
    ]),
    (!onEdit.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.assetMaterial.blendPercentage)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _cache[1] || (_cache[1] = _createElementVNode("p", { class: "label" }, "Blend Percentage", -1)),
                  _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.assetMaterial.blendPercentage), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.assetMaterial.thickness)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _cache[2] || (_cache[2] = _createElementVNode("p", { class: "label" }, "Thickness", -1)),
                  _createElementVNode("p", _hoisted_7, [
                    _createTextVNode(_toDisplayString(_ctx.assetMaterial.thickness[0]) + " - " + _toDisplayString(_ctx.assetMaterial.thickness[1]) + " ", 1),
                    (_ctx.assetMaterial.thicknessUnit)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.assetMaterial.thicknessUnit.abbreviation), 1))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.assetMaterial.notes)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _cache[3] || (_cache[3] = _createElementVNode("p", { class: "label" }, "Notes", -1)),
                _createElementVNode("p", {
                  innerHTML: _ctx.assetMaterial.notes,
                  class: "notes"
                }, null, 8, _hoisted_10)
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createVNode(AssetMaterialPropertiesForm, {
            onCancelEdition: onCancelEdition,
            "asset-material": _ctx.assetMaterial
          }, null, 8, ["asset-material"])
        ]))
  ], 64))
}
}

})