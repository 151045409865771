import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid grid-cols-1 gap-4"
}
const _hoisted_2 = { class: "grid grid-cols-1 gap-4" }

import { ref,computed,watch } from 'vue';
import { useStore } from 'vuex';
import AIChatQuestion from "@/components/ai/AIChatQuestion.vue";
import AIChatAnswer from "@/components/ai/AIChatAnswer.vue";
import AIChatError from '@/components/ai/AIChatError.vue';
import LastPulse from './LastPulse.vue';
import RegulationsConfirm from "@/components/ai/RegulationsConfirm.vue";
import AIFlowBoxes from "@/components/ai/AIFlowBoxes.vue";
import AIEnd from "@/components/ai/AIEnd.vue";
import AIError from "@/components/ai/AIError.vue";
import {useQuery} from '@vue/apollo-composable';
import {useEdgesToMessages} from "@/composables/aiComposables";
import {CHAT_MESSAGES} from '@/grapql/aiQuery';
import { IChatHistroy,IChatQueue } from '@/models/aiInterfaces';
import { MessageType } from '@/models/aiModels';

export default /*@__PURE__*/_defineComponent({
  __name: 'AIChat',
  props: ['currentChatId'],
  setup(__props) {

const a = {
    "sample": "sample",
    "paperContent":"93.45",
    "gsmTotal":"35",
    "composition": [
        {"layerName": "asdf", "layerType": "asdasdf","processingTechnique":"something"},
        {"layerName": "asdf", "layerType": "asdasdf","processingTechnique":"something"},
        {"layerName": "asdf", "layerType": "asdasdf","processingTechnique":"something"},
        {"layerName": "asdf", "layerType": "asdasdf","processingTechnique":"something"},
        {"layerName": "asdf", "layerType": "asdasdf","processingTechnique":"something"},
        {"layerName": "asdf", "layerType": "asdasdf","processingTechnique":"something"},
        {"layerName": "asdf", "layerType": "asdasdf","processingTechnique":"something"},
        {"layerName": "asdf", "layerType": "asdasdf","processingTechnique":"something"}
    ],
    "otr":{
        "min":"5.2,",
        "max":"8.44",
        "condition":"23.0:432.23C",
        "unit":"mm"
    },
    "wvtr":{
        "min":"5.2,",
        "max":"8.44",
        "condition":"23.0:432.23C",
        "unit":"mm"
    }
};
const props = __props;

const store = useStore();


const isPulsingOrTyping = computed(()=> store.getters["aiModule/getIsPulsingOrTyping"]);

// get currentForm
const currentForm = computed(()=>store.state.aiModule.currentForm);

// get error
const showError = computed(()=> !!store.state.aiModule.error);

// get and check if is completed
const showEnd = computed(()=> store.getters["aiModule/getIsCompleted"]);

// Refs for the chat container and the bottom of the chat
const chatContainer = ref(null);
// const bottomOfChat = ref(null);

// chat history
const chatsHistory = ref<IChatHistroy>(null);

// show forms for retrying
const showRetry = computed(()=> store.getters["aiModule/getShowRetry"]);

// get chat
const chat = computed(()=> {
    return store.state.aiModule.chat;
});

// get chat Queue
const chatQueue = computed<IChatQueue[]>(()=> {
    return store.state.aiModule.chatQueue;
});

watch(() => props.currentChatId, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        //get chat history
        const {onResult , error} = useQuery(CHAT_MESSAGES, {chatId: props.currentChatId});
        if (error) {
        console.log(error);
        }
        onResult(async queryResult => {
            if (queryResult.data && queryResult?.data?.chat?.messages) {
                store.commit('aiModule/setChatHistoryMessages', {chatId:props.currentChatId, messages: useEdgesToMessages(queryResult.data.chat.messages.edges)});
            }
            chatsHistory.value = store.state.aiModule.chatsHistory.find(chat => chat.id === props.currentChatId);
        });
    }
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "pt-20 pb-20 ml-10 mr-20 flex",
    id: "chat-container",
    ref_key: "chatContainer",
    ref: chatContainer
  }, [
    (chatsHistory.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chatsHistory.value.messages, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "chat-message"
              }, [
                (item.messageType == _unref(MessageType).QUESTION)
                  ? (_openBlock(), _createBlock(AIChatQuestion, {
                      key: 0,
                      question: item.question
                    }, null, 8, ["question"]))
                  : (item.messageType == _unref(MessageType).ANSWER)
                    ? (_openBlock(), _createBlock(AIChatAnswer, {
                        key: 1,
                        answer: item.answer,
                        messageId: item.messageId,
                        isHistory: true
                      }, null, 8, ["answer", "messageId"]))
                    : (item.messageType == _unref(MessageType).ERROR)
                      ? (_openBlock(), _createBlock(AIChatError, {
                          key: 2,
                          error: item.error
                        }, null, 8, ["error"]))
                      : _createCommentVNode("", true)
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chatQueue.value, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "chat-message"
          }, [
            (item.messageType === _unref(MessageType).QUESTION)
              ? (_openBlock(), _createBlock(AIChatQuestion, {
                  key: 0,
                  question: item.question
                }, null, 8, ["question"]))
              : (item.messageType === _unref(MessageType).ANSWER)
                ? (_openBlock(), _createBlock(AIChatAnswer, {
                    key: 1,
                    answer: item.answer,
                    messageId: item.messageId
                  }, null, 8, ["answer", "messageId"]))
                : (item.messageType === _unref(MessageType).ERROR)
                  ? (_openBlock(), _createBlock(AIChatError, {
                      key: 2,
                      error: item.error
                    }, null, 8, ["error"]))
                  : _createCommentVNode("", true)
          ]))
        }), 128)),
        (currentForm.value=='regulationsConfirm' && !isPulsingOrTyping.value)
          ? (_openBlock(), _createBlock(RegulationsConfirm, { key: 0 }))
          : _createCommentVNode("", true),
        (currentForm.value=='regulationsNext' && !isPulsingOrTyping.value)
          ? (_openBlock(), _createBlock(AIFlowBoxes, { key: 1 }))
          : _createCommentVNode("", true),
        _createVNode(LastPulse)
      ]),
      (showEnd.value)
        ? (_openBlock(), _createBlock(AIEnd, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    (showError.value)
      ? (_openBlock(), _createBlock(AIError, { key: 1 }))
      : _createCommentVNode("", true),
    (showRetry.value)
      ? (_openBlock(), _createBlock(AIFlowBoxes, {
          key: 2,
          showRegulations: true,
          showProductBrief: true,
          showSmallIcons: true
        }))
      : _createCommentVNode("", true)
  ], 512))
}
}

})