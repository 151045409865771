import moment from "moment";
import { ref } from "vue";
import { useInitialsToUserLogo } from "./useInitialsToUserLogo";
import { useNodeContentAsList, useNodeContentAsListWithConcat, useParsingSourcesToDisplay } from "./useNodeContentAsList";
import { AssetDetailMainDataType } from "@/models/technologyAssetInterface";

export async function useAssetDataDetailToMainContent(asset) {
    const assetDetailMainData = ref<AssetDetailMainDataType>(null);
    try {
        assetDetailMainData.value = {
            title: asset?.nickname,
            activity: [
                {
                    title: 'Created by',
                    date: asset?.createdAt ? moment(asset?.createdAt, moment.ISO_8601).format("DD MMM YYYY") : null,
                    personName: asset?.createdBy ? `${asset?.createdBy.firstName} ${asset?.createdBy.lastName}` : null,
                    initials: asset?.createdBy ? useInitialsToUserLogo(asset?.createdBy.firstName, asset?.createdBy.lastName) : null
                },
                {   title: 'Updated by',
                    date: asset?.updatedAt ? moment(asset?.updatedAt, moment.ISO_8601).format("DD MMM YYYY") : null,
                    personName: asset?.updatedBy ? `${asset?.updatedBy?.firstName} ${asset?.updatedBy?.lastName}` : null,
                    initials: asset?.updatedBy ? useInitialsToUserLogo(asset?.updatedBy?.firstName, asset?.updatedBy?.lastName) : null 
                }
            ],
            accordionList: [
                {
                    title: 'Info',
                    infoContent: [
                        {
                            title: 'Asset Type',
                            content: asset?.otherTechnologyType ? asset?.otherTechnologyType.name : asset?.assetType.replace('_', " "),
                            mutationValue: null,
                            editable: false
                        },
                        {
                            title: 'Patent ID',
                            content: asset?.patentId ? asset?.patentId : null,
                            mutationValue: 'patentId',
                            editable: false
                        },
                        {
                            title: 'Doi ID',
                            content: asset?.doiId ? asset?.doiId : null,
                            mutationValue: 'doiId',
                            editable: true,
                            inputType: 'text',
                        },
                        {
                            title: 'Journal',
                            content: asset?.journal ? asset?.journal : null,
                            mutationValue: 'journal',
                            editable: true,
                            inputType: 'text',
                        },
                        {
                            title: 'Status',
                            content: asset?.status ? asset?.status.name : null,
                            mutationValue: 'status',
                            editable: false
                        },
                        {
                            title: 'Is Prepatent',
                            content: asset?.isPrepatent ? asset?.isPrepatent : null,
                            type: 'boolean',
                            mutationValue: 'isPrepatent',
                            editable: false
                        },
                        {
                            title: 'Sources',
                            content: useParsingSourcesToDisplay(asset?.assetSources.edges),
                            mutationValue: 'sources',
                            editable: false,
                            type: 'list',
                            inputType: "multipleSelect",
                            queryName: "sources",
                            queryOptions: "sourcesOptions",
                            extraWidth: true,
                        },
                        {
                            title: "Patent ID's",
                            content: asset?.patentIds && asset?.patentIds.length ? asset?.patentIds : null,
                            type: 'list',
                            mutationValue: 'patentIds',
                            editable: false
                        },
                        {
                            title: 'Url',
                            content: asset?.url,
                            type: 'link',
                            mutationValue: 'url',
                            editable: true,
                            extraWidth: false,
                        },                        
                        {
                            title: 'Links',
                            content: asset?.links && asset?.links.length > 0 ? asset?.links : null,
                            type: 'linkList',
                            mutationValue: 'links',
                            editable: true,
                            extraWidth: true,
                        },
                        {
                            title: 'Priority Date',
                            content: asset?.priorityDate ? asset?.priorityDate : null,
                            mutationValue: 'priorityDate',
                            editable: false
                        },
                        {
                            title: 'Publication Date',
                            content: asset?.publicationDate ? asset?.publicationDate : null,
                            mutationValue: 'publicationDate',
                            editable: false
                        },
                        {
                            title: 'Granted Date',
                            content: asset?.grantedDate ? asset?.grantedDate : null,
                            mutationValue: 'grantedDate',
                            editable: false
                        },
                        {
                            title: 'Expiry Date',
                            content: asset?.expiryDate ? asset?.expiryDate : null,
                            mutationValue: 'expiryDate',
                            editable: false
                        },
                        {
                            title: 'Pending Territories',
                            content: asset?.pendingTerritories ? useNodeContentAsList(asset?.pendingTerritories.edges, 'name') : null,
                            type: 'list',
                            mutationValue: 'pendingTerritories',
                            editable: false,
                            extraWidth: true,
                        },
                        {
                            title: 'Granted Territories',
                            content: asset?.grantedTerritories ? useNodeContentAsList(asset?.grantedTerritories.edges, 'name') : null,
                            type: 'list',
                            mutationValue: 'grantedTerritories',
                            editable: false,
                            extraWidth: true,
                        },
                        {
                            title: 'Expired Territories',
                            content: asset?.expiredTerritories ? useNodeContentAsList(asset?.expiredTerritories.edges, 'name') : null,
                            type: 'list',
                            mutationValue: 'expiredTerritories',
                            editable: false,
                            extraWidth: true,
                        },                       
                        {
                            title: 'Inventors',
                            content: asset?.inventors ? useNodeContentAsListWithConcat(asset?.inventors.edges, 'nickname', 'partyType') : null,
                            type: 'list',
                            mutationValue: 'inventors',
                            inputType: "multipleSelect",
                            queryName: "parties",
                            queryOptions: "partiesOptions",
                            editable: true,
                            extraWidth: true,
                        },
                        {
                            title: 'Assignees',
                            content: asset?.assignees ? useNodeContentAsListWithConcat(asset?.assignees.edges, 'nickname', 'partyType') : null,
                            type: 'list',
                            mutationValue: 'assignees',
                            inputType: "multipleSelect",
                            queryName: "parties",
                            queryOptions: "partiesOptions",
                            editable: true,
                            extraWidth: true,
                        },
                        {
                            title: asset?.otherTechnologyType ? 'Suppliers' : 'Owners',
                            content: asset?.owners ? useNodeContentAsListWithConcat(asset?.owners.edges, 'nickname', 'partyType') : null,
                            rawContent: asset?.otherTechnologyType && asset?.owners ? asset?.owners.edges.map(edge => edge.node) : null,
                            type: 'list',
                            mutationValue: 'owners',
                            inputType: "multipleSelect",
                            queryName: "parties",
                            queryOptions: "partiesOptions",
                            editable: true,
                            extraWidth: true,
                        },
                        {
                            title: 'Authors',
                            content: asset?.authors ? useNodeContentAsListWithConcat(asset?.authors.edges, 'nickname', 'partyType') : null,
                            type: 'list',
                            mutationValue: 'authors',
                            inputType: "multipleSelect",
                            queryName: "parties",
                            queryOptions: "partiesOptions",
                            editable: true,
                            extraWidth: true,
                        },
                        {
                            title: 'Owner',
                            content: asset?.owner ? `${asset?.owner.nickname} - (${asset?.owner.partyType})` : null,
                            type: 'text',
                            mutationValue: 'owner',
                            inputType: "simpleSelect",
                            queryName: "parties",
                            queryOptions: "partiesOptions",
                            editable: true,
                            extraWidth: true,
                        },
                        {
                            title: 'Previously Procured',
                            content: asset?.previouslyProcured ? asset?.previouslyProcured : null,
                            type: 'boolean',
                            mutationValue: 'previouslyProcured',
                            editable: false
                        },
                     ],
                    editable: true,
                    expanded: true
                },
                {
                    title: 'Pricing',
                    priceContent:[
                        {
                            title: 'Quantity',
                            content: asset?.assetPrice?.quantity,
                            mutationValue: 'assetPrice.quantity',
                            editable: true,
                            inputType: 'number',
                        },
                        {
                            title: 'Unit Type',
                            content: asset?.assetPrice?.unitType?.name,
                            mutationValue: 'assetPrice.unitType',
                            editable: true,
                            inputType: 'simpleSelect',
                            queryName: "unitTypes",
                            queryOptions: "unitTypesOptions",
                            
                        },
                        {
                            title: 'Price',
                            content: asset?.assetPrice?.price,
                            mutationValue: 'assetPrice.price',
                            editable: true,
                            inputType: 'number',
                        },
                        {
                            title: 'Unit Type Size',
                            content: asset?.assetPrice?.unitTypeSize,
                            mutationValue: 'assetPrice.unitTypeSize',
                            editable: true,
                            inputType: 'text',
                        },
                        {
                            title: 'Notes',
                            content: asset?.assetPrice?.notes,
                            mutationValue: 'assetPrice.notes',
                            editable: true,
                            inputType: 'text',
                        },
                    ],
                    editable:true,
                    expanded:false,
                },
                {
                    title: 'Title',
                    content: asset?.title,
                    expanded: true,
                    innerHtml: true,
                    mutationValue: 'title',
                    mutationType: 'text',
                    editable: true
                },
                {
                    title: 'Abstract',
                    content: asset?.abstract,
                    expanded: true,
                    innerHtml: true,
                    mutationValue: 'abstract',
                    mutationType: 'text'
                },
                {
                    title: 'Description',
                    content: asset?.description,
                    expanded: false,
                    innerHtml: true,
                    mutationValue: 'description',
                    mutationType: 'text',
                    editable: true
                },
                {
                    title: 'Claims',
                    content: asset?.claims ? useNodeContentAsList(asset?.claims.edges, 'claim') : null,
                    expanded: true,
                    innerHtml: true,
                    mutationValue: 'claims',
                    mutationType: 'textList'                
                },
                
            ],
            
        }}
        catch{(error) =>  console.log(error)}
    return  assetDetailMainData.value 
}