import gql from "graphql-tag";


export const TDS_PROCESS = gql`
    mutation tdsProcess($input: AssetTdsProcessMutationInput!) {
        assetTdsProcess (input: $input) {
            result
        }
    }
`

export function TDS_EXTRACT(assetId:number, isNewUpload:boolean,assetFile:number):string{
    const queryObject = {
        query: `mutation($input: AssetTdsExtractMutationInput!) {
            assetTdsExtract(input: $input) {
                result
            }
        }`,
        variables: {
            input: {
                asset: Number(assetId),
                assetFile: isNewUpload ? null : assetFile,
                file: null
            }
        }
    };
    return JSON.stringify(queryObject);
}