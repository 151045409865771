import { useMutation } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { provideApolloClient } from "@vue/apollo-composable";
import apolloClient from "@/utils/apolloClient";
import { POSITION, useToast } from 'vue-toastification';
import { OtherTechnologyAssetType, PatentAssetType, ResearchPaperAssetType } from "@/models/technologyAssetInterface";
import { useRawAssetResponseToStateType } from "@/composables/useRawAssetResponseToStateType";
import { ASSETS_QUERY } from "@/grapql/assetsQuery";
import { ASSET_FILE_DELETE } from "@/grapql/assetFileDelete";
import { uploadFiles } from "@/composables/useUploadFiles";
import { OTHER_TECHNOLOGY_UPDATE_PARTIAL, PATENT_UPDATE_PARTIAL, RESEARCH_PAPER_UPDATE_PARTIAL, RESEARCH_PROJECT_UPDATE_PARTIAL } from "@/grapql/assetsUpdatePartial";
import { ASSESSMENT_HIGH_LEVEL_OPTIONS } from "@/grapql/assessmentHighLevelQueries";
import { ASSET_PROGRAM_LINK_CREATE, ASSET_PROGRAM_LINK_UPDATE } from "@/grapql/assetProgramLinkMutations";
import { AssetMaterialUpdateMutationInput, AssetProcessingTechniqueUpdateMutationInput, AssetQuantitativePropertyUpdateMutationInput } from "@/models/assetAssessmentInterfaces";
import { ASSET_MATERIAL_UPDATE, ASSET_PROCESSING_TECHNIQUE_UPDATE, ASSET_QUANTITATIVE_PROPERTY_CREATE, ASSET_QUANTITATIVE_PROPERTY_UPDATE } from "@/grapql/assessmentInDepthMutations";


provideApolloClient(apolloClient);
const toast = useToast()

const getDefaultState = () => { return { 
    asset: {
        patent: null,
        researchPaper: null,
        researchProject: null,
        otherTechnology: null
    },
    assetType: null,
    assessmentPannelOpen: true,
    mainContent: {},
    edditableMain: false,
    editableAssessmentHighLevel: false, 
    editableAssessmentPrograms: false, 
    editableSection: {
        main: false,
        highLevel: false,
        programsAdd: false,
        programEdit: false,
        inDepth: false
    },
    assessmentHighLevel: {},
    errorsDuringMutation: {
        patent: null,
        researchPaper: null,
        researchProject: null,
        otherTechnology: null,
        programsAddLink: null,
        programsEditLink: null,
        assetMaterialEdition: [],
        assetProcessingTechniquesUpdate: null,
        assetQuantitativePropertyUpdate: null,
        assetQuantitativePropertyCreate: null,
    },
    filesToRemove: [],
    filesToAdd: [],
    showFilesUploadLoader: false,
    }
}
const state = getDefaultState();

const mutations = { 
    
    setEditableOn(state, payload:{ sectionToMakeEditbale: string}) {
        state.editableSection.main = false;
        state.editableSection.highLevel = false;
        state.editableSection.programsAdd = false;
        state.editableSection.programsEdit = false;
        state.editableSection.inDepth = false;
        state.editableSection[payload.sectionToMakeEditbale] = true;
    },
    setEditableMainOff(state) {
        state.editableSection.main = false;
    },
    setEditableAssessmentHighLevelOff(state) {
        state.editableSection.highLevel = false;
    },
    setEditableProgramsAddOff(state) {
        state.editableSection.programsAdd = false;
    },
    setEditableProgramsEditOff(state) {
        state.editableSection.programsEdit = false;
    },
    setEditableinDepthOff(state) {
        state.editableSection.inDepth = false;
    },
    
    async setAssetValues(state, payload:{data, assetType: string }){
        state.edditableMain = false;
        state.editableAssessmentHighLevel = false;
        state.assetType = payload.assetType;
        state.asset[payload.assetType] = useRawAssetResponseToStateType(payload.data, payload.assetType);
    },
    updateAssetValue(state, payload:{mutationValue:string, content:string | number}) {
        state.asset[state.assetType][payload.mutationValue] = payload.content; 
    },
    updateAssessmentHigLevelValue( state, payload:{mutationValue:string, content:string | number}) {
        state.asset[state.assetType][payload.mutationValue] = payload.content;  
        Object.assign(state.assessmentHighLevel, {[payload.mutationValue]: payload.content})
    },
    updateMainAssetValue( state, payload:{mutationValue:string, content:string}) {
        if(payload.mutationValue.includes('.')){
            const [base,value] = payload.mutationValue.split('.');
            state.asset[state.assetType][base][value] = payload.content;
            if(!state.mainContent[base]){state.mainContent[base] = {};}
            state.mainContent[base][value] = payload.content;
        }else{
            state.asset[state.assetType][payload.mutationValue] = payload.content; 
            Object.assign(state.mainContent, {[payload.mutationValue]: payload.content})
        }
    },
    resetValues(state) {
        Object.assign(state, getDefaultState());
    },
    setFilesToRemove(state, payload:[number]) {
        state.filesToRemove = payload;
    },
    setFilesToAdd(state, payload) {
        state.filesToAdd = payload;
    },
    toggleShowFilesUploadLoader(state) {
        state.showFilesUploadLoader = !state.showFilesUploadLoader;
    },
    toggleAssessmentPannel(state) {
        state.assessmentPannelOpen = !state.assessmentPannelOpen
    },
    setErrorOnLinkCreationResponse(state, payload:{ error:string}) {
        if (payload.error.includes('duplicate key')) {
            state.errorsDuringMutation.programsAddLink = 'This asset is already assigned to this program'
        }
    },
    setErrorOnLinkUpdateResponse(state, payload:{ error:string}) {
        state.errorsDuringMutation.programsEditLink = payload.error;
    },
    async setErrorOnAssetMaterialUpdateResponse(state, payload:{ error:string, id:number}) {
        let filteredError = [];
        if (state.errorsDuringMutation.assetMaterialEdition.length > 0 ) {
            filteredError = state.errorsDuringMutation.assetMaterialEdition.filter((error: {error:string, id: number; }) => error.id !== payload.id)
        }
        if (payload.error) {
            filteredError.push(payload)
        }
        state.errorsDuringMutation.assetMaterialEdition = filteredError;
    },
    async setErrorOnAssetProcessingTechniqueUpdate(state, payload: {error:string}) {
        state.errorsDuringMutation.assetProcessingTechniquesUpdate = payload.error
    },
    async setErrorOnAssetQuantitativePropertyCreate(state, payload: {error:string}) {
        state.errorsDuringMutation.assetQuantitativePropertyCreate = payload.error
    },
    async setErrorOnAssetQuantitativePropertyUpdate(state, payload: {error:string}) {
        state.errorsDuringMutation.assetQuantitativePropertyUpdate = payload.error
    },
    
}

const actions = { 

    // REMOVE FILES WHEN NEEDED
   async removeFiles({state}) {
        if (state.filesToRemove.length) {
            const { mutate: assetFileDelete } = useMutation(ASSET_FILE_DELETE); 
            state.filesToRemove.map(async fileId => {
                await assetFileDelete({ input: { id: fileId } })})
        }
   },

   // ADD FILES TO ASSET
   async addFiles({state, commit}) {
        if(state.filesToAdd.length) {
            await commit('toggleShowFilesUploadLoader');
            await uploadFiles(state.filesToAdd, state.asset[state.assetType].id);
        }
   },


    // PATENT UPDATE PARTIAL
    async patentUpdatePartial({state, dispatch}, payload:{type:string}) {
        const { mutate: patentUpdatePartial, onDone } = useMutation(PATENT_UPDATE_PARTIAL, {refetchQueries:[{query: ASSESSMENT_HIGH_LEVEL_OPTIONS}, {query:  ASSETS_QUERY}]}); 
        const patentInput = Object.assign({}, state[payload.type], {id: state.asset.patent.id});
        patentUpdatePartial({input: patentInput})
        onDone( async () => {
            await dispatch('assetUpdateOnDone', {type: payload.type, query: 'getPatent'})
        })
    },

    // RESEARCH PAPER UPDATE PARTIAL
    async researchPaperUpdatePartial({state, dispatch}, payload:{type:string}) {
        const { mutate: researchPaperUpdatePartial, onDone } = useMutation(RESEARCH_PAPER_UPDATE_PARTIAL, {refetchQueries:[{query: ASSESSMENT_HIGH_LEVEL_OPTIONS}, {query:  ASSETS_QUERY}]}); 
        const patentInput = Object.assign({}, state[payload.type], {id: state.asset.researchPaper.id});
        researchPaperUpdatePartial({input: patentInput})
        onDone( async () => {
            await dispatch('assetUpdateOnDone', {type: payload.type, query: 'getResearchPaper'})
        })
    },

    // RESEARCH PROJECT UPDATE PARTIAL
    async researchProjectUpdatePartial({state, dispatch}, payload:{type:string}) {
        const { mutate: researchProjectUpdatePartial, onDone } = useMutation(RESEARCH_PROJECT_UPDATE_PARTIAL, {refetchQueries:[{query: ASSESSMENT_HIGH_LEVEL_OPTIONS}, {query:  ASSETS_QUERY}]}); 
        const patentInput = Object.assign({}, state[payload.type], {id: state.asset.researchProject.id});
        researchProjectUpdatePartial({input: patentInput})
        onDone( async () => {
            await dispatch('assetUpdateOnDone', {type: payload.type, query: 'getResearchProject'})
        })
    },

    // OTHER TECHNOLOGY UPDATE PARTIAL
    async otherTechnologyUpdatePartial({state, dispatch}, payload:{type:string}) {
        const { mutate: otherTechnologyUpdatePartial, onDone } = useMutation(OTHER_TECHNOLOGY_UPDATE_PARTIAL, {refetchQueries:[{query: ASSESSMENT_HIGH_LEVEL_OPTIONS}, {query:  ASSETS_QUERY}]}); 
        const patentInput = Object.assign({}, state[payload.type], {id: state.asset.otherTechnology.id});
        otherTechnologyUpdatePartial({input: patentInput})
        onDone( async () => {
            await dispatch('assetUpdateOnDone', {type: payload.type, query: 'getOtherTechnology'})
        })
    },

    // ON PARTIAL UPDATE SUCCEDED ACTIONS
    async assetUpdateOnDone({dispatch, commit}, payload:{type:string, query:string}) {
        if (payload.type == 'assessmentHighLevel') {
            await dispatch('addFiles').then(() =>  commit('toggleShowFilesUploadLoader'));
            await dispatch('removeFiles');
        }
        await apolloClient.refetchQueries({include: [payload.query, 'getAssessmentInDepth'] }).then(
            () => toast.success("Asset Updated", { position: POSITION.BOTTOM_LEFT, timeout: 1524 }));
        await commit('setEditableAssessmentHighLevelOff');
    },

    // CREATE NEW ASSET PROGRAM LINK
    async assetProgramLinkCreate({state, commit}, payload:{ asset: number, program: number, linkType: number }) {
        const { mutate: assetProgramLinkCreate, onDone, onError } = useMutation(ASSET_PROGRAM_LINK_CREATE); 
        const queryToRefetch = state.assetType.charAt(0).toUpperCase() + state.assetType.slice(1);
        assetProgramLinkCreate({input: payload})
        onDone( async () => {
            await apolloClient.refetchQueries({include: [`get${queryToRefetch}`]}).then(
                () => toast.success("Asset Updated", { position: POSITION.BOTTOM_LEFT, timeout: 1524 }));
            await commit('setEditableProgramsAddOff')
        });
        onError((error) => commit('setErrorOnLinkCreationResponse', {error: error.message}) )
    },

    // UPDATE ASSET PROGRAM LINK
    async assetProgramLinkUpdate({state, commit}, payload:{ asset: number, program: number, linkType: number, id:number, linkstatus: number, statusReason: string }) {
        const { mutate: assetProgramLinkUpdate, onDone, onError } = useMutation(ASSET_PROGRAM_LINK_UPDATE); 
        const queryToRefetch = state.assetType.charAt(0).toUpperCase() + state.assetType.slice(1);
        assetProgramLinkUpdate({input: payload});
        onDone( async () => {
            await apolloClient.refetchQueries({include: [`get${queryToRefetch}`]}).then(
                () => toast.success("Asset Updated", { position: POSITION.BOTTOM_LEFT, timeout: 1524 }));
            await commit('setEditableProgramsEditOff')
        });
        onError( async (error) => await commit('setErrorOnLinkUpdateResponse', {error: error.message}) )
    },


    // UPDATE ASSET MATERIAL  
    async assetMaterialUpdate({commit}, payload: AssetMaterialUpdateMutationInput ){
        return new Promise<void>((resolve) => {
            const {mutate: assetMaterialUpdate, onDone, onError} = useMutation(ASSET_MATERIAL_UPDATE);
            assetMaterialUpdate({input: payload});
            onDone(async ()=> {
                await commit('setErrorOnAssetMaterialUpdateResponse', {error: null, id: payload.id})
                await apolloClient.refetchQueries({include: ['getAssessmentInDepth']}).then(
                    () =>  toast.success("Asset Updated", { position: POSITION.BOTTOM_LEFT, timeout: 1524 }));
                await commit('setEditableinDepthOff');
                resolve()
             });
            onError(async (error)=> { 
                await commit('setErrorOnAssetMaterialUpdateResponse', {error: error.message, id: payload.id}); 
                resolve();
            })
        })

    },


    // UPDATE ASSET PROCESSING TECHNIQUE PROPERTY
    async assetProcessingTechniqueUpdate({commit}, payload: AssetProcessingTechniqueUpdateMutationInput) {
        return new Promise<void>((resolve) => {
            const {mutate: assetProcessingTechniqueUpdate, onDone, onError} = useMutation(ASSET_PROCESSING_TECHNIQUE_UPDATE);
            assetProcessingTechniqueUpdate({input: payload});
            onDone( async () => {
                await apolloClient.refetchQueries({include: ['getAssessmentInDepth']}).then(
                    () =>  toast.success("Asset Updated", { position: POSITION.BOTTOM_LEFT, timeout: 1524 }));
                await commit('setEditableinDepthOff');
                resolve()
            });
            onError(async (error)=> { 
                await commit('setErrorOnAssetProcessingTechniqueUpdate', {error: error.message}); 
                resolve();
            })
        })
    },


    // UPDATE ASSET QUANTITATIVE PROPERTY
    async assetQuantitativePropertyUpdate({commit}, payload: AssetQuantitativePropertyUpdateMutationInput) {
        return new Promise<void>((resolve) => {
            const {mutate: assetQuantitativePropertyUpdate, onDone, onError} = useMutation(ASSET_QUANTITATIVE_PROPERTY_UPDATE);
            assetQuantitativePropertyUpdate({input: payload});
            onDone( async () => {
                await apolloClient.refetchQueries({include: ['getAssessmentInDepth']}).then(
                    () =>  toast.success("Asset Updated", { position: POSITION.BOTTOM_LEFT, timeout: 1524 }));
                await commit('setEditableinDepthOff');
                resolve()
            });
            onError(async (error)=> { 
                await commit('setErrorOnAssetQuantitativePropertyUpdate', {error: error.message}); 
                resolve();
            })
        })
    },

    // UPDATE ASSET QUANTITATIVE PROPERTY
    async assetQuantitativePropertyCreate({commit}, payload: AssetQuantitativePropertyUpdateMutationInput) {
        return new Promise<void>((resolve) => {
            const {mutate: assetQuantitativePropertyCreate, onDone, onError} = useMutation(ASSET_QUANTITATIVE_PROPERTY_CREATE);
            assetQuantitativePropertyCreate({input: payload});
            onDone( async () => {
                await apolloClient.refetchQueries({include: ['getAssessmentInDepth']}).then(
                    () =>  toast.success("Asset Updated", { position: POSITION.BOTTOM_LEFT, timeout: 1524 }));
                await commit('setEditableinDepthOff');
                resolve()
            });
            onError(async (error)=> { 
                await commit('setErrorOnAssetQuantitativePropertyCreate', {error: error.message}); 
                resolve();
            })
        })
    },


    // UPDATE PATENT WITH NEW VALUES (ALL NEW VALUES NEEDED HERE)
    async patentUpdate({state, commit, dispatch}) {
        return new Promise<void>((resolve, reject) => {
            const { mutate: patentUpdate, onDone } = useMutation(gql`
                mutation patentUpdate($input: PatentUpdateMutationInput! ) {
                    patentUpdate (input: $input) {
                        patent { id }
                    }
                } `, { refetchQueries: [{ query: ASSETS_QUERY}] } );
            try {
                const patentInput: PatentAssetType = Object.assign({}, state.asset['patent']);
                patentUpdate({input: patentInput});
                onDone( async ()  => { 
                    await dispatch('addFiles').then(() =>  commit('toggleShowFilesUploadLoader'));
                    await dispatch('removeFiles');
                    await apolloClient.refetchQueries({include: ['getPatent']}).then(
                        () => toast.success("Patent Updated", { position: POSITION.BOTTOM_LEFT, timeout: 1524 }));
                   
                    await commit('setEditableModeOff')
                    resolve();
                });
            } catch(error) {
                console.log(error);
                reject();
            }
        })
        
    }, 

    async researchPaperUpdate({state, commit, dispatch}) {
        await dispatch('addFiles');
        await dispatch('removeFiles');
        return new Promise<void>((resolve, reject) => {
            const { mutate: researchPaperUpdate, onDone } = useMutation(gql`
                mutation researchPaperUpdate($input: ResearchPaperUpdateMutationInput! ) {
                    researchPaperUpdate (input: $input) {
                        researchPaper { id }
                    }
                } `, { refetchQueries: [{ query: ASSETS_QUERY}] } );
            try {
                dispatch('removeFiles');
                const researchPaperInput: ResearchPaperAssetType = Object.assign({}, state.asset['researchPaper']);
                researchPaperUpdate({input: researchPaperInput});
                onDone(async (result) => { 
                    await dispatch('addFiles');
                    await dispatch('removeFiles');
                    await apolloClient.refetchQueries({include: ['getResearchPaper']}).then(
                        () => toast.success("Patent Updated", { position: POSITION.BOTTOM_LEFT, timeout: 1524 }));
                   
                    await commit('setEditableModeOff')
                    resolve();
                })
            } catch(error) {
                console.log(error);
                reject();
            }
        })
    },

    async researchProjectUpdate({state, commit, dispatch}) {
        await dispatch('addFiles');
        await dispatch('removeFiles');
        return new Promise<void>((resolve, reject) => {
            const { mutate: researchProjectUpdate, onDone } = useMutation(gql`
                mutation researchProjectUpdate($input: ResearchProjectUpdateMutationInput! ) {
                    researchProjectUpdate (input: $input) {
                        researchProject { id }
                    }
                } `, { refetchQueries: [{ query: ASSETS_QUERY}] } );
            try {
                dispatch('removeFiles');
                const researchProjectInput: ResearchPaperAssetType = Object.assign({}, state.asset['researchProject']);
                researchProjectUpdate({input: researchProjectInput});
                onDone(async (result) => { 
                    await dispatch('addFiles');
                    await dispatch('removeFiles');
                    await apolloClient.refetchQueries({include: ['getResearchProject']}).then(
                        () => toast.success("Patent Updated", { position: POSITION.BOTTOM_LEFT, timeout: 1524 }));
                   
                    await commit('setEditableModeOff')
                    resolve();
                })
            } catch(error) {
                console.log(error);
                reject();
            }
        })
    },

    async otherTechnologyUpdate({state, commit, dispatch}) {
        await dispatch('addFiles');
        await dispatch('removeFiles');
        return new Promise<void>((resolve, reject) => {
            const { mutate: otherTechnologyUpdate, onDone } = useMutation(gql`
                mutation otherTechnologyUpdate($input: OtherTechnologyUpdateMutationInput! ) {
                    otherTechnologyUpdate (input: $input) {
                        otherTechnology { id }
                    }
                } `, { refetchQueries: [{ query: ASSETS_QUERY}] } );
                try {
                    dispatch('removeFiles');
                    const otherTechnologyInput: OtherTechnologyAssetType = Object.assign({}, state.asset['otherTechnology']);
                    otherTechnologyUpdate({input: otherTechnologyInput});
                    onDone(async (result) => { 
                        await dispatch('addFiles');
                        await dispatch('removeFiles');
                        await apolloClient.refetchQueries({include: ['getOtherTechnology']}).then(
                            () => toast.success("Patent Updated", { position: POSITION.BOTTOM_LEFT, timeout: 1524 }));
                    
                        await commit('setEditableModeOff')
                        resolve();
                    })
                } catch(error) {
                    console.log(error);
                    reject();
                }
            })
    }



}
const getters = { 
    isAssetMainEditable(state) {
        return state.editableSection.main
    },
    isAssetAssessmentHighLevelEditable(state){
        return state.editableSection.highLevel
    },
    isAssetAssessmentProgramsEditable(state){
        return state.editableSection.programsEdit
    },
    isAssetAssessmentProgramsAddition(state){
        return state.editableSection.programsAdd
    },
    isAssetAssessmentInDepthEditable(state) {
        return state.editableSection.inDepth
    },
    getAssetType(state) { 
        return state.assetType 
    },
    getAssetValues(state) {
        return state.asset[state.assetType]
    },
    getShowFilesUploadLoader(state) {
        return state.showFilesUploadLoader
    },
    getAssessmentPannelStatus(state) {
        return state.assessmentPannelOpen;
    },
    getAssetId(state) {
        return state.asset[state.assetType].id
    },
    getErrorsOnMaterialUpdate(state) {
        return state.errorsDuringMutation.assetMaterialEdition
    }
}

export default {
    namespaced: true,
    state, 
    mutations,
    getters,
    actions
}
