import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import PageNotFound from '@/views/PageNotFound.vue';
import store from "@/store";
import HomeView from "@/tea/views/HomeView.vue";
import DashboardView from "@/tea/views/DashboardView.vue";
import RunGroupDetailView from "@/tea/views/RunGroupDetailView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Tea.Home",
        meta: { hideSidebar: true },
        component: HomeView,
    },
    {
        path: "/dashboard",
        name: "Tea.Dashboard",
        meta: { hideSidebar: false },
        component: DashboardView,
    },
    {
        path: "/run-group/:id",
        name: "Tea.RunGroupDetail",
        meta: { hideSidebar: false },
        component: RunGroupDetailView,
    },
    {
        path: '/:catchAll(.*)*',
        name: "Tea.PageNotFound",
        meta: { hideSidebar: true },
        component: PageNotFound,
    },
];

const AnonymousRoute = [
    "Tea.Home",
    "Tea.About",
    "Tea.Contact",
];


const teaRouter = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

// Redirect User to Welcome Page when non authenticated
teaRouter.beforeEach(async (to) => {
    document.title = "One.Five TEA";

    // check if it's anonymous route
    if (AnonymousRoute.includes(to.name.toString())){
        return;
    }

    if (!store.getters["authModule/isAuthenticated"] && !!localStorage.auth_token) {
        await store.dispatch('authModule/refreshUser')
    }
    if (!store.getters["authModule/isAuthenticated"]) {
        return { name: "Tea.Home" }
    }
});

export default teaRouter;
