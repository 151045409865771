<template>
    <div class="page-padding">
        <div v-if="loading" class="loading-gif"></div>
        <div v-else-if="brief">
            <TitleGoBackHeader :title="brief.product"></TitleGoBackHeader>
            <ProductBriefOverview :product-brief="brief" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useRoute } from 'vue-router';
import { PRODUCT_BRIEF_QUERY  } from '@/grapql/productBriefQuery';
import TitleGoBackHeader from '@/components/TitleGoBackHeader.vue';
import ProductBriefOverview from '@/components/ProductBriefOverview.vue';

const route = useRoute();
const briefId = route.params.id;

const brief = ref(null);

const { onResult, loading } = useQuery(PRODUCT_BRIEF_QUERY, { id: briefId });

onResult(queryResult => {
    if(queryResult.data && queryResult.data.productBrief) {
        brief.value = queryResult?.data.productBrief;
    }
    
})

</script>
<style lang="scss"></style>