<template>
    <div class="pt-5 pb-20 items-center text-center">
        <div class="flex">
            <div class="w-full px-3 py-1">
                <p>End of Response</p>
                <ArrowPathIcon title="Restart" @click="handleReset" class="icon w-9 h-9 cursor-pointer hover:bg-gray-200 active:shadow-inner rounded inline-block px-2 m-2 hover:stroke-black"/>
            </div>
        </div>
   </div>
</template>
<script lang="ts" setup>
import { ArrowPathIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';

const store = useStore();

const handleReset = ()=>{
    store.commit("aiModule/resetStore");
}
</script>