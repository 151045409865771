<template>
  <Chart :options="chartOptions"></Chart>
</template>
<script setup>
import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting';
import { Chart } from 'highcharts-vue';
import coloraxisInit from "highcharts/modules/coloraxis";
import moreInit from "highcharts/highcharts-more";
import  heatmapInit from 'highcharts/modules/heatmap';
import dataInit from 'highcharts/modules/data';

exportingInit(Highcharts);
coloraxisInit(Highcharts);
moreInit(Highcharts);
heatmapInit(Highcharts);
dataInit(Highcharts)

const chartOptions = {
    chart: {
        type: 'heatmap',
        plotBorderWidth: 1,
        style: {
            fontFamily: 'Montserrat'
        },
    },
    plotOptions: {
        states: {
        inactive: {
          opacity: 1
        },
        hover: {
          brightness: -0.2 // darken
        }
      }
    },
    title: {
        text: '<p style="font-size: 1.1rem; color: rgb(55, 65, 81); text-transform: uppercase; font-weight: 500; fill: rgb(55, 65, 81);">Heat sealing alternatives</p>',

      },

    yAxis: {
        tickWidth: 2,
        tickColor: 'gray',
        title: null,
        labels: {
                x: -5,
                useHTML: false,
                rotation: 0,
                  style: {
                    color: '#374151',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    fontFamily: 'Montserrat',
                  }
            },

        categories: ['HS2', 'HS14','HS1','HS7','HS6', 'HS4','HS10','HS13','Alpine', 'Olivia','Compound 7', 'Compound1', 'Compound 5','Compound 4' ]
            
        
    },

    xAxis: {
            labels: {
                useHTML: false,
                rotation: 30,
                  style: {
                    color: '#374151',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    fontFamily: 'Montserrat',
                  }
            },
            
        categories: ['Food Contact', 'Heat seal T >100°C', 'WVTR', 'Extrusion', 'Grease', 'Dispersion', 'BioDeg', 'Heat Seal T<100°C','OTR'],
        title: null,
        
    },
    colorAxis: [{
        minColor: '#8bb9a7',
        maxColor: '#ed9071',
        endOnTick: false,
        startOnTick: false,

        showInLegend: false
    },{
        stops: [
                [0,'#8bb9a7'],
                [0.5,'#eee'],
                [0.9,'#ed9071']
            ],
            min: 0,
        endOnTick: false,
        startOnTick: false,

        showInLegend: false
    }],

    tooltip: {
        formatter: function() {
            return '<b>' + this.series.yAxis.categories[this.point.y] + '</b></br><b>' 
            + this.series.xAxis.categories[this.point.x] + '</b>';
        }
    },

    series: [{
        name: 'Not available',
        showInLegend: true,
        //borderWidth: 2,
        colorAxis: 1,
        color: '#ed9071',
        data: [
            [0, 0, 0],
            [1, 0, 0],
            [2, 0, 0],
            [3, 0, 100],
            [4, 0, 50],
            [5, 0, 0],
            [6, 0, 100],
            [7, 0, 100],
            [8, 0, 100],
            [0, 1, 0],
            [1, 1, 0],
            [2, 1, 0],
            [3, 1, 100],
            [4, 1, 50],
            [5, 1, 0],
            [6, 1, 50],
            [7, 1, 100],
            [8, 1, 100],
            
        ],
        dataLabels: {
            enabled: false
        }
    }, {
        name: 'Available',
        showInLegend: true,
        //borderWidth: 2,
        colorAxis: 1,
        color: '#8bb9a7',
        data: [
            [0, 2, 100],
            [1, 2, 0],
            [2, 2, 0],
            [3, 2, 100],
            [4, 2, 50],
            [5, 2, 0],
            [6, 2, 100],
            [7, 2, 100],
            [8, 2, 100],
            
        ],
        dataLabels: {
            enabled: false
        }
    },{
        name: 'Not present in TDS/To be evaluated',
        showInLegend: true,
        //borderWidth: 2,
        colorAxis: 1,
        color: '#ddd',
        data: [
            [0, 3, 50],
            [1, 3, 0],
            [2, 3, 1],
            [3, 3, 100],
            [4, 3, 0],
            [5, 3, 1],
            [6, 3, 50],
            [7, 3, 0],
            [8, 3, 100],
            [0, 4, 50],
            [1, 4, 0],
            [2, 4, 1],
            [3, 4, 100],
            [4, 4, 0],
            [5, 4, 1],
            [6, 4, 50],
            [7, 4, 0],
            [8, 4, 100],
            [0, 5, 0],
            [1, 5, 0],
            [2, 5, 1],
            [3, 5, 100],
            [4, 5, 0],
            [5, 5, 1],
            [6, 5, 50],
            [7, 5, 0],
            [8, 5, 100],
        ],
        dataLabels: {
            enabled: false
        }
    },{
        name: 'Phase 2',
        showInLegend: false,
        //borderWidth: 2,
        colorAxis: 1,
        color: '#a1a1a1',
        data: [
            [0, 6, 0],
            [1, 6, 0],
            [2, 6, 100],
            [3, 6, 100],
            [4, 6, 0],
            [5, 6, 0],
            [6, 6, 50],
            [7, 6, 100],
            [8, 6, 0],
        ],
        dataLabels: {
            enabled: false
        }
    },{
        name: 'Phase 1',
        showInLegend: false,
        //borderWidth: 2,
        colorAxis: 1,
        color: '#a1a1a1',
        data: [
            [0, 7, 50],
            [1, 7, 0],
            [2, 7, 100],
            [3, 7, 100],
            [4, 7, 0],
            [5, 7, 0],
            [6, 7, 0],
            [7, 7, 100],
            [8, 7, 0],
        ],
        dataLabels: {
            enabled: false
        }
    },{
        name: 'Phase 2',
        showInLegend: false,
        //borderWidth: 2,
        colorAxis: 1,
        color: '#a1a1a1',
        data: [
            [0, 8, 50],
            [1, 8, 0],
            [2, 8, 50],
            [3, 8, 0],
            [4, 8, 50],
            [5, 8, 100],
            [6, 8, 0],
            [7, 8, 0],
            [8, 8, 100],
            [0, 9, 0],
            [1, 9, 0],
            [2, 9, 100],
            [3, 9, 0],
            [4, 9, 100],
            [5, 9, 0],
            [6, 9, 0],
            [7, 9, 50],
            [8, 9, 100],
        ],
        dataLabels: {
            enabled: false
        }
    },{
        name: 'Phase 1',
        showInLegend: false,
        //borderWidth: 2,
        colorAxis: 1,
        color: '#a1a1a1',
        data: [
            
            [0, 10, 0],
            [1, 10, 0],
            [2, 10, 0],
            [3, 10, 0],
            [4, 10, 50],
            [5, 10, 100],
            [6, 10, 100],
            [7, 10, 100],
            [8, 10, 100],
            [0, 11, 0],
            [1, 11, 0],
            [2, 11, 0],
            [3, 11, 0],
            [4, 11, 50],
            [5, 11, 100],
            [6, 11, 100],
            [7, 11, 100],
            [8, 11, 100],
            [0, 12, 0],
            [1, 12, 0],
            [2, 12, 0],
            [3, 12, 0],
            [4, 12, 50],
            [5, 12, 100],
            [6, 12, 100],
            [7, 12, 100],
            [8, 12, 100],
            [0, 13, 0],
            [1, 13, 0],
            [2, 13, 0],
            [3, 13, 0],
            [4, 13, 50],
            [5, 13, 100],
            [6, 13, 100],
            [7, 13, 100],
            [8, 13, 100],
        ],
        dataLabels: {
            enabled: false,
        }
    }]

}
</script>
<style scoped>
.highcharts-figure,
.highcharts-data-table table {
  /* min-width: 310px;
  max-width: 800px; */
  margin: 1em auto;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}
</style>