import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

import { ref,watch,computed} from 'vue';
import HeartBeat from './HeartBeat.vue';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'AIChatAnswerRender',
  props: ['text','messageId'],
  setup(__props) {

const store = useStore();

const props =__props;

const textWords = computed(()=> props.text?.split(' '));

const typedText = ref('');
const index = ref(0);

const pulsing = computed(()=> index.value < textWords.value?.length || store.state.aiModule.pulsing == props.messageId );

const intervalId = ref(null);

const typingSpeed = computed(()=> store.state.aiModule.typingSpeed);

const isStopped = computed(()=> store.state.aiModule.isStopped);
const isSubscriptionActive = computed(()=> store.getters["aiModule/getIsSubscriptionActive"]);

const typing = computed(()=> store.state.aiModule.typing);

const isHeartBeating = computed(()=>{
    return typing.value && isSubscriptionActive.value && index.value < textWords.value?.length;
});

// Watch for changes in props.answer
watch(async() => props.text, (newValue, oldValue) => {
    if (newValue !== oldValue) {
        clearInterval(intervalId.value);
        typeWords();
    }
});

const typeWords = ()=>
{

    intervalId.value = setInterval(() => {

        if(!isSubscriptionActive.value){
            clearInterval(intervalId.value);
            store.commit('aiModule/setTyping',false);
        }else{

            store.commit('aiModule/setTyping',true);
        
            typedText.value += textWords.value[index.value] + ' ';
            index.value++;
            window.scrollTo(0, document.body.scrollHeight);
            if(index.value == textWords.value.length) {
                clearInterval(intervalId.value);
                store.commit('aiModule/setTyping',false);
            }
        }
    }, typingSpeed.value);

}   
typeWords();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("span", {
      innerHTML: typedText.value,
      class: "whitespace-pre-line"
    }, null, 8, _hoisted_1),
    (isHeartBeating.value)
      ? (_openBlock(), _createBlock(HeartBeat, {
          key: 0,
          style: {"display":"inline-block !important"}
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})