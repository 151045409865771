import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client/core"
import { onError } from "@apollo/client/link/error"

function getHeaders() {
    const headers = {}
    const token = process.env.VUE_APP_HASURA_SECRET
    if (token) {
        headers["x-hasura-admin-secret"] = `${token}`
        headers["x-hasura-role"] = "front_end"
    }
    return headers
}

// Create an http link:
const httpLink = new HttpLink({
    uri: process.env.VUE_APP_HASURA_API,
    fetch: (uri: RequestInfo, options: RequestInit) => {
        options.headers = getHeaders()
        return fetch(uri, options)
    },
})

const errorLink = onError((error) => {
    console.log(error);
})

// Create the apollo client
export const hasuraApolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: errorLink.concat(httpLink),
})


// EXAMPLE - HOW TO QUERY WITH ASSURA
// -------------------------------------------
// const options = ref({
//     clientId: 'hasuraClient',
// })

// const { result } = useQuery(gql`
//     query getPapers {
//         prod_staging_data_dim_static_papers {
//             gsm
//             nickname
//             product_name
//   }
// }`, null, options)

// const queryResult = computed(() => result?.value ?? null);
// const papers = computed(() => queryResult?.value ? queryResult.value.prod_staging_data_dim_static_papers : []);