import { gql } from "graphql-tag";

export const SIGN_IN_MUTATION = gql`
  mutation tokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      refreshToken
      user {
        id
        lastLogin
        firstName
        lastName
        dateJoined
        username
        email
        fullName
      }
    }
  }
`;

export const SIGN_UP_MUTATION = gql`
  mutation ($email: String!, $password: String!,$firstName:String!, $lastName:String, $organizationName:String!) {
    register(email: $email, password: $password, firstName:$firstName, lastName:$lastName, organizationName:$organizationName) {
      result
    }
  }
`;

export const RE_VERIFY_MUTATION = gql`
  mutation ($email: String!, $password: String!) {
    resendVerificationEmail(email: $email, password: $password) {
      result
    }
  }
`;

export const RESET_MUTATION = gql`
  mutation resetPassword($email: String!) {
    resetPassword(email: $email) {
      result
    }
  }
`;

export const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      refreshToken
      refreshExpiresIn
    }
  }
`;
