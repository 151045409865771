import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "label-with-icon" }
const _hoisted_2 = { class: "party-box-container" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "text" }
const _hoisted_5 = {
  key: 0,
  class: "party-box-body"
}

import ModalSlots from "@/components/ModalSlots.vue";
import PartyAddNewForm from "@/components/PartyAddNewForm.vue";
import { ref, computed, reactive } from "vue";
import { ChevronDownIcon } from '@heroicons/vue/24/outline';
import { useStore } from "vuex";
import { NEW_PARTY_FIELDS } from "@/models/newPartyFormFields";



export default /*@__PURE__*/_defineComponent({
  __name: 'PartyAddNew',
  props: { partyType: String },
  emits: ['createdParty'],
  setup(__props, { emit: __emit }) {


const emit = __emit
const isOpen = ref(false);
const closeModal = () => { isOpen.value = false };
const partyTypeSelected = ref('');
const store = useStore();


const partyTypeUpper = computed(() => partyTypeSelected.value.toUpperCase());
const partyTypeList = computed(() => Object.keys(NEW_PARTY_FIELDS).map(k => k.toLowerCase()) );

const formSubmited = reactive(partyTypeList.value.reduce((o, key) => Object.assign(o, {[key]: false}), {}) );

const errorsFromMutation = computed(() => partyTypeSelected.value != '' ? store.state.partyModule.errorsDuringPartyCreation[partyTypeSelected.value] : null )

const valuesSubmitted = computed(() => store.state.partyModule.party[partyTypeSelected.value]);
const formValid = computed(() => partyTypeSelected.value ? NEW_PARTY_FIELDS[partyTypeUpper.value].mandatoryFormFields.every(mf => !!valuesSubmitted.value[mf]) : false);
const errorFields = computed(() => partyTypeSelected.value ? NEW_PARTY_FIELDS[partyTypeUpper.value].mandatoryFormFields.filter(mf => !valuesSubmitted.value[mf]) : [] )


const onPartyTypeSelection = (selectedPartyType:string) => partyTypeSelected.value = selectedPartyType;
const createNewParty = async () => {
    formSubmited[partyTypeSelected.value] = true;
    if (formValid.value) {
        const mutation = NEW_PARTY_FIELDS[partyTypeUpper.value].createMutation;
        
        await store.dispatch(mutation)
            .then(() => {
                if(!errorsFromMutation.value) {
                    formSubmited[partyTypeSelected.value] = false;
                    store.commit('partyModule/resetPartyValues');
                    closeModal();
                }
            });
        
        
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (isOpen.value = true)),
        class: "icon-span"
      }, "+ Create new " + _toDisplayString(__props.partyType), 1)
    ]),
    _createVNode(ModalSlots, {
      show: isOpen.value,
      onCloseModal: closeModal,
      onClickCreate: createNewParty,
      dialogClass: "create-party-modal"
    }, {
      header: _withCtx(() => [
        _createElementVNode("p", null, "Create new " + _toDisplayString(__props.partyType), 1)
      ]),
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(partyTypeList.value, (party) => {
            return (_openBlock(), _createElementBlock("div", {
              key: party,
              class: "party-box",
              onClick: ($event: any) => (onPartyTypeSelection(party))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["party-box-header", {'selected': partyTypeSelected.value === party}])
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("p", null, _toDisplayString(party), 1),
                  _createElementVNode("p", null, "Create a new " + _toDisplayString(party) + " as " + _toDisplayString(__props.partyType), 1)
                ]),
                _createVNode(_unref(ChevronDownIcon), { class: "icon icon-small thick" })
              ], 2),
              (partyTypeSelected.value === party)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(PartyAddNewForm, {
                      "party-type-selected": partyTypeSelected.value,
                      "form-is-valid": formValid.value,
                      "form-submited": formSubmited[party],
                      "errors-fields": errorFields.value,
                      "errors-from-mutation": errorsFromMutation.value
                    }, null, 8, ["party-type-selected", "form-is-valid", "form-submited", "errors-fields", "errors-from-mutation"])
                  ]))
                : _createCommentVNode("", true)
            ], 8, _hoisted_3))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}
}

})