export const BRIEFS_DATA = [
  {
    id: 1,
    title: "paper-based pet food packaging",
    description:
      "PFAS-treated paper grades show exceptional high grease resistance for pet food applications. At the moment where is no 1:1 alternative to existing fluor-based papers available. Plastic film materials need to be added to paper bags to be meet the same barrier properties. We aim for full paper-based solution.",
    image: "pet-food-peach.svg",
    imageColor: "bg-peach-light",
    solutions: [
      {
        text: 'Combo 1',
        img: 'layer-text.png'
      },
      {
        text: 'Combo 2',
        img: 'layer-text-2.png'
      },
      {
        text: 'Combo 3',
        img: 'layer-text.png'
      },
      {
        text: 'Combo 4',
        img: 'layer-text-2.png'
      },{
        text: 'Combo 5',
        img: 'layer-text.png'
      }
    ]
  },
  {
    id: 2,
    title: "paper-based dishwasher (ADW) doypacks",
    description:
      "This brief is to determine the viability to move, where possible, the ADW laminates portfolio to a recyclable Paper base laminates, that can be recycled in the general waste stream and supported with a certificate of recyclability from a certified source.",
    image: "package.svg",
    imageColor: "bg-acqua",
    solutions: [
      {
        text: 'Combo 1',
        img: 'layer-text-3.png'
      },
      {
        text: 'Combo 2',
        img: 'layer-text-2.png'
      },
      {
        text: 'Combo 3',
        img: 'layer-text.png'
      }
    ]
  },
];

export const FUNNEL_LAYERS = [
  {
    id: 1,
    name: "Papers",
    type: "substrate",
    total: 84,
    color: "bg-nude",
  },
  {
    id: 2,
    name: "Plastics",
    type: "substrate",
    total: 84,
    color: "bg-nude",
  },
  {
    id: 3,
    name: "Barrier Technology",
    type: "Coating",
    total: 62,
    color: "bg-acqua",
  },
  {
    id: 4,
    name: "Heat Seals",
    type: "Coating",
    total: 56,
    color: "bg-peach",
  },
];

