import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-span-5 relative"
}
const _hoisted_2 = {
  key: 0,
  class: "absolute top-0 left-0 w-full h-full flex items-center justify-center"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "flex items-start mb-2" }
const _hoisted_5 = { class: "ml-2" }
const _hoisted_6 = { class: "underline" }
const _hoisted_7 = { class: "overflow-y-auto max-h-60 min-h-20 border border-slate-100" }
const _hoisted_8 = { class: "table-auto border-collapse border border-slate-300" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "p-1" }
const _hoisted_11 = ["checked"]
const _hoisted_12 = { class: "p-1 w-full" }
const _hoisted_13 = { class: "text-center p-4" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = { key: 2 }
const _hoisted_16 = { class: "bg-slate-100 p-4 pt-5 pb-5 flex items-start mb-2" }
const _hoisted_17 = { class: "text-center p-4" }
const _hoisted_18 = ["disabled"]

import { computed, ref } from "vue";
import { useStore } from "vuex";
import {RESEARCH_PAPER_AUTOFILL_FIELDS} from '@/models/researchPaperAutofillFormFields';
import { InformationCircleIcon, FaceFrownIcon } from '@heroicons/vue/24/outline';



export default /*@__PURE__*/_defineComponent({
  __name: 'ResearchPaperAutoFillExtract',
  setup(__props) {

const store = useStore();

const formSubmited = ref(false);
// Create Fields for the form
const fieldsFromConstant = computed<string[]>(() => RESEARCH_PAPER_AUTOFILL_FIELDS['EXTRACT'].formFields.map(formField =>  { return formField.mutationValue} ));


const formFieldOptions = {
    fields: fieldsFromConstant.value,
    base: RESEARCH_PAPER_AUTOFILL_FIELDS['EXTRACT'].graphqlValue,
    mutation: 'researchPaperAutofillModule/setSingleValueForExtract'
};
const formFields = (formFieldOptions:{fields:string[], base:string, mutation:string}) => {
    const object = {};
    formFieldOptions.fields.map(fieldName => {
        object[fieldName] = computed({
            get() {
                return store.state.researchPaperAutofillModule[formFieldOptions.base][fieldName];
            },
            set(value) {
                store.commit(formFieldOptions.mutation, { field: fieldName , value: value});
            }
        })
    });
    return object;
}

const vModels = formFields(formFieldOptions);

const valuesSubmitted = computed(() => store.state.researchPaperAutofillModule.extract);
const formIsValid = computed(()=> !errorFields.value);
const errorFields = computed(()=> {
    if(searchResult.value.length){
        if(!valuesSubmitted.value.uid){
            return "uid";
        }
        return null;
    }else{
        if(!valuesSubmitted.value.pdf){
            return "pdf";
        }
        return null;
    }
});

const showSearchResult = computed(()=> store.state.researchPaperAutofillModule.showSearchResult);
const searchResult = computed(()=> store.state.researchPaperAutofillModule.searchResult);
const searchInProgress = computed(()=> store.state.researchPaperAutofillModule.searchInProgress);
const extractInProgress = computed(()=> store.state.researchPaperAutofillModule.extractInProgress);
const buttonText= computed(()=> extractInProgress.value ? "Extracting Selected Info" : "Extract Selected Info");
const buttonInactive = computed(()=> !formIsValid.value || extractInProgress.value);


const handleExtractInfo = async()=>{
    formSubmited.value =true;
    if(formIsValid.value){
        const query = RESEARCH_PAPER_AUTOFILL_FIELDS['EXTRACT'].extractQuery;
        await store.dispatch(query)
        .catch((error)=>{
            // since the search is throwing error, enable pdf upload
            // store.commit("researchPaperAutofillModule/setSearchResult", []);
            console.log('error called')
        })
        .then(()=>{
            formSubmited.value = false;
        });
    }
}
const handlecheck = async(uid)=>{
    if(vModels['uid'].value==uid){
        vModels['uid'].value = null;
    }else{
        vModels['uid'].value = uid;
    }
}

const handleFileChange = (event)=>{
    const files = event.target.files;
    if (files.length > 0) {
    vModels['pdf'].value =  files[0];
    } else {
    vModels['pdf'].value =  null;
    }
}


return (_ctx: any,_cache: any) => {
  return (showSearchResult.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (extractInProgress.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
              _createElementVNode("div", { class: "text-center" }, [
                _createElementVNode("div", { class: "loading-gif-transparent" })
              ], -1)
            ])))
          : _createCommentVNode("", true),
        (searchResult.value.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("p", _hoisted_4, [
                _createVNode(_unref(InformationCircleIcon), { class: "icon icon-mid thick inline" }),
                _createElementVNode("span", _hoisted_5, [
                  _cache[1] || (_cache[1] = _createTextVNode(" Choose your ")),
                  _cache[2] || (_cache[2] = _createElementVNode("strong", null, "desired research paper", -1)),
                  _cache[3] || (_cache[3] = _createTextVNode(" from these ")),
                  _createElementVNode("strong", _hoisted_6, _toDisplayString(searchResult.value.length) + " Research Papers", 1),
                  _cache[4] || (_cache[4] = _createTextVNode(" to extract its relevant information. You may also ")),
                  _cache[5] || (_cache[5] = _createElementVNode("strong", null, "upload a pdf file", -1)),
                  _cache[6] || (_cache[6] = _createTextVNode(" with it so that its content is inlcluded in the ")),
                  _cache[7] || (_cache[7] = _createElementVNode("strong", null, "description", -1)),
                  _cache[8] || (_cache[8] = _createTextVNode(" property. "))
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("table", _hoisted_8, [
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(searchResult.value, (item, index) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: index,
                        class: _normalizeClass([{ 'bg-slate-50': index % 2 === 0,'bg-blue-200':_unref(vModels)['uid'].value==item.uid }, "border border-slate-100 cursor-pointer"]),
                        onClick: ($event: any) => (handlecheck(item.uid))
                      }, [
                        _createElementVNode("td", _hoisted_10, [
                          _createElementVNode("input", {
                            type: "checkbox",
                            checked: _unref(vModels)['uid'].value==item.uid
                          }, null, 8, _hoisted_11)
                        ]),
                        _createElementVNode("td", _hoisted_12, [
                          _createTextVNode(_toDisplayString(item.title) + " ", 1),
                          _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
                          _createElementVNode("small", null, _toDisplayString(item.authors), 1)
                        ])
                      ], 10, _hoisted_9))
                    }), 128))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("input", {
                  type: "file",
                  name: "uploadFiles",
                  id: "uploadFiles",
                  title: "Upload research paper pdf",
                  onChange: handleFileChange,
                  accept: ".pdf"
                }, null, 32),
                _createElementVNode("button", {
                  onClick: handleExtractInfo,
                  class: _normalizeClass(["button green-accept", {'inactive': buttonInactive.value}]),
                  disabled: buttonInactive.value
                }, _toDisplayString(buttonText.value), 11, _hoisted_14)
              ])
            ]))
          : (!searchInProgress.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createElementVNode("p", _hoisted_16, [
                  _createVNode(_unref(FaceFrownIcon), { class: "icon icon-mid thick inline" }),
                  _cache[10] || (_cache[10] = _createElementVNode("span", { class: "ml-2" }, [
                    _createElementVNode("p", { class: "mb-1" }, [
                      _createElementVNode("strong", null, "No Results Found !")
                    ]),
                    _createElementVNode("p", { class: "mb-1" }, [
                      _createTextVNode("It is recommended to try again with different "),
                      _createElementVNode("strong", null, "searchField"),
                      _createTextVNode(" or "),
                      _createElementVNode("strong", null, "searchValue"),
                      _createTextVNode(" until you find your desired result.")
                    ]),
                    _createElementVNode("p", null, "If you are still not able to find results, then as your last option just upload research paper pdf, we will try our best to extract information!")
                  ], -1))
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("input", {
                    type: "file",
                    name: "uploadFiles",
                    id: "uploadFiles",
                    title: "Upload research paper pdf",
                    onChange: handleFileChange,
                    accept: ".pdf"
                  }, null, 32),
                  _createElementVNode("button", {
                    onClick: handleExtractInfo,
                    class: _normalizeClass(["button green-accept", {'inactive':buttonInactive.value}]),
                    disabled: buttonInactive.value
                  }, _toDisplayString(buttonText.value), 11, _hoisted_18)
                ])
              ]))
            : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})