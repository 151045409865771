import gql from "graphql-tag";

export const OTHER_LAST_NUMBERS_BENCHLING = gql`
  query getLastNumbers {
    west1prod_dim_ndn_incrementals {
      last_numbers
    }
  }
`;

export const OTHER_LAST_NUMBERS_Q = gql`
  query getLastNumbersFromQ($filter: OtherTechnologyNodeFilterInputType) {
    otherTechnologies(filter: $filter, orderBy: "-id", first: 10) {
      edges {
        node {
          id
          nickname
        }
      }
    }
  }
`;

export const BENCHLING_DESIGNS = gql`
  query getDesigns {
    prod_mart_data_test_designs {
      description
      total_grammage
      sealing_sides
      programmes
      pro_mat_name
      paper_content
      min_wvtr_38_90
      min_wvtr_23_85
      min_wvtr_23_50
      min_otr_38_90
      min_otr_23_50
      min_otr_23_0
      max_wvtr_38_90
      max_wvtr_23_85
      max_wvtr_23_50
      max_otr_38_90
      max_otr_23_50
      max_otr_23_0
      hs_max_tech_reps
      layers
      locked_design
    }
  }
`;

export const EXT_SOLUTIONS = gql`
  query getExternalEntities {
    prod_mart_data_external_designs {
      description
      design_name
      hs_max_tech_reps
      material
      max_otr_23_0
      max_otr_23_50
      max_otr_38_90
      max_wvtr_23_50
      max_wvtr_23_85
      max_wvtr_38_90
      min_otr_23_0
      min_otr_23_50
      min_otr_38_90
      min_wvtr_23_50
      min_wvtr_23_85
      min_wvtr_38_90
      paper_content
      programmes
      total_grammage
      type
    }
  }
`;
