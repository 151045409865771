import gql from "graphql-tag";

export const CHATS_HISTORY = gql`
query getChats{
    chats(orderBy:"-createdAt"){
        edges{
            node{
                id
                name
                subscriptionId
            }
        }
    }
}
`;
export const CHAT_MESSAGES = gql`
query getChatMessages($chatId:ID!){
    chat(id:$chatId){
        id
        name
        messages{
            edges{
                node{
                    id
                    messageId
                    messageType
                    content
                    contentType
                }
            }
        }
    }
}
`;

export const PRODUCTBRIEF_FEED_DATA = gql`
query getProductBriefFeedData{
    wvtrConditions
    otrConditions
    aiModels
}
`;