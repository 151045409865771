<template>
    <template v-if="assetDetailMainData">
        <AssetDetailHeader :title="assetDetailMainData.title" :users-activity="assetDetailMainData.activity"  />
    </template>
    <template v-if="assetDetailMainData && assetDetailMainData.accordionList">
        <AccordionList v-if="!editModeOn" :data-for-accordion-list="assetDetailMainData.accordionList" />
        <AccordionListEditable v-if="editModeOn" :data-for-accordion-list="assetDetailMainData.accordionList" />
    </template>
</template>
<script setup lang="ts">
import { computed, defineProps } from 'vue';
import AssetDetailHeader from './AssetDetailHeader.vue';
import AccordionList from '@/components/AccordionList.vue';
import AccordionListEditable from '@/components/AccordionListEditable.vue';
import { useStore } from 'vuex';
import { AssetDetailMainDataType } from '@/models/technologyAssetInterface';
    
const store = useStore();

defineProps<{assetDetailMainData: AssetDetailMainDataType}>();
const editModeOn = computed(() => store.getters["assetDetailModule/isAssetMainEditable"]);
</script>

