import gql from "graphql-tag";


export const PATENT_UPDATE_PARTIAL = gql`
    mutation patentUpdatePartial($input: PatentUpdatePartialMutationInput!) {
        patentUpdatePartial (input: $input) {
            patent {
                id
            }
        }
    }
` 

export const RESEARCH_PAPER_UPDATE_PARTIAL = gql`
    mutation researchPaperUpdatePartial($input: ResearchPaperUpdatePartialMutationInput!) {
        researchPaperUpdatePartial (input: $input) {
            researchPaper {
                id
            }
        }
    }
` 

export const RESEARCH_PROJECT_UPDATE_PARTIAL = gql`
    mutation researchProjectUpdatePartial($input: ResearchProjectUpdatePartialMutationInput!) {
        researchProjectUpdatePartial (input: $input) {
            researchProject {
                id
            }
        }
    }
` 

export const OTHER_TECHNOLOGY_UPDATE_PARTIAL = gql`
    mutation otherTechnologyUpdatePartial($input: OtherTechnologyUpdatePartialMutationInput!) {
        otherTechnologyUpdatePartial (input: $input) {
            otherTechnology {
                id
            }
        }
    }
` 