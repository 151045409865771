<template>
    <div class="border shadow-sm bg-white py-6 px-5 flex flex-col justify-between cursor-pointer" @click="goToProductDetail">
        <div>
            <p class="uppercase font-medium text-m mb-2">{{ productBrief.product }}</p>
            <p v-if="productBrief.objective" class="text-gray-500 font-light text-sm mb-4" v-html="productBrief.objective"></p> 
        </div>
        <div class="flex items-end justify-end">
            <p class="bg-red rounded-full text-right uppercase text-sm bg-nyanza px-4 py-1">{{ productBrief.client.nickname }}</p>
        </div>
        
    </div>
</template>

<script setup lang="ts">

import { defineProps, PropType } from 'vue';
import { ProductBriefInterface } from '@/models/productBriefsInterface';
import router from '@/router';

const props = defineProps({
    productBrief: {
        type: Object as PropType<ProductBriefInterface>,
        required: false,
    }
})

const goToProductDetail = () => router.push({ name: 'productBriefDetail', params: { id: props.productBrief.id } });

</script>