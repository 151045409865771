import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withCtx as _withCtx, vShow as _vShow, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col-span-4" }
const _hoisted_2 = { class: "grid grid-cols-4 gap-y-4 gap-x-4" }
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]
const _hoisted_5 = {
  key: 0,
  class: "col-span-4 bg-red-100 p-5 rounded-md"
}
const _hoisted_6 = { class: "text-red-700" }
const _hoisted_7 = {
  key: 1,
  class: "col-span-4 grid grid-cols-3 gap-y-4 gap-x-4"
}
const _hoisted_8 = {
  key: 0,
  class: "col-span-4 grid grid-cols-3 gap-y-4 gap-x-4"
}
const _hoisted_9 = ["value"]
const _hoisted_10 = {
  key: 1,
  class: "col-span-4 grid grid-cols-3 gap-y-4 gap-x-4"
}
const _hoisted_11 = {
  key: 2,
  class: "col-span-4 bg-gray-100 p-3 rounded-md"
}
const _hoisted_12 = {
  key: 0,
  class: "text-sm text-black py-2"
}
const _hoisted_13 = {
  key: 1,
  class: "text-sm text-black py-2"
}
const _hoisted_14 = { class: "flex w-full items-center justify-stretch" }
const _hoisted_15 = { class: "flex w-full items-center justify-between" }
const _hoisted_16 = { class: "flex items-center" }
const _hoisted_17 = { class: "text-sm" }
const _hoisted_18 = { class: "shrink-0 text-white items-right" }
const _hoisted_19 = {
  key: 3,
  class: "col-span-4 flex border border-gray-100 border-solid p-1 pt-3 pb-0 -mt-3 bg-gray-50"
}
const _hoisted_20 = { class: "flex w-full items-center justify-stretch" }
const _hoisted_21 = { class: "flex w-full items-center justify-between" }
const _hoisted_22 = { class: "flex items-center" }
const _hoisted_23 = { class: "text-sm" }
const _hoisted_24 = { class: "shrink-0 text-white items-right" }
const _hoisted_25 = { class: "text-xs text-gray-500 py-2 block" }
const _hoisted_26 = { class: "text-xs text-gray-500 py-2 block" }
const _hoisted_27 = {
  key: 6,
  class: "col-span-4 grid grid-cols-3 gap-y-4 gap-x-4"
}
const _hoisted_28 = { class: "flex w-full items-center justify-stretch" }
const _hoisted_29 = { class: "flex w-full items-center justify-between" }
const _hoisted_30 = { class: "flex items-center" }
const _hoisted_31 = { class: "text-sm" }
const _hoisted_32 = { class: "shrink-0 text-white items-right" }
const _hoisted_33 = {
  key: 7,
  class: "col-span-4 grid grid-cols-3 gap-y-4 gap-x-4"
}
const _hoisted_34 = { class: "text-xs text-gray-500 py-2 block" }
const _hoisted_35 = {
  key: 8,
  class: "col-span-4 grid grid-cols-3 gap-y-4 gap-x-4"
}
const _hoisted_36 = { class: "text-xs text-gray-500 py-2 block" }
const _hoisted_37 = { class: "flex w-full items-center justify-stretch" }
const _hoisted_38 = { class: "flex w-full items-center justify-between" }
const _hoisted_39 = { class: "flex items-center" }
const _hoisted_40 = { class: "text-sm" }
const _hoisted_41 = { class: "shrink-0 text-white items-right" }
const _hoisted_42 = { class: "flex w-full items-center justify-stretch" }
const _hoisted_43 = { class: "flex w-full items-center justify-between" }
const _hoisted_44 = { class: "flex items-center" }
const _hoisted_45 = { class: "text-sm" }
const _hoisted_46 = { class: "shrink-0 text-white items-right" }
const _hoisted_47 = { class: "flex w-full items-center justify-stretch" }
const _hoisted_48 = { class: "flex w-full items-center justify-between" }
const _hoisted_49 = { class: "flex items-center" }
const _hoisted_50 = { class: "text-sm" }
const _hoisted_51 = { class: "shrink-0 text-white items-right" }
const _hoisted_52 = {
  key: 10,
  class: "col-span-4 flex border border-gray-100 border-solid p-1 pt-3 pb-0 -mt-3 bg-gray-50"
}
const _hoisted_53 = {
  key: 0,
  class: "grow grid grid-cols-1 gap-y-4 gap-x-4"
}
const _hoisted_54 = {
  key: 1,
  class: "grow grid grid-cols-1 gap-y-4 gap-x-4 ml-4"
}
const _hoisted_55 = {
  key: 2,
  class: "grow grid grid-cols-1 gap-y-4 gap-x-4 ml-4"
}
const _hoisted_56 = {
  key: 11,
  class: "col-span-4 grid grid-cols-3 gap-y-4 gap-x-4"
}
const _hoisted_57 = {
  key: 12,
  class: "col-span-4 grid grid-cols-3 gap-y-4 gap-x-4"
}
const _hoisted_58 = {
  key: 13,
  class: "col-span-4 grid grid-cols-3 gap-y-4 gap-x-4"
}
const _hoisted_59 = { class: "text-xs text-gray-500 py-2 block" }
const _hoisted_60 = {
  key: 14,
  class: "col-span-4 bg-gray-100 p-3 py-5 rounded-md"
}
const _hoisted_61 = { key: 0 }
const _hoisted_62 = { key: 1 }
const _hoisted_63 = {
  key: 15,
  class: "col-span-4 grid grid-cols-4 gap-y-4 gap-x-4"
}
const _hoisted_64 = ["value"]
const _hoisted_65 = {
  key: 0,
  class: "col-span-4 bg-red-100 p-5 rounded-md"
}
const _hoisted_66 = {
  key: 1,
  class: "col-span-4"
}
const _hoisted_67 = {
  key: 0,
  class: "w-full grid grid-cols-4 gap-y-4 gap-x-4"
}
const _hoisted_68 = { class: "flex w-full items-center justify-stretch" }
const _hoisted_69 = { class: "flex w-full items-center justify-between" }
const _hoisted_70 = { class: "flex items-center" }
const _hoisted_71 = { class: "text-sm" }
const _hoisted_72 = { class: "shrink-0 text-white items-right" }
const _hoisted_73 = {
  key: 0,
  class: "col-span-4 flex border border-gray-100 border-solid p-1 pt-3 pb-1 -mt-3 mb-3 bg-gray-50"
}
const _hoisted_74 = {
  key: 0,
  class: "grow grid grid-cols-1 gap-y-4 gap"
}
const _hoisted_75 = { class: "text-xs text-gray-500 py-2 block" }
const _hoisted_76 = {
  key: 1,
  class: "grow grid grid-cols-1 gap-y-4 gap-x-4"
}
const _hoisted_77 = { class: "text-xs text-gray-500 py-2 block" }
const _hoisted_78 = {
  key: 2,
  class: "grow grid grid-cols-1 gap-y-4 gap-x-4"
}
const _hoisted_79 = {
  key: 1,
  class: "col-span-4"
}
const _hoisted_80 = { class: "text-xs text-gray-500 py-2 block" }

import { computed } from "vue";
import { useFormFieldsCreationNewRun } from "@/tea/composables/runs/useFormFieldsCreationNewRun";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { useStore } from "vuex";
import { NEW_RUN_FIELDS } from "@/tea/models/run";
import { RadioGroup, RadioGroupLabel, RadioGroupOption, Switch } from "@headlessui/vue";
import { NewspaperIcon, ExclamationTriangleIcon, LightBulbIcon } from "@heroicons/vue/24/outline";

export default /*@__PURE__*/_defineComponent({
  __name: 'RunNonResidual',
  props: ["errorsFields", "formSubmited", "formInizializationValues"],
  setup(__props) {

const bycompounds = [0, 1, 2, 3];
const landCapexes = [
  { key: "Buying", value: "Buying" },
  { key: "Renting", value: "Renting" },
];
const yesNos = [
  { key: true, value: "Yes" },
  { key: false, value: "No" },
];
const suppliments = [0, 1, 2, 3];

const store = useStore();

const props = __props;

const fields = computed(() => store.getters["runCreateModule/fields"]);
const ocsGoodCombo = computed(() => {
  const combo = [fields.value.organism, fields.value.cultivationSystem];
  if (combo[0] != null && combo[1] != null) {
    const isGoodCombo = props.formInizializationValues["ocsGoodCombos"].some((tuple) => tuple[0] === combo[0] && tuple[1] === combo[1]);
    return isGoodCombo;
  }
  return null;
});
const shmGoodCombo = computed(() => {
  const species = props.formInizializationValues["cultivationSystemsOptions"].find((item) => item.id == fields.value.cultivationSystem).species.id;
  const combo = [species, fields.value.harvestMethod];
  if (combo[0] != null && combo[1] != null) {
    const isGoodCombo = props.formInizializationValues["shmGoodCombos"].some((tuple) => tuple[0] === combo[0] && tuple[1] === combo[1]);
    return isGoodCombo;
  }
  return null;
});
const isSystemSSF = computed(() => {
  const csId = fields.value.cultivationSystem;
  if (props.formInizializationValues["cultivationSystemsOptions"] && csId != null) {
    if (props.formInizializationValues["cultivationSystemsOptions"].find((item) => item.name == "Solid-state fermentor (SSF)").id == csId) {
      return true;
    } else {
      // set biomass_target to 'Target compound'
      return false;
    }
  }
  return null;
});

const isSystemGreenhouse = computed(() => {
  const csId = fields.value.cultivationSystem;
  if (props.formInizializationValues["cultivationSystemsOptions"] && csId != null) {
    if (props.formInizializationValues["cultivationSystemsOptions"].find((item) => item.name == "Greenhouse").id == csId) {
      return true;
    } else {
      // set biomass_target to 'Target compound'
      return false;
    }
  }
  return null;
});

const isOrganismAutotroph = computed(() => {
  const oId = fields.value.organism;
  if (props.formInizializationValues["organismsOptions"] && oId != null) {
    if (props.formInizializationValues["organismsOptions"].find((item) => item.name == "Autotroph").id == oId) {
      return true;
    } else {
      // set biomass_target to 'Target compound'
      return false;
    }
  }
  return null;
});

const isOrganismHetrotroph = computed(() => {
  const oId = fields.value.organism;
  if (props.formInizializationValues["organismsOptions"] && oId != null) {
    if (props.formInizializationValues["organismsOptions"].find((item) => item.name == "Heterotroph").id == oId) {
      return true;
    } else {
      // set biomass_target to 'Target compound'
      return false;
    }
  }
  return null;
});
const isOrganismMixotroph = computed(() => {
  const oId = fields.value.organism;
  if (props.formInizializationValues["organismsOptions"] && oId != null) {
    if (props.formInizializationValues["organismsOptions"].find((item) => item.name == "Mixotroph").id == oId) {
      return true;
    } else {
      // set biomass_target to 'Target compound'
      return false;
    }
  }
  return null;
});

const showFirstInfo = computed(() => {
  if (ocsGoodCombo.value && fields.value.compoundPct) {
    if (fields.value.biomassTarget == "Target compound" && fields.value.targetCompoundCapacity) {
      return true;
    } else if (fields.value.biomassTarget == "Substrate" && fields.value.biomass) {
      return true;
    }
  }
  if (ocsGoodCombo.value && fields.value.targetCompoundCapacity) {
    return true;
  }
  return false;
});

const showbycompoundX = computed(() => {
  const bc = Number(fields.value.bycompounds);
  if (props.formInizializationValues["organismsOptions"] && bc > 0) {
    return true;
  }
  return false;
});
const showbycompoundY = computed(() => {
  const bc = Number(fields.value.bycompounds);
  if (props.formInizializationValues["organismsOptions"] && bc > 1) {
    return true;
  }
  return false;
});
const showbycompoundZ = computed(() => {
  const bc = Number(fields.value.bycompounds);
  if (props.formInizializationValues["organismsOptions"] && bc == 3) {
    return true;
  }
  return false;
});
const showsupplimentX = computed(() => {
  const s = Number(fields.value.suppliments);
  if (props.formInizializationValues["organismsOptions"] && s > 0) {
    return true;
  }
  return false;
});
const showsupplimentY = computed(() => {
  const s = Number(fields.value.suppliments);
  if (props.formInizializationValues["organismsOptions"] && s > 1) {
    return true;
  }
  return false;
});
const showsupplimentZ = computed(() => {
  const s = Number(fields.value.suppliments);
  if (props.formInizializationValues["organismsOptions"] && s == 3) {
    return true;
  }
  return false;
});

const calcTargetCompoundPerYear = computed(() => {
  const tcc = fields.value.targetCompoundCapacity;
  const cpct = fields.value.compoundPct;
  return Math.round(tcc / (cpct / 100));
});
const calcSubstratePerYear = computed(() => {
  const biomass = fields.value.biomass;
  const cpct = fields.value.compoundPct;
  return Math.round(biomass * (cpct / 100));
});

const showSecondInfo = computed(() => {
  if (fields.value.land && fields.value.harvestingConc) {
    return true;
  }
  return false;
});
const calcVolumeRealPerCycle = computed(() => {
  const n_cycles = fields.value.operatingDays / fields.value.cycleLength;
  const biomass = fields.value.targetCompoundCapacity / (fields.value.compoundPct / 100);

  if (!isSystemGreenhouse.value) {
    const volume_real_per_cyc = (biomass * 1000) / n_cycles / fields.value.harvestingConc;
    const volume_tot = (biomass * 1000) / n_cycles / fields.value.harvestingConc / (fields.value.harvestingPct / 100) / (fields.value.facilityUsagePct / 100);
    const land_estim = volume_tot / fields.value.land;
    let towrite,
      towrite2 = [];
    if (volume_tot >= 1000) {
      towrite = [Math.round(volume_tot / 1000), "1,000 liters"];
    } else if (volume_tot >= 100 && volume_tot < 1000) {
      towrite = [Math.round(volume_tot / 100), "100 liters"];
    } else {
      towrite = [Math.round(volume_tot / 10), "10 liters"];
    }
    if (land_estim > 0.1) {
      towrite2 = [Number(land_estim).toFixed(1), "hectares of land"];
    } else {
      towrite2 = [Number(land_estim * 10000).toFixed(1), "m2 of land"];
    }

    return { volume_real_per_cyc: Math.round(volume_real_per_cyc), towrite: towrite, towrite2: towrite2 };
  } else {
    const volume =
      (biomass * 1000) /
      n_cycles /
      fields.value.harvestingConc /
      (fields.value.harvestingPct / 100) /
      (fields.value.facilityUsagePct / 100) /
      fields.value.land;
    let towrite2 = [];
    if (volume > 0.1) {
      towrite2 = [Number(volume).toFixed(1), "hectares of land"];
    } else {
      towrite2 = [Number(volume * 10000).toFixed(1), "m2 of land"];
    }

    return { towrite2: towrite2 };
  }
});

const isHarvestingMethodFlocculation = computed(() => {
  const fId = fields.value.harvestMethod;
  if (props.formInizializationValues["harvestingMethodsOptions"] && fId != null) {
    if (props.formInizializationValues["harvestingMethodsOptions"].find((item) => item.name == "Flocculation").id == fId) {
      return true;
    } else {
      return false;
    }
  }
  return null;
});
const isHarvestingMethodCFF = computed(() => {
  const fId = fields.value.harvestMethod;
  if (props.formInizializationValues["harvestingMethodsOptions"] && fId != null) {
    if (props.formInizializationValues["harvestingMethodsOptions"].find((item) => item.name == "Cross flow filtration").id == fId) {
      return true;
    } else {
      return false;
    }
  }
  return null;
});

const selectedCultivationSystem = computed(() => {
  if (props.formInizializationValues["cultivationSystemsOptions"]) {
    return props.formInizializationValues["cultivationSystemsOptions"].find((item) => item.id == fields.value.cultivationSystem).name;
  }
  return null;
});

const isFlocculantAluminium = computed(() => {
  const fId = fields.value.flocculant;
  if (props.formInizializationValues["flocculantsOptions"] && fId != null) {
    if (props.formInizializationValues["flocculantsOptions"].find((item) => item.name == "Aluminium").id == fId) {
      return true;
    } else {
      return false;
    }
  }
  return null;
});
const isFlocculantChitosan = computed(() => {
  const fId = fields.value.flocculant;
  if (props.formInizializationValues["flocculantsOptions"] && fId != null) {
    if (props.formInizializationValues["flocculantsOptions"].find((item) => item.name == "Chitosan").id == fId) {
      return true;
    } else {
      return false;
    }
  }
  return null;
});
const isFlocculantAnotherFlocculant = computed(() => {
  const fId = fields.value.flocculant;
  if (props.formInizializationValues["flocculantsOptions"] && fId != null) {
    if (props.formInizializationValues["flocculantsOptions"].find((item) => item.name == "Another flocculant").id == fId) {
      return true;
    } else {
      return false;
    }
  }
  return null;
});

const getFormFields = Object.keys(NEW_RUN_FIELDS.formFields).map((key) => {
  const fieldConfig = NEW_RUN_FIELDS.formFields[key];
  return {
    [key]: Array.isArray(fieldConfig.onChangeResetFields) ? fieldConfig.onChangeResetFields : [],
  };
});
const formFieldOptions = {
  fields: getFormFields,
  mutation: "runCreateModule/SET_SINGLE_RUN_VALUE",
};
const formFields = useFormFieldsCreationNewRun(formFieldOptions);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('organism') && __props.formSubmited }])
      }, [
        _cache[48] || (_cache[48] = _createElementVNode("label", null, "Organism *", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "w-full",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(formFields)['organism'].value) = $event))
        }, [
          _cache[47] || (_cache[47] = _createElementVNode("option", {
            selected: "",
            value: null
          }, null, -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.formInizializationValues['organismsOptions'], (organism) => {
            return (_openBlock(), _createElementBlock("option", {
              value: Number(organism.id),
              key: organism.id
            }, _toDisplayString(organism.name), 9, _hoisted_3))
          }), 128))
        ], 512), [
          [_vModelSelect, _unref(formFields)['organism'].value]
        ])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('cultivationSystem') && __props.formSubmited }])
      }, [
        _cache[50] || (_cache[50] = _createElementVNode("label", null, "Cultivation System *", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "w-full",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(formFields)['cultivationSystem'].value) = $event))
        }, [
          _cache[49] || (_cache[49] = _createElementVNode("option", {
            selected: "",
            value: null
          }, null, -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.formInizializationValues['cultivationSystemsOptions'], (cultivationSystem) => {
            return (_openBlock(), _createElementBlock("option", {
              value: Number(cultivationSystem.id),
              key: cultivationSystem.id
            }, _toDisplayString(cultivationSystem.name), 9, _hoisted_4))
          }), 128))
        ], 512), [
          [_vModelSelect, _unref(formFields)['cultivationSystem'].value]
        ])
      ], 2),
      (ocsGoodCombo.value == false)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, [
              _createVNode(_unref(ExclamationTriangleIcon), { class: "icon icon-small inline-block red" }),
              _cache[51] || (_cache[51] = _createTextVNode(" The organism you selected cannot be cultivated in the cultivation system you selected. Please choose a more suitable one. "))
            ])
          ]))
        : (ocsGoodCombo.value == true)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", {
                class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('compoundPct') && __props.formSubmited }])
              }, [
                _cache[52] || (_cache[52] = _createElementVNode("label", null, "Target compound (% dry weight biomass) *", -1)),
                _withDirectives(_createElementVNode("input", {
                  class: "w-full",
                  type: "number",
                  min: "0.00",
                  max: "100.00",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(formFields)['compoundPct'].value) = $event))
                }, null, 512), [
                  [_vModelText, _unref(formFields)['compoundPct'].value]
                ]),
                _cache[53] || (_cache[53] = _createElementVNode("span", { class: "text-xs text-gray-400" }, "Note: if you chose Solid-state fermentor (SSF) as cultivation system the term 'biomass' stands for 'substrate'", -1))
              ], 2),
              (isSystemSSF.value == true)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["col-span-3", { 'error-form-field': __props.errorsFields.includes('biomassTarget') && __props.formSubmited }])
                    }, [
                      _cache[54] || (_cache[54] = _createElementVNode("label", null, "I know the target compound or substrate capacity? *", -1)),
                      _withDirectives(_createElementVNode("select", {
                        class: "w-full",
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(formFields)['biomassTarget'].value) = $event))
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.formInizializationValues['biomassTargetsOptions'], (biomassTarget) => {
                          return (_openBlock(), _createElementBlock("option", {
                            value: biomassTarget.id,
                            key: biomassTarget.id
                          }, _toDisplayString(biomassTarget.name), 9, _hoisted_9))
                        }), 128))
                      ], 512), [
                        [_vModelSelect, _unref(formFields)['biomassTarget'].value]
                      ])
                    ], 2),
                    (fields.value.biomassTarget == 'Target compound')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(["col-span-3", { 'error-form-field': __props.errorsFields.includes('targetCompoundCapacity') && __props.formSubmited }])
                        }, [
                          _cache[55] || (_cache[55] = _createElementVNode("label", null, "Target compound capacity (ton/year dry weight) *", -1)),
                          _withDirectives(_createElementVNode("input", {
                            class: "w-full",
                            type: "number",
                            min: "0.00",
                            max: "100.00",
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(formFields)['targetCompoundCapacity'].value) = $event))
                          }, null, 512), [
                            [_vModelText, _unref(formFields)['targetCompoundCapacity'].value]
                          ])
                        ], 2))
                      : (fields.value.biomassTarget == 'Substrate')
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(["col-span-3", { 'error-form-field': __props.errorsFields.includes('biomass') && __props.formSubmited }])
                          }, [
                            _cache[56] || (_cache[56] = _createElementVNode("label", null, "Substrate capacity (ton/year dry weight) *", -1)),
                            _withDirectives(_createElementVNode("input", {
                              class: "w-full",
                              type: "number",
                              min: "0.00",
                              max: "100.00",
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(formFields)['biomass'].value) = $event))
                            }, null, 512), [
                              [_vModelText, _unref(formFields)['biomass'].value]
                            ])
                          ], 2))
                        : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      class: _normalizeClass(["col-span-3", { 'error-form-field': __props.errorsFields.includes('costBiomass') && __props.formSubmited }])
                    }, [
                      _cache[57] || (_cache[57] = _createElementVNode("label", null, "Price substrate (€/ton) *", -1)),
                      _withDirectives(_createElementVNode("input", {
                        class: "w-full",
                        type: "number",
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(formFields)['costBiomass'].value) = $event))
                      }, null, 512), [
                        [_vModelText, _unref(formFields)['costBiomass'].value]
                      ])
                    ], 2)
                  ]))
                : (isSystemSSF.value == false)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-span-3", { 'error-form-field': __props.errorsFields.includes('targetCompoundCapacity') && __props.formSubmited }])
                      }, [
                        _cache[58] || (_cache[58] = _createElementVNode("label", null, "Target compound capacity (ton/year dry weight) *", -1)),
                        _withDirectives(_createElementVNode("input", {
                          class: "w-full",
                          type: "number",
                          min: "0.00",
                          max: "100.00",
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(formFields)['targetCompoundCapacity'].value) = $event))
                        }, null, 512), [
                          [_vModelText, _unref(formFields)['targetCompoundCapacity'].value]
                        ])
                      ], 2)
                    ]))
                  : _createCommentVNode("", true),
              (showFirstInfo.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    (fields.value.biomassTarget == 'Target compound')
                      ? (_openBlock(), _createElementBlock("p", _hoisted_12, [
                          _createVNode(_unref(NewspaperIcon), { class: "icon icon-small inline-block" }),
                          _createTextVNode(" Considering your target compound production you will need " + _toDisplayString(calcTargetCompoundPerYear.value) + " tons of biomass per year ", 1)
                        ]))
                      : _createCommentVNode("", true),
                    (fields.value.biomassTarget == 'Substrate')
                      ? (_openBlock(), _createElementBlock("p", _hoisted_13, [
                          _createVNode(_unref(NewspaperIcon), { class: "icon icon-small inline-block" }),
                          _createTextVNode(" Considering your substrate availability you will be able to have " + _toDisplayString(calcSubstratePerYear.value) + " tons of target compound per year ", 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_unref(RadioGroup), {
                modelValue: _unref(formFields)['bycompounds'].value,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(formFields)['bycompounds'].value) = $event)),
                class: _normalizeClass(["col-span-4 mb-0", { 'error-form-field': __props.errorsFields.includes('bycompounds') && __props.formSubmited }])
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(RadioGroupLabel), { class: "mb-1" }, {
                    default: _withCtx(() => _cache[59] || (_cache[59] = [
                      _createTextVNode("How many bycompounds are you going to have?")
                    ])),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_14, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(bycompounds, (bycompound) => {
                      return _createVNode(_unref(RadioGroupOption), {
                        as: "template",
                        key: bycompound,
                        value: bycompound
                      }, {
                        default: _withCtx(({ active, checked }) => [
                          _createElementVNode("div", {
                            class: _normalizeClass([[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white '], "relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"])
                          }, [
                            _createElementVNode("div", _hoisted_15, [
                              _createElementVNode("div", _hoisted_16, [
                                _createElementVNode("div", _hoisted_17, [
                                  _createVNode(_unref(RadioGroupLabel), {
                                    as: "p",
                                    class: _normalizeClass([checked ? 'text-white' : 'text-gray-500', "font-base"])
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(bycompound), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["class"])
                                ])
                              ]),
                              _withDirectives(_createElementVNode("div", _hoisted_18, _cache[60] || (_cache[60] = [
                                _createElementVNode("svg", {
                                  class: "h-5 w-5",
                                  viewBox: "0 0 24 24",
                                  fill: "none"
                                }, [
                                  _createElementVNode("circle", {
                                    cx: "12",
                                    cy: "12",
                                    r: "12",
                                    fill: "#fff",
                                    "fill-opacity": "0.2"
                                  }),
                                  _createElementVNode("path", {
                                    d: "M7 13l3 3 7-7",
                                    stroke: "#fff",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  })
                                ], -1)
                              ]), 512), [
                                [_vShow, checked]
                              ])
                            ])
                          ], 2)
                        ]),
                        _: 2
                      }, 1032, ["value"])
                    }), 64))
                  ])
                ]),
                _: 1
              }, 8, ["modelValue", "class"]),
              (showbycompoundX.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    (showbycompoundX.value)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(["grow mb-4", { 'error-form-field': __props.errorsFields.includes('bycompoundXPct') && __props.formSubmited }])
                        }, [
                          _cache[61] || (_cache[61] = _createElementVNode("label", null, "Bycompound x (% dry weight biomass)", -1)),
                          _withDirectives(_createElementVNode("input", {
                            class: "w-full",
                            type: "number",
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(formFields)['bycompoundXPct'].value) = $event))
                          }, null, 512), [
                            [_vModelText, _unref(formFields)['bycompoundXPct'].value]
                          ])
                        ], 2))
                      : _createCommentVNode("", true),
                    (showbycompoundY.value)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: _normalizeClass(["grow mb-4 ml-4", { 'error-form-field': __props.errorsFields.includes('bycompoundYPct') && __props.formSubmited }])
                        }, [
                          _cache[62] || (_cache[62] = _createElementVNode("label", null, "Bycompound y (% dry weight biomass)", -1)),
                          _withDirectives(_createElementVNode("input", {
                            class: "w-full",
                            type: "number",
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(formFields)['bycompoundYPct'].value) = $event))
                          }, null, 512), [
                            [_vModelText, _unref(formFields)['bycompoundYPct'].value]
                          ])
                        ], 2))
                      : _createCommentVNode("", true),
                    (showbycompoundZ.value)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 2,
                          class: _normalizeClass(["grow mb-4 ml-4", { 'error-form-field': __props.errorsFields.includes('bycompoundZPct') && __props.formSubmited }])
                        }, [
                          _cache[63] || (_cache[63] = _createElementVNode("label", null, "Bycompound z (% dry weight biomass)", -1)),
                          _withDirectives(_createElementVNode("input", {
                            class: "w-full",
                            type: "number",
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_unref(formFields)['bycompoundZPct'].value) = $event))
                          }, null, 512), [
                            [_vModelText, _unref(formFields)['bycompoundZPct'].value]
                          ])
                        ], 2))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_unref(RadioGroup), {
                modelValue: _unref(formFields)['landCapex'].value,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_unref(formFields)['landCapex'].value) = $event)),
                class: _normalizeClass(["col-span-4 mb-0 mt-1", { 'error-form-field': __props.errorsFields.includes('landCapex') && __props.formSubmited }])
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(RadioGroupLabel), { class: "mb-1" }, {
                    default: _withCtx(() => _cache[64] || (_cache[64] = [
                      _createTextVNode("Are you buying or renting the land? *")
                    ])),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_20, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(landCapexes, (landCapex) => {
                      return _createVNode(_unref(RadioGroupOption), {
                        as: "template",
                        key: landCapex.key,
                        value: landCapex.key
                      }, {
                        default: _withCtx(({ active, checked }) => [
                          _createElementVNode("div", {
                            class: _normalizeClass([[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white '], "relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"])
                          }, [
                            _createElementVNode("div", _hoisted_21, [
                              _createElementVNode("div", _hoisted_22, [
                                _createElementVNode("div", _hoisted_23, [
                                  _createVNode(_unref(RadioGroupLabel), {
                                    as: "p",
                                    class: _normalizeClass([checked ? 'text-white' : 'text-gray-500', "font-base"])
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(landCapex.value), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["class"])
                                ])
                              ]),
                              _withDirectives(_createElementVNode("div", _hoisted_24, _cache[65] || (_cache[65] = [
                                _createElementVNode("svg", {
                                  class: "h-5 w-5",
                                  viewBox: "0 0 24 24",
                                  fill: "none"
                                }, [
                                  _createElementVNode("circle", {
                                    cx: "12",
                                    cy: "12",
                                    r: "12",
                                    fill: "#fff",
                                    "fill-opacity": "0.2"
                                  }),
                                  _createElementVNode("path", {
                                    d: "M7 13l3 3 7-7",
                                    stroke: "#fff",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  })
                                ], -1)
                              ]), 512), [
                                [_vShow, checked]
                              ])
                            ])
                          ], 2)
                        ]),
                        _: 2
                      }, 1032, ["value"])
                    }), 64))
                  ])
                ]),
                _: 1
              }, 8, ["modelValue", "class"]),
              (isSystemGreenhouse.value != true)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 4,
                    class: _normalizeClass(["col-span-4 border border-gray-100 border-solid p-1 pt-3 pb-0 -mt-3 bg-gray-50", { 'error-form-field': __props.errorsFields.includes('land') && __props.formSubmited }])
                  }, [
                    _cache[67] || (_cache[67] = _createElementVNode("label", null, "Estimated volume per hectare (m3) *", -1)),
                    _withDirectives(_createElementVNode("input", {
                      class: "w-full",
                      type: "number",
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_unref(formFields)['land'].value) = $event))
                    }, null, 512), [
                      [_vModelText, _unref(formFields)['land'].value]
                    ]),
                    _createElementVNode("span", _hoisted_25, [
                      _createVNode(_unref(LightBulbIcon), { class: "icon icon-small inline-block" }),
                      _cache[66] || (_cache[66] = _createTextVNode(" If you have no idea 5,000 m3/ha is a good estimation!"))
                    ])
                  ], 2))
                : (_openBlock(), _createElementBlock("div", {
                    key: 5,
                    class: _normalizeClass(["col-span-4 border border-gray-100 border-solid p-1 pt-3 pb-0 -mt-3 bg-gray-50", { 'error-form-field': __props.errorsFields.includes('land') && __props.formSubmited }])
                  }, [
                    _cache[69] || (_cache[69] = _createElementVNode("label", null, "Estimated plant density per hectare (#plants/ha) *", -1)),
                    _withDirectives(_createElementVNode("input", {
                      class: "w-full",
                      type: "number",
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_unref(formFields)['land'].value) = $event))
                    }, null, 512), [
                      [_vModelText, _unref(formFields)['land'].value]
                    ]),
                    _createElementVNode("span", _hoisted_26, [
                      _createVNode(_unref(LightBulbIcon), { class: "icon icon-small inline-block" }),
                      _cache[68] || (_cache[68] = _createTextVNode(" If you have no idea 350,000 plants/ha is a good estimation!"))
                    ])
                  ], 2)),
              (isOrganismAutotroph.value == true)
                ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                    _createVNode(_unref(RadioGroup), {
                      modelValue: _unref(formFields)['artificialLight'].value,
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_unref(formFields)['artificialLight'].value) = $event)),
                      class: _normalizeClass(["col-span-4 mb-0 mt-1", { 'error-form-field': __props.errorsFields.includes('artificialLight') && __props.formSubmited }])
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(RadioGroupLabel), { class: "mb-1" }, {
                          default: _withCtx(() => _cache[70] || (_cache[70] = [
                            _createTextVNode("Are you going to use artificial light? *")
                          ])),
                          _: 1
                        }),
                        _createElementVNode("div", _hoisted_28, [
                          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(yesNos, (yesNo) => {
                            return _createVNode(_unref(RadioGroupOption), {
                              as: "template",
                              key: yesNo.value,
                              value: yesNo.key
                            }, {
                              default: _withCtx(({ active, checked }) => [
                                _createElementVNode("div", {
                                  class: _normalizeClass([[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white '], "relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"])
                                }, [
                                  _createElementVNode("div", _hoisted_29, [
                                    _createElementVNode("div", _hoisted_30, [
                                      _createElementVNode("div", _hoisted_31, [
                                        _createVNode(_unref(RadioGroupLabel), {
                                          as: "p",
                                          class: _normalizeClass([checked ? 'text-white' : 'text-gray-500', "font-base"])
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(yesNo.value), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["class"])
                                      ])
                                    ]),
                                    _withDirectives(_createElementVNode("div", _hoisted_32, _cache[71] || (_cache[71] = [
                                      _createElementVNode("svg", {
                                        class: "h-5 w-5",
                                        viewBox: "0 0 24 24",
                                        fill: "none"
                                      }, [
                                        _createElementVNode("circle", {
                                          cx: "12",
                                          cy: "12",
                                          r: "12",
                                          fill: "#fff",
                                          "fill-opacity": "0.2"
                                        }),
                                        _createElementVNode("path", {
                                          d: "M7 13l3 3 7-7",
                                          stroke: "#fff",
                                          "stroke-width": "1.5",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round"
                                        })
                                      ], -1)
                                    ]), 512), [
                                      [_vShow, checked]
                                    ])
                                  ])
                                ], 2)
                              ]),
                              _: 2
                            }, 1032, ["value"])
                          }), 64))
                        ])
                      ]),
                      _: 1
                    }, 8, ["modelValue", "class"]),
                    (fields.value.artificialLight == true)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(["col-span-4 border border-gray-100 border-solid p-1 pt-3 pb-0 -mt-3 bg-gray-50", { 'error-form-field': __props.errorsFields.includes('hsLight') && __props.formSubmited }])
                        }, [
                          _cache[72] || (_cache[72] = _createElementVNode("label", null, "Light period (hours per day) *", -1)),
                          _withDirectives(_createElementVNode("input", {
                            class: "w-full",
                            type: "number",
                            "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_unref(formFields)['hsLight'].value) = $event))
                          }, null, 512), [
                            [_vModelText, _unref(formFields)['hsLight'].value]
                          ])
                        ], 2))
                      : _createCommentVNode("", true)
                  ]))
                : (isOrganismHetrotroph.value == true && isSystemSSF.value != true)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('sugarConc') && __props.formSubmited }])
                      }, [
                        _cache[74] || (_cache[74] = _createElementVNode("label", null, "What is the preferred sugar concentration? (kg/kg biomass) *", -1)),
                        _withDirectives(_createElementVNode("input", {
                          class: "w-full",
                          type: "number",
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_unref(formFields)['sugarConc'].value) = $event))
                        }, null, 512), [
                          [_vModelText, _unref(formFields)['sugarConc'].value]
                        ]),
                        _createElementVNode("span", _hoisted_34, [
                          _createVNode(_unref(LightBulbIcon), { class: "icon icon-small inline-block" }),
                          _cache[73] || (_cache[73] = _createTextVNode(" The reference price for sugar is 0.44 €/kg. If this reference does not correspond please select \"Another price\""))
                        ])
                      ], 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-span-4 flex items-center", { 'error-form-field': __props.errorsFields.includes('isOtherSugarPrice') && __props.formSubmited }])
                      }, [
                        _cache[76] || (_cache[76] = _createElementVNode("label", { class: "inline-block mr-2" }, "Another Price", -1)),
                        _createVNode(_unref(Switch), {
                          modelValue: _unref(formFields)['isOtherSugarPrice'].value,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_unref(formFields)['isOtherSugarPrice'].value) = $event)),
                          class: _normalizeClass([_unref(formFields)['isOtherSugarPrice'].value ? 'bg-emerald-700' : 'bg-gray-200', "relative inline-flex h-6 w-11 items-center rounded-full"])
                        }, {
                          default: _withCtx(() => [
                            _cache[75] || (_cache[75] = _createElementVNode("span", { class: "sr-only" }, "Another Price", -1)),
                            _createElementVNode("span", {
                              class: _normalizeClass([_unref(formFields)['isOtherSugarPrice'].value ? 'translate-x-6' : 'translate-x-1', "inline-block h-4 w-4 transform rounded-full bg-white transition"])
                            }, null, 2)
                          ]),
                          _: 1
                        }, 8, ["modelValue", "class"])
                      ], 2),
                      (fields.value.isOtherSugarPrice == true)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('otherSugarPrice') && __props.formSubmited }])
                          }, [
                            _cache[77] || (_cache[77] = _createElementVNode("label", null, "Price sugar? (€/kg) *", -1)),
                            _withDirectives(_createElementVNode("input", {
                              class: "w-full",
                              type: "number",
                              "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_unref(formFields)['otherSugarPrice'].value) = $event))
                            }, null, 512), [
                              [_vModelText, _unref(formFields)['otherSugarPrice'].value]
                            ])
                          ], 2))
                        : _createCommentVNode("", true)
                    ]))
                  : (isOrganismMixotroph.value == true)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('sugarConc') && __props.formSubmited }])
                        }, [
                          _cache[79] || (_cache[79] = _createElementVNode("label", null, "What is the preferred sugar concentration? (kg/kg biomass) *", -1)),
                          _withDirectives(_createElementVNode("input", {
                            class: "w-full",
                            type: "number",
                            "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_unref(formFields)['sugarConc'].value) = $event))
                          }, null, 512), [
                            [_vModelText, _unref(formFields)['sugarConc'].value]
                          ]),
                          _createElementVNode("span", _hoisted_36, [
                            _createVNode(_unref(LightBulbIcon), { class: "icon icon-small inline-block" }),
                            _cache[78] || (_cache[78] = _createTextVNode(" The reference price for sugar is 0.44 €/kg. If this reference does not correspond please select \"Another price\""))
                          ])
                        ], 2),
                        _createElementVNode("div", {
                          class: _normalizeClass(["col-span-4 flex items-center", { 'error-form-field': __props.errorsFields.includes('isOtherSugarPrice') && __props.formSubmited }])
                        }, [
                          _cache[81] || (_cache[81] = _createElementVNode("label", { class: "inline-block mr-2" }, "Another Price", -1)),
                          _createVNode(_unref(Switch), {
                            modelValue: _unref(formFields)['isOtherSugarPrice'].value,
                            "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_unref(formFields)['isOtherSugarPrice'].value) = $event)),
                            class: _normalizeClass([_unref(formFields)['isOtherSugarPrice'].value ? 'bg-emerald-700' : 'bg-gray-200', "relative inline-flex h-6 w-11 items-center rounded-full"])
                          }, {
                            default: _withCtx(() => [
                              _cache[80] || (_cache[80] = _createElementVNode("span", { class: "sr-only" }, "Another Price", -1)),
                              _createElementVNode("span", {
                                class: _normalizeClass([_unref(formFields)['isOtherSugarPrice'].value ? 'translate-x-6' : 'translate-x-1', "inline-block h-4 w-4 transform rounded-full bg-white transition"])
                              }, null, 2)
                            ]),
                            _: 1
                          }, 8, ["modelValue", "class"])
                        ], 2),
                        (fields.value.isOtherSugarPrice == true)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('otherSugarPrice') && __props.formSubmited }])
                            }, [
                              _cache[82] || (_cache[82] = _createElementVNode("label", null, "Price sugar? (€/kg) *", -1)),
                              _withDirectives(_createElementVNode("input", {
                                class: "w-full",
                                type: "number",
                                "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_unref(formFields)['otherSugarPrice'].value) = $event))
                              }, null, 512), [
                                [_vModelText, _unref(formFields)['otherSugarPrice'].value]
                              ])
                            ], 2))
                          : _createCommentVNode("", true),
                        _createVNode(_unref(RadioGroup), {
                          modelValue: _unref(formFields)['artificialLight'].value,
                          "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_unref(formFields)['artificialLight'].value) = $event)),
                          class: _normalizeClass(["col-span-4 mb-0 mt-1", { 'error-form-field': __props.errorsFields.includes('artificialLight') && __props.formSubmited }])
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(RadioGroupLabel), { class: "mb-1" }, {
                              default: _withCtx(() => _cache[83] || (_cache[83] = [
                                _createTextVNode("Are you going to use artificial light? *")
                              ])),
                              _: 1
                            }),
                            _createElementVNode("div", _hoisted_37, [
                              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(yesNos, (yesNo) => {
                                return _createVNode(_unref(RadioGroupOption), {
                                  as: "template",
                                  key: yesNo.value,
                                  value: yesNo.key
                                }, {
                                  default: _withCtx(({ active, checked }) => [
                                    _createElementVNode("div", {
                                      class: _normalizeClass([[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white '], "relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"])
                                    }, [
                                      _createElementVNode("div", _hoisted_38, [
                                        _createElementVNode("div", _hoisted_39, [
                                          _createElementVNode("div", _hoisted_40, [
                                            _createVNode(_unref(RadioGroupLabel), {
                                              as: "p",
                                              class: _normalizeClass([checked ? 'text-white' : 'text-gray-500', "font-base"])
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(yesNo.value), 1)
                                              ]),
                                              _: 2
                                            }, 1032, ["class"])
                                          ])
                                        ]),
                                        _withDirectives(_createElementVNode("div", _hoisted_41, _cache[84] || (_cache[84] = [
                                          _createElementVNode("svg", {
                                            class: "h-5 w-5",
                                            viewBox: "0 0 24 24",
                                            fill: "none"
                                          }, [
                                            _createElementVNode("circle", {
                                              cx: "12",
                                              cy: "12",
                                              r: "12",
                                              fill: "#fff",
                                              "fill-opacity": "0.2"
                                            }),
                                            _createElementVNode("path", {
                                              d: "M7 13l3 3 7-7",
                                              stroke: "#fff",
                                              "stroke-width": "1.5",
                                              "stroke-linecap": "round",
                                              "stroke-linejoin": "round"
                                            })
                                          ], -1)
                                        ]), 512), [
                                          [_vShow, checked]
                                        ])
                                      ])
                                    ], 2)
                                  ]),
                                  _: 2
                                }, 1032, ["value"])
                              }), 64))
                            ])
                          ]),
                          _: 1
                        }, 8, ["modelValue", "class"]),
                        (fields.value.artificialLight == true)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              class: _normalizeClass(["col-span-4 border border-gray-100 border-solid p-1 pt-3 pb-0 -mt-3 bg-gray-50", { 'error-form-field': __props.errorsFields.includes('hsLight') && __props.formSubmited }])
                            }, [
                              _cache[85] || (_cache[85] = _createElementVNode("label", null, "Light period (hours per day) *", -1)),
                              _withDirectives(_createElementVNode("input", {
                                class: "w-full",
                                type: "number",
                                "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_unref(formFields)['hsLight'].value) = $event))
                              }, null, 512), [
                                [_vModelText, _unref(formFields)['hsLight'].value]
                              ])
                            ], 2))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
              _createVNode(_unref(RadioGroup), {
                modelValue: _unref(formFields)['heatCoolSystem'].value,
                "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_unref(formFields)['heatCoolSystem'].value) = $event)),
                class: _normalizeClass(["col-span-4 mb-0 mt-1", { 'error-form-field': __props.errorsFields.includes('heatCoolSystem') && __props.formSubmited }])
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(RadioGroupLabel), { class: "mb-1" }, {
                    default: _withCtx(() => _cache[86] || (_cache[86] = [
                      _createTextVNode("Are you going to use cooling/heating systems? *")
                    ])),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_42, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(yesNos, (yesNo) => {
                      return _createVNode(_unref(RadioGroupOption), {
                        as: "template",
                        key: yesNo.value,
                        value: yesNo.key
                      }, {
                        default: _withCtx(({ active, checked }) => [
                          _createElementVNode("div", {
                            class: _normalizeClass([[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white '], "relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"])
                          }, [
                            _createElementVNode("div", _hoisted_43, [
                              _createElementVNode("div", _hoisted_44, [
                                _createElementVNode("div", _hoisted_45, [
                                  _createVNode(_unref(RadioGroupLabel), {
                                    as: "p",
                                    class: _normalizeClass([checked ? 'text-white' : 'text-gray-500', "font-base"])
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(yesNo.value), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["class"])
                                ])
                              ]),
                              _withDirectives(_createElementVNode("div", _hoisted_46, _cache[87] || (_cache[87] = [
                                _createElementVNode("svg", {
                                  class: "h-5 w-5",
                                  viewBox: "0 0 24 24",
                                  fill: "none"
                                }, [
                                  _createElementVNode("circle", {
                                    cx: "12",
                                    cy: "12",
                                    r: "12",
                                    fill: "#fff",
                                    "fill-opacity": "0.2"
                                  }),
                                  _createElementVNode("path", {
                                    d: "M7 13l3 3 7-7",
                                    stroke: "#fff",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  })
                                ], -1)
                              ]), 512), [
                                [_vShow, checked]
                              ])
                            ])
                          ], 2)
                        ]),
                        _: 2
                      }, 1032, ["value"])
                    }), 64))
                  ])
                ]),
                _: 1
              }, 8, ["modelValue", "class"]),
              (fields.value.heatCoolSystem == true)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 9,
                    class: _normalizeClass(["col-span-4 border border-gray-100 border-solid p-1 pt-3 pb-0 -mt-3 bg-gray-50", { 'error-form-field': __props.errorsFields.includes('hsHeatCool') && __props.formSubmited }])
                  }, [
                    _cache[88] || (_cache[88] = _createElementVNode("label", null, "Cooling/heating period (hours per day) *", -1)),
                    _withDirectives(_createElementVNode("input", {
                      class: "w-full",
                      type: "number",
                      "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_unref(formFields)['hsHeatCool'].value) = $event))
                    }, null, 512), [
                      [_vModelText, _unref(formFields)['hsHeatCool'].value]
                    ])
                  ], 2))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('operatingDays') && __props.formSubmited }])
              }, [
                _cache[89] || (_cache[89] = _createElementVNode("label", null, "Operating days (in one year) *", -1)),
                _withDirectives(_createElementVNode("input", {
                  class: "w-full",
                  type: "number",
                  "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_unref(formFields)['operatingDays'].value) = $event))
                }, null, 512), [
                  [_vModelText, _unref(formFields)['operatingDays'].value]
                ])
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('cycleLength') && __props.formSubmited }])
              }, [
                _cache[90] || (_cache[90] = _createElementVNode("label", null, "Cycle duration (days) *", -1)),
                _withDirectives(_createElementVNode("input", {
                  class: "w-full",
                  type: "number",
                  "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_unref(formFields)['cycleLength'].value) = $event))
                }, null, 512), [
                  [_vModelText, _unref(formFields)['cycleLength'].value]
                ])
              ], 2),
              _createVNode(_unref(RadioGroup), {
                modelValue: _unref(formFields)['suppliments'].value,
                "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_unref(formFields)['suppliments'].value) = $event)),
                class: _normalizeClass(["col-span-4 mb-0", { 'error-form-field': __props.errorsFields.includes('suppliments') && __props.formSubmited }])
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(RadioGroupLabel), { class: "mb-1" }, {
                    default: _withCtx(() => _cache[91] || (_cache[91] = [
                      _createTextVNode("How many specific supplements are you going to use (i.e. vitamins)? *")
                    ])),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_47, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(suppliments, (suppliment) => {
                      return _createVNode(_unref(RadioGroupOption), {
                        as: "template",
                        key: suppliment,
                        value: suppliment
                      }, {
                        default: _withCtx(({ active, checked }) => [
                          _createElementVNode("div", {
                            class: _normalizeClass([[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white '], "relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"])
                          }, [
                            _createElementVNode("div", _hoisted_48, [
                              _createElementVNode("div", _hoisted_49, [
                                _createElementVNode("div", _hoisted_50, [
                                  _createVNode(_unref(RadioGroupLabel), {
                                    as: "p",
                                    class: _normalizeClass([checked ? 'text-white' : 'text-gray-500', "font-base"])
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(suppliment), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["class"])
                                ])
                              ]),
                              _withDirectives(_createElementVNode("div", _hoisted_51, _cache[92] || (_cache[92] = [
                                _createElementVNode("svg", {
                                  class: "h-5 w-5",
                                  viewBox: "0 0 24 24",
                                  fill: "none"
                                }, [
                                  _createElementVNode("circle", {
                                    cx: "12",
                                    cy: "12",
                                    r: "12",
                                    fill: "#fff",
                                    "fill-opacity": "0.2"
                                  }),
                                  _createElementVNode("path", {
                                    d: "M7 13l3 3 7-7",
                                    stroke: "#fff",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  })
                                ], -1)
                              ]), 512), [
                                [_vShow, checked]
                              ])
                            ])
                          ], 2)
                        ]),
                        _: 2
                      }, 1032, ["value"])
                    }), 64))
                  ])
                ]),
                _: 1
              }, 8, ["modelValue", "class"]),
              (showsupplimentX.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                    (showsupplimentX.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_53, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["col-span-1", { 'error-form-field': __props.errorsFields.includes('supplimentXConc') && __props.formSubmited }])
                          }, [
                            _cache[93] || (_cache[93] = _createElementVNode("label", null, "Concentration supplement x (kg/kg biomass or L/kg biomass) *", -1)),
                            _withDirectives(_createElementVNode("input", {
                              class: "w-full",
                              type: "number",
                              "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_unref(formFields)['supplimentXConc'].value) = $event))
                            }, null, 512), [
                              [_vModelText, _unref(formFields)['supplimentXConc'].value]
                            ])
                          ], 2),
                          _createElementVNode("div", {
                            class: _normalizeClass(["col-span-1", { 'error-form-field': __props.errorsFields.includes('supplimentXPrice') && __props.formSubmited }])
                          }, [
                            _cache[94] || (_cache[94] = _createElementVNode("label", null, "Price supplement x (€/kg or €/L) *", -1)),
                            _withDirectives(_createElementVNode("input", {
                              class: "w-full",
                              type: "number",
                              "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_unref(formFields)['supplimentXPrice'].value) = $event))
                            }, null, 512), [
                              [_vModelText, _unref(formFields)['supplimentXPrice'].value]
                            ])
                          ], 2)
                        ]))
                      : _createCommentVNode("", true),
                    (showsupplimentY.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["col-span-1", { 'error-form-field': __props.errorsFields.includes('supplimentYConc') && __props.formSubmited }])
                          }, [
                            _cache[95] || (_cache[95] = _createElementVNode("label", null, "Concentration supplement y (kg/kg biomass or L/kg biomass) *", -1)),
                            _withDirectives(_createElementVNode("input", {
                              class: "w-full",
                              type: "number",
                              "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_unref(formFields)['supplimentYConc'].value) = $event))
                            }, null, 512), [
                              [_vModelText, _unref(formFields)['supplimentYConc'].value]
                            ])
                          ], 2),
                          _createElementVNode("div", {
                            class: _normalizeClass(["col-span-1", { 'error-form-field': __props.errorsFields.includes('supplimentYPrice') && __props.formSubmited }])
                          }, [
                            _cache[96] || (_cache[96] = _createElementVNode("label", null, "Price supplement y (€/kg or €/L) *", -1)),
                            _withDirectives(_createElementVNode("input", {
                              class: "w-full",
                              type: "number",
                              "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_unref(formFields)['supplimentYPrice'].value) = $event))
                            }, null, 512), [
                              [_vModelText, _unref(formFields)['supplimentYPrice'].value]
                            ])
                          ], 2)
                        ]))
                      : _createCommentVNode("", true),
                    (showsupplimentZ.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["col-span-1", { 'error-form-field': __props.errorsFields.includes('supplimentZConc') && __props.formSubmited }])
                          }, [
                            _cache[97] || (_cache[97] = _createElementVNode("label", null, "Concentration supplement z (kg/kg biomass or L/kg biomass) *", -1)),
                            _withDirectives(_createElementVNode("input", {
                              class: "w-full",
                              type: "number",
                              "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_unref(formFields)['supplimentZConc'].value) = $event))
                            }, null, 512), [
                              [_vModelText, _unref(formFields)['supplimentZConc'].value]
                            ])
                          ], 2),
                          _createElementVNode("div", {
                            class: _normalizeClass(["col-span-1", { 'error-form-field': __props.errorsFields.includes('supplimentZPrice') && __props.formSubmited }])
                          }, [
                            _cache[98] || (_cache[98] = _createElementVNode("label", null, "Price supplement z (€/kg or €/L) *", -1)),
                            _withDirectives(_createElementVNode("input", {
                              class: "w-full",
                              type: "number",
                              "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_unref(formFields)['supplimentZPrice'].value) = $event))
                            }, null, 512), [
                              [_vModelText, _unref(formFields)['supplimentZPrice'].value]
                            ])
                          ], 2)
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _cache[120] || (_cache[120] = _createElementVNode("div", { class: "form-separator col-span-4" }, [
                _createElementVNode("p", { class: "form-subtitle" }, "Harvesting")
              ], -1)),
              (!isSystemGreenhouse.value && !isSystemSSF.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_56, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('harvestingConc') && __props.formSubmited }])
                    }, [
                      _cache[99] || (_cache[99] = _createElementVNode("label", null, "Culture concentration for harvesting (kg/m3 or g/L) *", -1)),
                      _withDirectives(_createElementVNode("input", {
                        class: "w-full",
                        type: "number",
                        "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_unref(formFields)['harvestingConc'].value) = $event))
                      }, null, 512), [
                        [_vModelText, _unref(formFields)['harvestingConc'].value]
                      ])
                    ], 2),
                    _createElementVNode("div", {
                      class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('harvestingPct') && __props.formSubmited }])
                    }, [
                      _cache[100] || (_cache[100] = _createElementVNode("label", null, "Harvesting (%) *", -1)),
                      _withDirectives(_createElementVNode("input", {
                        class: "w-full",
                        type: "number",
                        "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => ((_unref(formFields)['harvestingPct'].value) = $event))
                      }, null, 512), [
                        [_vModelText, _unref(formFields)['harvestingPct'].value]
                      ])
                    ], 2)
                  ]))
                : (isSystemGreenhouse.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('harvestingConc') && __props.formSubmited }])
                      }, [
                        _cache[101] || (_cache[101] = _createElementVNode("label", null, "Plant aerial biomass at harvesting (kg/plant) *", -1)),
                        _withDirectives(_createElementVNode("input", {
                          class: "w-full",
                          type: "number",
                          "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => ((_unref(formFields)['harvestingConc'].value) = $event))
                        }, null, 512), [
                          [_vModelText, _unref(formFields)['harvestingConc'].value]
                        ])
                      ], 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('harvestingPct') && __props.formSubmited }])
                      }, [
                        _cache[102] || (_cache[102] = _createElementVNode("label", null, "Germination efficiency (%) *", -1)),
                        _withDirectives(_createElementVNode("input", {
                          class: "w-full",
                          type: "number",
                          "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => ((_unref(formFields)['harvestingPct'].value) = $event))
                        }, null, 512), [
                          [_vModelText, _unref(formFields)['harvestingPct'].value]
                        ])
                      ], 2)
                    ]))
                  : (isSystemSSF.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_58, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('harvestingConc') && __props.formSubmited }])
                        }, [
                          _cache[104] || (_cache[104] = _createElementVNode("label", null, "Substrate occupancy (kg/m3) *", -1)),
                          _withDirectives(_createElementVNode("input", {
                            class: "w-full",
                            type: "number",
                            "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => ((_unref(formFields)['harvestingConc'].value) = $event))
                          }, null, 512), [
                            [_vModelText, _unref(formFields)['harvestingConc'].value]
                          ]),
                          _createElementVNode("span", _hoisted_59, [
                            _createVNode(_unref(LightBulbIcon), { class: "icon icon-small inline-block" }),
                            _cache[103] || (_cache[103] = _createTextVNode(" If you have no idea 400 kg/m3 is a good estimation!"))
                          ])
                        ], 2)
                      ]))
                    : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('facilityUsagePct') && __props.formSubmited }])
              }, [
                _cache[105] || (_cache[105] = _createElementVNode("label", null, "Facility usage (%) *", -1)),
                _withDirectives(_createElementVNode("input", {
                  class: "w-full",
                  type: "number",
                  "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => ((_unref(formFields)['facilityUsagePct'].value) = $event))
                }, null, 512), [
                  [_vModelText, _unref(formFields)['facilityUsagePct'].value]
                ])
              ], 2),
              (showSecondInfo.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_60, [
                    (!isSystemGreenhouse.value)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_61, [
                          _createVNode(_unref(NewspaperIcon), { class: "icon icon-small inline-block" }),
                          _cache[106] || (_cache[106] = _createTextVNode(" You will need ")),
                          _createElementVNode("b", null, _toDisplayString(calcVolumeRealPerCycle.value.volume_real_per_cyc), 1),
                          _cache[107] || (_cache[107] = _createTextVNode(" m3 per cycle, and considering the harvesting and facility usage %s you will need ")),
                          _createElementVNode("b", null, _toDisplayString(calcVolumeRealPerCycle.value.towrite[0]), 1),
                          _createTextVNode(" " + _toDisplayString(calcVolumeRealPerCycle.value.towrite[1]) + " " + _toDisplayString(selectedCultivationSystem.value) + " and ", 1),
                          _createElementVNode("b", null, _toDisplayString(calcVolumeRealPerCycle.value.towrite2[0]), 1),
                          _createTextVNode(" " + _toDisplayString(calcVolumeRealPerCycle.value.towrite2[1]), 1)
                        ]))
                      : (_openBlock(), _createElementBlock("p", _hoisted_62, [
                          _createVNode(_unref(NewspaperIcon), { class: "icon icon-small inline-block" }),
                          _cache[108] || (_cache[108] = _createTextVNode(" Considering your target compound production you will need ")),
                          _createElementVNode("b", null, _toDisplayString(calcVolumeRealPerCycle.value.towrite2[0]), 1),
                          _createTextVNode(" " + _toDisplayString(calcVolumeRealPerCycle.value.towrite2[1]), 1)
                        ]))
                  ]))
                : _createCommentVNode("", true),
              (!isSystemSSF.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_63, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('harvestMethod') && __props.formSubmited }])
                    }, [
                      _cache[110] || (_cache[110] = _createElementVNode("label", null, "Which harvesting method are you going to use? *", -1)),
                      _withDirectives(_createElementVNode("select", {
                        class: "w-full",
                        "onUpdate:modelValue": _cache[42] || (_cache[42] = ($event: any) => ((_unref(formFields)['harvestMethod'].value) = $event))
                      }, [
                        _cache[109] || (_cache[109] = _createElementVNode("option", {
                          selected: "",
                          value: null
                        }, null, -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.formInizializationValues['harvestingMethodsOptions'], (harvestMethod) => {
                          return (_openBlock(), _createElementBlock("option", {
                            value: Number(harvestMethod.id),
                            key: harvestMethod.id
                          }, _toDisplayString(harvestMethod.name), 9, _hoisted_64))
                        }), 128))
                      ], 512), [
                        [_vModelSelect, _unref(formFields)['harvestMethod'].value]
                      ])
                    ], 2),
                    (shmGoodCombo.value == false)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_65, [
                          _createVNode(_unref(ExclamationTriangleIcon), { class: "icon icon-small inline-block red" }),
                          _cache[111] || (_cache[111] = _createTextVNode(" The organism you selected cannot be harvested with the harvesting method you selected. Please choose a more suitable one. "))
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_66, [
                          (isHarvestingMethodFlocculation.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_67, [
                                _createVNode(_unref(RadioGroup), {
                                  modelValue: _unref(formFields)['flocculant'].value,
                                  "onUpdate:modelValue": _cache[43] || (_cache[43] = ($event: any) => ((_unref(formFields)['flocculant'].value) = $event)),
                                  class: _normalizeClass(["col-span-4 mb-0", { 'error-form-field': __props.errorsFields.includes('flocculant') && __props.formSubmited }])
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(RadioGroupLabel), { class: "mb-1" }, {
                                      default: _withCtx(() => _cache[112] || (_cache[112] = [
                                        _createTextVNode("Which flocculant are you going to use? *")
                                      ])),
                                      _: 1
                                    }),
                                    _createElementVNode("div", _hoisted_68, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.formInizializationValues['flocculantsOptions'], (flocculant) => {
                                        return (_openBlock(), _createBlock(_unref(RadioGroupOption), {
                                          as: "template",
                                          key: flocculant.id,
                                          value: flocculant.id
                                        }, {
                                          default: _withCtx(({ active, checked }) => [
                                            _createElementVNode("div", {
                                              class: _normalizeClass([[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white '], "relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"])
                                            }, [
                                              _createElementVNode("div", _hoisted_69, [
                                                _createElementVNode("div", _hoisted_70, [
                                                  _createElementVNode("div", _hoisted_71, [
                                                    _createVNode(_unref(RadioGroupLabel), {
                                                      as: "p",
                                                      class: _normalizeClass([checked ? 'text-white' : 'text-gray-500', "font-base"])
                                                    }, {
                                                      default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(flocculant.name), 1)
                                                      ]),
                                                      _: 2
                                                    }, 1032, ["class"])
                                                  ])
                                                ]),
                                                _withDirectives(_createElementVNode("div", _hoisted_72, _cache[113] || (_cache[113] = [
                                                  _createElementVNode("svg", {
                                                    class: "h-5 w-5",
                                                    viewBox: "0 0 24 24",
                                                    fill: "none"
                                                  }, [
                                                    _createElementVNode("circle", {
                                                      cx: "12",
                                                      cy: "12",
                                                      r: "12",
                                                      fill: "#fff",
                                                      "fill-opacity": "0.2"
                                                    }),
                                                    _createElementVNode("path", {
                                                      d: "M7 13l3 3 7-7",
                                                      stroke: "#fff",
                                                      "stroke-width": "1.5",
                                                      "stroke-linecap": "round",
                                                      "stroke-linejoin": "round"
                                                    })
                                                  ], -1)
                                                ]), 512), [
                                                  [_vShow, checked]
                                                ])
                                              ])
                                            ], 2)
                                          ]),
                                          _: 2
                                        }, 1032, ["value"]))
                                      }), 128))
                                    ])
                                  ]),
                                  _: 1
                                }, 8, ["modelValue", "class"]),
                                (fields.value.flocculant !== null)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_73, [
                                      (isFlocculantAluminium.value)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_74, [
                                            _createElementVNode("p", _hoisted_75, [
                                              _createVNode(_unref(LightBulbIcon), { class: "icon icon-small inline-block" }),
                                              _cache[114] || (_cache[114] = _createTextVNode(" Aluminium is used at a concentration of 0.27 kg/kg biomass and has the price of 1.5 €/kg. If these references do not correspond please select \"Another flocculant\" "))
                                            ])
                                          ]))
                                        : (isFlocculantChitosan.value)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_76, [
                                              _createElementVNode("p", _hoisted_77, [
                                                _createVNode(_unref(LightBulbIcon), { class: "icon icon-small inline-block" }),
                                                _cache[115] || (_cache[115] = _createTextVNode(" Chitosan is used at a concentration of 0.18 kg/kg biomass and has the price of 40 €/kg. If these references do not correspond please select \"Another flocculant\" "))
                                              ])
                                            ]))
                                          : (isFlocculantAnotherFlocculant.value)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_78, [
                                                _createElementVNode("div", {
                                                  class: _normalizeClass(["col-span-1", { 'error-form-field': __props.errorsFields.includes('otherFlocculantConc') && __props.formSubmited }])
                                                }, [
                                                  _cache[116] || (_cache[116] = _createElementVNode("label", null, "Concentration flocculant (kg/kg biomass) *", -1)),
                                                  _withDirectives(_createElementVNode("input", {
                                                    class: "w-full",
                                                    type: "number",
                                                    "onUpdate:modelValue": _cache[44] || (_cache[44] = ($event: any) => ((_unref(formFields)['otherFlocculantConc'].value) = $event))
                                                  }, null, 512), [
                                                    [_vModelText, _unref(formFields)['otherFlocculantConc'].value]
                                                  ])
                                                ], 2),
                                                _createElementVNode("div", {
                                                  class: _normalizeClass(["col-span-1", { 'error-form-field': __props.errorsFields.includes('otherFlocculantPrice') && __props.formSubmited }])
                                                }, [
                                                  _cache[117] || (_cache[117] = _createElementVNode("label", null, "Price flocculant (€/kg) *", -1)),
                                                  _withDirectives(_createElementVNode("input", {
                                                    class: "w-full",
                                                    type: "number",
                                                    "onUpdate:modelValue": _cache[45] || (_cache[45] = ($event: any) => ((_unref(formFields)['otherFlocculantPrice'].value) = $event))
                                                  }, null, 512), [
                                                    [_vModelText, _unref(formFields)['otherFlocculantPrice'].value]
                                                  ])
                                                ], 2)
                                              ]))
                                            : _createCommentVNode("", true)
                                    ]))
                                  : _createCommentVNode("", true)
                              ]))
                            : _createCommentVNode("", true),
                          (isHarvestingMethodFlocculation.value || isHarvestingMethodCFF.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_79, [
                                _createElementVNode("div", {
                                  class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('concFactorHarvesting') && __props.formSubmited }])
                                }, [
                                  _cache[119] || (_cache[119] = _createElementVNode("label", null, "Concentration factor harvesting *", -1)),
                                  _withDirectives(_createElementVNode("input", {
                                    class: "w-full",
                                    type: "number",
                                    "onUpdate:modelValue": _cache[46] || (_cache[46] = ($event: any) => ((_unref(formFields)['concFactorHarvesting'].value) = $event))
                                  }, null, 512), [
                                    [_vModelText, _unref(formFields)['concFactorHarvesting'].value]
                                  ]),
                                  _createElementVNode("span", _hoisted_80, [
                                    _createVNode(_unref(LightBulbIcon), { class: "icon icon-small inline-block" }),
                                    _cache[118] || (_cache[118] = _createTextVNode(" As concentraction factor 80 is a good estimation!"))
                                  ])
                                ], 2)
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
    ])
  ]))
}
}

})