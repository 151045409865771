<template>
    <div class="programs-sidebar" :class="{ 'closed': isFilterClosed }">
        <div class="filter-container">
            <div class="flex justify-between" @click="filterToggle">
                <div class="flex items-center">
                    <AdjustmentsHorizontalIcon class="icon icon-x-small mr-2" />
                    <span v-if="!isFilterClosed" class="font-medium text-base">Filters</span>
                </div>
                <ChevronLeftIcon v-if="!isFilterClosed" class="icon icon-x-small black" />
            </div>
            <div class="filter-values" v-if="!isFilterClosed">
                <div class="filter-title">
                    <p>Programmes</p>
                    <p v-if="checkedPrograms.length" class="clear" @click="clearFilter">Clear all</p>
                </div>
                <template v-if="filteredPrograms.length">
                    <div v-for="program in filteredPrograms" :key="program.id" class="filter-option">
                        <input type="checkbox" :id="program.name" :value="program.id" v-model.number="checkedPrograms"
                            @change="emits('filteredProgramsChange', checkedPrograms)">
                        <label :for="program.name">{{ program.name }}</label>
                    </div>
                </template>
            </div>
        </div>

    </div>
</template>
<script setup lang="ts">
import { ChevronLeftIcon, AdjustmentsHorizontalIcon } from '@heroicons/vue/20/solid';
import { defineProps, defineEmits, ref, computed } from 'vue';
import { ProgramSimpleInterface } from '@/models/programsInterface';

const props = defineProps<{
    programList: ProgramSimpleInterface[];
    isFilterClosed: boolean;
}>();
const emits = defineEmits(['filteredProgramsChange', 'filterToggle'])

const filteredPrograms = computed(() => props.programList.filter(program => ['Ferrari', 'BMW', 'Ford'].includes(program.name)))
const checkedPrograms = ref([])

const filterToggle = () => {
    emits('filterToggle');
}

const clearFilter = () => {
    checkedPrograms.value = [];
    emits('filteredProgramsChange', checkedPrograms.value)
}

</script>
<style lang="scss" scoped>
.programs-sidebar {
    width: 20%;
    padding-right: 1rem;
    min-height: 80vh;
    border-right: 1px solid #ddd;
    position: sticky;
    top: 0;
    max-height: 90vh;
    overflow: scroll;
    transition: width 0.3s ease;
    &.closed {
        width: 3%;
    }
}


.filter-values {
    display: flex;
    flex-direction: column;

    .filter-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.8rem;
        color: $font-color-super-light;
        margin-bottom: 1rem;
        margin-top: 2rem;

        .clear {
            color: $one-five-blue;
            cursor: pointer;
        }
    }

    .filter-option {
        margin-bottom: 0.5rem;
        font-size: 1rem;

        input {
            width: 14px;
            height: 14px;
            border-radius: 5px;
            margin-right: 1rem;

            &:checked {
                background-image: url("@/assets/icons/black-check-box.svg");
            }
        }

        label {
            display: inline-flex;
            font-size: 0.85rem;
            cursor: pointer;
        }
    }
}
</style>