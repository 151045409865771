import { useMutation } from "@vue/apollo-composable";
import { provideApolloClient } from "@vue/apollo-composable";
import apolloClient from "@/utils/apolloClient";
import { POSITION, useToast } from "vue-toastification";
import { NewProductBriefInterface } from "@/models/productBriefsInterface";
import { PRODUCT_BRIEF_CREATE } from "@/grapql/productBriefsCreateMutation";

provideApolloClient(apolloClient);
const toast = useToast();

const getDefaultState = () => {
  return {
    newProductBrief: {
      client: null,
      product: null,
      objective: null,
      markets: [],
    },
    errorDuringMutation: {
      newProductBrief: null,
    },
  };
};
const state = getDefaultState();

const mutations = {
  setSingleProductBriefValue(
    state,
    payload: { productBriefsField: string; value: Date | string | null | [] }
  ) {
    state.newProductBrief[payload.productBriefsField] = payload.value;
  },
  clearErrors(state, payload: { type: string }) {
    state.errorDuringMutation[payload.type] = null;
  },
  setErrorOnCreationResponse(state, payload: { error: string; type: string }) {
    if (payload.error.includes("already exists")) {
      state.errorDuringMutation[payload.type] =
        "Ops! what you are trying to create already exists";
    } else {
      state.errorDuringMutation[payload.type] = payload.error;
    }
  },
  resetProductBriefsValues(state) {
    state.newProductBrief = getDefaultState().newProductBrief;
  },
};

// CREATE NEW PRODUCT BRIEF
const actions = {
  productBriefCreate({ state, commit }) {
    return new Promise<void>((resolve, reject) => {
      const {
        mutate: productBriefCreate,
        onDone,
        onError,
      } = useMutation(PRODUCT_BRIEF_CREATE, {
        awaitRefetchQueries: true,
        refetchQueries: ["ProductBriefs"]
      });
      const productBriefInput: NewProductBriefInterface = Object.assign(
        {},
        state.newProductBrief
      );
      productBriefCreate({ input: productBriefInput });
      onDone(async () => {
        await commit("clearErrors", { type: "newProductBrief" });
        toast.success(`New product brief created`, {
          position: POSITION.BOTTOM_LEFT,
          timeout: 1524,
        });
        resolve();
      });
      onError(async (error) => {
        commit("setErrorOnCreationResponse", {
          error: error.message,
          type: "newProductBrief",
        });
        reject();
      });
    });
  },
};

const getters = {
  productBriefsValues() {
    return state.newProductBrief;
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
