export const RUN_TABLE_COLUMS = [
  { label: "ID", field: "id", hidden: true },
  { label: "Species", field: "species", thClass:"sticky-col first-col", tdClass:"sticky-col first-col"},
  { label: "Compound", field: "compound" },
  { label: "Country", field: "place.name" },
  { label: "Land", field: "landCapex" },
  { label: "Residual Biomass", field: "residualBiomass" },
  { label: "Extraction Method", field: "extractionMethod.name" },
  { label: "Target Compound", field: "targetCompoundCapacity" },
  { label: "Biomass Capacity", field: "biomass" },
  { label: "Biomass Cost", field: "cost_biomass" },
  { label: "Operating Days", field: "operatingDays" },
  { label: "Concentration Extraction Buffer", field: "extractionBufferConc" },
  { label: "Biomass Price", field: "targetCompoundPrice" },
  { label: "Bycompound x", field: "bycompoundXPrice" },
  { label: "MEC", field: "finalMec" },
  { label: "CapEx", field: "finalCapex" },
  { label: "OpEx", field: "finalOpex" },
  { label: "Created At", field: "createdAt" },
  { label: "Created By", field: "createdBy.fullName", sortable: true },
];

export const RUN = {
  key: "RUN",
  graphqlValue: "run",
  urlValue: "run",
  displayValue: "Run",
};

export const NEW_RUN_FIELDS = {
  graphqlValue: "fields",
  formFields: {
    place: { required: true },
    species: { required: true },
    compound: { required: true },
    residualBiomass: {
      required: true,
      valueValidation: {
        type: "array",
        values: [true, false],
      },
      dependants: [
        {
          ifValue: false,
          fields: [
            "organism",
            "cultivationSystem",
            "land",
            "heatCoolSystem",
            "cycleLength",
            "suppliments",
            "harvestingConc",
            "facilityUsagePct",
          ],
        },
        {
          ifValue: true,
          fields: ["biomassTarget", "costBiomass"],
        },
        {
          ifValue: false,
          fields: ["harvestingPct", "harvestMethod"],
          not: [
            {
              ifField: "cultivationSystem",
              ifValue: "Solid-state fermentor (SSF)",
              ifValueType: "option",
              ifValueReference: "cultivationSystemsOptions",
            },
          ],
        },
      ],
      onChangeResetFields: [
        "organism",
        "cultivationSystem",
        "land",
        "heatCoolSystem",
        "cycleLength",
        "suppliments",
        "harvestingConc",
        "facilityUsagePct",
        "biomassTarget",
        "costBiomass",
        "harvestingPct",
        "harvestMethod",
        "artificialLight",
        "hsLight",
        "hsHeatCool",
        "sugarConc",
        "targetCompoundCapacity",
        "biomass",
        "isOtherSugarPrice",
        "flocculant",

      ],
    },
    organism: {
      dependants: [
        {
          ifValue: "Autotroph",
          ifValueType: "option",
          ifValueReference: "organismsOptions",
          fields: ["artificialLight"],
        },
        {
          ifValue: "Heterotroph",
          ifValueType: "option",
          ifValueReference: "organismsOptions",
          fields: ["sugarConc"],
          not: [
            {
              ifField: "cultivationSystem",
              ifValue: "Solid-state fermentor (SSF)",
              ifValueType: "option",
              ifValueReference: "cultivationSystemsOptions",
            },
          ],
        },
        {
          ifValue: "Mixotroph",
          ifValueType: "option",
          ifValueReference: "organismsOptions",
          fields: ["sugarConc", "artificialLight"],
        },
      ],
      onChangeResetFields: ["artificialLight","sugarConc"]
    },
    cultivationSystem: {
      dependants: [
        {
          ifValue: "Solid-state fermentor (SSF)",
          ifValueType: "option",
          ifValueReference: "cultivationSystemsOptions",
          fields: ["biomassTarget", "costBiomass"],
        },
      ],
    },
    compoundPct: {
      required: true,
      valueValidation: {
        type: "range",
        values: [0.01, 100],
      },
    },
    biomassTarget: {
      valueValidation: {
        type: "array",
        values: ["Target compound", "Substrate", "Biomass", null],
      },
      dependants: [
        {
          ifValue: "Target compound",
          fields: ["targetCompoundCapacity"],
        },
        {
          ifValue: "Substrate",
          fields: ["biomass"],
        },
        {
          ifValue: "Biomass",
          fields: ["biomass"],
        },
        {
          ifValue: null,
          fields: ["targetCompoundCapacity"],
        },
      ],
    },
    targetCompoundCapacity: { required: false },
    biomass: { required: false },
    costBiomass: {
      required: false,
      valueValidation: {
        type: "min",
        values: 0,
      },
    },
    bycompounds: {
      required: true,
      valueValidation: {
        type: "range",
        values: [0, 3],
      },
      dependants: [
        {
          ifValue: 1,
          fields: ["bycompoundXPct"],
        },
        {
          ifValue: 2,
          fields: ["bycompoundXPct", "bycompoundYPct"],
        },
        {
          ifValue: 3,
          fields: ["bycompoundXPct", "bycompoundYPct", "bycompoundZPct"],
        },
      ],
    },
    bycompoundXPct: {
      valueValidation: {
        type: "range",
        values: [0, 100],
      },
    },
    bycompoundYPct: {
      valueValidation: {
        type: "range",
        values: [0, 100],
      },
    },
    bycompoundZPct: {
      valueValidation: {
        type: "range",
        values: [0, 100],
      },
    },
    landCapex: {
      required: true,
      valueValidation: {
        type: "array",
        values: ["Buying", "Renting"],
      },
    },
    land: {
      valueValidation: {
        type: "min",
        values: 1,
      },
    },
    artificialLight: {
      valueValidation: {
        type: "array",
        values: [true, false],
      },
      dependants: [
        {
          ifValue: true,
          fields: ["hsLight"],
        },
      ],
    },
    hsLight: {
      valueValidation: {
        type: "range",
        values: [0, 24],
      },
    },
    sugarConc: {},
    isOtherSugarPrice: {
      valueValidation: {
        type: "array",
        values: [true, false],
      },
      dependants: [
        {
          ifValue: true,
          fields: ["otherSugarPrice"],
        },
      ],
    },
    otherSugarPrice: { required: false },
    heatCoolSystem: {
      valueValidation: {
        type: "array",
        values: [true, false],
      },
      dependants: [
        {
          ifValue: true,
          fields: ["hsHeatCool"],
        },
      ],
    },
    hsHeatCool: {
      valueValidation: {
        type: "range",
        values: [0, 24],
      },
    },
    operatingDays: {
      required: true,
      valueValidation: {
        type: "range",
        values: [1, 365],
      },
    },
    cycleLength: {
      valueValidation: {
        type: "range",
        values: [1, 365],
      },
    },
    suppliments: {
      valueValidation: {
        type: "range",
        values: [0, 3],
      },
      dependants: [
        {
          ifValue: 1,
          fields: ["supplimentXConc", "supplimentXPrice"],
        },
        {
          ifValue: 2,
          fields: ["supplimentXConc", "supplimentXPrice", "supplimentYConc", "supplimentYPrice"],
        },
        {
          ifValue: 3,
          fields: ["supplimentXConc", "supplimentXPrice", "supplimentYConc", "supplimentYPrice", "supplimentZConc", "supplimentZPrice"],
        },
      ],
    },
    supplimentXConc: { required: false },
    supplimentXPrice: { required: false },
    supplimentYConc: { required: false },
    supplimentYPrice: { required: false },
    supplimentZConc: { required: false },
    supplimentZPrice: { required: false },
    harvestingConc: {
      valueValidation: {
        type: "min",
        values: 1,
      },
    },
    harvestingPct: {
      valueValidation: {
        type: "range",
        values: [1, 100],
      },
    },
    facilityUsagePct: {
      valueValidation: {
        type: "range",
        values: [1, 100],
      },
    },
    harvestMethod: {
      dependants: [
        {
          ifValue: "Flocculation",
          ifValueType: "option",
          ifValueReference: "harvestingMethodsOptions",
          fields: ["flocculant", "concFactorHarvesting"],
        },
        {
          ifValue: "Cross flow filtration",
          ifValueType: "option",
          ifValueReference: "harvestingMethodsOptions",
          fields: ["concFactorHarvesting"],
        },
      ],
      onChangeResetFields: ["flocculant","concFactorHarvesting"]
    },
    flocculant: {
      dependants: [
        {
          ifValue: "Another flocculant",
          ifValueType: "option",
          ifValueReference: "flocculantsOptions",
          fields: ["otherFlocculantConc", "otherFlocculantPrice"],
        },
      ],
    },
    otherFlocculantConc: { required: false },
    otherFlocculantPrice: { required: false },
    concFactorHarvesting: {
      valueValidation: {
        type: "range",
        values: [1, 100],
      },
    },
    extractionMethod: {
      required: true,
      dependants: [
        {
          ifValue: "Physical",
          ifValueType: "option",
          ifValueReference: "extractionMethodsOptions",
          fields: ["extractionBufferConc", "extractionBufferPrice"],
          is: [
            {
              ifField: "residualBiomass",
              ifValue: true,
            },
          ],
        },
        {
          ifValue: "Physical",
          ifValueType: "option",
          ifValueReference: "extractionMethodsOptions",
          fields: ["extractionBufferConc", "extractionBufferPrice"],
          is: [
            {
              ifField: "harvestMethod",
              ifValue: "Mechanical",
              ifValueType: "option",
              ifValueReference: "harvestingMethodsOptions",
            },
          ],
        },
        {
          ifValue: "Physical",
          ifValueType: "option",
          ifValueReference: "extractionMethodsOptions",
          fields: ["extractionBufferConc", "extractionBufferPrice"],
          is: [
            {
              ifField: "cultivationSystem",
              ifValue: "Solid-state fermentor (SSF)",
              ifValueType: "option",
              ifValueReference: "cultivationSystemsOptions",
            },
          ],
        },
        {
          ifValue: "Chemical",
          ifValueType: "option",
          ifValueReference: "extractionMethodsOptions",
          fields: ["solvent", "solventConc", "moreSolvents"],
        },
        {
          ifValue: "Both",
          ifValueType: "option",
          ifValueReference: "extractionMethodsOptions",
          fields: ["solvent", "solventConc", "extractionBuffer", "moreSolvents"],
        },
      ],
      onChangeResetFields: ["solvent","solventConc","otherSolventPrice","moreSolvents","moreSolventXConc","moreSolventXPrice","moreSolventYConc","moreSolventYPrice","extractionBuffer","extractionBufferConc","extractionBufferPrice"]
    },
    extractionBuffer: {
      valueValidation: {
        type: "array",
        values: [true, false],
      },
      dependants: [
        {
          ifValue: true,
          fields: ["extractionBufferConc", "extractionBufferPrice"],
        },
      ],
    },
    extractionBufferConc: { required: false },
    extractionBufferPrice: { required: false },
    solvent: {
      dependants: [
        {
          ifValue: "Another solvent",
          ifValueType: "option",
          ifValueReference: "solventsOptions",
          fields: ["otherSolventPrice"],
        },
      ],
    },
    solventConc: {},
    otherSolventPrice: {},
    moreSolvents: {
      valueValidation: {
        type: "range",
        values: [0, 2],
      },
      dependants: [
        {
          ifValue: 1,
          fields: ["moreSolventXConc", "moreSolventXPrice"],
        },
        {
          ifValue: 2,
          fields: ["moreSolventXConc", "moreSolventXPrice", "moreSolventYConc", "moreSolventYPrice"],
        },
      ],
      onChangeResetFields: ["moreSolventXConc","moreSolventXPrice","moreSolventYConc","moreSolventYPrice"]
    },
    moreSolventXConc: { required: false },
    moreSolventXPrice: { required: false },
    moreSolventYConc: { required: false },
    moreSolventYPrice: { required: false },
    dryMethod: { required: true },
    knowTargetCompoundPrice:{
      required:true,
      valueValidation: {
        type: "array",
        values: [true,false],
      },
      dependants: [
        {
          ifValue: true,
          fields: ["targetCompoundPrice"],
        },
        {
          ifValue:false,
          fields:['knowBycompoundsPrice'],
          not: [
            {
              ifField: "bycompounds",
              ifValue: 0,
            },
          ],
        },
        {
          ifValue: true,
          fields: ["bycompoundXPrice"],
          is: [
            {
              ifField: "bycompounds",
              ifValue: 1,
            },
          ],
        },
        {
          ifValue: true,
          fields: ["bycompoundXPrice","bycompoundYPrice"],
          is: [
            {
              ifField: "bycompounds",
              ifValue: 2,
            },
          ],
        },
        {
          ifValue: true,
          fields: ["bycompoundXPrice","bycompoundYPrice","bycompoundZPrice"],
          is: [
            {
              ifField: "bycompounds",
              ifValue: 3,
            },
          ],
        },
      ],
      onChangeResetFields:['knowBycompoundsPrice'],
    },
    targetCompoundPrice:{

    },
    knowBycompoundsPrice:{
      valueValidation:{
        type:'array',
        values:[true,false],
      },
      dependants: [
        {
          ifValue: true,
          fields: ["bycompoundXPrice"],
          is: [
            {
              ifField: "bycompounds",
              ifValue: 1,
            },
          ],
        },
        {
          ifValue: true,
          fields: ["bycompoundXPrice","bycompoundYPrice"],
          is: [
            {
              ifField: "bycompounds",
              ifValue: 2,
            },
          ],
        },
        {
          ifValue: true,
          fields: ["bycompoundXPrice","bycompoundYPrice","bycompoundZPrice"],
          is: [
            {
              ifField: "bycompounds",
              ifValue: 3,
            },
          ],
        },

      ],
    },
    bycompoundXPrice:{},
    bycompoundYPrice:{},
    bycompoundZPrice:{},
  },
  // mandatoryFormFields: ["place", "species", "compound"],
  fieldsToQuery: [
    "places",
    "organisms",
    "cultivationSystems",
    "ocsGoodCombos",
    "harvestingMethods",
    "shmGoodCombos",
    "flocculants",
    "extractionMethods",
    "solvents",
    "dryingMethods",
  ],
  createMutation: "runCreateModule/runCreate",
  calculateMutation: "runCreateModule/runCalculate",
};

export interface formFieldsOptionsInteface {
  id: number;
  name: string;
}
