import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tabs-container justify-between items-center flex mb-6 border-b"
}
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "ml-1"
}
const _hoisted_5 = {
  key: 0,
  class: "text-black py-2 text-sm font-medium uppercase"
}

import { TOTAL_ASSETS_BY_TYPE } from '@/grapql/assetsQuery';
import { ASSET_TYPES } from '@/models/technologyAssetConstants';
import { AssetTypeConstantInterface } from '@/models/technologyAssetsInterface';
import { useQuery } from '@vue/apollo-composable';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'TechnologyAssetsRepoTabs',
  setup(__props) {

const store = useStore();

const tabsOptions = ref([ASSET_TYPES.OTHER, ASSET_TYPES.PATENT, ASSET_TYPES.RESEARCH_PAPER, ASSET_TYPES.RESEARCH_PROJECT])
const selectedTab = computed( () => store.getters['assetsModule/getSelectedTab']);

const { result } = useQuery(TOTAL_ASSETS_BY_TYPE)
const totals = computed(() => result.value ?? null)

const changeSelection = (option: AssetTypeConstantInterface) => {
   store.commit('assetsModule/setTablePage', {page: 1})
   store.commit('assetsModule/updateTabSelection', {selectedTab: option})
   
}



return (_ctx: any,_cache: any) => {
  return (selectedTab.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabsOptions.value, (option) => {
            return (_openBlock(), _createElementBlock("div", {
              key: option.key,
              onClick: ($event: any) => (changeSelection(option)),
              class: _normalizeClass(["option px-3 py-2 text-sm mr-4 font-light relative", { 'selected': option == selectedTab.value }])
            }, [
              _createTextVNode(_toDisplayString(option.displayValue) + " ", 1),
              (totals.value)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, " (" + _toDisplayString(totals.value[option.allQueryName].totalCount) + ") ", 1))
                : _createCommentVNode("", true)
            ], 10, _hoisted_3))
          }), 128))
        ]),
        (totals.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, " Total Assets: " + _toDisplayString(totals.value.assets.totalCount), 1))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})