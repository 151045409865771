import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-padding" }

import ProgramsDashboard from '@/components/ProgramsDashboard.vue';
import ProgramsHeader from '@/components/ProgramsHeader.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramsView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(ProgramsHeader),
    _createVNode(ProgramsDashboard, { "is-mockup": false })
  ]))
}
}

})