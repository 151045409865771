<template>
  <div class="col-span-4">
    <div class="grid grid-cols-4 gap-y-4 gap-x-4">

      <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('knowTargetCompoundPrice') && formSubmited }">
        <label>Do you know the price you need to sell target compound? *</label>
        <select class="w-full" v-model="formFields['knowTargetCompoundPrice'].value">
          <option v-for="yesNo in knowTargetCompoundPrices" :value="yesNo.key" :key="yesNo.key">
            {{ yesNo.value }}
          </option>
        </select>
        <span class="text-xs text-gray-400">Note: NPV is calculated considering a tax rate of 20% and a discount rate of 15%</span>
      </div>

      <div
        v-if="fields.knowTargetCompoundPrice"
        class="col-span-4"
        :class="{ 'error-form-field': errorsFields.includes('targetCompoundPrice') && formSubmited }"
      >
        <label>Price target compound (€/kg) *</label>
        <input class="w-full" type="number" min="0.00" v-model="formFields['targetCompoundPrice'].value" />
      </div>

      <div v-if="showbycompoundsPrice" class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('knowBycompoundsPrice') && formSubmited }">
        <label>Do you know the price you need to sell bycompound(s)? *</label>
        <select class="w-full" v-model="formFields['knowBycompoundsPrice'].value">
          <option v-for="yesNo in yesNos" :value="yesNo.key" :key="yesNo.key">
            {{ yesNo.value }}
          </option>
        </select>
        <span v-if="!fields.knowBycompoundsPrice" class="text-xs text-black  rounded-md block bg-gray-100 p-4 mt-4">
          <InformationCircleIcon class="icon icon-small inline-block "/> Karen is doing its best to help you, but at this stage it's still a bit limited. It can only
          <b>suggest you the selling price of your main compound</b></span>
      </div>

      <div v-if="showbycompounds" class="col-span-4">
        <div v-if="showbycompoundX" class="mb-4" :class="{ 'error-form-field': errorsFields.includes('bycompoundXPrice') && formSubmited }">
          <label>Price bycompound x (€/kg)</label>
          <input class="w-full" type="number" v-model="formFields['bycompoundXPrice'].value" />
        </div>

        <div v-if="showbycompoundY" class="mb-4" :class="{ 'error-form-field': errorsFields.includes('bycompoundYPrice') && formSubmited }">
          <label>Price bycompound y (€/kg)</label>
          <input class="w-full" type="number" v-model="formFields['bycompoundYPrice'].value" />
        </div>

        <div v-if="showbycompoundZ" class="mb-4" :class="{ 'error-form-field': errorsFields.includes('bycompoundZPrice') && formSubmited }">
          <label>Price bycompound z (€/kg)</label>
          <input class="w-full" type="number" v-model="formFields['bycompoundZPrice'].value" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, defineProps } from "vue";
import { useFormFieldsCreationNewRun } from "@/tea/composables/runs/useFormFieldsCreationNewRun";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { useStore } from "vuex";
import { NEW_RUN_FIELDS } from "@/tea/models/run";
import {InformationCircleIcon} from '@heroicons/vue/24/outline';
const yesNos = [
  { key: true, value: "Yes" },
  { key: false, value: "No" },
];
const knowTargetCompoundPrices = [
  { key: true, value: "Yes, I know the selling price" },
  { key: false, value: "No, I don't, Suggest me!" },
];

const store = useStore();

const props = defineProps(["errorsFields", "formSubmited", "formInizializationValues"]);

const fields = computed(() => store.getters["runCreateModule/fields"]);

const showbycompounds = computed(() => {
  const ktcp = fields.value.knowTargetCompoundPrice;
  const bc = Number(fields.value.bycompounds);
  const kbp = fields.value.knowBycompoundsPrice;
  if ((ktcp == true && bc > 0) || (ktcp == false && kbp == true)) {
    return true;
  }
  return false;
});

const showbycompoundsPrice = computed(() => {
  const ktcp = fields.value.knowTargetCompoundPrice;
  const bc = Number(fields.value.bycompounds);
  if (ktcp == false && bc > 0) {
    return true;
  }
  return false;
});

const showbycompoundX = computed(() => {
  if (showbycompounds.value == false) {
    return false;
  }
  const bc = Number(fields.value.bycompounds);
  if (props.formInizializationValues["organismsOptions"] && bc > 0) {
    return true;
  }
  return false;
});
const showbycompoundY = computed(() => {
  if (showbycompounds.value == false) {
    return false;
  }
  const bc = Number(fields.value.bycompounds);
  if (props.formInizializationValues["organismsOptions"] && bc > 1) {
    return true;
  }
  return false;
});
const showbycompoundZ = computed(() => {
  if (showbycompounds.value == false) {
    return false;
  }
  const bc = Number(fields.value.bycompounds);
  if (props.formInizializationValues["organismsOptions"] && bc == 3) {
    return true;
  }
  return false;
});

const getFormFields = Object.keys(NEW_RUN_FIELDS.formFields).map((key) => {
  const fieldConfig = NEW_RUN_FIELDS.formFields[key];
  return {
    [key]: Array.isArray(fieldConfig.onChangeResetFields) ? fieldConfig.onChangeResetFields : [],
  };
});
const formFieldOptions = {
  fields: getFormFields,
  mutation: "runCreateModule/SET_SINGLE_RUN_VALUE",
};
const formFields = useFormFieldsCreationNewRun(formFieldOptions);
</script>
