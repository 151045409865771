import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "title-menu-header mb-0" }
const _hoisted_2 = { class: "view-title" }
const _hoisted_3 = { class: "action-icons" }
const _hoisted_4 = {
  key: 2,
  class: "flex items-center"
}

import router from '@/router';
import { ArrowUturnLeftIcon, PencilIcon, TrashIcon, CheckIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { computed } from 'vue';
import { useStore } from 'vuex';
import AvatarsActivity from '@/components/AvatarsActivity.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssetDetailHeader',
  props: ['title', 'usersActivity'],
  setup(__props) {


const store = useStore();
const editModeOn = computed(() => store.getters["assetDetailModule/isAssetMainEditable"]);
const assetType = computed(() => store.getters["assetDetailModule/getAssetType"]);

const handleBack = () => {
  router.back();
  store.commit('assetDetailModule/setEditableMainOff'); router.push({path:'/technology-assets-repo'})};
const handleEdit = () => store.commit('assetDetailModule/setEditableOn', {sectionToMakeEditbale: 'main'});
// To defined - Store or remove asset
// const deleteAsset = () => console.log('delete');

const saveChanges = async () => await store.dispatch(`assetDetailModule/${assetType.value}UpdatePartial`, {type:'mainContent'});
const cancelChanges = () => store.commit('assetDetailModule/setEditableMainOff');


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(__props.title), 1),
      _createElementVNode("div", _hoisted_3, [
        (!editModeOn.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "round-action-icon",
              onClick: handleBack
            }, [
              _createVNode(_unref(ArrowUturnLeftIcon), { class: "icon icon-small" })
            ]))
          : _createCommentVNode("", true),
        (!editModeOn.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "round-action-icon",
              onClick: handleEdit
            }, [
              _createVNode(_unref(PencilIcon), { class: "icon icon-small" })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("button", {
                class: "header-action-button action",
                onClick: saveChanges
              }, [
                _createVNode(_unref(CheckIcon), { class: "icon icon-small" }),
                _cache[0] || (_cache[0] = _createTextVNode("Save changes"))
              ]),
              _createElementVNode("button", {
                class: "header-action-button cancel",
                onClick: cancelChanges
              }, [
                _createVNode(_unref(XMarkIcon), { class: "icon icon-small" }),
                _cache[1] || (_cache[1] = _createTextVNode("Cancel"))
              ])
            ]))
      ])
    ]),
    _createVNode(AvatarsActivity, {
      orientation: 'left',
      "users-activity": __props.usersActivity
    }, null, 8, ["users-activity"])
  ]))
}
}

})