<template>
    <div v-for="chat in chatsHistory" :key="chat.id">
        <AIChatHistoryItem  :chat="chat" />
    </div>
</template>
<script lang="ts" setup>
import {computed} from "vue";
import { useStore } from 'vuex';
import {v4 as uuid} from 'uuid';
import AIChatHistoryItem from "@/components/ai/AIChatHistoryItem.vue";
import { useQuery } from '@vue/apollo-composable';
import {CHATS_HISTORY} from "@/grapql/aiQuery";
import {useEdgesToChats} from "@/composables/aiComposables";

const store = useStore();

const chatsHistory = computed(()=> store.state.aiModule.chatsHistory);


const {onResult , error} = useQuery(CHATS_HISTORY);
if (error) {
console.log(error);
}
onResult(async queryResult => {
    if (queryResult.data && queryResult?.data?.chats) {
        store.commit('aiModule/setChatsHistory', useEdgesToChats(queryResult.data.chats.edges));
    }
});

</script>