import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "page-padding" }
const _hoisted_2 = {
  key: 1,
  class: "loading-gif"
}
const _hoisted_3 = { key: 2 }
const _hoisted_4 = {
  key: 0,
  class: "leyend"
}
const _hoisted_5 = {
  key: 1,
  class: "leyend"
}
const _hoisted_6 = { class: "polymers-list" }
const _hoisted_7 = {
  key: 0,
  class: "poly-name"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "capitalize" }
const _hoisted_10 = { class: "key" }
const _hoisted_11 = { class: "value" }
const _hoisted_12 = {
  key: 0,
  class: "value"
}

import TitleGoBackHeader from '@/components/TitleGoBackHeader.vue';
import PolymersSearch from '@/components/PolymersSearch.vue';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { ChevronUpIcon } from '@heroicons/vue/20/solid';
import { onMounted, ref } from 'vue';
import { app, credentials } from '@/mongo.client';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';


interface polymerPropertyInterface {
    [key:string] : {
        value: string,
        unit: string,
        reference?: string | unknown
    }
}
type polymer = {
    [key:string] : polymerPropertyInterface
}

interface formConditionInterface {
    condition: string;
    property: string;
    relation: string;
    value: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PolymersRepository',
  setup(__props) {


// import polymersData from '@/assets/jsonData/polymer_data.json';
const polymers = ref<polymer[]>(null);
const filteredPolymers = ref<polymer[]>(null);
const numberOfPolymers = ref<number>(null);
const propertiesList = ref<DropdownSearchableOptionInterface[]>(null);
const showHideFilter = ref(false);
const toogleFilter = () => showHideFilter.value = !showHideFilter.value;
const clearFilter = () => filteredPolymers.value = polymers.value


function isPolymerSiutable(value) {
    const polymerInternalValues:polymerPropertyInterface[] = Object.values(value);
    const polymerPropertiesNames = polymerInternalValues.map(poly => Object.keys(poly)).flat();
    const conditionsProperties = this.map(fc => fc.property);
    const doesThisPolymerHasSomeOfThePropertiesConditions = polymerPropertiesNames.some(propertyName => conditionsProperties.includes(propertyName));
    let isSuitable;
    if (!doesThisPolymerHasSomeOfThePropertiesConditions) {
        return false;
    }
    else {
        polymerInternalValues.map(po => {
            this.map(fc => { 
                if(fc.condition && fc.condition == 'and' && po[`${fc.property}`] && po[`${fc.property}`].value) {
                isSuitable = isSuitable && singleConditionEvaluation(po, fc);
                }
                if(fc.condition && fc.condition == 'or' && po[`${fc.property}`] && po[`${fc.property}`].value) {
                    isSuitable = isSuitable || singleConditionEvaluation(po, fc);
                }
                if(!fc.condition && po[`${fc.property}`] && po[`${fc.property}`].value) {
                    console.log(po[`${fc.property}`], ': ', po[`${fc.property}`].value)
                    isSuitable = singleConditionEvaluation(po, fc);
                }

            })
        })
        return isSuitable;
    }
    
}

const singleConditionEvaluation = (polymer:polymerPropertyInterface, filterCondition:formConditionInterface) => {
    let resultEvaluation = false
    if( polymer[`${filterCondition.property}`]) {
        switch(filterCondition.relation) {
            case 'includes': 
            resultEvaluation = polymer[`${filterCondition.property}`].value.toLowerCase().includes(filterCondition.value.toLowerCase());
                break;
            case 'Notincludes': 
            resultEvaluation = !polymer[`${filterCondition.property}`].value.toLowerCase().includes(filterCondition.value.toLowerCase());
                break;
            case '==': 
            resultEvaluation = polymer[`${filterCondition.property}`].value.toLowerCase() == filterCondition.value.toLowerCase();
                break;
            case '!==': 
            resultEvaluation = polymer[`${filterCondition.property}`].value.toLowerCase() != filterCondition.value.toLowerCase();
                break;
            case '>=': 
            resultEvaluation = polymer[`${filterCondition.property}`].value.toLowerCase() >= filterCondition.value.toLowerCase();
                break;
            case '<=': 
            resultEvaluation = polymer[`${filterCondition.property}`].value.toLowerCase() <= filterCondition.value.toLowerCase();
                break;
        }
    }
    return resultEvaluation;
}



const filterPolymer = (filterConditions:formConditionInterface[]) => {   
    filteredPolymers.value  = polymers.value.filter(isPolymerSiutable, filterConditions );
};

const getPropertiesList = () => { 
    const keysOfKeys = polymers?.value.map((polymer) => {
        const firstLevel = Object.values(polymer)
        return firstLevel.map(poly => Object.keys(poly)).flat()
    });
    const keysNoRepetition = [...new Set(keysOfKeys.flat())].sort();
    propertiesList.value = keysNoRepetition.reduce((o, key, index) => ([ ...o, { 'id': index, 'name': key}]), []);
}

const getPolymers = async () => {
    const user = await app.logIn(credentials);
    const listOfPolymers = user.functions.polymers();
    listOfPolymers.then((resp) => {
        polymers.value = resp;
        filteredPolymers.value = resp;
        numberOfPolymers.value = resp.length;
        getPropertiesList();
        
      });
}

onMounted(() => {
     getPolymers()
    
})



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(TitleGoBackHeader, {
      title: "polymers",
      "show-filter": true,
      onToogleFilter: _cache[0] || (_cache[0] = ($event: any) => (toogleFilter()))
    }),
    (showHideFilter.value && propertiesList.value)
      ? (_openBlock(), _createBlock(PolymersSearch, {
          key: 0,
          "properties-list": propertiesList.value,
          onApplyFilter: filterPolymer,
          onClearFilter: clearFilter
        }, null, 8, ["properties-list"]))
      : _createCommentVNode("", true),
    (!filteredPolymers.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (filteredPolymers.value.length == numberOfPolymers.value)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, "Showing " + _toDisplayString(numberOfPolymers.value) + " polymers", 1))
            : (_openBlock(), _createElementBlock("p", _hoisted_5, " Showing " + _toDisplayString(filteredPolymers.value.length) + " polymers out of " + _toDisplayString(numberOfPolymers.value), 1)),
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredPolymers.value, (polymer, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "polymer-card"
              }, [
                (polymer.general)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("p", null, _toDisplayString(polymer.general['Common name'].value), 1),
                      (polymer.general['Acronym'])
                        ? (_openBlock(), _createElementBlock("span", _hoisted_8, "(" + _toDisplayString(polymer.general['Acronym'].value) + ")", 1))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(polymer, (property, key, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "m-3"
                  }, [
                    (key != '_id')
                      ? (_openBlock(), _createBlock(_unref(Disclosure), {
                          key: 0,
                          "default-open": index === 1
                        }, {
                          default: _withCtx(({ open }) => [
                            _createVNode(_unref(DisclosureButton), {
                              "data-headlessui-state": open,
                              "aria-extended": true,
                              class: "property-collapse mb-3 flex w-full justify-between px-4 py-2 text-left text-sm focus:outline-none focus-visible:ring focus-visible:ring-opacity-75"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", _hoisted_9, _toDisplayString(key), 1),
                                _createVNode(_unref(ChevronUpIcon), {
                                  class: _normalizeClass([open ? 'rotate-180 transform' : '', "h-5 w-5"])
                                }, null, 8, ["class"])
                              ]),
                              _: 2
                            }, 1032, ["data-headlessui-state"]),
                            _createVNode(_unref(DisclosurePanel), { class: "px-4 mb-4" }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(property, (value, key, index) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: "mb-3",
                                    key: index
                                  }, [
                                    _createElementVNode("p", _hoisted_10, _toDisplayString(key), 1),
                                    _createElementVNode("p", _hoisted_11, _toDisplayString(value.value) + " " + _toDisplayString(value.unit), 1),
                                    (value.reference)
                                      ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(value.reference), 1))
                                      : _createCommentVNode("", true)
                                  ]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["default-open"]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]))
            }), 128))
          ])
        ]))
  ]))
}
}

})