import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "program-detail-card mb-4" }
const _hoisted_2 = { class: "card-text" }
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = ["src", "alt"]



export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramApplicationCard',
  props: ['description', 'application', 'productImg'],
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("p", { class: "cards-title" }, "description", -1)),
    _createElementVNode("p", _hoisted_2, _toDisplayString(__props.description), 1),
    _cache[1] || (_cache[1] = _createElementVNode("p", { class: "cards-title" }, "application", -1)),
    _createElementVNode("p", _hoisted_3, _toDisplayString(__props.application), 1),
    (__props.productImg !== '')
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: require(`@/assets/images/${__props.productImg}`),
          alt: __props.description,
          class: "product-image"
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}
}

})