<template>
  <div>
    <RunGroupDetailHeader :title="runGroupDetail.value.title" :users-activity="runGroupDetail.value.activity" @on-run-created="handleOnRunCreated" @on-run-search-text="handleOnRunSearchText" :runGroupId="runGroupId" />
    <AccordionList v-if="!editModeOn" :data-for-accordion-list="runGroupDetail.value.accordionList" />
    <AccordionListEditable v-else :data-for-accordion-list="runGroupDetail.value.accordionList" />
    <div>
      <RunRepo :runCreated="runCreated" :searchTerm="runSearchTerm" :runGroupId="runGroupId" />
    </div>
  </div>
</template>

<script setup lang="ts">
import RunGroupDetailHeader from "@/tea/components/rungroups/RunGroupDetailHeader.vue";
import { computed,defineProps,ref,defineEmits } from "vue";
import AccordionList from "@/tea/components/rungroups/AccordionList.vue";
import AccordionListEditable from "@/tea/components/rungroups/AccordionListEditable.vue";
import { useStore } from "vuex";
import RunRepo from "@/tea/components/runs/RunRepo.vue";

const store = useStore();
defineProps(["runGroupDetail","runGroupId"]);
defineEmits(["onRunSearchText"])
const update =computed(()=> store.getters["runGroupModule/update"]);
const editModeOn = computed(() => update.value.editable);

const runCreated=ref('')
const handleOnRunCreated=()=>{
  runCreated.value=Date.now().toString()
}

const runSearchTerm = ref('')
const handleOnRunSearchText=(value)=>{
  runSearchTerm.value= value;
}

</script>
