import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "avatars" }

 

export interface usersActivityInterface {
    title: string,
    date?: string,
    personName: string, 
    initials: string,
    color?: string,
    twoLines?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AvatarsActivity',
  props: {
    usersActivity: {},
    orientation: {}
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_ctx.usersActivity)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["avatars-activity", _ctx.orientation])
      }, [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.usersActivity, (user) => {
            return (_openBlock(), _createElementBlock("div", {
              key: user.initials,
              class: "initials-icon",
              style: _normalizeStyle({'background-color': user.color ? user.color : ''  })
            }, _toDisplayString(user.initials || 'A'), 5))
          }), 128))
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["text", [{'one': _ctx.usersActivity.length == 1},_ctx.orientation]])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.usersActivity, (activity) => {
            return (_openBlock(), _createElementBlock("p", {
              key: activity.title
            }, [
              _createTextVNode(_toDisplayString(activity.title) + " ", 1),
              _createElementVNode("span", {
                class: _normalizeClass({'block': activity.twoLines})
              }, _toDisplayString(activity.personName), 3),
              (activity.date)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _cache[0] || (_cache[0] = _createTextVNode(" On ")),
                    _createElementVNode("span", null, _toDisplayString(activity.date), 1)
                  ], 64))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ], 2)
      ], 2))
    : _createCommentVNode("", true)
}
}

})