import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import FilesUpload from '@/components/FilesUpload.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'FilesUploadFormField',
  props: ['assetType'],
  setup(__props) {

const props = __props;
const store = useStore();

const storeSavedfiles =  computed(() => store.getters['assetCreationModule/getFiles'])
const filesAlreadySelected = computed(() => storeSavedfiles.value.assetType === props.assetType && storeSavedfiles.value.files ? storeSavedfiles.value.files : [])

const onFilesToUpload = (files:[File]) => {
    const payload = {
        assetType: props.assetType,
        files: files
    }
    store.commit('assetCreationModule/setFilesToUpload', payload);
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(FilesUpload, {
    "label-text": "Files",
    "preselected-files": filesAlreadySelected.value,
    onFilesToUpload: onFilesToUpload
  }, null, 8, ["preselected-files"]))
}
}

})