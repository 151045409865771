import { provideApolloClient } from "@vue/apollo-composable";
import apolloClient from "@/utils/apolloClient";
import { POSITION, useToast } from 'vue-toastification';
import {TDS_PROCESS, TDS_EXTRACT} from "@/grapql/tdsMutation";
import { useMutation } from "@vue/apollo-composable";



provideApolloClient(apolloClient);
const toast = useToast();

const getDefaultState = () => {
    return {
        asset:null,
        extract: {
            isNewUpload: false,
            assetFile:null,
            file:null,
        },
        extractInProgress: false,
        extractError:null,
        tempTdses:null,
        process:{
            id:null,
            properties:[],
            applications:[],
            certificates:[]
        },
        processOutputError:null,
        processInProgress:null,
        processError:null,
        polling:null,
    }
}
const state = getDefaultState();

const mutations = {
    setAssetId(state,id){
        Object.assign(state, getDefaultState());
        state.asset = id;
    },
    setSingleValue(state, payload:{field: string , value: Date | number | string | number[] | null}) {
        state.extract[payload.field] = payload.value
    },
    setFileToUpload(state, file: File) {
        state.extract.file = file;
    },
    setExtractInProgress(state,data){
        state.extractInProgress = data;
    },
    setExtractError(state,error){
        state.extractError = error;
    },
    resetStore(state){
        const id = state.asset;
        Object.assign(state, getDefaultState());
        state.asset = id;
    },
    setBeforeExtract(state){
        state.extractInProgress=true;
        state.extractError=null;
    },
    setTempTdsesValue(state,data){
        state.tempTdses = data;
    },
    setProcessSingleValue(state, payload:{field: string, index:string, key:string , value: Date | number | string | number[] | null}) {
        state.process[payload.field][payload.index][payload.key] = payload.value;
    },
    setTempTdsForProcess(state, payload:{data:any, error:any}){
        state.process = payload.data;
        state.processOutputError = payload.error;
    },
    setBeforeProcess(state){
        state.processInProgress=true;
        state.processError=null;
    },
    setProcessInProgress(state,data){
        state.processInProgress = data;
    },
    setProcessError(state,error){
        state.processError=error;
    },
    cleanUpAfterProcess(state){
        state.tempTdses = state.tempTdses.filter(a=>a.id !== state.process.id);
    },
    setPolling(state, data){
        state.polling = data;
    },
}

const actions = { 
    async extract({state, commit}) {
        return new Promise((resolve,reject)=>{
            commit("setBeforeExtract");

            const formData = new FormData();
            const operations = TDS_EXTRACT(state.asset, state.extract.isNewUpload, state.extract.assetFile);
            formData.append("operations", operations);

            if(state.extract.isNewUpload){
                const map = `{"0": ["variables.input.file"]}`;
                formData.append("map", map);
                formData.append("0", state.extract.file)
            }

            const token = localStorage.getItem("auth_token");
            
            fetch(process.env.VUE_APP_ROOT_API, {
                body: formData,
                method: "post",
                headers: {
                    authorization: token ? `jwt ${token}` : "",
                },
            })
            .then(response => {
                if (!response.ok){
                    throw new Error(response.statusText)
                }
                return response.json();
            })
            .then(responseData => {
                if (responseData.errors){
                    throw new Error(responseData.errors[0].message);
                }
                
                if (responseData.data && responseData.data.assetTdsExtract) {
                    toast.success("Form submitted! please stay until extraction is completed, OR come back later.", { position: POSITION.BOTTOM_LEFT, timeout: 6000 });
                    resolve(responseData.data.assetTdsExtract.result);
                } else {
                    commit("setExtractError", "Unknown error occurred.");
                    reject("Unknown error occurred.");
                }
                commit("setExtractInProgress", false);
            })
            .catch(error=>{
                commit("setExtractError", error.message || "An error occurred.");
                reject(error.message || "An error occurred.");
                commit("setExtractInProgress", false);
            });

        });

    },
    process({state, commit}) {
        return new Promise<void>((resolve,reject) => {
            commit("setBeforeProcess");
            const { mutate: process, onDone, onError } = useMutation(TDS_PROCESS, {refetchQueries:['getAssessmentInDepth']});
            const processInput = Object.assign({}, state.process);
            process({input: processInput});
            onDone( async () => { 
                commit("setProcessInProgress", false);
                toast.success(`TDS Processed successfully`, { position: POSITION.BOTTOM_LEFT, timeout: 1524 });
                resolve()
            });
            onError( async (error) => {
                commit("setProcessInProgress", false);
                commit('setProcessError', error.message);
                reject() 
            })
        })
      },
}
const getters = {

    getFile(state) { 
        return state.extract.file;
    },
    getTempTdses(state){
        return state.tempTdses;
    },
    getTempTdsForProcess(state){
        return state.process;
    },
    getProcessError(state){
        return state.processError;
    },
    getTempoTdsOutputError(state){
        return state.processOutputError;
    }
}

export default {
    namespaced: true,
    state, 
    mutations,
    getters,
    actions
}
