import { AssetQuantitativePropertyInterface, rawAssetQuantitativePropertyInterface, ValueTypeInterface, ValueUnitInterface } from "@/models/assetsComparisonTableInterfaces"
import { ref } from "vue";


export const parseQuantitativeValue = (value1:string, value2:string, valueType: ValueTypeInterface, valueUnit: ValueUnitInterface) => {
    const parsedValue = ref('');
    if (valueType.name == 'Between') {
        parsedValue.value = valueUnit ? `${value1} - ${value2} ${valueUnit.abbreviation}` : `${value1} - ${value2}` 
    }
    else if (valueType.name == 	'Boolean') {
        parsedValue.value = value1 == 'true' ? 'Yes' : 'No'
    }
    else {
        parsedValue.value = valueUnit ? `${valueType.symbol} ${value1} ${valueUnit.abbreviation}` :  `${valueType.symbol} ${value1}`
    }
    return parsedValue.value
}

export const useConverterForQuantitativeProperties = (assetsQuantitaiveProperties: rawAssetQuantitativePropertyInterface[]): AssetQuantitativePropertyInterface[] => {
    const listOfFormatedProperties = ref([]);
    assetsQuantitaiveProperties.map(quantitativeProperty => {
        const newProp = {
            id: quantitativeProperty.node.quantitativeProperty.id,
            name: quantitativeProperty.node.quantitativeProperty.name,
            category: quantitativeProperty.node.quantitativeProperty.category,
            extraInformation: quantitativeProperty.node.extraInformation,
            value: parseQuantitativeValue(quantitativeProperty.node.value1, quantitativeProperty.node.value2, quantitativeProperty.node.valueType, quantitativeProperty.node.valueUnit)
        }
        listOfFormatedProperties.value.push(newProp);
    });
    return listOfFormatedProperties.value
}