<template>
    <div v-if="priceContent" class="info-box">
        <template v-for="item in priceContent" :key="item.title" > 
            <template v-if="( !item.mutationValue || fieldsInThisAssetType.includes(item.mutationValue) )">
                <div v-if="item.editable && item.inputType === 'text'" class="card-content full">
                    <p class="card-title">{{item.title}} </p>
                    <input class="w-full" type="text" v-model="vModels[item.mutationValue].value">
                </div>
                <div v-if="item.editable && item.inputType === 'number'" class="card-content full">
                    <p class="card-title">{{item.title}} </p>
                    <input class="w-full" type="number" v-model="vModels[item.mutationValue].value" >
                </div>
                <div v-else-if="item.editable && item.inputType == 'simpleSelect'" class="card-content full">
                    <p class="card-title">{{item.title}}</p>
                    <DropdownSearchableSingle v-if="optionsListForDropdown[item.queryOptions] && vModels[item.mutationValue] !== undefined" :options-list="optionsListForDropdown[item.queryOptions]" v-model="vModels[item.mutationValue].value" />
                </div>
                <div v-else-if="!item.editable && !!item.content" class="card-content">
                    <p class="card-title">{{item.title}}</p>
                    <template v-if="item.content === 'RESEARCH PROJECT'">External Opportunity</template>
                    <template  v-else>{{ item.content }}</template>
                    
                </div>
            </template> 
        </template>
    </div>
</template>
<script setup lang="ts">
import { computed, defineProps } from 'vue';
import { useStore } from 'vuex';
import { NEW_ASSET_FIELDS } from '@/models/newAssetFormFields';
import { PriceContentType } from '@/models/technologyAssetInterface';
import { useQuery } from '@vue/apollo-composable';
import { ASSET_MAIN_CONTENT } from '@/grapql/assetMainContentQueries';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import DropdownSearchableSingle from './DropdownSearchableSingle.vue';

const props = defineProps<{
    priceContent: Array<PriceContentType>,
}>();

const store = useStore();

const assetType = computed(() => store.getters["assetDetailModule/getAssetType"]);
const fieldsInThisAssetType = computed(() => Object.values(NEW_ASSET_FIELDS).find(naf => naf.graphqlValue === assetType.value).formFileds)

// Inizialize the form
const { result } = useQuery(ASSET_MAIN_CONTENT);
const inizilizedData = computed(() => result?.value ?? null)

// Creat option list for drpdown
const fieldsForOptionsList = computed(() => props.priceContent.reduce((acc, obj) => [...acc, obj.queryName], []).filter(value => value !== undefined));
const optionsListForDropdown = computed<{[key: string]: DropdownSearchableOptionInterface[]}>(() => useFormInizializationNewAsset(inizilizedData.value, fieldsForOptionsList.value));
                    
const fieldsForVModels = computed(() => 
    props.priceContent.filter(field => 
            field.editable && 
            (field.inputType == 'multipleSelect' || field.inputType == 'simpleSelect' || field.inputType == 'text' || field.inputType == "number") && 
            fieldsInThisAssetType.value.includes(field.mutationValue)
        ).map(field => {return field.mutationValue}).filter(value => value !== undefined)
    )

const formFieldOptions = {
    fields: fieldsForVModels.value,
    base: assetType.value,
    mutation: 'assetDetailModule/updateMainAssetValue'
};

const formFieldsForSearchableMultipleDropdown = (formFieldOptions) => {
    console.log(formFieldOptions)
    const object = {};
    formFieldOptions.fields.map(fieldName => {
        object[fieldName] = computed({
            get() {
                if(fieldName.includes('.')){
                    const [base, field] = fieldName.split('.');
                    return store.state.assetDetailModule.asset[formFieldOptions.base][base][field] || [];
                }else{
                    return store.state.assetDetailModule.asset[formFieldOptions.base][fieldName] || [];
                }
            },
            set(value) {
                // if(fieldName.includes('.')){
                //     const [base, field] = fieldName.split('.');
                //     store.commit(formFieldOptions.mutation, { mutationValue: base, content: { [field]: value }});
                // }else{
                    store.commit(formFieldOptions.mutation, { mutationValue: fieldName, content: value});
                // }
            }
        })
    });
    return object;
}

const vModels = formFieldsForSearchableMultipleDropdown(formFieldOptions);


</script>
<style lang="scss" scoped>
.info-box{
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    .card-content {
        width: 25%; 
        flex-shrink: 0; 
        border-left: 1px solid #ddd;
        text-align: initial;
        padding: 0 1.5rem 0.5rem 1rem;  
        font-size: 0.85rem; 
        color: $font-color-light; 
        font-weight: 500;
        overflow: auto;
        text-transform: uppercase;
        overflow: visible;
        .accordion-item .content ul {
            list-style: none!important;
            padding-left: 0;
        }
        &.extra-width {
            width: 50%;
        }
        &.full {
            width: 100%;
        }
        .url {
            text-decoration: underline;
            cursor: pointer;
            text-transform: none;
            &.linkItem {
                display: block;
                // max-width: 200px;

            }
        }
        input[type=text] {
            background-color: $grey-modal!important;
            &:focus {
                border: 2px solid $blue-bg-text;
            }
        }

    }
}
.card-title {
    font-size: 0.9rem;
    color: $font-color-super-light;
    font-weight: 300;
    text-transform: none;
    position: relative;
    span {
        font-size: 0.7rem;
        color: #999;
    }
    &::after{
        content: "";
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #eeeeee;
        border: 1px solid #dddddd;
        position: absolute;
        left: -22px;
        top: 4px;
    }
    
}

.selected-results span {
    text-transform: none;
    color: white;
    background-color: $blue-bg-text;
    font-weight: 300;
    svg {
        stroke: white;
    }
}
img {
    display: inline-block;
    margin-left: 10px;
    width: 16px;
    opacity: 0.8;
}
    
</style>
