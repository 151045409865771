<template>
    <!-- <template v-if="designs.length">
       <template v-for="design in parsedDesigns" :key="design.id">
            <ProgramVersionDesign :design="design"/>
       </template>
    </template> -->
    <template v-if="loading">
        <div class="program-detail-card mb-4 mr-4">
            <div class="loading-gif-transparent"></div>
        </div>
    </template>
    <template v-else-if="wipDesigns && wipDesigns.length || archivedDesigns && archivedDesigns.length">
        <!-- CURRENT DESINGS - LOCKED YES -->
        <template v-if="wipDesigns && wipDesigns.length">
            <div class="flex items-center my-3">
                <p class="cards-title m-0">current material combinations</p>
                <div class="tooltip">
                    <img src="@/assets/icons/position.png" class="tooltip-icon" />
                    <p class="tooltip-info">Current material combinations are selected by the programme developer to reflect the potential performance of a final design.<span class="font-medium"> Please note!</span> These combinations do not confirm final performance but should give you an understanding on where we stand in the development.</p>
                </div>
                
            </div>            
            <template v-for="design in wipDesigns" :key="design">
                <ProgramVersionDesignFromBenchling :designData="design"/>
            </template>
        </template>
        <!-- ARCHIVED DESINGS - LOCKED NO -->
        <template v-if="archivedDesigns && archivedDesigns.length && isUserAllowToSeeExtraInfo">
            <div class="flex items-center my-3">
                <p class="cards-title m-0">Archived material combinations</p>
            </div>
            
            <template v-for="design in archivedDesigns" :key="design">
                <ProgramVersionDesignFromBenchling :designData="design"/>
            </template>
        </template>
    </template>
    <template v-else>
        <div class="program-detail-card mb-4 mr-4">
            <p class="cards-title">designs</p>
            <div class="flex items-center justify-center opacity-15">
                <img src="../assets/icons/layers.svg" class="w-2/3 max-w-48 pb-8 pr-4" />
                <p class="text-xl uppercase font-extrabold">No desings yet.<br /> 
                </p>
            </div>
        </div>
    </template>
</template>
<script setup lang="ts">
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
// import ProgramVersionDesign from './ProgramVersionDesign.vue';
// import { DesignFromBEInterface } from '@/models/programVersionsInterface';
import ProgramVersionDesignFromBenchling from './ProgramVersionDesignFromBenchling.vue';
import { useQuery } from '@vue/apollo-composable';

import { HasuraDesignsInterface } from '@/models/hasuraModels';
import { BENCHLING_DESIGNS } from '@/grapql/hasuraQueries';




const store = useStore();

// Desings from BE
// const designs = computed(() => store.state['programVersionDetailModule']['programVersionFromBE']['designs'].edges.map(edge => edge.node))

// const parsedDesigns = computed<DesignFromBEInterface[]>(() =>    
//     designs.value.length ? 
//         designs.value.map(({ layers, ...rest}) => ({
//             layers: layers.edges.map(edge => edge.node),
//             ...rest
//         })) 
//     : []);


// ALLOW TEMPORTARY ACCESS ONLY TO MARTA & MARTIN
const isUserAllowToSeeExtraInfo = computed(() => store.getters['authModule/isUserAllowToSeeExtraInfo']);


const programName = computed(() => store.getters['programVersionDetailModule/getProgramName'])

// Benchling and Hasure integration
const options = ref({clientId: 'hasuraClient'});

const designsFromBenchlig = ref(null);
const { onResult, loading } = useQuery(BENCHLING_DESIGNS, null, options);
onResult(queryResult => {
    if(queryResult.data && queryResult.data.prod_mart_data_test_designs) {
        designsFromBenchlig.value = queryResult.data.prod_mart_data_test_designs;
        store.commit('programVersionDetailModule/setDesingsFromBenchling', {designsFromBenchlig: designsFromBenchlig.value})
    }
    
})

const designsToShow = computed<HasuraDesignsInterface[]>(() => designsFromBenchlig.value ? designsFromBenchlig.value.filter((design: HasuraDesignsInterface) => design.programmes.includes(programName.value)) : null);
const wipDesigns = computed<HasuraDesignsInterface[]>(() => designsToShow.value ? designsToShow.value.filter((design: HasuraDesignsInterface) => design.locked_design == 'Yes') : null);
const archivedDesigns = computed<HasuraDesignsInterface[]>(() => designsToShow.value ? designsToShow.value.filter((design: HasuraDesignsInterface) => design.locked_design == 'No') : null);

</script>
<style lang="scss" scoped> 

.tooltip {
    position: relative;
    margin-bottom: 12px;
    .tooltip-icon {
        margin-left: 10px;
        cursor: pointer;
    }
    .tooltip-info {
        visibility: hidden;
        opacity: 0;
        text-align: left;
        border-radius: 4px;
        padding: 5px 9px;
        position: absolute;
        z-index: 1;
        top: -10px;
        left: 60px;
        font-size: 0.9rem;
        font-weight: 300;
        transition: all 0.4s ease-in;
        width: 503px;
        background-color: black;
        border: 1px solid;
        color: white
    }
    &:hover {
        .tooltip-info {
            visibility: visible;
            opacity: 1;
        }
    }
}
</style>