<template>
    <div class="stage-ruler">
        <div v-for="stage in stages" :key="stage.stageNumber"> 
            <p class="stage-number" :class="{active: stage.stageNumber == programStage}">{{ stage.stageNumber }}</p>
            <p v-if="stage.stageNumber === programStage" class="stage-name">{{ stage.stageName }}</p>
        </div>
        
    </div>
</template>
<script setup lang="ts">
import { PROGRAM_STAGES } from '@/models/programStagesConstant';
import {defineProps}   from 'vue';
defineProps(['programStage']);
const stages = PROGRAM_STAGES;

</script>
<style lang="scss">
    .stage-ruler {
        display: flex;
        margin: 1rem -1.5rem 3.5rem;
        font-size: 0.8rem;
        font-weight: 300;
        color: $font-color-super-light;
        align-items: flex-end;
        > div {
            flex-basis: calc(100% / 9);
            position: relative;
        }
        .stage-number {
            border-bottom: $border;
            text-align: center;
            padding-bottom: 5px;
            &.active {
                color: $blue-bg-text;
                font-size: 1.4rem;
                font-weight: 900;
                padding-bottom: 0px;
            }
            &.active::after   {
                content: '';
                background: #ddd;
                position: absolute;
                top: 33px;
                left: 50%;
                height: 15px;
                width: 1px;
            }
        }
        .stage-name {
            position: absolute;
            width: 500%;
            bottom: -42px;
            font-family: $secondary-font;
            color: $blue-bg-text;
            font-size: 1.1rem;
        }
    }
</style>