import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-4" }
const _hoisted_2 = { class: "mt-1 p-4" }
const _hoisted_3 = { class: "pt-1 pb-1" }
const _hoisted_4 = { class: "pt-1 pb-1" }

import { computed } from "vue";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import accessibilityInit from "highcharts/modules/accessibility"; // Import the accessibility module
import exportingInit from "highcharts/modules/exporting";
import heatmapInit from "highcharts/modules/heatmap"; // Import the heatmap module

// Initialize the accessibility module

export default /*@__PURE__*/_defineComponent({
  __name: 'DoubleSensitivityChart',
  props: ["data"],
  setup(__props) {

accessibilityInit(Highcharts);
// Initialize the exporting module
exportingInit(Highcharts);
// Initialize the heatmap module
heatmapInit(Highcharts);

const props = __props;

// Determine the range of x-axis and y-axis values
const xAxisCategories = [...new Set(props.data.heatMap.map((item) => item.x))];
const yAxisCategories = [...new Set(props.data.heatMap.map((item) => item.y))];
// Create a two-dimensional array for the heatmap data
const heatmapData = xAxisCategories.map((x, xIndex) => {
  return yAxisCategories.map((y, yIndex) => {
    const dataPoint = props.data.heatMap.find((item) => item.x === x && item.y === y);
    return dataPoint ? [xIndex, yIndex, dataPoint.value] : [xIndex, yIndex, null];
  });
});
// Flatten the arrays to create a single array for all data points
const flattenedHeatmapData = [].concat(...heatmapData);

/// Calculate the min and max values from your heatmapData
const min = Math.min(...props.data.heatMap.map((item) => item.value));
const max = Math.max(...props.data.heatMap.map((item) => item.value));

const chartOptions = {
  chart: {
    type: "heatmap",
  },
  title: {
    text: "Minimum Selling Price €",
  },
  xAxis: {
    categories: xAxisCategories,
    reversed: true,
    title: {
      text: props.data.x, // Title for the y-axis
    },
  },
  yAxis: {
    categories: yAxisCategories,
    title: {
      text: props.data.y, // Title for the y-axis
    },
    reversed: false,
  },
  colorAxis: {
    min: min,
    max: max, // Adjust min and max based on your data
    minColor: "#FFFFFF",
    maxColor: Highcharts.getOptions().colors[7],
    reversed: true,
  },
  legend: {
    align: "right",
    layout: "vertical",
    // margin: 0,
    verticalAlign: "middle",
    // y: 50,
    // symbolHeight: 280,
  },
  tooltip: {
    format: "Minimum Selling Price<br/>[{series.xAxis.categories.(point.x)} , {series.yAxis.categories.(point.y)}] : <b>{point.value} €</b>",
  },
  series: [
    {
      name: "Minimum Selling Price €",
      borderWidth: 1,
      data: flattenedHeatmapData,
      dataLabels: {
        enabled: true,
        color: "#000000",
      },
    },
  ],
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          yAxis: {
            labels: {
              format: "{substr value 0 1}",
            },
          },
        },
      },
    ],
  },
  exporting: {
    enabled: true,
  },
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Chart), { options: chartOptions }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(__props.data.note), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(__props.data.message), 1)
    ])
  ]))
}
}

})