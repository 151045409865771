// export const RUN_TABLE_COLUMS = [
//   { label: "ID", field: "id", hidden: true },
//   { label: "Species", field: "species", thClass:"sticky-col first-col", tdClass:"sticky-col first-col"},
//   { label: "Compound", field: "compound" },
//   { label: "Country", field: "place.name" },
//   { label: "Land", field: "landCapex" },
//   { label: "Residual Biomass", field: "residualBiomass" },
//   { label: "Extraction Method", field: "extractionMethod.name" },
//   { label: "Target Compound", field: "targetCompoundCapacity" },
//   { label: "Biomass Capacity", field: "biomass" },
//   { label: "Biomass Cost", field: "cost_biomass" },
//   { label: "Operating Days", field: "operatingDays" },
//   { label: "Concentration Extraction Buffer", field: "extractionBufferConc" },
//   { label: "Biomass Price", field: "targetCompoundPrice" },
//   { label: "Bycompound x", field: "bycompoundXPrice" },
//   { label: "MEC", field: "finalMec" },
//   { label: "CapEx", field: "finalCapex" },
//   { label: "OpEx", field: "finalOpex" },
//   { label: "Created At", field: "createdAt" },
//   { label: "Created By", field: "createdBy.fullName", sortable: true },
// ];

// export const RUN = {
//   key: "RUN",
//   graphqlValue: "run",
//   urlValue: "run",
//   displayValue: "Run",
// };

export const NEW_RUN_SENSITIVITY_FIELDS = {
  graphqlValue: "fields",
  formFields: {
    electricity: {
      required: true,
      valueValidation: {
        type: "array",
        values: [true, false],
      },
      dependants: [
        {
          ifValue: true,
          fields: ["electricityPrice"],
        },
      ],
      onChangeResetFields: ["electricityPrice"],
    },
    electricityPrice: {
      valueValidation: {
        type: "range",
        values: [0, 100],
      },
    },
    water: {
      required: true,
      valueValidation: {
        type: "array",
        values: [true, false],
      },
      dependants: [
        {
          ifValue: true,
          fields: ["waterPrice"],
        },
      ],
      onChangeResetFields: ["waterPrice"],
    },
    waterPrice: {
      valueValidation: {
        type: "range",
        values: [0, 100],
      },
    },
    co2: {
      required: true,
      valueValidation: {
        type: "array",
        values: [true, false],
      },
      dependants: [
        {
          ifValue: true,
          fields: ["co2Price"],
        },
      ],
      onChangeResetFields: ["co2Price"],
    },
    co2Price: {
      valueValidation: {
        type: "range",
        values: [0, 100],
      },
    },
  },
  fieldsToQuery: ["electricity", "water", "co2"],
  createMutation: "runSensitivityModule/runSensitivityCreate",
  calculateMutation: "runSensitivityModule/runSensitivityCalculate",
};

export interface formFieldsOptionsInteface {
  id: number;
  name: string;
}
