export const NEW_RUN_DOUBLE_SENSITIVITY_FIELDS = {
  graphqlValue: "fields",
  formFields: {
    firstVariable: {
      required: true,
      dependants: [
        {
          ifValue: "Electricity",
          ifValueType: "option",
          ifValueReference: "variablesOptions",
          fields: ["firstValue"],
        },
        {
          ifValue: "Water",
          ifValueType: "option",
          ifValueReference: "variablesOptions",
          fields: ["firstValue"],
        },
        {
          ifValue: "CO2",
          ifValueType: "option",
          ifValueReference: "variablesOptions",
          fields: ["firstValue"],
        },
        {
          ifValue: "Productivity target compound",
          ifValueType: "option",
          ifValueReference: "variablesOptions",
          fields: ["firstValueRangeMin", "firstValueRangeMax"],
        },
        {
          ifValue: "Cycle length",
          ifValueType: "option",
          ifValueReference: "variablesOptions",
          fields: ["firstValueRangeMin", "firstValueRangeMax"],
        },
        {
          ifValue: "Sugar conc.",
          ifValueType: "option",
          ifValueReference: "variablesOptions",
          fields: ["firstValueRangeMin", "firstValueRangeMax"],
        },
        {
          ifValue: "Extraction buffer conc.",
          ifValueType: "option",
          ifValueReference: "variablesOptions",
          fields: ["firstValueRangeMin", "firstValueRangeMax"],
        },
        {
          ifValue: "Solvent conc.",
          ifValueType: "option",
          ifValueReference: "variablesOptions",
          fields: ["firstValueRangeMin", "firstValueRangeMax"],
        },
        {
          ifValue: "Solvent #2 conc.",
          ifValueType: "option",
          ifValueReference: "variablesOptions",
          fields: ["firstValueRangeMin", "firstValueRangeMax"],
        },
        {
          ifValue: "Solvent #3 conc.",
          ifValueType: "option",
          ifValueReference: "variablesOptions",
          fields: ["firstValueRangeMin", "firstValueRangeMax"],
        },
      ],
      onChangeResetFields: ["firstValue", "firstValueRangeMin", "firstValueRangeMax"],
    },
    secondVariable: {
      required: true,
      dependants: [
        {
          ifValue: "Electricity",
          ifValueType: "option",
          ifValueReference: "variablesOptions",
          fields: ["secondValue"],
        },
        {
          ifValue: "Water",
          ifValueType: "option",
          ifValueReference: "variablesOptions",
          fields: ["secondValue"],
        },
        {
          ifValue: "CO2",
          ifValueType: "option",
          ifValueReference: "variablesOptions",
          fields: ["secondValue"],
        },
        {
          ifValue: "Productivity target compound",
          ifValueType: "option",
          ifValueReference: "variablesOptions",
          fields: ["secondValueRangeMin", "secondValueRangeMax"],
        },
        {
          ifValue: "Cycle length",
          ifValueType: "option",
          ifValueReference: "variablesOptions",
          fields: ["secondValueRangeMin", "secondValueRangeMax"],
        },
        {
          ifValue: "Sugar conc.",
          ifValueType: "option",
          ifValueReference: "variablesOptions",
          fields: ["secondValueRangeMin", "secondValueRangeMax"],
        },
        {
          ifValue: "Extraction buffer conc.",
          ifValueType: "option",
          ifValueReference: "variablesOptions",
          fields: ["secondValueRangeMin", "secondValueRangeMax"],
        },
        {
          ifValue: "Solvent conc.",
          ifValueType: "option",
          ifValueReference: "variablesOptions",
          fields: ["secondValueRangeMin", "secondValueRangeMax"],
        },
        {
          ifValue: "Solvent #2 conc.",
          ifValueType: "option",
          ifValueReference: "variablesOptions",
          fields: ["secondValueRangeMin", "secondValueRangeMax"],
        },
        {
          ifValue: "Solvent #3 conc.",
          ifValueType: "option",
          ifValueReference: "variablesOptions",
          fields: ["secondValueRangeMin", "secondValueRangeMax"],
        },
      ],
      onChangeResetFields: ["secondValue", "secondValueRangeMin", "secondValueRangeMax"],
    },
    firstValue: {
      valueValidation: {
        type: "range",
        values: [0, 100],
      },
    },
    secondValue: {
      valueValidation: {
        type: "range",
        values: [0, 100],
      },
    },
    firstValueRangeMin: {},
    firstValueRangeMax: {},
    secondValueRangeMin: {},
    secondValueRangeMax: {},
  },
  fieldsToQuery: ["variables"],
  createMutation: "runDoubleSensitivityModule/runDoubleSensitivityCreate",
  calculateMutation: "runDoubleSensitivityModule/runDoubleSensitivityCalculate",
};

export interface formFieldsOptionsInteface {
  id: number;
  name: string;
}


export interface formFieldsOptionsInteface {
  id: number;
  name: string;
}
