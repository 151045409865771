import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass, vModelText as _vModelText, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/icons/layers.svg'
import _imports_1 from '../assets/icons/food-delivery.svg'


const _hoisted_1 = { class: "px-6 pb-12" }
const _hoisted_2 = { class: "grid grid-cols-2 gap-4" }
const _hoisted_3 = { class: "filter-values flex flex-col" }
const _hoisted_4 = { class: "flex flex-col leading-4" }
const _hoisted_5 = { class: "text-sm uppercase font-medium text-black" }
const _hoisted_6 = { class: "text-xs text-neutral-400" }
const _hoisted_7 = ["id", "value"]
const _hoisted_8 = { class: "filter-values flex flex-col" }
const _hoisted_9 = { class: "flex flex-col leading-4" }
const _hoisted_10 = { class: "text-sm uppercase font-medium text-black" }
const _hoisted_11 = { class: "text-xs text-neutral-400" }
const _hoisted_12 = ["id", "value"]
const _hoisted_13 = { class: "filter-values flex flex-wrap" }
const _hoisted_14 = { class: "flex flex-col leading-4" }
const _hoisted_15 = { class: "text-sm uppercase font-medium text-black" }
const _hoisted_16 = { class: "text-xs text-neutral-400" }
const _hoisted_17 = ["id", "value"]
const _hoisted_18 = { class: "px-6 flex justify-center mb-6" }
const _hoisted_19 = { class: "filter-values flex-wrap grid grid-cols-2" }
const _hoisted_20 = { class: "flex flex-col leading-4" }
const _hoisted_21 = { class: "text-sm uppercase font-medium text-black" }
const _hoisted_22 = { class: "text-xs text-neutral-400" }
const _hoisted_23 = ["id", "value"]

import ModalSlots from "@/components/ModalSlots.vue";
import { ref, computed, watch } from 'vue';
import {BENCHMARK_PROPERTIES, BENCHMARK_COMMERCIAL_PROPERTIES, BenchmarkPropertiesInterface, StoreBenchmarkData} from '@/models/benchmarkModels';
import { useStore } from 'vuex';
import { ExternalSolutionInterface, HasuraDesignsInterface } from "@/models/hasuraModels";
import { BENCHLING_DESIGNS, EXT_SOLUTIONS } from "@/grapql/hasuraQueries";
import { useQuery } from '@vue/apollo-composable';
import router from '@/router';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramVersionBenchmarkForm',
  props: {
    isOpen: { type: Boolean },
    isOnEdit: { type: Boolean },
    programName: {}
  },
  emits: ['closeModal'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;

const store = useStore();

// Data from Store 
const benchmarkData = computed<StoreBenchmarkData>(() => store.getters['programVersionDetailModule/getBenchmarkData']);
const programName = computed(() => props.programName ? props.programName : benchmarkData.value.program );

// Properties
const benchmarkProperties = computed<BenchmarkPropertiesInterface[]>(() => BENCHMARK_PROPERTIES.filter(property => property.ready));
const commercialProperties = ref<BenchmarkPropertiesInterface[]>(BENCHMARK_COMMERCIAL_PROPERTIES);
const selectedProperties = ref([]);
const selectedCommercial = ref([]);

// Benchling packages - our packages
const allPackages = ref([]);
const designsToShow = computed<HasuraDesignsInterface[]>(() => allPackages?.value ? allPackages.value.filter((design: HasuraDesignsInterface) => design.programmes.includes(programName.value)) : null);
const ourPackages = computed<HasuraDesignsInterface[]>(() => designsToShow?.value ? designsToShow.value.filter((design: HasuraDesignsInterface) => design.locked_design == 'Yes') : null);
const selectedOwnPackages = ref<HasuraDesignsInterface[]>([]);

// External entities from Benchling - External packages
const externalPackages = ref<ExternalSolutionInterface[]>([]);
const selectedExternalPackages = ref([])
const options = ref({clientId: 'hasuraClient'});
const filterValue = ref('');
const filteredExternalPackages = computed(() =>
    filterValue.value === ''
      ? externalPackages.value
      : externalPackages.value.filter((extPack) => {
          return extPack.design_name.toLowerCase().includes(filterValue.value.toLowerCase())
        })
  )

const { onResult } = useQuery(EXT_SOLUTIONS, null, options);
onResult(queryResult => {
    if(queryResult.data && queryResult.data.prod_mart_data_external_designs) {
        externalPackages.value = queryResult.data.prod_mart_data_external_designs;
    }
});

const { onResult:internalDesigns } = useQuery(BENCHLING_DESIGNS, null, options);
internalDesigns(queryResult => {
    if(queryResult.data && queryResult.data.prod_mart_data_test_designs) {
        allPackages.value = queryResult.data.prod_mart_data_test_designs;
    }
})

const disableButton = computed(() => selectedOwnPackages.value.length == 0 && selectedExternalPackages.value.length == 0)

watch(() => props.isOpen, () => {
    if (props.isOnEdit) {
        selectedExternalPackages.value = benchmarkData.value.selectedExternalPackages;
        selectedOwnPackages.value = benchmarkData.value.selectedInternalPackages;
        selectedCommercial.value = benchmarkData.value.selectedCommercialValues;
        selectedProperties.value = benchmarkData.value.selectedProperties;
    } else {
        selectedProperties.value = benchmarkProperties.value;
        selectedCommercial.value = commercialProperties.value; 
    }
}, { deep: true })



const handleCreateBenchmarkTable = () => {
    const payload = {
        selectedProperties: selectedProperties.value, 
        selectedCommercialValues: selectedCommercial.value, 
        selectedInternalPackages: selectedOwnPackages.value, 
        selectedExternalPackages: selectedExternalPackages.value,
        program: programName.value,
    }
    store.commit('programVersionDetailModule/saveValuesForBenchmarking', payload );
    emits('closeModal')
    router.push({name: 'Benchmark', params: {title: 'create'}});
}



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalSlots, {
    isOpen: _ctx.isOpen,
    dialogClass: "m-modal",
    onCloseModal: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('closeModal'))),
    onClickCreate: handleCreateBenchmarkTable,
    "create-in-progress": disableButton.value,
    "create-text": _ctx.isOnEdit ? 'Upadate' : 'create'
  }, {
    header: _withCtx(() => _cache[6] || (_cache[6] = [
      _createElementVNode("p", null, "Benchmarking", -1)
    ])),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "form-separator" }, [
          _createElementVNode("p", { class: "form-subtitle" }, "properties")
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(benchmarkProperties.value, (property) => {
              return (_openBlock(), _createElementBlock("label", {
                key: property.criteria,
                class: "filter-option justify-between"
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("p", _hoisted_5, _toDisplayString(property.criteria), 1),
                  _createElementVNode("p", _hoisted_6, _toDisplayString(property.category), 1)
                ]),
                _withDirectives(_createElementVNode("input", {
                  type: "checkbox",
                  class: "pink",
                  id: property.criteria,
                  value: property,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedProperties).value = $event))
                }, null, 8, _hoisted_7), [
                  [_vModelCheckbox, selectedProperties.value]
                ])
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(commercialProperties.value, (commercialValue) => {
              return (_openBlock(), _createElementBlock("label", {
                key: commercialValue,
                class: "filter-option justify-between"
              }, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("p", _hoisted_10, _toDisplayString(commercialValue.criteria), 1),
                  _createElementVNode("p", _hoisted_11, _toDisplayString(commercialValue.category), 1)
                ]),
                _withDirectives(_createElementVNode("input", {
                  type: "checkbox",
                  class: "pink",
                  id: commercialValue.criteria,
                  value: commercialValue,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedCommercial).value = $event))
                }, null, 8, _hoisted_12), [
                  [_vModelCheckbox, selectedCommercial.value]
                ])
              ]))
            }), 128))
          ])
        ]),
        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "form-separator" }, [
          _createElementVNode("p", { class: "form-subtitle" }, "our packaging")
        ], -1)),
        _createElementVNode("div", _hoisted_13, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ourPackages.value, (design) => {
            return (_openBlock(), _createElementBlock("label", {
              key: design.description,
              class: _normalizeClass(["filter-option hidden-check mr-4", {'border-on': selectedOwnPackages.value.some(pack => pack.pro_mat_name == design.pro_mat_name)}])
            }, [
              _createElementVNode("img", {
                src: _imports_0,
                class: _normalizeClass(["w-2/3 max-w-12 pr-4 pl-1", [!selectedOwnPackages.value.some(pack => pack.pro_mat_name == design.pro_mat_name) ? 'opacity-50' : 'opacity-100']])
              }, null, 2),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("p", _hoisted_15, _toDisplayString(design.pro_mat_name), 1),
                _createElementVNode("p", _hoisted_16, _toDisplayString(design.description), 1)
              ]),
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                id: design.description,
                value: design,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((selectedOwnPackages).value = $event))
              }, null, 8, _hoisted_17), [
                [_vModelCheckbox, selectedOwnPackages.value]
              ])
            ], 2))
          }), 128))
        ]),
        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "form-separator" }, [
          _createElementVNode("p", { class: "form-subtitle" }, "external packaging")
        ], -1)),
        _createElementVNode("div", _hoisted_18, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((filterValue).value = $event)),
            placeholder: "Type here for quick search ...",
            class: "search-externals h-10 w-4/5"
          }, null, 512), [
            [_vModelText, filterValue.value]
          ])
        ]),
        _createElementVNode("div", _hoisted_19, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredExternalPackages.value, (ext, index) => {
            return (_openBlock(), _createElementBlock("label", {
              key: index,
              class: _normalizeClass(["filter-option hidden-check mr-4", {'border-on': selectedExternalPackages.value.some(pack => pack.design_name == ext.design_name)}])
            }, [
              _createElementVNode("img", {
                src: _imports_1,
                class: _normalizeClass(["w-2/3 max-w-12 pr-4 pl-1", [!selectedExternalPackages.value.some(pack => pack.design_name == ext.design_name) ? 'opacity-50' : 'opacity-100']])
              }, null, 2),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("p", _hoisted_21, _toDisplayString(ext.design_name), 1),
                _createElementVNode("p", _hoisted_22, _toDisplayString(ext.description), 1)
              ]),
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                id: ext.description,
                value: ext,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((selectedExternalPackages).value = $event))
              }, null, 8, _hoisted_23), [
                [_vModelCheckbox, selectedExternalPackages.value]
              ])
            ], 2))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }, 8, ["isOpen", "create-in-progress", "create-text"]))
}
}

})