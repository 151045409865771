<template>
  <div>
    <RunGroupHeader
      :title="HEADER_TITLE"
      :edge-count="runGroups.edgeCount"
      :total-count="runGroups.totalCount"
      @search-text="handleSearch"
      @on-created="onCreated"
    />
    <RunGroupTable
      :columns="columns"
      :rows="runGroups.loadedRunGroups"
      @on-page-change="onPageChange"
      @on-row-click="onRowClick"
      :searchTerm="searchTerm"
      :loading="runGroups.loading"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import RunGroupHeader from "@/tea/components/rungroups/RunGroupHeader.vue";
import RunGroupTable from "@/tea/components/rungroups/RunGroupTable.vue";
import { RUN_GROUP_TABLE_COLUMS } from "@/tea/models/runGroup";
import router from "@/router";
import { useStore } from "vuex";
const store = useStore();

const HEADER_TITLE = "Run Groups";
const columns = ref(RUN_GROUP_TABLE_COLUMS);

const searchTerm = ref("");
const handleSearch = (searchValue) => {
  searchTerm.value = searchValue;
};
const repo = computed(() => store.getters["runGroupModule/repo"]);
const runGroups = computed(() => {
  const loadedRunGroups = repo.value.runGroups.map((node) => {
    return { ...node, createdAt: new Date(node.createdAt).toLocaleString() };
  });
  return { loadedRunGroups, totalCount: repo.value.totalCount, edgeCount: repo.value.edgeCount, loading: repo.value.loading };
});

store.dispatch("runGroupModule/loadRunGroups");

const hasNextPage = computed(() => {
  if (repo.value.pageInfo) {
    return repo.value.pageInfo.hasNextPage;
  }
  return false;
});

const loadMore = async () => {
  await store.dispatch("runGroupModule/loadMoreRunGroups");
};
const onCreated = async () => {
  console.log("created");
};
const onPageChange = () => {
  if (hasNextPage.value) {
    loadMore();
  }
};

const onRowClick = (id) => {
  router.push({ name: "Tea.RunGroupDetail", params: { id } });
};
</script>
