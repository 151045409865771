<template>
    <div v-if="lastPulse" class="flex mb-6">
      <div style="width:40px; min-width: 40px;" class="mr-4">
          <div class="flex items-center justify-center border border-gray-200 rounded-full h-10 w-10">
              <CpuChipIcon class="icon icon-small black  heartbeat-circle"/>
          </div>
      </div>
      <div class="flex-grow p-3  border border-gray-200 shadow-sm ">
          <span  v-html="JSON.parse(lastPulse)" style="width: auto !important;"></span><HeartBeat style="display: inline-block !important;"/>
      </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { CpuChipIcon } from '@heroicons/vue/24/outline';
import HeartBeat from './HeartBeat.vue';

const store = useStore();

// get lastPulse
const lastPulse = computed(()=> store.state.aiModule.lastPulse);

</script>
<style scoped>
@keyframes heartbeat {
  0%, 100% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.6);
  }
}

.heartbeat-circle {
  animation: heartbeat 0.4s infinite alternate;
}
</style>