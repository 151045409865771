import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, normalizeClass as _normalizeClass, vModelText as _vModelText, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "form-container" }
const _hoisted_2 = {
  key: 0,
  class: "add-run-cultivation-form"
}
const _hoisted_3 = { class: "grid grid-cols-4 gap-y-3 gap-x-4" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "col-span-4" }
const _hoisted_6 = { class: "col-span-4" }

import { RUN_ADD_FORM_VALUES } from "@/tea/graphql/queries/runQueries";
import { useTeaQuery } from "@/tea/composables/apollo/useTeaQuery";
import { computed, ref } from "vue";
import { NEW_RUN_FIELDS } from "@/tea/models/run";
import { useFormFieldsCreationNewRun } from "@/tea/composables/runs/useFormFieldsCreationNewRun";
import { useFormInizializationNewRun } from "@/tea/composables/runs/useFormInizializationNewRun";
import FormErrorToast from "@/components/FormErrorToast.vue";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { DropdownSearchableOptionInterface } from "@/models/dropdownSearchableMultipleOptionInterface";
import { useStore } from "vuex";
import RunNonResidual from "@/tea/components/runs/RunNonResidual.vue";
import RunResidual from "@/tea/components/runs/RunResidual.vue";
import RunExtraction from "@/tea/components/runs/RunExtraction.vue";
import RunNvp from "@/tea/components/runs/RunNvp.vue";
import RunOutputs from "@/tea/components/runs/RunOutputs.vue";

import { Switch } from "@headlessui/vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'RunAddNewForm',
  setup(__props) {

const store = useStore();

// Inizialize the form
const { result } = useTeaQuery(RUN_ADD_FORM_VALUES);

const inizilizedData = computed(() => result?.value ?? null);
const formInizializationValues = computed<{ [key: string]: DropdownSearchableOptionInterface[] }>(() =>
  useFormInizializationNewRun(inizilizedData.value, NEW_RUN_FIELDS.fieldsToQuery)
);
const errorsDuringMutation=computed(()=> store.getters["runCreateModule/createError"])

const getFormFields = Object.keys(NEW_RUN_FIELDS.formFields).map((key) => {
    const fieldConfig = NEW_RUN_FIELDS.formFields[key];
    return {
        [key]: Array.isArray(fieldConfig.onChangeResetFields) ? fieldConfig.onChangeResetFields : [],
    };
});
const formFieldOptions = {
  fields: getFormFields,
  mutation: "runCreateModule/SET_SINGLE_RUN_VALUE",
};
const formFields = useFormFieldsCreationNewRun(formFieldOptions);

const fields = computed(() => store.getters["runCreateModule/fields"]);
const isReadyForOutput = computed(() => store.getters["runCreateModule/isReadyForOutput"]);
const isResidualBiomass = computed(() => {
  return fields.value.residualBiomass;
});

const formSubmited = computed(()=> store.getters["runCreateModule/isFormSubmitted"]);
const valuesSubmitted = computed(() => fields.value);

const formIsValid = computed(() => {
  return errorsFields.value.length === 0;
});

function getMissingFields(fieldName, fieldValue, validation) {
  const missingFields = [];
  // 1. check if required
  if (validation.required === true) {
    if (fieldValue === undefined || fieldValue === null || fieldValue === "") {
      missingFields.push(fieldName);
    }
  }

  // 2. check if validtion has valueValidation property set and aloso field has value, then check if value is truthy
  if (validation.valueValidation && fieldValue !== undefined && fieldValue !== null && fieldValue !== "") {
    const { type, values } = validation.valueValidation;
    if (type === "array") {
      if (!values.includes(fieldValue)) {
        missingFields.push(fieldName);
      }
    } else if (type === "range") {
      if (fieldValue < values[0] || fieldValue > values[1]) {
        missingFields.push(fieldName);
      }
    } else if (type === "equal") {
      if (fieldValue !== values) {
        missingFields.push(fieldName);
      }
    } else if (type === "min") {
      if (fieldValue < values) {
        missingFields.push(fieldName);
      }
    }
  }

  // 3. check dependants
  if (validation.dependants) {
    const { dependants } = validation;
    for (const condition of dependants) {
      let doCheckUp = false;
      const { ifValue, ifValueType, ifValueReference, fields, not, is } = condition;
      // Check if the field associated with the condition is truthy
      //check if there is ifValueType equals to options
      //check if it has not property
      //check if it has is property
      if (ifValueType === "option") {
        if (formInizializationValues.value[ifValueReference]) {
          let selectedItem = formInizializationValues.value[ifValueReference].find((item) => item.name === ifValue);
          if (selectedItem) {
            if (selectedItem.id == fieldValue) {
              doCheckUp = true;
            }
          }
        }
      } else if (fieldValue === ifValue) {
        doCheckUp = true;
      }

      if (not && doCheckUp) {
        for (const notCondition of not) {
          let notIfFieldValue = valuesSubmitted.value[notCondition.ifField];
          if (notCondition.ifValueType === "option") {
            if (formInizializationValues.value[notCondition.ifValueReference]) {
              let selectedItem = formInizializationValues.value[notCondition.ifValueReference].find(
                (item) => item.name === notCondition.ifValue
              );
              if (selectedItem) {
                if (selectedItem.id === notIfFieldValue) {
                  //do not do checkup because it does not passes the not condition
                  doCheckUp = false;
                }
              }
            }
          } else if (notIfFieldValue === notCondition.ifValue) {
            //do not do checkup because it does not passes the not condition
            doCheckUp = false;
          }
        }
      }

      if (is && doCheckUp) {
        for (const isCondition of is) {
          let isIfFieldValue = valuesSubmitted.value[isCondition.ifField];
          if (isCondition.ifValueType === "option") {
            if (formInizializationValues.value[isCondition.ifValueReference]) {
              let selectedItem = formInizializationValues.value[isCondition.ifValueReference].find(
                (item) => item.name === isCondition.ifValue
              );
              if (selectedItem) {
                if (selectedItem.id !== isIfFieldValue) {
                  //do not do checkup because it does not passes the not condition
                  doCheckUp = false;
                }
              }
            }
          } else if (isIfFieldValue !== isCondition.ifValue) {
            //do not do checkup because it does not passes the not condition
            doCheckUp = false;
          }
        }
      }

      if (doCheckUp) {
        for (const dependantField of fields) {
          // Check if the field is missing
          const dependantValue = valuesSubmitted.value[dependantField];
          if (dependantValue === undefined || dependantValue === null || dependantValue === "") {
            missingFields.push(dependantField);
          }
        }
      }
    }
  }

  // Add more validation logic here if needed.
  return Array.from(new Set(missingFields)); //Remove duplicates
}
const errorsFields = computed(() => {
  const missingFields = [];
  for (const fieldName of Object.keys(NEW_RUN_FIELDS.formFields)) {
    const validation = NEW_RUN_FIELDS.formFields[fieldName];
    const fieldValue = valuesSubmitted.value[fieldName];
    missingFields.push(...getMissingFields(fieldName, fieldValue, validation));
  }
  return Array.from(new Set(missingFields)); //Remove duplicates
});

const showOutputs =async () => {
  
  store.commit('runCreateModule/SET_IS_FORM_SUBMITTED',true);

  if (formIsValid.value) {
    
    // get outputs now
    const mutation = NEW_RUN_FIELDS.calculateMutation;
    await store
      .dispatch(mutation)
      .then(() => {
        store.commit("runCreateModule/SET_IS_READY_FOR_OUTPUT", true);
        store.commit("runCreateModule/SET_CAN_SUMBIT", true);
        store.commit("runCreateModule/SET_CREATE_ERROR",null);
      })
      .catch((error) => {
        console.log('error is here')
        console.log(error);
      });
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(FormErrorToast, {
      "is-showing": !!errorsDuringMutation.value,
      title: "Warning!",
      subtitle: errorsDuringMutation.value,
      "toast-type": "warning"
    }, null, 8, ["is-showing", "subtitle"]),
    _createVNode(FormErrorToast, {
      "is-showing": formSubmited.value && !formIsValid.value,
      title: "The following fields have validation errors:",
      subtitle: errorsFields.value.join(', ')
    }, null, 8, ["is-showing", "subtitle"]),
    (formInizializationValues.value)
      ? (_openBlock(), _createElementBlock("form", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "form-separator col-span-4" }, [
              _createElementVNode("p", { class: "form-subtitle" }, "Cultivation Info")
            ], -1)),
            _createElementVNode("div", {
              class: _normalizeClass(["col-span-4", { 'error-form-field': errorsFields.value.includes('place') && formSubmited.value }])
            }, [
              _cache[4] || (_cache[4] = _createElementVNode("label", null, "Place *", -1)),
              _withDirectives(_createElementVNode("select", {
                class: "w-full",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(formFields)['place'].value) = $event))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formInizializationValues.value['placesOptions'], (place) => {
                  return (_openBlock(), _createElementBlock("option", {
                    value: Number(place.id),
                    key: place.id
                  }, _toDisplayString(place.name), 9, _hoisted_4))
                }), 128))
              ], 512), [
                [_vModelSelect, _unref(formFields)['place'].value]
              ])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["col-span-2", { 'error-form-field': errorsFields.value.includes('species') && formSubmited.value }])
            }, [
              _cache[5] || (_cache[5] = _createElementVNode("label", null, "Which species are you working with? *", -1)),
              _withDirectives(_createElementVNode("input", {
                class: "w-full",
                type: "text",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(formFields)['species'].value) = $event))
              }, null, 512), [
                [_vModelText, _unref(formFields)['species'].value]
              ])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["col-span-2 grid", { 'error-form-field': errorsFields.value.includes('compound') && formSubmited.value }])
            }, [
              _cache[6] || (_cache[6] = _createElementVNode("label", null, "Which compound are you interested in producing? *", -1)),
              _withDirectives(_createElementVNode("input", {
                class: "w-full",
                type: "text",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(formFields)['compound'].value) = $event))
              }, null, 512), [
                [_vModelText, _unref(formFields)['compound'].value]
              ])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["col-span-4 flex items-center", { 'error-form-field': errorsFields.value.includes('residualBiomass') && formSubmited.value }])
            }, [
              _cache[8] || (_cache[8] = _createElementVNode("label", { class: "inline-block mr-2" }, "Are you using residual biomass?", -1)),
              _createVNode(_unref(Switch), {
                modelValue: _unref(formFields)['residualBiomass'].value,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(formFields)['residualBiomass'].value) = $event)),
                class: _normalizeClass([_unref(formFields)['residualBiomass'].value ? 'bg-emerald-700' : 'bg-gray-200', "relative inline-flex h-6 w-11 items-center rounded-full"])
              }, {
                default: _withCtx(() => [
                  _cache[7] || (_cache[7] = _createElementVNode("span", { class: "sr-only" }, "Are you using residual biomass?", -1)),
                  _createElementVNode("span", {
                    class: _normalizeClass([_unref(formFields)['residualBiomass'].value ? 'translate-x-6' : 'translate-x-1', "inline-block h-4 w-4 transform rounded-full bg-white transition"])
                  }, null, 2)
                ]),
                _: 1
              }, 8, ["modelValue", "class"])
            ], 2),
            (!isResidualBiomass.value)
              ? (_openBlock(), _createBlock(RunNonResidual, {
                  key: 0,
                  formInizializationValues: formInizializationValues.value,
                  errorsFields: errorsFields.value,
                  formSubmited: formSubmited.value
                }, null, 8, ["formInizializationValues", "errorsFields", "formSubmited"]))
              : (isResidualBiomass.value)
                ? (_openBlock(), _createBlock(RunResidual, {
                    key: 1,
                    formInizializationValues: formInizializationValues.value,
                    errorsFields: errorsFields.value,
                    formSubmited: formSubmited.value
                  }, null, 8, ["formInizializationValues", "errorsFields", "formSubmited"]))
                : _createCommentVNode("", true),
            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "form-separator col-span-4" }, [
              _createElementVNode("p", { class: "form-subtitle" }, "Extraction & drying")
            ], -1)),
            _createVNode(RunExtraction, {
              formInizializationValues: formInizializationValues.value,
              errorsFields: errorsFields.value,
              formSubmited: formSubmited.value
            }, null, 8, ["formInizializationValues", "errorsFields", "formSubmited"]),
            _cache[11] || (_cache[11] = _createElementVNode("div", { class: "form-separator col-span-4" }, [
              _createElementVNode("p", { class: "form-subtitle" }, "Expected Yearly Income")
            ], -1)),
            _createVNode(RunNvp, {
              formInizializationValues: formInizializationValues.value,
              errorsFields: errorsFields.value,
              formSubmited: formSubmited.value
            }, null, 8, ["formInizializationValues", "errorsFields", "formSubmited"]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(FormErrorToast, {
                "is-showing": formSubmited.value && !formIsValid.value,
                title: "The following fields have validation errors::",
                subtitle: errorsFields.value.join(', ')
              }, null, 8, ["is-showing", "subtitle"]),
              _createVNode(FormErrorToast, {
                "is-showing": !!errorsDuringMutation.value,
                title: "Warning!",
                subtitle: errorsDuringMutation.value,
                "toast-type": "warning"
              }, null, 8, ["is-showing", "subtitle"])
            ]),
            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "form-separator col-span-4" }, [
              _createElementVNode("p", { class: "form-subtitle" }, "Outputs")
            ], -1)),
            _createVNode(RunOutputs, {
              isReadyForOutput: isReadyForOutput.value,
              onShowOutputs: showOutputs
            }, null, 8, ["isReadyForOutput"]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(FormErrorToast, {
                "is-showing": !!errorsDuringMutation.value && (formSubmited.value && !formIsValid.value),
                title: "Warning!",
                subtitle: errorsDuringMutation.value,
                "toast-type": "warning"
              }, null, 8, ["is-showing", "subtitle"])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})