<template>
  <div>
    <div class="round-action-icon" :class="{ active: isOpen }">
      <PlusIcon class="icon icon-small" @click="openModal" />
    </div>
    <ModalSlots :show="isOpen" @close-modal="closeModal" @click-create="createNewRunGroup" dialogClass="create-run-group-modal">
      <template v-slot:header><p>Create new Run Group</p></template>
      <template v-slot:body>
        <RunGroupAddNewForm
          :form-is-valid="formValid"
          :form-submited="formSubmited"
          :errors-fields="errorFields"
          :query-error="queryError"
        />
      </template>
    </ModalSlots>
  </div>
</template>
<script setup lang="ts">
import {PlusIcon } from "@heroicons/vue/24/outline";
import { ref, computed, defineEmits } from "vue";
import { useStore } from "vuex";
import ModalSlots from "@/components/ModalSlots.vue";
import { NEW_RUN_GROUP_FIELDS } from "@/tea/models/runGroup";
import RunGroupAddNewForm from "@/tea/components/rungroups/RunGroupAddNewForm.vue";

const store = useStore();
const isOpen = ref(false);
const collapsed = computed(() => store.getters["authModule/isSidebarCollapsed"]);
const toggleSidebar = () => store.commit("authModule/toogleSidebar");
const closeModal = () => {
  isOpen.value = false;
  formSubmited.value = false;
};

const openModal = () => {
  if (!collapsed.value) {
    toggleSidebar();
  }
  isOpen.value = true;
};
const emit = defineEmits(["onCreated"]);
const formSubmited = ref(false);
const create = computed(() => store.getters["runGroupModule/create"]);

const valuesSubmitted = computed(() => create.value.runGroup);
const formValid = computed(() => NEW_RUN_GROUP_FIELDS.mandatoryFormFields.every((mf) => !!valuesSubmitted.value[mf]));
const errorFields = computed(() => NEW_RUN_GROUP_FIELDS.mandatoryFormFields.filter((mf) => !valuesSubmitted.value[mf]));
const queryError = computed(() => create.value.error);

const createNewRunGroup = async () => {
  formSubmited.value = true;
  if (formValid.value) {
    formSubmited.value = false;
    const mutation = NEW_RUN_GROUP_FIELDS.createMutation;
    await store
      .dispatch(mutation)
      .then(() => {
        emit("onCreated");
        closeModal();
      })
      .catch((error) => {
        // console.log(error);
      });
  }
};
</script>

<style lang="scss" scoped>
.party-box-container {
  margin: 2rem 1rem 3rem;
}
.party-box {
  border: $border;
  border-radius: 8px;
  margin: 1rem;
  color: $font-color;
  .party-box-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    cursor: pointer;
    &.selected {
      background-color: $grey-modal;
    }

    p {
      font-size: 0.8rem;
      line-height: 1.2;
      &:first-child {
        font-weight: 400;
        text-transform: uppercase;
        font-size: 1.2rem;
      }
    }
  }
  .party-box-body {
    border-top: $border;
    padding: 1rem 2rem;
    background-color: white;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}
</style>
