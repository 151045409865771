import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "stage-sidebar" }
const _hoisted_2 = { class: "stages-list" }

import { PROGRAM_STAGES } from '@/models/programStagesConstant';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramDetailStagesSidebar',
  props: {activeStage: { type: String }},
  setup(__props) {


const stages = PROGRAM_STAGES;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("p", { class: "overview" }, "Overview", -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(stages), (stage) => {
        return (_openBlock(), _createElementBlock("div", {
          key: stage.stageNumber,
          class: "stage-row"
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["status-dot", {active: __props.activeStage == stage.stageNumber, ok: __props.activeStage > stage.stageNumber, ko: __props.activeStage < stage.stageNumber }])
          }, null, 2),
          _createElementVNode("div", {
            class: _normalizeClass(["name", {ko: __props.activeStage < stage.stageNumber}])
          }, _toDisplayString(stage.stageName), 3),
          _createElementVNode("div", {
            class: _normalizeClass(["number", {active: __props.activeStage == stage.stageNumber, ko: __props.activeStage < stage.stageNumber}])
          }, _toDisplayString(stage.stageNumber), 3)
        ]))
      }), 128))
    ])
  ]))
}
}

})