import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, normalizeClass as _normalizeClass, vModelText as _vModelText, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col-span-4" }
const _hoisted_2 = { class: "grid grid-cols-4 gap-y-4 gap-x-4" }
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  key: 0,
  class: "col-span-4"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  class: "col-span-4"
}
const _hoisted_7 = {
  key: 0,
  class: "col-span-4"
}
const _hoisted_8 = { class: "flex w-full items-center justify-stretch" }
const _hoisted_9 = { class: "flex w-full items-center justify-between" }
const _hoisted_10 = { class: "flex items-center" }
const _hoisted_11 = { class: "text-sm" }
const _hoisted_12 = { class: "shrink-0 text-white items-right" }
const _hoisted_13 = {
  key: 0,
  class: "col-span-4 mt-4"
}
const _hoisted_14 = { class: "flex w-full items-center justify-stretch" }
const _hoisted_15 = { class: "flex w-full items-center justify-between" }
const _hoisted_16 = { class: "flex items-center" }
const _hoisted_17 = { class: "text-sm" }
const _hoisted_18 = { class: "shrink-0 text-white items-right" }
const _hoisted_19 = {
  key: 1,
  class: "col-span-4 flex border border-gray-100 border-solid p-2 pt-3 pb-2 -mt-3 mb-4 bg-gray-50"
}
const _hoisted_20 = {
  key: 0,
  class: "grow grid grid-cols-1 gap-y-4 gap"
}
const _hoisted_21 = { class: "text-xs text-gray-500 pt-3 block" }
const _hoisted_22 = {
  key: 1,
  class: "grow grid grid-cols-1 gap-y-4 gap-x-4"
}
const _hoisted_23 = { class: "text-xs text-gray-500 pt-3 block" }
const _hoisted_24 = {
  key: 2,
  class: "grow grid grid-cols-1 gap-y-4 gap-x-4 pt-2"
}
const _hoisted_25 = { class: "flex w-full items-center justify-stretch" }
const _hoisted_26 = { class: "flex w-full items-center justify-between" }
const _hoisted_27 = { class: "flex items-center" }
const _hoisted_28 = { class: "text-sm" }
const _hoisted_29 = { class: "shrink-0 text-white items-right" }
const _hoisted_30 = {
  key: 2,
  class: "col-span-4 flex border border-gray-100 border-solid p-1 pt-5 pb-1 -mt-3 bg-gray-50"
}
const _hoisted_31 = {
  key: 0,
  class: "grow grid grid-cols-1 gap-y-4 gap-x-4"
}
const _hoisted_32 = {
  key: 1,
  class: "grow grid grid-cols-1 gap-y-4 gap-x-4 ml-4"
}
const _hoisted_33 = ["value"]

import { computed } from "vue";
import { useFormFieldsCreationNewRun } from "@/tea/composables/runs/useFormFieldsCreationNewRun";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { useStore } from "vuex";
import { NEW_RUN_FIELDS } from "@/tea/models/run";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import { LightBulbIcon } from "@heroicons/vue/24/outline";

export default /*@__PURE__*/_defineComponent({
  __name: 'RunExtraction',
  props: ["errorsFields", "formSubmited", "formInizializationValues"],
  setup(__props) {

const yesNos = [
  { key: true, value: "Yes" },
  { key: false, value: "No" },
];
const moreSolvents = [0, 1, 2];

const store = useStore();

const props = __props;

const fields = computed(() => store.getters["runCreateModule/fields"]);

const isExtractionMethodPhysical = computed(() => {
  const emId = fields.value.extractionMethod;
  if (props.formInizializationValues["extractionMethodsOptions"] && emId != null) {
    if (props.formInizializationValues["extractionMethodsOptions"].find((item) => item.name == "Physical").id == emId) {
      return true;
    } else {
      return false;
    }
  }
  return null;
});
const isExtractionMethodChemical = computed(() => {
  const emId = fields.value.extractionMethod;
  if (props.formInizializationValues["extractionMethodsOptions"] && emId != null) {
    if (props.formInizializationValues["extractionMethodsOptions"].find((item) => item.name == "Chemical").id == emId) {
      return true;
    } else {
      return false;
    }
  }
  return null;
});
const isExtractionMethodBoth = computed(() => {
  const emId = fields.value.extractionMethod;
  if (props.formInizializationValues["extractionMethodsOptions"] && emId != null) {
    if (props.formInizializationValues["extractionMethodsOptions"].find((item) => item.name == "Both").id == emId) {
      return true;
    } else {
      return false;
    }
  }
  return null;
});

const isHarvestingMethodMechanical = computed(() => {
  const fId = fields.value.harvestMethod;
  if (props.formInizializationValues["harvestingMethodsOptions"] && fId != null) {
    if (props.formInizializationValues["harvestingMethodsOptions"].find((item) => item.name == "Mechanical").id == fId) {
      return true;
    } else {
      return false;
    }
  }
  return null;
});

const isSystemSSF = computed(() => {
  const csId = fields.value.cultivationSystem;
  if (props.formInizializationValues["cultivationSystemsOptions"] && csId != null) {
    if (props.formInizializationValues["cultivationSystemsOptions"].find((item) => item.name == "Solid-state fermentor (SSF)").id == csId) {
      return true;
    } else {
      // set biomass_target to 'Target compound'
      return false;
    }
  }
  return null;
});

const isSolventAcetone = computed(() => {
  const sId = fields.value.solvent;
  if (props.formInizializationValues["solventsOptions"] && sId != null) {
    if (props.formInizializationValues["solventsOptions"].find((item) => item.name == "Acetone").id == sId) {
      return true;
    } else {
      return false;
    }
  }
  return null;
});
const isSolventEthanol = computed(() => {
  const sId = fields.value.solvent;
  if (props.formInizializationValues["solventsOptions"] && sId != null) {
    if (props.formInizializationValues["solventsOptions"].find((item) => item.name == "Ethanol").id == sId) {
      return true;
    } else {
      return false;
    }
  }
  return null;
});
const isSolventAnotherSolvent = computed(() => {
  const sId = fields.value.solvent;
  if (props.formInizializationValues["solventsOptions"] && sId != null) {
    if (props.formInizializationValues["solventsOptions"].find((item) => item.name == "Another solvent").id == sId) {
      return true;
    } else {
      return false;
    }
  }
  return null;
});

const showmoreSolventX = computed(() => {
  const ms = Number(fields.value.moreSolvents);
  if (props.formInizializationValues["solventsOptions"] && ms > 0) {
    return true;
  }
  return false;
});
const showmoreSolventY = computed(() => {
  const ms = Number(fields.value.moreSolvents);
  if (props.formInizializationValues["solventsOptions"] && ms == 2) {
    return true;
  }
  return false;
});

const getFormFields = Object.keys(NEW_RUN_FIELDS.formFields).map((key) => {
  const fieldConfig = NEW_RUN_FIELDS.formFields[key];
  return {
    [key]: Array.isArray(fieldConfig.onChangeResetFields) ? fieldConfig.onChangeResetFields : [],
  };
});
const formFieldOptions = {
  fields: getFormFields,
  mutation: "runCreateModule/SET_SINGLE_RUN_VALUE",
};
const formFields = useFormFieldsCreationNewRun(formFieldOptions);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('extractionMethod') && __props.formSubmited }])
      }, [
        _cache[16] || (_cache[16] = _createElementVNode("label", null, "Which kind of extraction method are you going to use? *", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "w-full",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(formFields)['extractionMethod'].value) = $event))
        }, [
          _cache[15] || (_cache[15] = _createElementVNode("option", {
            selected: "",
            value: null
          }, null, -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.formInizializationValues['extractionMethodsOptions'], (extractionMethod) => {
            return (_openBlock(), _createElementBlock("option", {
              value: Number(extractionMethod.id),
              key: extractionMethod.id
            }, _toDisplayString(extractionMethod.name), 9, _hoisted_3))
          }), 128))
        ], 512), [
          [_vModelSelect, _unref(formFields)['extractionMethod'].value]
        ])
      ], 2),
      (isExtractionMethodPhysical.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (fields.value.residualBiomass || isHarvestingMethodMechanical.value || isSystemSSF.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["col-span-4 mb-2", { 'error-form-field': __props.errorsFields.includes('extractionBufferConc') && __props.formSubmited }])
                  }, [
                    _cache[17] || (_cache[17] = _createElementVNode("label", null, "Concentration extraction buffer (L/kg biomass) *", -1)),
                    _withDirectives(_createElementVNode("input", {
                      class: "w-full",
                      type: "number",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(formFields)['extractionBufferConc'].value) = $event))
                    }, null, 512), [
                      [_vModelText, _unref(formFields)['extractionBufferConc'].value]
                    ]),
                    _cache[18] || (_cache[18] = _createElementVNode("span", { class: "text-xs text-gray-400" }, "Note: if you chose Solid-state fermentor (SSF) as cultivation system the term 'biomass' stands for 'substrate'", -1))
                  ], 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('extractionBufferPrice') && __props.formSubmited }])
                  }, [
                    _cache[19] || (_cache[19] = _createElementVNode("label", null, "Price extraction buffer (€/L) *", -1)),
                    _withDirectives(_createElementVNode("input", {
                      class: "w-full",
                      type: "number",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(formFields)['extractionBufferPrice'].value) = $event))
                    }, null, 512), [
                      [_vModelText, _unref(formFields)['extractionBufferPrice'].value]
                    ])
                  ], 2)
                ]))
              : _createCommentVNode("", true)
          ]))
        : (isExtractionMethodChemical.value || isExtractionMethodBoth.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (isExtractionMethodBoth.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_unref(RadioGroup), {
                      modelValue: _unref(formFields)['extractionBuffer'].value,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(formFields)['extractionBuffer'].value) = $event)),
                      class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('extractionBuffer') && __props.formSubmited }])
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(RadioGroupLabel), { class: "mb-1" }, {
                          default: _withCtx(() => _cache[20] || (_cache[20] = [
                            _createTextVNode("Are you going to use an extraction buffer? *")
                          ])),
                          _: 1
                        }),
                        _createElementVNode("div", _hoisted_8, [
                          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(yesNos, (yesNo) => {
                            return _createVNode(_unref(RadioGroupOption), {
                              as: "template",
                              key: yesNo.value,
                              value: yesNo.key
                            }, {
                              default: _withCtx(({ active, checked }) => [
                                _createElementVNode("div", {
                                  class: _normalizeClass([[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white '], "relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"])
                                }, [
                                  _createElementVNode("div", _hoisted_9, [
                                    _createElementVNode("div", _hoisted_10, [
                                      _createElementVNode("div", _hoisted_11, [
                                        _createVNode(_unref(RadioGroupLabel), {
                                          as: "p",
                                          class: _normalizeClass([checked ? 'text-white' : 'text-gray-500', "font-base"])
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(yesNo.value), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["class"])
                                      ])
                                    ]),
                                    _withDirectives(_createElementVNode("div", _hoisted_12, _cache[21] || (_cache[21] = [
                                      _createElementVNode("svg", {
                                        class: "h-5 w-5",
                                        viewBox: "0 0 24 24",
                                        fill: "none"
                                      }, [
                                        _createElementVNode("circle", {
                                          cx: "12",
                                          cy: "12",
                                          r: "12",
                                          fill: "#fff",
                                          "fill-opacity": "0.2"
                                        }),
                                        _createElementVNode("path", {
                                          d: "M7 13l3 3 7-7",
                                          stroke: "#fff",
                                          "stroke-width": "1.5",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round"
                                        })
                                      ], -1)
                                    ]), 512), [
                                      [_vShow, checked]
                                    ])
                                  ])
                                ], 2)
                              ]),
                              _: 2
                            }, 1032, ["value"])
                          }), 64))
                        ])
                      ]),
                      _: 1
                    }, 8, ["modelValue", "class"]),
                    (fields.value.extractionBuffer)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('extractionBufferConc') && __props.formSubmited }])
                          }, [
                            _cache[22] || (_cache[22] = _createElementVNode("label", null, "Concentration extraction buffer (L/kg biomass) *", -1)),
                            _withDirectives(_createElementVNode("input", {
                              class: "w-full",
                              type: "number",
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(formFields)['extractionBufferConc'].value) = $event))
                            }, null, 512), [
                              [_vModelText, _unref(formFields)['extractionBufferConc'].value]
                            ]),
                            _cache[23] || (_cache[23] = _createElementVNode("span", { class: "text-xs text-gray-400" }, "Note: if you chose Solid-state fermentor (SSF) as cultivation system the term 'biomass' stands for 'substrate'", -1))
                          ], 2),
                          _createElementVNode("div", {
                            class: _normalizeClass(["col-span-4 mt-2", { 'error-form-field': __props.errorsFields.includes('extractionBufferPrice') && __props.formSubmited }])
                          }, [
                            _cache[24] || (_cache[24] = _createElementVNode("label", null, "Price extraction buffer (€/L) *", -1)),
                            _withDirectives(_createElementVNode("input", {
                              class: "w-full",
                              type: "number",
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(formFields)['extractionBufferPrice'].value) = $event))
                            }, null, 512), [
                              [_vModelText, _unref(formFields)['extractionBufferPrice'].value]
                            ])
                          ], 2)
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_unref(RadioGroup), {
                modelValue: _unref(formFields)['solvent'].value,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(formFields)['solvent'].value) = $event)),
                class: _normalizeClass(["col-span-4 mb-0 mt-4", { 'error-form-field': __props.errorsFields.includes('solvent') && __props.formSubmited }])
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(RadioGroupLabel), { class: "mb-1" }, {
                    default: _withCtx(() => _cache[25] || (_cache[25] = [
                      _createTextVNode("Solvent *")
                    ])),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_14, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.formInizializationValues['solventsOptions'], (solvent) => {
                      return (_openBlock(), _createBlock(_unref(RadioGroupOption), {
                        as: "template",
                        key: solvent.id,
                        value: solvent.id
                      }, {
                        default: _withCtx(({ active, checked }) => [
                          _createElementVNode("div", {
                            class: _normalizeClass([[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white '], "relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"])
                          }, [
                            _createElementVNode("div", _hoisted_15, [
                              _createElementVNode("div", _hoisted_16, [
                                _createElementVNode("div", _hoisted_17, [
                                  _createVNode(_unref(RadioGroupLabel), {
                                    as: "p",
                                    class: _normalizeClass([checked ? 'text-white' : 'text-gray-500', "font-base"])
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(solvent.name), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["class"])
                                ])
                              ]),
                              _withDirectives(_createElementVNode("div", _hoisted_18, _cache[26] || (_cache[26] = [
                                _createElementVNode("svg", {
                                  class: "h-5 w-5",
                                  viewBox: "0 0 24 24",
                                  fill: "none"
                                }, [
                                  _createElementVNode("circle", {
                                    cx: "12",
                                    cy: "12",
                                    r: "12",
                                    fill: "#fff",
                                    "fill-opacity": "0.2"
                                  }),
                                  _createElementVNode("path", {
                                    d: "M7 13l3 3 7-7",
                                    stroke: "#fff",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  })
                                ], -1)
                              ]), 512), [
                                [_vShow, checked]
                              ])
                            ])
                          ], 2)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ])
                ]),
                _: 1
              }, 8, ["modelValue", "class"]),
              (fields.value.solvent !== null)
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    (isSolventAcetone.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                          _createElementVNode("p", _hoisted_21, [
                            _createVNode(_unref(LightBulbIcon), { class: "icon icon-small inline-block" }),
                            _cache[27] || (_cache[27] = _createTextVNode(" The reference price of acetone is 0.45 €/L. If this reference does not correspond please select \"Another solvent\" "))
                          ])
                        ]))
                      : (isSolventEthanol.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                            _createElementVNode("p", _hoisted_23, [
                              _createVNode(_unref(LightBulbIcon), { class: "icon icon-small inline-block" }),
                              _cache[28] || (_cache[28] = _createTextVNode(" The reference price of ethanol is 0.55 €/L. If this reference does not correspond please select \"Another solvent\" "))
                            ])
                          ]))
                        : (isSolventAnotherSolvent.value)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                              _createElementVNode("div", {
                                class: _normalizeClass(["col-span-1", { 'error-form-field': __props.errorsFields.includes('otherSolventPrice') && __props.formSubmited }])
                              }, [
                                _cache[29] || (_cache[29] = _createElementVNode("label", null, "Price solvent (€/L) *", -1)),
                                _withDirectives(_createElementVNode("input", {
                                  class: "w-full",
                                  type: "number",
                                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(formFields)['otherSolventPrice'].value) = $event))
                                }, null, 512), [
                                  [_vModelText, _unref(formFields)['otherSolventPrice'].value]
                                ])
                              ], 2)
                            ]))
                          : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(["col-span-1 mb-4", { 'error-form-field': __props.errorsFields.includes('solventConc') && __props.formSubmited }])
              }, [
                _cache[30] || (_cache[30] = _createElementVNode("label", null, "Solvent concentration (L/kg biomass) *", -1)),
                _withDirectives(_createElementVNode("input", {
                  class: "w-full",
                  type: "number",
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(formFields)['solventConc'].value) = $event))
                }, null, 512), [
                  [_vModelText, _unref(formFields)['solventConc'].value]
                ]),
                _cache[31] || (_cache[31] = _createElementVNode("span", { class: "text-xs text-gray-400" }, "Note: if you chose Solid-state fermentor (SSF) as cultivation system the term 'biomass' stands for 'substrate'", -1))
              ], 2),
              _createVNode(_unref(RadioGroup), {
                modelValue: _unref(formFields)['moreSolvents'].value,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(formFields)['moreSolvents'].value) = $event)),
                class: _normalizeClass(["col-span-4 mb-0 mt-2", { 'error-form-field': __props.errorsFields.includes('moreSolvents') && __props.formSubmited }])
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(RadioGroupLabel), { class: "mb-1" }, {
                    default: _withCtx(() => _cache[32] || (_cache[32] = [
                      _createTextVNode("How many additional solvents are you going to use? *")
                    ])),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_25, [
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(moreSolvents, (solvent) => {
                      return _createVNode(_unref(RadioGroupOption), {
                        as: "template",
                        key: solvent,
                        value: solvent
                      }, {
                        default: _withCtx(({ active, checked }) => [
                          _createElementVNode("div", {
                            class: _normalizeClass([[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white '], "relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"])
                          }, [
                            _createElementVNode("div", _hoisted_26, [
                              _createElementVNode("div", _hoisted_27, [
                                _createElementVNode("div", _hoisted_28, [
                                  _createVNode(_unref(RadioGroupLabel), {
                                    as: "p",
                                    class: _normalizeClass([checked ? 'text-white' : 'text-gray-500', "font-base"])
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(solvent), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["class"])
                                ])
                              ]),
                              _withDirectives(_createElementVNode("div", _hoisted_29, _cache[33] || (_cache[33] = [
                                _createElementVNode("svg", {
                                  class: "h-5 w-5",
                                  viewBox: "0 0 24 24",
                                  fill: "none"
                                }, [
                                  _createElementVNode("circle", {
                                    cx: "12",
                                    cy: "12",
                                    r: "12",
                                    fill: "#fff",
                                    "fill-opacity": "0.2"
                                  }),
                                  _createElementVNode("path", {
                                    d: "M7 13l3 3 7-7",
                                    stroke: "#fff",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  })
                                ], -1)
                              ]), 512), [
                                [_vShow, checked]
                              ])
                            ])
                          ], 2)
                        ]),
                        _: 2
                      }, 1032, ["value"])
                    }), 64))
                  ])
                ]),
                _: 1
              }, 8, ["modelValue", "class"]),
              (showmoreSolventX.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                    (showmoreSolventX.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["col-span-1", { 'error-form-field': __props.errorsFields.includes('moreSolventXConc') && __props.formSubmited }])
                          }, [
                            _cache[34] || (_cache[34] = _createElementVNode("label", null, "Solvent #2 concentration (L/kg biomass) *", -1)),
                            _withDirectives(_createElementVNode("input", {
                              class: "w-full",
                              type: "number",
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(formFields)['moreSolventXConc'].value) = $event))
                            }, null, 512), [
                              [_vModelText, _unref(formFields)['moreSolventXConc'].value]
                            ])
                          ], 2),
                          _createElementVNode("div", {
                            class: _normalizeClass(["col-span-1", { 'error-form-field': __props.errorsFields.includes('moreSolventXPrice') && __props.formSubmited }])
                          }, [
                            _cache[35] || (_cache[35] = _createElementVNode("label", null, "Price solvent #2 (€/L) *", -1)),
                            _withDirectives(_createElementVNode("input", {
                              class: "w-full",
                              type: "number",
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_unref(formFields)['moreSolventXPrice'].value) = $event))
                            }, null, 512), [
                              [_vModelText, _unref(formFields)['moreSolventXPrice'].value]
                            ])
                          ], 2)
                        ]))
                      : _createCommentVNode("", true),
                    (showmoreSolventY.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["col-span-1", { 'error-form-field': __props.errorsFields.includes('moreSolventYConc') && __props.formSubmited }])
                          }, [
                            _cache[36] || (_cache[36] = _createElementVNode("label", null, "Solvent #3 concentration (L/kg biomass) *", -1)),
                            _withDirectives(_createElementVNode("input", {
                              class: "w-full",
                              type: "number",
                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_unref(formFields)['moreSolventYConc'].value) = $event))
                            }, null, 512), [
                              [_vModelText, _unref(formFields)['moreSolventYConc'].value]
                            ])
                          ], 2),
                          _createElementVNode("div", {
                            class: _normalizeClass(["col-span-1", { 'error-form-field': __props.errorsFields.includes('moreSolventYPrice') && __props.formSubmited }])
                          }, [
                            _cache[37] || (_cache[37] = _createElementVNode("label", null, "Price solvent #3 (€/L) *", -1)),
                            _withDirectives(_createElementVNode("input", {
                              class: "w-full",
                              type: "number",
                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_unref(formFields)['moreSolventYPrice'].value) = $event))
                            }, null, 512), [
                              [_vModelText, _unref(formFields)['moreSolventYPrice'].value]
                            ])
                          ], 2)
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('dryMethod') && __props.formSubmited }])
      }, [
        _cache[39] || (_cache[39] = _createElementVNode("label", null, "Which kind of drying method are you going to use? *", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "w-full",
          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_unref(formFields)['dryMethod'].value) = $event))
        }, [
          _cache[38] || (_cache[38] = _createElementVNode("option", {
            selected: "",
            value: null
          }, null, -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.formInizializationValues['dryingMethodsOptions'], (dryMethod) => {
            return (_openBlock(), _createElementBlock("option", {
              value: Number(dryMethod.id),
              key: dryMethod.id
            }, _toDisplayString(dryMethod.name), 9, _hoisted_33))
          }), 128))
        ], 512), [
          [_vModelSelect, _unref(formFields)['dryMethod'].value]
        ])
      ], 2)
    ])
  ]))
}
}

})