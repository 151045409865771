<template>
    <div v-if="layers && inizilizedData" class="layer-form">
        <div class="border-t mt-6 pb-8">
            <div class="sticky top-0 bg-white pt-6 pb-4 border-b z-10">
                <div class="flex justify-between items-center">
                    <p class="px-6 cards-title text-neutral-500">Layers layout</p>
                    <div v-if="!onEdit" class="flex items-center pr-6 cursor-pointer" @click="addLayer()">
                        <label class="text-neutral-400 mr-2 cursor-pointer">Add new layer </label>
                        <PlusCircleIcon class="icon icon-small" />
                    </div>
                    <Popover v-if="onEdit" class="flex justify-end relative pr-6">
                        <PopoverButton class="flex items-center">
                            <label class="text-neutral-400 mr-2 cursor-pointer">Add new layer </label>
                            <PlusCircleIcon class="icon icon-small" />
                        </PopoverButton>
                        <PopoverPanel class="absolute z-10 bg-white rounded top-8 left-0 border-gray-200 border min-w-32">
                            <div class="grid grid-cols-1 divide-y">
                                <div @click="addLayer('top')" class="py-2 pl-3 pr-6 font-normal text-xs cursor-pointer flex items-center hover:bg-gray-100">
                                    <ArrowUpIcon class="icon icon-x-small thick mr-2" />
                                    <p>Top</p>
                                </div>
                                <div @click="addLayer()" class="py-2 pl-3 pr-6 font-normal text-xs cursor-pointer flex items-center hover:bg-gray-100">
                                    <ArrowDownIcon class="icon icon-x-small thick mr-2" />
                                    <p>Bottom</p>
                                </div>
                            </div>
                        </PopoverPanel>
                    </Popover> 
                </div>
                <!-- Design --> 
                <!-- {{ layersData }} -->
                <div v-if="onEdit && layersData" class="flex justify-center ">
                    <LayersDesignGenerator :layersData="layersData" class="design-draw" />
                </div>
                <!-- {{ layers }} -->
                <!-- Disclaimer -->
                <div v-if="layers.length > 1 && !onEdit" class="bg-neutral-100 p-4 mx-6 flex items-center mt-2">
                    <InformationCircleIcon class="icon icon-small thick mr-2" />
                    <p class="text-xs">Layer number 1 is going to be the upper layer in the design</p>
                </div>
            </div>
            <!-- layers form-->
            <div v-for="(layer, index) in layers" :key="index"
                class="flex mt-6 items-center justify-center border-dashed border-b relative" :class="{'to-delete': onEdit && layersToDelete.includes(layer.id)}">
                <div v-if="index > 0 && !onEdit" class="absolute top-0 right-0 mr-6" @click="removeLayer(index)">
                    <XCircleIcon class="icon icon-small thick" />
                </div>
                <div v-if="onEdit && layersToDelete.includes(layer.id)" class="absolute top-0 right-0 mr-6" @click="removeLayerToRemove(layer.id)">
                    <ArrowUturnLeftIcon class="icon icon-small thick" />
                </div>
                <div v-if="onEdit && !layersToDelete.includes(layer.id)" class="absolute top-0 right-0 mr-6" @click="addLayerToRemove(layer.id, index)">
                    <XCircleIcon class="icon icon-small thick" />
                </div>
                <div class="flex flex-col pl-6 py-4 w-5/6" :class="{'opacity-50': onEdit && layersToDelete.includes(layer.id)}">
                    <div class="flex items-center justify-center mb-3">
                        <label class="w-1/3 text-right">Layer type: *</label>
                        <select v-model="layer.layerType" class="w-2/3 ml-3">
                            <option v-for="layerType in layerTypes" :key="layerType.id" :value="layerType.id">
                                {{ layerType.name }}
                            </option>
                        </select>
                    </div>
                    <div class="flex items-center justify-center mb-3">
                        <label class="w-1/3 text-right">Thickness value type: *</label>
                        <select v-model="layer.thicknessValueType" class="w-2/3 ml-3">
                            <option v-for="valueType in valueTypes" :key="valueType.id" :value="valueType.id">
                                {{ valueType.description }}
                            </option>
                        </select>
                    </div>
                    <div class="flex items-center justify-center mb-3">
                        <label class="w-1/3 text-right">Value: *</label>
                        <input class="w-2/3 ml-3" type="text" v-model.number="layer.thicknessValue1">
                        <template v-if="layer.thicknessValueType && isValueTypeBetween(layer.thicknessValueType)">
                            <label class="w-1/3 text-right">Value 2: </label>
                            <input class="w-2/3 ml-3" type="text" v-model.number="layer.thicknessValue2">
                        </template>
                    </div>
                    <div class="flex items-center justify-center mb-3">
                        <label class="w-1/3 text-right">Unit: *</label>
                        <select v-model="layer.thicknessUnitType" class="w-2/3 ml-3">
                            <option v-for="unit in units" :key="unit.id" :value="unit.id">
                                {{ unit.abbreviation }} - {{ unit.name }}
                            </option>
                        </select>
                    </div>
                    <div class="flex items-center justify-center mb-3">
                        <label class="w-1/3 text-right">Technology:</label>
                        <div class="w-2/3 ml-3">
                            <DropdownSearchableSingle v-model="layer.asset" :options-list="assets" />
                        </div>
                    </div>
                    <div class="flex items-center justify-center mb-3">
                        <label class="w-1/3 text-right">Description:</label>
                        <input class="w-2/3 ml-3" type="text" v-model="layer.description">
                    </div>
                    <div v-if="onEdit" class="flex items-center justify-end mb-3">
                        <div class="flex items-center">
                            <label class="text-right">Design Thickness:</label>
                            <input class="ml-3 mr-4" type="number" v-model="layer.visualThickness">
                        </div>
                        <Popover v-if="onEdit" class="flex justify-end relative">
                            <PopoverButton class="flex items-center">
                                <label class="text-right ml-4 mr-4">Color:</label>
                                <div class="flex-shrink-0 color-dot mr-3" :style="{backgroundColor: layer.colorCode }"></div>
                            </PopoverButton>
                            <PopoverPanel class="absolute z-10">
                                <Vue3ColorPicker v-model="layer.colorCode" mode="solid" :show-alpha="false" :showInputSet="false" :showInputMenu="false" type="HEX" />
                            </PopoverPanel>
                        </Popover>    
                    </div>
                    
                    <div>
                        
                    </div>
                </div>
                <div :class="{'opacity-0': onEdit}" class="w-1/6 pr-6 text-center number">{{ index + 1 }}</div>
            </div>

        </div>
    </div>
</template>  


<script setup lang="ts">
import { ref, computed, defineProps, onMounted } from 'vue';
import { PlusCircleIcon, InformationCircleIcon, XCircleIcon, ArrowUturnLeftIcon, ArrowUpIcon, ArrowDownIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';
import { NEW_PROGRAM_VERSIONS_DESIGN_QUERIES } from '@/grapql/newProgramVersionsDesignQueries';
import { useQuery } from '@vue/apollo-composable';
import { LayerCreateInterface, LayersFromBEInterface, ValueTypeInterface } from '@/models/programVersionsInterface';
import DropdownSearchableSingle from './DropdownSearchableSingle.vue';
import LayersDesignGenerator from './LayersDesignGenerator.vue';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { layerDataInterface } from '@/models/programsInterface';
import { calcThickness } from '@/composables/useLayerThicknessToVisualThickness';
import {Vue3ColorPicker} from '@cyhnkckali/vue3-color-picker';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'


const props = defineProps<{
    onEdit?: boolean;
    designId?: number
}>();

const store = useStore();

// Inizialize values for new desing
const layers = computed(() => !props.onEdit ? store.state['programVersionDetailModule'].newDesign.layers : store.state['programVersionDetailModule'].designForEdition.layers);
const layersToDelete = computed(() => store.state['programVersionDetailModule'].layersToDelete );

const inizializeLayers = () => !layers.value.length ? addLayer() : [];

const layerToPreview = computed(() => props.onEdit ? store.state['programVersionDetailModule'].designForEdition.layers.filter((layer: layerDataInterface) => !layersToDelete.value.includes(layer.id)) : []);
const layersData = computed<layerDataInterface[]>(() => layerToPreview.value.length ? layerToPreview.value.map((layer: LayersFromBEInterface, index: number) => ({
    thickness: layer.visualThickness ? layer.visualThickness : calcThickness(layer.thicknessValue1),
    color: layer.colorCode ? layer.colorCode : null,
    id: layer.id,
    position: layer.layerOrder ? layer.layerOrder : index,
})) : null)


const addLayer = (position?: string) => {
    const newLayer = ref<LayerCreateInterface>({
        layerType: null,
        thicknessValueType: null,
        thicknessValue1: null,
        thicknessValue2: null,
        thicknessUnitType: null,
        asset: null,
        description: null,
        visualThickness: props.onEdit ? 2 : null,
        colorCode: props.onEdit ? '#548fb9' : null,
        layerOrder: null
    })
    position && position == 'top' ? layers.value.unshift(newLayer.value) : layers.value.push(newLayer.value);
}
const removeLayer = (index: number) => layers.value.splice(index, 1);

const addLayerToRemove = (id: number, index: number) => {
    layerToPreview.value.splice(index, 1);
    layersToDelete.value.push(id);
}

const removeLayerToRemove = (id: number) => layersToDelete.value.splice(layersToDelete.value.indexOf(id), 1)

// Inizialize the form
const { result } = useQuery(NEW_PROGRAM_VERSIONS_DESIGN_QUERIES);
const inizilizedData = computed(() => result?.value ?? null);
const units = computed(() => inizilizedData.value && inizilizedData.value.unitTypes ? inizilizedData.value.unitTypes.edges.map(edge => edge.node) : []);
const valueTypes = computed(() => inizilizedData.value && inizilizedData.value.valueTypes ? inizilizedData.value.valueTypes.edges.map(edge => edge.node) : []);
const layerTypes = computed(() => inizilizedData.value && inizilizedData.value.layerTypes ? inizilizedData.value.layerTypes.edges.map(edge => edge.node) : []);
const assets = computed<DropdownSearchableOptionInterface[]>(() => inizilizedData.value && inizilizedData.value.assets ? inizilizedData.value.assets.edges.map(({ node: { nickname: name, ...rest } }) => ({ name, id: rest.id })) : []);

const isValueTypeBetween = (valueTypeId: number) => valueTypes.value.find((valueType: ValueTypeInterface) => valueType.id == valueTypeId).name == "Between"

onMounted(() => {
    if (!props.onEdit) {
        inizializeLayers();
    }
})
</script>
<style lang="scss" scoped>
.number {
    color: $grey-modal;
    font-family: $secondary-font;
    font-size: 5rem;
}
.design-draw {
    width: 200px;
}
.color-dot {
    border: 4px solid #f5f5f5;
    width: 22px;
    height: 22px;
    border-radius: 50px;
}
</style>