<template>
    <div class="grid grid-cols-7 gap-2">
        <!-- DESIGN CARD -->
        <div v-if="designData" class="col-span-5 program-detail-card mb-4 relative">
            <!-- Status and title, subtile -->
            <div class="status-dot" :class="[designData.locked_design == 'No' ? 'archived' : 'wip']"></div>
            <div class="status-text wip" v-if="designData.locked_design == 'Yes' ">work in progress</div>
            <div class="status-text archived" v-else-if="designData.locked_design == 'No'">archived</div>
            <p class="cards-title ml-4 mt-10">{{ designData.pro_mat_name }}</p>
            <p v-if="designData.description" v-html="designData.description" class="px-4 text-xs -mt-2"></p>
            
            <!-- Design and info -->
            <div class="mt-10 mb-4 px-2 grid grid-cols-9 items-center justify-around">
                
                <!-- Layer Type -->
                <div class="col-span-2 flex flex-col justify-evenly pb-12 pt-4 items-end uppercase font-normal text-xs text-right">
                    <template v-for="layer in parsedLayersForText" :key="layer.layer_order">
                        <p v-if="layer.layer_type != 'NAN'" class="mb-2">{{ layer.layer_type }}</p>
                        <p v-else class="mb-2 text-white">.</p>
                    </template>
                </div>

                <!-- Sandwich -->
                <div v-if="parsedLayersData" class="col-span-3 mt-4 justify-center flex">
                    <LayersDesignGenerator :layers-data="parsedLayersData" />
                </div>

                <!-- Description y asset -->
                <div class="col-span-4 flex flex-col justify-evenly pt-4 pb-12 text-sm">
                    <div v-for="layer in parsedLayersForText" :key="layer.layer_order" class="mb-2 flex justify-between mr-8">
                        <p v-if="layer.description != 'NAN'" @click="goToAsset(layer.description)" class="mr-2 inline-block font-medium cursor-pointer text-black underline underline-offset-2">{{ layer.description }}</p>
                        <p v-else class="text-white">.</p>
                        <p v-if="layer.gm_value != 'nan'" class="inline-block text-black font-medium"> {{ layer.gm_value }} gsm</p>
                    </div>
                </div>
            </div>
            <div class="col-span-5 border-t">
                <div class="grid grid-flow-col divide-x">
                    <div class="py-3 pl-6 flex flex-col">
                        <p class="font-medium text-black">{{ designData.paper_content }} %</p>
                        <p class="uppercase font-normal text-xs">Paper content</p>
                    </div>
                    <div v-if="designData.sealing_sides" class="py-3 pl-6 flex flex-col">
                        <p class="font-medium text-black">{{ designData.sealing_sides }}</p>
                        <p class="uppercase font-normal text-xs">sealing sides</p>
                    </div>
                    <div class="py-3 pl-6 flex flex-col">
                        <p class="font-medium text-black">{{ Number(designData.total_grammage).toFixed(2) }}</p>
                        <p class="uppercase font-normal text-xs">total grammage</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- OTR - WVTR - HS -->
        <div class="col-span-2 program-detail-card mb-4 mr-4">
            <div class="grid grid-rows-7 h-full">
                <!-- OTR -->
                <div class="row-span-3 flex border-b">
                    <div class="w-1/6 flex justify-center items-center border-r border-dashed">
                        <p class="-rotate-90 font-medium text-black font-medium text-black">OTR</p>
                    </div>
                    <div class="w-5/6 flex flex-col justify-center">
                        <div :class="{'hidden': !designData.min_otr_23_0}" class="flex justify-around">
                            <div class="flex flex-col items-center justify-center leading-3 py-1">
                                <p class="lowercase text-xs text-gray-400">MIN</p>
                                <p class="text-black font-medium">{{ Number(designData.min_otr_23_0).toFixed(1) }}</p> 
                            </div> 
                            <div class="flex flex-col items-center justify-center leading-3 py-1">
                                <p class="lowercase text-xs text-gray-400">max</p>
                                <p class="text-black font-medium">{{ Number(designData.max_otr_23_0).toFixed(1) }}</p> 
                            </div> 
                        </div>
                        <p :class="{'hidden': !designData.min_otr_23_0}" class="text-xs border-y text-center text-black">T: 23°C  -  RH: 0%</p>
                        <div :class="{'hidden': !designData.min_otr_23_50}" class="flex justify-around">
                            <div class="flex flex-col items-center justify-center leading-3 py-1">
                                <p class="lowercase text-xs text-gray-400">MIN</p>
                                <p class="text-black font-medium">{{ Number(designData.min_otr_23_50).toFixed(1) }}</p> 
                            </div> 
                            <div class="flex flex-col items-center justify-center leading-3 py-1">
                                <p class="lowercase text-xs text-gray-400">max</p>
                                <p class="text-black font-medium">{{ Number(designData.max_otr_23_50).toFixed(1) }}</p> 
                            </div> 
                        </div>
                        <p :class="{'hidden': !designData.min_otr_23_50}" class="text-xs border-y text-center">T: 23°C  -  RH: 50%</p>
                        <div :class="{'hidden': !designData.min_otr_38_90}" class="flex justify-around">
                            <div class="flex flex-col items-center justify-center leading-3 py-1">
                                <p class="lowercase text-xs text-gray-400">MIN</p>
                                <p class="text-black font-medium">{{ Number(designData.min_otr_38_90).toFixed(1) }}</p> 
                            </div> 
                            <div class="flex flex-col items-center justify-center leading-3 py-1">
                                <p class="lowercase text-xs text-gray-400">max</p>
                                <p class="text-black font-medium">{{ Number(designData.max_otr_38_90).toFixed(1) }}</p> 
                            </div> 
                        </div>
                        <p :class="{'hidden': !designData.min_otr_38_90}" class="text-xs border-t border-b-0 text-center">T: 38°C  -  RH: 90%</p>
                    </div>
                </div>
                <!-- WVTR -->
                <div class="row-span-3 flex border-b">
                    <div class="w-1/6 flex justify-center items-center border-r border-dashed">
                        <p class="-rotate-90 font-medium text-black">WVTR</p>
                    </div>
                    <div class="w-5/6 flex flex-col justify-center">
                        <div :class="{'hidden': !designData.min_wvtr_23_50}" class="flex justify-around">
                            <div class="flex flex-col items-center justify-center leading-3 py-1">
                                <p class="lowercase text-xs text-gray-400">MIN</p>
                                <p class="text-black font-medium">{{ Number(designData.min_wvtr_23_50).toFixed(1) }}</p> 
                            </div> 
                            <div class="flex flex-col items-center justify-center leading-3 py-1">
                                <p class="lowercase text-xs text-gray-400">max</p>
                                <p class="text-black font-medium">{{ Number(designData.max_wvtr_23_50).toFixed(1) }}</p> 
                            </div> 
                        </div>
                        <p :class="{'hidden': !designData.min_wvtr_23_50}" class="text-xs border-y text-center">T: 23°C  -  RH: 50%</p>
                        <div :class="{'hidden': !designData.min_wvtr_23_85}" class="flex justify-around">
                            <div class="flex flex-col items-center justify-center leading-3 py-1">
                                <p class="lowercase text-xs text-gray-400">MIN</p>
                                <p class="text-black font-medium">{{ Number(designData.min_wvtr_23_85).toFixed(1) }}</p> 
                            </div> 
                            <div class="flex flex-col items-center justify-center leading-3 py-1">
                                <p class="lowercase text-xs text-gray-400">max</p>
                                <p class="text-black font-medium">{{ Number(designData.max_wvtr_23_85).toFixed(1) }}</p> 
                            </div> 
                        </div>
                        <p :class="{'hidden': !designData.min_wvtr_23_85}" class="text-xs border-y text-center">T: 23°C  -  RH: 85%</p>
                        <div :class="{'hidden': !designData.min_wvtr_38_90}" class="flex justify-around">
                            <div class="flex flex-col items-center justify-center leading-3 py-1">
                                <p class="lowercase text-xs text-gray-400">MIN</p>
                                <p class="text-black font-medium">{{ Number(designData.min_wvtr_38_90).toFixed(1) }}</p> 
                            </div> 
                            <div class="flex flex-col items-center justify-center leading-3 py-1">
                                <p class="lowercase text-xs text-gray-400">max</p>
                                <p class="text-black font-medium">{{ Number(designData.max_wvtr_38_90).toFixed(1) }}</p> 
                            </div> 
                        </div>
                        <p :class="{'hidden': !designData.min_wvtr_38_90}" class="text-xs border-y text-center">T: 38°C  -  RH: 90%</p>
                    </div>
                </div>
                <!-- HS -->
                <div class="row-span-1 flex">
                    <div class="w-1/6 flex justify-center items-center border-r border-dashed">
                        <p class="-rotate-90 font-medium text-black">HS</p>
                    </div>
                    <div class="w-5/6 flex flex-col justify-between">
                        <div :class="{'hidden': !designData.hs_max_tech_reps}" class="flex flex-col items-center justify-center h-full py-1">
                            <p class="text-black font-medium">{{ Number(designData.hs_max_tech_reps).toFixed(1) }}</p> 
                        </div>
                        <p :class="{'hidden': !designData.hs_max_tech_reps}" class="text-xs border-t text-center uppercase">{{ hsConditions }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { defineProps, computed, ref } from "vue";
import LayersDesignGenerator from "./LayersDesignGenerator.vue";
import { layerDataInterface } from "@/models/programVersionsInterface";
import { useRouter } from "vue-router";
import { calcThickness } from "@/composables/useLayerThicknessToVisualThickness";
import { HasuraDesignsInterface, HasuraLayersInterface } from '@/models/hasuraModels';
import { useQuery } from "@vue/apollo-composable";
import { BENCHLINK_ASSET_QUERY } from "@/grapql/assetsQuery";



const props = defineProps<{ designData: HasuraDesignsInterface }>();

const router = useRouter();

const parsedLayers = computed<HasuraLayersInterface[]>(() => props.designData && props.designData.layers != "" ? JSON.parse(props.designData.layers) : []);

const parsedUsefulLayers = computed<HasuraLayersInterface[]>(() => parsedLayers.value.filter(layer => layer.description != "NAN" && layer.gm_value != 'NAN')) 

const parsedLayersForText = computed(() => parsedLayers.value.toReversed());

const colors = ['#d4c7c1', '#76b3b6', '#eea39b', '#badfd9','#5fa2a6', '#2a87d3', '#7d91bc']

const parsedLayersData = computed<layerDataInterface[]>(() => parsedLayers.value.length ?
    parsedUsefulLayers.value.map((layer, index) => ({
        thickness: layer.gm_value == "nan" 
            ? 3
            : calcThickness(layer.gm_value),
        color: colors[layer.layer_order -1 ],
        id: layer.layer_order,
        position: parsedLayers.value.length - index,
    }))
    : []
);

const hsConditions = "<= 0.5s - <= 180°C - 350-450 kPa";

const goToAsset = (assetNickname: string) => {
    const assetId = ref(null);
    const parsedNickname = assetNickname.includes('_') ? assetNickname.slice(0, assetNickname.indexOf('_')) : assetNickname;
    const {onResult} = useQuery(BENCHLINK_ASSET_QUERY, {filter: {nickname: {icontains: parsedNickname}}} )
    onResult(queryResults => {
        if(queryResults.data) {
            assetId.value = queryResults.data && queryResults.data.otherTechnologies.edges.length ? queryResults.data.otherTechnologies.edges[0].node.id : null;
            if (assetId.value) {
                router.push({ name: "TechnologyAssetDetail", params: { id: `other-${assetId.value}` }})
            }
        }
    }) 
};



</script>
<style lang="scss" scoped>
.program-detail-card {
    padding: 0;
}

.status-dot {
    border-color: $grey-light;
    top: -6px;
    left: -10px;
    background-color: #548fb9;
    &.wip {
        background-color: #548fb9;
    }
    &.archived {
        background-color: $moss;
    }
}

.status-text {
    position: absolute;
    top: 9px;
    left: 15px;
    text-transform: uppercase;
    font-size: 0.7rem;
    font-weight: 700;
    color: #548fb9;
    &.wip {
        color: #548fb9;
    }
    &.archived {
        color: $moss;
    }
}

.grammage {
    color: $font-color;
    text-align: right;
    font-size: 0.9rem;
    font-weight: 600;
}

.size-medium {
    font-size: 0.9rem;
}

.size-small {
    font-size: 0.8rem;
}
</style>
