import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "col-span-4" }
const _hoisted_2 = { class: "grid grid-cols-4 gap-y-4 gap-x-4" }
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  key: 0,
  class: "col-span-4 grid grid-cols-3 gap-y-4 gap-x-4"
}
const _hoisted_5 = {
  key: 1,
  class: "col-span-4 bg-gray-100 p-2 py-5 rounded-md"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "flex w-full items-center justify-stretch" }
const _hoisted_9 = { class: "flex w-full items-center justify-between" }
const _hoisted_10 = { class: "flex items-center" }
const _hoisted_11 = { class: "text-sm" }
const _hoisted_12 = { class: "shrink-0 text-white items-right" }
const _hoisted_13 = {
  key: 2,
  class: "col-span-4 flex border border-gray-100 border-solid p-1 pt-3 pb-0 -mt-3 bg-gray-50"
}
const _hoisted_14 = { class: "flex w-full items-center justify-stretch" }
const _hoisted_15 = { class: "flex w-full items-center justify-between" }
const _hoisted_16 = { class: "flex items-center" }
const _hoisted_17 = { class: "text-sm" }
const _hoisted_18 = { class: "shrink-0 text-white items-right" }

import { computed } from "vue";
import { useFormFieldsCreationNewRun } from "@/tea/composables/runs/useFormFieldsCreationNewRun";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { useStore } from "vuex";
import { NEW_RUN_FIELDS } from "@/tea/models/run";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import { NewspaperIcon } from "@heroicons/vue/24/outline";


export default /*@__PURE__*/_defineComponent({
  __name: 'RunResidual',
  props: ["errorsFields", "formSubmited", "formInizializationValues"],
  setup(__props) {

const biomassTargets = ["Target compound", "Biomass"];
const bycompounds = [0, 1, 2, 3];
const landCapexes = [
  { key: "Buying", value: "Buying" },
  { key: "Renting", value: "Renting" },
];

const store = useStore();

const props = __props;

const fields = computed(() => store.getters["runCreateModule/fields"]);
const ocsGoodCombo = computed(() => {
  const combo = [fields.value.organism, fields.value.cultivationSystem];
  if (combo[0] != null && combo[1] != null) {
    const isGoodCombo = props.formInizializationValues["ocsGoodCombos"].some((tuple) => tuple[0] === combo[0] && tuple[1] === combo[1]);
    return isGoodCombo;
  }
  return null;
});

const showFirstInfo = computed(() => {
  if (fields.value.compoundPct) {
    if (fields.value.biomassTarget == "Target compound" && fields.value.targetCompoundCapacity) {
      return true;
    } else if (fields.value.biomassTarget == "Biomass" && fields.value.biomass) {
      return true;
    }
  }
  if (ocsGoodCombo.value && fields.value.targetCompoundCapacity) {
    return true;
  }
  return false;
});

const showbycompoundX = computed(() => {
  const bc = Number(fields.value.bycompounds);
  if (props.formInizializationValues["organismsOptions"] && bc > 0) {
    return true;
  }
  return false;
});
const showbycompoundY = computed(() => {
  const bc = Number(fields.value.bycompounds);
  if (props.formInizializationValues["organismsOptions"] && bc > 1) {
    return true;
  }
  return false;
});
const showbycompoundZ = computed(() => {
  const bc = Number(fields.value.bycompounds);
  if (props.formInizializationValues["organismsOptions"] && bc == 3) {
    return true;
  }
  return false;
});

const calcTargetCompoundPerYear = computed(() => {
  const tcc = fields.value.targetCompoundCapacity;
  const cpct = fields.value.compoundPct;
  return Math.round(tcc / (cpct / 100));
});
const calcBiomassPerYear = computed(() => {
  const biomass = fields.value.biomass;
  const cpct = fields.value.compoundPct;
  return Math.round(biomass * (cpct / 100));
});

const getFormFields = Object.keys(NEW_RUN_FIELDS.formFields).map((key) => {
  const fieldConfig = NEW_RUN_FIELDS.formFields[key];
  return {
    [key]: Array.isArray(fieldConfig.onChangeResetFields) ? fieldConfig.onChangeResetFields : [],
  };
});
const formFieldOptions = {
  fields: getFormFields,
  mutation: "runCreateModule/SET_SINGLE_RUN_VALUE",
};
const formFields = useFormFieldsCreationNewRun(formFieldOptions);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('compoundPct') && __props.formSubmited }])
      }, [
        _cache[11] || (_cache[11] = _createElementVNode("label", null, "Target compound (% dry weight biomass) *", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "w-full",
          type: "number",
          min: "0.00",
          max: "100.00",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(formFields)['compoundPct'].value) = $event))
        }, null, 512), [
          [_vModelText, _unref(formFields)['compoundPct'].value]
        ]),
        _cache[12] || (_cache[12] = _createElementVNode("span", { class: "text-xs text-gray-400" }, "Note: if you chose Solid-state fermentor (SSF) as cultivation system the term 'biomass' stands for 'substrate'", -1))
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('biomassTarget') && __props.formSubmited }])
      }, [
        _cache[13] || (_cache[13] = _createElementVNode("label", null, "I know the target compound or biomass capacity? *", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "w-full",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(formFields)['biomassTarget'].value) = $event))
        }, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(biomassTargets, (biomassTarget) => {
            return _createElementVNode("option", {
              value: biomassTarget,
              key: biomassTarget
            }, _toDisplayString(biomassTarget), 9, _hoisted_3)
          }), 64))
        ], 512), [
          [_vModelSelect, _unref(formFields)['biomassTarget'].value]
        ])
      ], 2),
      (fields.value.compoundPct)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (fields.value.biomassTarget == 'Target compound')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["col-span-3", { 'error-form-field': __props.errorsFields.includes('targetCompoundCapacity') && __props.formSubmited }])
                }, [
                  _cache[14] || (_cache[14] = _createElementVNode("label", null, "Target compound capacity (ton/year dry weight) *", -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: "w-full",
                    type: "number",
                    min: "0.00",
                    max: "100.00",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(formFields)['targetCompoundCapacity'].value) = $event))
                  }, null, 512), [
                    [_vModelText, _unref(formFields)['targetCompoundCapacity'].value]
                  ])
                ], 2))
              : (fields.value.biomassTarget == 'Biomass')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(["col-span-3", { 'error-form-field': __props.errorsFields.includes('biomass') && __props.formSubmited }])
                  }, [
                    _cache[15] || (_cache[15] = _createElementVNode("label", null, "Biomass capacity (ton/year dry weight) *", -1)),
                    _withDirectives(_createElementVNode("input", {
                      class: "w-full",
                      type: "number",
                      min: "0.00",
                      max: "100.00",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(formFields)['biomass'].value) = $event))
                    }, null, 512), [
                      [_vModelText, _unref(formFields)['biomass'].value]
                    ])
                  ], 2))
                : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (showFirstInfo.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (fields.value.biomassTarget == 'Target compound')
              ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
                  _createVNode(_unref(NewspaperIcon), { class: "icon icon-small inline-block" }),
                  _cache[16] || (_cache[16] = _createTextVNode(" Considering your target compound production you will need ")),
                  _createElementVNode("b", null, _toDisplayString(calcTargetCompoundPerYear.value), 1),
                  _cache[17] || (_cache[17] = _createTextVNode(" tons of biomass per year "))
                ]))
              : _createCommentVNode("", true),
            (fields.value.biomassTarget == 'Biomass')
              ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
                  _createVNode(_unref(NewspaperIcon), { class: "icon icon-small inline-block" }),
                  _cache[18] || (_cache[18] = _createTextVNode(" Considering your substrate availability you will be able to have ")),
                  _createElementVNode("b", null, _toDisplayString(calcBiomassPerYear.value), 1),
                  _cache[19] || (_cache[19] = _createTextVNode(" tons of target compound per year "))
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_unref(RadioGroup), {
        modelValue: _unref(formFields)['bycompounds'].value,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(formFields)['bycompounds'].value) = $event)),
        class: _normalizeClass(["col-span-4 mb-0", { 'error-form-field': __props.errorsFields.includes('bycompounds') && __props.formSubmited }])
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(RadioGroupLabel), { class: "mb-1" }, {
            default: _withCtx(() => _cache[20] || (_cache[20] = [
              _createTextVNode("How many bycompounds are you going to have?")
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(bycompounds, (bycompound) => {
              return _createVNode(_unref(RadioGroupOption), {
                as: "template",
                key: bycompound,
                value: bycompound
              }, {
                default: _withCtx(({ active, checked }) => [
                  _createElementVNode("div", {
                    class: _normalizeClass([[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white '], "relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"])
                  }, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _createVNode(_unref(RadioGroupLabel), {
                            as: "p",
                            class: _normalizeClass([checked ? 'text-white' : 'text-gray-500', "font-base"])
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(bycompound), 1)
                            ]),
                            _: 2
                          }, 1032, ["class"])
                        ])
                      ]),
                      _withDirectives(_createElementVNode("div", _hoisted_12, _cache[21] || (_cache[21] = [
                        _createElementVNode("svg", {
                          class: "h-5 w-5",
                          viewBox: "0 0 24 24",
                          fill: "none"
                        }, [
                          _createElementVNode("circle", {
                            cx: "12",
                            cy: "12",
                            r: "12",
                            fill: "#fff",
                            "fill-opacity": "0.2"
                          }),
                          _createElementVNode("path", {
                            d: "M7 13l3 3 7-7",
                            stroke: "#fff",
                            "stroke-width": "1.5",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round"
                          })
                        ], -1)
                      ]), 512), [
                        [_vShow, checked]
                      ])
                    ])
                  ], 2)
                ]),
                _: 2
              }, 1032, ["value"])
            }), 64))
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "class"]),
      (showbycompoundX.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            (showbycompoundX.value)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["grow mb-4", { 'error-form-field': __props.errorsFields.includes('bycompoundXPct') && __props.formSubmited }])
                }, [
                  _cache[22] || (_cache[22] = _createElementVNode("label", null, "Bycompound x (% dry weight biomass)", -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: "w-full",
                    type: "number",
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(formFields)['bycompoundXPct'].value) = $event))
                  }, null, 512), [
                    [_vModelText, _unref(formFields)['bycompoundXPct'].value]
                  ])
                ], 2))
              : _createCommentVNode("", true),
            (showbycompoundY.value)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(["grow mb-4 ml-4", { 'error-form-field': __props.errorsFields.includes('bycompoundYPct') && __props.formSubmited }])
                }, [
                  _cache[23] || (_cache[23] = _createElementVNode("label", null, "Bycompound y (% dry weight biomass)", -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: "w-full",
                    type: "number",
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(formFields)['bycompoundYPct'].value) = $event))
                  }, null, 512), [
                    [_vModelText, _unref(formFields)['bycompoundYPct'].value]
                  ])
                ], 2))
              : _createCommentVNode("", true),
            (showbycompoundZ.value)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: _normalizeClass(["grow mb-4 ml-4", { 'error-form-field': __props.errorsFields.includes('bycompoundZPct') && __props.formSubmited }])
                }, [
                  _cache[24] || (_cache[24] = _createElementVNode("label", null, "Bycompound z (% dry weight biomass)", -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: "w-full",
                    type: "number",
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(formFields)['bycompoundZPct'].value) = $event))
                  }, null, 512), [
                    [_vModelText, _unref(formFields)['bycompoundZPct'].value]
                  ])
                ], 2))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('operatingDays') && __props.formSubmited }])
      }, [
        _cache[25] || (_cache[25] = _createElementVNode("label", null, "Operating days (in one year) *", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "w-full",
          type: "number",
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(formFields)['operatingDays'].value) = $event))
        }, null, 512), [
          [_vModelText, _unref(formFields)['operatingDays'].value]
        ])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('costBiomass') && __props.formSubmited }])
      }, [
        _cache[26] || (_cache[26] = _createElementVNode("label", null, "Price residual biomass (€/ton) *", -1)),
        _withDirectives(_createElementVNode("input", {
          class: "w-full",
          type: "number",
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(formFields)['costBiomass'].value) = $event))
        }, null, 512), [
          [_vModelText, _unref(formFields)['costBiomass'].value]
        ])
      ], 2),
      _createVNode(_unref(RadioGroup), {
        modelValue: _unref(formFields)['landCapex'].value,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(formFields)['landCapex'].value) = $event)),
        class: _normalizeClass(["col-span-4 mb-0 mt-1", { 'error-form-field': __props.errorsFields.includes('landCapex') && __props.formSubmited }])
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(RadioGroupLabel), { class: "mb-1" }, {
            default: _withCtx(() => _cache[27] || (_cache[27] = [
              _createTextVNode("Are you buying or renting the land? *")
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_14, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(landCapexes, (landCapex) => {
              return _createVNode(_unref(RadioGroupOption), {
                as: "template",
                key: landCapex.key,
                value: landCapex.key
              }, {
                default: _withCtx(({ active, checked }) => [
                  _createElementVNode("div", {
                    class: _normalizeClass([[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white '], "relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"])
                  }, [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("div", _hoisted_17, [
                          _createVNode(_unref(RadioGroupLabel), {
                            as: "p",
                            class: _normalizeClass([checked ? 'text-white' : 'text-gray-500', "font-base"])
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(landCapex.value), 1)
                            ]),
                            _: 2
                          }, 1032, ["class"])
                        ])
                      ]),
                      _withDirectives(_createElementVNode("div", _hoisted_18, _cache[28] || (_cache[28] = [
                        _createElementVNode("svg", {
                          class: "h-5 w-5",
                          viewBox: "0 0 24 24",
                          fill: "none"
                        }, [
                          _createElementVNode("circle", {
                            cx: "12",
                            cy: "12",
                            r: "12",
                            fill: "#fff",
                            "fill-opacity": "0.2"
                          }),
                          _createElementVNode("path", {
                            d: "M7 13l3 3 7-7",
                            stroke: "#fff",
                            "stroke-width": "1.5",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round"
                          })
                        ], -1)
                      ]), 512), [
                        [_vShow, checked]
                      ])
                    ])
                  ], 2)
                ]),
                _: 2
              }, 1032, ["value"])
            }), 64))
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "class"])
    ])
  ]))
}
}

})