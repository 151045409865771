import { useMutation } from "@vue/apollo-composable";
import { provideApolloClient } from "@vue/apollo-composable";
import apolloClient from "@/utils/apolloClient";
import { POSITION, useToast } from "vue-toastification";
import { INDIVIDUAL_CREATE, ORGANIZATION_CREATE, UNIVERSITY_CREATE } from "@/grapql/partyCreateMutation";

provideApolloClient(apolloClient);
const toast = useToast()

const getDefaultState = () => { return { 
    party: {
        individual: {
            nickname: null,
            firstname: null,
            lastname: null,
            description: null,
            url: null,
            links: [],
            address: null,
            phone: null,
            email: null,
            expertise: [],
            sources: [],
            businessTypes: [],
            files: [],
            relatedUniversity: null,
            relatedOrganization: null
        },
        organization: {
            nickname: null,
            description: null,
            url: null,
            links: [],
            address: null,
            phone: null,
            email: null,
            expertise: [],
            sources: [],
            businessTypes: [],
            files: [],
            country: null,
            foundingYear: null,
            employees: null,
            totalPatents: null, 
            totalGrantedPatents: null, 
            parent: null, 
            organizationType: null,
        },
        university: {
            nickname: null,
            description: null, 
            url: null, 
            links: [],
            address: null, 
            phone: null, 
            email: null, 
            expertise: [],
            sources: [],
            businessTypes: [],
            files: [],
            country: null,
            foundingYear: null
        },
    },
    errorsDuringPartyCreation: {
        individual: null, 
        organization: null,
        university: null,
    },
    createdParty: {
        id: null, 
        nickname: null
    }
}};

const state = getDefaultState();

const mutations = {
    setSinglePartyValue(state, payload:{partyType: string, partyField: string , value: Date | number | string | number[] | null}) {
        state.party[payload.partyType][payload.partyField] = payload.value;
    },
    async resetPartyValues(state) {
        state.party = getDefaultState().party;
        state.errorsDuringPartyCreation = getDefaultState().errorsDuringPartyCreation;
    },
    async setErrorOnCreationResponse(state, payload:{error:string, partyType: string}) {
        if (payload.error.includes('already exists') ) {
            state.errorsDuringPartyCreation[payload.partyType] = 'Ops! what you are trying to create already exists'   
        } else {
            state.errorsDuringPartyCreation[payload.partyType] = payload.error
        }
    },
    async clearErrors(state) {
      state.errorsDuringPartyCreation = {
        individual: null, 
        organization: null,
        university: null,
        }  
    },
    async setCreatedParty(state, payload: {createdParty: {id: number, nickname: string}}) {
        state.createdParty.id = payload.createdParty.id;
        state.createdParty.nickname = payload.createdParty.nickname;
    },
    restoreCreatedParty(state) {
        state.createdParty.id = null;
        state.createdParty.nickname = null;
    }

};

const actions = {

    // UNIVERSITY CREATE
    universityCreate({state, commit, dispatch}) {
        return new Promise<void>((resolve) => {
            const { mutate: universityCreate, onDone, onError } = useMutation(UNIVERSITY_CREATE);
            const universityInput = Object.assign({}, state.party['university']);
            universityCreate({input: universityInput});
            onDone( async (result) => { 
                await dispatch('partyCreateOnDone', {partyType: 'university'});
                await commit('setCreatedParty', {createdParty: result.data.universityCreate.university}) 
                resolve() 
            });
            onError( async (error) => {
                await commit('setErrorOnCreationResponse', {error: error.message, partyType: 'university'}) 
                resolve();
            })
        })
        
    },

    // ORGANIZATION CREATE
    organizationCreate({state, commit, dispatch}) {
        return new Promise<void>((resolve) => {
            const { mutate: organizationCreate, onDone, onError } = useMutation(ORGANIZATION_CREATE);
            const organizationInput = Object.assign({}, state.party['organization']);
            organizationCreate({input: organizationInput});
            onDone( async (result) => { 
                await dispatch('partyCreateOnDone', {partyType: 'organization'}) ;
                await commit('setCreatedParty', {createdParty: result.data.organizationCreate.organization}) 
                resolve()
            });
            onError( async (error) => {
                commit('setErrorOnCreationResponse', {error: error.message, partyType: 'organization'})
                resolve() 
            })
        })
    },

    // INDIVIDUAL CREATE
    individualCreate({state, commit, dispatch}) {
        return new Promise<void>((resolve) => {
            const { mutate: individualCreate, onDone, onError } = useMutation(INDIVIDUAL_CREATE);
            const individualInput = Object.assign({}, state.party['individual']);
            individualCreate({input: individualInput});
            onDone( async (result) => { 
                await dispatch('partyCreateOnDone', {partyType: 'individual'});
                await commit('setCreatedParty', {createdParty: result.data.individualCreate.individual}) 
                resolve();
            });
            onError( async (error) => {
                commit('setErrorOnCreationResponse', {error: error.message, partyType: 'individual'});
                resolve();
            })
        })
        
    },

    // ON DONE CREATION ACTIONS
    async partyCreateOnDone({commit}, payload: {partyType: string}) {
        await commit('clearErrors');
        toast.success(`New ${payload.partyType} created`, { position: POSITION.BOTTOM_LEFT, timeout: 1524 });
        await apolloClient.refetchQueries({include: ['getAssetAddFormValues']});
    }
};

const getters = {
    getCreatedParty(state) {
        return state.createdParty
    }
};

export default {
    namespaced: true,
    state, 
    mutations,
    getters,
    actions
}