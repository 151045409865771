<template>
    <div v-if="targetsToDisplay.length > 0" class="col-span-5">
        <div class="flex justify-between items-center flex-wrap mb-6">
            <div v-for="(target, index) in targetsToDisplay" :key="index" class="target-card shadow-md" :class="{'to-delete': onEdit && targetsToDelete.includes(target.id)}">
                <div class="flex justify-between items-strech">
                    <div class="flex">
                        <div v-if="onEdit && targetsToDelete.includes(target.id)" class="flex trash">
                            <TrashIcon class="icon icon-m" />
                        </div>
                        <div class="flex flex-col">
                            <p class="font-medium capitalize">{{ target.targetCategoryType }} property</p>
                            <p class="capitalize italic mb-2"> {{ target.targetPropertyClass }} / {{
                                target.targetPropertyCategory
                            }}</p>
                            <div class="flex text-neutral-950 text-sm font-normal">
                                <p class="mr-2 uppercase font-medium"> {{ target.targetPropertyName }} : </p>
                                <p v-if="target.targetValueTypeName">{{ target.targetValueTypeName }}</p>
                                <p class="mx-2">{{ target.targetValue1 }} <span v-if="target.targetValue2"> and {{
                                    target.targetValue2 }}</span></p>
                                <p v-if="target.targetValueUnit">{{ target.targetValueUnit }}</p>
                                <p v-if="target.currentValue && isUserAllowToSeeExtraInfo" class="ml-4 font-light text-sm"> ( Current Value: <span class="font-normal">{{ target.currentValue }}</span> )</p>
                            </div>
                        </div>
                    </div>
                    <div v-if="onEdit && targetsToDelete.includes(target.id)" class="flex items-center" >
                        <ArrowUturnLeftIcon class="icon icon-small ml-3" @click="removeTargetToDelete(target.id)" />
                    </div>
                    <div v-else class="flex items-center">
                        <PencilSquareIcon v-if="onEdit && target.id" class="icon icon-small mr-4"
                            @click="setTargetToEdit(target.id)" /> 
                        <XCircleIcon v-if="onEdit && target.id" class="icon icon-small " @click="addTargetToDelete(target.id)" />
                        <XCircleIcon v-else class="icon icon-small " @click="removeTarget(index)" />
                    </div>
                </div> 
            </div>
        </div>
    </div>
    <!-- Form for edition with values -->
    <ProgramVersionTargetFormFields v-if="onEdit && targetToEdit" :key="targetToEdit.id" :on-edit="true"
        :target-to-edit="targetToEdit" :properties="propertiesDropdownValues" :units="units" :valueTypes="valueTypes"
        :targetCategoryTypes="targetCategoryTypes" @edited-target="handleEditedTarget" />
    <!-- For for new target creation -->
    <ProgramVersionTargetFormFields :key="programVersionTargets.length" :properties="propertiesDropdownValues"
        :units="units" :valueTypes="valueTypes" :targetCategoryTypes="targetCategoryTypes" @new-target="handleNewTarget" />
</template>
<script setup lang="ts">
import { PROGRAM_VERSION_TARGETS_FORM } from '@/grapql/programVersionTargetsQueries';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { ProgramVersionTargetsInterface, TargetToDisplayInterface } from '@/models/programVersionsInterface';
import { useQuery } from '@vue/apollo-composable';
import { computed, ref, defineProps } from 'vue';
import { useStore } from 'vuex';
import { XCircleIcon, PencilSquareIcon, ArrowUturnLeftIcon, TrashIcon } from '@heroicons/vue/24/outline';
import ProgramVersionTargetFormFields from './ProgramVersionTargetFormFields.vue';

const props = defineProps<{
    onEdit: boolean
}>()
const store = useStore();

const programVersionTargets = computed<ProgramVersionTargetsInterface[]>(() => props.onEdit ? store.state.programVersionDetailModule['programVersionTargetsForEdition'] : store.state.programsCreationModule['programVersion']['programVersionTargets']);
const targetsToDelete = computed<number[]>(() => store.state.programVersionDetailModule.targetsToDelete );


const isUserAllowToSeeExtraInfo = computed(() => store.getters['authModule/isUserAllowToSeeExtraInfo'])

// Inizialize the form
const { result } = useQuery(PROGRAM_VERSION_TARGETS_FORM);
const inizilizedData = computed(() => result?.value ?? null);

const properties = computed(() => inizilizedData.value && inizilizedData.value.properties ? inizilizedData.value?.properties.edges.map(edge => edge.node) : []);
const targetCategoryTypes = computed(() => inizilizedData.value && inizilizedData.value.targetCategoryTypes ? inizilizedData.value.targetCategoryTypes.edges.map(edge => edge.node) : []);
const units = computed(() => inizilizedData.value && inizilizedData.value.unitTypes ? inizilizedData.value.unitTypes.edges.map(edge => edge.node) : []);
const valueTypes = computed(() => inizilizedData.value && inizilizedData.value.valueTypes ? inizilizedData.value.valueTypes.edges.map(edge => edge.node) : []);

const propertiesDropdownValues = computed<DropdownSearchableOptionInterface[]>(() => properties.value ? properties.value.map(property => Object.assign({}, { id: property.id, name: property.name })) : []);

const targetsToDisplay = computed<TargetToDisplayInterface[]>(() => {
    const targets = [];
    if (programVersionTargets.value.length && inizilizedData.value) {
        programVersionTargets.value.map(target => {
            const displayTarget = parseTarget(target)
            targets.push(displayTarget)
        })
    }
    return targets
});

const targetIndexToEdit = ref(null);
const setTargetToEdit = (id: number) => {
    const targetToEdit = programVersionTargets.value.find(target => target.id == id);
    targetIndexToEdit.value = programVersionTargets.value.indexOf(targetToEdit);
}
const targetToEdit = computed(() => targetIndexToEdit.value != null ? programVersionTargets.value[targetIndexToEdit.value] : null)


const handleNewTarget = (targetToAdd) => {
    delete targetToAdd.id
    programVersionTargets.value.push(targetToAdd);
}

const handleEditedTarget = (editedTarget) => {
    programVersionTargets.value[targetIndexToEdit.value] = editedTarget;
    targetIndexToEdit.value = null;
}

const removeTarget = (index: number) => {
    programVersionTargets.value.splice(index, 1);
}

const addTargetToDelete = (id: number) => {
    targetsToDelete.value.push(id);
    targetIndexToEdit.value = null;
}

const removeTargetToDelete = (id: number) => targetsToDelete.value.splice(targetsToDelete.value.indexOf(id), 1)

const parseTarget = (target: ProgramVersionTargetsInterface) => {
    const valueType = valueTypes.value.find(tv => tv.id == target.targetValueType);
    const displayTarget: TargetToDisplayInterface = {
        id: target.id ? target.id : null,
        targetCategoryType: targetCategoryTypes.value.find(tct => tct.id == target.targetCategoryType).name,
        targetPropertyName: properties.value.find(p => p.id == target.targetProperty).name,
        targetPropertyClass: properties.value.find(p => p.id == target.targetProperty).className,
        targetPropertyCategory: properties.value.find(p => p.id == target.targetProperty).category,
        targetValue1: target.targetValue1 == 'true' ? 'Yes' : target.targetValue1 == 'false' ? 'No' : target.targetValue1,
        targetValueTypeName: valueType.name == 'Boolean' ? null : valueType.name == 'Between' ? valueType.name : valueType.symbol,
        targetValueUnit: target.targetValueUnit ? units.value.find(unit => unit.id == target.targetValueUnit).abbreviation : null,
        targetValue2: target.targetValue2 ? target.targetValue2 : null,
        currentValue: target.currentValue ? target.currentValue : null
    }
    return displayTarget;
}

</script>
<style lang="scss" scoped>
.target-card {
    width: 48%;
    background-color: #fafafa;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    font-size: 0.8rem;
    border-radius: 6px;
    font-weight: 300;
    border-left: 6px solid black;
    &.to-delete {
       border-left: 6px solid $one-five-orange;
        
    }
    .trash {
        background-color: $one-five-orange;
        margin: -8px 16px -8px -16px;
        padding: 0 24px;
        align-items: center;
        > svg {
            stroke: white;
            stroke-width: 1;
        }
    }
}

input[type=radio] {
    width: 16px;
    height: 16px;
    border-radius: 5px;
    margin-top: 0.5rem;

    &:checked {
        background-image: url("@/assets/icons/black-check-box.svg");
    }
}
</style>