import gql from 'graphql-tag';

export const ASSET_QUANTITATIVE_PROPERTIES_FORM_VALUES = gql`
    query getQuantitativePropertiesFormValues {
		unitTypes(orderBy: "abbreviation", filter: {not: {family: {iexact: "Boolean"}}}) {
			edges {
				node {
					id
					abbreviation
					name
					}
				}
      	}
		valueTypes {
			edges {
				node {
					id
					name
					symbol
					description
				}
			}
		}
		quantitativeProperties (orderBy: "name") {
			edges {
				node {
					id
					name
					category
				}
			}
		}
	}`
