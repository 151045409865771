import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "p-4" }
const _hoisted_2 = { style: {"max-width":"500px"} }
const _hoisted_3 = { class: "grid gap-y-3 gap-x-4 mt-6 mb-6" }
const _hoisted_4 = {
  key: 0,
  class: "col-span-2 grid"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["onUpdate:modelValue"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["onUpdate:modelValue"]

import { NEW_RUN_GROUP_FIELDS } from "@/tea/models/runGroup";
import { computed } from "vue";
import { useStore } from "vuex";
import FormErrorToast from "@/components/FormErrorToast.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'RunGroupAddNewForm',
  props: {
  errorsFields: Array,
  formIsValid: Boolean,
  formSubmited: Boolean,
  queryError: String,
},
  setup(__props) {

const store = useStore();
const props = __props;
const showErrors = computed(() => !props.formIsValid && props.formSubmited);

// Create Fields for the form
const fields = computed<{ label: string; type: string; mutationValue: string; queryValue: string; required: boolean }[]>(
  () => NEW_RUN_GROUP_FIELDS.formFields
);

const fieldsFromConstant = computed<string[]>(() =>
  NEW_RUN_GROUP_FIELDS.formFields.map((formField) => {
    return formField.mutationValue;
  })
);

const formFieldOptions = {
  fields: fieldsFromConstant.value,
  base: NEW_RUN_GROUP_FIELDS.graphqlValue,
  mutation: "runGroupModule/SET_CREATE_SINGLE_RUN_GROUP_VALUE",
};

const formFields = (formFieldOptions: { fields: string[]; base: string; mutation: string }) => {
  const object = {};
  formFieldOptions.fields.map((fieldName) => {
    object[fieldName] = computed({
      get() {
        return store.state.runGroupModule.create.runGroup[fieldName];
      },
      set(value) {
        store.commit(formFieldOptions.mutation, { runGroupField: fieldName, value: value });
      },
    });
  });
  return object;
};

const vModels = formFields(formFieldOptions);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(FormErrorToast, {
      "is-showing": showErrors.value,
      title: "The following fields are mandatory:",
      subtitle: __props.errorsFields.join(', ')
    }, null, 8, ["is-showing", "subtitle"]),
    _createElementVNode("p", _hoisted_2, [
      _createVNode(FormErrorToast, {
        "is-showing": (__props.queryError?true:false),
        title: "Error",
        subtitle: __props.queryError
      }, null, 8, ["is-showing", "subtitle"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fields.value, (field, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          (field.type === 'textarea')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("label", null, [
                  _createTextVNode(_toDisplayString(field.label), 1),
                  (field.required)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, "*"))
                    : _createCommentVNode("", true)
                ]),
                _withDirectives(_createElementVNode("textarea", {
                  class: "w-full",
                  "onUpdate:modelValue": ($event: any) => ((_unref(vModels)[field.mutationValue].value) = $event)
                }, null, 8, _hoisted_6), [
                  [_vModelText, _unref(vModels)[field.mutationValue].value]
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["col-span-2", { 'error-form-field': __props.errorsFields.includes(field.mutationValue) && __props.formSubmited }])
              }, [
                _createElementVNode("label", null, [
                  _createTextVNode(_toDisplayString(field.label) + " ", 1),
                  (field.required)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, "*"))
                    : _createCommentVNode("", true)
                ]),
                (field.type === 'text')
                  ? _withDirectives((_openBlock(), _createElementBlock("input", {
                      key: 0,
                      class: "w-full",
                      type: "text",
                      "onUpdate:modelValue": ($event: any) => ((_unref(vModels)[field.mutationValue].value) = $event)
                    }, null, 8, _hoisted_8)), [
                      [_vModelText, _unref(vModels)[field.mutationValue].value]
                    ])
                  : _createCommentVNode("", true)
              ], 2))
        ]))
      }), 128))
    ])
  ]))
}
}

})