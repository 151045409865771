<template>
    <ModalSlots
      :show="showSensitive"
      @close-modal="closeModal"
      @reset-modal="handleReset"
      dialogClass="mx-auto max-w-[80%] min-w-[80%]"
      :hideFooter="true"
    >
      <template v-slot:header><p>RUN SENSITIVITY ANALYSIS</p></template>
      <template v-slot:body>
        <!-- <LoaderPage v-if="showFilesUploadLoader" loader-text="Creating Asset" gif-position="center"/> -->
        <div class="form-container">    
            <RunSensitivityCreate :run-id="selectedId" />
        </div>
      </template>
    </ModalSlots>
</template>

<script setup lang="ts">
import {ref, defineProps,computed,defineEmits, onMounted} from "vue"
import { useStore } from "vuex";
import ModalSlots from "@/components/ModalSlots.vue";
import RunSensitivityCreate from "./RunSensitivityCreate.vue";

const props = defineProps(["selectedId","showSensitive"]);
const emits = defineEmits(["onCreated","closeSensitive"]);
const store = useStore();
const collapsed = computed(() => store.getters["authModule/isSidebarCollapsed"]);
const toggleSidebar = () => store.commit("authModule/toogleSidebar");

const closeModal = () => {
    emits("closeSensitive")
};

onMounted(()=>{
    console.log(props.selectedId)
    if(!collapsed.value){
        toggleSidebar()
    }
});

const handleReset = ()=>{
    store.commit("runSensitivityModule/RESET_CREATE");
}


</script>
<style scoped lang="scss">

</style>