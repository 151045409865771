<template>
  <div class="form-container">
    <FormErrorToast :is-showing="!!errorsDuringMutation" title="Warning!" :subtitle="errorsDuringMutation" toast-type="warning" />
    <FormErrorToast
      :is-showing="formSubmited && !formIsValid"
      title="The following fields have validation errors:"
      :subtitle="errorsFields.join(', ')"
    />
    <form class="add-run-cultivation-form" v-if="formInizializationValues">
      <div class="grid grid-cols-4 gap-y-3 gap-x-4">
        <div class="form-separator col-span-4"><p class="form-subtitle">Cultivation Info</p></div>
        <!-- ##### place -->
        <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('place') && formSubmited }">
          <label>Place *</label>
          <select class="w-full" v-model="formFields['place'].value">
            <option v-for="place in formInizializationValues['placesOptions']" :value="Number(place.id)" :key="place.id">
              {{ place.name }}
            </option>
          </select>
        </div>

        <!-- ##### Species -->
        <div class="col-span-2" :class="{ 'error-form-field': errorsFields.includes('species') && formSubmited }">
          <label>Which species are you working with? *</label>
          <input class="w-full" type="text" v-model="formFields['species'].value" />
        </div>

        <!-- ##### Compound -->
        <div class="col-span-2 grid" :class="{ 'error-form-field': errorsFields.includes('compound') && formSubmited }">
          <label>Which compound are you interested in producing? *</label>
          <input class="w-full" type="text" v-model="formFields['compound'].value" />
        </div>

        <!-- ##### Residual Biomass? -->
        <div class="col-span-4 flex items-center" :class="{ 'error-form-field': errorsFields.includes('residualBiomass') && formSubmited }">
          <label class="inline-block mr-2">Are you using residual biomass?</label>
          <Switch
            v-model="formFields['residualBiomass'].value"
            :class="formFields['residualBiomass'].value ? 'bg-emerald-700' : 'bg-gray-200'"
            class="relative inline-flex h-6 w-11 items-center rounded-full"
          >
            <span class="sr-only">Are you using residual biomass?</span>
            <span
              :class="formFields['residualBiomass'].value ? 'translate-x-6' : 'translate-x-1'"
              class="inline-block h-4 w-4 transform rounded-full bg-white transition"
            ></span>
          </Switch>
        </div>

        <!-- if residual biomass is NOT used -->
        <!-- ########Cultivation with not using resdual biomass########  -->
        <RunNonResidual
          v-if="!isResidualBiomass"
          :formInizializationValues="formInizializationValues"
          :errorsFields="errorsFields"
          :formSubmited="formSubmited"
        />

        <!-- ###########Using residual biomass - no cultivation nor harvesting considered########### -->
        <RunResidual
          v-else-if="isResidualBiomass"
          :formInizializationValues="formInizializationValues"
          :errorsFields="errorsFields"
          :formSubmited="formSubmited"
        />

        <!-- ###########Extraction (valid for residual and not residual)########### -->
        <div class="form-separator col-span-4"><p class="form-subtitle">Extraction & drying</p></div>
        <RunExtraction
          :formInizializationValues="formInizializationValues"
          :errorsFields="errorsFields"
          :formSubmited="formSubmited"
        />

        <!-- ###########NPV########### -->
        <div class="form-separator col-span-4"><p class="form-subtitle">Expected Yearly Income</p></div>
        <RunNvp
          :formInizializationValues="formInizializationValues"
          :errorsFields="errorsFields"
          :formSubmited="formSubmited"
        />

        <!-- show errors here if available -->
        <div class="col-span-4">
          <FormErrorToast
            :is-showing="formSubmited && !formIsValid"
            title="The following fields have validation errors::"
            :subtitle="errorsFields.join(', ')"
          />
          <FormErrorToast :is-showing="!!errorsDuringMutation" title="Warning!" :subtitle="errorsDuringMutation" toast-type="warning" />
        </div>

        <!-- ###########Outputs########### -->
        <div class="form-separator col-span-4"><p class="form-subtitle">Outputs</p></div>
        <RunOutputs
          :isReadyForOutput="isReadyForOutput"
          @showOutputs="showOutputs"
        />

        <!-- show errors here if available -->
        <div class="col-span-4">
          <FormErrorToast :is-showing="!!errorsDuringMutation && (formSubmited && !formIsValid)" title="Warning!" :subtitle="errorsDuringMutation" toast-type="warning" />
        </div>

      </div>
    </form>
  </div>
</template>
<script setup lang="ts">
import { RUN_ADD_FORM_VALUES } from "@/tea/graphql/queries/runQueries";
import { useTeaQuery } from "@/tea/composables/apollo/useTeaQuery";
import { computed, ref } from "vue";
import { NEW_RUN_FIELDS } from "@/tea/models/run";
import { useFormFieldsCreationNewRun } from "@/tea/composables/runs/useFormFieldsCreationNewRun";
import { useFormInizializationNewRun } from "@/tea/composables/runs/useFormInizializationNewRun";
import FormErrorToast from "@/components/FormErrorToast.vue";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { DropdownSearchableOptionInterface } from "@/models/dropdownSearchableMultipleOptionInterface";
import { useStore } from "vuex";
import RunNonResidual from "@/tea/components/runs/RunNonResidual.vue";
import RunResidual from "@/tea/components/runs/RunResidual.vue";
import RunExtraction from "@/tea/components/runs/RunExtraction.vue";
import RunNvp from "@/tea/components/runs/RunNvp.vue";
import RunOutputs from "@/tea/components/runs/RunOutputs.vue";

import { Switch } from "@headlessui/vue";

const store = useStore();

// Inizialize the form
const { result } = useTeaQuery(RUN_ADD_FORM_VALUES);

const inizilizedData = computed(() => result?.value ?? null);
const formInizializationValues = computed<{ [key: string]: DropdownSearchableOptionInterface[] }>(() =>
  useFormInizializationNewRun(inizilizedData.value, NEW_RUN_FIELDS.fieldsToQuery)
);
const errorsDuringMutation=computed(()=> store.getters["runCreateModule/createError"])

const getFormFields = Object.keys(NEW_RUN_FIELDS.formFields).map((key) => {
    const fieldConfig = NEW_RUN_FIELDS.formFields[key];
    return {
        [key]: Array.isArray(fieldConfig.onChangeResetFields) ? fieldConfig.onChangeResetFields : [],
    };
});
const formFieldOptions = {
  fields: getFormFields,
  mutation: "runCreateModule/SET_SINGLE_RUN_VALUE",
};
const formFields = useFormFieldsCreationNewRun(formFieldOptions);

const fields = computed(() => store.getters["runCreateModule/fields"]);
const isReadyForOutput = computed(() => store.getters["runCreateModule/isReadyForOutput"]);
const isResidualBiomass = computed(() => {
  return fields.value.residualBiomass;
});

const formSubmited = computed(()=> store.getters["runCreateModule/isFormSubmitted"]);
const valuesSubmitted = computed(() => fields.value);

const formIsValid = computed(() => {
  return errorsFields.value.length === 0;
});

function getMissingFields(fieldName, fieldValue, validation) {
  const missingFields = [];
  // 1. check if required
  if (validation.required === true) {
    if (fieldValue === undefined || fieldValue === null || fieldValue === "") {
      missingFields.push(fieldName);
    }
  }

  // 2. check if validtion has valueValidation property set and aloso field has value, then check if value is truthy
  if (validation.valueValidation && fieldValue !== undefined && fieldValue !== null && fieldValue !== "") {
    const { type, values } = validation.valueValidation;
    if (type === "array") {
      if (!values.includes(fieldValue)) {
        missingFields.push(fieldName);
      }
    } else if (type === "range") {
      if (fieldValue < values[0] || fieldValue > values[1]) {
        missingFields.push(fieldName);
      }
    } else if (type === "equal") {
      if (fieldValue !== values) {
        missingFields.push(fieldName);
      }
    } else if (type === "min") {
      if (fieldValue < values) {
        missingFields.push(fieldName);
      }
    }
  }

  // 3. check dependants
  if (validation.dependants) {
    const { dependants } = validation;
    for (const condition of dependants) {
      let doCheckUp = false;
      const { ifValue, ifValueType, ifValueReference, fields, not, is } = condition;
      // Check if the field associated with the condition is truthy
      //check if there is ifValueType equals to options
      //check if it has not property
      //check if it has is property
      if (ifValueType === "option") {
        if (formInizializationValues.value[ifValueReference]) {
          let selectedItem = formInizializationValues.value[ifValueReference].find((item) => item.name === ifValue);
          if (selectedItem) {
            if (selectedItem.id == fieldValue) {
              doCheckUp = true;
            }
          }
        }
      } else if (fieldValue === ifValue) {
        doCheckUp = true;
      }

      if (not && doCheckUp) {
        for (const notCondition of not) {
          let notIfFieldValue = valuesSubmitted.value[notCondition.ifField];
          if (notCondition.ifValueType === "option") {
            if (formInizializationValues.value[notCondition.ifValueReference]) {
              let selectedItem = formInizializationValues.value[notCondition.ifValueReference].find(
                (item) => item.name === notCondition.ifValue
              );
              if (selectedItem) {
                if (selectedItem.id === notIfFieldValue) {
                  //do not do checkup because it does not passes the not condition
                  doCheckUp = false;
                }
              }
            }
          } else if (notIfFieldValue === notCondition.ifValue) {
            //do not do checkup because it does not passes the not condition
            doCheckUp = false;
          }
        }
      }

      if (is && doCheckUp) {
        for (const isCondition of is) {
          let isIfFieldValue = valuesSubmitted.value[isCondition.ifField];
          if (isCondition.ifValueType === "option") {
            if (formInizializationValues.value[isCondition.ifValueReference]) {
              let selectedItem = formInizializationValues.value[isCondition.ifValueReference].find(
                (item) => item.name === isCondition.ifValue
              );
              if (selectedItem) {
                if (selectedItem.id !== isIfFieldValue) {
                  //do not do checkup because it does not passes the not condition
                  doCheckUp = false;
                }
              }
            }
          } else if (isIfFieldValue !== isCondition.ifValue) {
            //do not do checkup because it does not passes the not condition
            doCheckUp = false;
          }
        }
      }

      if (doCheckUp) {
        for (const dependantField of fields) {
          // Check if the field is missing
          const dependantValue = valuesSubmitted.value[dependantField];
          if (dependantValue === undefined || dependantValue === null || dependantValue === "") {
            missingFields.push(dependantField);
          }
        }
      }
    }
  }

  // Add more validation logic here if needed.
  return Array.from(new Set(missingFields)); //Remove duplicates
}
const errorsFields = computed(() => {
  const missingFields = [];
  for (const fieldName of Object.keys(NEW_RUN_FIELDS.formFields)) {
    const validation = NEW_RUN_FIELDS.formFields[fieldName];
    const fieldValue = valuesSubmitted.value[fieldName];
    missingFields.push(...getMissingFields(fieldName, fieldValue, validation));
  }
  return Array.from(new Set(missingFields)); //Remove duplicates
});

const showOutputs =async () => {
  
  store.commit('runCreateModule/SET_IS_FORM_SUBMITTED',true);

  if (formIsValid.value) {
    
    // get outputs now
    const mutation = NEW_RUN_FIELDS.calculateMutation;
    await store
      .dispatch(mutation)
      .then(() => {
        store.commit("runCreateModule/SET_IS_READY_FOR_OUTPUT", true);
        store.commit("runCreateModule/SET_CAN_SUMBIT", true);
        store.commit("runCreateModule/SET_CREATE_ERROR",null);
      })
      .catch((error) => {
        console.log('error is here')
        console.log(error);
      });
  }
};
</script>
