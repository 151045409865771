<template>
    <div v-for="item in dataForAccordionList" :key="item.title">
        <div v-if="item.content || item.infoContent || item.priceContent" class="accordion-item">
            <div v-if="item.content || item.infoContent || item.priceContent" @click="toggleExpandedField(item.title)" class="title">
                <ChevronRightIcon class="icon" :class="{'face-down': expandedField.includes(item.title)}"/>
                <p> {{item.title}} </p> 
            </div>
            <div v-if="item.infoContent" :class="{'visible': expandedField.includes(item.title)}" class="content">
                <AssetDetailInfoContent :info-content="item.infoContent" />
            </div>
            <div v-else-if="item.priceContent" :class="{'visible': expandedField.includes(item.title)}" class="content">
                <AssetDetailPriceContent :price-content="item.priceContent" />
            </div>
            <div v-else-if="item.innerHtml && item.mutationType == 'textList'" :class="{'visible': expandedField.includes(item.title)}" class="content">
                <div v-for="claim in item.content" :key="claim" v-html="claim"></div>
            </div>
            <div v-else-if="item.innerHtml" v-html="item.content" :class="{'visible': expandedField.includes(item.title)}" class="content"></div> 
            <div v-else :class="{'visible': expandedField.includes(item.title)}" class="content">{{ item.content }}</div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { defineProps, ref } from 'vue';
    import { ChevronRightIcon } from '@heroicons/vue/24/solid';
    import AssetDetailInfoContent from './AssetDetailInfoContent.vue';
    import AssetDetailPriceContent from './AssetDetailPriceContent.vue';
    const props = defineProps(['dataForAccordionList']);
    const expandedField = ref(props.dataForAccordionList.filter(item => item.expanded).map(ei => ei.title));
    const toggleExpandedField = (title:string) => {
        const titlePos = expandedField.value.indexOf(title);
        titlePos == -1 ? expandedField.value.push(title) : expandedField.value.splice(titlePos, 1);
    }
</script>