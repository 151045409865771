import { useMutation } from "@vue/apollo-composable";
import {REFRESH_TOKEN_MUTATION} from "@/tea/graphql/mutations/authMutations";

const refreshTokenIfNeeded = async () => {
  try {
    const { mutate: refreshToken, onDone, onError } = useMutation(
      REFRESH_TOKEN_MUTATION,
      {
        context: {
          uri: process.env.VUE_TEA_AUTH_API,
        },
      }
    );

    const localStorageRefreshToken = localStorage.getItem("refresh_token");
    const refreshResult = await refreshToken({ refreshToken: localStorageRefreshToken });

    if (refreshResult.data && refreshResult.data.refreshToken) {
      const { token, refreshToken: newRefreshToken } = refreshResult.data.refreshToken;
      localStorage.setItem("auth_token", token);
      localStorage.setItem("refresh_token", newRefreshToken);
      return { token, refreshToken: newRefreshToken };
    } else {
      console.log("Refresh token failed.");
      return null;
    }
  } catch (error) {
    localStorage.clear();
    return null;
  }
};

export default refreshTokenIfNeeded;
