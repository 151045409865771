export const useFormInizializationNewAsset = (queryResult, fields:string[]) => {
    const object = {};
    if (queryResult) {

        fields.map(field => {

            if(field == 'standards') {
                const newStandard = queryResult['standards'].edges.map(({ node: {code: name, ...rest}}) => ({name, ...rest}));  
                object[`${field}Options`] = newStandard;
            }
            if(field == 'parties') {
                const newParties = queryResult['parties'].edges.map(({ node: {nickname: name, ...rest}}) => ({name: capitalizeFirstLetter(name), ...rest})); 
                object[`${field}Options`] = newParties;
            }
            if(field == 'materials') {
                const newMaterials = queryResult['materials'].edges.map(({ node: {name, acronym, ...rest}}) => ({ 
                    id: rest.id,
                    name: acronym ? `(${acronym}) - ${name}` : name,
                    ...rest,
                }));   
                object[`${field}Options`] = newMaterials;
            }    
            if(field == 'teamRoleTypes') {
                const newTeamRoleTypes = queryResult['teamRoleTypes'].edges.map(({ node: {category, name, ...rest}}) => ({
                    id: rest.id,
                    name: `${name} - ${category}`,
                    ...rest,
                }));  
                object[`${field}Options`] = newTeamRoleTypes;
            }
            if(field == 'employees') {
                const newEmployees = queryResult['employees'].edges.map(({ node: {fullName: name, ...rest}}) => ({name, ...rest}));  
                object[`${field}Options`] = newEmployees;
            }
            if (field == 'countries') {
                const countries = queryResult['countries'].edges.map(({ node: { id, name, code, ...rest }}) => ({
                    id,
                    name: `${name} - (${code})`,
                    ...rest,
                }));
                object[`${field}Options`] = countries;
            }
            if(field != 'standards' && field != 'parties' && field != 'materials' && field != 'teamRoleTypes' && field != 'employees' && field != 'countries') {
                object[`${field}Options`] = queryResult[field].edges.map((edge: { node: unknown; }) => edge.node) ;
            }
        });        
    }
    return object;
}


export function capitalizeFirstLetter(string) {
    if (typeof string === 'string' && string.length > 0) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
        return string;
    }
}