import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-container" }

import {ref,computed, onMounted} from "vue"
import { useStore } from "vuex";
import ModalSlots from "@/components/ModalSlots.vue";
import RunDoubleSensitivityCreate from "./RunDoubleSensitivityCreate.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'RunDoubleSensitivityRepo',
  props: ["selectedId","showDoubleSensitive"],
  emits: ["onCreated","closeDoubleSensitive"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emits = __emit;
const store = useStore();
const collapsed = computed(() => store.getters["authModule/isSidebarCollapsed"]);
const toggleSidebar = () => store.commit("authModule/toogleSidebar");

const closeModal = () => {
    emits("closeDoubleSensitive")
};

onMounted(()=>{
    if(!collapsed.value){
        toggleSidebar()
    }
});

const handleReset = ()=>{
    store.commit("runDoubleSensitivityModule/RESET_CREATE");
}



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalSlots, {
    show: __props.showDoubleSensitive,
    onCloseModal: closeModal,
    onResetModal: handleReset,
    dialogClass: "mx-auto max-w-[80%] min-w-[80%]",
    hideFooter: true
  }, {
    header: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("p", null, "RUN DOUBLE SENSITIVITY ANALYSIS", -1)
    ])),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(RunDoubleSensitivityCreate, { "run-id": __props.selectedId }, null, 8, ["run-id"])
      ])
    ]),
    _: 1
  }, 8, ["show"]))
}
}

})