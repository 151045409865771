import gql from "graphql-tag";

export const PRODUCT_BRIEFS_QUERY = gql`

query ProductBriefs {
  productBriefs {
    edges {
      node {
        client {
          id
          description
          nickname
        }
        product
        description
        objective
        id
      }
    }
  }
}
`;
