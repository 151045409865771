import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "title-menu-header" }
const _hoisted_2 = { class: "view-title" }
const _hoisted_3 = { class: "action-icons" }

import { ArrowUturnLeftIcon, BookmarkIcon, PencilIcon, RocketLaunchIcon } from '@heroicons/vue/24/outline';
import TechnologyAssetsComparisonCriteriaModal from '@/components/TechnologyAssetsComparisonCriteriaModal.vue';
import { ref } from 'vue';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'TechnologyAssetsComparisonTableHeader',
  props: { title: String },
  setup(__props) {


const store = useStore();

const action = ref('compare');


const openModal = (actionToSet: string) => {
  action.value = actionToSet;
  store.commit('assetsModule/openComparisonCriteriaModal')
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(__props.title), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "round-action-icon",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (openModal('edit')))
        }, [
          _createVNode(_unref(PencilIcon), { class: "icon icon-small" })
        ]),
        _createElementVNode("div", {
          class: "round-action-icon tooltip disable",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (openModal('edit')))
        }, [
          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "tooltiptext danger" }, "Save for later", -1)),
          _createVNode(_unref(BookmarkIcon), { class: "icon icon-small" })
        ]),
        _createElementVNode("div", {
          class: "round-action-icon tooltip disable",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (openModal('edit')))
        }, [
          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "tooltiptext danger" }, "Publish this table", -1)),
          _createVNode(_unref(RocketLaunchIcon), { class: "icon icon-small" })
        ]),
        _createElementVNode("div", {
          class: "round-action-icon",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.go(-1)))
        }, [
          _createVNode(_unref(ArrowUturnLeftIcon), { class: "icon icon-small" })
        ])
      ])
    ]),
    _createVNode(TechnologyAssetsComparisonCriteriaModal, { action: action.value }, null, 8, ["action"])
  ], 64))
}
}

})