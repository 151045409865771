<template>
    <div class="filter-box shadow-md rounded-lg mb-6 bg-nude">
        <div class="filter-title flex justify-between px-4 py-3">
            <div class="flex items-center">
                <div>
                    <p class="title">SUBSTRATE</p>
                    <p class="subtitle">{{ substrate.name }} filtering</p>
                </div>
                <ChartBarSquareIcon class="icon icon-m white thick ml-4" @click="hanldeshowHeatMap" />
            </div>
            <EllipsisHorizontalIcon class="icon icon-small white" @click="showFilterOptions = !showFilterOptions" />
        </div>
        <div class="filter-numbers flex cursor-pointer" @click="showFilterOptions = !showFilterOptions">
            <p class="w-full text-center">84</p>
            <p v-if="filterColor" class="w-full text-center">{{ filterColor }}</p>
            <p v-if="filterGrammage" class="w-full text-center">21</p>
            <p v-if="filterRaughness" class="w-full text-center">{{ filterRaughness }}</p>
        </div>
        <div v-if="showFilterOptions" class="filter-options flex flex-col px-3 py-4 text-sm">
            <p class="uppercase font-medium">Color</p>
            <div class="flex justify-between pr-4">
                <div class="flex justify-between mt-1 mb-3">
                    <div v-for="color in colorOptions" :key="color.value" class="filter-option flex items-center">
                        <input type="checkbox" :value="color.value" v-model="selectedColors">
                        <label>{{ color.label }} </label>
                    </div>
                </div>
                <MagnifyingGlassCircleIcon @click="onFilterLayer('color')" class="icon icon-m" />
            </div>
            <p class="uppercase font-medium">Grammage (gsm)</p>
            <div class="flex justify-between pr-4">
                <div class="flex mt-1 mb-3">
                    <div v-for="grammage in grammageOptions" :key="grammage.value"
                        class="filter-option flex items-center">
                        <input type="checkbox" :value="grammage.value" v-model="selectedGrammages">
                        <label>{{ grammage.label }} </label>
                    </div>
                </div>
                <MagnifyingGlassCircleIcon @click="onFilterLayer('grammage')" class="icon icon-m" />
            </div>
            <p class="uppercase font-medium">Surface roughness</p>
            <div class="flex justify-between pr-4">
                <div class="flex mt-1 mb-3">
                    <div v-for="roughness in roughnessOptions" :key="roughness.value"
                        class="filter-option flex items-center">
                        <input type="checkbox" :value="roughness.value" v-model="selectedroughness">
                        <label>{{ roughness.label }} </label>
                    </div>
                </div>
                <MagnifyingGlassCircleIcon @click="onFilterLayer('roughness')" class="icon icon-m" />
            </div>
        </div>
    </div>

     <!-- HS HEAT MAP -->
     <div v-if="creatingHeatMap || showHeatMap" class="bg-white shadow-md border p-2 mb-4 rounded-lg relative min-h-96 w-full">
        <template v-if="creatingHeatMap">
            <ChartBarIcon class="animate-pulse spin-chart heat" />
        </template>
        <template v-else-if="showHeatMap">
            <XCircleIcon @click="showHeatMap = false"
                class="icon icon-m absolute top-3 z-10 left-3 duration-1000 delay-300"
                :class="{ 'opacity-0': !showHeatMap, 'opacity-100': showHeatMap }" />
                <ChartHeatMapPaper class="transition-opacity duration-1000 delay-300"
                :class="{ 'opacity-0': !showHeatMap, 'opacity-100': showHeatMap }" />
        </template>
    </div>
</template>
<script setup lang="ts">
import { EllipsisHorizontalIcon, ChartBarIcon } from '@heroicons/vue/20/solid';
import { ChartBarSquareIcon, MagnifyingGlassCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import { defineProps, defineEmits, ref } from 'vue';
import ChartHeatMapPaper from './ChartHeatMapPaper.vue';

const props = defineProps(['substrate', 'layerIndex']);
const emit = defineEmits(['filterLayer']);

const showFilterOptions = ref(false);
const creatingHeatMap = ref(false);
const showHeatMap = ref(false);

const colorOptions = [{ label: 'Transparent', value: 13 }, { label: 'Unbleached', value: 10 }, { label: 'Bleached', value: 61 }]
const selectedColors = ref([]);

const grammageOptions = [{ label: '50', value: 1 }, { label: '60', value: 2 }, { label: '70', value: 3 }, { label: '80', value: 4 }, { label: '90', value: 5 }, { label: '100', value: 6 }]
const selectedGrammages = ref([]);

const roughnessOptions = [{ label: '< 0.8', value: 1 }, { label: '< 1', value: 2 }, { label: '< 1.2', value: 3 }, { label: '< 1.5', value: 4 }]
const selectedroughness = ref([]);

const filterColor = ref(null);
const filterGrammage = ref(null);
const filterRaughness = ref(null);

const hanldeshowHeatMap = () => {
    creatingHeatMap.value = true;
    setTimeout(() => {
        showHeatMap.value = true;
        creatingHeatMap.value = false
    }, 1300)
}

const onFilterLayer = (filterType: string) => {
    if (filterType == 'color') {
        const newTotal = selectedColors.value.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        setTimeout(() => {
            filterColor.value = newTotal
            emit('filterLayer', { layerIndex: props.layerIndex, newTotal: filterColor.value, rowNumber: 2 })
        }, 1000)
    }
    if (filterType == 'grammage') {
        setTimeout(() => {
            filterGrammage.value = 21
            emit('filterLayer', { layerIndex: props.layerIndex, newTotal: filterGrammage.value, rowNumber: 3 })
        }, 700)
    }
    if (filterType == 'roughness') {
        const newTotal = selectedroughness.value.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        setTimeout(() => {
            filterRaughness.value = newTotal
            emit('filterLayer', { layerIndex: props.layerIndex, newTotal: filterRaughness.value, rowNumber: 4 })
        }, 500)
    }
}

</script>
<style lang="scss" scoped>
    .spin-chart {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    stroke: none;
    color: $acqua;
    color: #e9f1eb;
    width: 6rem;
    height: 6rem;
    &.heat {
        color: $nude;
    }
}
</style>