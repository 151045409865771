<template>
   <div class="asset-material"> 
      <p>material properties</p>
      <PencilSquareIcon class="icon" @click="handleEdit" v-if="!onEdit"/>
   </div>  
   <div v-if="!onEdit" class="mb-4">
      <div class="flex justify-between items-center flex-wrap">
         <div class="w-1/2" v-if="assetMaterial.blendPercentage">
            <p class="label">Blend Percentage</p>
            <p class="value">{{ assetMaterial.blendPercentage }} </p>
         </div>
         <div class="w-1/2" v-if="assetMaterial.thickness">
            <p class="label">Thickness</p>
            <p class="value">
               {{ assetMaterial.thickness[0] }} - {{ assetMaterial.thickness[1] }} 
               <span v-if="assetMaterial.thicknessUnit" class="lowercase">{{ assetMaterial.thicknessUnit.abbreviation }}</span> </p>
         </div>
      </div>
      <div v-if="assetMaterial.notes">
         <p class="label">Notes</p>
         <p v-html="assetMaterial.notes" class="notes"></p>
      </div>
   </div>
   <div v-else>
      <AssetMaterialPropertiesForm @cancel-edition="onCancelEdition" :asset-material="assetMaterial" />
   </div>
</template>
<script setup lang="ts">
import { AssetMaterialInterface } from '@/models/assetAssessmentInterfaces';
import { PencilSquareIcon } from '@heroicons/vue/24/outline';
import { defineProps, ref} from 'vue';
import AssetMaterialPropertiesForm from './AssetMaterialPropertiesForm.vue';
import { useStore } from 'vuex';

const props = defineProps<{assetMaterial: AssetMaterialInterface}>();
const store = useStore();
const onEdit = ref(false);

const handleEdit = () => {
   store.commit('assetDetailModule/setEditableOn', {sectionToMakeEditbale: 'inDepth' });
   onEdit.value = true;
}

const onCancelEdition = () => {
   onEdit.value = false;
   store.commit('assetDetailModule/setErrorOnAssetMaterialUpdateResponse', {error: null, id: props.assetMaterial.id})
}

</script>
<style lang="scss" scoped>
   .asset-material {
      display: flex;
      align-items: center;
      justify-content: space-between;  
      border-top: 1px dashed #ddd;
      border-bottom: 1px dashed #ddd;
      padding: 0.5rem 0; 
      margin: 1rem 0;
      > p {
         font-weight: 400;
         font-size: 0.9rem;
         text-transform: uppercase;
         color: $font-color-light;
      }
   }
   .icon {
        stroke-width: 1;
        width: 22px;
        height: 22px;
        stroke: #999;
    }
    
    .label {
		font-size: 0.9rem;
		color: $font-color-super-light;
		font-weight: 300;
	}

	.value {
		font-size: 0.9rem;
		color: rgb(109, 109, 109);
		font-weight: 500;
		padding-bottom: 0.5rem;
		overflow: auto;
		text-transform: uppercase;
		overflow: visible;
	}
</style>