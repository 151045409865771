<template>
  <Chart :options="chartOptions"></Chart>
</template>
<script setup>
import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting';
import { Chart } from 'highcharts-vue';
import coloraxisInit from "highcharts/modules/coloraxis";
import moreInit from "highcharts/highcharts-more";

exportingInit(Highcharts);
coloraxisInit(Highcharts);
moreInit(Highcharts);

const chartOptions = {

chart: {
  type: 'bubble',
  plotBorderWidth: 1,
  zoomType: 'xy',
  style: {
    fontFamily: 'Montserrat'
},
},

legend: {
  enabled: false
},

title: {
  text: '<p style="font-size: 1.1rem; color: rgb(55, 65, 81); text-transform: uppercase; font-weight: 500; fill: rgb(55, 65, 81);">OTR and WVTR tests</p>'
},


xAxis: {
  gridLineWidth: 1,
  title: {
    text: 'Sample name'
  },
  labels: {
    format: '{value}'
  },
  plotLines: [{
    color: 'black',
    dashStyle: 'dot',
    width: 2,
    value: 65,
    zIndex: 3
  }]
},

yAxis: {
  startOnTick: false,
  endOnTick: false,
  title: {
    text: 'Test value'
  },
  labels: {
    format: '{value}'
  },
  maxPadding: 0.2,
  plotLines: [{
    color: 'black',
    dashStyle: 'dot',
    width: 2,
    value: 50,
    zIndex: 3
  }]
},

tooltip: {
  useHTML: true,
  headerFormat: '<table>',
  pointFormat: '<tr><th colspan="2"><h3>PM#{point.name}</h3></th></tr>' +
    '<tr><th>{point.type} value:</th><td>{point.y}</td></tr>' +
    '<tr><th>Paper content:</th><td>{point.p_txt}</td></tr>' +
    '<tr><th>Testing conditions (°C, %RH):</th><td>{point.tc}</td></tr>' +
    '<tr><th>Recyclable:</th><td>{point.rc}</td></tr>',
  footerFormat: '</table>',
  followPointer: true
},

plotOptions: {
  series: {
   colorKey: 'pap',
   minSize: '1%',
   maxSize: '10%',
   zMin: 0,  
    zMax: 500,
    
  }
},
 colorAxis: [{
      layout: 'horizontal',
      minColor: '#fff',
      maxColor: '#c7c3c3'
  },{
      layout: 'horizontal',
      minColor: '#ffbaba',
      maxColor: '#ffbaba'
  }, {
      layout: 'horizontal',
      minColor: '#ff7b7b',
      maxColor: '#ff7b7b'
  }, {
      layout: 'horizontal',
      minColor: '#FF5252',
      maxColor: '#FF5252'
  }, {
      layout: 'horizontal',
      minColor: '#a70000',
      maxColor: '#a70000'
  },{
      layout: 'horizontal',
      minColor: '#6497B1',
      maxColor: '#6497B1'
  }, {
      layout: 'horizontal',
      minColor: '#005B96',
      maxColor: '#005B96'
  }, {
      layout: 'horizontal',
      minColor: '#03396C',
      maxColor: '#03396C'
  }, {
      layout: 'horizontal',
      minColor: '#011F4B',
      maxColor: '#011F4B'
  }],

series: [
  {
      colorAxis: 0,
      data: 
      [{"x": 2, "y": 0.1, "pap": 0, "name": 2, "type": "OTR", "p_txt": "NaN", "tc": "23.0:0.0", "rc": "No Info"}, {"x": 2, "y": 0.2, "pap": 0, "name": 2, "type": "OTR", "p_txt": "NaN", "tc": "23.0:0.0", "rc": "No Info"}, {"x": 2, "y": 0.0, "pap": 0, "name": 2, "type": "OTR", "p_txt": "NaN", "tc": "23.0:0.0", "rc": "No Info"}, {"x": 56, "y": 0.0, "pap": 0, "name": 56, "type": "OTR", "p_txt": "NaN", "tc": "23.0:0.0", "rc": "No Info"}, {"x": 56, "y": 0.2, "pap": 0, "name": 56, "type": "OTR", "p_txt": "NaN", "tc": "23.0:0.0", "rc": "No Info"}, {"x": 97, "y": 0.0, "pap": 0, "name": 97, "type": "OTR", "p_txt": "NaN", "tc": "23.0:0.0", "rc": "No Info"}]
  },{
      colorAxis: 1,
      data: []
  },{
      colorAxis: 2,
      data: 
      []
  },{
      colorAxis: 3,
      data: [{"x": 58, "y": 0.0, "pap": 86.37265026, "name": 58, "type": "OTR", "p_txt": "86.4%", "tc": "23.0:0.0", "rc": "No Info"}, {"x": 102, "y": 0.9, "pap": 89.92441036, "name": 102, "type": "OTR", "p_txt": "89.9%", "tc": "23.0:0.0", "rc": "Yes"}, {"x": 114, "y": 0.1, "pap": 92.19418383, "name": 114, "type": "OTR", "p_txt": "92.2%", "tc": "23.0:0.0", "rc": "No Info"}, {"x": 116, "y": 0.1, "pap": 94.67572753, "name": 116, "type": "OTR", "p_txt": "94.7%", "tc": "23.0:0.0", "rc": "No Info"}, {"x": 114, "y": 0.7, "pap": 92.19418383, "name": 114, "type": "OTR", "p_txt": "92.2%", "tc": "23.0:0.0", "rc": "No Info"}, {"x": 114, "y": 0.5, "pap": 92.19418383, "name": 114, "type": "OTR", "p_txt": "92.2%", "tc": "23.0:0.0", "rc": "No Info"}, {"x": 136, "y": 0.0, "pap": 88.72652405, "name": 136, "type": "OTR", "p_txt": "88.7%", "tc": "23.0:0.0", "rc": "No Info"}, {"x": 136, "y": 0.2, "pap": 88.72652405, "name": 136, "type": "OTR", "p_txt": "88.7%", "tc": "23.0:0.0", "rc": "No Info"}]
  },{
      colorAxis: 4,
      data: 
      [{"x": 114, "y": 0.1, "pap": 92.19418383, "name": 114, "type": "OTR", "p_txt": "92.2%", "tc": "23.0:0.0", "rc": "No Info"}, {"x": 116, "y": 0.1, "pap": 94.67572753, "name": 116, "type": "OTR", "p_txt": "94.7%", "tc": "23.0:0.0", "rc": "No Info"}]
  },{
      colorAxis: 5,
      data: []
  },{
      colorAxis: 6,
      data: 
      []
  },{
      colorAxis: 7,
      data: [{"x": 102, "y": 0.4, "pap": 89.9, "name": 102, "type": "WVTR", "p_txt": "89.9%", "tc": "38.0:90.0", "rc": "Yes"}, {"x": 102, "y": 0.9, "pap": 89.9, "name": 102, "type": "WVTR", "p_txt": "89.9%", "tc": "38.0:90.0", "rc": "Yes"}, {"x": 102, "y": 0.5, "pap": 89.9, "name": 102, "type": "WVTR", "p_txt": "89.9%", "tc": "38.0:90.0", "rc": "Yes"}, {"x": 102, "y": 0.6, "pap": 89.9, "name": 102, "type": "WVTR", "p_txt": "89.9%", "tc": "38.0:90.0", "rc": "Yes"}, {"x": 102, "y": 0.1, "pap": 89.9, "name": 102, "type": "WVTR", "p_txt": "89.9%", "tc": "38.0:90.0", "rc": "Yes"}, {"x": 102, "y": 0.7, "pap": 89.9, "name": 102, "type": "WVTR", "p_txt": "89.9%", "tc": "38.0:90.0", "rc": "Yes"}, {"x": 102, "y": 0.2, "pap": 89.9, "name": 102, "type": "WVTR", "p_txt": "89.9%", "tc": "38.0:90.0", "rc": "Yes"}, {"x": 102, "y": 0.3, "pap": 89.9, "name": 102, "type": "WVTR", "p_txt": "89.9%", "tc": "38.0:90.0", "rc": "Yes"}]
  },{
      colorAxis: 8,
      data: 
      [{"x": 102, "y": 0.4, "pap": 89.9, "name": 102, "type": "WVTR", "p_txt": "89.9%", "tc": "38.0:90.0", "rc": "Yes"}, {"x": 102, "y": 0.1, "pap": 89.9, "name": 102, "type": "WVTR", "p_txt": "89.9%", "tc": "38.0:90.0", "rc": "Yes"}, {"x": 102, "y": 0.2, "pap": 89.9, "name": 102, "type": "WVTR", "p_txt": "89.9%", "tc": "38.0:90.0", "rc": "Yes"}]
  }
]

}
</script>
<style scoped>
#container {
  height: 400px;
}

.highcharts-figure,
.highcharts-data-table table {
  min-width: 310px;
  max-width: 800px;
  margin: 1em auto;
}

.highcharts-data-table table {
  border-collapse: collapse;
  border: 1px solid #ddd;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 400;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}
</style>