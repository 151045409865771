<template>
  <div v-if="runGroupDetail" class="page-padding">
    <RunGroupDetail :runGroupDetail="runGroupDetail" :runGroupId="id" />
  </div>
  <div v-else class="loading-gif"></div>
</template>
<script setup lang="ts">
import { computed, ref } from "vue";

import { useRoute } from "vue-router";
import { RUN_GROUP_QUERY } from "@/tea/graphql/queries/runGroupQueries";
import RunGroupDetail from "@/tea/components/rungroups/RunGroupDetail.vue";
import { useStore } from "vuex";
import { useTeaQuery } from "../composables/apollo/useTeaQuery";
import { useRunGroupToRunGroupDetail } from "@/tea/composables/rungroups/useRunGroupToRunGroupDetial";

const route = useRoute();
const store = useStore();
const errorToDisplay = ref(null);
const runGroupDetail = ref(null);
const id = computed<number>(() => {return +route.params.id});

const { onResult, error } = useTeaQuery(RUN_GROUP_QUERY, { id: id }, { fetchPolicy: "cache-and-network" });
if (error) {
  errorToDisplay.value = error;
}
onResult(async (queryResult) => {
  if (queryResult && queryResult.data) {
    if (queryResult.data.runGroup) {
      store.commit("runGroupModule/RESET_UPDATE");
      store.commit("runGroupModule/SET_UPDATE_RUN_GROUP_VALUES", queryResult.data.runGroup);
      runGroupDetail.value = await useRunGroupToRunGroupDetail(queryResult.data.runGroup);
    }
  }
});
</script>
<style lang="scss" scoped></style>
