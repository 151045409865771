import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

import {computed} from "vue";
import { useStore } from 'vuex';
import {v4 as uuid} from 'uuid';
import AIChatHistoryItem from "@/components/ai/AIChatHistoryItem.vue";
import { useQuery } from '@vue/apollo-composable';
import {CHATS_HISTORY} from "@/grapql/aiQuery";
import {useEdgesToChats} from "@/composables/aiComposables";


export default /*@__PURE__*/_defineComponent({
  __name: 'AIChatsHistory',
  setup(__props) {

const store = useStore();

const chatsHistory = computed(()=> store.state.aiModule.chatsHistory);


const {onResult , error} = useQuery(CHATS_HISTORY);
if (error) {
console.log(error);
}
onResult(async queryResult => {
    if (queryResult.data && queryResult?.data?.chats) {
        store.commit('aiModule/setChatsHistory', useEdgesToChats(queryResult.data.chats.edges));
    }
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chatsHistory.value, (chat) => {
    return (_openBlock(), _createElementBlock("div", {
      key: chat.id
    }, [
      _createVNode(AIChatHistoryItem, { chat: chat }, null, 8, ["chat"])
    ]))
  }), 128))
}
}

})