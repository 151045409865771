import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex px-4 flex-wrap"
}
const _hoisted_2 = ["value", "id"]
const _hoisted_3 = ["for"]

import { PROGRAMS_OPTIONS } from '@/grapql/assetsFilterOptionsQueries';
import { useQuery } from '@vue/apollo-composable';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

export interface programVersionInterface {
    id: number, 
    name: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TechnologyAssetsFilterByPrograms',
  setup(__props) {

const store = useStore();
const { result } = useQuery(PROGRAMS_OPTIONS);
const queryResult = computed(() => result?.value ?? null);

const programs = computed<programVersionInterface[]>(() => queryResult.value && queryResult.value.programs.edges.length ? queryResult.value.programs.edges.map((edge) => edge.node) : null);

const selectedPrograms = ref([]);

const setValueInStore = () => store.commit('assetsModule/setSelectedProgramsForFilter', selectedPrograms.value );

onMounted(() => {
    const programsFromStore = store.state.assetsModule.selectedProgramsForFilter;
    selectedPrograms.value = programsFromStore
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex items-center pl-4 mb-4" }, [
      _createElementVNode("p", null, "Select assets linked to: ")
    ], -1)),
    (programs.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(programs.value, (program) => {
            return (_openBlock(), _createElementBlock("div", {
              key: program.id
            }, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedPrograms).value = $event)),
                onChange: setValueInStore,
                value: program.name,
                id: program.name,
                class: "hidden peer"
              }, null, 40, _hoisted_2), [
                [_vModelCheckbox, selectedPrograms.value]
              ]),
              _createElementVNode("label", {
                for: program.name,
                class: "transition-colors duration-200 ease-in-out peer-checked:bg-red-300 peer-checked:text-white cursor-pointer border m-1 px-3 py-2 rounded-lg"
              }, _toDisplayString(program.name), 9, _hoisted_3)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})