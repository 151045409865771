import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center min-h-screen" }
const _hoisted_2 = { class: "grid grid-cols-1 p-6" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = { class: "flex justify-center items-center m-4" }

import {CpuChipIcon} from '@heroicons/vue/24/outline'
import AIFlowBoxes from "@/components/ai/AIFlowBoxes.vue"

export default /*@__PURE__*/_defineComponent({
  __name: 'AIStart',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(CpuChipIcon), { class: "icon icon-x-large" })
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("p", { class: "m-4 uppercase text-gray-500" }, "Welcome to one.five ai platform", -1))
      ]),
      _createVNode(AIFlowBoxes, {
        "show-regulations": true,
        "show-product-brief": true
      })
    ])
  ]))
}
}

})