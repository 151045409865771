<template>
	<div :class="{'flex' :twoColumns}">
		<div :class="{'w-1/2  mr-3' :twoColumns}">
			<label v-if="filterLabel">{{ filterLabel }}</label>
			<label v-else>Filter by:</label>
			<select class="w-full" v-model="filteredValue">
				<option :value="''">Show all</option>
				<option v-for="(option, index) in filterOptions" :key="index">{{ option.name }}</option>
			</select>
		</div>
		<div :class="[twoColumns ? 'w-1/2' : 'mt-2']">
			<label v-if="selectLabel">{{ selectLabel }} <span v-if="isMultiple">(One or more)</span></label>
			<Combobox :modelValue="modelValue" @update:modelValue="value => emit('update:modelValue', value)" :multiple="isMultiple">
				<div class="relative">
					<div class="relative w-full cursor-default overflow-hidden">
						<ComboboxInput class="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 focus:ring-0"
							@change="query = $event.target.value" />
						<ComboboxButton class="absolute inset-y-0 right-0 flex items-center pr-2">
							<ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
						</ComboboxButton>
					</div>
					<TransitionRoot leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0"
						@after-leave="query = ''">
						<ComboboxOptions
							class="options-border z-10 text-xs absolute mt-1 max-h-60 w-full overflow-auto  bg-white py-1 focus:outline-none">
							<div v-if="filteredOption.length === 0 && query !== ''"
								class="relative cursor-default select-none py-2 px-4">
								Nothing found.
							</div>
							<ComboboxOption v-for="option in filteredOption" as="template" :key="option.id"
								:value="option[valueToSelect]" v-slot="{ selected, active }">
								<li class="relative cursor-default select-none py-0.5 pl-10 pr-4"
									:class="{ 'hover-option': active }">
									<span class="block truncate"
										:class="{ 'font-medium': selected, 'font-normal': !selected }">
										{{ option.name }}
									</span>
									<span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3"
										:class="{ 'text-white': active, 'green-color': !active }">
										<CheckIcon class="h-5 w-5" aria-hidden="true" />
									</span>
								</li>
							</ComboboxOption>
						</ComboboxOptions>
					</TransitionRoot>
				</div>
				<div class="selected-results" v-if="isMultiple && Array.isArray(modelValue) && modelValue.length > 0">
					<template v-if="selectOtherThanId">
						<template v-for="(value, index) in modelValue" :key="index">
							<span>
								{{ value }}
								<XMarkIcon class="icon icon-x-small thick inline" @click="removeValue(value)" />
							</span>
						</template>
					</template>
					<template v-else v-for="(option, index) in optionsList" :key="index">
						<span v-if="modelValue.includes(option[valueToSelect])">
							{{ option.name }}
							<XMarkIcon class="icon icon-x-small thick inline" @click="removeValue(option[valueToSelect])" />
						</span>
					</template>
				</div>
				<div class="selected-results" v-if="!isMultiple && modelValue">
					<span>
						{{ singleSelectedName }}
						<XMarkIcon class="icon icon-x-small thick inline" @click="removeValue(modelValue)" />
					</span>
				</div>
			</Combobox>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, computed, defineProps, PropType, defineEmits } from 'vue';
import { Combobox, ComboboxInput, ComboboxButton, ComboboxOptions, ComboboxOption, TransitionRoot } from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface'


const props = defineProps({
	optionsList: { type: Array as PropType<DropdownSearchableOptionInterface[]> },
	selectLabel: { type: String, required: false },
	modelValue: { type: Array || Number || null  },
	selectOtherThanId: { type: String, required: false },
	filterOptions: { type: Array as PropType<DropdownSearchableOptionInterface[]> },
	filterCriteria: { type: String },
	filterLabel: { type: String, required: false },
	twoColumns: { type: Boolean, required: false },
	isMultiple: {type: Boolean, required: true}
})

const emit = defineEmits(['update:modelValue']);

let query = ref('');
const valueToSelect = computed(() => props.selectOtherThanId ? props.selectOtherThanId : 'id')

const removeValue = (optionId) => {
	const newValue = Array.isArray(props.modelValue) ? props.modelValue.filter((value) => value != optionId) : null;
	emit('update:modelValue', newValue)
}

const singleSelectedName = computed(() => 
	props.modelValue
		? props.optionsList.find((option) => option.id == props.modelValue ).name
		: null
);
const filteredValue = ref('');
const filteredOptionList = computed(() =>
	filteredValue.value === ''
		? props.optionsList
		: props.optionsList.filter((option) =>
			option[props.filterCriteria].toLowerCase() === filteredValue.value.toLowerCase()
		)
);

let filteredOption = computed(() =>
	query.value === ''
		? filteredOptionList.value
		: filteredOptionList.value.filter((option) =>
			option.name
				.toLowerCase()
				.replace(/\s+/g, '')
				.includes(query.value.toLowerCase().replace(/\s+/g, ''))
		)
)
</script>

<style lang="scss">
.hover-option {
	background-color: $green-light;
	cursor: pointer;
}

.options-border {
	box-shadow: $card-shadow;
	border-radius: 4px;
}

.green-color {
	color: $green-light;
}
</style>