import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-4" }

import { computed } from "vue";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import accessibilityInit from "highcharts/modules/accessibility"; // Import the accessibility module
import exportingInit from "highcharts/modules/exporting";

// Initialize the accessibility module

export default /*@__PURE__*/_defineComponent({
  __name: 'NpvChart',
  props: ["data"],
  setup(__props) {

accessibilityInit(Highcharts);
// Initialize the exporting module
exportingInit(Highcharts);

const props = __props;
const chartData = computed(() => {
  return props.data.map((item) => ({
    x: item.index,
    y: item.npv,
    color: item.npv < 0 ? "red" : "green",
  }));
});
const chartOptions = {
  chart: {
    type: "column",
  },
  title: {
    text: "Net Present Values",
  },
  xAxis: {
    categories: chartData.value.x,
  },
  yAxis: {
    title: {
      text: "Npvs",
    },
  },
  series: [
    {
      name: "Years",
      data: chartData.value,
    },
  ],
  exporting: {
    enabled: true,
  },
  tooltip: {
    formatter: function () {
      // Customize the tooltip format here
      return `year ${this.x}: <b>${this.y}</b>`;
    },
  },
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Chart), { options: chartOptions })
  ]))
}
}

})