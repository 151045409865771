import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = ["disabled"]

import { FunnelIcon } from '@heroicons/vue/24/outline'
import { ref, computed  } from 'vue';
import ModalSlots from "@/components/ModalSlots.vue";
import { useStore } from 'vuex';
import TechnologyAssetsFilterSortBy from './TechnologyAssetsFilterSortBy.vue';
import TechnologyAssetsFilterColumnsConfiguration from './TechnologyAssetsFilterColumnsConfiguration.vue';
import TechnologyAssetsFilterFilterOptions from './TechnologyAssetsFilterFilterOptions.vue';
import TechnologyAssetsFilterByLayerType from './TechnologyAssetsFilterByLayerType.vue';
import TechnologyAssetsFilterByPrograms from './TechnologyAssetsFilterByPrograms.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TechnologyAssetsFilterModal',
  emits: ['filterRefetch'],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const store = useStore();

const isOpen = computed(() => store.state.assetsModule.filterModalIsOpen);

const closeModal = () => store.commit('assetsModule/closeModal');
const openModal = () => store.commit('assetsModule/openModal');

const defaultView = ref(false);

const handleRestoreValues = () => {
    defaultView.value = !defaultView.value;
    store.commit('assetsModule/restoreAllValues');
}

const handleApplyChanges = () => {
    if (!defaultView.value) {
        store.commit('assetsModule/transfromFormConditionsIntoFilters');
        store.commit('assetsModule/addSelectedLayerTypesAsFilter');
        store.commit('assetsModule/addSelectedProgramsAsFilter');
    }
    defaultView.value = false;

    emit('filterRefetch'); 
    closeModal();
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["round-action-icon", {active: isOpen.value}]),
      onClick: openModal
    }, [
      _createVNode(_unref(FunnelIcon), { class: "icon icon-small" })
    ], 2),
    _createVNode(ModalSlots, {
      show: isOpen.value,
      onCloseModal: closeModal,
      dialogClass: "asset-filter-modal"
    }, {
      header: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("p", null, "view options", -1)
      ])),
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "default-view",
            onClick: handleRestoreValues
          }, [
            _createElementVNode("input", {
              type: "checkbox",
              class: _normalizeClass(["round-checkbox", {selected: defaultView.value}]),
              disabled: defaultView.value
            }, null, 10, _hoisted_2),
            _cache[1] || (_cache[1] = _createElementVNode("p", null, "Restore values", -1))
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "form-separator" }, [
            _createElementVNode("p", { class: "form-subtitle" }, "sort")
          ], -1)),
          _createVNode(TechnologyAssetsFilterSortBy),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "form-separator" }, [
            _createElementVNode("p", { class: "form-subtitle" }, "filter")
          ], -1)),
          _createVNode(TechnologyAssetsFilterFilterOptions),
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "form-separator" }, [
            _createElementVNode("p", { class: "form-subtitle" }, "Layer functionality")
          ], -1)),
          _createVNode(TechnologyAssetsFilterByLayerType),
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "form-separator" }, [
            _createElementVNode("p", { class: "form-subtitle" }, "Programmes")
          ], -1)),
          _createVNode(TechnologyAssetsFilterByPrograms),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "form-separator" }, [
            _createElementVNode("p", { class: "form-subtitle" }, "Columns")
          ], -1)),
          _createVNode(TechnologyAssetsFilterColumnsConfiguration)
        ])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("button", {
          class: "button bg-stone-800 text-white",
          onClick: handleApplyChanges
        }, "Apply")
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}
}

})