<template>
    <div class="page-padding"> 
        <TitleGoBackHeader title="polymers" :show-filter="true" @toogle-filter="toogleFilter()" />
        <PolymersSearch v-if="showHideFilter && propertiesList" :properties-list="propertiesList" @apply-filter="filterPolymer" @clear-filter="clearFilter"/>
        <div v-if="!filteredPolymers" class="loading-gif"></div>
        <div v-else>
            <p v-if="filteredPolymers.length == numberOfPolymers" class="leyend">Showing {{ numberOfPolymers }} polymers</p>
            <p v-else class="leyend"> Showing {{ filteredPolymers.length }} polymers out of {{ numberOfPolymers }}</p>
        
        <div class="polymers-list">
            <div v-for="(polymer, index) in filteredPolymers" :key="index" class="polymer-card">
                <div class="poly-name" v-if="polymer.general">
                    <p>{{ polymer.general['Common name'].value }} </p>
                    <span v-if="polymer.general['Acronym']">({{  polymer.general['Acronym'].value }})</span>
                </div>
                <div v-for="(property, key, index) in polymer" :key="index" class="m-3">
                    <template v-if="key != '_id'">
                        <Disclosure v-slot="{ open }" :default-open="index === 1">
                            <DisclosureButton :data-headlessui-state="open" :aria-extended="true"
                                class="property-collapse mb-3 flex w-full justify-between px-4 py-2 text-left text-sm  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-75"
                            >
                                <span class="capitalize">{{key}}</span>
                                <ChevronUpIcon
                                    :class="open ? 'rotate-180 transform' : ''"
                                    class="h-5 w-5 "
                                />
                            </DisclosureButton>
                            <DisclosurePanel class="px-4 mb-4">
                                <div class="mb-3" v-for="(value, key, index) in property" :key="index">
                                    <p class="key">{{ key }}</p>
                                    <p class="value">{{ value.value }} {{ value.unit }} </p>
                                    <p class="value" v-if="value.reference">{{ value.reference}} </p>
                                </div>
                            </DisclosurePanel>
                        </Disclosure>
                    </template>
                </div>
            </div> 
        </div>
    </div>
    </div>
</template>
<script setup lang="ts">

// import polymersData from '@/assets/jsonData/polymer_data.json';
import TitleGoBackHeader from '@/components/TitleGoBackHeader.vue';
import PolymersSearch from '@/components/PolymersSearch.vue';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { ChevronUpIcon } from '@heroicons/vue/20/solid';
import { onMounted, ref } from 'vue';
import { app, credentials } from '@/mongo.client';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';


interface polymerPropertyInterface {
    [key:string] : {
        value: string,
        unit: string,
        reference?: string | unknown
    }
}
type polymer = {
    [key:string] : polymerPropertyInterface
}

interface formConditionInterface {
    condition: string;
    property: string;
    relation: string;
    value: string
}

const polymers = ref<polymer[]>(null);
const filteredPolymers = ref<polymer[]>(null);
const numberOfPolymers = ref<number>(null);
const propertiesList = ref<DropdownSearchableOptionInterface[]>(null);
const showHideFilter = ref(false);
const toogleFilter = () => showHideFilter.value = !showHideFilter.value;
const clearFilter = () => filteredPolymers.value = polymers.value


function isPolymerSiutable(value) {
    const polymerInternalValues:polymerPropertyInterface[] = Object.values(value);
    const polymerPropertiesNames = polymerInternalValues.map(poly => Object.keys(poly)).flat();
    const conditionsProperties = this.map(fc => fc.property);
    const doesThisPolymerHasSomeOfThePropertiesConditions = polymerPropertiesNames.some(propertyName => conditionsProperties.includes(propertyName));
    let isSuitable;
    if (!doesThisPolymerHasSomeOfThePropertiesConditions) {
        return false;
    }
    else {
        polymerInternalValues.map(po => {
            this.map(fc => { 
                if(fc.condition && fc.condition == 'and' && po[`${fc.property}`] && po[`${fc.property}`].value) {
                isSuitable = isSuitable && singleConditionEvaluation(po, fc);
                }
                if(fc.condition && fc.condition == 'or' && po[`${fc.property}`] && po[`${fc.property}`].value) {
                    isSuitable = isSuitable || singleConditionEvaluation(po, fc);
                }
                if(!fc.condition && po[`${fc.property}`] && po[`${fc.property}`].value) {
                    console.log(po[`${fc.property}`], ': ', po[`${fc.property}`].value)
                    isSuitable = singleConditionEvaluation(po, fc);
                }

            })
        })
        return isSuitable;
    }
    
}

const singleConditionEvaluation = (polymer:polymerPropertyInterface, filterCondition:formConditionInterface) => {
    let resultEvaluation = false
    if( polymer[`${filterCondition.property}`]) {
        switch(filterCondition.relation) {
            case 'includes': 
            resultEvaluation = polymer[`${filterCondition.property}`].value.toLowerCase().includes(filterCondition.value.toLowerCase());
                break;
            case 'Notincludes': 
            resultEvaluation = !polymer[`${filterCondition.property}`].value.toLowerCase().includes(filterCondition.value.toLowerCase());
                break;
            case '==': 
            resultEvaluation = polymer[`${filterCondition.property}`].value.toLowerCase() == filterCondition.value.toLowerCase();
                break;
            case '!==': 
            resultEvaluation = polymer[`${filterCondition.property}`].value.toLowerCase() != filterCondition.value.toLowerCase();
                break;
            case '>=': 
            resultEvaluation = polymer[`${filterCondition.property}`].value.toLowerCase() >= filterCondition.value.toLowerCase();
                break;
            case '<=': 
            resultEvaluation = polymer[`${filterCondition.property}`].value.toLowerCase() <= filterCondition.value.toLowerCase();
                break;
        }
    }
    return resultEvaluation;
}



const filterPolymer = (filterConditions:formConditionInterface[]) => {   
    filteredPolymers.value  = polymers.value.filter(isPolymerSiutable, filterConditions );
};

const getPropertiesList = () => { 
    const keysOfKeys = polymers?.value.map((polymer) => {
        const firstLevel = Object.values(polymer)
        return firstLevel.map(poly => Object.keys(poly)).flat()
    });
    const keysNoRepetition = [...new Set(keysOfKeys.flat())].sort();
    propertiesList.value = keysNoRepetition.reduce((o, key, index) => ([ ...o, { 'id': index, 'name': key}]), []);
}

const getPolymers = async () => {
    const user = await app.logIn(credentials);
    const listOfPolymers = user.functions.polymers();
    listOfPolymers.then((resp) => {
        polymers.value = resp;
        filteredPolymers.value = resp;
        numberOfPolymers.value = resp.length;
        getPropertiesList();
        
      });
}

onMounted(() => {
     getPolymers()
    
})


</script>
<style lang="scss" scoped>
.leyend {
    margin-bottom: 1rem;
    padding-left: 0.5rem;
    font-size: 0.9rem;
    font-weight: 500;
    color: #6b8c7f;
}
    .polymers-list {
        display: flex;
        display: flex;
        align-items: start;
        flex-wrap: wrap;
        justify-content: space-between;
        .polymer-card {
            background-color: $grey-super-light;
            border: $border;
            width: 32.7%;
            margin-bottom: 1rem;
            border-radius: 8px;
            color: $font-color-light;
            font-size: 0.9rem;
            padding-bottom: 1rem;
            .poly-name {
                padding: 1.5rem 1rem 1rem;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 1.1rem;
                color: $font-color;
                p {overflow-wrap: anywhere;}
                span {
                    display: inline-block;
                    font-weight: 300;
                }
            }
            .property-collapse {
                border-radius: 0;
                font-weight: 300;
                text-transform: uppercase;
                border-radius: 8px;
                border: $border;
                &:hover, &[aria-expanded="true"] {
                    background-color: #D3EAE8;
                }
            }    
            .key {
                font-weight: 300;
                font-size: 0.8rem;
                text-transform: uppercase;
                color: $font-color-super-light;
            }
            .value {
                font-weight: 400;
                color: $font-color-light;
                font-size: 0.9rem;
                word-break: break-word;
            }
        }   
    }
    
</style>