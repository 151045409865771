import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, vModelText as _vModelText, createCommentVNode as _createCommentVNode, isRef as _isRef, withKeys as _withKeys, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-container m-4 mt-0" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "grid grid-cols-5 gap-y-2.5 gap-x-4" }
const _hoisted_4 = ["value"]
const _hoisted_5 = {
  key: 0,
  class: "selected-results"
}
const _hoisted_6 = { class: "col-span-1 sm:col-span-5 md:col-span-1" }
const _hoisted_7 = {
  key: 2,
  class: "col-span-5"
}
const _hoisted_8 = { key: 1 }

import { computed, ref } from "vue";
import { useStore } from "vuex";
import FormErrorToast from '@/components/FormErrorToast.vue';
import {RESEARCH_PAPER_AUTOFILL_FIELDS} from '@/models/researchPaperAutofillFormFields';
import { XMarkIcon, InformationCircleIcon } from '@heroicons/vue/24/outline';
import ResearchPaperAutoFillExtract from "@/components/ResearchPaperAutoFillExtract.vue";
import ResearchPaperAutoFillExtractInfo from "@/components/ResearchPaperAutoFillExtractInfo.vue";





export default /*@__PURE__*/_defineComponent({
  __name: 'ResearchPaperAutoFill',
  setup(__props) {

const store = useStore();

const formSubmited = ref(false);
const searchFieldOptions = [
  { key: "doi", value: "DoiId" },
  { key: "title", value: "Title" },
  { key: "title_terms", value: "Title Terms" },
  { key: "mesh_terms", value: "Mesh Terms" },
  { key: "raw", value: "Raw" },
];
// Create Fields for the form
const fieldsFromConstant = computed<string[]>(() => RESEARCH_PAPER_AUTOFILL_FIELDS['SEARCH'].formFields.map(formField =>  { return formField.mutationValue} ));


const formFieldOptions = {
    fields: fieldsFromConstant.value,
    base: RESEARCH_PAPER_AUTOFILL_FIELDS['SEARCH'].graphqlValue,
    mutation: 'researchPaperAutofillModule/setSingleValue'
};
const formFields = (formFieldOptions:{fields:string[], base:string, mutation:string}) => {
    const object = {};
    formFieldOptions.fields.map(fieldName => {
        object[fieldName] = computed({
            get() {
                return store.state.researchPaperAutofillModule[formFieldOptions.base][fieldName];
            },
            set(value) {
                store.commit(formFieldOptions.mutation, { field: fieldName , value: value});
            }
        })
    });
    return object;
}

const vModels = formFields(formFieldOptions);

const isSingleValue = computed(()=>['title','doi','raw'].includes(vModels['searchField'].value))

var tempSearchTerms = '';
const handleInputChange = (event: Event) => (event.target as HTMLInputElement).value;
const handleEnterKey = (event) =>{
    const val =handleInputChange(event).trim()
    if(val !== ''){
        vModels['searchValue'].value.push(handleInputChange(event).trim());
        tempSearchTerms = '';
    }
}
const handleRemoveSearchTerm = (index)=>{
    store.commit("researchPaperAutofillModule/removeSearchTerm", index);
}

const valuesSubmitted = computed(() => store.state.researchPaperAutofillModule.search);
const formIsValid = computed(() => RESEARCH_PAPER_AUTOFILL_FIELDS['SEARCH'].mandatoryFormFields.every(mf => {
    if(mf=="searchValue"){
        return !!valuesSubmitted.value[mf].length;
    }
    return !!valuesSubmitted.value[mf]
}));
const errorFields = computed(() => RESEARCH_PAPER_AUTOFILL_FIELDS['SEARCH'].mandatoryFormFields.filter(mf => {
    if(mf=="searchValue"){
        return valuesSubmitted.value[mf].length>0 ? false: true;
    }
    return !valuesSubmitted.value[mf]
}) );

const showSearchResult = computed(()=> store.state.researchPaperAutofillModule.showSearchResult);
const showExtractResult = computed(()=> store.state.researchPaperAutofillModule.showExtractResult);
const searchInProgress = computed(()=> store.state.researchPaperAutofillModule.searchInProgress);
const searchError = computed(()=> store.state.researchPaperAutofillModule.searchError);
const buttonText= computed(()=> searchInProgress.value ? "Searching" : "Search");

const searchResearchPapers = async () => {
    formSubmited.value = true;
    if (formIsValid.value && !searchInProgress.value) {
        const query = RESEARCH_PAPER_AUTOFILL_FIELDS['SEARCH'].searchQuery;
        await store.dispatch(query)
        .catch((error)=>{
            // since the search is throwing error, enable pdf upload
            store.commit("researchPaperAutofillModule/setSearchResult", []);
        })
        .then(()=>{
            formSubmited.value = false;
        });
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(FormErrorToast, {
      "is-showing": formSubmited.value && !formIsValid.value,
      title: "The following fields are mandatory:",
      subtitle: errorFields.value.join(', ')
    }, null, 8, ["is-showing", "subtitle"]),
    _createVNode(FormErrorToast, {
      "is-showing": searchError.value?true:false,
      title: "Error while searching:",
      subtitle: searchError.value
    }, null, 8, ["is-showing", "subtitle"]),
    (!showExtractResult.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "form-separator col-span-5 m-2" }, [
              _createElementVNode("p", { class: "form-subtitle" }, "SEARCH FOR RESEARCH PAPER")
            ], -1)),
            _createElementVNode("div", {
              class: _normalizeClass(["col-span-1 sm:col-span-5 md:col-span-1", {'error-form-field': errorFields.value.includes('searchField') && formSubmited.value }])
            }, [
              _cache[4] || (_cache[4] = _createElementVNode("label", null, "Search Field", -1)),
              _withDirectives(_createElementVNode("select", {
                class: "w-full",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(vModels)['searchField'].value) = $event))
              }, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(searchFieldOptions, (option) => {
                  return _createElementVNode("option", {
                    key: option.key,
                    value: option.key
                  }, _toDisplayString(option.value), 9, _hoisted_4)
                }), 64))
              ], 512), [
                [_vModelSelect, _unref(vModels)['searchField'].value]
              ])
            ], 2),
            (isSingleValue.value)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["col-span-3 sm:col-span-5 md:col-span-3", {'error-form-field': errorFields.value.includes('searchValue') && formSubmited.value }])
                }, [
                  _cache[5] || (_cache[5] = _createElementVNode("label", null, [
                    _createTextVNode("Search Value "),
                    _createElementVNode("span", null, "(One)")
                  ], -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: "w-full",
                    type: "text",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(vModels)['searchValue'].value) = $event))
                  }, null, 512), [
                    [_vModelText, _unref(vModels)['searchValue'].value]
                  ])
                ], 2))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(["col-span-3 sm:col-span-5 md:col-span-3", {'error-form-field': errorFields.value.includes('searchValue') && formSubmited.value }])
                }, [
                  _cache[6] || (_cache[6] = _createElementVNode("label", null, [
                    _createTextVNode("Search Value "),
                    _createElementVNode("span", null, "(One or more) press enter to add more terms")
                  ], -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: "w-full",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(tempSearchTerms) ? (tempSearchTerms).value = $event : tempSearchTerms = $event)),
                    type: "text",
                    onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (handleEnterKey($event)), ["enter"]))
                  }, null, 544), [
                    [_vModelText, _unref(tempSearchTerms)]
                  ]),
                  (_unref(vModels)['searchValue'].value.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(vModels)['searchValue'].value, (term, index) => {
                          return (_openBlock(), _createElementBlock("span", { key: index }, [
                            _createTextVNode(_toDisplayString(term) + " ", 1),
                            _createVNode(_unref(XMarkIcon), {
                              class: "icon icon-x-small thick inline",
                              onClick: ($event: any) => (handleRemoveSearchTerm(index))
                            }, null, 8, ["onClick"])
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ], 2)),
            _createElementVNode("div", _hoisted_6, [
              _cache[7] || (_cache[7] = _createElementVNode("label", null, " ", -1)),
              _createElementVNode("span", {
                class: _normalizeClass(["button green-accept cursor-pointer text-center w-full", { 'inactive': searchInProgress.value }]),
                style: {"padding":"4px 20px","display":"inline-block"},
                onClick: searchResearchPapers
              }, _toDisplayString(buttonText.value), 3)
            ]),
            (searchInProgress.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[8] || (_cache[8] = [
                  _createElementVNode("div", { class: "text-center p-6" }, [
                    _createElementVNode("div", { class: "loading-gif-transparent" })
                  ], -1)
                ])))
              : (showSearchResult.value && !searchInProgress.value)
                ? (_openBlock(), _createBlock(ResearchPaperAutoFillExtract, { key: 3 }))
                : _createCommentVNode("", true)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(ResearchPaperAutoFillExtractInfo)
        ]))
  ]))
}
}

})