export const isNullOrEmptyArray = (value: any): boolean => {
    return value === null || value === undefined || (Array.isArray(value) && value.length === 0);
};

export const generateProductBriefQuestion = (productBrief: any): string => {
    let question = '<p>Hi, I am looking for a packaging solution with the following specifications: </p>';
    question += '<ul class="list-disc pl-5">';
    for(const key in productBrief){
        if(productBrief[key] === null || productBrief[key] === undefined){
            continue;
        }else{
            question += `<li>${key}:&nbsp; ${productBrief[key]}</li>`;
        }
    }
    question += '</ul>';
    return question;

}

export const generateRegulationsQuestion = (regulations: any): string => {
    let question = '<p>Can you please look through packaing regulations with the following creteria: </p>';
    question += '<ul class="list-disc pl-5">';
    for(const key in regulations){
        if(regulations[key] === null || regulations[key] === undefined){
            continue;
        }else{
            question += `<li>${key}:&nbsp; ${regulations[key]}</li>`;
        }
    }
    question += '</ul>';
    return question;
}

export const generateGeneralQuestion = (regulations: any): string => {
    let question = '';
    question += '<ul class="list-disc pl-5">';
    for(const key in regulations){
        if(regulations[key] === null || regulations[key] === undefined){
            continue;
        }else{
            question += `<li>${key}:&nbsp; ${regulations[key]}</li>`;
        }
    }
    question += '</ul>';
    return question;
}



/**
 * Creates a function that retrieves a value from a deeply nested state path within the Vuex store.
 * 
 * This function generates a getter function based on a given module and a nested path in the Vuex store. 
 * When the returned function is invoked, it will access and return the value at the specified nested state path.
 * 
 * @param {Object} store - The Vuex store instance, which contains the state.
 * @param {string} module - The Vuex module where the state is located. The module name is used to access the correct part of the state.
 * @param {string} base - The nested state path within the module, specified as a string with keys separated by '.' or '/'. 
 *                        For example, "somePath.subPath" or "somePath/subPath".
 * 
 * @returns {Function} A function that, when invoked, returns the value located at the nested state path. 
 *                     The function uses the `base` string to traverse the state object using the keys derived from splitting the `base` string.
 * 
 * @example
 * const getter = generateStatePathGetter(store, 'myModule', 'nested/path.to/value');
 * const value = getter(); // Retrieves the value from store.state.myModule.nested.path.to.value
 */
export function generateStatePathGetter(store,module,base){
    // Split by both '.' and '/'
    const baseKeys= base.split(/[./]/);
    
    // Return a function that will access the nested state when invoked
    return () => baseKeys.reduce((acc, key) => acc && acc[key], store.state[module]);
}

