<template>
  <div>
    <div class="title-menu-header">
      <h1 class="view-title">
        {{ title }} &nbsp; <PopUp :content="RUN_GROUP_HELP_CONTENT"><span class="round-tooltip-icon">?</span></PopUp>
      </h1>
      <div class="action-icons">
        <RunGroupAddNew @on-created="$emit('onCreated')" />
        <SearchAsYouType @search-text="onSearchText" />
      </div>
    </div>
    <div class="show-assets">
      <h2 class="view-subtitle">Loaded {{ edgeCount }} of {{ totalCount }} run groups</h2>
    </div>
  </div>
</template>
<script setup lang="ts">
import { defineProps, defineEmits } from "vue";
import PopUp from "@/tea/components/PopUp.vue";
import SearchAsYouType from "@/components/SearchAsYouType.vue";
import RunGroupAddNew from "./RunGroupAddNew.vue";

const RUN_GROUP_HELP_CONTENT = "Group Similar Runs (Scenarios), so that you can compare and do sensitivity analysis";

const props = defineProps(["title", "edgeCount", "totalCount"]);
const emit = defineEmits(["searchText", "onCreated"]);

const onSearchText = (searchValue: string) => emit("searchText", searchValue);
</script>

<style lang="scss" scoped>
.show-assets {
  margin-bottom: 1.5rem;
  margin-left: 2px;
  transform: translateY(-2rem);
}
</style>
