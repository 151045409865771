<template>
    <div>
        <div class="flex items-stretch justify-between mb-3">
                        <template v-for="protoDesign in alternativeDesigns" :key="protoDesign.id">
                            <LayersDesignVotingCard :prototype-design-info="protoDesign" @feedback="handleNewFeedback"/>
                        </template>
                    </div>
                    <div class="flex items-stretch justify-between mb-3">
                        <template v-for="protoDesign in alternativeDesigns" :key="protoDesign.id">
                            <LayersDesignFeedback :all-feedbacks="allFeedbacks" :prototype-design-id="protoDesign.id" /> 
                        </template>
                    </div>
    </div>
</template>
<script setup lang="ts">
import LayersDesignVotingCard from "./LayersDesignVotingCard.vue";
import LayersDesignFeedback from "./LayersDesignFeedback.vue";
import { designFeedbackInterface, prototypesDesignsInfoInterface } from "@/models/programsInterface";
import { PropType, defineProps, onMounted, ref } from "vue";

defineProps({
    alternativeDesigns: { type: Array as PropType<prototypesDesignsInfoInterface[]> }
});

const allFeedbacks = ref([]);

const handleNewFeedback = (feedback:designFeedbackInterface) => {
    allFeedbacks.value.push(feedback);
    localStorage.setItem('feedback', JSON.stringify(allFeedbacks.value));
    console.log('despues del emit: ' ,allFeedbacks.value)
}

onMounted(() => {
    allFeedbacks.value = JSON.parse(localStorage.getItem('feedback')) ? JSON.parse(localStorage.getItem('feedback')) : [] ;  
})

</script>
<style lang="scss" scoped>
    
</style>