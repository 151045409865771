<template>
  <div>
    <div class="round-action-icon" :class="{ active: isOpen }">
      <PlusIcon class="icon icon-small" @click="openModal" />
    </div>
    <ModalSlots
      :show="isOpen"
      @close-modal="closeModal"
      @reset-modal="resetModal"
      @click-create="createNewRun"
      dialogClass="mx-auto max-w-[80%] min-w-[80%]"
      :hideFooter="hideFooter"
      :create-text="createText"
      :create-in-progress="createInProgress"
    >
      <template v-slot:header><p>NEW TEA</p></template>
      <template v-slot:body>
        <!-- <LoaderPage v-if="showFilesUploadLoader" loader-text="Creating Asset" gif-position="center"/> -->
        <div class="forms-container">
          <p>
            <br />
            Under no circumstances can Karen replace a detailed analysis of each technology, but it is rather a comparative tool helping in
            the selection process of biotechnology projects
          </p>
          <RunAddNewForm />
        </div>
      </template>
    </ModalSlots>
  </div>
</template>
<script setup lang="ts">
import { PlusIcon } from "@heroicons/vue/24/outline";
import { ref, computed, defineEmits,onMounted } from "vue";
import { useStore } from "vuex";
import ModalSlots from "@/components/ModalSlots.vue";
import RunAddNewForm from "./RunAddNewForm.vue";
import { NEW_RUN_FIELDS } from "@/tea/models/run";

const createText = ref("Save Run")
const createInProgress = ref(false)


onMounted(()=>{
  createInProgress.value=false
  createText.value="Save Run";
});

const store = useStore();
const isOpen = ref(false);
const hideFooter = computed(()=>!store.getters["runCreateModule/canSubmit"]);
const collapsed = computed(() => store.getters["authModule/isSidebarCollapsed"]);
const toggleSidebar = () => store.commit("authModule/toogleSidebar");
const closeModal = () => {
  isOpen.value = false;
  // formSubmited.value = false;
};

const openModal = () => {
  if (!collapsed.value) {
    toggleSidebar();
  }
  isOpen.value = true;
};

const resetModal=async ()=>{
  store.commit('runCreateModule/SET_IS_FORM_SUBMITTED',false);
  store.commit("runCreateModule/RESET_CREATE");
}

const emit = defineEmits(["onCreated"]);

// const queryError = computed(() => store.getters["runCreateModule/fields"]);
const canSubmit = computed(()=> store.getters["runCreateModule/canSubmit"])
const createNewRun =async () => {
  if (canSubmit.value) {
    createText.value = "Saving Run . . .";
    createInProgress.value=true;
    const mutation = NEW_RUN_FIELDS.createMutation;
    await store
      .dispatch(mutation)
      .then(() => {
        closeModal();
        resetModal();
        emit("onCreated");
        createInProgress.value=false
        createText.value="Save Run";
      })
      .catch((error) => {
        createText.value = "Save Run";
        createInProgress.value=false;
        console.log(error);
      });
  }
};

</script>

<style lang="scss">
.forms-container {
  background: #fff;
  padding: 0rem 2rem 4rem;
  max-height: 70vh;
  overflow: scroll;
}
</style>
