<template>
    <div class="border border-gray-200 shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div class="mb-2" :class="{ 'error-form-field': errorFields.includes('countries') && formSubmited }">
            <label class="block">In which country(ies) do you intend to market your product?</label>
            <DropdownSearchableMultiple :options-list="countries" v-model="vModels['countries'].value" />
        </div>
        <div class="mb-2" :class="{ 'error-form-field': errorFields.includes('application') && formSubmited }">
            <label class="block">What is the intended application of your product?</label>
            <select v-model="vModels['application'].value" class="w-full">
                <option value="" disabled selected>Select Application</option>
                <option v-for="application in applications" :key="application.id" :value="application.id">{{application.name}}</option>
            </select>
            <!-- <DropdownSearchableSingle  :options-list="applications" v-model="vModels['application'].value" /> -->
        </div>
        <div class="mb-2 mt-5">
            <div class="flex">
                <button @click="handleAsk" class="ml-auto py-2 px-5 rounded-none  hover:shadow-md active:shadow-inner button cancel" type="button">
                    <PlayIcon class="icon icon-small" />
                </button>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { computed, defineProps,ref } from 'vue';
import { useStore } from 'vuex';
import { PlayIcon } from '@heroicons/vue/24/outline';
import DropdownSearchableMultiple from '../DropdownSearchableMultiple.vue';
// import DropdownSearchableSingle from '../DropdownSearchableSingle.vue';
import { REGULATIONS_FORM_FIELDS } from '@/models/aiModels';
import { isNullOrEmptyArray } from "@/utils/utils";

const store = useStore();

const countries = [
    { id: "Germany", name: "Germany" },
    { id: "UK", name: "UK" },
    { id: "Spain", name: "Spain" },
    { id: "Italy", name: "Italy" },
    { id: "France", name: "France" },
    { id: "EU", name: "EU" },
    ];

const applications = [
    {id:"dishwasher tablets", name:"Dishwasher Tablets"},
]

// Create Fields for the form
const fieldsFromConstant = computed<string[]>(() => REGULATIONS_FORM_FIELDS.formFields.map(formField =>  { return formField.mutationValue} ));
const formFieldOptions = {
    fields: fieldsFromConstant.value,
    base: REGULATIONS_FORM_FIELDS.graphqlValue,
    mutation: 'aiModule/setRegulationsSingleValue',
};
const formFields = (formFieldOptions:{fields:string[], base:string, mutation:string}) => {
    const object = {};
    formFieldOptions.fields.map(fieldName => {
            object[fieldName] = computed({
                get() {
                    return store.state.aiModule.ask[formFieldOptions.base][fieldName];
                },
                set(value) {
                    store.commit(formFieldOptions.mutation, { field: fieldName , value: value});
                }
            })
    });
    return object;
}
const vModels = formFields(formFieldOptions);


const formSubmited = ref(false);
const valuesSubmitted = computed(() => store.state.aiModule.ask[REGULATIONS_FORM_FIELDS.graphqlValue]);
const formIsValid = computed(() => REGULATIONS_FORM_FIELDS.mandatoryFormFields.every(mf => {
    return !isNullOrEmptyArray(valuesSubmitted.value[mf]);
}));
const errorFields = computed(() => REGULATIONS_FORM_FIELDS.mandatoryFormFields.filter(mf => {
    return isNullOrEmptyArray(valuesSubmitted.value[mf]);
}) );


const handleAsk = async () => {
    formSubmited.value = true;
    if(formIsValid.value){
        formSubmited.value=false;

        // store.commit("aiModule/setRegulationsStart");
        // store.commit("aiModule/addMessage",{type:"question",messageId:null,value:store.state.aiModule.ask.regulations});
        await store.dispatch("aiModule/askRegulations");
    }
}

</script>