<template>
    <div v-if="teamToDisplay.length" class="col-span-5">
        <div class="team-member-container">
            <div v-for="(member, index) in teamToDisplay" :key="index" class="team-member-card shadow-md" :class="{'to-delete': onEdit && teamMembersToDelete.includes(member.id)}">
                <div class="flex">
                    <div v-if="onEdit && teamMembersToDelete.includes(member.id)" class="flex trash">
                        <TrashIcon class="icon icon-small" />
                    </div>
                    <div class="flex flex-col">
                        <p class="name">{{ member.personName }}</p>
                        <p>{{ member.role }}</p>
                    </div>
                </div>
                <div v-if="onEdit && teamMembersToDelete.includes(member.id)" class="flex items-center" >
                    <ArrowUturnLeftIcon class="icon icon-small ml-3" @click="removeMemberToDelete(member.id)" />
                </div>
                <div v-else class="flex items-center">
                    <PencilSquareIcon  v-if="onEdit && member.id" class="icon icon-small mr-4" @click="setTeamMemberToEdit(member.id)"  />
                    <XCircleIcon v-if="onEdit && member.id" class="icon icon-small " @click="addMemberToDelete(member.id)" />
                    <XCircleIcon v-else class="icon icon-small"  @click="removeTeamMember(index)"/>
                </div>
            </div>
        </div>
    </div>
    <!-- Edition Form --> 
    <template v-if="onEdit && teamMemberIndexToEdit !=  null">
        <div class="col-span-2">
            <label>Role </label>
            <DropdownSearchableSingleVue v-model="programVersionTeam[teamMemberIndexToEdit].teamRoleType" :options-list="teamRoleTypesOptions" />
        </div>
        <div class="col-span-2">
            <label>Member</label>
            <DropdownSearchableSingleVue v-model="programVersionTeam[teamMemberIndexToEdit].user" :options-list="employeesOptions" />
        </div>
        <div class="col-sapn-1">
            <button class="black-button-with-icon" @click.prevent="updateTeamMember(programVersionTeam[teamMemberIndexToEdit].id)">
                <CheckIcon class="icon icon-x-small light thick mr-2" />
                Save this Changes
            </button>
        </div>
    </template>
    <!-- New Member form -->
    <div class="col-span-2">
        <label>Role </label>
        <DropdownSearchableSingleVue v-model="newMember.teamRoleType" :options-list="teamRoleTypesOptions" />
    </div>
    <div class="col-span-2">
        <label>Member</label>
        <DropdownSearchableSingleVue v-model="newMember.user" :options-list="employeesOptions" />
    </div>
    <div class="col-sapn-1">
        <button class="black-button-with-icon" @click.prevent="addMemberToTheTeam" :disabled="isTeamMemberDisabled">
            <CheckIcon class="icon icon-x-small light thick mr-2" />
            Add to the team
        </button>
    </div>
    <div v-if="isTeamMemberComplete" class="col-span-5">
        <div class="disclaimer-team items-center flex">
                <InformationCircleIcon class="icon icon-small thick mr-2" />
                <p> Click the button to add this member to the team</p>
        </div>
    </div>
</template>
<script setup lang="ts">
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { XCircleIcon, CheckIcon, InformationCircleIcon, PencilSquareIcon, ArrowUturnLeftIcon, TrashIcon } from '@heroicons/vue/24/outline'
import { TeamMemberInterface } from '@/models/programVersionsInterface';
import DropdownSearchableSingleVue from '@/components/DropdownSearchableSingle.vue'
import { defineProps, ref, computed } from 'vue';
import { useStore } from 'vuex';


const props = defineProps<{
    teamRoleTypesOptions: DropdownSearchableOptionInterface[],
    employeesOptions: DropdownSearchableOptionInterface[],
    onEdit: boolean
}>()

const store = useStore();

const newMember = ref({
    teamRoleType: null,
    user: null
})

const programVersionTeam = computed<TeamMemberInterface[]>(() => props.onEdit ? store.state.programVersionDetailModule['programVersionTeamForEdition'] : store.state.programsCreationModule['programVersion']['programVersionTeam']);
const teamToDisplay = computed(() => { 
    const members = [];
    if (programVersionTeam.value.length) {
        programVersionTeam.value.map(teamMember => {
            const displayMember = {
                id: teamMember.id ? teamMember.id : null,
                role: props.teamRoleTypesOptions.find(teamRole => teamRole.id == teamMember.teamRoleType).name,
                personName: props.employeesOptions.find(employee => employee.id == teamMember.user).name,
            }
            members.push(displayMember);
        })
    }
    return members;
})
const teamMemberIndexToEdit = ref(null);
const teamMembersToDelete = computed<number[]>(() => store.state.programVersionDetailModule.teamMembersToDelete );

const setTeamMemberToEdit = (id: number) => {
    const teamMemberToEdit = programVersionTeam.value.find(member => member.id == id);
    teamMemberIndexToEdit.value = programVersionTeam.value.indexOf(teamMemberToEdit)
}

const addMemberToDelete = (id: number) => {
    teamMembersToDelete.value.push(id);
    teamMemberIndexToEdit.value = null;
};

const removeMemberToDelete = (id: number) => teamMembersToDelete.value.splice(teamMembersToDelete.value.indexOf(id), 1)

const isTeamMemberComplete = computed(() => {
    return newMember.value.teamRoleType !== null && newMember.value.user !== null;
});

const isTeamMemberDisabled = computed(() => {
    return !newMember.value.teamRoleType || !newMember.value.user;
});

const updateTeamMember = (memberId: number) => {
    const displayedTeamMember = teamToDisplay.value.find(member => member.id == memberId);
    const indexToModify =  teamToDisplay.value.indexOf(displayedTeamMember);
    teamToDisplay.value[indexToModify].role = props.teamRoleTypesOptions.find(teamRole => teamRole.id == programVersionTeam.value[teamMemberIndexToEdit.value].teamRoleType).name;
    teamToDisplay.value[indexToModify].personName = props.employeesOptions.find(employee => employee.id == programVersionTeam.value[teamMemberIndexToEdit.value].user).name;
    teamMemberIndexToEdit.value = null;
}

const addMemberToTheTeam = () => {
    if (newMember.value.teamRoleType && newMember.value.user) {
        programVersionTeam.value.push({
            user: newMember.value.user,
            teamRoleType: newMember.value.teamRoleType
        })
        newMember.value.teamRoleType = null;
        newMember.value.user = null;
    }
}

const removeTeamMember = (index: number) => {
    programVersionTeam.value.splice(index, 1);
}



</script>
<style lang="scss" scoped>
.team-member-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;

    .team-member-card {
        width: 48%;
        background-color: #fafafa;
        padding: 0.5rem 1rem;
        margin-bottom: 1rem;
        font-size: 0.8rem;
        border-radius: 6px;
        font-weight: 300;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:nth-child(4n + 4) {
            border-left: 6px solid $warning-strong;
        }

        &:nth-child(4n + 3) {
            border-left: 6px solid #FE8A7E;
        }

        &:nth-child(4n + 2) {
            border-left: 6px solid #5ea2a4;
        }

        &:nth-child(4n + 1) {
            border-left: 6px solid #007CB7;
        }
        &.to-delete {
            border-left: 6px solid $one-five-orange;
        }

        .name {
            font-weight: 500;
            text-transform: uppercase;

        }
    }
}

.trash {
    background-color: $one-five-orange;
    margin: -8px 16px -8px -16px;
    padding: 0 16px 0 10px;
    align-items: center;
    > svg {
        stroke: white;
        stroke-width: 1;
    }
}
.disclaimer-team {
    background-color: $grey-modal;
    padding: 1rem;
}
</style>