import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, vModelText as _vModelText, normalizeClass as _normalizeClass, unref as _unref, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "mb-8" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  key: 0,
  class: "error-form-field"
}
const _hoisted_5 = ["value"]
const _hoisted_6 = {
  key: 0,
  class: "mb-3"
}
const _hoisted_7 = { class: "flex" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = {
  key: 1,
  class: "flex justify-between"
}
const _hoisted_11 = {
  key: 0,
  class: "error-form-field"
}
const _hoisted_12 = {
  key: 0,
  class: "w-2/5"
}
const _hoisted_13 = ["value"]
const _hoisted_14 = { class: "mb-3 assessment-editor" }
const _hoisted_15 = { class: "edit-assessment-buttons" }

import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import { AssetProcessingTechniquePropertyInterface, AssetProcessingTechniquePropertyNestedCreateOrUpdateInput, AssetProcessingTechniqueUpdateMutationInput } from '@/models/assetAssessmentInterfaces';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { XMarkIcon, CheckIcon } from '@heroicons/vue/24/outline';
import { useQuery } from '@vue/apollo-composable';
import { computed, ref} from 'vue';
import { useStore } from 'vuex';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { ASSET_PROCESSING_TECHNIQUES_FORM_VALUES } from '@/grapql/assetProcessingTechniqueFormQueries';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssetProcessingTechniquePropertyAddForm',
  props: {
    assetProcessingTechniqueProperties: {},
    assetProcessingTechniqueId: {},
    processingTechniqueId: {}
  },
  emits: ['cancelAddition'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit

const store = useStore();
const onEditorReady = (event: Event) => {return event};
const erroFromMutation = computed(() => store.state.assetDetailModule.errorsDuringMutation.assetProcessingTechniquesUpdate)


const { result } = useQuery(ASSET_PROCESSING_TECHNIQUES_FORM_VALUES);
const inizilizedData = computed(() => result?.value ?? null );
const formValueOptions = computed<{[key: string]: DropdownSearchableOptionInterface[]}>(() => useFormInizializationNewAsset(inizilizedData.value, ['unitTypes', 'valueTypes', 'processingTechniqueProperties']));

const alreadyTakenProperties = computed(() => props.assetProcessingTechniqueProperties.map(property => property.processingTechniqueProperty.id) );
const availablesPropeties = computed(() => formValueOptions?.value['processingTechniquePropertiesOptions']?.filter(option => !alreadyTakenProperties.value.includes(option.id)));

const formSubmited = ref(false);
const valueError = computed(() => formSubmited.value && !value1.value ?  "Please assign a value to the property" : null);
const propertyError = computed(() => formSubmited.value && !processingTechniqueProperty.value ? "Pleasee select a property" : null);

const processingTechniqueProperty = ref<number>(null);
const valueType = ref<DropdownSearchableOptionInterface>(null);
const value1 = ref<string>(null);
const value2 = ref<string>(null);
const valueUnit = ref<number>(null);
const extraInformation = ref<string>(null);

const createMutationInput = () => {
   const processingTechniquePropertyInput = ref<AssetProcessingTechniquePropertyNestedCreateOrUpdateInput>({
      processingTechniqueProperty: Number(processingTechniqueProperty.value),
      value1: value1.value,
      value2: value2.value,
      valueType: valueType.value ? valueType.value.id : null,
      valueUnit: valueUnit.value,
      extraInformation: extraInformation.value,
   });
   const mutationInput = ref<AssetProcessingTechniqueUpdateMutationInput>({
      id: props.assetProcessingTechniqueId,
      processingTechnique: props.processingTechniqueId,
      properties: [processingTechniquePropertyInput.value],
   });
   return mutationInput;
}

const handleSaveChanges = async () => {
   formSubmited.value = true;
   if (!propertyError.value && !valueError.value) {
     const mutationInput = createMutationInput();
     await store.dispatch('assetDetailModule/assetProcessingTechniqueUpdate', mutationInput.value)
      .then(() => { 
         if (!erroFromMutation.value) {
            emit('cancelAddition')
         }
      })
   }
}



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[9] || (_cache[9] = _createElementVNode("label", null, "Property", -1)),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((processingTechniqueProperty).value = $event)),
        class: "w-full"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(availablesPropeties.value, (property) => {
          return (_openBlock(), _createElementBlock("option", {
            key: property.id,
            value: property.id
          }, _toDisplayString(property.name), 9, _hoisted_3))
        }), 128))
      ], 512), [
        [_vModelSelect, processingTechniqueProperty.value]
      ]),
      (propertyError.value)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
            _createElementVNode("label", null, _toDisplayString(propertyError.value), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _cache[17] || (_cache[17] = _createElementVNode("label", null, "Value Type", -1)),
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((valueType).value = $event)),
      class: "w-full mb-3"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formValueOptions.value['valueTypesOptions'], (valueType) => {
        return (_openBlock(), _createElementBlock("option", {
          key: valueType.id,
          value: valueType
        }, _toDisplayString(valueType.description), 9, _hoisted_5))
      }), 128))
    ], 512), [
      [_vModelSelect, valueType.value]
    ]),
    (valueType.value && valueType.value.name == 'Boolean')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _cache[10] || (_cache[10] = _createElementVNode("label", null, "Value", -1)),
          _createElementVNode("div", _hoisted_7, [
            _withDirectives(_createElementVNode("input", {
              class: "shrink-0",
              type: "checkbox",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((value1).value = $event)),
              "true-value": "true",
              "false-value": "false"
            }, null, 512), [
              [_vModelCheckbox, value1.value]
            ]),
            (value1.value && value1.value == 'true')
              ? (_openBlock(), _createElementBlock("label", _hoisted_8, "Yes"))
              : (_openBlock(), _createElementBlock("label", _hoisted_9, "No"))
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", {
            class: _normalizeClass([valueType.value && valueType.value.name == 'Between' ? 'w-2/5' : 'w-full', "mb-3"])
          }, [
            _cache[11] || (_cache[11] = _createElementVNode("label", null, "Value", -1)),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((value1).value = $event)),
              class: "w-full"
            }, null, 512), [
              [_vModelText, value1.value]
            ]),
            (valueError.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_11, [
                  _createElementVNode("label", null, _toDisplayString(valueError.value), 1)
                ]))
              : _createCommentVNode("", true)
          ], 2),
          (valueType.value && valueType.value.name == 'Between')
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _cache[12] || (_cache[12] = _createElementVNode("label", null, "Value 2", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((value2).value = $event)),
                  class: "w-full mb-3"
                }, null, 512), [
                  [_vModelText, value2.value]
                ])
              ]))
            : _createCommentVNode("", true)
        ])),
    _cache[18] || (_cache[18] = _createElementVNode("label", null, "Unit", -1)),
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((valueUnit).value = $event)),
      class: "w-full mb-3"
    }, [
      _cache[13] || (_cache[13] = _createElementVNode("option", { value: null }, null, -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formValueOptions.value['unitTypesOptions'], (unitOption) => {
        return (_openBlock(), _createElementBlock("option", {
          key: unitOption.id,
          value: unitOption.id
        }, _toDisplayString(unitOption.abbreviation) + " - " + _toDisplayString(unitOption.name), 9, _hoisted_13))
      }), 128))
    ], 512), [
      [_vModelSelect, valueUnit.value]
    ]),
    _createElementVNode("div", _hoisted_14, [
      _cache[14] || (_cache[14] = _createElementVNode("label", null, "Extra Information", -1)),
      _createVNode(_unref(QuillEditor), {
        content: extraInformation.value,
        "onUpdate:content": _cache[6] || (_cache[6] = ($event: any) => ((extraInformation).value = $event)),
        "content-type": "html",
        onReady: _cache[7] || (_cache[7] = ($event: any) => (onEditorReady($event)))
      }, null, 8, ["content"])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", {
        onClick: handleSaveChanges,
        class: "on-green"
      }, [
        _createVNode(_unref(CheckIcon), { class: "icon" }),
        _cache[15] || (_cache[15] = _createElementVNode("p", null, "Save Changes", -1))
      ]),
      _createElementVNode("div", {
        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('cancelAddition')))
      }, [
        _createVNode(_unref(XMarkIcon), { class: "icon" }),
        _cache[16] || (_cache[16] = _createElementVNode("p", null, "cancel", -1))
      ])
    ])
  ]))
}
}

})