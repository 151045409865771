<template>
	<div class=""> 
		<label v-if="searchLabel">{{ searchLabel }}<span class="ml-2">Write your search criteria and HIT
				ENTER</span></label>
		<label v-else>Search:<span class="ml-2">Write your search criteria and HIT ENTER</span></label>
		<div class="relative">
			<input type="text" class="w-full" v-model="searchableValue"
				@keyup.enter="emit('searchValueChange', searchableValue); valueSubmited = true">
			<MagnifyingGlassIcon class="icon icon-small absolute top-0.5 right-2" />
		</div>
	</div> 
	<div class="selected-results" v-if="valuesToDisplay.length > 0">
		<template v-for="(value, index) in valuesToDisplay" :key="index">
			<span>
				{{ value.name }}
				<XMarkIcon class="icon icon-x-small thick inline" @click="removeValue(value.id, index)" />
			</span>
		</template> 
	</div>
	<div v-if="message !== '' && valueSubmited" class="flex items-center mt-3 sorry-text">
		<FaceFrownIcon class="icon icon-small mr-3 black thick" />
		<p>{{message}}</p>
	</div>
	<div v-else-if="optionsList.length > 0 && valueSubmited" class="mt-4">
		<label>Posible Results:</label> 
		<div class="flex flex-wrap"> 
			<div v-for="option in optionsList" :key="option.id">
				<template v-if="!valuesToDisplay.includes(option)">
					<input type="checkbox" v-model="selectedOptions" @change="handleSelection(option)"
						:value="option.id" :id="option.id.toString()" class="hidden peer">
					<label :for="option.id.toString()" class="transition-colors duration-200 ease-in-out cursor-pointer border m-1 px-2 py-1 rounded-2xl">
						{{ option.name }} 
					</label>
				</template>
			</div>
		</div>
	</div> 


</template>

<script setup lang="ts">
import { ref, defineProps, PropType, defineEmits, watch, computed, onMounted } from 'vue';
import { XMarkIcon, MagnifyingGlassIcon, FaceFrownIcon } from '@heroicons/vue/24/outline';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { useStore } from 'vuex';

const props = defineProps({
	optionsList: { type: Array as PropType<DropdownSearchableOptionInterface[]> },
	modelValue: { type: Array, required: true },
	searchLabel: { type: String, required: false },
	resultCount: {type: Number, required: true },
	isMultiple: { type: Boolean, required: true },
	selectedValues: {type: Array, required: false},
	newAssetCreated: {type: Boolean, required: false}
})

const emit = defineEmits(['update:modelValue', 'searchValueChange']);

const searchableValue = ref('');
const valuesToDisplay = ref([]);
const selectedOptions = ref([]);
const valueSubmited = ref(false);
const message = computed(() => 
					props.resultCount == 0 ? `Sorry, we could not find any results for ${searchableValue.value}. Maybe try again or create a new one` 
                    : props.resultCount >= 1000 ? 'There are too many result for thi search. Could you please be more specific?' 
					: ''
)

const store = useStore();
const newCreatedParty = ref<{id: number, nickname: string}>(store.state.partyModule.createdParty);

watch(
  () => newCreatedParty.value.id,
  () => {
	if (newCreatedParty.value.id && !selectedOptions.value.includes(newCreatedParty.value.id)) {
		const newParty = {id: newCreatedParty.value.id, name: newCreatedParty.value.nickname}
		valuesToDisplay.value.push(newParty);
		selectedOptions.value.push(newParty.id);		
		emit('update:modelValue', selectedOptions.value);
		valueSubmited.value = false;
		store.commit('partyModule/restoreCreatedParty');
	}
  }
)

watch(() => props.newAssetCreated, (newValue) => {
	if (newValue) {
		resetValues();
	}
})

const resetValues = () => {
	selectedOptions.value = [];
	valuesToDisplay.value = [];
	valueSubmited.value = false;
	searchableValue.value = '';
}

const handleSelection = (selectedOption: DropdownSearchableOptionInterface) => {
	emit('update:modelValue', selectedOptions.value);
	valuesToDisplay.value.push(selectedOption);
}

const removeValue = (idToRemove: number, index: number) => {
	valuesToDisplay.value.splice(index, 1);
	selectedOptions.value = selectedOptions.value.filter(value => value != idToRemove)
	emit('update:modelValue', selectedOptions.value)
}

onMounted(() => {
	resetValues();
	if (props.modelValue && props.modelValue.length > 0) {
		selectedOptions.value = props.modelValue
	}
	if(props.selectedValues) {
		let newSelectedValue = {id: null, name: null};
		props.selectedValues.map((value: {id:number, nickname:string}) => {
			newSelectedValue = {id: value.id, name: value.nickname};
			valuesToDisplay.value.push(newSelectedValue);
		})
	}
})
</script>

<style lang="scss" scoped>
.selected {
	background-color: $green-light;
}
.sorry-text {
	font-size: 0.8rem;
	background: #ffb7be;
    border-radius: 5px;
    padding: 8px;
    border: 2px solid #000;
    color: #000;
}

</style>