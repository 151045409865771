import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, Fragment as _Fragment, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "px-8 pb-16" }
const _hoisted_4 = { class: "grid grid-cols-5 gap-y-2.5 gap-x-4" }
const _hoisted_5 = { class: "col-span-3" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "col-span-2" }
const _hoisted_8 = { class: "col-span-2" }
const _hoisted_9 = ["value"]
const _hoisted_10 = { class: "col-span-3" }
const _hoisted_11 = { class: "col-span-5 row-span-2 grid mb-3" }
const _hoisted_12 = { class: "col-span-5 row-span-2 grid" }

import { ref, computed, onMounted } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import Datepicker from 'vue3-datepicker';
import ModalSlots from '@/components/ModalSlots.vue';
import FormErrorToast from '@/components/FormErrorToast.vue';
import { NEW_PROGRAM_VERSIONS } from '@/grapql/newProgramVersionsQueries';
import { NEW_PROGRAMS_FIELDS } from '@/models/newProgramsFormFields';
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import DropdownSearchableSingleVue from './DropdownSearchableSingle.vue';
import DropdownSearchableMultiple from '@/components/DropdownSearchableMultiple.vue';
import ProgramAddNewForm from '@/components/ProgramAddNewForm.vue';
import TeamMembersForm from '@/components/TeamMembersForm.vue';
import ProgramVersionTargetsForm from '@/components/ProgramVersionTargetsForm.vue';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { QuillEditor } from '@vueup/vue-quill';
import { useStore } from 'vuex';
import moment from "moment";
import { format } from "date-fns";
import { useFormFieldsCreationNewProgramAndVersion, useFormFieldsToEditProgramAndVersion } from '@/composables/useFormFieldsCreateAndEditPrograms';



export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramVersionsForm',
  props: {
	onEdit: { type: Boolean, required: false },
	isOpen: { type: Boolean, required: false }
},
  emits: ['closeEdit', 'closeNew'],
  setup(__props, { emit: __emit }) {

const props = __props

const emit = __emit;
const store = useStore();

// Inizialize the form
const { result } = useQuery(NEW_PROGRAM_VERSIONS);
const inizilizedData = computed(() => result?.value ?? null);
const formInizializationValues = computed<{ [key: string]: DropdownSearchableOptionInterface[] }>(() => {
	return useFormInizializationNewAsset(inizilizedData.value, NEW_PROGRAMS_FIELDS.PROGRAM_VERSIONS.fieldsToQuery);
});

// Fields for the creation form, excluding programVersionTeam and programVersionTargets
const formFieldOptions = {
	fields: NEW_PROGRAMS_FIELDS.PROGRAM_VERSIONS.formFields,
	base: NEW_PROGRAMS_FIELDS.PROGRAM_VERSIONS.graphqlValue,
	mutation: 'programsCreationModule/setSingleProgramVersionValue',
};

// Fields for the edition form, excluding programVersionTeam and programVersionTargets
const formFieldEditOptions = {
	fields: NEW_PROGRAMS_FIELDS.PROGRAM_VERSIONS_EDITION.formFields,
	base: NEW_PROGRAMS_FIELDS.PROGRAM_VERSIONS_EDITION.graphqlValue,
	mutation: 'programVersionDetailModule/updateSingleProgramVersionValue',
};

const programName = computed(() => props.onEdit ? store.state['programVersionDetailModule'].programVersionFromBE.program.name : null)
const creationDate = computed(() => props.onEdit ? store.state['programVersionDetailModule'].programVersionFromBE.creationDate : null)

const formFields = props.onEdit ? useFormFieldsToEditProgramAndVersion(formFieldEditOptions) : useFormFieldsCreationNewProgramAndVersion(formFieldOptions);
const onEditorReady = (event: Event) => { return event };

// Form validation
const formSubmited = ref(false);
const valuesSubmitted = computed(() => store.state['programsCreationModule']['programVersion']);
const formIsValid = computed(() => NEW_PROGRAMS_FIELDS.PROGRAM_VERSIONS.mandatoryFormFields.every(mandatoryField => !!valuesSubmitted.value[mandatoryField]));
const missingFields = computed(() => formIsValid.value ? [] : NEW_PROGRAMS_FIELDS.PROGRAM_VERSIONS.mandatoryFormFields.filter(mandatoryField => !valuesSubmitted.value[mandatoryField]));
const errorsFromMutation = computed(() => props.onEdit ? store.state['programVersionDetailModule']['errorDuringMutation']['programVersionUpdatePartial'] : store.state['programsCreationModule']['errorDuringMutation']['newProgramVersion']);
const showMutationErrors = computed(() => formSubmited.value && !!errorsFromMutation.value);

const handleCreateOrUpdate = () => props.onEdit ? updateProgramVersion() : createProgramVersion();

const createProgramVersion = async () => {
	formSubmited.value = true;
	if (formIsValid.value) {
		const mutation = NEW_PROGRAMS_FIELDS.PROGRAM_VERSIONS.createMutation;

		await store.dispatch(mutation)
			.then(() => {
				if (!errorsFromMutation.value) {
					formSubmited.value = false;
					store.commit('programsCreationModule/resetProgramVersionValues');
					emit('closeNew')

				}
			});
	}
}

const updateProgramVersion = async () => {
	formSubmited.value = true;
	const mutation = NEW_PROGRAMS_FIELDS.PROGRAM_VERSIONS_EDITION.updateMutation;
	await store.dispatch('programVersionDetailModule/programVersionTargetDelete')
		.then(async () => { await store.dispatch('programVersionDetailModule/programVersionTeamDelete')})
		.then(async () => { await store.dispatch(mutation)})
		.then(() => {
			if (!errorsFromMutation.value) {
				formSubmited.value = false;
				emit('closeEdit');
			}
		});
}

onMounted(() => {
	if (props.onEdit) {
		store.commit('programVersionDetailModule/parseBeValuesForEdition');
		store.commit('programVersionDetailModule/parseBeTeamValuesForEdition');
		store.commit('programVersionDetailModule/parseBeTargetsValuesForEdition');
	}
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalSlots, {
    "create-text": __props.onEdit ? 'Update' : 'Create',
    isOpen: __props.isOpen,
    dialogClass: "l-modal",
    onCloseModal: _cache[8] || (_cache[8] = ($event: any) => (__props.onEdit ? _ctx.$emit('closeEdit') : _ctx.$emit('closeNew'))),
    onClickCreate: handleCreateOrUpdate
  }, {
    header: _withCtx(() => [
      (__props.onEdit)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, "edit programme version"))
        : (_openBlock(), _createElementBlock("p", _hoisted_2, "new programme version"))
    ]),
    body: _withCtx(() => [
      _createElementVNode("form", _hoisted_3, [
        (errorsFromMutation.value)
          ? (_openBlock(), _createBlock(FormErrorToast, {
              key: 0,
              "is-showing": showMutationErrors.value,
              title: errorsFromMutation.value,
              toastType: "warning"
            }, null, 8, ["is-showing", "title"]))
          : _createCommentVNode("", true),
        _createVNode(FormErrorToast, {
          "is-showing": formSubmited.value && !formIsValid.value && !__props.onEdit,
          title: "The following fields are mandatory:",
          subtitle: missingFields.value.join(', ')
        }, null, 8, ["is-showing", "subtitle"]),
        _createElementVNode("div", _hoisted_4, [
          _cache[15] || (_cache[15] = _createElementVNode("div", { class: "form-separator col-span-5" }, [
            _createElementVNode("p", { class: "form-subtitle" }, "Overview")
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _cache[9] || (_cache[9] = _createElementVNode("label", null, "Programme *", -1)),
            (!__props.onEdit)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(DropdownSearchableSingleVue, {
                    modelValue: _unref(formFields)['program'].value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(formFields)['program'].value) = $event)),
                    "options-list": formInizializationValues.value['programsOptions']
                  }, null, 8, ["modelValue", "options-list"]),
                  _createVNode(ProgramAddNewForm)
                ], 64))
              : (_openBlock(), _createElementBlock("input", {
                  key: 1,
                  class: "w-full",
                  type: "text",
                  disabled: "",
                  value: programName.value
                }, null, 8, _hoisted_6))
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[10] || (_cache[10] = _createElementVNode("label", null, "Version *", -1)),
            _withDirectives(_createElementVNode("input", {
              class: "w-full",
              type: "text",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(formFields)['version'].value) = $event))
            }, null, 512), [
              [
                _vModelText,
                _unref(formFields)['version'].value,
                void 0,
                { number: true }
              ]
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _cache[11] || (_cache[11] = _createElementVNode("label", null, "Creation Date *", -1)),
            (!__props.onEdit)
              ? (_openBlock(), _createBlock(_unref(Datepicker), {
                  key: 0,
                  class: "w-full",
                  "upper-limit": new Date(),
                  clearable: true,
                  "input-format": 'dd-MMM-yyyy',
                  modelValue: _unref(formFields)['creationDate'].value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(formFields)['creationDate'].value) = $event))
                }, null, 8, ["upper-limit", "modelValue"]))
              : (_openBlock(), _createElementBlock("input", {
                  key: 1,
                  class: "w-full",
                  type: "text",
                  disabled: "",
                  value: _unref(moment)(creationDate.value).format('DD MMM YYYY') 
                }, null, 8, _hoisted_9))
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[12] || (_cache[12] = _createElementVNode("label", null, [
              _createTextVNode("Application "),
              _createElementVNode("span", null, "(One or more)")
            ], -1)),
            (formInizializationValues.value['applicationTypesOptions'])
              ? (_openBlock(), _createBlock(DropdownSearchableMultiple, {
                  key: 0,
                  "options-list": formInizializationValues.value['applicationTypesOptions'],
                  modelValue: _unref(formFields)['applicationTypes'].value,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(formFields)['applicationTypes'].value) = $event))
                }, null, 8, ["options-list", "modelValue"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _cache[13] || (_cache[13] = _createElementVNode("label", null, "Goal *", -1)),
            _createVNode(_unref(QuillEditor), {
              content: _unref(formFields)['goal'].value ? _unref(formFields)['goal'].value : '<p><br></p>',
              "content-type": "html",
              "onUpdate:content": _cache[4] || (_cache[4] = content => content == '<p><br></p>' ? _unref(formFields)['goal'].value = null : _unref(formFields)['goal'].value = content),
              onReady: _cache[5] || (_cache[5] = ($event: any) => (onEditorReady($event)))
            }, null, 8, ["content"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _cache[14] || (_cache[14] = _createElementVNode("label", null, "Description", -1)),
            _createVNode(_unref(QuillEditor), {
              content: _unref(formFields)['description'].value ? _unref(formFields)['description'].value : '<p><br></p>',
              "content-type": "html",
              "onUpdate:content": _cache[6] || (_cache[6] = content => content == '<p><br></p>' ? _unref(formFields)['description'].value = null : _unref(formFields)['description'].value = content),
              onReady: _cache[7] || (_cache[7] = ($event: any) => (onEditorReady($event)))
            }, null, 8, ["content"])
          ]),
          _cache[16] || (_cache[16] = _createElementVNode("div", { class: "form-separator col-span-5" }, [
            _createElementVNode("p", { class: "form-subtitle" }, "Team")
          ], -1)),
          _createVNode(TeamMembersForm, {
            "on-edit": __props.onEdit,
            "team-role-types-options": formInizializationValues.value['teamRoleTypesOptions'],
            "employees-options": formInizializationValues.value['employeesOptions']
          }, null, 8, ["on-edit", "team-role-types-options", "employees-options"]),
          _cache[17] || (_cache[17] = _createElementVNode("div", { class: "form-separator col-span-5" }, [
            _createElementVNode("p", { class: "form-subtitle" }, "Properties")
          ], -1)),
          _createVNode(ProgramVersionTargetsForm, { "on-edit": __props.onEdit }, null, 8, ["on-edit"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["create-text", "isOpen"]))
}
}

})