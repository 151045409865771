import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "mt-4" }

import AssetDetailAssessmentProgramsLinkAddition from './AssetDetailAssessmentProgramsLinkAddition.vue';
import AssetDetailAssessmentProgramsLinkDetail from './AssetDetailAssessmentProgramsLinkDetail.vue';
import { PropType, computed } from 'vue';
import { PlusIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';
import { LinkedProgramInterface } from '@/models/linkedProgramInterface';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssetDetailAssessmentProgramsLinks',
  props: {linkedProgrammes: Array as PropType<LinkedProgramInterface[]>},
  setup(__props) {



const store = useStore();
const programAddOn = computed<boolean>(() => store.getters['assetDetailModule/isAssetAssessmentProgramsAddition']);
const programEditOn = computed<boolean>(() => store.getters['assetDetailModule/isAssetAssessmentProgramsEditable']);

const handleAdd = () => {
    store.commit('assetDetailModule/setEditableOn', {sectionToMakeEditbale: 'programsAdd' });
    store.commit('assetDetailModule/setEditableProgramsEditOff')
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!__props.linkedProgrammes.length && !programAddOn.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[1] || (_cache[1] = _createElementVNode("p", { class: "no-assessment" }, "No Programes yet", -1)),
          _createElementVNode("div", {
            class: "edit-assessment on-green",
            onClick: handleAdd
          }, [
            _createVNode(_unref(PlusIcon), { class: "icon" }),
            _cache[0] || (_cache[0] = _createElementVNode("p", null, "Add program", -1))
          ])
        ]))
      : _createCommentVNode("", true),
    (programAddOn.value)
      ? (_openBlock(), _createBlock(AssetDetailAssessmentProgramsLinkAddition, { key: 1 }))
      : _createCommentVNode("", true),
    (!!__props.linkedProgrammes.length)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (!programAddOn.value && !programEditOn.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "edit-assessment",
                onClick: handleAdd
              }, [
                _createVNode(_unref(PlusIcon), { class: "icon" }),
                _cache[2] || (_cache[2] = _createElementVNode("p", null, "Add new", -1))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.linkedProgrammes, (linkedProgram) => {
              return (_openBlock(), _createBlock(AssetDetailAssessmentProgramsLinkDetail, {
                key: linkedProgram.id,
                "linked-program": linkedProgram
              }, null, 8, ["linked-program"]))
            }), 128))
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}
}

})