// import { AssetDetailMainDataType } from "@/models/technologyAssetInterface";
import moment from "moment";
import { ref } from "vue";
import { useInitialsToUserLogo } from "@/tea/composables/useInitialsToUserLogo";

export async function useRunGroupToRunGroupDetail(runGroup) {
  const runGroupDetail = ref(null);
  try {
    runGroupDetail.value = {
      title: runGroup?.name,
      activity: [
        {
          title: "Created by",
          date: runGroup?.createdAt ? moment(runGroup?.createdAt, moment.ISO_8601).format("DD MMM YYYY") : null,
          personName: runGroup?.createdBy ? `${runGroup?.createdBy.firstName} ${runGroup?.createdBy.lastName}` : null,
          initials: runGroup?.createdBy ? useInitialsToUserLogo(runGroup?.createdBy.firstName, runGroup?.createdBy.lastName) : null,
        },
        {
          title: "Updated by",
          date: runGroup?.updatedAt ? moment(runGroup?.updatedAt, moment.ISO_8601).format("DD MMM YYYY") : null,
          personName: runGroup?.updatedBy ? `${runGroup?.updatedBy?.firstName} ${runGroup?.updatedBy?.lastName}` : null,
          initials: runGroup?.updatedBy ? useInitialsToUserLogo(runGroup?.updatedBy?.firstName, runGroup?.updatedBy?.lastName) : null,
        },
      ],
      accordionList: [
        {
          title: "Info",
          content: [
            {
              title: "Name",
              content: runGroup?.name,
              type: "text",
              mutationValue: "name",
              editable: true,
            },
            {
              title: "Description",
              content: runGroup?.description,
              type: "textarea",
              mutationValue: "description",
              editable: true,
            },
          ],
          editable: true,
          expanded: true,
        },
      ],
    };
  } catch {
    (error) => console.log(error);
  }

  return runGroupDetail;
}
