import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-padding" }
const _hoisted_2 = { class: "loading-gif" }

import TechnologyAssetsRepoHeader from '@/components/TechnologyAssetsRepoHeader.vue';
import TechnologyAssetsTable from "@/components/TechnologyAssetsTable.vue";
import TechnologyAssetsRepoTabs from "@/components/TechnologyAssetsRepoTabs.vue";
import { useAssetDataToTableRow } from '@/composables/useAssetDataToTableRow';
import { useQuery } from '@vue/apollo-composable';
import { computed, ref, watchEffect } from 'vue';
import { OTHER_ASSETS, PATENT_ASSETS, RESEARCH_PAPERS_ASSETS, RESEARCH_PROJECTS_ASSETS } from '@/grapql/assetsQuery';
import { useStore } from 'vuex';
import NotFound from '@/components/NotFound.vue';
import { GoodTablePageChangeInterface, GoodTablePerPageParamsInterface, PageInfoInterface, TechnologyAssetAsTableRowInterface, TechnologyAssetInterface } from '@/models/technologyAssetsInterface';

const sectionTitle = 'Technology Assets Repository';


export default /*@__PURE__*/_defineComponent({
  __name: 'TechnologyAssetsRepoView',
  setup(__props) {

const store = useStore();

const selectedAssetType = computed(() => store.getters['assetsModule/getSelectedTab'])
const quickSearchFilter = computed(() => store.state.assetsModule.quickSearchFilter);
const filterCriteria = computed(() => store.state.assetsModule.filter);
const sortByCriteria = computed(() => store.getters['assetsModule/getSortCriteria']);
const perPage = computed(() => store.getters['assetsModule/getTablePerPage']);
const page = computed(() => store.getters['assetsModule/getTablePage']);
// const perPage = ref(perPageFromStore.value);
// const page = ref(pageFromStore.value);


const queryToRun = ref();
const resultQueryName = ref();

const { result, refetch, fetchMore, loading } = useQuery(queryToRun, { first: perPage.value, after: " ", orderBy: sortByCriteria.value, filter: filterCriteria.value });  

watchEffect(() => {
    if (selectedAssetType.value && selectedAssetType.value.key == 'OTHER') {
        queryToRun.value = OTHER_ASSETS;
        resultQueryName.value = selectedAssetType.value.allQueryName;
    }
    if (selectedAssetType.value && selectedAssetType.value.key == 'PATENT') {
        queryToRun.value = PATENT_ASSETS;
        resultQueryName.value = selectedAssetType.value.allQueryName;
    }
    if (selectedAssetType.value && selectedAssetType.value.key == 'RESEARCH_PAPER') {
        queryToRun.value = RESEARCH_PAPERS_ASSETS;
        resultQueryName.value = selectedAssetType.value.allQueryName;
    }
    if (selectedAssetType.value && selectedAssetType.value.key == 'RESEARCH_PROJECT') {
        queryToRun.value = RESEARCH_PROJECTS_ASSETS;
        resultQueryName.value = selectedAssetType.value.allQueryName;
    }
});


const assets = computed<TechnologyAssetAsTableRowInterface[]>(()=> {
    let newListOfAssets = [];
    if (result.value && result.value[resultQueryName.value]) {
        const assetsToParse = result.value[resultQueryName.value].edges.map(a => a.node);
        assetsToParse.map((asset: TechnologyAssetInterface) => newListOfAssets.push(useAssetDataToTableRow(asset)))
    }
    return newListOfAssets
});

const totalCount = computed(() => result.value && result?.value[resultQueryName.value] ? result?.value[resultQueryName.value].totalCount : 0);
const hasNextPage = computed(() => result.value && result?.value[resultQueryName.value] ? result?.value[resultQueryName.value].pageInfo.hasNextPage : false)
const edgeCount = computed(() => result.value && result?.value[resultQueryName.value] ? result?.value[resultQueryName.value].edgeCount : 0);
const pageInfo = computed<PageInfoInterface>(() => result.value && result?.value[resultQueryName.value] ? result.value[resultQueryName.value].pageInfo : null);

const onPerPageChange = (params: GoodTablePerPageParamsInterface) => {
    store.commit('assetsModule/setTablePerPage', {perPage: params.currentPerPage});
    refetchAssets();
}

const onPageChange = (params: GoodTablePageChangeInterface) => {
    if (params.currentPage > params.prevPage) {
        store.commit('assetsModule/setTablePage', {page: page.value + 1});
        const variables = {
            first: perPage.value,
            after: pageInfo.value.endCursor,
            orderBy: sortByCriteria.value,
            before: null,
            filter: filterCriteria.value,
        }
        loadMore(variables)
    }
    if (params.currentPage < params.prevPage) {
        store.commit('assetsModule/setTablePage', {page: page.value - 1});
        const variables = {
            last: perPage.value,
            before: pageInfo.value.startCursor,
            orderBy: sortByCriteria.value,
            first: null,
            filter: filterCriteria.value,
        }
        loadMore(variables)
    }
}

const loadMore = (variables) => {
    fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
            const newEdges = fetchMoreResult[resultQueryName.value].edges;
            const pageInfo = fetchMoreResult[resultQueryName.value].pageInfo;
            return newEdges.length ? {
                ...previousResult,
                [resultQueryName.value]: {
                    ...previousResult[resultQueryName.value],
                    edges: [
                        ...newEdges,
                    ],
                    pageInfo,
                }
            } : previousResult
        },
    })
}

const refetchAssets = () => {
    refetch({ first: perPage.value, after: " ", orderBy: sortByCriteria.value, filter: filterCriteria.value });
    store.commit('assetsModule/setTablePage', {page: 1});
}

const handleQuickSearch = () => {
    refetch({ first: perPage.value, after: " ", orderBy: sortByCriteria.value, filter: quickSearchFilter.value });
    store.commit('assetsModule/setTablePage', {page: 1});
}

const onNotFoundDesition = (desition: string) => {
    if (desition == 'cta2') {
        store.commit('assetsModule/restoreAllValues');
        refetchAssets();
    }
    if (desition == 'cta1') {
        store.commit('assetsModule/openModal')
    }

};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(TechnologyAssetsRepoHeader, {
      title: sectionTitle,
      onFilterRefetch: _cache[0] || (_cache[0] = ($event: any) => (refetchAssets())),
      onQuickSearchRefetch: handleQuickSearch
    }),
    _createVNode(TechnologyAssetsRepoTabs),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(TechnologyAssetsTable, {
        assets: assets.value,
        "is-loading": _unref(loading),
        "total-records": totalCount.value,
        "has-next-page": hasNextPage.value,
        "server-params": {perPage: perPage.value, page: page.value},
        "showing-records": edgeCount.value,
        onOnPageChange: onPageChange,
        onOnPerPageChange: onPerPageChange
      }, null, 8, ["assets", "is-loading", "total-records", "has-next-page", "server-params", "showing-records"])
    ], 512), [
      [_vShow, assets.value.length]
    ]),
    (!_unref(loading) && assets.value.length == 0)
      ? (_openBlock(), _createBlock(NotFound, {
          key: 0,
          imageName: "search-transpa.gif",
          text: "No result found",
          subtitle: "We can't find any asset matching your search",
          "cta-1": "edit filters",
          "cta-2": "restore search",
          onCtaMessage: onNotFoundDesition
        }))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_2, null, 512), [
      [_vShow, _unref(loading) && !assets.value.length]
    ])
  ]))
}
}

})