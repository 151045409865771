import { OTHER_LAST_NUMBERS_Q } from "@/grapql/hasuraQueries";
import { useQuery } from "@vue/apollo-composable";
import { computed, ref, watchEffect } from "vue";



export const LAYERS_FROM_Q = ['Compound', 'Heat Seal', 'Metal', 'Substrate', 'Primer', 'Tie'];

export interface lastAssetsFromQInterface {
    compound: number | null,
    heatseal: number | null, 
    metal: number | null,
    paper: number | null, 
    plastic_film: number | null,
    primer: number | null,
    tie: number | null, 
}

export interface QResponseInterface {
    id: number, 
    nickname: string
}

const lastNumbersForAssets = ref<lastAssetsFromQInterface>({
    compound: null,
    heatseal: null, 
    metal: null,
    paper: null, 
    plastic_film: null,
    primer: null,
    tie: null, 
});

export const useQToGetLasNumbersForAssets = (selectedLayerName:string) => {

    
    if(selectedLayerName == null) {
        lastNumbersForAssets.value.compound = null;
        lastNumbersForAssets.value.heatseal = null;
        lastNumbersForAssets.value.metal = null;
        lastNumbersForAssets.value.paper = null;
        lastNumbersForAssets.value.plastic_film = null;
        lastNumbersForAssets.value.primer = null;
        lastNumbersForAssets.value.tie = null;
        return lastNumbersForAssets.value
    }

    const enableAssetQuery = ref(false);
    const queryOption = ref({ filter: {}});
    const layerKey = ref();

    const getDataFromQ = () => {
              
        const { result } = useQuery(OTHER_LAST_NUMBERS_Q, queryOption.value, () => ({ enabled: enableAssetQuery.value,}) );
        const lastAssets = computed(() => result?.value && result.value?.otherTechnologies ? result?.value.otherTechnologies.edges.map((edge: {node: QResponseInterface}) => edge.node) : null);
        const lastNumbers = computed(() => lastAssets?.value && layerKey.value != 'paper' ? lastAssets.value.map((lastAsset:QResponseInterface) => Number(lastAsset.nickname.split('#')[1]) ) : lastAssets?.value && layerKey.value == 'paper' ? lastAssets.value.map((lastAsset:QResponseInterface) => Number(lastAsset.nickname.split('PAP')[1]) ) :null);
        const cleanNumbers = computed(() => lastNumbers?.value ? lastNumbers.value.filter(x => Boolean(x)) : null)
        const biggerNumber = computed(() => cleanNumbers?.value ? Math.max(...cleanNumbers.value) : null)
        if( biggerNumber.value ) {
            lastNumbersForAssets.value[layerKey.value] = biggerNumber.value;
        }
    }


    if (selectedLayerName == LAYERS_FROM_Q[0] && !lastNumbersForAssets.value.compound) {
        queryOption.value.filter = {nickname: {istartswith: 'Compound#'}}
        enableAssetQuery.value = true;
        layerKey.value = 'compound'
    }
    if (selectedLayerName == LAYERS_FROM_Q[1] && !lastNumbersForAssets.value.heatseal) {
        queryOption.value.filter = {nickname: {istartswith: 'Heat-Seal#'}};
        enableAssetQuery.value = true;
        layerKey.value = 'heatseal'
    }
    if (selectedLayerName == LAYERS_FROM_Q[2] && !lastNumbersForAssets.value.metal) {
        queryOption.value.filter = {nickname: {istartswith: 'Metal#'}};
        enableAssetQuery.value = true;
        layerKey.value = 'metal'
    }
    
    if (selectedLayerName == LAYERS_FROM_Q[3] && !lastNumbersForAssets.value.plastic_film) {
        queryOption.value.filter = {nickname: {istartswith: 'Plastic-Film#'}};
        enableAssetQuery.value = true;
        layerKey.value = 'plastic_film';
    }

    if (selectedLayerName == LAYERS_FROM_Q[3] && !lastNumbersForAssets.value.paper) {
        queryOption.value.filter = {nickname: {istartswith: 'PAP'}};
        enableAssetQuery.value = true;
        layerKey.value = 'paper';
    }

    if (selectedLayerName == LAYERS_FROM_Q[4] && !lastNumbersForAssets.value.primer) {
        queryOption.value.filter = {nickname: {istartswith: 'Primer#'}};
        enableAssetQuery.value = true;
        layerKey.value = 'primer'
    }

    if (selectedLayerName == LAYERS_FROM_Q[5] && !lastNumbersForAssets.value.tie) {
        queryOption.value.filter = {nickname: {istartswith: 'Tie#'}};
        enableAssetQuery.value = true;
        layerKey.value = 'tie'
    
    }
    
    watchEffect(() => {
        if( selectedLayerName ) {
            getDataFromQ()
        }
    })

    return lastNumbersForAssets.value
}