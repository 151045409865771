<template>
  <div class="mb-4">
    <Chart :options="chartOptions" />
  </div>
</template>
<script setup lang="ts">
import { defineProps, computed } from "vue";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import accessibilityInit from "highcharts/modules/accessibility"; // Import the accessibility module
import exportingInit from "highcharts/modules/exporting";
// Initialize the accessibility module
accessibilityInit(Highcharts);
// Initialize the exporting module
exportingInit(Highcharts);

const props = defineProps(["data"]);
const chartData = computed(() => {
  return props.data.map((item) => {
    return { name: item.element, y: item.percentages, sliced: item.percentages < 20 };
  });
});
const chartOptions = {
  chart: {
    type: "pie",
  },
  title: {
    text: "Operating Costs",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: true,
        format: "<b>{point.name}</b>: {point.percentage:.1f} %",
      },
    },
  },
  series: [
    {
      name: "Elements",
      colorByPoint: true,
      data: chartData.value,
      showInLegend: true,
    },
  ],
  exporting: {
    enabled: true, // Enable exporting
  },
  tooltip: {
    formatter: function () {
      const formattedY = Highcharts.numberFormat(this.point.y, 1);
      return `<b>${this.point.name}</b>: ${formattedY} %`;
    },
  },
};
</script>
