<template>
    <div v-for="(item, index) in dataForAccordionList" :key="index">
        <div v-if="item.editable" class="accordion-item editable">
            <div @click="toggleExpandedField(item.title)" class="title">
                <ChevronRightIcon class="icon" :class="{'face-down': expandedField.includes(item.title)}"/>
                <p> {{item.title}} </p> 
            </div>
            <div v-if="item.title === 'Info'" :class="{'visible': expandedField.includes(item.title)}" class="content">
                <RunGroupDetailInfoContent :info-content="item.content" :editable="true"/>
            </div>
            <div v-else :class="{'visible': expandedField.includes(item.title)}" class="content mt-4">
                <QuillEditor :content="item.content" content-type="html" @ready="onEditorReady($event)" @update:content="onEditorUpdate($event, item.mutationValue)"/>
            </div>
        </div>
        <div v-else-if="item.content" class="accordion-item">
            <div v-if="item.content" @click="toggleExpandedField(item.title)" class="title">
                <ChevronRightIcon class="icon" :class="{'face-down': expandedField.includes(item.title)}"/>
                <p> {{item.title}} </p> 
            </div>
            <div v-if="item.innerHtml && item.mutationType == 'textList'" :class="{'visible': expandedField.includes(item.title)}" class="content">
                <div v-for="claim in item.content" :key="claim" v-html="claim"></div>
            </div>
            <div v-else-if="item.innerHtml" v-html="item.content" :class="{'visible': expandedField.includes(item.title)}" class="content"></div> 
            <div v-else :class="{'visible': expandedField.includes(item.title)}" class="content">{{ item.content }}</div>
        </div>
    </div>
</template>
<script setup lang="ts">
    import { defineProps, ref } from 'vue';
    import { ChevronRightIcon } from '@heroicons/vue/24/solid';
    import { useStore } from 'vuex';
    import RunGroupDetailInfoContent from './RunGroupDetailInfoContent.vue';
    import { QuillEditor } from '@vueup/vue-quill'
    import '@vueup/vue-quill/dist/vue-quill.snow.css';

    const props = defineProps(['dataForAccordionList']);
    const store = useStore();
    const expandedField = ref(props.dataForAccordionList.filter(item => item.expanded).map(ei => ei.title));
    
    const toggleExpandedField = (title:string) => {
        const titlePos = expandedField.value.indexOf(title);
        titlePos == -1 ? expandedField.value.push(title) : expandedField.value.splice(titlePos, 1);
    }

    const onEditorReady = (event: Event) => {
        console.log(event)
    }
    const onEditorUpdate =  (event:Event, mutationValue:string) => {
        store.commit('assetDetailModule/updateMainAssetValue', {mutationValue: mutationValue, content: event })
    }
    

</script>
