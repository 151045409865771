import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "title-menu-header" }
const _hoisted_2 = { class: "view-title" }
const _hoisted_3 = { class: "action-icons flex" }

import { ArrowUturnLeftIcon, PaintBrushIcon, PencilIcon, CheckIcon, BookmarkIcon } from '@heroicons/vue/24/outline';
import ProgramVersionBenchmarkForm from '@/components/ProgramVersionBenchmarkForm.vue';
import ModalSave from '@/components/ModalSave.vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'BenchmarkHeader',
  props: ['title'],
  emits: ['toogleEditOnTable'],
  setup(__props, { emit: __emit }) {

const router = useRouter()


const emit = __emit;

const handleEditOnTable = () => {
    editingOnTable.value = !editingOnTable.value;
    emit('toogleEditOnTable')
}

const handleBack = () => {
    if(router.options.history.state.back.toString().includes('create')) {
        router.go(-2)
    } else router.back();
}

const editingOnTable = ref(false);
const isBenchmarkFormOpen = ref(false);
const isSaveModalOpen = ref(false);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(__props.title), 1),
      _createElementVNode("div", _hoisted_3, [
        (!editingOnTable.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "round-action-icon",
              onClick: handleEditOnTable
            }, [
              _createVNode(_unref(PaintBrushIcon), { class: "icon icon-small" })
            ]))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "round-action-icon pink",
              onClick: handleEditOnTable
            }, [
              _createVNode(_unref(CheckIcon), { class: "icon icon-small" })
            ])),
        _createElementVNode("div", {
          class: "round-action-icon",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (isBenchmarkFormOpen.value = !isBenchmarkFormOpen.value))
        }, [
          _createVNode(_unref(PencilIcon), { class: "icon icon-small" })
        ]),
        _createElementVNode("div", {
          class: "round-action-icon",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (isSaveModalOpen.value = !isSaveModalOpen.value))
        }, [
          _createVNode(_unref(BookmarkIcon), { class: "icon icon-small" })
        ]),
        _createElementVNode("div", {
          class: "round-action-icon",
          onClick: handleBack
        }, [
          _createVNode(_unref(ArrowUturnLeftIcon), { class: "icon icon-small" })
        ])
      ])
    ]),
    _createVNode(ProgramVersionBenchmarkForm, {
      "is-open": isBenchmarkFormOpen.value,
      onCloseModal: _cache[2] || (_cache[2] = ($event: any) => (isBenchmarkFormOpen.value = false)),
      "is-on-edit": true
    }, null, 8, ["is-open"]),
    _createVNode(ModalSave, {
      "is-open": isSaveModalOpen.value,
      onCloseModal: _cache[3] || (_cache[3] = ($event: any) => (isSaveModalOpen.value = false))
    }, null, 8, ["is-open"])
  ], 64))
}
}

})