export const NEW_PROGRAMS_FIELDS = {
  PROGRAM: {
    graphqlValue: "program",
    formFields: ["name", "goal", "description", "creationDate"],
    mandatoryFormFields: ["name", "goal", "creationDate"],
    fieldsToQuery: null,
    createMutation: "programsCreationModule/programCreate",
  },
  PROGRAM_VERSIONS: {
    graphqlValue: "programVersion",
    formFields: [
      "program",
      "version",
      "goal",
      "description",
      "creationDate",
      "applicationTypes"
    ],    
    mandatoryFormFields: ["version", "creationDate", "program", "goal"],
    fieldsToQuery: ["teamRoleTypes", "employees","applicationTypes", "programs" ],
    createMutation: "programsCreationModule/programVersionCreateWithTargetsAndTeam",
  },
  PROGRAM_VERSIONS_EDITION: {
    graphqlValue: "programVersionForEdition",
    formFields: [
      "program",
      "version",
      "goal",
      "description",
      "applicationTypes"
    ],    
    mandatoryFormFields: [],
    fieldsToQuery: ["teamRoleTypes", "employees","applicationTypes", "programs" ],
    updateMutation: "programVersionDetailModule/programVersionUpdatePartial",
  },
};
