import gql from 'graphql-tag';

export const DESIGN_STATUSES_QUERY = gql`
    query getDesignStatuses {
        designStatuses {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }`