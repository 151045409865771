<template>
    <div v-if="showSearchResult" class="col-span-5  relative">
        <div v-if="extractInProgress" class="absolute top-0 left-0 w-full h-full flex items-center justify-center">
            <div class="text-center">
                <div class="loading-gif-transparent"></div>
            </div>
        </div>
        <div v-if="searchResult.length">
            <p class="flex items-start mb-2">
                <InformationCircleIcon class="icon icon-mid thick inline" />
                <span class="ml-2">
                    Choose your <strong>desired research paper</strong> from these <strong class="underline">{{ searchResult.length }} Research Papers</strong> to extract its relevant information. You may also <strong>upload a pdf file</strong> with it so that its content is inlcluded in the <strong>description</strong> property.
                </span>
            </p>
            <div class="overflow-y-auto max-h-60 min-h-20 border border-slate-100">
                <table class="table-auto border-collapse border border-slate-300">
                    <tbody>
                        <tr v-for="(item, index) in searchResult" :key="index" :class="{ 'bg-slate-50': index % 2 === 0,'bg-blue-200':vModels['uid'].value==item.uid }" class="border border-slate-100 cursor-pointer"  @click="handlecheck(item.uid)">
                            <td class="p-1"><input type="checkbox" :checked="vModels['uid'].value==item.uid"></td>
                            <td class="p-1 w-full">{{ item.title }} <br/><small>{{  item.authors }}</small></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-center p-4">
                <input type="file" name="uploadFiles" id="uploadFiles" title="Upload research paper pdf"  @change="handleFileChange" accept=".pdf" />
                <button @click="handleExtractInfo" class="button green-accept" :disabled="buttonInactive" :class="{'inactive': buttonInactive}">{{buttonText}}</button>
            </div>
        </div>
        <div v-else-if="!searchInProgress">
            <p class="bg-slate-100 p-4 pt-5 pb-5 flex items-start mb-2">
                <FaceFrownIcon class="icon icon-mid thick inline" />
                <span class="ml-2">
                    <p class="mb-1"><strong>No Results Found !</strong></p>
                    <p class="mb-1">It is recommended to try again with different <strong>searchField</strong> or <strong>searchValue</strong> until you find your desired result.</p>
                    <p>If you are still not able to find results, then as your last option just upload research paper pdf, we will try our best to extract information!</p>
                </span>
            </p>
            <div class="text-center p-4">
                    <input type="file" name="uploadFiles" id="uploadFiles" title="Upload research paper pdf"  @change="handleFileChange" accept=".pdf" />
                    <button @click="handleExtractInfo" class="button green-accept" :disabled="buttonInactive" :class="{'inactive':buttonInactive}">{{buttonText}}</button>
            </div>
        </div>
    </div>  
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import { useStore } from "vuex";
import {RESEARCH_PAPER_AUTOFILL_FIELDS} from '@/models/researchPaperAutofillFormFields';
import { InformationCircleIcon, FaceFrownIcon } from '@heroicons/vue/24/outline';


const store = useStore();

const formSubmited = ref(false);
// Create Fields for the form
const fieldsFromConstant = computed<string[]>(() => RESEARCH_PAPER_AUTOFILL_FIELDS['EXTRACT'].formFields.map(formField =>  { return formField.mutationValue} ));


const formFieldOptions = {
    fields: fieldsFromConstant.value,
    base: RESEARCH_PAPER_AUTOFILL_FIELDS['EXTRACT'].graphqlValue,
    mutation: 'researchPaperAutofillModule/setSingleValueForExtract'
};
const formFields = (formFieldOptions:{fields:string[], base:string, mutation:string}) => {
    const object = {};
    formFieldOptions.fields.map(fieldName => {
        object[fieldName] = computed({
            get() {
                return store.state.researchPaperAutofillModule[formFieldOptions.base][fieldName];
            },
            set(value) {
                store.commit(formFieldOptions.mutation, { field: fieldName , value: value});
            }
        })
    });
    return object;
}

const vModels = formFields(formFieldOptions);

const valuesSubmitted = computed(() => store.state.researchPaperAutofillModule.extract);
const formIsValid = computed(()=> !errorFields.value);
const errorFields = computed(()=> {
    if(searchResult.value.length){
        if(!valuesSubmitted.value.uid){
            return "uid";
        }
        return null;
    }else{
        if(!valuesSubmitted.value.pdf){
            return "pdf";
        }
        return null;
    }
});

const showSearchResult = computed(()=> store.state.researchPaperAutofillModule.showSearchResult);
const searchResult = computed(()=> store.state.researchPaperAutofillModule.searchResult);
const searchInProgress = computed(()=> store.state.researchPaperAutofillModule.searchInProgress);
const extractInProgress = computed(()=> store.state.researchPaperAutofillModule.extractInProgress);
const buttonText= computed(()=> extractInProgress.value ? "Extracting Selected Info" : "Extract Selected Info");
const buttonInactive = computed(()=> !formIsValid.value || extractInProgress.value);


const handleExtractInfo = async()=>{
    formSubmited.value =true;
    if(formIsValid.value){
        const query = RESEARCH_PAPER_AUTOFILL_FIELDS['EXTRACT'].extractQuery;
        await store.dispatch(query)
        .catch((error)=>{
            // since the search is throwing error, enable pdf upload
            // store.commit("researchPaperAutofillModule/setSearchResult", []);
            console.log('error called')
        })
        .then(()=>{
            formSubmited.value = false;
        });
    }
}
const handlecheck = async(uid)=>{
    if(vModels['uid'].value==uid){
        vModels['uid'].value = null;
    }else{
        vModels['uid'].value = uid;
    }
}

const handleFileChange = (event)=>{
    const files = event.target.files;
    if (files.length > 0) {
    vModels['pdf'].value =  files[0];
    } else {
    vModels['pdf'].value =  null;
    }
}

</script>
<style>
.min-100{
    min-width: 100px;
    display: inline-block;
}
</style>