export const useFormInizializationNewRunDoubleSensitivity = (queryResult, fields: string[]) => {
  const object = {};
  if (queryResult) {
    fields
      .map((field) => {
        if(field=="variables"){
          object[`${field}Options`] = queryResult.runDoubleSensitivityVariables[field].map((item) => ({
            id: item,
            name: item,
          }));
        }
        
      });
  }
  return object;
};
