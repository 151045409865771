import { POSITION, useToast } from "vue-toastification";
import { RUN_SENSITIVITY_CALCULATE_MUTATION } from "@/tea/graphql/mutations/runSensitivityMutations";
import { useTeaMutation } from "@/tea/composables/apollo/teaUseMutation";
import { useTeaQuery } from "@/tea/composables/apollo/useTeaQuery";
import { RUNS_QUERY } from "@/tea/graphql/queries/runQueries";
import { RUN_DELETE_MUTATION,RUN_UPDATE_MUTATION } from "@/tea/graphql/mutations/runMutations";
import { useRawRunToStateType } from "@/tea/composables/runs/useRawRunToStateType";

const getDefaultCreateState = () => {
  return {
    fields: {
      run:null,
      electricity: false,
      electricityPrice: null,
      water: false,
      waterPrice: null,
      co2: false,
      co2Price: null,
    },
    error: null,
    isReadyForOutput: false,
    canSubmit:false,
    sensitivityOutput:null,
    calculateError:null,
    createError:null,
    isOutputLoading:false,
    isFormSubmitted:false,
  };
};
const getDefaultState = () => {
  return {
    runId:null,
    repo: {
      runs: [],
      totalCount: 0,
      edgeCount: 0,
      loading: true,
      pageInfo: null,
      error: null,
    },
    create: getDefaultCreateState(),
    error: null,
  };
};

const state = getDefaultState();

const mutations = {
  SET_RUN_ID(state,id){
    state.runId=id;
    state.create.fields.run = id;
  },
  SET_SINGLE_RUN_VALUE(state, payload: { field: string; value: Date | number | string | number[] | null; resetFields:[] }) {
    state.create.fields[payload.field] = payload.value;
    state.create.isReadyForOutput = false;
    state.create.canSubmit= false;
    state.create.isOutputLoading = false;
    payload.resetFields.forEach((field)=>{
      state.create.fields[field] = getDefaultState().create.fields[field]
    })
  },
  SET_CREATE_MUTATION(state,payload:{key:string;value:Date | number | string | number[] | null}){
    state.create[payload.key]=payload.value;
  },
  RESET_CREATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_SENSITIVITY_OUTPUT(state,sensitivity){
    state.create.sensitivityOutput=sensitivity;
  },
};

const actions = {
  // RUN SENSITIVITY SHOW OUTPUT
  async runSensitivityCalculate({state,commit,dispatch}){
    commit('SET_CREATE_MUTATION',{key:"calculateError",value:null});
    commit('SET_CREATE_MUTATION',{key:"isOutputLoading",value:true});
    return new Promise<void>((resolve, reject) => {
      const { mutate: runSensitivityCalculate, onDone, onError } = useTeaMutation(RUN_SENSITIVITY_CALCULATE_MUTATION);
      const runInput = Object.assign({}, state.create.fields);
      runSensitivityCalculate({ input: runInput },{ fetchPolicy: 'no-cache',});
      onDone((result) => {
        commit("SET_SENSITIVITY_OUTPUT",result.data.runSensitivityCalculate.sensitivity)
        commit('SET_CREATE_MUTATION',{key:"isOutputLoading",value:false});
        resolve();
      });
      onError((error) => {
        commit('SET_CREATE_MUTATION',{key:"calculateError",value:error.message});
        commit('SET_CREATE_MUTATION',{key:"isOutputLoading",value:false});
        reject(error.message);
      });
    });
  },
};

const getters = {
  create(state) {
    return state.create;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
