<template>
    <div class="stage-sidebar">
        <p class="overview">Overview</p>
        <div class="stages-list">
            <div v-for="stage in stages" :key="stage.stageNumber" class="stage-row">
                <div class="status-dot"  :class="{active: activeStage == stage.stageNumber, ok: activeStage > stage.stageNumber, ko: activeStage < stage.stageNumber }"></div>
                <div class="name" :class="{ko: activeStage < stage.stageNumber}">{{ stage.stageName }}</div>
                <div class="number" :class="{active: activeStage == stage.stageNumber, ko: activeStage < stage.stageNumber}">{{ stage.stageNumber }}</div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { PROGRAM_STAGES } from '@/models/programStagesConstant';
import { defineProps } from 'vue';
defineProps({activeStage: { type: String }});
const stages = PROGRAM_STAGES;
</script>
<style lang="scss" scoped>
.status-dot {
    left: -25px;
    top: 17px;
    width: 18px;
    height: 18px;
}
.overview {
    font-family: $secondary-font;
    font-size: 1.8rem;
    padding-top: 1.5rem;
    text-align: right;
    color: $blue-dark;
    padding-right: 2rem;
}
.stages-list {
    display: flex;
    flex-direction: column;
    border-left: $border;
    margin-top: 2rem;
    margin-left: 1.5rem;


}
.stage-row {
    display: flex;
    position: relative;
    justify-content: space-between;
    margin: 0 1rem;
    border-bottom: 1px solid #e7e7e7;
}
.name {
    font-weight: 500;
    text-transform: uppercase;
    color: $font-color-light;
    font-size: 0.85rem;
    padding-top: 1rem;
    &.ko {
        opacity: 0.3;
    }
}
.number {
    font-weight: 900;
    color: #e7e7e7;
    font-size: 3rem;
    &.active {
        color: rgb(125 145 188 / 46%);
    }
    &.ko {
        opacity: 0.3;
    }
}
    
</style>