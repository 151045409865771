<template>
    <div class="label-with-icon">
        <span @click="isOpen = true" class="icon-span">+ Create new {{ partyType }}</span>
    </div>
    <ModalSlots :show="isOpen" @close-modal="closeModal" @click-create="createNewParty" dialogClass="create-party-modal">
        <template v-slot:header><p>Create new {{ partyType }}</p></template>
        <template v-slot:body>
            <div class="party-box-container">
                <div v-for="party in partyTypeList" :key="party" class="party-box" @click="onPartyTypeSelection(party)">
                    <div class="party-box-header" :class="{'selected': partyTypeSelected === party}">
                        <div class="text">
                            <p>{{party}}</p>
                            <p>Create a new {{party}} as {{ partyType }}</p>
                        </div>
                        <ChevronDownIcon class="icon icon-small thick" />
                    </div>
                    <div class="party-box-body" v-if="partyTypeSelected === party">
                        <PartyAddNewForm :party-type-selected="partyTypeSelected" :form-is-valid="formValid" :form-submited="formSubmited[party]" :errors-fields="errorFields" :errors-from-mutation="errorsFromMutation"/>
                    </div>
                </div>
            </div>
        </template>
    </ModalSlots>
</template>
<script setup lang="ts">
import ModalSlots from "@/components/ModalSlots.vue";
import PartyAddNewForm from "@/components/PartyAddNewForm.vue";
import { ref, defineProps, defineEmits, computed, reactive } from "vue";
import { ChevronDownIcon } from '@heroicons/vue/24/outline';
import { useStore } from "vuex";
import { NEW_PARTY_FIELDS } from "@/models/newPartyFormFields";


defineProps({ partyType: String })
const emit = defineEmits(['createdParty'])
const isOpen = ref(false);
const closeModal = () => { isOpen.value = false };
const partyTypeSelected = ref('');
const store = useStore();


const partyTypeUpper = computed(() => partyTypeSelected.value.toUpperCase());
const partyTypeList = computed(() => Object.keys(NEW_PARTY_FIELDS).map(k => k.toLowerCase()) );

const formSubmited = reactive(partyTypeList.value.reduce((o, key) => Object.assign(o, {[key]: false}), {}) );

const errorsFromMutation = computed(() => partyTypeSelected.value != '' ? store.state.partyModule.errorsDuringPartyCreation[partyTypeSelected.value] : null )

const valuesSubmitted = computed(() => store.state.partyModule.party[partyTypeSelected.value]);
const formValid = computed(() => partyTypeSelected.value ? NEW_PARTY_FIELDS[partyTypeUpper.value].mandatoryFormFields.every(mf => !!valuesSubmitted.value[mf]) : false);
const errorFields = computed(() => partyTypeSelected.value ? NEW_PARTY_FIELDS[partyTypeUpper.value].mandatoryFormFields.filter(mf => !valuesSubmitted.value[mf]) : [] )


const onPartyTypeSelection = (selectedPartyType:string) => partyTypeSelected.value = selectedPartyType;
const createNewParty = async () => {
    formSubmited[partyTypeSelected.value] = true;
    if (formValid.value) {
        const mutation = NEW_PARTY_FIELDS[partyTypeUpper.value].createMutation;
        
        await store.dispatch(mutation)
            .then(() => {
                if(!errorsFromMutation.value) {
                    formSubmited[partyTypeSelected.value] = false;
                    store.commit('partyModule/resetPartyValues');
                    closeModal();
                }
            });
        
        
    }
}
</script>
<style lang="scss" scoped>

.party-box-container {
    margin: 2rem 1rem 3rem;
}
.party-box {
    border: $border;
    border-radius: 8px;
    margin: 1rem;
    color: $font-color;
    .party-box-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 2rem;
        cursor: pointer;
        &.selected {
            background-color: $grey-modal;
        }
        
        
        p {
        font-size: 0.8rem;
        line-height: 1.2;
        &:first-child {
            font-weight: 400;
            text-transform: uppercase;
            font-size: 1.2rem;
            }
        }
    }
    .party-box-body {
        border-top: $border;
        padding: 1rem 2rem;
        background-color: white;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    
}
</style>