<template>
      <div class="flex items-start space-x-4 mb-12 relative">
        <div class="w-10 flex-shrink-0">
            <div class="flex items-center justify-center border border-gray-200 rounded-full h-10 w-10">
                <CpuChipIcon class="icon icon-small black"/>
            </div>
        </div>
        <div class="flex-1 overflow-x-auto ml-4 p-3 border border-gray-200">
            <div v-if="isHistory" v-html="html" class="whitespace-pre-line"></div>
            <AIChatAnswerRender v-else :text="html" :message-id="messageId"/>
        </div>
        <div v-if="isCompleted" class="absolute top-full left-10 pr-3 py-1">
            <HandThumbUpIcon class="icon w-9 h-9 cursor-pointer hover:bg-gray-200 active:shadow-inner rounded inline-block px-2 hover:stroke-black"/>
            <ArrowPathIcon class="icon w-9 h-9 cursor-pointer hover:bg-gray-200 active:shadow-inner rounded inline-block px-2 hover:stroke-black"/>
            <HandThumbDownIcon class="icon w-9 h-9 cursor-pointer hover:bg-gray-200 active:shadow-inner rounded inline-block px-2 hover:stroke-black"/>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { computed, defineProps, h, ref, watch, onMounted} from 'vue';
import AIChatAnswerRender from './AIChatAnswerRender.vue';
import { CpuChipIcon, HandThumbDownIcon, HandThumbUpIcon, ArrowPathIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';
import {IAnswer} from '@/models/aiInterfaces';
import {ContentType} from '@/models/aiModels';
import AIChatSandwichRenderer from './AIChatSandwichRenderer.vue';
import { renderToString } from '@vue/server-renderer';

const props = defineProps<{
    answer: IAnswer[];
    messageId: string;
    isHistory?: boolean;
}>();

const store = useStore();

// get and check if is completed
const isCompleted = computed(()=> store.getters["aiModule/getIsCompleted"]);

const html = ref<string>('');
async function updateHtml(){
    const newHtmlTexts = await Promise.all(props.answer.map(async (item) => {
    const parsedContent = JSON.parse(item.content);

    if (item.contentType === ContentType.TABLE) {
        // set typing speed
        store.commit('aiModule/setTypingSpeed', 15);

      return `<div class="table-container">${parsedContent}</div>`;
    }

    if (item.contentType === ContentType.SANDWICH) {
        // set typing speed
        store.commit('aiModule/setTypingSpeed', 10);
      return await renderToString(h(AIChatSandwichRenderer,{content:parsedContent}))
    }
    
    // set typing speed
    store.commit('aiModule/setTypingSpeed', 25);


    return `<span>${parsedContent}</span>`;
  }));

  html.value = newHtmlTexts.join(' ');
}
// Watch for changes to props.answer and update HTML
watch(() => props.answer, () => {
  updateHtml();
}, { deep: true });

// Ensure updateHtml runs on component mount
onMounted(() => {
  updateHtml();
});

</script>