import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "name mb-2" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "mb-4 text-sm italic text-neutral-400"
}
const _hoisted_4 = { class: "flex items-center justify-between flex-wrap" }
const _hoisted_5 = { class: "w-full" }
const _hoisted_6 = { class: "value" }
const _hoisted_7 = {
  key: 0,
  class: "w-full"
}
const _hoisted_8 = { class: "value" }
const _hoisted_9 = {
  key: 1,
  class: "w-1/2"
}
const _hoisted_10 = { class: "value" }
const _hoisted_11 = {
  key: 0,
  class: "lowercase"
}
const _hoisted_12 = {
  key: 2,
  class: "w-1/2"
}
const _hoisted_13 = { class: "value" }
const _hoisted_14 = {
  key: 0,
  class: "lowercase"
}
const _hoisted_15 = {
  key: 3,
  class: "w-full"
}
const _hoisted_16 = { class: "value" }
const _hoisted_17 = { class: "w-1/2" }
const _hoisted_18 = {
  key: 0,
  class: "value"
}
const _hoisted_19 = {
  key: 1,
  class: "value"
}
const _hoisted_20 = { key: 1 }

import { AssetMaterialInterface } from '@/models/assetAssessmentInterfaces';
import AssetMaterialProperties from './AssetMaterialProperties.vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'AssetDetailAssessmentInDepthMaterials',
  props: {
    materialsData: {}
  },
  setup(__props: any) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.materialsData, (material) => {
    return (_openBlock(), _createElementBlock("div", {
      key: material.id,
      class: "material-info"
    }, [
      _createElementVNode("p", _hoisted_1, [
        (material.material.acronym)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "(" + _toDisplayString(material.material.acronym) + ") - ", 1))
          : _createCommentVNode("", true),
        _createTextVNode(_toDisplayString(material.material.name), 1)
      ]),
      (material.material.description)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(material.material.description), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _cache[0] || (_cache[0] = _createElementVNode("p", { class: "label" }, "Class", -1)),
          _createElementVNode("p", _hoisted_6, _toDisplayString(material.material.className), 1)
        ]),
        (material.material.category)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _cache[1] || (_cache[1] = _createElementVNode("p", { class: "label" }, "Category", -1)),
              _createElementVNode("p", _hoisted_8, _toDisplayString(material.material.category), 1)
            ]))
          : _createCommentVNode("", true),
        (material.material.productionCapacity)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _cache[2] || (_cache[2] = _createElementVNode("p", { class: "label" }, "Production Capacity", -1)),
              _createElementVNode("p", _hoisted_10, [
                _createTextVNode(_toDisplayString(material.material.productionCapacity) + " ", 1),
                (material.material.productionCapacityUnit)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(material.material.productionCapacityUnit.abbreviation), 1))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        (material.material.price)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _cache[3] || (_cache[3] = _createElementVNode("p", { class: "label" }, "Price", -1)),
              _createElementVNode("p", _hoisted_13, [
                _createTextVNode(_toDisplayString(material.material.price) + " ", 1),
                (material.material.priceUnit)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(material.material.priceUnit.abbreviation), 1))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        (material.material.sustainabilityType)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _cache[4] || (_cache[4] = _createElementVNode("p", { class: "label" }, "Sustainability Type", -1)),
              _createElementVNode("p", _hoisted_16, _toDisplayString(material.material.sustainabilityType.name), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_17, [
          _cache[5] || (_cache[5] = _createElementVNode("p", { class: "label" }, "FDA Approved", -1)),
          (material.material.isFdaApprovedFcm)
            ? (_openBlock(), _createElementBlock("p", _hoisted_18, _toDisplayString(material.material.isFdaApprovedFcm), 1))
            : (_openBlock(), _createElementBlock("p", _hoisted_19, "Unknown"))
        ])
      ]),
      (material.material.sourcing)
        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
            _cache[6] || (_cache[6] = _createElementVNode("p", { class: "label" }, "Sourcing", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(material.material.sourcing, (source, key) => {
              return (_openBlock(), _createElementBlock("p", { key: key }, _toDisplayString(source), 1))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createVNode(AssetMaterialProperties, { "asset-material": material }, null, 8, ["asset-material"])
    ]))
  }), 128))
}
}

})