import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-padding" }

import { useRoute } from 'vue-router';
import TitleGoBackHeader from '@/components/TitleGoBackHeader.vue';
import DashboardDetailViewDishwasher from '@/components/playgroundDemo/DashboardDetailViewDishwasher.vue';
import DashboardDetailViewPetFood from '@/components/playgroundDemo/DashboardDetailViewPetFood.vue';
import { computed } from 'vue';
import { BRIEFS_DATA } from '@/components/playgroundDemo/briefsData';
import PlaygroundPage from '@/components/playgroundDemo/PlaygroundPage.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PlaygroundDetail',
  setup(__props) {

const route = useRoute()
const briefId = Number(route.params.id);

const briefs = BRIEFS_DATA;
const briefData = computed(() => briefs.find(brief => brief.id === briefId));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(TitleGoBackHeader, {
      title: briefData.value.title
    }, null, 8, ["title"]),
    _createVNode(PlaygroundPage, { "brief-data": briefData.value }, null, 8, ["brief-data"])
  ]))
}
}

})