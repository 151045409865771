import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-4" }

import { computed } from "vue";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import accessibilityInit from "highcharts/modules/accessibility"; // Import the accessibility module
import exportingInit from "highcharts/modules/exporting";

// Initialize the accessibility module

export default /*@__PURE__*/_defineComponent({
  __name: 'SensitivityChart',
  props: ["data"],
  setup(__props) {

accessibilityInit(Highcharts);
// Initialize the exporting module
exportingInit(Highcharts);

const props = __props;
const chartData = computed(() => {
  return props.data.map(item=> ({
    x: item.element,
    y: item.value
  }));
});
const chartOptions = {
  chart: {
    type: "column",
  },
  title: {
    text: "Sensitivity analysis (+/- 20%)",
  },
  xAxis: {
    categories: chartData.value.map(item=>item.x),
  },
  yAxis: {
    title: {
      text: "Minimum Selling Price (€)",
    },
  },
  series: [
    {
      name: "Scenario",
      data: chartData.value.map(item=>item.y)
    },
  ],
  exporting: {
    enabled: true,
  },
  plotOptions:{
        column:{
            dataLabels: {
              enabled: true,
              inside: false, // Set to true if you want labels inside the column
              format:'{y} €'
            },
        }
    },
  tooltip: {
    formatter: function () {
      // Customize the tooltip format here
      return `${this.x}: <b>${this.y} €</b>`;
    },
  },
  legend: {
    enabled: false, // Disable the legend
  },
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Chart), { options: chartOptions })
  ]))
}
}

})