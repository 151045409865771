<template>
    <span class="relative">
        <span class="w-auto" @mouseover="showPopover = true" @mouseout="showPopover = false">
            <slot></slot>
        </span>
  
      <div
        v-if="showPopover"
        class="absolute top-full left-0 mt-2 w-48 bg-white border border-gray-300 shadow-lg rounded-lg py-2 z-10">
        <p class="px-4 py-2 text-base lowercase font-sans">
            {{ content }}
        </p>
        <div v-if="!content" class=" text-base normal-case font-sans"><slot name="content"></slot></div>
      </div>
    </span>
  </template>
  
  <script setup>
  import { ref,defineProps } from 'vue';
  const props = defineProps({
    content:String
  });
  const showPopover = ref(false);
  </script>
  
  <style scoped>
  /* Add any custom styles here */
  </style>
  