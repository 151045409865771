<template>
     <ModalSlots :show="isOpen" @close-modal="closeModal" dialogClass="l-modal" > 
        <template v-slot:header> <p>comparison criteria selection</p></template>
        <template v-slot:body> 
            <div class="p-4"> 
                <!--  Name field for saving  -->
                <template v-if="action == 'publish' || action == 'save'">
                    <div class="form-separator"><p class="form-subtitle">table name</p></div>
                    <label>Name *</label>
                    <input class="w-1/2" type="text" required v-model="tableName">
                </template>
               
                <!-- Asset section  -->
                <template v-if="action != 'compare'">
                    <div class="form-separator"><p class="form-subtitle">technology assets</p></div>
                    <!-- New Assets -->
                    <div class="flex justify-end mb-6 -mt-2">
                        <button v-if="!showAditionOptions" class="button bg-danger-light text-white font-medium" @click="handleShowAssetAditionOptions">Add Assets</button>
                        <button v-else class="button bg-charcoal text-white font-medium" @click="cancelAddAssets">Cancel</button>
                    </div> 
                    <div v-if="showAditionOptions" class="mb-12">
                        <div v-if="layerTypes">
                            <label class="pl-2 mb-2">Please select the type of tehcnology you are working with</label>
                            <div class="flex flex-wrap">
                                <div v-for="layer in layerTypes" :key="layer.id">
                                    <input type="radio" v-model="selectedLayerType" :value="layer.name" :id="layer.name" class="hidden peer" @change="getAssetsToAdd">
                                    <label :for="layer.name" class="transition-colors duration-200 ease-in-out peer-checked:bg-black peer-checked:text-white cursor-pointer border m-1 px-2 py-1 rounded-lg" >  {{ layer.name }} </label>
                                </div>
                            </div>
                        </div>
                        <template v-if="assetsForDropdown && selectedLayerType">
                            <div v-if="assetsForDropdown.length > 0">
                                <div class="mt-6 pl-2 pr-8">
                                    <label class="mb-2">Select the technology assets you want to add to the table:</label>
                                    <DropdownSearchableMultiple :options-list="assetsForDropdown" v-model="assetsToBeAdded" />
                                </div>
                            </div>
                            <div v-else class="border bg-charcoal flex pl-2 pr-6 py-4 text-white items-center w-fit rounded-lg mt-4 ml-4">
                                <FaceFrownIcon class="icon icon-m internal-pannel mr-3" />
                                <div>
                                    <p>No technology assets found </p>
                                    <p class="text-xs font-light">Please choose another type of layer</p>
                                </div>
                                
                            </div>
                        </template>
                    </div>
                    
                    <!-- Already selected assets -->
                    <div class="assets-container">
                        <div v-for="(asset, index) in assets" :key="index" class="asset-card shadow-md overflow-hidden" :class="{'to-delete': assetsToDelete.includes(asset)}">
                            <div class="flex">
                                <div v-if="assetsToDelete.includes(asset)" class="flex trash">
                                    <TrashIcon class="icon icon-small" />
                                </div>
                                <div class="flex flex-col">
                                    <p class="name">{{ asset.nickname }}</p>
                                    <!-- <p v-html="asset.title" class="overflow-hidden text-nowrap pr-3"></p> -->
                                    <p class="text-ellipsis overflow-hidden" v-html="asset.title"></p>
                                </div>
                            </div>
                            <div v-if="assetsToDelete.includes(asset)" class="flex items-center" >
                                <ArrowUturnLeftIcon class="icon icon-small ml-3" @click="assetsToDelete.splice(assetsToDelete.indexOf(asset), 1)" />
                            </div>
                            <div v-else class="flex items-center">
                                <XCircleIcon class="icon icon-small " @click="assetsToDelete.push(asset)" />
                            </div>
                        </div>
                    </div>
                </template>
                <!-- Properties -->
                <div class="form-separator"><p class="form-subtitle">properties</p></div>
                <template v-if="properties">
                    <template v-if="propertiesClassNames">
                        <label class="pl-2 mb-2">You can filter by properties categories to reduce the dropdown list:</label>
                        <div class="flex flex-wrap">
                            <div v-for="category in propertiesClassNames" :key="category">
                                <input type="checkbox" v-model="selectedClassName" :value="category" :id="category" class="hidden peer">
                                <label :for="category" class="transition-colors duration-200 ease-in-out peer-checked:bg-black peer-checked:text-white cursor-pointer border m-1 px-2 py-1 rounded-lg" >  {{ category }} </label>
                            </div>
                        </div>
                    </template>
                    <div class="mt-6 pl-2 pr-8">
                        <label class="mb-2">Select the properties yo want to compare:</label>
                        <DropdownSearchableMultiple :options-list="filteredProperties" v-model="selectedProperties" />
                    </div>
                </template>
                <!-- Quantitative Properties -->
                <template v-if="quantitativeProperties">
                    <div class="form-separator"><p class="form-subtitle">Quantitative properties</p></div>
                    <div class="mt-6 pl-2 pr-8 mb-16">
                        <label class="mb-2">Select the quantitative properties you want to compare:</label>
                        <DropdownSearchableMultiple :options-list="quantitativeProperties" v-model="selectedQuantitativeProperties" />
                    </div>
                </template>
            </div>
      
        </template>
        <template v-slot:footer>
            <button v-if="action == 'save'" class="button bg-stone-800 text-white" @click="handleSaveForLater">save for later</button>  
            <button v-else-if="action == 'publish'" class="button bg-stone-800 text-white" @click="handlePublish">{{ action}}</button>  
            <button v-else class="button bg-stone-800 text-white" @click="handleAssetComparison">compare</button>  
        </template>
    </ModalSlots>
</template>
<script setup lang="ts">
import { computed, ref, defineProps, watch } from 'vue';
import { useStore } from 'vuex';
import ModalSlots from "@/components/ModalSlots.vue";
import { XCircleIcon, ArrowUturnLeftIcon, TrashIcon, FaceFrownIcon } from '@heroicons/vue/24/outline'
import { useQuery } from '@vue/apollo-composable';
import { ALL_ASSETS, ASSETS_COMPARISON_CRITERIA_OPTIONS, LAYER_TYPES } from '@/grapql/assetsComparisonCriteriaOptions';
import DropdownSearchableMultiple from './DropdownSearchableMultiple.vue';
import router from '@/router';
import {AssetSimpreInterface, LayerTypeInterface, PropertyInterface, QuantitativePropertyInterface} from '@/models/assetsComparisonTableInterfaces';

const props = defineProps({
    action: {
        type: String,
        default: 'compare',
        required: false
    }, 
})
    
const store = useStore();

const isOpen = computed(() => store.state.assetsModule.comparisonCriteriaModalIsOpen);
const closeModal = () => store.commit('assetsModule/closeComparisonCriteriaModal');


const { result } = useQuery(ASSETS_COMPARISON_CRITERIA_OPTIONS);
const queryResult = computed(() => result?.value ?? null);
const properties = computed<PropertyInterface[]>(() => queryResult.value && queryResult.value.properties.edges.length ? queryResult.value.properties.edges.map((edge: { node: PropertyInterface; }) => edge.node) : null)
const quantitativeProperties = computed<QuantitativePropertyInterface[]>(() => queryResult.value && queryResult.value.quantitativeProperties.edges.length ? queryResult.value.quantitativeProperties.edges.map((edge: { node: QuantitativePropertyInterface; }) => edge.node) : null)

const propertiesClassNames = computed(() => {
    const classNames = properties.value ? properties.value.map(property => property.className.toLowerCase()) : null
    return [...new Set(classNames)]
})

const selectedClassName = ref([]);
const filteredProperties = computed(() => selectedClassName.value.length == 0 ? properties.value : properties.value.filter(property => selectedClassName.value.includes(property.className) ));

const selectedPropertiesIds = computed(() => store.getters['assetsModule/getComparisonProperties'].map((property: PropertyInterface) => property.id));
const selectedQuantitativePropertiesIds = computed(() => store.getters['assetsModule/getComparisonQuantitativeProperties'].map((property: PropertyInterface) => property.id));


const selectedProperties = ref(selectedPropertiesIds.value)
const selectedQuantitativeProperties = ref(selectedQuantitativePropertiesIds.value)


// Variables to handle new assets in the comparison table 
const showAditionOptions = ref(false);
const selectedLayerType = ref(null);
const enabled = ref(false)

// Layer Types
const { result:layerResults } = useQuery(LAYER_TYPES, null, () => ({ enabled: enabled.value,}));
const layerQueryResult = computed(() => layerResults?.value ?? null);
const layerTypes = computed<LayerTypeInterface[]>(() => layerQueryResult?.value && layerQueryResult.value?.layerTypes ? layerQueryResult.value?.layerTypes?.edges.map((edge: { node: LayerTypeInterface; }) => edge.node) : null)

const handleShowAssetAditionOptions = () => {
    enabled.value = true;
    showAditionOptions.value = true;
}

// Assets section
const assetsFromStore = computed(() => props.action != 'compare' ? store.getters['assetsModule/getAssetsToCompare'] : null);
const assets = ref(assetsFromStore.value);

// Watch for changes in the store 
watch(assetsFromStore, (newAssetsFromStore) => {
    assets.value = newAssetsFromStore
})

const assetsToDelete = ref([]);
const assetsToBeAdded = ref([]);

const enableAssetQuery = ref(false);
const queryOption = ref({ filter: {}});

const { result:assetsResult, refetch:refetchAssets } = useQuery(ALL_ASSETS, queryOption.value, () => ({ enabled: enableAssetQuery.value,}) );
const assetsQueryResult = computed(() => assetsResult?.value ?? null);
const allAssetsFromBE = computed<AssetSimpreInterface[]>(() => assetsQueryResult?.value && assetsQueryResult.value?.otherTechnologies ? assetsQueryResult.value?.otherTechnologies?.edges.map((edge: { node: AssetSimpreInterface; }) => edge.node).filter((asset: AssetSimpreInterface) => !assets.value.includes(asset)) : null)
const assetsForDropdown = computed(() => allAssetsFromBE?.value ? allAssetsFromBE.value.map(({id, nickname, title}) => ({id, name: nickname, title})) : null)

const getAssetsToAdd = () => {
    queryOption.value.filter = {targetLayers: {name: {in: selectedLayerType.value}}};
    enableAssetQuery.value = true;
    refetchAssets(); 
}

const cancelAddAssets = () => {
    assetsToBeAdded.value = [];
    selectedLayerType.value = null;
    showAditionOptions.value = false
}

const handleAssetComparison = () => {
    const propertiesToSave = computed(() => properties.value.filter(property => selectedProperties.value.includes(property.id)));
    const quantitativePropertiesToSave = computed(() => quantitativeProperties.value.filter(qproperty => selectedQuantitativeProperties.value.includes(qproperty.id)));
    if (props.action != 'compare') {
        const assetsToAdd = computed(() => assetsToBeAdded.value.length > 0 ? allAssetsFromBE.value.filter(asset => assetsToBeAdded.value.includes(asset.id)) : null)
        const assetsWithoutRemovals = computed(() => assets.value.filter((asset: any) => !assetsToDelete.value.includes(asset)));
        const assetsToSaveInStore = computed(() => assetsToAdd.value ? assetsWithoutRemovals.value.concat(assetsToAdd.value) : assetsWithoutRemovals.value)
        store.commit('assetsModule/setSelectedAssetForComparison', {assetsToCompare: assetsToSaveInStore.value})
    }
    
    store.commit('assetsModule/setSelectedPropertiesForComparison', { properties: propertiesToSave.value, quantitativeProperties: quantitativePropertiesToSave.value } );
    store.commit('assetsModule/closeComparisonCriteriaModal');
    router.push({name: 'TechnologyAssetsComparisonView'});
    assetsToDelete.value = [];
    cancelAddAssets();
}

const tableName = ref('');
const handlePublish = () => console.log('publish');
const handleSaveForLater = () => console.log('save for later');

</script>
<style lang="scss" scoped>
.assets-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;

    .asset-card {
        width: 48%;
        background-color: #fafafa;
        padding: 0.5rem 1rem;
        margin-bottom: 1rem;
        font-size: 0.8rem;
        border-radius: 6px;
        font-weight: 300;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-left: 6px solid $danger-light;
        &.to-delete {
            border-left: 6px solid $danger-light;
        }

        .name {
            font-weight: 500;
            text-transform: uppercase;

        }
    }
}

.trash {
    background-color: $danger-light;
    margin: -8px 16px -8px -16px;
    padding: 0 16px 0 10px;
    align-items: center;
    > svg {
        stroke: white;
        stroke-width: 1;
    }
}

</style>