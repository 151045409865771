import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, vModelSelect as _vModelSelect, withDirectives as _withDirectives, normalizeClass as _normalizeClass, vModelText as _vModelText, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2 gap-y-3 gap-x-4 mt-6 mb-6" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onUpdate:modelValue"]
const _hoisted_4 = ["value"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 2,
  class: "col-span-2 grid"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 3,
  class: "col-span-2"
}
const _hoisted_10 = { key: 0 }
const _hoisted_11 = ["onKeyup"]
const _hoisted_12 = {
  key: 0,
  class: "selected-results"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = ["onUpdate:modelValue"]

import { NEW_PARTY_FIELDS } from '@/models/newPartyFormFields';
import {computed} from 'vue';
import { useStore } from 'vuex';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { PARTY_ADD_FORM_VALUES } from '@/grapql/partyAddFormValues';
import { useQuery } from '@vue/apollo-composable';
import DropdownSearchableMultiple from '@/components/DropdownSearchableMultiple.vue';
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import FormErrorToast from '@/components/FormErrorToast.vue';

const tempLinks = '';

export default /*@__PURE__*/_defineComponent({
  __name: 'PartyAddNewForm',
  props: {
    partyTypeSelected: {},
    errorsFields: {},
    formIsValid: { type: Boolean },
    formSubmited: { type: Boolean },
    errorsFromMutation: {}
  },
  setup(__props: any) {

const store = useStore();
const props = __props
const partyType = computed(() => props.partyTypeSelected.toUpperCase());
const showErrors = computed(() => !props.formIsValid && props.formSubmited );
const showMutationErrors = computed(() => props.formSubmited && !!props.errorsFromMutation);

const handleInputChange = (event: Event) => (event.target as HTMLInputElement).value;

// Inizialize values for select
const { result } = useQuery(PARTY_ADD_FORM_VALUES);
const inizilizedData = computed(() => result?.value ?? null);
const formInizializationValues = computed<{[key: string]: DropdownSearchableOptionInterface[]}>(() => useFormInizializationNewAsset(inizilizedData.value, NEW_PARTY_FIELDS[partyType.value].fieldsToQuery));


// Init rich text editor
const onEditorReady = (event: Event) => {return event};


// Create Fields for the form
const fields = computed<{label:string, type:string, mutationValue:string, queryValue:string, required: boolean}[]>(() => NEW_PARTY_FIELDS[partyType.value].formFields)

const fieldsFromConstant = computed<string[]>(() => NEW_PARTY_FIELDS[partyType.value].formFields.map(formField =>  { return formField.mutationValue} ));

const formFieldOptions = {
    fields: fieldsFromConstant.value,
    base: NEW_PARTY_FIELDS[partyType.value].graphqlValue,
    mutation: 'partyModule/setSinglePartyValue'
};

const formFields = (formFieldOptions:{fields:string[], base:string, mutation:string}) => {
    const object = {};
    formFieldOptions.fields.map(fieldName => {
        object[fieldName] = computed({
            get() {
                return store.state.partyModule.party[formFieldOptions.base][fieldName];
            },
            set(value) {
                store.commit(formFieldOptions.mutation, { partyType: formFieldOptions.base, partyField: fieldName , value: value});
            }
        })
    });
    return object;
}

const vModels = formFields(formFieldOptions);



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(FormErrorToast, {
      "is-showing": showErrors.value,
      title: "The following fields are mandatory:",
      subtitle: _ctx.errorsFields.join(', ')
    }, null, 8, ["is-showing", "subtitle"]),
    (_ctx.errorsFromMutation)
      ? (_openBlock(), _createBlock(FormErrorToast, {
          key: 0,
          "is-showing": showMutationErrors.value,
          title: _ctx.errorsFromMutation,
          toastType: "warning"
        }, null, 8, ["is-showing", "title"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fields.value, (field, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
          (field.type === 'select')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["col-span-2", {'error-form-field': _ctx.errorsFields.includes(field.mutationValue) && _ctx.formSubmited }])
              }, [
                _createElementVNode("label", null, [
                  _createTextVNode(_toDisplayString(field.label) + " ", 1),
                  (field.required)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
                    : _createCommentVNode("", true)
                ]),
                _withDirectives(_createElementVNode("select", {
                  class: "w-full",
                  "onUpdate:modelValue": ($event: any) => ((_unref(vModels)[field.mutationValue].value) = $event)
                }, [
                  _cache[2] || (_cache[2] = _createElementVNode("option", {
                    selected: "",
                    value: "null"
                  }, null, -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formInizializationValues.value[`${field.queryValue}Options`], (option) => {
                    return (_openBlock(), _createElementBlock("option", {
                      value: Number(option.id),
                      key: option.id
                    }, _toDisplayString(option.name), 9, _hoisted_4))
                  }), 128))
                ], 8, _hoisted_3), [
                  [_vModelSelect, _unref(vModels)[field.mutationValue].value]
                ])
              ], 2))
            : (field.type === 'mulitple-select')
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("label", null, [
                    _createTextVNode(_toDisplayString(field.label) + " ", 1),
                    (field.required)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, "*"))
                      : _createCommentVNode("", true)
                  ]),
                  (formInizializationValues.value[`${field.queryValue}Options`])
                    ? (_openBlock(), _createBlock(DropdownSearchableMultiple, {
                        key: 0,
                        "options-list": formInizializationValues.value[`${field.queryValue}Options`],
                        modelValue: _unref(vModels)[field.mutationValue].value,
                        "onUpdate:modelValue": ($event: any) => ((_unref(vModels)[field.mutationValue].value) = $event)
                      }, null, 8, ["options-list", "modelValue", "onUpdate:modelValue"]))
                    : _createCommentVNode("", true)
                ]))
              : (field.type === 'textarea')
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("label", null, [
                      _createTextVNode(_toDisplayString(field.label), 1),
                      (field.required)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_8, "*"))
                        : _createCommentVNode("", true)
                    ]),
                    (field.type === 'textarea')
                      ? (_openBlock(), _createBlock(_unref(QuillEditor), {
                          key: 0,
                          "content-type": "html",
                          onReady: _cache[0] || (_cache[0] = ($event: any) => (onEditorReady($event))),
                          content: _unref(vModels)[field.mutationValue].value,
                          "onUpdate:content": ($event: any) => ((_unref(vModels)[field.mutationValue].value) = $event)
                        }, null, 8, ["content", "onUpdate:content"]))
                      : _createCommentVNode("", true)
                  ]))
                : (field.type === 'multiple-text')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("label", null, [
                        _createTextVNode(_toDisplayString(field.label) + " ", 1),
                        (field.required)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_10, "*"))
                          : _createCommentVNode("", true),
                        _cache[3] || (_cache[3] = _createTextVNode()),
                        _cache[4] || (_cache[4] = _createElementVNode("span", null, "(Press Enter to add)", -1))
                      ]),
                      _withDirectives(_createElementVNode("input", {
                        class: "w-full",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((tempLinks) = $event)),
                        type: "text",
                        onKeyup: _withKeys(($event: any) => {_unref(vModels)[field.mutationValue].value.push(handleInputChange($event)); tempLinks = ''}, ["enter"])
                      }, null, 40, _hoisted_11), [
                        [_vModelText, tempLinks]
                      ]),
                      (_unref(vModels)[field.mutationValue].value.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(vModels)[field.mutationValue].value, (link, index) => {
                              return (_openBlock(), _createElementBlock("span", { key: index }, [
                                _createTextVNode(_toDisplayString(link) + " ", 1),
                                _createVNode(_unref(XMarkIcon), {
                                  class: "icon icon-x-small thick inline",
                                  onClick: ($event: any) => (_unref(vModels)[field.mutationValue].value.splice(index, 1))
                                }, null, 8, ["onClick"])
                              ]))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 4,
                      class: _normalizeClass(["col-span-2", {'error-form-field': _ctx.errorsFields.includes(field.mutationValue) && _ctx.formSubmited }])
                    }, [
                      _createElementVNode("label", null, [
                        _createTextVNode(_toDisplayString(field.label) + " ", 1),
                        (field.required)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_13, "*"))
                          : _createCommentVNode("", true)
                      ]),
                      (field.type === 'text')
                        ? _withDirectives((_openBlock(), _createElementBlock("input", {
                            key: 0,
                            class: "w-full",
                            type: "text",
                            "onUpdate:modelValue": ($event: any) => ((_unref(vModels)[field.mutationValue].value) = $event)
                          }, null, 8, _hoisted_14)), [
                            [_vModelText, _unref(vModels)[field.mutationValue].value]
                          ])
                        : _createCommentVNode("", true)
                    ], 2))
        ], 64))
      }), 128))
    ])
  ], 64))
}
}

})