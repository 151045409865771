import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "label mr-3" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "value"
}
const _hoisted_6 = {
  key: 0,
  class: "lowercase"
}
const _hoisted_7 = {
  key: 0,
  class: "value"
}
const _hoisted_8 = {
  key: 1,
  class: "value"
}
const _hoisted_9 = {
  key: 2,
  class: "value"
}
const _hoisted_10 = {
  key: 0,
  class: "lowercase"
}
const _hoisted_11 = {
  key: 1,
  class: "value"
}
const _hoisted_12 = {
  key: 0,
  class: "lowercase"
}
const _hoisted_13 = {
  key: 0,
  class: "w-full"
}
const _hoisted_14 = ["innerHTML"]

import { AssetQuantitativePropertyInterface } from '@/models/assetAssessmentInterfaces';
import { ref } from 'vue';
import { PencilSquareIcon } from '@heroicons/vue/24/outline';
import AssetQuantitativePropertyEditForm from './AssetQuantitativePropertyEditForm.vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'AssetQuantitativeProperty',
  props: {
    assetQuantitativeProperty: {}
  },
  setup(__props: any) {



const onEdit = ref(null);


return (_ctx: any,_cache: any) => {
  return (!onEdit.value)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, [
              _createTextVNode(_toDisplayString(_ctx.assetQuantitativeProperty.quantitativeProperty.name) + " ", 1),
              (_ctx.assetQuantitativeProperty.quantitativeProperty.category)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, "(" + _toDisplayString(_ctx.assetQuantitativeProperty.quantitativeProperty.category) + ")", 1))
                : _createCommentVNode("", true)
            ]),
            (!onEdit.value)
              ? (_openBlock(), _createBlock(_unref(PencilSquareIcon), {
                  key: 0,
                  class: "icon",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (onEdit.value = true))
                }))
              : _createCommentVNode("", true)
          ]),
          (_ctx.assetQuantitativeProperty.valueType)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.assetQuantitativeProperty.valueType.name == 'Between')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
                      _createTextVNode(_toDisplayString(_ctx.assetQuantitativeProperty.value1) + " - " + _toDisplayString(_ctx.assetQuantitativeProperty.value2) + " ", 1),
                      (_ctx.assetQuantitativeProperty.valueUnit)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.assetQuantitativeProperty.valueUnit.abbreviation), 1))
                        : _createCommentVNode("", true)
                    ]))
                  : (_ctx.assetQuantitativeProperty.valueType.name == 'Boolean')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        (_ctx.assetQuantitativeProperty.value1 && _ctx.assetQuantitativeProperty.value1 == 'true')
                          ? (_openBlock(), _createElementBlock("p", _hoisted_7, "Yes"))
                          : (_openBlock(), _createElementBlock("p", _hoisted_8, "No"))
                      ], 64))
                    : (_openBlock(), _createElementBlock("p", _hoisted_9, [
                        _createTextVNode(_toDisplayString(_ctx.assetQuantitativeProperty.valueType.symbol) + " " + _toDisplayString(_ctx.assetQuantitativeProperty.value1) + " ", 1),
                        (_ctx.assetQuantitativeProperty.valueUnit)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.assetQuantitativeProperty.valueUnit.abbreviation), 1))
                          : _createCommentVNode("", true)
                      ]))
              ], 64))
            : (_openBlock(), _createElementBlock("p", _hoisted_11, [
                _createTextVNode(_toDisplayString(_ctx.assetQuantitativeProperty.value1) + " ", 1),
                (_ctx.assetQuantitativeProperty.valueUnit)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.assetQuantitativeProperty.valueUnit.abbreviation), 1))
                  : _createCommentVNode("", true)
              ]))
        ]),
        (_ctx.assetQuantitativeProperty.extraInformation)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _cache[2] || (_cache[2] = _createElementVNode("p", { class: "label" }, "Extra Information", -1)),
              _createElementVNode("p", {
                class: "text-sm italic mb-4",
                innerHTML:  _ctx.assetQuantitativeProperty.extraInformation
              }, null, 8, _hoisted_14)
            ]))
          : _createCommentVNode("", true)
      ], 64))
    : (_openBlock(), _createBlock(AssetQuantitativePropertyEditForm, {
        key: 1,
        "asset-quantitative-property": _ctx.assetQuantitativeProperty,
        onCancelEdition: _cache[1] || (_cache[1] = ($event: any) => (onEdit.value = false))
      }, null, 8, ["asset-quantitative-property"]))
}
}

})