<template>
    <FilesUpload label-text="Files" :preselected-files="filesAlreadySelected" @files-to-upload="onFilesToUpload"/>
</template>

<script setup lang="ts">
import FilesUpload from '@/components/FilesUpload.vue';
import { computed, defineProps } from 'vue';
import { useStore } from 'vuex';

const props = defineProps(['assetType']);
const store = useStore();

const storeSavedfiles =  computed(() => store.getters['assetCreationModule/getFiles'])
const filesAlreadySelected = computed(() => storeSavedfiles.value.assetType === props.assetType && storeSavedfiles.value.files ? storeSavedfiles.value.files : [])

const onFilesToUpload = (files:[File]) => {
    const payload = {
        assetType: props.assetType,
        files: files
    }
    store.commit('assetCreationModule/setFilesToUpload', payload);
}

</script>