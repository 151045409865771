import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }

import SignInForm from "./SignInForm.vue";
import SignUpForm from "./SignUpForm.vue";
import ForgotPasswordForm from "./ForgotPasswordForm.vue";
import PasswordResetConfirmation from "./PasswordResetConfirmation.vue";
import SignUpConfirmation from "./SignUpConfirmation.vue";
import AuthModalSlot from "./AuthModalSlot.vue";
import { ref, watch } from "vue";

const SIGN_IN_TITLE = "Sign In!";
const SIGN_UP_TITLE = "Sign Up!";
const FORGOT_PASSWORD_TITLE = "Reset Password!";
const FORGOT_PASSWORD_SUCCESS_TITLE = "Reset Email Sent!";
const SIGN_UP_SUCCESS_TITLE = "Confirmation Email Sent!";


export default /*@__PURE__*/_defineComponent({
  __name: 'AuthModal',
  props: {
  AuthModalVisible: Boolean,
},
  setup(__props) {

const currentForm = ref("signIn");
const title = ref("Sign In!");

const showSignUpForm = () => {
  currentForm.value = "signUp";
  title.value = SIGN_UP_TITLE;
};
const showSignInForm = () => {
  currentForm.value = "signIn";
  title.value = SIGN_IN_TITLE;
};
const showForgotPasswordForm = () => {
  currentForm.value = "forgotPassword";
  title.value = FORGOT_PASSWORD_TITLE;
};
const showResetSuccess = () => {
  currentForm.value = "resetSuccess";
  title.value = FORGOT_PASSWORD_SUCCESS_TITLE;
};
const signUpEmail = ref(null);
const handleSignUpEmail = (email) => {
  signUpEmail.value = email;
  currentForm.value = "signUpSuccess";
  title.value = SIGN_UP_SUCCESS_TITLE;
};

const props = __props;

watch(
  () => props.AuthModalVisible,
  (newValue, oldValue) => {
    if (!newValue) {
      currentForm.value = "signIn";
      title.value = SIGN_IN_TITLE;
    }
  }
);

return (_ctx: any,_cache: any) => {
  return (__props.AuthModalVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (__props.AuthModalVisible)
          ? (_openBlock(), _createBlock(AuthModalSlot, {
              key: 0,
              onCloseModal: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('closeModal'))),
              title: title.value
            }, {
              default: _withCtx(() => [
                (currentForm.value === 'signIn')
                  ? (_openBlock(), _createBlock(SignInForm, {
                      key: 0,
                      onShowSignUp: showSignUpForm,
                      onShowForgotPassword: showForgotPasswordForm,
                      onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeModal')))
                    }))
                  : (currentForm.value === 'signUp')
                    ? (_openBlock(), _createBlock(SignUpForm, {
                        key: 1,
                        onShowSignIn: showSignInForm,
                        onCloseModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeModal'))),
                        onShowEmail: handleSignUpEmail
                      }))
                    : (currentForm.value === 'forgotPassword')
                      ? (_openBlock(), _createBlock(ForgotPasswordForm, {
                          key: 2,
                          onShowSignIn: showSignInForm,
                          onCloseModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('closeModal'))),
                          onShowSuccess: showResetSuccess
                        }))
                      : (currentForm.value === 'resetSuccess')
                        ? (_openBlock(), _createBlock(PasswordResetConfirmation, {
                            key: 3,
                            onShowSignIn: showSignInForm,
                            onCloseModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('closeModal')))
                          }))
                        : (currentForm.value === 'signUpSuccess')
                          ? (_openBlock(), _createBlock(SignUpConfirmation, {
                              key: 4,
                              onShowSignIn: showSignInForm,
                              onCloseModal: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('closeModal'))),
                              email: signUpEmail.value
                            }, null, 8, ["email"]))
                          : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["title"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}
}

})