import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/icons/position.png'
import _imports_1 from '../assets/icons/layers.svg'


const _hoisted_1 = {
  key: 0,
  class: "program-detail-card mb-4 mr-4"
}
const _hoisted_2 = {
  key: 2,
  class: "program-detail-card mb-4 mr-4"
}

import { computed, ref } from 'vue';
import { useStore } from 'vuex';
// import ProgramVersionDesign from './ProgramVersionDesign.vue';
// import { DesignFromBEInterface } from '@/models/programVersionsInterface';
import ProgramVersionDesignFromBenchling from './ProgramVersionDesignFromBenchling.vue';
import { useQuery } from '@vue/apollo-composable';

import { HasuraDesignsInterface } from '@/models/hasuraModels';
import { BENCHLING_DESIGNS } from '@/grapql/hasuraQueries';





export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramVersionDesignsCard',
  setup(__props) {

const store = useStore();

// Desings from BE
// const designs = computed(() => store.state['programVersionDetailModule']['programVersionFromBE']['designs'].edges.map(edge => edge.node))

// const parsedDesigns = computed<DesignFromBEInterface[]>(() =>    
//     designs.value.length ? 
//         designs.value.map(({ layers, ...rest}) => ({
//             layers: layers.edges.map(edge => edge.node),
//             ...rest
//         })) 
//     : []);


// ALLOW TEMPORTARY ACCESS ONLY TO MARTA & MARTIN
const isUserAllowToSeeExtraInfo = computed(() => store.getters['authModule/isUserAllowToSeeExtraInfo']);


const programName = computed(() => store.getters['programVersionDetailModule/getProgramName'])

// Benchling and Hasure integration
const options = ref({clientId: 'hasuraClient'});

const designsFromBenchlig = ref(null);
const { onResult, loading } = useQuery(BENCHLING_DESIGNS, null, options);
onResult(queryResult => {
    if(queryResult.data && queryResult.data.prod_mart_data_test_designs) {
        designsFromBenchlig.value = queryResult.data.prod_mart_data_test_designs;
        store.commit('programVersionDetailModule/setDesingsFromBenchling', {designsFromBenchlig: designsFromBenchlig.value})
    }
    
})

const designsToShow = computed<HasuraDesignsInterface[]>(() => designsFromBenchlig.value ? designsFromBenchlig.value.filter((design: HasuraDesignsInterface) => design.programmes.includes(programName.value)) : null);
const wipDesigns = computed<HasuraDesignsInterface[]>(() => designsToShow.value ? designsToShow.value.filter((design: HasuraDesignsInterface) => design.locked_design == 'Yes') : null);
const archivedDesigns = computed<HasuraDesignsInterface[]>(() => designsToShow.value ? designsToShow.value.filter((design: HasuraDesignsInterface) => design.locked_design == 'No') : null);


return (_ctx: any,_cache: any) => {
  return (_unref(loading))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
        _createElementVNode("div", { class: "loading-gif-transparent" }, null, -1)
      ])))
    : (wipDesigns.value && wipDesigns.value.length || archivedDesigns.value && archivedDesigns.value.length)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (wipDesigns.value && wipDesigns.value.length)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"flex items-center my-3\" data-v-4fb6a82d><p class=\"cards-title m-0\" data-v-4fb6a82d>current material combinations</p><div class=\"tooltip\" data-v-4fb6a82d><img src=\"" + _imports_0 + "\" class=\"tooltip-icon\" data-v-4fb6a82d><p class=\"tooltip-info\" data-v-4fb6a82d>Current material combinations are selected by the programme developer to reflect the potential performance of a final design.<span class=\"font-medium\" data-v-4fb6a82d> Please note!</span> These combinations do not confirm final performance but should give you an understanding on where we stand in the development.</p></div></div>", 1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(wipDesigns.value, (design) => {
                  return (_openBlock(), _createBlock(ProgramVersionDesignFromBenchling, {
                    key: design,
                    designData: design
                  }, null, 8, ["designData"]))
                }), 128))
              ], 64))
            : _createCommentVNode("", true),
          (archivedDesigns.value && archivedDesigns.value.length && isUserAllowToSeeExtraInfo.value)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex items-center my-3" }, [
                  _createElementVNode("p", { class: "cards-title m-0" }, "Archived material combinations")
                ], -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(archivedDesigns.value, (design) => {
                  return (_openBlock(), _createBlock(ProgramVersionDesignFromBenchling, {
                    key: design,
                    designData: design
                  }, null, 8, ["designData"]))
                }), 128))
              ], 64))
            : _createCommentVNode("", true)
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[3] || (_cache[3] = [
          _createElementVNode("p", { class: "cards-title" }, "designs", -1),
          _createElementVNode("div", { class: "flex items-center justify-center opacity-15" }, [
            _createElementVNode("img", {
              src: _imports_1,
              class: "w-2/3 max-w-48 pb-8 pr-4"
            }),
            _createElementVNode("p", { class: "text-xl uppercase font-extrabold" }, [
              _createTextVNode("No desings yet."),
              _createElementVNode("br")
            ])
          ], -1)
        ])))
}
}

})