<template>
  <div>
    <div v-if="loading" class="loading-gif"></div>
    <vue-good-table v-else
      ref="run-repo"
      :columns="columns"
      :rows="rows"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
        perPageDropdown: [5, 10,20],
        dropdownAllowAll: false,
      }"
      v-on:page-change="$emit('onPageChange')"
      v-on:per-page-change="$emit('onPageChange')"
      :row-style-class="rowStyleClass"
      styleClass="vgt-table my-table"
      v-on:row-click="onRowClick"
      class="vgt-holder"
      :search-options="{
        enabled: false,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      v-on:selected-rows-change="onSelectedRowsChange"
    >
      <template #emptystate> You have no runs in this group.</template>
    </vue-good-table>

    <hr class="mt-10" />
  </div>
</template>

<script setup>
import { useQuery } from "@vue/apollo-composable";
import { ref, computed, defineProps,defineEmits } from "vue";
import { VueGoodTable } from "vue-good-table-next";
import "vue-good-table-next/dist/vue-good-table-next.css";
// in Query it should always be more than perPage, I have used perPage*2
const perPage = ref(5);

const rowStyleClass = (row) => {
  return "hover:bg-gray-100";
};
const onRowClick = (params) => {
  // Find the closest TR element in the DOM hierarchy
  const closestTR = params.event.target.closest('tr');
  const closestTH = params.event.target.closest('th');
  if(!closestTR || closestTR.querySelector('th:first-child') !== closestTH){
    emits("onRowClick",params.row.id);
  }
};

const props = defineProps(["columns", "rows", "searchTerm","loading"]);
const emits = defineEmits(["onRowClick","onPageChange","onSelectedRowsChange"]);
// const VueGoodTableComponent = VueGoodTable;

const onSelectedRowsChange=(event)=>{
  emits("onSelectedRowsChange",event.selectedRows.map(obj=>obj.id))
}
</script>

<style>
.vgt-holder .vgt-wrap__footer {
  background: none;
}
.my-table {
  border: none;
}
.my-table tbody tr {
  cursor: pointer;
}
.my-table tbody tr td {
  padding: 15px !important;
}
.my-table thead,
.my-table thead tr,
.my-table thead tr th {
  background: #eee;
  padding: 15px;
  text-transform: uppercase;
}

.round-tooltip-icon {
  border-radius: 50%;
  background: #e3e3e3;
  border: solid 1px #e0e0e0;
  padding: 1px 10px;
  cursor: pointer;
  font-size: 25px;
}

/* .vgt-table th:first-of-type{
    position: sticky;
    z-index: 2;
}
.sticky-col {
    position: sticky; 
    background-color: white;
}  
.first-col {
	left: 0px;    
}
.vgt-table .sticky-col:hover{
  background-color: gray;
} */

.vgt-table th.vgt-checkbox-col{
  position: sticky !important;
  left: 0;
  z-index: 2;
}
</style>
