import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = {
  key: 0,
  class: "loading-gif"
}
const _hoisted_3 = {
  key: 0,
  class: "empty-box add"
}
const _hoisted_4 = {
  key: 1,
  class: "grid grid-cols-4 gap-3"
}

import ProductBriefCard from './ProductBriefCard.vue';
import { PRODUCT_BRIEFS_QUERY } from '@/grapql/productBriefsQuery';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import { ProductBriefInterface } from '@/models/productBriefsInterface';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductBriefsDashboard',
  setup(__props) {

const { result, loading } = useQuery(PRODUCT_BRIEFS_QUERY);
const queryResult = computed(() => result?.value ?? null);

const productBriefs = computed<ProductBriefInterface[]>(() => queryResult?.value && queryResult?.value?.productBriefs.edges.length > 0 ? queryResult.value.productBriefs.edges.map((edge: { node: ProductBriefInterface }) => edge.node) : []);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(loading))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : (productBriefs.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (productBriefs.value.length == 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(productBriefs.value, (brief) => {
                    return (_openBlock(), _createBlock(ProductBriefCard, {
                      key: brief.id,
                      "product-brief": brief
                    }, null, 8, ["product-brief"]))
                  }), 128))
                ]))
          ], 64))
        : _createCommentVNode("", true)
  ]))
}
}

})