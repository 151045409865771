import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "accordion-card" }
const _hoisted_2 = { class: "accordion-card-content" }

import { ref } from 'vue';
    
export default /*@__PURE__*/_defineComponent({
  __name: 'AccordionCard',
  props: {startOpen: Boolean},
  setup(__props) {

    const props = __props
    const isOpen =  ref(props.startOpen);
    const toggleAccordion = () => isOpen.value = !isOpen.value;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", { onClick: toggleAccordion }, [
      _renderSlot(_ctx.$slots, "title")
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "content")
    ], 512), [
      [_vShow, isOpen.value]
    ])
  ]))
}
}

})