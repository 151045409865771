import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "accordion-item"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

import { ref } from 'vue';
    import { ChevronRightIcon } from '@heroicons/vue/24/solid';
    import AssetDetailInfoContent from './AssetDetailInfoContent.vue';
    import AssetDetailPriceContent from './AssetDetailPriceContent.vue';
    
export default /*@__PURE__*/_defineComponent({
  __name: 'AccordionList',
  props: ['dataForAccordionList'],
  setup(__props) {

    const props = __props;
    const expandedField = ref(props.dataForAccordionList.filter(item => item.expanded).map(ei => ei.title));
    const toggleExpandedField = (title:string) => {
        const titlePos = expandedField.value.indexOf(title);
        titlePos == -1 ? expandedField.value.push(title) : expandedField.value.splice(titlePos, 1);
    }

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.dataForAccordionList, (item) => {
    return (_openBlock(), _createElementBlock("div", {
      key: item.title
    }, [
      (item.content || item.infoContent || item.priceContent)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (item.content || item.infoContent || item.priceContent)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: ($event: any) => (toggleExpandedField(item.title)),
                  class: "title"
                }, [
                  _createVNode(_unref(ChevronRightIcon), {
                    class: _normalizeClass(["icon", {'face-down': expandedField.value.includes(item.title)}])
                  }, null, 8, ["class"]),
                  _createElementVNode("p", null, _toDisplayString(item.title), 1)
                ], 8, _hoisted_2))
              : _createCommentVNode("", true),
            (item.infoContent)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass([{'visible': expandedField.value.includes(item.title)}, "content"])
                }, [
                  _createVNode(AssetDetailInfoContent, {
                    "info-content": item.infoContent
                  }, null, 8, ["info-content"])
                ], 2))
              : (item.priceContent)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass([{'visible': expandedField.value.includes(item.title)}, "content"])
                  }, [
                    _createVNode(AssetDetailPriceContent, {
                      "price-content": item.priceContent
                    }, null, 8, ["price-content"])
                  ], 2))
                : (item.innerHtml && item.mutationType == 'textList')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 3,
                      class: _normalizeClass([{'visible': expandedField.value.includes(item.title)}, "content"])
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.content, (claim) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: claim,
                          innerHTML: claim
                        }, null, 8, _hoisted_3))
                      }), 128))
                    ], 2))
                  : (item.innerHtml)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 4,
                        innerHTML: item.content,
                        class: _normalizeClass([{'visible': expandedField.value.includes(item.title)}, "content"])
                      }, null, 10, _hoisted_4))
                    : (_openBlock(), _createElementBlock("div", {
                        key: 5,
                        class: _normalizeClass([{'visible': expandedField.value.includes(item.title)}, "content"])
                      }, _toDisplayString(item.content), 3))
          ]))
        : _createCommentVNode("", true)
    ]))
  }), 128))
}
}

})