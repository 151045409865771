import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "title-menu-header" }
const _hoisted_2 = { class: "view-title" }
const _hoisted_3 = { class: "action-icons" }
const _hoisted_4 = { class: "show-assets" }
const _hoisted_5 = { class: "view-subtitle" }


import PopUp from "@/tea/components/PopUp.vue";
import SearchAsYouType from "@/components/SearchAsYouType.vue";
import RunGroupAddNew from "./RunGroupAddNew.vue";

const RUN_GROUP_HELP_CONTENT = "Group Similar Runs (Scenarios), so that you can compare and do sensitivity analysis";


export default /*@__PURE__*/_defineComponent({
  __name: 'RunGroupHeader',
  props: ["title", "edgeCount", "totalCount"],
  emits: ["searchText", "onCreated"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const onSearchText = (searchValue: string) => emit("searchText", searchValue);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, [
        _createTextVNode(_toDisplayString(__props.title) + "   ", 1),
        _createVNode(PopUp, { content: RUN_GROUP_HELP_CONTENT }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("span", { class: "round-tooltip-icon" }, "?", -1)
          ])),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(RunGroupAddNew, {
          onOnCreated: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onCreated')))
        }),
        _createVNode(SearchAsYouType, { onSearchText: onSearchText })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h2", _hoisted_5, "Loaded " + _toDisplayString(__props.edgeCount) + " of " + _toDisplayString(__props.totalCount) + " run groups", 1)
    ])
  ]))
}
}

})