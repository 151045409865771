<template>
    <template v-if="programMockup">
        <div class="program-card" @click="goToProgramDetailMockup(programMockup.id)">
            <div class="program status-dot" :class="programMockup.status.value"></div>
            <div class="status-text" :class="programMockup.status.value">{{ programMockup.status.label }}</div>
            <div class="program-name-version">
                <p class="name">{{ programMockup.name }}</p>
                <p class="version">{{ programMockup.version }}</p>
            </div>
            <div class="programs-leaders">
                <AvatarsActivity :users-activity="programMockup.leadTeam" orientation="left" />
            </div>
            <p class="program-description">{{ programMockup.description }}</p>
            <ProgramCardStageRuler :program-stage="programMockup.stage" />
        </div>
    </template>

    <template v-if="programVersion">
        <div class="program-card flex flex-col justify-between" @click="goToProgramDetail(programVersion.id)">
            <div class="flex flex-col program-name-version"> 
                <p class="name">{{ programVersion.program.name }}</p>
                <p class="version">{{ programVersion.name }}</p>
                <p v-html="programVersion.goal" class="program-description mt-5"></p>
            </div>
            <div class="my-5" v-if="applications.length">
                <p class="programs-tags" v-for="applicationType in applications" :key="applicationType.id">
                   {{ applicationType.name }}
                </p>
            </div>
        </div>
    </template>
</template>

<script setup lang="ts">
import { defineProps, computed, PropType } from 'vue';
import router from '@/router';
import AvatarsActivity from './AvatarsActivity.vue';
import { ProgramVersionsInterface, ApplicationTypesInterface } from '@/models/programVersionsInterface';
import { programMockupIterface } from '@/models/programsInterface';
import ProgramCardStageRuler from './ProgramCardStageRuler.vue';

const props = defineProps({
    programVersion: {
        type: Object as PropType<ProgramVersionsInterface>,
        required: false,
    },
    programVersionApplications: {
        type: Object,
        required: false,
    },
    programMockup: {
        type: Object as PropType<programMockupIterface>,
        required: false
    }
})

const goToProgramDetailMockup = (programmeId) => router.push({ name: 'ProgramDetail', params: { id: programmeId } });
const goToProgramDetail = (programmeId) => router.push({ name: 'ProgramDetailWIP', params: { id: programmeId } });

const applications = computed<ApplicationTypesInterface[]>(() => props.programVersion ? props.programVersionApplications.edges.length ? props.programVersionApplications.edges.map((edge) => edge.node) : [] : []);

</script>
<style lang="scss" scoped>
.program-card {
    border: $border;
    background: $grey-super-light;
    padding: 1rem 1.5rem;
    font-size: 0.9rem;
    margin-right: 1rem;
    border-radius: 4px;
    flex-basis: 340px;
    position: relative;
    cursor: pointer;
    margin-bottom: 1rem;
}
.program-name-version {
    margin: 1.5rem 0 1rem;
    line-height: 1.2;
    .name {
        font-size: 0.8rem;
        color: $font-color-super-light;
        font-weight: 300;
    }
    .version {
        color: $font-color;
        font-weight: 700;
        font-size: 1.1rem;
    }
}

.program-description {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 300;
    color: $grey;
    min-height: 80px;
}
.status-text {
    position: absolute;
    top: 5px;
    text-transform: uppercase;
    font-size: 0.7rem;
    font-weight: 700;

    &.ok {
        color: #98D2C0;
    }

    &.delayed {
        color: $warning-strong;
    }

    &.atRisk {
        color: $danger-light;
    }
}
</style>