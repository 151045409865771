import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center" }
const _hoisted_2 = { class: "grid grid-cols-1" }
const _hoisted_3 = { class: "text-center bg-red-100 border border-red-100 p-3 rounded" }
const _hoisted_4 = { class: "m-4 uppercase text-gray-500" }

import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'AIError',
  setup(__props) {

const store = useStore();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(store).state.aiModule.error), 1)
      ])
    ])
  ]))
}
}

})