import gql from "graphql-tag";

export const PARTY_ADD_FORM_VALUES = gql`
query getPartyAddFormValues {
    sources {
        edges {
            node {
                id
                name
            }
        }
    }
    organizationTypes {
        edges {
            node {
                id
                name
            }
        }
    }
    businessTypes {
        edges {
            node {
                id
                name
            }
        }
    }
    
}`