<template>
   <div class="mb-3">
      <div> 
         <label>Blend Percentage</label>
         <input v-model="blendPercentage" type="number" min="0" max="100" class="w-full mb-4">
      </div>
      <div class="mb-3">
         <label>Thickness <span>(Range of thickness. Ex: 3-6)</span> </label>
         <div class="flex justify-between">
            <input v-model="thicknessFirstValue" type="number" min="0" class="w-2/5"> - 
            <input v-model="thicknessSecondValue" type="number" min="0" class="w-2/5">
         </div> 
         <p v-if="!!errorForThisRelation.length" class="error-form-field"><label>{{ errorForThisRelation[0].error }}</label></p>
         <p v-if="formSubmited" class="error-form-field"><label>{{ errorFromRange }}</label></p>
      </div>
      <div class="mb-3">
         <label>Thickness Unit</label> 
         <select v-model="thicknessUnit" class="w-full">
            <option v-for="unit in unitOptions['unitTypesOptions']" :key="unit.id" :value="unit.id">
              {{ unit.abbreviation }} - {{ unit.name }}
            </option>
         </select>
      </div>
      <div class="mb-3 assessment-editor">
         <label>Notes</label>
         <QuillEditor v-model:content="notes" content-type="html" @ready="onEditorReady($event)" />
      </div>
   </div>
   <div class="edit-assessment-buttons"> 
      <div @click="handleSaveChanges" class="on-green"><CheckIcon class="icon"/><p>Save Changes</p></div>
      <div @click="$emit('cancelEdition')"><XMarkIcon class="icon"/><p>cancel</p></div> 
   </div> 
</template>
<script setup lang="ts">
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import { ASSET_MATERIAL_FORM_VALUES } from '@/grapql/assetMaterialFormQuery';
import { AssetMaterialInterface, AssetMaterialUpdateMutationInput } from '@/models/assetAssessmentInterfaces';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { XMarkIcon, CheckIcon } from '@heroicons/vue/24/outline';
import { useQuery } from '@vue/apollo-composable';
import { defineProps, defineEmits, ref, computed} from 'vue';
import { useStore } from 'vuex';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const props = defineProps<{assetMaterial: AssetMaterialInterface}>();
const emit = defineEmits(['cancelEdition']);

const store = useStore();
const onEditorReady = (event: Event) => {return event};

const { result } = useQuery(ASSET_MATERIAL_FORM_VALUES);
const inizilizedData = computed(() => result?.value ?? null );
const unitOptions = computed<{[key: string]: DropdownSearchableOptionInterface[]}>(() => useFormInizializationNewAsset(inizilizedData.value, ['unitTypes']));

const assetId = computed(() => store.getters['assetDetailModule/getAssetId']);
const errorsFromStore = computed(() => store.getters['assetDetailModule/getErrorsOnMaterialUpdate']);
const errorForThisRelation = computed(() =>  errorsFromStore.value.length > 0 ? errorsFromStore.value.filter(error => error.id == props.assetMaterial.id) : [] )
const errorFromRange = computed(() => ((!thicknessFirstValue.value && thicknessSecondValue.value) || (thicknessFirstValue.value && !thicknessSecondValue.value)) ? 'Thickness should be a range, please complete both values' : null);
const formSubmited = ref(false)


const blendPercentage = props.assetMaterial ? ref(props.assetMaterial.blendPercentage) : ref(null);
const notes = props.assetMaterial ? ref(props.assetMaterial.notes) : ref(null);
const thicknessFirstValue = props.assetMaterial && props.assetMaterial.thickness ? ref(props.assetMaterial.thickness[0]) : ref(null);
const thicknessSecondValue = props.assetMaterial && props.assetMaterial.thickness ? ref(props.assetMaterial.thickness[1]) : ref(null);
const thicknessUnit = props.assetMaterial && props.assetMaterial.thicknessUnit ? ref(props.assetMaterial.thicknessUnit.id) : ref(null);

const handleSaveChanges = async () => {
   formSubmited.value = true;
   if (!errorFromRange.value ){
      const materialPayload = ref<AssetMaterialUpdateMutationInput>({
      asset: assetId.value,
      material: props.assetMaterial.material.id,
      thickness: thicknessFirstValue.value && thicknessSecondValue.value ? [thicknessFirstValue.value, thicknessSecondValue.value] : null,
      thicknessUnit: thicknessUnit.value,
      blendPercentage: blendPercentage.value,
      notes: notes.value,
      id: props.assetMaterial.id
    })
    await store.dispatch('assetDetailModule/assetMaterialUpdate', materialPayload.value)
    .then(() => { 
      if (!errorForThisRelation.value.length) {
         emit('cancelEdition')}
    })
   }
    
   
}
  
</script>
<style lang="scss" scoped>
   .edit-assessment-buttons {
      margin: 0;
      margin-bottom: 1rem;
   }
   label {
		font-size: 0.9rem;
		color: $font-color-super-light;
		font-weight: 300;
	}

	.value {
		font-size: 0.9rem;
		color: rgb(109, 109, 109);
		font-weight: 500;
		padding-bottom: 0.5rem;
		overflow: auto;
		text-transform: uppercase;
		overflow: visible;
	}
   .error-form-field label {
      font-size: 0.8rem;
      font-weight: 400;
      margin-top: 10px;
   }
</style>