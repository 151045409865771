import { useStore } from "vuex";
import { computed } from "vue";


export const useFormFieldsCreationNewAsset = (formFieldOptions:{fields:string[], base:string, mutation:string}) => {
    const store = useStore();
    const object = {};
    formFieldOptions.fields.map(fieldName => {
        object[fieldName] = computed({
            get() {
                return store.state.assetCreationModule.asset[formFieldOptions.base][fieldName];
            },
            set(value) {
                store.commit(formFieldOptions.mutation, { assetType: formFieldOptions.base, assetField:fieldName , value: value});
            }
        })
    });
    return object;
}