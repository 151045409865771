<template>
    <div class="program-detail-card mb-4 ">
        <p class="cards-title">team</p>
        <div v-if="programVersionTeam.length && teamAsAvatarts.length" class="py-3 mr-6">
            <template v-for="(avatar, index) in teamAsAvatarts" :key="index">
                <div class="team-member">
                    <AvatarsActivity
                        :users-activity="[avatar]"
                        orientation="right"
                    />
                </div>
            </template>
        </div>
        <div v-else class="empty-box add"></div>
    </div>
</template>
<script setup lang="ts">
import { ProgramVersionTeamInterface } from '@/models/programVersionsInterface';
import { computed, defineProps } from 'vue';
import AvatarsActivity from '@/components/AvatarsActivity.vue';

const props = defineProps<{ programVersionTeam?: ProgramVersionTeamInterface[] }>()

const teamAsAvatarts = computed(() => {
    const teamAsAvatarts = [];
    props.programVersionTeam.map((teamMember: ProgramVersionTeamInterface) => {
        const newAvatar = {
            title: `${teamMember.teamRoleType.name} - ${teamMember.teamRoleType.category}`,
            personName: `${teamMember.user.firstName.toUpperCase()} ${teamMember.user.lastName.toUpperCase()}`,
            initials: `${teamMember.user.firstName.charAt(0)}${teamMember.user.lastName.charAt(0)}`,
            color: '#5ea2a4',
            twoLines: true
        }
        teamAsAvatarts.push(newAvatar);
    })
    return teamAsAvatarts;
});
</script>

<style lang="scss" scoped>
.team-member {
     margin-bottom: -1rem;
}

</style>