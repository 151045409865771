import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, isRef as _isRef, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "mb-4" }
const _hoisted_2 = ["value"]
const _hoisted_3 = { class: "mb-4" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "mb-4 assessment-editor" }
const _hoisted_6 = { class: "edit-assessment-buttons internal" }

import { type PropType, computed, ref } from 'vue';
import { LinkedProgramInterface } from '@/models/linkedProgramInterface';
import { useStore } from 'vuex';
import { CheckIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { ASSET_PROGRAM_LINK_FORM_VALUES } from '@/grapql/assetProgramLinkFormQueries';
import { useQuery } from '@vue/apollo-composable';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import { QuillEditor } from '@vueup/vue-quill';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssetDetailAssessmentProgramsLinkEditable',
  props: { linkedProgram: Object as PropType<LinkedProgramInterface> },
  emits: ['cancelEdition'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const store = useStore();
const assetId = computed(() => store.getters["assetDetailModule/getAssetId"]);
const onEditorReady = (event: Event) => { return event }

const { result } = useQuery(ASSET_PROGRAM_LINK_FORM_VALUES);
const inizilizedData = computed(() => result?.value ?? null );
const optionsListForDropdown = computed<{[key: string]: DropdownSearchableOptionInterface[]}>(() => useFormInizializationNewAsset(inizilizedData.value, ['assetProgramLinkTypes', 'assetEvaluationStatuses']));

const linkType = props.linkedProgram ? ref(props.linkedProgram.linkType.id) : ref(null);
const linkStatus = props.linkedProgram ? ref(props.linkedProgram.linkStatus.id) : ref(null)
const statusReason = props.linkedProgram && props.linkedProgram.statusReason ? ref(props.linkedProgram.statusReason) : ref('');

const onEditorUpdate = (event) => statusReason.value = event;

const handleEdit = async () => {
    const editLinkPayload = ref({
        asset: Number(assetId.value),
        program: Number(props.linkedProgram.program.id),
        linkType: Number(linkType.value),
        id: Number(props.linkedProgram.id),
        linkStatus: Number(linkStatus.value),
        statusReason: statusReason.value
    })
    await store.dispatch('assetDetailModule/assetProgramLinkUpdate', editLinkPayload.value).then( () => emit('cancelEdition'))
}






return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[5] || (_cache[5] = _createElementVNode("label", null, "Link Type *", -1)),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(linkType) ? (linkType).value = $event : null)),
        class: "w-full"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(optionsListForDropdown.value['assetProgramLinkTypesOptions'], (linkType) => {
          return (_openBlock(), _createElementBlock("option", {
            key: linkType.id,
            value: linkType.id
          }, _toDisplayString(linkType.name), 9, _hoisted_2))
        }), 128))
      ], 512), [
        [_vModelSelect, _unref(linkType)]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[6] || (_cache[6] = _createElementVNode("label", null, "Link Status *", -1)),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(linkStatus) ? (linkStatus).value = $event : null)),
        class: "w-full"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(optionsListForDropdown.value['assetEvaluationStatusesOptions'], (status) => {
          return (_openBlock(), _createElementBlock("option", {
            key: status.id,
            value: status.id
          }, _toDisplayString(status.name), 9, _hoisted_4))
        }), 128))
      ], 512), [
        [_vModelSelect, _unref(linkStatus)]
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[7] || (_cache[7] = _createElementVNode("label", null, "Status Reason", -1)),
      _createVNode(_unref(QuillEditor), {
        content: _unref(statusReason).toString(),
        "content-type": "html",
        onReady: _cache[2] || (_cache[2] = ($event: any) => (onEditorReady($event))),
        "onUpdate:content": _cache[3] || (_cache[3] = ($event: any) => (onEditorUpdate($event)))
      }, null, 8, ["content"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", {
        onClick: handleEdit,
        class: "on-green"
      }, [
        _createVNode(_unref(CheckIcon), { class: "icon" }),
        _cache[8] || (_cache[8] = _createElementVNode("p", null, "Save Changes", -1))
      ]),
      _createElementVNode("div", {
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('cancelEdition')))
      }, [
        _createVNode(_unref(XMarkIcon), { class: "icon" }),
        _cache[9] || (_cache[9] = _createElementVNode("p", null, "cancel", -1))
      ])
    ])
  ], 64))
}
}

})