import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex mb-6"
}
const _hoisted_2 = {
  style: {"width":"40px","min-width":"40px"},
  class: "mr-4"
}
const _hoisted_3 = { class: "flex items-center justify-center border border-gray-200 rounded-full h-10 w-10" }
const _hoisted_4 = { class: "flex-grow p-3 border border-gray-200 shadow-sm" }
const _hoisted_5 = ["innerHTML"]

import { computed } from 'vue';
import { useStore } from 'vuex';
import { CpuChipIcon } from '@heroicons/vue/24/outline';
import HeartBeat from './HeartBeat.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LastPulse',
  setup(__props) {

const store = useStore();

// get lastPulse
const lastPulse = computed(()=> store.state.aiModule.lastPulse);


return (_ctx: any,_cache: any) => {
  return (lastPulse.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(CpuChipIcon), { class: "icon icon-small black heartbeat-circle" })
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", {
            innerHTML: JSON.parse(lastPulse.value),
            style: {"width":"auto !important"}
          }, null, 8, _hoisted_5),
          _createVNode(HeartBeat, { style: {"display":"inline-block !important"} })
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})