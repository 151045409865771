export const RUN_GROUP_TABLE_COLUMS = [
  { label: "ID", field: "id", hidden: true },
  { label: "Name", field: "name" },
  { label: "Description", field: "description" },
  { label: "Created At", field: "createdAt" },
  { label: "Created By", field: "createdBy.fullName", sortable: true },
];

export const NEW_RUN_GROUP_FIELDS = {
  graphqlValue: "runGroup",
  formFields: [
    { label: "Name", type: "text", mutationValue: "name", required: true, queryValue:"name" },
    { label: "Description", type: "textarea", mutationValue: "description" },
  ],
  mandatoryFormFields: ["name"],
  fieldsToQuery: [],
  createMutation: "runGroupModule/runGroupCreate",
};


export const RUN_GROUP ={
  key: 'RIN_GROUP',
  graphqlValue: 'runGroup',
  urlValue: 'run_group', 
  displayValue: 'Run Group'
}