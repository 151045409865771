import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, computed } from "vue";
import RunGroupHeader from "@/tea/components/rungroups/RunGroupHeader.vue";
import RunGroupTable from "@/tea/components/rungroups/RunGroupTable.vue";
import { RUN_GROUP_TABLE_COLUMS } from "@/tea/models/runGroup";
import router from "@/router";
import { useStore } from "vuex";
const HEADER_TITLE = "Run Groups";

export default /*@__PURE__*/_defineComponent({
  __name: 'RunGroupRepo',
  setup(__props) {

const store = useStore();

const columns = ref(RUN_GROUP_TABLE_COLUMS);

const searchTerm = ref("");
const handleSearch = (searchValue) => {
  searchTerm.value = searchValue;
};
const repo = computed(() => store.getters["runGroupModule/repo"]);
const runGroups = computed(() => {
  const loadedRunGroups = repo.value.runGroups.map((node) => {
    return { ...node, createdAt: new Date(node.createdAt).toLocaleString() };
  });
  return { loadedRunGroups, totalCount: repo.value.totalCount, edgeCount: repo.value.edgeCount, loading: repo.value.loading };
});

store.dispatch("runGroupModule/loadRunGroups");

const hasNextPage = computed(() => {
  if (repo.value.pageInfo) {
    return repo.value.pageInfo.hasNextPage;
  }
  return false;
});

const loadMore = async () => {
  await store.dispatch("runGroupModule/loadMoreRunGroups");
};
const onCreated = async () => {
  console.log("created");
};
const onPageChange = () => {
  if (hasNextPage.value) {
    loadMore();
  }
};

const onRowClick = (id) => {
  router.push({ name: "Tea.RunGroupDetail", params: { id } });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(RunGroupHeader, {
      title: HEADER_TITLE,
      "edge-count": runGroups.value.edgeCount,
      "total-count": runGroups.value.totalCount,
      onSearchText: handleSearch,
      onOnCreated: onCreated
    }, null, 8, ["edge-count", "total-count"]),
    _createVNode(RunGroupTable, {
      columns: columns.value,
      rows: runGroups.value.loadedRunGroups,
      onOnPageChange: onPageChange,
      onOnRowClick: onRowClick,
      searchTerm: searchTerm.value,
      loading: runGroups.value.loading
    }, null, 8, ["columns", "rows", "searchTerm", "loading"])
  ]))
}
}

})