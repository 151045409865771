import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "view-title mb-4 flex justify-between items-center" }

import {computed, ref} from 'vue';
import AccordionCard from '@/components/AccordionCard.vue';
import AccordionCardHeader from '@/components/AccordionCardHeader.vue';
import AssetDetailAssessmentHighLevel from '@/components/AssetDetailAssessmentHighLevel.vue';
import { ChevronDoubleLeftIcon } from '@heroicons/vue/24/solid';
import { useStore } from 'vuex';
import AssetDetailAssessmentHighLevelEditable from './AssetDetailAssessmentHighLevelEditable.vue';
import AssetDetailAssessmentProgramsLinks from './AssetDetailAssessmentProgramsLinks.vue';
import AssetDetailAssessmentInDepth from './AssetDetailAssessmentInDepth.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssetDetailAssessmentSection',
  props: ['assetDetailAssessmentData'],
  setup(__props) {


const store = useStore();
const togglePannel = () => store.commit('assetDetailModule/toggleAssessmentPannel');
const highLevelEditable = computed<boolean>(() => store.getters['assetDetailModule/isAssetAssessmentHighLevelEditable']);
const isInDepthOopen = ref(true)

return (_ctx: any,_cache: any) => {
  return (__props.assetDetailAssessmentData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("span", null, "Assessment", -1)),
          _createVNode(_unref(ChevronDoubleLeftIcon), {
            class: "icon icon-small internal-pannel",
            onClick: togglePannel
          })
        ]),
        _createVNode(AccordionCard, { "start-open": true }, {
          title: _withCtx(() => [
            _createVNode(AccordionCardHeader, {
              "img-name": 'clipboard.png',
              title: "HIGH LEVEL",
              subtitle: "First general assessment on the technology",
              status: "ok"
            })
          ]),
          content: _withCtx(() => [
            (!highLevelEditable.value)
              ? (_openBlock(), _createBlock(AssetDetailAssessmentHighLevel, {
                  key: 0,
                  "asset-assessment-high-level-data": __props.assetDetailAssessmentData.value.highLevel
                }, null, 8, ["asset-assessment-high-level-data"]))
              : (_openBlock(), _createBlock(AssetDetailAssessmentHighLevelEditable, {
                  key: 1,
                  "asset-assessment-high-level-data": __props.assetDetailAssessmentData.value.highLevel
                }, null, 8, ["asset-assessment-high-level-data"]))
          ]),
          _: 1
        }),
        _createVNode(AccordionCard, { "start-open": true }, _createSlots({
          title: _withCtx(() => [
            _createVNode(AccordionCardHeader, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (isInDepthOopen.value = !isInDepthOopen.value)),
              "img-name": 'survey.png',
              title: "IN DEPTH",
              subtitle: "Deep technical assessment on the technology",
              status: "ok"
            })
          ]),
          _: 2
        }, [
          (isInDepthOopen.value)
            ? {
                name: "content",
                fn: _withCtx(() => [
                  _createVNode(AssetDetailAssessmentInDepth)
                ]),
                key: "0"
              }
            : undefined
        ]), 1024),
        _createVNode(AccordionCard, null, {
          title: _withCtx(() => [
            _createVNode(AccordionCardHeader, {
              "img-name": 'documentation.png',
              title: "RELATED PROGRAMMES",
              subtitle: "Programmes associated with this technology ",
              status: "ok"
            })
          ]),
          content: _withCtx(() => [
            _createVNode(AssetDetailAssessmentProgramsLinks, {
              "linked-programmes": __props.assetDetailAssessmentData.value.programs.programList
            }, null, 8, ["linked-programmes"])
          ]),
          _: 1
        }),
        _createVNode(AccordionCard, null, {
          title: _withCtx(() => [
            _createVNode(AccordionCardHeader, {
              "img-name": 'plant.png',
              title: "PRODUCT READINESS",
              subtitle: "Assessment on whether this belong to research project",
              status: "ko"
            })
          ]),
          content: _withCtx(() => _cache[2] || (_cache[2] = [
            _createElementVNode("p", null, "Work in progress", -1)
          ])),
          _: 1
        }),
        _createVNode(AccordionCard, null, {
          title: _withCtx(() => [
            _createVNode(AccordionCardHeader, {
              "img-name": 'medical-laboratory.png',
              title: "TESTING",
              subtitle: "Experimental data from different kinds of test",
              status: "ko"
            })
          ]),
          content: _withCtx(() => _cache[3] || (_cache[3] = [
            _createElementVNode("p", null, "Work in progress", -1)
          ])),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}
}

})