<template>
    <button @click="hanldleClickLogin"  class="google-btn">
        <img src="@/assets/images/google.png" alt="google icon">
        Sign in with Google 
    </button>
</template>
<script setup lang="ts">
import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import { googleTokenLogin } from "vue3-google-login";
import { useStore } from "vuex";

const store = useStore();
const error = ref(null);
const loggedUser = computed(() => { return store.state.authModule.user });
const router = useRouter();

const hanldleClickLogin =  async () => {
    try {
        const nextPath = router.currentRoute.value.redirectedFrom ? router.currentRoute.value.redirectedFrom.fullPath : '/technology-assets-repo';
        const googleResponse = await googleTokenLogin();
        await store.dispatch('authModule/login', googleResponse.access_token)
        loggedUser.value && loggedUser.value.isStaff ? router.push(nextPath) : null;  
    }
    catch(err) {
        error.value = err.message
        console.log(error.value)
    }
}
</script>
