import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "title-menu-header" }
const _hoisted_2 = { class: "view-title" }
const _hoisted_3 = { class: "action-icons flex" }
const _hoisted_4 = { class: "relative" }
const _hoisted_5 = {
  key: 0,
  class: "absolute top-12 left-1 one-five-blue rounded-md"
}

import { ArrowUturnLeftIcon, PencilIcon } from '@heroicons/vue/24/outline';
import ProgramVersionDesignForm from './ProgramVersionDesignForm.vue';
import { ref, computed } from 'vue';
import ProgramVersionsForm from './ProgramVersionsForm.vue';
import { useStore } from 'vuex';
import BenchmarkIcon from '@/components/icons/BenchmarkIcon.vue';
import LayerIcon from '@/components/icons/LayerIcon.vue';
import ProgramVersionBenchmarkForm from './ProgramVersionBenchmarkForm.vue';
import ProgramVersionBenchmarkList from './ProgramVersionBenchmarkList.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramVersionDetailHeader',
  props: ['programVersion'],
  setup(__props) {



const store = useStore();
const isDesignFormOpen = ref(false);
const isEditFormOpen = ref(false);
const isBenchmarkFormOpen = ref(false);
const isBenchmarkListOpen = ref(false);
const showBenchmarkOptions = ref(false);

const collapsed = computed(() => store.getters['authModule/isSidebarCollapsed']);
const toggleSidebar = () => store.commit('authModule/toogleSidebar');

const toggleForm = (formType: string) => {
    if( formType == "design") {
        isDesignFormOpen.value = !isDesignFormOpen.value
    }
    if( formType == "edit") {
        isEditFormOpen.value = !isEditFormOpen.value
    }
    if( formType == "benchmark") {
        showBenchmarkOptions.value = !showBenchmarkOptions.value
    }
    if (!collapsed.value) {
        toggleSidebar();
    }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(__props.programVersion.name), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "round-action-icon",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleForm('edit')))
        }, [
          _createVNode(_unref(PencilIcon), { class: "icon icon-small" })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: "round-action-icon p-2",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (toggleForm('benchmark')))
          }, [
            _createVNode(BenchmarkIcon, { class: "icon icon-small fill-white" })
          ]),
          (showBenchmarkOptions.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("p", {
                  class: "text-xs font-light text-white border-b border-white py-1 px-4 cursor-pointer",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => {isBenchmarkFormOpen.value = !isBenchmarkFormOpen.value; showBenchmarkOptions.value = false})
                }, "New"),
                _createElementVNode("p", {
                  class: "text-xs font-light text-white py-1 px-4 cursor-pointer",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => {isBenchmarkListOpen.value = !isBenchmarkListOpen.value; showBenchmarkOptions.value = false})
                }, "Open")
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: "round-action-icon p-2 opacity-30 cursor-not-allowed",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (toggleForm('design')))
        }, [
          _createVNode(LayerIcon)
        ]),
        _createElementVNode("div", {
          class: "round-action-icon",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$router.go(-1)))
        }, [
          _createVNode(_unref(ArrowUturnLeftIcon), { class: "icon icon-small" })
        ])
      ])
    ]),
    _createVNode(ProgramVersionDesignForm, {
      "is-open": isDesignFormOpen.value,
      onCloseModal: _cache[6] || (_cache[6] = ($event: any) => (isDesignFormOpen.value = false))
    }, null, 8, ["is-open"]),
    _createVNode(ProgramVersionsForm, {
      "is-open": isEditFormOpen.value,
      "on-edit": true,
      onCloseEdit: _cache[7] || (_cache[7] = ($event: any) => (isEditFormOpen.value = false))
    }, null, 8, ["is-open"]),
    _createVNode(ProgramVersionBenchmarkForm, {
      "is-open": isBenchmarkFormOpen.value,
      onCloseModal: _cache[8] || (_cache[8] = ($event: any) => (isBenchmarkFormOpen.value = false)),
      "is-on-edit": false,
      "program-name": __props.programVersion.program.name
    }, null, 8, ["is-open", "program-name"]),
    _createVNode(ProgramVersionBenchmarkList, {
      "is-open": isBenchmarkListOpen.value,
      onCloseModal: _cache[9] || (_cache[9] = ($event: any) => (isBenchmarkListOpen.value = false))
    }, null, 8, ["is-open"])
  ], 64))
}
}

})