<template>
  <div>
    <form @submit.prevent="signUp">
      <p class="text-red-500 text-center">{{ errorMessage }}</p>
      <div class="grid grid-cols-2 gap-y-3 gap-x-4 mb-2">
        <div class="col-span-1 sm:col-span-2 md:col-span-1">
          <label class="block text-gray-700 text-sm" for="firstName"> First Name </label>
          <input
            v-model="firstName"
            :class="{
              'border rounded w-full py-1 px-3 focus:outline-none focus:shadow-outline': true,
              'border-red-500': !isfirstNameValid && firstName,
            }"
            id="firstName"
            type="text"
            required
          />
          <div v-if="!isfirstNameValid && firstName" class="text-red-500">Enter a valid first name.</div>
        </div>
        <div class="col-span-1 sm:col-span-2 md:col-span-1">
          <label class="block text-gray-700 text-sm" for="lastName"> Last Name </label>
          <input
            v-model="lastName"
            :class="{
              'border rounded w-full py-1 px-3 focus:outline-none focus:shadow-outline': true,
              'border-red-500': !isLastNameValid && lastName,
            }"
            id="lastName"
            type="text"
            required
          />
          <div v-if="!isLastNameValid && lastName" class="text-red-500">Enter a valid last name.</div>
        </div>
      </div>
      <div class="mb-2">
        <label class="block text-gray-700 text-sm" for="organizationName"> Organization Name </label>
        <input
          v-model="organizationName"
          :class="{
            'border rounded w-full py-1 px-3 focus:outline-none focus:shadow-outline': true,
            'border-red-500': !isOrganizationNameValid && organizationName,
          }"
          id="organizationName"
          type="text"
          required
        />
        <div v-if="!isOrganizationNameValid && organizationName" class="text-red-500">Enter a valid organization name.</div>
      </div>
      <div class="mb-2">
        <label class="block text-gray-700 text-sm" for="email"> Email </label>
        <input
          v-model="email"
          :class="{
            'border rounded w-full py-1 px-3 focus:outline-none focus:shadow-outline': true,
            'border-red-500': !isEmailValid && email,
          }"
          id="email"
          type="email"
          required
        />
        <div v-if="!isEmailValid && email" class="text-red-500">Enter a valid email address.</div>
      </div>
      <div class="mb-2">
        <label class="block text-gray-700 text-sm" for="password"> Password </label>
        <input
          v-model="password"
          :class="{
            'border rounded w-full py-1 px-3 focus:outline-none focus:shadow-outline': true,
            'border-red-500': passwordTooShort || (!isPasswordComplex && password) || passwordTooLong,
          }"
          id="password"
          type="password"
          required
        />
        <div v-if="passwordTooShort" class="text-red-500">Password should be at least 6 characters long.</div>
        <div v-if="passwordTooLong" class="text-red-500">Password should not exceed 12 characters.</div>
        <div v-if="!isPasswordComplex && password && !passwordTooShort && !passwordTooLong" class="text-red-500">
          Password should include at least one uppercase letter, one lowercase letter, one digit, and one of the special characters: !@#$&*
        </div>
      </div>
      <div class="mb-2">
        <label class="block text-gray-700 text-sm" for="confirmPassword"> Confirm Password </label>
        <input
          v-model="confirmPassword"
          :class="{
            'border rounded w-full py-1 px-3 focus:outline-none focus:shadow-outline': true,
            'border-red-500': passwordMismatch,
          }"
          id="confirmPassword"
          type="password"
          required
        />
        <div v-if="passwordMismatch" class="text-red-500">Passwords do not match</div>
      </div>
      <div class="mb-3">
        <div>
          <small>I'm not a robot</small>
          <Checkbox v-model="checkboxResponse" />
        </div>
      </div>
      <div class="mb-3">
        <label class="block text-sm font-bold flex items-center" for="terms">
          <input type="checkbox" v-model="acceptTerms" id="terms" class="mr-1" />
          <span class="align-middle ml-1 hover:cursor-pointer"
            >I accept the
            <a target="_blank" href="https://www.one-five.com/privacy-policy" class="text-blue-500 hover:underline cursor-pointer">Privacy Policy</a>,
            <a target="_blank" href="https://www.one-five.com/cookies-policy" class="text-blue-500 hover:underline cursor-pointer">Cookies Policy</a>,
            <a target="_blank" href="https://www.one-five.com/terms-of-use" class="text-blue-500 hover:underline cursor-pointer">Terms of Use</a>
            <!-- <a
              href="#"
              @click="showTerms = !showTerms"
              class="text-blue-500 hover:underline cursor-pointer"
              >Terms, Conditions and Traceability</a -->
          </span>
        </label>
        <div v-if="!acceptTerms" class="text-red-500 ml-2">
          <small>You must accept the Terms, Conditions and Traceability to proceed.</small>
        </div>
      </div>
      <div class="flex justify-stretch">
        <button
          type="submit"
          :class="{
            'w-full bg-emerald-400 text-white font-bold py-2 px-4 rounded': true,
            'hover:bg-emerald-600  focus:outline-none focus:shadow-outline': isFormValid,
            'cursor-not-allowed opacity-50 ': !isFormValid || buttonDisabled,
          }"
          :disabled="!isFormValid || buttonDisabled"
        >
          {{ buttonText }}
        </button>
      </div>
    </form>
    <hr class="my-2" />
    <div class="text-right">
      Already registered? &nbsp;
      <button @click.prevent="$emit('showSignIn')" class="text-emerald-400 hover:underline cursor-pointer">Sign In Here</button>
    </div>
    <!-- <TermsModal v-if="showTerms" @closeModal="showTerms = !showTerms" /> -->
  </div>
</template>
<script setup lang="ts">
// import TermsModal from "../TermsModal.vue";
import { computed, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
const { emit } = getCurrentInstance();

import { Checkbox } from "vue-recaptcha";

const checkboxResponse = ref();
const checkboxVerified = computed(() => !!checkboxResponse.value);

const store = useStore();

const buttonText = ref("Sign Up");
const buttonDisabled = ref(false);

const firstName = ref("");
const lastName = ref("");
const organizationName = ref("");
const email = ref("");
const password = ref("");
const confirmPassword = ref("");
const acceptTerms = ref(false);
const errorMessage = ref("");

const passwordMismatch = computed(() => password.value !== confirmPassword.value && password.value && confirmPassword.value);

const passwordTooShort = computed(() => password.value.length < 6 && password.value);
const passwordTooLong = computed(() => password.value.length > 12 && password.value);

const hasUppercase = computed(() => /[A-Z]/.test(password.value));
const hasLowercase = computed(() => /[a-z]/.test(password.value));
const hasDigit = computed(() => /\d/.test(password.value));
const hasSpecialChar = computed(() => /[!@#$&*]/.test(password.value));

const isPasswordComplex = computed(() => {
  return hasUppercase.value && hasLowercase.value && hasDigit.value && hasSpecialChar.value;
});
const isEmailValid = computed(() => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value));

const isfirstNameValid = computed(() => firstName.value);
const isLastNameValid = computed(() => lastName.value);
const isOrganizationNameValid = computed(() => organizationName.value);

const isFormValid = computed(() => {
  return (
    firstName.value &&
    organizationName.value &&
    email.value &&
    password.value &&
    confirmPassword.value &&
    checkboxVerified.value &&
    !passwordMismatch.value &&
    !passwordTooShort.value &&
    !passwordTooLong.value &&
    isPasswordComplex.value &&
    isEmailValid.value &&
    acceptTerms.value
  );
});

const signUp = async () => {
  try {
    buttonText.value = "Signing Up . . .";
    buttonDisabled.value = true;
    await store
      .dispatch("authModule/signUp", {
        email: email.value,
        password: password.value,
        firstName: firstName.value,
        lastName: lastName.value,
        organizationName: organizationName.value,
      })
      .then(() => {
        emit("showEmail", email.value);
        buttonText.value = "Sign Up";
        buttonDisabled.value = false;
      })
      .catch((error) => {
        errorMessage.value = error;
        buttonText.value = "Sign Up";
        buttonDisabled.value = false;
      });
  } catch (error) {
    errorMessage.value = "An error occurred while registering.";
    buttonText.value = "Sign Up";
    buttonDisabled.value = false;
  }
};

// const showTerms = ref(false);
</script>

<style scoped>
/* Add your custom styling here */
</style>
