<template>
    <div class="page-padding">
        <TechnologyAssetsRepoHeader :title="sectionTitle" @filter-refetch="refetchAssets()" @quick-search-refetch="handleQuickSearch" />
        <TechnologyAssetsRepoTabs  />
        <div v-show="assets.length">
            <TechnologyAssetsTable 
                :assets="assets" 
                :is-loading="loading" 
                :total-records="totalCount"
                :has-next-page="hasNextPage" 
                :server-params="{perPage: perPage, page: page}" 
                :showing-records="edgeCount"
                @on-page-change="onPageChange" 
                @on-per-page-change="onPerPageChange" />
        </div>

        <NotFound v-if="!loading && assets.length == 0" imageName="search-transpa.gif" text="No result found"
            subtitle="We can't find any asset matching your search" cta-1="edit filters" cta-2="restore search"
            @cta-message="onNotFoundDesition" />
        <div v-show="loading && !assets.length" class="loading-gif"></div>
    </div>
</template>

<script setup lang="ts">
import TechnologyAssetsRepoHeader from '@/components/TechnologyAssetsRepoHeader.vue';
import TechnologyAssetsTable from "@/components/TechnologyAssetsTable.vue";
import TechnologyAssetsRepoTabs from "@/components/TechnologyAssetsRepoTabs.vue";
import { useAssetDataToTableRow } from '@/composables/useAssetDataToTableRow';
import { useQuery } from '@vue/apollo-composable';
import { computed, ref, watchEffect } from 'vue';
import { OTHER_ASSETS, PATENT_ASSETS, RESEARCH_PAPERS_ASSETS, RESEARCH_PROJECTS_ASSETS } from '@/grapql/assetsQuery';
import { useStore } from 'vuex';
import NotFound from '@/components/NotFound.vue';
import { GoodTablePageChangeInterface, GoodTablePerPageParamsInterface, PageInfoInterface, TechnologyAssetAsTableRowInterface, TechnologyAssetInterface } from '@/models/technologyAssetsInterface';

const sectionTitle = 'Technology Assets Repository';

const store = useStore();

const selectedAssetType = computed(() => store.getters['assetsModule/getSelectedTab'])
const quickSearchFilter = computed(() => store.state.assetsModule.quickSearchFilter);
const filterCriteria = computed(() => store.state.assetsModule.filter);
const sortByCriteria = computed(() => store.getters['assetsModule/getSortCriteria']);
const perPage = computed(() => store.getters['assetsModule/getTablePerPage']);
const page = computed(() => store.getters['assetsModule/getTablePage']);
// const perPage = ref(perPageFromStore.value);
// const page = ref(pageFromStore.value);


const queryToRun = ref();
const resultQueryName = ref();

const { result, refetch, fetchMore, loading } = useQuery(queryToRun, { first: perPage.value, after: " ", orderBy: sortByCriteria.value, filter: filterCriteria.value });  

watchEffect(() => {
    if (selectedAssetType.value && selectedAssetType.value.key == 'OTHER') {
        queryToRun.value = OTHER_ASSETS;
        resultQueryName.value = selectedAssetType.value.allQueryName;
    }
    if (selectedAssetType.value && selectedAssetType.value.key == 'PATENT') {
        queryToRun.value = PATENT_ASSETS;
        resultQueryName.value = selectedAssetType.value.allQueryName;
    }
    if (selectedAssetType.value && selectedAssetType.value.key == 'RESEARCH_PAPER') {
        queryToRun.value = RESEARCH_PAPERS_ASSETS;
        resultQueryName.value = selectedAssetType.value.allQueryName;
    }
    if (selectedAssetType.value && selectedAssetType.value.key == 'RESEARCH_PROJECT') {
        queryToRun.value = RESEARCH_PROJECTS_ASSETS;
        resultQueryName.value = selectedAssetType.value.allQueryName;
    }
});


const assets = computed<TechnologyAssetAsTableRowInterface[]>(()=> {
    let newListOfAssets = [];
    if (result.value && result.value[resultQueryName.value]) {
        const assetsToParse = result.value[resultQueryName.value].edges.map(a => a.node);
        assetsToParse.map((asset: TechnologyAssetInterface) => newListOfAssets.push(useAssetDataToTableRow(asset)))
    }
    return newListOfAssets
});

const totalCount = computed(() => result.value && result?.value[resultQueryName.value] ? result?.value[resultQueryName.value].totalCount : 0);
const hasNextPage = computed(() => result.value && result?.value[resultQueryName.value] ? result?.value[resultQueryName.value].pageInfo.hasNextPage : false)
const edgeCount = computed(() => result.value && result?.value[resultQueryName.value] ? result?.value[resultQueryName.value].edgeCount : 0);
const pageInfo = computed<PageInfoInterface>(() => result.value && result?.value[resultQueryName.value] ? result.value[resultQueryName.value].pageInfo : null);

const onPerPageChange = (params: GoodTablePerPageParamsInterface) => {
    store.commit('assetsModule/setTablePerPage', {perPage: params.currentPerPage});
    refetchAssets();
}

const onPageChange = (params: GoodTablePageChangeInterface) => {
    if (params.currentPage > params.prevPage) {
        store.commit('assetsModule/setTablePage', {page: page.value + 1});
        const variables = {
            first: perPage.value,
            after: pageInfo.value.endCursor,
            orderBy: sortByCriteria.value,
            before: null,
            filter: filterCriteria.value,
        }
        loadMore(variables)
    }
    if (params.currentPage < params.prevPage) {
        store.commit('assetsModule/setTablePage', {page: page.value - 1});
        const variables = {
            last: perPage.value,
            before: pageInfo.value.startCursor,
            orderBy: sortByCriteria.value,
            first: null,
            filter: filterCriteria.value,
        }
        loadMore(variables)
    }
}

const loadMore = (variables) => {
    fetchMore({
        variables,
        updateQuery: (previousResult, { fetchMoreResult }) => {
            const newEdges = fetchMoreResult[resultQueryName.value].edges;
            const pageInfo = fetchMoreResult[resultQueryName.value].pageInfo;
            return newEdges.length ? {
                ...previousResult,
                [resultQueryName.value]: {
                    ...previousResult[resultQueryName.value],
                    edges: [
                        ...newEdges,
                    ],
                    pageInfo,
                }
            } : previousResult
        },
    })
}

const refetchAssets = () => {
    refetch({ first: perPage.value, after: " ", orderBy: sortByCriteria.value, filter: filterCriteria.value });
    store.commit('assetsModule/setTablePage', {page: 1});
}

const handleQuickSearch = () => {
    refetch({ first: perPage.value, after: " ", orderBy: sortByCriteria.value, filter: quickSearchFilter.value });
    store.commit('assetsModule/setTablePage', {page: 1});
}

const onNotFoundDesition = (desition: string) => {
    if (desition == 'cta2') {
        store.commit('assetsModule/restoreAllValues');
        refetchAssets();
    }
    if (desition == 'cta1') {
        store.commit('assetsModule/openModal')
    }

};
</script>
