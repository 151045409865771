<template>
  <div>
    <form @submit.prevent="signIn">
      <p class="text-red-500 text-center">{{ errorMessage }}</p>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm" for="email"> Email </label>
        <input v-model="email" class="border rounded w-full py-1 px-3 focus:outline-none focus:shadow-outline" id="email" type="email" required />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm" for="password"> Password </label>
        <input v-model="password" class="border rounded w-full py-1 px-3 focus:outline-none focus:shadow-outline" id="password" type="password" required />
      </div>

      <div class="mb-4">
        <div>
          <small>I'm not a robot</small>
          <Checkbox v-model="checkboxResponse" />
        </div>
      </div>
      <div class="flex justify-stretch">
        <button
          type="submit"
          :class="{
            'w-full bg-emerald-400 text-white font-bold py-2 px-4 rounded': true,
            'hover:bg-emerald-600  focus:outline-none focus:shadow-outline': isFormValid,
            'cursor-not-allowed opacity-50 ': !isFormValid || buttonDisabled,
          }"
          :disabled="!isFormValid || buttonDisabled"
        >
          {{ buttonText }}
        </button>
      </div>
    </form>
    <hr class="my-4" />
    <div class="mb-4 text-sm flex justify-between">
      <button tabindex="-1" @click="$emit('showForgotPassword')" class="text-blue-500 hover:underline cursor-pointer">Forgot Password?</button>
      <div class="text-right">
        Don't have an account?&nbsp;
        <button @click="$emit('showSignUp')" class="text-emerald-400 hover:underline cursor-pointer">Sign Up Here</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
const { emit } = getCurrentInstance();

import { Checkbox } from "vue-recaptcha";

const checkboxResponse = ref();
const checkboxVerified = computed(() => !!checkboxResponse.value);

const store = useStore();
const loggedUser = computed(() => {
  return store.state.authModule.user;
});
const router = useRouter();

const email = ref("");
const password = ref("");
const errorMessage = ref("");

const buttonText = ref("Sign In");
const buttonDisabled = ref(false);

const isFormValid = computed(() => {
  return email.value && password.value && checkboxVerified.value;
});

const signIn = async () => {
  try {
    buttonText.value = "Signing In . . .";
    buttonDisabled.value = true;
    const nextPath = router.currentRoute.value.redirectedFrom ? router.currentRoute.value.redirectedFrom.fullPath : "/dashboard";
    await store
      .dispatch("authModule/signIn", {
        email: email.value,
        password: password.value,
      })
      .then(() => {
        if (loggedUser.value) {
          emit("closeModal");
          router.push(nextPath);
          buttonText.value = "Sign In";
          buttonDisabled.value = false;
        }
      })
      .catch((error) => {
        errorMessage.value = error;
        buttonText.value = "Sign In";
        buttonDisabled.value = false;
      });
  } catch (error) {
    errorMessage.value = "An error occurred while signing in.";
    buttonText.value = "Sign In";
    buttonDisabled.value = false;
  }
};
</script>

<style scoped>
/* Add your custom styling here */
</style>
