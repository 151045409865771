<template>
    <div class="mb-4">
        <Chart :options="chartOptions" />
    </div>
</template>
<script setup>
import {defineProps, computed} from "vue";
import { Chart } from 'highcharts-vue'
import Highcharts from 'highcharts';
import accessibilityInit from 'highcharts/modules/accessibility'; // Import the accessibility module
import exportingInit from 'highcharts/modules/exporting';

// Initialize the accessibility module
accessibilityInit(Highcharts);
// Initialize the exporting module
exportingInit(Highcharts);


const props = defineProps(['data'])
const chartData = computed(()=>{
  return props.data;
})
const chartOptions = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'CapEx and MEC',
    },
    xAxis: {
      categories: props.data.categories,
      crosshair: true,
      title:{
        text:"run (scenario)"
      }
    },
    yAxis: {
      title: {
        text: '€',
      },
    },
    series: props.data.series,
    exporting: {
      enabled: true,
    },
    plotOptions:{
        column:{
            pointPadding:0,
        }
    }
  };
</script>