import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, createVNode as _createVNode, vShow as _vShow, withKeys as _withKeys, vModelCheckbox as _vModelCheckbox } from "vue"
import _imports_0 from '@/assets/icons/position.png'


const _hoisted_1 = { class: "form-container" }
const _hoisted_2 = {
  key: 0,
  class: "add-asset-form"
}
const _hoisted_3 = { class: "grid grid-cols-3 gap-y-2.5 gap-x-4" }
const _hoisted_4 = ["value"]
const _hoisted_5 = ["value"]
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "col-span-1 text-xs flex items-center mt-4 text-neutral-900 font-normal" }
const _hoisted_8 = { class: "col-span-3 grid" }
const _hoisted_9 = { class: "col-span-3" }
const _hoisted_10 = { class: "col-span-2" }
const _hoisted_11 = ["value"]
const _hoisted_12 = {
  key: 0,
  class: "selected-results"
}
const _hoisted_13 = {
  key: 0,
  class: "flex justify-between items-center"
}
const _hoisted_14 = ["id", "onClick"]
const _hoisted_15 = ["value", "onInput"]
const _hoisted_16 = {
  key: 0,
  class: "selected-results"
}
const _hoisted_17 = { class: "col-span-2" }
const _hoisted_18 = { class: "mt-3" }
const _hoisted_19 = { class: "flex mt-3" }
const _hoisted_20 = ["value"]
const _hoisted_21 = {
  key: 0,
  class: "selected-results"
}
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { class: "col-span-3 row-span-2 grid" }
const _hoisted_24 = { class: "col-span-3 mt-2" }
const _hoisted_25 = { class: "form-error-toast" }

import { ASSET_ADD_FORM_VALUES, PARTIES_QUERY } from '@/grapql/assetAddFormValues';
import { useQuery } from '@vue/apollo-composable';
import { computed, ref, watch, watchEffect } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { useMultipleSelect, useMultipleSelectForSources } from '@/composables/useMultipleSelect';
import Datepicker from 'vue3-datepicker';
import { NEW_ASSET_FIELDS } from '@/models/newAssetFormFields';
import { useFormFieldsCreationNewAsset } from '@/composables/useFormFieldsCreationNewAsset';
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import FormErrorToast from '@/components/FormErrorToast.vue';
import DropdownSearchableMultiple from '@/components/DropdownSearchableMultiple.vue';
import SearchInputWithResultSelection from './SearchInputWithResultSelection.vue'
import FilesUploadFormField from './FilesUploadFormField.vue';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import PartyAddNew from '@/components/PartyAddNew.vue';
import {OTHER_LAST_NUMBERS_BENCHLING} from '@/grapql/hasuraQueries';
import {useHasuraAndQToProposeNicknames} from '@/composables/useHasuraAndQToProposeNicknames'
import {useQToGetLasNumbersForAssets} from '@/composables/useQToGetLasNumbersForAssets';
import {useStore} from 'vuex';
const tempLinks = '';
// temporarily removed
// const tempNewTag = ref('');
// const showTagInput = ref(false);

export default /*@__PURE__*/_defineComponent({
  __name: 'TechnologyAssetsAddNewOtherTechnology',
  props: ['errorsFields', 'formIsValid', 'formSubmited'],
  setup(__props) {

const props = __props;
const showSourceIDInput = ref([]);

// Inizialize the form
const { result } = useQuery(ASSET_ADD_FORM_VALUES);

const inizilizedData = computed(() => result?.value ?? null)
const formInizializationValues = computed<{ [key: string]: DropdownSearchableOptionInterface[] }>(() => useFormInizializationNewAsset(inizilizedData.value, NEW_ASSET_FIELDS.OTHER.fieldsToQuery));


// Create Fields for the form
const handleInputChange = (event: Event) => (event.target as HTMLInputElement).value;
const formFieldOptions = {
    fields: NEW_ASSET_FIELDS.OTHER.formFileds,
    base: NEW_ASSET_FIELDS.OTHER.graphqlValue,
    mutation: 'assetCreationModule/setSingleAssetValue'
};
const formFields = useFormFieldsCreationNewAsset(formFieldOptions);
const onEditorReady = (event: Event) => { return event };


// Parties 
const enableQuery = ref(false)
const queryOption = ref({ filter: {}});
const searchCriteria = ref()
// Filtered parties
const { result:parties, refetch:refetchParties } = useQuery(PARTIES_QUERY, queryOption, () => ({ enabled: enableQuery.value,}));
const partiesQueryResults = computed(() => parties?.value ?? null);
const foundParties = computed<DropdownSearchableOptionInterface[]>(() => partiesQueryResults?.value && partiesQueryResults.value?.parties ? partiesQueryResults.value?.parties?.edges.map((edge: { node }) => edge.node) : null);
const foundPartiesCount = computed<number | null>(() => partiesQueryResults?.value && partiesQueryResults.value?.parties ? partiesQueryResults.value.parties.totalCount : -1);

const partiesForDropdown = computed(() => foundParties.value ? foundParties.value.map(({id, nickname}) => ({id, name: nickname})) : []);
const otherAssetCreated = computed(() => props.formSubmited && props.errorsFields.length == 0);

const onPartySearchValueChange = (searchValue: string) => {
        queryOption.value.filter = {nickname: {icontains: searchValue}};
        enableQuery.value = true;
        searchCriteria.value = searchValue;
        refetchParties();
}


// ****** NICKNAME RECOMMENDER ******
// Get last values from datawarehouse to propose nicknames
const options = ref({clientId: 'hasuraClient'});
const { result:lastNumbersBenchling } = useQuery(OTHER_LAST_NUMBERS_BENCHLING, null, options);
const lastNumbersForAssetFromBenchling = computed(() => lastNumbersBenchling.value && lastNumbersBenchling.value.west1prod_dim_ndn_incrementals ? lastNumbersBenchling.value.west1prod_dim_ndn_incrementals[0].last_numbers : null)

// Get las values from Q to propose nicknames based on layer type selection
const selectedLayerName = computed(() => formFields['targetLayers'].value && formInizializationValues?.value['layerTypesOptions'] ? formInizializationValues.value['layerTypesOptions'].filter((layer: {id: number, name:string}) => layer.id == formFields['targetLayers'].value)[0].name : null);

const selectedMaterials = computed(() => formFields['materials'].value.length > 0 ? formInizializationValues.value['materialsOptions'].filter(material => formFields['materials'].value.includes(material.id)) : []);

const selectedMaterialsNames = computed(() => selectedMaterials.value.length > 0 ? selectedMaterials.value.map(material => material.name) : []);

const lastNumbersForAssetFromQ = computed(() => useQToGetLasNumbersForAssets(selectedLayerName.value))

// Change proposed nickanme based on material selection when substrate is selected
watch(formFields['materials'], () => {
    handleNicknameProposal()
})  

const helpTextForNickname = ref('');
const nicknameDisable = ref(true);

const handleNicknameProposal = () => {
    const {nickname, helpText, disableInput} = useHasuraAndQToProposeNicknames(lastNumbersForAssetFromBenchling.value, lastNumbersForAssetFromQ.value, selectedLayerName.value, selectedMaterialsNames.value)
    helpTextForNickname.value = helpText.value;
    nicknameDisable.value = disableInput.value;
    formFields['nickname'].value = nickname.value;
}

watchEffect(() => {
    handleNicknameProposal()
})

 

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (formInizializationValues.value)
      ? (_openBlock(), _createElementBlock("form", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[50] || (_cache[50] = _createElementVNode("div", { class: "form-separator col-span-3" }, [
              _createElementVNode("p", { class: "form-subtitle" }, "Commercial & Other technology Info")
            ], -1)),
            _createElementVNode("div", null, [
              _cache[26] || (_cache[26] = _createElementVNode("label", null, "Layer functionality *", -1)),
              _withDirectives(_createElementVNode("select", {
                class: "w-full",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(formFields)['targetLayers'].value) = $event)),
                onChange: _cache[1] || (_cache[1] = ($event: any) => (handleNicknameProposal()))
              }, [
                _cache[25] || (_cache[25] = _createElementVNode("option", {
                  selected: "",
                  value: "null"
                }, null, -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formInizializationValues.value['layerTypesOptions'], (layer) => {
                  return (_openBlock(), _createElementBlock("option", {
                    value: layer.id,
                    key: layer.id
                  }, _toDisplayString(layer.name), 9, _hoisted_4))
                }), 128))
              ], 544), [
                [_vModelSelect, _unref(formFields)['targetLayers'].value]
              ])
            ]),
            _createElementVNode("div", null, [
              _cache[27] || (_cache[27] = _createElementVNode("label", null, [
                _createTextVNode("Materials "),
                _createElementVNode("span", null, "(One or more)")
              ], -1)),
              (formInizializationValues.value['materialsOptions'])
                ? (_openBlock(), _createBlock(DropdownSearchableMultiple, {
                    key: 0,
                    "options-list": formInizializationValues.value['materialsOptions'],
                    modelValue: _unref(formFields)['materials'].value,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(formFields)['materials'].value) = $event))
                  }, null, 8, ["options-list", "modelValue"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", {
              class: _normalizeClass({ 'error-form-field': __props.errorsFields.includes('otherTechnologyType') && __props.formSubmited })
            }, [
              _cache[29] || (_cache[29] = _createElementVNode("label", null, "Type *", -1)),
              _withDirectives(_createElementVNode("select", {
                class: "w-full",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(formFields)['otherTechnologyType'].value) = $event))
              }, [
                _cache[28] || (_cache[28] = _createElementVNode("option", {
                  selected: "",
                  value: "null"
                }, null, -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formInizializationValues.value['otherTechnologyTypesOptions'], (technologyType) => {
                  return (_openBlock(), _createElementBlock("option", {
                    value: Number(technologyType.id),
                    key: technologyType.id
                  }, _toDisplayString(technologyType.name), 9, _hoisted_5))
                }), 128))
              ], 512), [
                [_vModelSelect, _unref(formFields)['otherTechnologyType'].value]
              ])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["col-span-2", { 'error-form-field': __props.errorsFields.includes('nickname') && __props.formSubmited }])
            }, [
              _cache[30] || (_cache[30] = _createElementVNode("label", null, "Nickname *", -1)),
              _withDirectives(_createElementVNode("input", {
                disabled: nicknameDisable.value,
                class: "w-full",
                type: "text",
                required: "",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(formFields)['nickname'].value) = $event))
              }, null, 8, _hoisted_6), [
                [_vModelText, _unref(formFields)['nickname'].value]
              ])
            ], 2),
            _createElementVNode("div", _hoisted_7, [
              _cache[31] || (_cache[31] = _createElementVNode("img", {
                src: _imports_0,
                class: "mr-2"
              }, null, -1)),
              _createElementVNode("p", null, _toDisplayString(helpTextForNickname.value), 1)
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["col-start-auto col-span-3", { 'error-form-field': __props.errorsFields.includes('title') && __props.formSubmited }])
            }, [
              _cache[32] || (_cache[32] = _createElementVNode("label", null, "Title *", -1)),
              _withDirectives(_createElementVNode("input", {
                class: "w-full",
                type: "text",
                required: "",
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(formFields)['title'].value) = $event))
              }, null, 512), [
                [_vModelText, _unref(formFields)['title'].value]
              ])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["col-span-3 grid", { 'error-form-field': __props.errorsFields.includes('abstract') && __props.formSubmited }])
            }, [
              _cache[33] || (_cache[33] = _createElementVNode("label", null, "Abstract *", -1)),
              _createVNode(_unref(QuillEditor), {
                content: _unref(formFields)['abstract'].value ? _unref(formFields)['abstract'].value : '<p><br></p>',
                "content-type": "html",
                "onUpdate:content": _cache[6] || (_cache[6] = content => _unref(formFields)['abstract'].value = content),
                onReady: _cache[7] || (_cache[7] = ($event: any) => (onEditorReady($event)))
              }, null, 8, ["content"])
            ], 2),
            _createElementVNode("div", _hoisted_8, [
              _cache[34] || (_cache[34] = _createElementVNode("label", null, "Description", -1)),
              _createVNode(_unref(QuillEditor), {
                content: _unref(formFields)['description'].value ? _unref(formFields)['description'].value : '<p><br></p>',
                "content-type": "html",
                "onUpdate:content": _cache[8] || (_cache[8] = content => _unref(formFields)['description'].value = content),
                onReady: _cache[9] || (_cache[9] = ($event: any) => (onEditorReady($event)))
              }, null, 8, ["content"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[35] || (_cache[35] = _createElementVNode("label", null, "Url", -1)),
              _withDirectives(_createElementVNode("input", {
                class: "w-full",
                type: "text",
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_unref(formFields)['url'].value) = $event))
              }, null, 512), [
                [_vModelText, _unref(formFields)['url'].value]
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _cache[38] || (_cache[38] = _createElementVNode("label", null, [
                _createTextVNode("Sources "),
                _createElementVNode("span", null, "(One or more)")
              ], -1)),
              _createElementVNode("select", {
                class: "w-full",
                onInput: _cache[11] || (_cache[11] = ($event: any) => (_unref(formFields)['sources'].value = _unref(useMultipleSelectForSources)(Number(handleInputChange($event)), 'add', _unref(formFields)['sources'].value)))
              }, [
                _cache[36] || (_cache[36] = _createElementVNode("option", {
                  selected: "",
                  disabled: ""
                }, null, -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formInizializationValues.value['sourcesOptions'], (source) => {
                  return (_openBlock(), _createElementBlock("option", {
                    value: source.id,
                    key: source.id
                  }, _toDisplayString(source.name), 9, _hoisted_11))
                }), 128))
              ], 32),
              (_unref(formFields)['sources'].value.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formInizializationValues.value['sourcesOptions'], (sourceOption, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(formFields)['sources'].value, (source, index) => {
                          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                            (source.source == sourceOption.id)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                  _createElementVNode("span", null, [
                                    _createTextVNode(_toDisplayString(sourceOption.name) + " ", 1),
                                    _createVNode(_unref(XMarkIcon), {
                                      class: "icon icon-x-small thick inline",
                                      onClick: ($event: any) => (_unref(formFields)['sources'].value = _unref(useMultipleSelectForSources)(source.source, 'remove', _unref(formFields)['sources'].value))
                                    }, null, 8, ["onClick"])
                                  ]),
                                  _createElementVNode("div", null, [
                                    _withDirectives(_createElementVNode("div", {
                                      id: index.toString(),
                                      class: "label-with-icon",
                                      onClick: ($event: any) => (showSourceIDInput.value.push(index))
                                    }, _cache[37] || (_cache[37] = [
                                      _createElementVNode("span", { class: "icon-span" }, "+ Add ID", -1)
                                    ]), 8, _hoisted_14), [
                                      [_vShow, !showSourceIDInput.value.includes(index)]
                                    ]),
                                    _withDirectives(_createElementVNode("input", {
                                      type: "text",
                                      placeholder: "ID",
                                      value: source.externalSourceId,
                                      onInput: ($event: any) => (source.externalSourceId = handleInputChange($event))
                                    }, null, 40, _hoisted_15), [
                                      [_vShow, showSourceIDInput.value.includes(index)]
                                    ])
                                  ])
                                ]))
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 128))
                      ], 64))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _cache[39] || (_cache[39] = _createElementVNode("label", null, [
                _createTextVNode("Links "),
                _createElementVNode("span", null, "(Press Enter to add a link)")
              ], -1)),
              _withDirectives(_createElementVNode("input", {
                class: "w-full",
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((tempLinks) = $event)),
                type: "text",
                onKeyup: _cache[13] || (_cache[13] = _withKeys(($event: any) => {_unref(formFields)['links'].value.push(handleInputChange($event)); tempLinks = ''}, ["enter"]))
              }, null, 544), [
                [_vModelText, tempLinks]
              ]),
              (_unref(formFields)['links'].value.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(formFields)['links'].value, (link, index) => {
                      return (_openBlock(), _createElementBlock("span", { key: index }, [
                        _createTextVNode(_toDisplayString(link) + " ", 1),
                        _createVNode(_unref(XMarkIcon), {
                          class: "icon icon-x-small thick inline",
                          onClick: ($event: any) => (_unref(formFields)['links'].value.splice(index, 1))
                        }, null, 8, ["onClick"])
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _cache[51] || (_cache[51] = _createElementVNode("div", { class: "form-separator col-span-3" }, [
              _createElementVNode("p", { class: "form-subtitle" }, "Commercial & Other technology Participants")
            ], -1)),
            _createElementVNode("div", _hoisted_17, [
              _createVNode(SearchInputWithResultSelection, {
                modelValue: _unref(formFields)['owners'].value,
                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_unref(formFields)['owners'].value) = $event)),
                "options-list": partiesForDropdown.value,
                "search-label": 'Search for suppliers:',
                "result-count": foundPartiesCount.value,
                "is-multiple": true,
                "new-asset-created": otherAssetCreated.value,
                onSearchValueChange: onPartySearchValueChange
              }, null, 8, ["modelValue", "options-list", "result-count", "new-asset-created"])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createVNode(PartyAddNew, { "party-type": "supplier" })
            ]),
            _createElementVNode("div", _hoisted_19, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_unref(formFields)['previouslyProcured'].value) = $event)),
                "true-value": true,
                "false-value": null
              }, null, 512), [
                [_vModelCheckbox, _unref(formFields)['previouslyProcured'].value]
              ]),
              _cache[40] || (_cache[40] = _createElementVNode("label", null, "Previously procured", -1))
            ]),
            _cache[52] || (_cache[52] = _createElementVNode("div", { class: "form-separator col-span-3" }, [
              _createElementVNode("p", { class: "form-subtitle" }, "Commercial & Other technology Assessment")
            ], -1)),
            _createElementVNode("div", null, [
              _cache[41] || (_cache[41] = _createElementVNode("label", null, [
                _createTextVNode("Properties "),
                _createElementVNode("span", null, "(One or more)")
              ], -1)),
              (formInizializationValues.value['propertiesOptions'])
                ? (_openBlock(), _createBlock(DropdownSearchableMultiple, {
                    key: 0,
                    "options-list": formInizializationValues.value['propertiesOptions'],
                    modelValue: _unref(formFields)['properties'].value,
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_unref(formFields)['properties'].value) = $event))
                  }, null, 8, ["options-list", "modelValue"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _cache[42] || (_cache[42] = _createElementVNode("label", null, [
                _createTextVNode("Applications "),
                _createElementVNode("span", null, "(One or more)")
              ], -1)),
              (formInizializationValues.value['applicationTypesOptions'])
                ? (_openBlock(), _createBlock(DropdownSearchableMultiple, {
                    key: 0,
                    "options-list": formInizializationValues.value['applicationTypesOptions'],
                    modelValue: _unref(formFields)['applicationTypes'].value,
                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_unref(formFields)['applicationTypes'].value) = $event))
                  }, null, 8, ["options-list", "modelValue"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _cache[43] || (_cache[43] = _createElementVNode("label", null, [
                _createTextVNode("Certifications & standards "),
                _createElementVNode("span", null, "(One or more)")
              ], -1)),
              (formInizializationValues.value['standardsOptions'])
                ? (_openBlock(), _createBlock(DropdownSearchableMultiple, {
                    key: 0,
                    "options-list": formInizializationValues.value['standardsOptions'],
                    modelValue: _unref(formFields)['standards'].value,
                    "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_unref(formFields)['standards'].value) = $event))
                  }, null, 8, ["options-list", "modelValue"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _cache[44] || (_cache[44] = _createElementVNode("label", null, [
                _createTextVNode("Processing Techniques "),
                _createElementVNode("span", null, "(One or more)")
              ], -1)),
              (formInizializationValues.value['processingTechniquesOptions'])
                ? (_openBlock(), _createBlock(DropdownSearchableMultiple, {
                    key: 0,
                    "options-list": formInizializationValues.value['processingTechniquesOptions'],
                    modelValue: _unref(formFields)['processingTechniques'].value,
                    "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_unref(formFields)['processingTechniques'].value) = $event))
                  }, null, 8, ["options-list", "modelValue"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _cache[46] || (_cache[46] = _createElementVNode("label", null, [
                _createTextVNode("Form Type "),
                _createElementVNode("span", null, "(One or more)")
              ], -1)),
              _createElementVNode("select", {
                class: "w-full",
                onInput: _cache[20] || (_cache[20] = ($event: any) => (_unref(formFields)['formTypes'].value = _unref(useMultipleSelect)(Number(handleInputChange($event)), 'add', _unref(formFields)['formTypes'].value)))
              }, [
                _cache[45] || (_cache[45] = _createElementVNode("option", {
                  disabled: "",
                  selected: ""
                }, null, -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formInizializationValues.value['assetFormTypesOptions'], (formType) => {
                  return (_openBlock(), _createElementBlock("option", {
                    value: formType.id,
                    key: formType.id
                  }, _toDisplayString(formType.name), 9, _hoisted_20))
                }), 128))
              ], 32),
              (_unref(formFields)['formTypes'].value.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formInizializationValues.value['assetFormTypesOptions'], (formType, index) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                        (_unref(formFields)['formTypes'].value.includes(Number(formType.id)))
                          ? (_openBlock(), _createElementBlock("span", _hoisted_22, [
                              _createTextVNode(_toDisplayString(formType.name) + " ", 1),
                              _createVNode(_unref(XMarkIcon), {
                                class: "icon icon-x-small thick inline",
                                onClick: ($event: any) => (_unref(formFields)['formTypes'].value = _unref(useMultipleSelect)(Number(formType.id), 'remove', _unref(formFields)['formTypes'].value))
                              }, null, 8, ["onClick"])
                            ]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _cache[47] || (_cache[47] = _createElementVNode("label", null, [
                _createTextVNode("TRL "),
                _createElementVNode("span", null, "Number between 1 and 9")
              ], -1)),
              _withDirectives(_createElementVNode("input", {
                class: "w-full",
                type: "number",
                min: "1",
                max: "9",
                "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_unref(formFields)['trl'].value) = $event))
              }, null, 512), [
                [
                  _vModelText,
                  _unref(formFields)['trl'].value,
                  void 0,
                  { number: true }
                ]
              ])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _cache[48] || (_cache[48] = _createElementVNode("label", null, "Notes", -1)),
              _createVNode(_unref(QuillEditor), {
                content: _unref(formFields)['notes'].value ? _unref(formFields)['notes'].value : '<p><br></p>',
                "content-type": "html",
                "onUpdate:content": _cache[22] || (_cache[22] = content => _unref(formFields)['notes'].value = content),
                onReady: _cache[23] || (_cache[23] = ($event: any) => (onEditorReady($event)))
              }, null, 8, ["content"])
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createVNode(FilesUploadFormField, { "asset-type": "otherTechnology" })
            ]),
            _cache[53] || (_cache[53] = _createElementVNode("div", { class: "form-separator col-span-3" }, [
              _createElementVNode("p", { class: "form-subtitle" }, "Commercial & Other technology Dates")
            ], -1)),
            _createElementVNode("div", null, [
              _cache[49] || (_cache[49] = _createElementVNode("label", null, "Publication Date", -1)),
              _createVNode(_unref(Datepicker), {
                class: "w-full",
                clearable: true,
                "input-format": 'dd-MMM-yyyy',
                modelValue: _unref(formFields)['publicationDate'].value,
                "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_unref(formFields)['publicationDate'].value) = $event)),
                "upper-limit": new Date()
              }, null, 8, ["modelValue", "upper-limit"])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_25, [
      _createVNode(FormErrorToast, {
        "is-showing": __props.formSubmited && !__props.formIsValid,
        title: "The following fields are mandatory:",
        subtitle: __props.errorsFields.join(', ')
      }, null, 8, ["is-showing", "subtitle"])
    ])
  ]))
}
}

})