
export const calcThickness = (thickness:number) => {
    let newThickness:number;
    if (thickness >= 0 && thickness <= 3) {
        newThickness = 1
    }
    if (thickness >= 3 && thickness <= 5) {
        newThickness = 3
    }
    if (thickness >= 5 && thickness <= 10) {
        newThickness = 4
    }
    if (thickness >= 10 && thickness <= 40) {
        newThickness = 6
    }
    if (thickness >= 40 && thickness <= 55) {
        newThickness = 7
    }
    if (thickness > 55) {
        newThickness = 15
    }
    return newThickness
};