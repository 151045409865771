<template>
    <ModalSlots :show="isOpen" :hide-footer="true" @close-modal="$emit('closeModal')" dialogClass="prototype-designer-modal">
        <template v-slot:header><p>prototype designer</p></template>
        <template v-slot:body>
            <div class="designer-options-container">
                <!-- TIME OUT LOADER - TO BE IMPLEMENTED FOR REAL LATER -->
                <div v-if="showLoader" class="waiting-for-creation">
                    <img src="@/assets/icons/layers.gif" alt="creating prototypes">
                    <div class="pb-3">
                        <p class="creating">Creating prototypes</p>
                        <p>This new proptotype designs will be based on your requirements and will be ready in a few seconds</p>
                    </div>
                </div>  
                <TransitionRoot appear :show="!showLoader" enter="transition-opacity duration-500" enter-from="opacity-0" enter-to="opacity-100">
                    <ProgramPrototypeDesignModalRequirements  :targets="targets" />
                    <ProgramPrototypeDesignsAndFeedback :alternative-designs="alternativeDesigns"/>
                </TransitionRoot>    
            </div>
            <!-- Just to avoid warning on console- No action on focus trap -->
            <button @click="selectProto"></button>
        </template>
    </ModalSlots>
</template>
<script setup lang="ts">
import ModalSlots from "@/components/ModalSlots.vue";
import { TransitionRoot } from '@headlessui/vue'
import { defineProps, defineEmits, ref, onUpdated, PropType } from "vue";
import ProgramPrototypeDesignsAndFeedback from "./ProgramPrototypeDesignsAndFeedback.vue";
import { programTargetInterface, prototypesDesignsInfoInterface } from "@/models/programsInterface";
import ProgramPrototypeDesignModalRequirements from "./ProgramPrototypeDesignModalRequirements.vue";



defineProps({
    isOpen: {type: Boolean},
    targets:  { type: Array as PropType<programTargetInterface[]>},
    alternativeDesigns: { type: Array as PropType<prototypesDesignsInfoInterface[]> }
});

defineEmits(['closeModal']);

const showLoader = ref(true);

const selectProto = () => console.log('select')

onUpdated(() => {
    if (showLoader.value) {
        setTimeout(() => {
            showLoader.value = false
        }, 3000)
    } else {  setTimeout(() => {
            showLoader.value = true
        }, 500)}
})

</script>
<style lang="scss" scoped>
.designer-options-container {
    padding: 2rem;
    padding-bottom: 0.5rem;
}
.waiting-for-creation {
    display: flex;
    align-items: center;
    img {
        width: 200px;
    }
    p {
        font-size: 0.9rem;
        color: #000;
        font-weight: 300;
    }
    .creating {
        font-weight: 600;
        font-size: 1.3rem;
        text-transform: uppercase;
        color: #33cccd;
    }
}
</style>