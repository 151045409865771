<template>
    <ModalSlots :isOpen="isOpen" dialogClass="m-modal" @close-modal="$emit('closeModal')" createText="open" @click-create=handleOpenBenchmarkTable>
		<template v-slot:header>
			<p>Saved Benchmarks</p>
		</template>
		<template v-slot:body>
			<div class="p-6 pb-8">
                <template v-if="benchmarkList">
                    <p class="font-normal mb-4">Please select the benchamark you want to see</p>
                    <div class="filter-values flex-wrap grid grid-cols-2"> 
                        <template v-for="table in benchmarkList" :key="table.id">
                            <label class="filter-option hidden-check mr-4" :class="{'border-on': selectedTable == table}">
                                <BenchmarkIcon class="w-2/3 max-w-12 pr-4 pl-1 fill-black" :class="[selectedTable != table ? 'opacity-50' : 'opacity-100']"/>
                                <div class="flex flex-col leading-4">
                                    <p class="text-sm uppercase font-medium text-black">{{ table.title }}</p>
                                    <p class="text-xs  text-neutral-400">Created by {{table.createdBy.username}} on {{ moment(table.createdAt).format('DD MMM YYYY') }}</p>
                                </div>
                                <input type="radio" :id="table.title" :value="table" v-model="selectedTable">
                            </label>
                        </template>
                    </div>
                </template>
                <template v-else>
                   <div class="flex items-center">
                    <FaceFrownIcon class="stroke-1 stroke-black w-12 mr-4" />
                    <p class="text-black">Ops! There are no tables created yet. <span class="block">You can click in the create option and start creating your benchmarking tables</span></p>
                   </div>  
                </template>
            </div>
		</template>
		
	</ModalSlots>
</template>
<script setup lang="ts">
import { useQuery } from "@vue/apollo-composable";
import ModalSlots from "./ModalSlots.vue";
import { defineProps, defineEmits, computed, ref} from 'vue';
import { FaceFrownIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';
import { BENCHMARK_LIST } from "@/grapql/userWorkspaceQueryMutations";
import { WorkspaceFromBE } from '@/models/benchmarkModels';
import BenchmarkIcon from '@/components/icons/BenchmarkIcon.vue';
import moment from 'moment';
import router from "@/router";

const props = defineProps<{isOpen: boolean}>();
const emits = defineEmits(['closeModal']);

const store = useStore();

const { result } = useQuery(BENCHMARK_LIST, {filter: {workspaceType: {exact: "ASSET_BENCHMARK"}}}, () => ({ enabled: props.isOpen,}));
const inizilizedData = computed(() => result?.value ?? null);
const benchmarkList = computed<WorkspaceFromBE[]>(() => inizilizedData?.value?.userWorkspaces?.edges.map((edge: {node: WorkspaceFromBE}) => edge.node)  ?? null);
const selectedTable = ref();
const handleOpenBenchmarkTable = () => {
    if(selectedTable.value) {
        store.commit('programVersionDetailModule/setBenchmarkValues', {id: selectedTable.value.id, tableData: JSON.parse(selectedTable.value.value), tableTitle: selectedTable.value.title});
        emits('closeModal');
        const title = (selectedTable.value.title).replaceAll(' ', '-');
        router.push({ name: 'Benchmark', params: { title: `${selectedTable.value.id}-${title}`} });
    }
}

</script>
<style lang="scss" scoped>
.filter-values {
    //display: flex;
    .filter-option {
        margin-bottom: 0.8rem;
        font-size: 1rem;
        display: flex;
        border: 1px solid #ddd;
        border-radius: 6px;
        padding: 5px 10px;
        align-items: center;
        // justify-content: space-between;
        cursor: pointer;
        input {
            width: 16px;
            height: 16px;
            border-radius: 20px;
            margin-right: 0.5rem;
            margin-top: 0.3rem;
        }
        &.hidden-check {
            &.border-on {
                border: 2px solid black;
                background-color: #feedf4;
            }
            input {
                appearance: none;
                border: none;
                &:checked {
                    background-image: none;
                }
            }
        }
    }
}
</style>