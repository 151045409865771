import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "program-detail-card mb-4" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "cards-title"
}
const _hoisted_4 = ["innerHTML"]




export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramVersionGoalDescriptionCard',
  props: {
    goal: {},
    description: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("p", { class: "cards-title" }, "goal", -1)),
    _createElementVNode("p", {
      innerHTML: _ctx.goal,
      class: "card-text"
    }, null, 8, _hoisted_2),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, "description"))
      : _createCommentVNode("", true),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          class: "mb-3",
          innerHTML: _ctx.description
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}
}

})