import { useQuery, useLazyQuery } from "@vue/apollo-composable";

// Create a custom hook with default options
export function useRpsesQuery(query, variables = {}, customOptions = {}) {
  const defaultOptions = {
    // Define your default options here
    context: {
      uri: process.env.VUE_APP_RPSES_ROOT_API,
    },
  };

  // Combine the default options with custom options
  const options = { ...defaultOptions, ...customOptions };

  // Use the useQuery function with the combined options
  return useQuery(query, variables, options);
}


// Create a custom hook with default options for useLazyQuery
export function useRpsesLazyQuery(query, customOptions = {}) {
  const defaultOptions = {
    // Define your default options here
    context: {
      uri: process.env.VUE_APP_TEA_ROOT_API,
    },
  };

  // Combine the default options with custom options
  const options = { ...defaultOptions, ...customOptions };

  // Use the useLazyQuery function with custom options
  return useLazyQuery(query, options);
}

export default { useRpsesQuery, useRpsesLazyQuery };
