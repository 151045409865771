import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "title-menu-header mb-0" }
const _hoisted_2 = { class: "view-title" }
const _hoisted_3 = { class: "action-icons" }
const _hoisted_4 = {
  key: 3,
  class: "flex items-center"
}

import router from "@/router";
import { ArrowUturnLeftIcon, PencilIcon, TrashIcon, CheckIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { computed } from "vue";
import { useStore } from "vuex";
import AvatarsActivity from "@/components/AvatarsActivity.vue";
import FormErrorToast from "@/components/FormErrorToast.vue";
import RunHeaderActions from "@/tea/components/runs/RunHeaderActions.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'RunGroupDetailHeader',
  props: ["title", "usersActivity","runGroupId"],
  emits: ["onRunCreated", "onRunSearchText"],
  setup(__props, { emit: __emit }) {


const emit = __emit;

const store = useStore();
const update = computed(()=>store.getters["runGroupModule/update"]);
const queryError = computed(() => update.value.error);
const editModeOn = computed(() => update.value.editable);
const isDeletable = computed(()=> update.value.deletable);
const handleNewValue = (event: Event, mutationValue: string) => {
  const content = (event.target as HTMLInputElement).value;
  store.commit("runGroupModule/SET_UPDATE_SINGLE_RUN_GROUP_VALUE", { mutationValue: mutationValue, content: content });
};
const handleBack = () => {
  store.commit("runGroupModule/SET_UPDATE_EDITABLE_OFF");
  router.push({ name:"Tea.Dashboard" });
};
const handleEdit = () => store.commit("runGroupModule/SET_UPDATE_EDITABLE_ON");

const deleteRunGroup = () => {
  store.dispatch("runGroupModule/deleteRunGroup",update.value.runGroup.id).then(()=>{
    handleBack();
  });
}

const saveChanges = async () => await store.dispatch("runGroupModule/runGroupUpdate");
const cancelChanges = () => store.commit("runGroupModule/SET_UPDATE_EDITABLE_OFF");

const handleOnRunSearchText=(value)=> emit('onRunSearchText',value);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", _hoisted_2, _toDisplayString(__props.title), 1),
      _createElementVNode("div", _hoisted_3, [
        (!editModeOn.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "round-action-icon",
              onClick: handleBack
            }, [
              _createVNode(_unref(ArrowUturnLeftIcon), { class: "icon icon-small" })
            ]))
          : _createCommentVNode("", true),
        (!editModeOn.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "round-action-icon",
              onClick: handleEdit
            }, [
              _createVNode(_unref(PencilIcon), { class: "icon icon-small" })
            ]))
          : _createCommentVNode("", true),
        (!editModeOn.value)
          ? (_openBlock(), _createBlock(RunHeaderActions, {
              key: 2,
              onOnCreated: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onRunCreated'))),
              onOnSearchText: handleOnRunSearchText,
              runGroupId: __props.runGroupId
            }, null, 8, ["runGroupId"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (isDeletable.value)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "round-action-icon danger tooltip mr-2",
                    onClick: deleteRunGroup
                  }, [
                    _cache[1] || (_cache[1] = _createElementVNode("span", { class: "tooltiptext danger" }, "Remove asset from repository", -1)),
                    _createVNode(_unref(TrashIcon), { class: "icon icon-small" })
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                class: "header-action-button action",
                onClick: saveChanges
              }, [
                _createVNode(_unref(CheckIcon), { class: "icon icon-small" }),
                _cache[2] || (_cache[2] = _createTextVNode("Save changes"))
              ]),
              _createElementVNode("button", {
                class: "header-action-button cancel",
                onClick: cancelChanges
              }, [
                _createVNode(_unref(XMarkIcon), { class: "icon icon-small" }),
                _cache[3] || (_cache[3] = _createTextVNode("Cancel"))
              ])
            ]))
      ])
    ]),
    _createVNode(AvatarsActivity, {
      orientation: 'left',
      "users-activity": __props.usersActivity,
      style: {"margin-bottom":"10px"}
    }, null, 8, ["users-activity"]),
    _createVNode(FormErrorToast, {
      "is-showing": queryError.value ? true : false,
      title: "Error",
      subtitle: queryError.value
    }, null, 8, ["is-showing", "subtitle"])
  ]))
}
}

})