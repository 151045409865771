import { ASSET_TYPES } from "@/models/technologyAssetConstants";
import { useNodeContent, useNodeContentOnSources } from "./useNodeContent";

const formatPatent = (data)  => {
    const formatedPatent = {
        id: data.id,
        nickname: data.nickname,
        title: data.title,
        assetStatus: data.assetStatus.name,
        statusReason: data.statusReason,
        patentId: data.patentId,
        status: Number(data.status.id),
        abstract: data.abstract,
        patentIds: data.patentIds,
        isPrepatent: data.isPrepatent,
        trl: data.trl,
        properties: useNodeContent(data.properties.edges, 'id', 'number'), 
        materials: useNodeContent(data.materials.edges, 'id', 'number'), 
        tags: data.tags, 
        applicationTypes: useNodeContent(data.applicationTypes.edges, 'id', 'number'),
        standards:  useNodeContent(data.standards.edges, 'id', 'number'), 
        processingTechniques: useNodeContent(data.processingTechniques.edges, 'id', 'number'), 
        url: data.url, 
        links: data.links, 
        notes: data.notes,
        description: data.description, 
        claims: useNodeContent(data.claims.edges, 'claim', 'number'),
        sources: useNodeContentOnSources(data.assetSources.edges.map(source => source.node)),
        publicationDate: data.publicationDate,
        priorityDate: data.priorityDate,
        expiryDate: data.expiryDate,
        grantedDate: data.grantedDate,
        inventors: data.inventors ? useNodeContent(data.inventors.edges, 'id', 'number') : [], 
        assignees: data.assignees ? useNodeContent(data.assignees.edges, 'id', 'number') : [], 
        owners: data.owners ? useNodeContent(data.owners.edges, 'id', 'number') : [], 
        pendingTerritories: useNodeContent(data.pendingTerritories.edges, 'id', 'number'), 
        grantedTerritories: useNodeContent(data.grantedTerritories.edges, 'id', 'number'), 
        expiredTerritories: useNodeContent(data.expiredTerritories.edges, 'id', 'number'),
        assetPrice: {
            quantity: data.assetPrice?.quantity, 
            unitType: data.assetPrice?.unitType?.id,
            price: data.assetPrice?.price,
            unitTypeSize: data.assetPrice?.unitTypeSize,
            notes: data.assetPrice?.notes
        },
    };
    return formatedPatent;
}
const formatResearchPaper = (data) => {
    const formatedResearchPaper = {
        id: data.id,
        nickname: data.nickname,
        title: data.title,
        assetStatus: data.assetStatus.name,
        statusReason: data.statusReason,
        abstract: data.abstract,
        trl: data.trl,
        properties: useNodeContent(data.properties.edges, 'id', 'number'), 
        materials: useNodeContent(data.materials.edges, 'id', 'number'), 
        tags: data.tags, 
        applicationTypes: useNodeContent(data.applicationTypes.edges, 'id', 'number'), 
        url: data.url, 
        links: data.links, 
        notes: data.notes,
        description: data.description, 
        standards:  useNodeContent(data.standards.edges, 'id', 'number'), 
        processingTechniques: useNodeContent(data.processingTechniques.edges, 'id', 'number'), 
        sources: useNodeContentOnSources(data.assetSources.edges.map(source => source.node)),
        publicationDate: data.publicationDate,
        authors: data.authors ? useNodeContent(data.authors.edges, 'id', 'number') : [],
        journal: data.journal,
        doiId: data.doiId,
        assetPrice: {
            quantity: data.assetPrice?.quantity, 
            unitType: data.assetPrice?.unitType?.id,
            price: data.assetPrice?.price,
            unitTypeSize: data.assetPrice?.unitTypeSize,
            notes: data.assetPrice?.notes
        },
    };
    return formatedResearchPaper;
}
const formatResearchProject = (data) => {
    const formatedResearchProject = {
        id: data.id,
        nickname: data.nickname,
        title: data.title,
        assetStatus: data.assetStatus.name,
        statusReason: data.statusReason,
        abstract: data.abstract,
        trl: data.trl,
        properties: useNodeContent(data.properties.edges, 'id', 'number'), 
        materials: useNodeContent(data.materials.edges, 'id', 'number'), 
        tags: data.tags, 
        applicationTypes: useNodeContent(data.applicationTypes.edges, 'id', 'number'), 
        standards:  useNodeContent(data.standards.edges, 'id', 'number'), 
        processingTechniques: useNodeContent(data.processingTechniques.edges, 'id', 'number'), 
        url: data.url, 
        links: data.links, 
        notes: data.notes,
        description: data.description, 
        sources: useNodeContentOnSources(data.assetSources.edges.map(source => source.node)),
        publicationDate: data.publicationDate,
        owner: data.owner.id ? data.owner.id : null,
        assetPrice: {
            quantity: data.assetPrice?.quantity, 
            unitType: data.assetPrice?.unitType?.id,
            price: data.assetPrice?.price,
            unitTypeSize: data.assetPrice?.unitTypeSize,
            notes: data.assetPrice?.notes
        },
    };
    return formatedResearchProject;
}
const formatOtherTechnology = (data) => {
    const formatedOtherTechnology = {
        id: data.id,
        nickname: data.nickname,
        title: data.title,
        assetStatus: data.assetStatus.name,
        statusReason: data.statusReason,
        abstract: data.abstract,
        trl: data.trl,
        properties: useNodeContent(data.properties.edges, 'id', 'number'), 
        materials: useNodeContent(data.materials.edges, 'id', 'number'), 
        tags: data.tags, 
        applicationTypes: useNodeContent(data.applicationTypes.edges, 'id', 'number'), 
        standards:  useNodeContent(data.standards.edges, 'id', 'number'), 
        processingTechniques: useNodeContent(data.processingTechniques.edges, 'id', 'number'), 
        url: data.url, 
        links: data.links, 
        notes: data.notes,
        description: data.description, 
        sources: useNodeContentOnSources(data.assetSources.edges.map(source => source.node)),  
        publicationDate: data.publicationDate,
        owners: data.owners ? useNodeContent(data.owners.edges, 'id', 'number') : [],
        targetLayers: useNodeContent(data.targetLayers.edges, 'id', 'number'),
        formTypes: useNodeContent(data.formTypes.edges, 'id', 'number'),
        otherTechnologyType: data.otherTechnologyType.name,
        previouslyProcured: data.previouslyProcured,
        assetPrice: {
            quantity: data.assetPrice?.quantity, 
            unitType: data.assetPrice?.unitType?.id,
            price: data.assetPrice?.price,
            unitTypeSize: data.assetPrice?.unitTypeSize,
            notes: data.assetPrice?.notes
        },

    };
    return formatedOtherTechnology;
}
export const useRawAssetResponseToStateType = (rawData: undefined, assetType:string) => {
    let formatedAsset = {};
    if (assetType === ASSET_TYPES.PATENT.graphqlValue) {
        formatedAsset = formatPatent(rawData);
    }
    if (assetType === ASSET_TYPES.RESEARCH_PAPER.graphqlValue) {
        formatedAsset = formatResearchPaper(rawData);
    }
    if (assetType === ASSET_TYPES.RESEARCH_PROJECT.graphqlValue) {
        formatedAsset = formatResearchProject(rawData);
    }
    if (assetType === ASSET_TYPES.OTHER.graphqlValue) {
        formatedAsset = formatOtherTechnology(rawData);
    }
    return formatedAsset;
}