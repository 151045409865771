import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/google.png'


import { useRouter } from "vue-router";
import { computed, ref } from "vue";
import { googleTokenLogin } from "vue3-google-login";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'GoogleLogin',
  setup(__props) {

const store = useStore();
const error = ref(null);
const loggedUser = computed(() => { return store.state.authModule.user });
const router = useRouter();

const hanldleClickLogin =  async () => {
    try {
        const nextPath = router.currentRoute.value.redirectedFrom ? router.currentRoute.value.redirectedFrom.fullPath : '/technology-assets-repo';
        const googleResponse = await googleTokenLogin();
        await store.dispatch('authModule/login', googleResponse.access_token)
        loggedUser.value && loggedUser.value.isStaff ? router.push(nextPath) : null;  
    }
    catch(err) {
        error.value = err.message
        console.log(error.value)
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    onClick: hanldleClickLogin,
    class: "google-btn"
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("img", {
      src: _imports_0,
      alt: "google icon"
    }, null, -1),
    _createTextVNode(" Sign in with Google ")
  ])))
}
}

})