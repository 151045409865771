import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-6 pb-8" }
const _hoisted_2 = { key: 2 }
const _hoisted_3 = ["disabled"]

import ModalSlots from "./ModalSlots.vue";
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import router from "@/router";
import FormErrorToast from '@/components/FormErrorToast.vue';
import { StoreBenchmarkData } from "@/models/benchmarkModels";


export default /*@__PURE__*/_defineComponent({
  __name: 'ModalSave',
  props: {
    isOpen: { type: Boolean }
  },
  emits: ['closeModal'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;

const store = useStore();

const benchmarkTableName = ref();
const saveInProgress = ref(false);
const benchmarkId = computed(() => store.state.programVersionDetailModule.benchmarkId)
const errorsFromMutation = computed(() => benchmarkId.value ? store.state.programVersionDetailModule.errorDuringMutation.benchmarkUpdate : store.state.programVersionDetailModule.errorDuringMutation.benchmarkCreate);


const saveBenchmarkTable = async () => {
	if(benchmarkId.value) {
		store.commit('programVersionDetailModule/setBenchmarkTableTitle', { title: benchmarkTableName.value });
		await store.dispatch('programVersionDetailModule/benchmarkTableUpdate')
			.then(() => {
				if (!errorsFromMutation.value) {
					emits('closeModal');
					router.push({ name: 'Benchmark', params: { title: `${benchmarkId.value}-${benchmarkTableName.value.replaceAll(' ', '-')}` } });
				}
			});
	}
	else if (benchmarkTableName.value) {
		store.commit('programVersionDetailModule/setBenchmarkTableTitle', { title: benchmarkTableName.value });
		await store.dispatch('programVersionDetailModule/benchmarkTableCreate')
			.then(() => {
				if (!errorsFromMutation.value) {
					emits('closeModal');
					if (benchmarkId.value) {
						router.push({ name: 'Benchmark', params: { title: `${benchmarkId.value}-${benchmarkTableName.value.replaceAll(' ', '-')}` } });
					}

				}
			});
	}
}

watch(() => props.isOpen, () => {
	if(props.isOpen) {
		const benchmarkData = computed<StoreBenchmarkData>(() => store.getters['programVersionDetailModule/getBenchmarkData']);
		benchmarkTableName.value = benchmarkData.value.benchmarkTitle;
	}
	
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalSlots, {
    isOpen: _ctx.isOpen,
    dialogClass: "xs-modal",
    onCloseModal: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('closeModal')))
  }, {
    header: _withCtx(() => _cache[3] || (_cache[3] = [
      _createElementVNode("p", null, "Save as", -1)
    ])),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (errorsFromMutation.value)
          ? (_openBlock(), _createBlock(FormErrorToast, {
              key: 0,
              "is-showing": errorsFromMutation.value,
              title: errorsFromMutation.value,
              toastType: "warning"
            }, null, 8, ["is-showing", "title"]))
          : _createCommentVNode("", true),
        (!benchmarkId.value)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _cache[4] || (_cache[4] = _createElementVNode("p", { class: "font-normal mb-4" }, "Please complete the title field to save the table", -1)),
              _cache[5] || (_cache[5] = _createElementVNode("label", null, "Title *", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((benchmarkTableName).value = $event)),
                class: "w-full p-2"
              }, null, 512), [
                [_vModelText, benchmarkTableName.value]
              ])
            ], 64))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _cache[6] || (_cache[6] = _createElementVNode("p", { class: "mb-4" }, "This tables has changes, click on save to make them permanent. You can also chenge the name if you want to.", -1)),
              _cache[7] || (_cache[7] = _createElementVNode("label", null, "Title *", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((benchmarkTableName).value = $event)),
                class: "w-full p-2"
              }, null, 512), [
                [_vModelText, benchmarkTableName.value]
              ])
            ]))
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("button", {
        onClick: saveBenchmarkTable,
        class: _normalizeClass(["button bg-black text-white", { 'cursor-not-allowed hover:shadow-none opacity-50': saveInProgress.value }]),
        disabled: saveInProgress.value
      }, "save", 10, _hoisted_3)
    ]),
    default: _withCtx(() => [
      _createTextVNode(" " + _toDisplayString(benchmarkTableName.value) + " ", 1)
    ]),
    _: 1
  }, 8, ["isOpen"]))
}
}

})