import { POSITION, useToast } from "vue-toastification";
import { RUN_CALCULATE_MUTATION, RUN_CREATE_MUTATION } from "@/tea/graphql/mutations/runMutations";
import { useTeaMutation } from "@/tea/composables/apollo/teaUseMutation";
import { useTeaQuery } from "@/tea/composables/apollo/useTeaQuery";
import { RUNS_QUERY } from "@/tea/graphql/queries/runQueries";
import { RUN_DELETE_MUTATION, RUN_UPDATE_MUTATION } from "@/tea/graphql/mutations/runMutations";
import { useRawRunToStateType } from "@/tea/composables/runs/useRawRunToStateType";

const toast = useToast();
const getDefaultState = () => {
  return {
    fields: {
      runGroup: null,
      place: null,
      species: null,
      compound: null,
      residualBiomass: false,
      organism: null,
      cultivationSystem: null,
      compoundPct: null,
      biomassTarget: "Target compound",
      targetCompoundCapacity: null,
      biomass: null,
      costBiomass: null,
      bycompounds: 0,
      bycompoundXPct: null,
      bycompoundYPct: null,
      bycompoundZPct: null,
      landCapex: null,
      land: null,
      artificialLight: false,
      hsLight: null,
      sugarConc: null,
      isOtherSugarPrice: null,
      otherSugarPrice: null,
      heatCoolSystem: false,
      hsHeatCool: null,
      operatingDays: null,
      cycleLength: null,
      suppliments: 0,
      supplimentXConc: null,
      supplimentXPrice: null,
      supplimentYConc: null,
      supplimentYPrice: null,
      supplimentZConc: null,
      supplimentZPrice: null,
      harvestingConc: null,
      harvestingPct: null,
      facilityUsagePct: null,
      harvestMethod: null,
      flocculant: null,
      otherFlocculantConc: null,
      otherFlocculantPrice: null,
      concFactorHarvesting: null,
      extractionMethod: null,
      extractionBufferConc: null,
      extractionBufferPrice: null,
      extractionBuffer: null,
      solvent: null,
      solventConc: null,
      otherSolventPrice: null,
      moreSolvents: 0,
      moreSolventXConc: null,
      moreSolventXPrice: null,
      moreSolventYConc: null,
      moreSolventYPrice: null,
      dryMethod: null,
      knowTargetCompoundPrice:false,
      targetCompoundPrice:null,
      knowBycompoundsPrice:null,
      bycompoundXPrice:null,
      bycompoundYPrice:null,
      bycompoundZPrice:null,
    },
    error: null,
    isReadyForOutput: false,
    canSubmit:false,
    teaOutput:null,
    calculateError:null,
    createError:null,
    isOutputLoading:false,
    isFormSubmitted:false,
  };
};

const state = getDefaultState();

const mutations = {
  SET_RUN_GROUP_ID(state, id) {
    state.fields.runGroup = id;
  },
  SET_SINGLE_RUN_VALUE(state, payload: { field: string; value: Date | number | string | number[] | null; resetFields:[] }) {
    state.fields[payload.field] = payload.value;
    state.isReadyForOutput = false;
    state.canSubmit= false;
    state.isOutputLoading = false;
    payload.resetFields.forEach((field)=>{
      state.fields[field] = getDefaultState().fields[field]
    })
  },
  SET_ERROR(state, message) {
    state.error = message;
  },
  RESET_CREATE(state) {
    const runGroup = state.fields.runGroup;
    Object.assign(state, getDefaultState());
    state.fields.runGroup=runGroup;
  },
  SET_IS_READY_FOR_OUTPUT(state, value) {
    state.isReadyForOutput = value;
  },
  SET_IS_OUTPUT_LOADING(state,value){
    state.isOutputLoading=value;
  },
  SET_CAN_SUMBIT(state,value){
    state.canSubmit = value;
  },
  SET_TEA_OUTPUT(state,tea){
    state.teaOutput=tea;
  },
  SET_CALCULATE_ERROR(state,message){
    state.calculateError=message;
  },
  SET_CREATE_ERROR(state,message){
    state.createError=message;
  },
  SET_IS_FORM_SUBMITTED(state,value){
    state.isFormSubmitted = value
  },
};

const actions = {
  // CREATE
  async runCreate({ state, commit, dispatch }) {
    return new Promise<void>((resolve, reject) => {
      const { mutate: runCreate, onDone, onError } = useTeaMutation(RUN_CREATE_MUTATION);
      const runInput = Object.assign({}, state.fields);
      runCreate({ input: runInput });
      onDone(async () => {
        await dispatch("runCreateOnDone");
        resolve();
      });
      onError((error) => {
        commit("SET_CREATE_ERROR", error.message);
        reject(error.message);
      });
    });
  },
  // ON DONE CREATION ACTIONS
  async runCreateOnDone({ state, commit, dispatch }) {
    toast.success(`New run created`, { position: POSITION.BOTTOM_LEFT, timeout: 1524 });
    
  },

  // RUN SHOW OUTPUT
  async runCalculate({state,commit,dispatch}){
    commit("SET_CALCULATE_ERROR",null)
    commit("SET_IS_OUTPUT_LOADING",true);
    return new Promise<void>((resolve, reject) => {
      const { mutate: runCalculate, onDone, onError } = useTeaMutation(RUN_CALCULATE_MUTATION);
      const runInput = Object.assign({}, state.fields);
      runCalculate({ input: runInput },{ fetchPolicy: 'no-cache',});
      onDone((result) => {
        commit("SET_TEA_OUTPUT",result.data.runCalculate.tea)
        commit("SET_IS_OUTPUT_LOADING",false);
        resolve();
      });
      onError((error) => {
        commit("SET_CALCULATE_ERROR", error.message);
        commit("SET_IS_OUTPUT_LOADING",false);
        reject(error.message);
      });
    });
  },

  //DELETE RUN GROUP
  async deleteRun({ state, commit, dispatch }, id) {
    const { mutate: runDelete, onDone, onError } = useTeaMutation(RUN_DELETE_MUTATION);
    runDelete({ input: { id: id } });
    onDone(async () => {
      state.error = null;
      commit("DELETE_REPO_RUN_RECORD", id);
      commit("SET_UPDATE_EDITABLE_OFF");
      toast.warning("Run  Deleted", { position: POSITION.BOTTOM_LEFT, timeout: 1524 });
      dispatch("loadRuns");
    });
    onError(async (error) => {
      state.error = error.message;
    });
  },

  // RUN GROUP UPDATE
  async runUpdate({ state, commit }) {
    const { mutate: runUpdate, onDone, onError } = useTeaMutation(RUN_UPDATE_MUTATION);
    const runInput = Object.assign({}, state.update.run);
    runUpdate({ input: runInput });
    onDone(async () => {
      state.update.error = null;
      commit("SET_UPDATE_EDITABLE_OFF");
      toast.success("Run  Updated", { position: POSITION.BOTTOM_LEFT, timeout: 1524 });
    });
    onError(async (error) => {
      state.update.error = error.message;
    });
  },
};

const getters = {
  repo(state) {
    return state.repo;
  },
  fields(state) {
    return state.fields;
  },
  isReadyForOutput(state){
    return state.isReadyForOutput;
  },
  isOutputLoading(state){
    return state.isOutputLoading;
  },
  isFormSubmitted(state){
    return state.isFormSubmitted;
  },
  canSubmit(state){
    return state.canSubmit;
  },
  teaOutput(state){
    return state.teaOutput;
  },
  error(state) {
    return state.error;
  },
  createError(state) {
    return state.createError;
  },
  calculateError(state) {
    return state.calculateError;
  },
  create(state) {
    return state.create;
  },
  update(state) {
    return state.update;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
