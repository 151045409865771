<template>
  <div>
    <p class="text-blue-500 text-center my-10">
      A password reset link is sent to your email.
    </p>

    <hr class="my-4" />
    <button
      @click.prevent="$emit('showSignIn')"
      class="w-full bg-emerald-400 text-white font-bold py-2 px-4 rounded hover:bg-emerald-600 focus:outline-none focus:shadow-outline"
    >
      Sign In
    </button>
  </div>
</template>

<script setup></script>

<style scoped>
/* Add your custom styling here */
</style>
