<template>
    <div class="filter-box shadow-md rounded-lg mb-6 bg-acqua">
        <div class="filter-title flex justify-between px-4 py-3">
            <div>
                <p class="title">coating</p>
                <p class="subtitle">Barrier technology filtering</p>
            </div>
            <EllipsisHorizontalIcon class="icon icon-small white"
                @click="coatingFilterOptions = !coatingFilterOptions" />
        </div>
        <div class="filter-numbers flex cursor-pointer" @click="coatingFilterOptions = !coatingFilterOptions">
            <p class="w-full text-center">62</p>
            <p v-if="filteredTreatment" class="w-full text-center">{{ filteredTreatment }}</p>
            <p v-if="filteredKit" class="w-full text-center">{{ filteredKit }}</p>
            <p v-if="filteredCoating" class="w-full text-center">{{ filteredCoating }}</p>
        </div>
        <div v-if="coatingFilterOptions" class="filter-options flex flex-col px-3 py-4 text-sm">
            <p class="uppercase font-medium">Treatment</p>
            <div class="flex justify-between pr-4">
                <div class="flex mt-1 mb-3 flex-wrap">
                    <div v-for="treatment in treatmentsOptions" :key="treatment.value"
                        class="filter-option flex items-center flex-nowrap">
                        <input type="checkbox" :value="treatment.value" v-model="selectedtreatments">
                        <label>{{ treatment.label }} </label>
                    </div>
                </div>
                <MagnifyingGlassCircleIcon @click="onFilterLayer('treatment')" class="icon icon-m" />
            </div>
            <p class="uppercase font-medium">Kit</p>
            <div class="flex justify-between pr-4">
                <div class="flex mt-1 mb-3">
                    <div v-for="kit in kitOptions" :key="kit.value" class="filter-option flex items-center">
                        <input type="checkbox" :value="kit.value" v-model="selectedKit">
                        <label>{{ kit.label }} </label>
                    </div>
                </div>
                <MagnifyingGlassCircleIcon @click="onFilterLayer('kit')" class="icon icon-m shrink-0" />
            </div>
            <p class="uppercase font-medium">Coating techniques</p>
            <div class="flex justify-between pr-4">
                <div class="flex mt-1 mb-3 flex-wrap">
                    <div v-for="coating in coatingOptions" :key="coating.value" class="filter-option flex items-center">
                        <input type="checkbox" :value="coating.value" v-model="selectedCoating">
                        <label>{{ coating.label }} </label>
                    </div>
                </div>
                <MagnifyingGlassCircleIcon @click="onFilterLayer('coating')" class="shrink-0 icon icon-m" />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { defineProps, defineEmits, ref } from 'vue';
import { EllipsisHorizontalIcon } from '@heroicons/vue/20/solid';
import { MagnifyingGlassCircleIcon } from '@heroicons/vue/24/outline';

const props = defineProps(['layerIndex']);
const emit = defineEmits(['filterLayer']);

const coatingFilterOptions = ref(false);
const treatmentsOptions = [{ label: 'Fully biobased', value: 15 }, { label: 'Partially biobased', value: 6 }, { label: 'Petrol based', value: 25 }]
const selectedtreatments = ref([]);

const kitOptions = [{ label: '>= 10', value: 2 }, { label: '>= 12', value: 5 }]
const selectedKit = ref([]);

const coatingOptions = [{ label: 'Extrusion', value: 2 }, { label: 'Lamination', value: 5 }, { label: 'Dispersion', value: 3 }, { label: 'Other', value: 4 }]
const selectedCoating = ref([]);

const filteredTreatment = ref(null);
const filteredKit = ref(null);
const filteredCoating = ref(null);

const onFilterLayer = (filterType: string) => {
    if (filterType == 'treatment') {
        const newTotal = selectedtreatments.value.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        setTimeout(() => {
            filteredTreatment.value = newTotal
            emit('filterLayer', { layerIndex: props.layerIndex, newTotal: filteredTreatment.value, rowNumber: 2 })
        }, 1000)
    }
    if (filterType == 'kit') {
        const newTotal = selectedKit.value.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        setTimeout(() => {
            filteredKit.value = newTotal
            emit('filterLayer', { layerIndex: props.layerIndex, newTotal: filteredKit.value, rowNumber: 3 })
        }, 700)
    }
    if (filterType == 'coating') {
        const newTotal = selectedCoating.value.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        setTimeout(() => {
            filteredCoating.value = newTotal
            emit('filterLayer', { layerIndex: props.layerIndex, newTotal: filteredCoating.value, rowNumber: 4 })
        }, 500)
    }
}

</script>