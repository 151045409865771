export const NEW_PARTY_FIELDS = {
    INDIVIDUAL: {
        graphqlValue: 'individual',
        formFields: [
            {label:'Nickname', type: 'text', mutationValue: 'nickname', required: true }, 
            {label:'Firstname', type: 'text', mutationValue: 'firstname', required: true }, 
            {label:'Lastname', type: 'text', mutationValue: 'lastname' }, 
            {label:'Url', type: 'text', mutationValue: 'url' },  
            {label:'Links', type: 'multiple-text', mutationValue: 'links' },  
            {label:'Description', type: 'textarea', mutationValue: 'description' }, 
            {label:'Sources', type: 'mulitple-select', mutationValue: 'sources', queryValue: 'sources' }, 
            {label:'Business Type', type: 'mulitple-select', mutationValue: 'businessTypes', queryValue: 'businessTypes' }, 
        ],
        mandatoryFormFields: ['nickname', 'firstname'],
        fieldsToQuery: ['sources','businessTypes'],
        createMutation: 'partyModule/individualCreate'
    },
    ORGANIZATION: {
        graphqlValue: 'organization',
        formFields: [
            {label:'Nickname', type: 'text', mutationValue: 'nickname', required: true }, 
            {label:'Description', type: 'textarea', mutationValue: 'description' }, 
            {label:'Url', type: 'text', mutationValue: 'url' },  
            {label:'Links', type: 'multiple-text', mutationValue: 'links' },  
            {label:'Sources', type: 'mulitple-select', mutationValue: 'sources', queryValue: 'sources' }, 
            {label:'Business Type', type: 'mulitple-select', mutationValue: 'businessTypes', queryValue: 'businessTypes' }, 
            {label:'Organization Type', type: 'select', mutationValue: 'organizationType', queryValue: 'organizationTypes', required: true }, 
        ],
        mandatoryFormFields: ['nickname', 'organizationType'],
        fieldsToQuery: ['sources', 'organizationTypes', 'businessTypes'],
        createMutation: 'partyModule/organizationCreate'
    },
    UNIVERSITY: {
        graphqlValue: 'university',
        formFields: [
            {label:'Nickname', type: 'text', mutationValue: 'nickname', required: true}, 
            {label:'Description', type: 'textarea', mutationValue: 'description' }, 
            {label:'Url', type: 'text', mutationValue: 'url' },  
            {label:'Links', type: 'multiple-text', mutationValue: 'links' },  
            {label:'Sources', type: 'mulitple-select', mutationValue: 'sources', queryValue: 'sources' }, 
            {label:'Business Types', type: 'mulitple-select', mutationValue: 'businessTypes', queryValue: 'businessTypes' }, 
        ],
        mandatoryFormFields: ['nickname'],
        fieldsToQuery: ['sources','businessTypes'],
        createMutation: 'partyModule/universityCreate'
    },
}




