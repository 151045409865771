import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "layer-form"
}
const _hoisted_2 = { class: "border-t mt-6 pb-8" }
const _hoisted_3 = { class: "sticky top-0 bg-white pt-6 pb-4 border-b z-10" }
const _hoisted_4 = { class: "flex justify-between items-center" }
const _hoisted_5 = { class: "grid grid-cols-1 divide-y" }
const _hoisted_6 = {
  key: 0,
  class: "flex justify-center"
}
const _hoisted_7 = {
  key: 1,
  class: "bg-neutral-100 p-4 mx-6 flex items-center mt-2"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "flex items-center justify-center mb-3" }
const _hoisted_12 = ["onUpdate:modelValue"]
const _hoisted_13 = ["value"]
const _hoisted_14 = { class: "flex items-center justify-center mb-3" }
const _hoisted_15 = ["onUpdate:modelValue"]
const _hoisted_16 = ["value"]
const _hoisted_17 = { class: "flex items-center justify-center mb-3" }
const _hoisted_18 = ["onUpdate:modelValue"]
const _hoisted_19 = ["onUpdate:modelValue"]
const _hoisted_20 = { class: "flex items-center justify-center mb-3" }
const _hoisted_21 = ["onUpdate:modelValue"]
const _hoisted_22 = ["value"]
const _hoisted_23 = { class: "flex items-center justify-center mb-3" }
const _hoisted_24 = { class: "w-2/3 ml-3" }
const _hoisted_25 = { class: "flex items-center justify-center mb-3" }
const _hoisted_26 = ["onUpdate:modelValue"]
const _hoisted_27 = {
  key: 0,
  class: "flex items-center justify-end mb-3"
}
const _hoisted_28 = { class: "flex items-center" }
const _hoisted_29 = ["onUpdate:modelValue"]

import { ref, computed, onMounted } from 'vue';
import { PlusCircleIcon, InformationCircleIcon, XCircleIcon, ArrowUturnLeftIcon, ArrowUpIcon, ArrowDownIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';
import { NEW_PROGRAM_VERSIONS_DESIGN_QUERIES } from '@/grapql/newProgramVersionsDesignQueries';
import { useQuery } from '@vue/apollo-composable';
import { LayerCreateInterface, LayersFromBEInterface, ValueTypeInterface } from '@/models/programVersionsInterface';
import DropdownSearchableSingle from './DropdownSearchableSingle.vue';
import LayersDesignGenerator from './LayersDesignGenerator.vue';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { layerDataInterface } from '@/models/programsInterface';
import { calcThickness } from '@/composables/useLayerThicknessToVisualThickness';
import {Vue3ColorPicker} from '@cyhnkckali/vue3-color-picker';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'



export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramVersionDesignLayersForm',
  props: {
    onEdit: { type: Boolean },
    designId: {}
  },
  setup(__props: any) {

const props = __props;

const store = useStore();

// Inizialize values for new desing
const layers = computed(() => !props.onEdit ? store.state['programVersionDetailModule'].newDesign.layers : store.state['programVersionDetailModule'].designForEdition.layers);
const layersToDelete = computed(() => store.state['programVersionDetailModule'].layersToDelete );

const inizializeLayers = () => !layers.value.length ? addLayer() : [];

const layerToPreview = computed(() => props.onEdit ? store.state['programVersionDetailModule'].designForEdition.layers.filter((layer: layerDataInterface) => !layersToDelete.value.includes(layer.id)) : []);
const layersData = computed<layerDataInterface[]>(() => layerToPreview.value.length ? layerToPreview.value.map((layer: LayersFromBEInterface, index: number) => ({
    thickness: layer.visualThickness ? layer.visualThickness : calcThickness(layer.thicknessValue1),
    color: layer.colorCode ? layer.colorCode : null,
    id: layer.id,
    position: layer.layerOrder ? layer.layerOrder : index,
})) : null)


const addLayer = (position?: string) => {
    const newLayer = ref<LayerCreateInterface>({
        layerType: null,
        thicknessValueType: null,
        thicknessValue1: null,
        thicknessValue2: null,
        thicknessUnitType: null,
        asset: null,
        description: null,
        visualThickness: props.onEdit ? 2 : null,
        colorCode: props.onEdit ? '#548fb9' : null,
        layerOrder: null
    })
    position && position == 'top' ? layers.value.unshift(newLayer.value) : layers.value.push(newLayer.value);
}
const removeLayer = (index: number) => layers.value.splice(index, 1);

const addLayerToRemove = (id: number, index: number) => {
    layerToPreview.value.splice(index, 1);
    layersToDelete.value.push(id);
}

const removeLayerToRemove = (id: number) => layersToDelete.value.splice(layersToDelete.value.indexOf(id), 1)

// Inizialize the form
const { result } = useQuery(NEW_PROGRAM_VERSIONS_DESIGN_QUERIES);
const inizilizedData = computed(() => result?.value ?? null);
const units = computed(() => inizilizedData.value && inizilizedData.value.unitTypes ? inizilizedData.value.unitTypes.edges.map(edge => edge.node) : []);
const valueTypes = computed(() => inizilizedData.value && inizilizedData.value.valueTypes ? inizilizedData.value.valueTypes.edges.map(edge => edge.node) : []);
const layerTypes = computed(() => inizilizedData.value && inizilizedData.value.layerTypes ? inizilizedData.value.layerTypes.edges.map(edge => edge.node) : []);
const assets = computed<DropdownSearchableOptionInterface[]>(() => inizilizedData.value && inizilizedData.value.assets ? inizilizedData.value.assets.edges.map(({ node: { nickname: name, ...rest } }) => ({ name, id: rest.id })) : []);

const isValueTypeBetween = (valueTypeId: number) => valueTypes.value.find((valueType: ValueTypeInterface) => valueType.id == valueTypeId).name == "Between"

onMounted(() => {
    if (!props.onEdit) {
        inizializeLayers();
    }
})

return (_ctx: any,_cache: any) => {
  return (layers.value && inizilizedData.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[7] || (_cache[7] = _createElementVNode("p", { class: "px-6 cards-title text-neutral-500" }, "Layers layout", -1)),
              (!_ctx.onEdit)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "flex items-center pr-6 cursor-pointer",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (addLayer()))
                  }, [
                    _cache[3] || (_cache[3] = _createElementVNode("label", { class: "text-neutral-400 mr-2 cursor-pointer" }, "Add new layer ", -1)),
                    _createVNode(_unref(PlusCircleIcon), { class: "icon icon-small" })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.onEdit)
                ? (_openBlock(), _createBlock(_unref(Popover), {
                    key: 1,
                    class: "flex justify-end relative pr-6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(PopoverButton), { class: "flex items-center" }, {
                        default: _withCtx(() => [
                          _cache[4] || (_cache[4] = _createElementVNode("label", { class: "text-neutral-400 mr-2 cursor-pointer" }, "Add new layer ", -1)),
                          _createVNode(_unref(PlusCircleIcon), { class: "icon icon-small" })
                        ]),
                        _: 1
                      }),
                      _createVNode(_unref(PopoverPanel), { class: "absolute z-10 bg-white rounded top-8 left-0 border-gray-200 border min-w-32" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("div", {
                              onClick: _cache[1] || (_cache[1] = ($event: any) => (addLayer('top'))),
                              class: "py-2 pl-3 pr-6 font-normal text-xs cursor-pointer flex items-center hover:bg-gray-100"
                            }, [
                              _createVNode(_unref(ArrowUpIcon), { class: "icon icon-x-small thick mr-2" }),
                              _cache[5] || (_cache[5] = _createElementVNode("p", null, "Top", -1))
                            ]),
                            _createElementVNode("div", {
                              onClick: _cache[2] || (_cache[2] = ($event: any) => (addLayer())),
                              class: "py-2 pl-3 pr-6 font-normal text-xs cursor-pointer flex items-center hover:bg-gray-100"
                            }, [
                              _createVNode(_unref(ArrowDownIcon), { class: "icon icon-x-small thick mr-2" }),
                              _cache[6] || (_cache[6] = _createElementVNode("p", null, "Bottom", -1))
                            ])
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            (_ctx.onEdit && layersData.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(LayersDesignGenerator, {
                    layersData: layersData.value,
                    class: "design-draw"
                  }, null, 8, ["layersData"])
                ]))
              : _createCommentVNode("", true),
            (layers.value.length > 1 && !_ctx.onEdit)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_unref(InformationCircleIcon), { class: "icon icon-small thick mr-2" }),
                  _cache[8] || (_cache[8] = _createElementVNode("p", { class: "text-xs" }, "Layer number 1 is going to be the upper layer in the design", -1))
                ]))
              : _createCommentVNode("", true)
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(layers.value, (layer, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: _normalizeClass(["flex mt-6 items-center justify-center border-dashed border-b relative", {'to-delete': _ctx.onEdit && layersToDelete.value.includes(layer.id)}])
            }, [
              (index > 0 && !_ctx.onEdit)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "absolute top-0 right-0 mr-6",
                    onClick: ($event: any) => (removeLayer(index))
                  }, [
                    _createVNode(_unref(XCircleIcon), { class: "icon icon-small thick" })
                  ], 8, _hoisted_8))
                : _createCommentVNode("", true),
              (_ctx.onEdit && layersToDelete.value.includes(layer.id))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: "absolute top-0 right-0 mr-6",
                    onClick: ($event: any) => (removeLayerToRemove(layer.id))
                  }, [
                    _createVNode(_unref(ArrowUturnLeftIcon), { class: "icon icon-small thick" })
                  ], 8, _hoisted_9))
                : _createCommentVNode("", true),
              (_ctx.onEdit && !layersToDelete.value.includes(layer.id))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: "absolute top-0 right-0 mr-6",
                    onClick: ($event: any) => (addLayerToRemove(layer.id, index))
                  }, [
                    _createVNode(_unref(XCircleIcon), { class: "icon icon-small thick" })
                  ], 8, _hoisted_10))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(["flex flex-col pl-6 py-4 w-5/6", {'opacity-50': _ctx.onEdit && layersToDelete.value.includes(layer.id)}])
              }, [
                _createElementVNode("div", _hoisted_11, [
                  _cache[9] || (_cache[9] = _createElementVNode("label", { class: "w-1/3 text-right" }, "Layer type: *", -1)),
                  _withDirectives(_createElementVNode("select", {
                    "onUpdate:modelValue": ($event: any) => ((layer.layerType) = $event),
                    class: "w-2/3 ml-3"
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(layerTypes.value, (layerType) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: layerType.id,
                        value: layerType.id
                      }, _toDisplayString(layerType.name), 9, _hoisted_13))
                    }), 128))
                  ], 8, _hoisted_12), [
                    [_vModelSelect, layer.layerType]
                  ])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _cache[10] || (_cache[10] = _createElementVNode("label", { class: "w-1/3 text-right" }, "Thickness value type: *", -1)),
                  _withDirectives(_createElementVNode("select", {
                    "onUpdate:modelValue": ($event: any) => ((layer.thicknessValueType) = $event),
                    class: "w-2/3 ml-3"
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(valueTypes.value, (valueType) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: valueType.id,
                        value: valueType.id
                      }, _toDisplayString(valueType.description), 9, _hoisted_16))
                    }), 128))
                  ], 8, _hoisted_15), [
                    [_vModelSelect, layer.thicknessValueType]
                  ])
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _cache[12] || (_cache[12] = _createElementVNode("label", { class: "w-1/3 text-right" }, "Value: *", -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: "w-2/3 ml-3",
                    type: "text",
                    "onUpdate:modelValue": ($event: any) => ((layer.thicknessValue1) = $event)
                  }, null, 8, _hoisted_18), [
                    [
                      _vModelText,
                      layer.thicknessValue1,
                      void 0,
                      { number: true }
                    ]
                  ]),
                  (layer.thicknessValueType && isValueTypeBetween(layer.thicknessValueType))
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _cache[11] || (_cache[11] = _createElementVNode("label", { class: "w-1/3 text-right" }, "Value 2: ", -1)),
                        _withDirectives(_createElementVNode("input", {
                          class: "w-2/3 ml-3",
                          type: "text",
                          "onUpdate:modelValue": ($event: any) => ((layer.thicknessValue2) = $event)
                        }, null, 8, _hoisted_19), [
                          [
                            _vModelText,
                            layer.thicknessValue2,
                            void 0,
                            { number: true }
                          ]
                        ])
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _cache[13] || (_cache[13] = _createElementVNode("label", { class: "w-1/3 text-right" }, "Unit: *", -1)),
                  _withDirectives(_createElementVNode("select", {
                    "onUpdate:modelValue": ($event: any) => ((layer.thicknessUnitType) = $event),
                    class: "w-2/3 ml-3"
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(units.value, (unit) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: unit.id,
                        value: unit.id
                      }, _toDisplayString(unit.abbreviation) + " - " + _toDisplayString(unit.name), 9, _hoisted_22))
                    }), 128))
                  ], 8, _hoisted_21), [
                    [_vModelSelect, layer.thicknessUnitType]
                  ])
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _cache[14] || (_cache[14] = _createElementVNode("label", { class: "w-1/3 text-right" }, "Technology:", -1)),
                  _createElementVNode("div", _hoisted_24, [
                    _createVNode(DropdownSearchableSingle, {
                      modelValue: layer.asset,
                      "onUpdate:modelValue": ($event: any) => ((layer.asset) = $event),
                      "options-list": assets.value
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options-list"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _cache[15] || (_cache[15] = _createElementVNode("label", { class: "w-1/3 text-right" }, "Description:", -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: "w-2/3 ml-3",
                    type: "text",
                    "onUpdate:modelValue": ($event: any) => ((layer.description) = $event)
                  }, null, 8, _hoisted_26), [
                    [_vModelText, layer.description]
                  ])
                ]),
                (_ctx.onEdit)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                      _createElementVNode("div", _hoisted_28, [
                        _cache[16] || (_cache[16] = _createElementVNode("label", { class: "text-right" }, "Design Thickness:", -1)),
                        _withDirectives(_createElementVNode("input", {
                          class: "ml-3 mr-4",
                          type: "number",
                          "onUpdate:modelValue": ($event: any) => ((layer.visualThickness) = $event)
                        }, null, 8, _hoisted_29), [
                          [_vModelText, layer.visualThickness]
                        ])
                      ]),
                      (_ctx.onEdit)
                        ? (_openBlock(), _createBlock(_unref(Popover), {
                            key: 0,
                            class: "flex justify-end relative"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(PopoverButton), { class: "flex items-center" }, {
                                default: _withCtx(() => [
                                  _cache[17] || (_cache[17] = _createElementVNode("label", { class: "text-right ml-4 mr-4" }, "Color:", -1)),
                                  _createElementVNode("div", {
                                    class: "flex-shrink-0 color-dot mr-3",
                                    style: _normalizeStyle({backgroundColor: layer.colorCode })
                                  }, null, 4)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_unref(PopoverPanel), { class: "absolute z-10" }, {
                                default: _withCtx(() => [
                                  _createVNode(_unref(Vue3ColorPicker), {
                                    modelValue: layer.colorCode,
                                    "onUpdate:modelValue": ($event: any) => ((layer.colorCode) = $event),
                                    mode: "solid",
                                    "show-alpha": false,
                                    showInputSet: false,
                                    showInputMenu: false,
                                    type: "HEX"
                                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                _cache[18] || (_cache[18] = _createElementVNode("div", null, null, -1))
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass([{'opacity-0': _ctx.onEdit}, "w-1/6 pr-6 text-center number"])
              }, _toDisplayString(index + 1), 3)
            ], 2))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})