<template>
    <div class="title-menu-header">
        <h1 class="view-title">{{ title }}</h1>
        <div class="action-icons flex">
            <div v-if="!editingOnTable" class="round-action-icon" @click="handleEditOnTable">
                <PaintBrushIcon class="icon icon-small" />
            </div>
            <div v-else class="round-action-icon pink" @click="handleEditOnTable">
                <CheckIcon class="icon icon-small" />
            </div>
            <div class="round-action-icon" @click="isBenchmarkFormOpen = !isBenchmarkFormOpen">
                <PencilIcon class="icon icon-small" />
            </div>
            <div class="round-action-icon" @click="isSaveModalOpen = !isSaveModalOpen">
                <BookmarkIcon class="icon icon-small" />
            </div>
            <div class="round-action-icon" @click="handleBack">
                <ArrowUturnLeftIcon class="icon icon-small" />
            </div>
        </div>
        
    </div>
    
    <ProgramVersionBenchmarkForm :is-open="isBenchmarkFormOpen" @close-modal="isBenchmarkFormOpen = false" :is-on-edit="true" />
    <ModalSave :is-open="isSaveModalOpen" @close-modal="isSaveModalOpen = false" /> 
</template>
<script setup lang="ts">
import { ArrowUturnLeftIcon, PaintBrushIcon, PencilIcon, CheckIcon, BookmarkIcon } from '@heroicons/vue/24/outline';
import ProgramVersionBenchmarkForm from '@/components/ProgramVersionBenchmarkForm.vue';
import ModalSave from '@/components/ModalSave.vue';
import { defineProps, ref, defineEmits } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter()

defineProps(['title']);
const emit = defineEmits(['toogleEditOnTable']);

const handleEditOnTable = () => {
    editingOnTable.value = !editingOnTable.value;
    emit('toogleEditOnTable')
}

const handleBack = () => {
    if(router.options.history.state.back.toString().includes('create')) {
        router.go(-2)
    } else router.back();
}

const editingOnTable = ref(false);
const isBenchmarkFormOpen = ref(false);
const isSaveModalOpen = ref(false);

</script>
