<template>
    <div class="mb-4">
        <Chart :options="chartOptions" />
    </div>
</template>
<script setup>
import {defineProps, computed} from "vue";
import { Chart } from 'highcharts-vue'
import Highcharts from 'highcharts';
import accessibilityInit from 'highcharts/modules/accessibility'; // Import the accessibility module
import exportingInit from 'highcharts/modules/exporting';

// Initialize the accessibility module
accessibilityInit(Highcharts);
// Initialize the exporting module
exportingInit(Highcharts);


const props = defineProps(['data'])
const chartData = computed(()=>{
  return props.data;
})
const chartOptions = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Minimum selling price of the main compound',
    },
    subtitle:{text:'On top of the bars the minimum selling price of the main compound is displayed, together with the first year of positive NPV'},
    xAxis: {
      categories: props.data.map(item=>item.name),
      crosshair: true,
      title:{
        text:"run (scenario)"
      }
    },
    yAxis: {
      title: {
        text: '€',
      },
    },
    series: [{
      name:"Min Sell Price",
      data:props.data
    }],
    exporting: {
      enabled: true,
    },
    plotOptions:{
        column:{
            color:'#CA4124',
            dataLabels: {
              enabled: true,
              inside: false, // Set to true if you want labels inside the column
              format:'{y} €, {point.year}y'
            },
        }
    },
    legend:{
      enabled:false
    },
  };
</script>