<template>
    <div>
      <div class="title-menu-header mb-0">
        <h1 class="view-title">{{ title }}</h1>
        <div class="action-icons">
          <div v-if="!editModeOn" class="round-action-icon" @click="handleBack"> 
            <ArrowUturnLeftIcon class="icon icon-small"  />
          </div> 
          <div v-if="!editModeOn" class="round-action-icon" @click="handleEdit"> 
            <PencilIcon  class="icon icon-small" />
          </div>
          <div v-else class="flex items-center"> 
          <!-- To Define - Maybe store this asset instead of removal
          <div class="round-action-icon danger tooltip mr-2" @click="deleteAsset"> 
            <span class="tooltiptext danger">Remove asset from repository</span>
            <TrashIcon class="icon icon-small"  />
          </div> -->
            <button class="header-action-button action" @click="saveChanges"><CheckIcon class="icon icon-small" />Save changes</button>
            <button class="header-action-button cancel" @click="cancelChanges"><XMarkIcon class="icon icon-small" />Cancel</button>
          </div>
        </div>
      </div>
    <AvatarsActivity :orientation="'left'" :users-activity="usersActivity" />
  </div>
</template>
<script setup lang="ts">
import router from '@/router';
import { ArrowUturnLeftIcon, PencilIcon, TrashIcon, CheckIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { computed, defineProps } from 'vue';
import { useStore } from 'vuex';
import AvatarsActivity from '@/components/AvatarsActivity.vue';

defineProps(['title', 'usersActivity']);
const store = useStore();
const editModeOn = computed(() => store.getters["assetDetailModule/isAssetMainEditable"]);
const assetType = computed(() => store.getters["assetDetailModule/getAssetType"]);

const handleBack = () => {
  router.back();
  store.commit('assetDetailModule/setEditableMainOff'); router.push({path:'/technology-assets-repo'})};
const handleEdit = () => store.commit('assetDetailModule/setEditableOn', {sectionToMakeEditbale: 'main'});
// To defined - Store or remove asset
// const deleteAsset = () => console.log('delete');

const saveChanges = async () => await store.dispatch(`assetDetailModule/${assetType.value}UpdatePartial`, {type:'mainContent'});
const cancelChanges = () => store.commit('assetDetailModule/setEditableMainOff');

</script>
