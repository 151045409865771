import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "title-menu-header" }
const _hoisted_2 = { class: "action-icons" }

import { ArrowUturnLeftIcon, PlusIcon } from '@heroicons/vue/24/outline';
import ProductBriefForm from './ProductBriefForm.vue';
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductBriefHeader',
  setup(__props) {

const openNewForm = ref(false);
const disableAddButton = ref(false);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "view-title" }, "PRODUCT Briefs", -1)),
      _createElementVNode("div", _hoisted_2, [
        (!disableAddButton.value)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "round-action-icon",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (openNewForm.value = openNewForm.value))
            }, [
              _createVNode(_unref(PlusIcon), { class: "icon icon-small" })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "round-action-icon",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.go(-1)))
        }, [
          _createVNode(_unref(ArrowUturnLeftIcon), { class: "icon icon-small" })
        ])
      ])
    ]),
    _createVNode(ProductBriefForm, {
      "is-open": openNewForm.value,
      onCloseNew: _cache[2] || (_cache[2] = ($event: any) => (openNewForm.value = false))
    }, null, 8, ["is-open"])
  ], 64))
}
}

})