import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "page-padding" }
const _hoisted_2 = {
  key: 0,
  class: "loading-gif"
}
const _hoisted_3 = { key: 1 }

import { ref } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useRoute } from 'vue-router';
import { PRODUCT_BRIEF_QUERY  } from '@/grapql/productBriefQuery';
import TitleGoBackHeader from '@/components/TitleGoBackHeader.vue';
import ProductBriefOverview from '@/components/ProductBriefOverview.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductBriefDetail',
  setup(__props) {

const route = useRoute();
const briefId = route.params.id;

const brief = ref(null);

const { onResult, loading } = useQuery(PRODUCT_BRIEF_QUERY, { id: briefId });

onResult(queryResult => {
    if(queryResult.data && queryResult.data.productBrief) {
        brief.value = queryResult?.data.productBrief;
    }
    
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(loading))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : (brief.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(TitleGoBackHeader, {
              title: brief.value.product
            }, null, 8, ["title"]),
            _createVNode(ProductBriefOverview, { "product-brief": brief.value }, null, 8, ["product-brief"])
          ]))
        : _createCommentVNode("", true)
  ]))
}
}

})