<template>
    <div class="edit-assessment-buttons"> 
        <div @click="handleSaveChanges" class="on-green"><CheckIcon class="icon"/><p>Save Changes</p></div>
        <div @click="cancelEdition"><XMarkIcon class="icon"/><p>cancel</p></div> 
    </div>  
    <template v-for="field in assetAssessmentHighLevelData.fields" :key="field.title" >
        <!-- Number type input -->
        <div v-if="field.inputType && field.inputType === 'number'" class="mb-4 mt-2">
            <label>{{field.title}}</label>
            <input class="w-full" type="number" min="1" max="9" @input="handleNewValue($event, field.mutationValue)" :value="field.content">                    
        </div>
        <!-- Select type input -->
        <div v-if="field.inputType === 'select'" class="mb-4 mt-3"> 
            <label>{{field.title}}</label> 
            <select class="w-full" @input="handleNewValue($event, field.mutationValue)" :value="getStatusValueId(field.content)" v-if="optionsListForDropdown[field.queryOptions]">
                <option v-for="option in optionsListForDropdown[field.queryOptions]" :value="option.id" :key="option.id">{{option.name}}</option>
            </select>
        </div>
        <!-- Rich text area -->
        <div v-if="field.inputType === 'textarea'" class="mb-3 assessment-editor">
            <label>{{field.title}}</label>
            <QuillEditor :content="field.content.toString()" content-type="html" @ready="onEditorReady($event)" @update:content="onEditorUpdate($event, field.mutationValue)" />
        </div>
        <!-- Multiselect type input -->
        <div v-if="field.inputType && field.inputType === 'multipleSelect'" class="mb-4">
            <div v-if="field.mutationValue === 'tags'">
                <div class="label-with-icon">
                    <label>{{field.title}}</label>
                    <span @click="showTagInput = true" class="icon-span">Create new tag +</span>
                </div>
                <div v-if="showTagInput" class="input-with-tooltip mb-4"> 
                    <input  class="w-full" type="text" v-model="tempNewTag" @keyup.enter="(event) => {handleNewTag(event); tempNewTag = ''}" />
                    <div v-if="showTagInput" class="tooltip">
                        <InformationCircleIcon class="icon" />
                        <span class="tooltiptext assess-info">Type the new tag and press enter to create and add it</span>
                    </div>
                </div>
                <div class="assessment-select"> 
                    <DropdownSearchableMultiple v-if="optionsListForDropdown[field.queryOptions] && vModels[field.mutationValue] !== undefined" :options-list="optionsListForDropdown[field.queryOptions]" v-model="vModels[field.mutationValue].value" :select-other-than-id="'name'"/>
                </div>
            </div>
            <div v-else> 
                <label>{{field.title}}</label> 
                <div class="assessment-select"> 
                    <DropdownSearchableMultiple v-if="optionsListForDropdown[field.queryOptions] && vModels[field.mutationValue] !== undefined" :options-list="optionsListForDropdown[field.queryOptions]" v-model="vModels[field.mutationValue].value" />
                </div>
            </div>
        </div>
    </template>
    <label>files</label>
    <AssetFilesListEditable :files-to-show="assetAssessmentHighLevelData.files"/>
</template>

<script setup lang='ts'>
import { computed, defineProps, ref} from 'vue';
import { useStore } from 'vuex';
import { useQuery } from '@vue/apollo-composable';
import { InformationCircleIcon, CheckIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import DropdownSearchableMultiple from '@/components/DropdownSearchableMultiple.vue'
import AssetFilesListEditable from './AssetFilesListEditable.vue';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { AssetAssessmentHighLevel } from '@/models/assetAssessmentInterfaces';
import { ASSESSMENT_HIGH_LEVEL_OPTIONS } from '@/grapql/assessmentHighLevelQueries';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';

const props = defineProps<{ assetAssessmentHighLevelData: AssetAssessmentHighLevel }>()

const store = useStore();
const tempNewTag = '';
const showTagInput = ref(false);

const { result } = useQuery(ASSESSMENT_HIGH_LEVEL_OPTIONS);
const inizilizedData = computed(() => result?.value ?? null );

const assetType = computed(() => store.state.assetDetailModule.assetType);

const fieldsForOptionsList = computed(() => props.assetAssessmentHighLevelData.fields.reduce((acc, obj) => [...acc, obj.queryName], []).filter(value => value !== undefined));
const optionsListForDropdown = computed<{[key: string]: DropdownSearchableOptionInterface[]}>(() => useFormInizializationNewAsset(inizilizedData.value, fieldsForOptionsList.value));

                    
const fieldsForVModels = computed(() => props.assetAssessmentHighLevelData.fields.map(field => { if (field.inputType == 'multipleSelect') { return field.mutationValue }}).filter(value => value !== undefined)) ;

const getStatusValueId = (statusName) => optionsListForDropdown.value['assetEvaluationStatusesOptions'].find(status => status.name === statusName).id;

const formFieldOptions = {
    fields: fieldsForVModels.value,
    base: assetType.value,
    mutation: 'assetDetailModule/updateAssessmentHigLevelValue'
};

const formFieldsForSearchableMultipleDropdown = (formFieldOptions) => {
    const object = {};
    formFieldOptions.fields.map(fieldName => {
        object[fieldName] = computed({
            get() {
                return store.state.assetDetailModule.asset[formFieldOptions.base][fieldName] || [];
            },
            set(value) {
                store.commit(formFieldOptions.mutation, { mutationValue: fieldName, content: value});
            }
        })
    });
    return object;
}

const vModels = formFieldsForSearchableMultipleDropdown(formFieldOptions);

const handleNewValue =  (event: Event, mutationValue:string) => {
    const content = (event.target as HTMLFormElement).value;
    store.commit('assetDetailModule/updateAssessmentHigLevelValue', {mutationValue: mutationValue, content: Number(content) })
}

const handleNewTag = (event: Event) => {
    const newTagValue = (event.target as HTMLInputElement).value;
    const storeTagNewValues = [...store.state.assetDetailModule.asset[formFieldOptions.base]['tags'], newTagValue];
    store.commit('assetDetailModule/updateAssessmentHigLevelValue', {mutationValue: 'tags', content: storeTagNewValues })
};

const handleSaveChanges = async () => {
    await store.dispatch(`assetDetailModule/${assetType.value}UpdatePartial`, {type:'assessmentHighLevel'});
    store.commit('assetDetailModule/setEditableAssessmentHighLevelOff')
}

const onEditorReady = (event: Event) => { return event };

const onEditorUpdate =  (event:Event, mutationValue:string) => {
    store.commit('assetDetailModule/updateAssessmentHigLevelValue', {mutationValue: mutationValue, content: event });
}

const cancelEdition = () => store.commit('assetDetailModule/setEditableAssessmentHighLevelOff');



</script>
<style lang='scss' scoped>
label {
    font-weight: 500;
    text-transform: uppercase;
    color: $font-color-light;
    margin-bottom: 0rem;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
}

.input-with-tooltip {
    position: relative;
    margin-top: 0.5rem;
    .tooltip {
        position: absolute;
        top: 2px;
        right: 6px;
        .icon {
            width: 20px;
            display: inline-block;
            cursor: pointer;
        }
    }
}  
</style>