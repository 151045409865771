<template>
    <div class="flex items-center pl-4 mb-4">
        <p>Select assets by layer functionality: </p> 
    </div> 
    <div v-if="layerTypes" class="flex px-4 flex-wrap"> 
        <div v-for="layer in layerTypes" :key="layer.id"> 
            <input type="checkbox" v-model="selectedLayerType" @change="setValueInStore" :value="layer.name" :id="layer.name" class="hidden peer">
            <label :for="layer.name" class="transition-colors duration-200 ease-in-out peer-checked:bg-red-300 peer-checked:text-white cursor-pointer border m-1 px-3 py-2 rounded-lg" >  {{ layer.name }} </label>
       </div>
    </div>
</template>
<script setup lang="ts">
import { LAYER_TYPES }  from '@/grapql/assetsComparisonCriteriaOptions';
import { useQuery } from '@vue/apollo-composable';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

export interface LayerTypeInterface {
    id: number, 
    name: string
}

const store = useStore();
const { result } = useQuery(LAYER_TYPES);
const queryResult = computed(() => result?.value ?? null);
const layerTypes = computed<LayerTypeInterface[]>(() => queryResult.value && queryResult.value.layerTypes.edges.length ? queryResult.value.layerTypes.edges.map((edge) => edge.node) : null)

const selectedLayerType = ref([]);

const setValueInStore = () => store.commit('assetsModule/setSelectedLayerTypeForFilter', selectedLayerType.value );

onMounted(() => {
    const statusFromStore = store.state.assetsModule.selectedLayerTypeForFilter;
    selectedLayerType.value = statusFromStore
})
</script>
<style lang="scss">
   
</style>