import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "program-detail-card mr-4 mb-4" }
const _hoisted_2 = { class: "target-value-table pb-3" }
const _hoisted_3 = { class: "targets" }
const _hoisted_4 = { class: "values" }
const _hoisted_5 = ["href"]


import MaterialTargetTable from '@/components/MaterialTargetTable.vue';
import { TargetInterface } from '@/models/programVersionsInterface';

interface TargetMockupInterface {
    value: string,
    label: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramVersionTargetsCard',
  props: {
    productBrief: {},
    programTargets: {},
    programVersionTargets: {}
  },
  setup(__props: any) {






return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("p", { class: "cards-title" }, "properties", -1)),
    (_ctx.programTargets)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.programTargets, (target) => {
              return (_openBlock(), _createElementBlock("div", {
                key: target.label,
                class: "flex items-center"
              }, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(target.label), 1),
                _createElementVNode("div", _hoisted_4, _toDisplayString(target.value), 1)
              ]))
            }), 128))
          ]),
          (_ctx.productBrief != '')
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: _ctx.productBrief,
                target: "_blank",
                class: "underline underline-offset-4 text-sm"
              }, _cache[0] || (_cache[0] = [
                _createTextVNode("Product Brief - "),
                _createElementVNode("span", null, "MUST HAVE", -1)
              ]), 8, _hoisted_5))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.programVersionTargets)
      ? (_openBlock(), _createBlock(MaterialTargetTable, {
          key: 1,
          "target-data": _ctx.programVersionTargets
        }, null, 8, ["target-data"]))
      : _createCommentVNode("", true)
  ]))
}
}

})