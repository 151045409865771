<template>
    <div class="layer-voting-card">
        <div class="design-content">
            <div class="score">
                <p>Success Score</p> 
                <p class="score-value" :style="{color: scoreColor}">{{ prototypeDesignInfo.successScore }}</p>
            </div>
            <div class="prototype" :class="{ 'dislike': isDislike }">
                <LayersDesignGenerator :layers-data="prototypeDesignInfo.layersData.toReversed()" class="cursor-pointer"  @click="goToLink"/>
                <div class="layers-info p-4">
                    <template v-for="(layer, index) in [...prototypeDesignInfo.layersData].toReversed()" :key="layer.id">
                        <p class="uppercase text-sm font-medium">Layer {{ index + 1 }}</p>
                        <p class="font-light text-sm mb-2">{{ layer.layerDescription }} - <span class="font-medium">{{ layer.layerName }} ({{ layer.grammage }})</span></p>
                    </template>
                </div>
            </div> 
            <div class="action-buttons">
                <button @click="handleThumUp" :class="{ 'like': isLike}"><HandThumbUpIcon class="icon icon-small" /></button>
                <button @click="handleThumDown" :class="{ 'dislike': isDislike }"> <HandThumbDownIcon class="icon icon-small" /></button>
            </div>
            <div v-if="isDislike || isLike" class="feedback-input">
                <textarea v-model="feedback" rows="5"  placeholder="Would you like to leave some feedback?"></textarea>
                <div class="cta">
                    <button @click="cancelFeedback">Cancel</button>
                    <button @click="submitFeedback" class="submit">Submit</button>
                </div>
            </div> 
        </div>
    </div>
   
</template>
<script setup lang="ts">
import {computed, defineProps, defineEmits, ref} from 'vue'; 
import LayersDesignGenerator from './LayersDesignGenerator.vue';
import { HandThumbDownIcon, HandThumbUpIcon} from '@heroicons/vue/24/solid';
import { designFeedbackInterface, prototypesDesignsInfoInterface } from '@/models/programsInterface';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import router from '@/router';
import { useInitialsToUserLogo } from '@/composables/useInitialsToUserLogo';

const props = defineProps<{ prototypeDesignInfo: prototypesDesignsInfoInterface }>();
const emit = defineEmits(['feedback']);
const store = useStore();
const route = useRoute();

const user = computed( () => { return store.state.authModule.user });
const initials = computed(() => useInitialsToUserLogo(user.value.firstName, user.value.lastName));
const scoreColor = computed(() => props.prototypeDesignInfo.successScore < 0.5 ? "#acabab" : props.prototypeDesignInfo.successScore <= 0.70 ? "#7d91bc" : "#8bb9a7");
const isLike = ref(false);
const isDislike = ref(false);
const feedback = ref(null);
const feedbackSubmited = ref(false);

const programId = computed(() => route.params.id)

const goToLink = () => props.prototypeDesignInfo.linkId ? router.push({ name: 'ProgramDetailWIP', params: { id: props.prototypeDesignInfo.linkId } }) : null;

const handleThumDown = () => { isDislike.value = !isDislike.value; isLike.value = false; }
const handleThumUp = () => { isLike.value = !isLike.value; isDislike.value = false; }

const cancelFeedback = () => {
    isDislike.value = false; 
    isLike.value = false; 
    feedback.value = null; 
    feedbackSubmited.value = false;
}
const submitFeedback = () => { 
    sendFeedbackToBeStorage();
    cancelFeedback();
}   

const sendFeedbackToBeStorage = () => {
    const feedbackToBeStorage:designFeedbackInterface = {
        programId: programId.value,
        designId: props.prototypeDesignInfo.id,
        feedback: feedback.value,
        who: initials.value
    }
    emit('feedback', feedbackToBeStorage)
}

</script>
<style lang="scss" scoped>
    .layer-voting-card {
        width: 300px;
        display: flex;
        
        &:first-child .design-content{
            border-color: #8bb9a7;
            border-width: 2px;
            background-color: rgb(139 185 167 / 13%);
        }
        .design-content {
            border: $border;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
        }
        
    }
    
    .layers-info {
        border-top: 1px dashed #eee;
    }
    .score {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eee;
        padding: 0 0.5rem;
        text-transform: uppercase;
        font-size: 0.7rem;
        font-weight: 400;
        .score-value {
            color: #5fa2a6;
            font-weight: 900;
            font-size: 2rem;
        }
    }
    .action-buttons {
        margin-top: auto;
        border-top: $border;
        display: flex;
        align-items: center;
        > button {
            width: 50%;
            text-transform: uppercase;
            padding: 0.8rem 0;
            > svg {
                display: inline-flex;
                fill: #fff;
            }
            &:first-child {
                border-right: $border;
            }
            &.like {
                background-color: rgb(0 160 110 / 18%);
                > svg {
                    fill: $one-five-green;
                    stroke: none;    
                }
            }
            &.dislike {
                background-color: #eee;
                > svg {
                    fill: #999;
                    stroke: none;    
                }
            }
        }
        
    }
    .prototype{
        &.dislike { opacity: 0.6;}
    }
    .feedback-input {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        textarea {
            border: none;
            border-top: $border;
            &:focus {
                border: none;
                border-top: $border;
            }
        }
        .cta {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin: 15px;
            button {
                border-radius: 20px;
                padding: 4px 15px;
                font-size: 0.8rem;
                text-transform: uppercase;
                &.submit {
                    margin-left: 0.5rem;
                    background-color: $one-five-green;
                    color: $grey-super-light;
                }

            }
        }
    }
    
</style>