import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "asset-pt" }
const _hoisted_2 = {
  key: 1,
  class: "flex justify-between items-center flex-wrap mb-4"
}

import { AssetProcessingTechniquePropertyInterface } from '@/models/assetAssessmentInterfaces';
import AssetProcessingTechniqueProperty from './AssetProcessingTechniqueProperty.vue';
import { PlusCircleIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';
import AssetProcessingTechniquePropertyAddForm from './AssetProcessingTechniquePropertyAddForm.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssetProcessingTechniqueProperties',
  props: {
    assetProcessingTechniqueProperties: {},
    assetProcessingTechniqueId: {},
    processingTechniqueId: {}
  },
  setup(__props: any) {


const onAddition = ref(false)


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[2] || (_cache[2] = _createElementVNode("p", null, "processing technique PROPERTIES", -1)),
      (!onAddition.value)
        ? (_openBlock(), _createBlock(_unref(PlusCircleIcon), {
            key: 0,
            class: "icon",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (onAddition.value = true))
          }))
        : _createCommentVNode("", true)
    ]),
    (onAddition.value)
      ? (_openBlock(), _createBlock(AssetProcessingTechniquePropertyAddForm, {
          key: 0,
          onCancelAddition: _cache[1] || (_cache[1] = ($event: any) => (onAddition.value = false)),
          "asset-processing-technique-properties": _ctx.assetProcessingTechniqueProperties,
          "asset-processing-technique-id": _ctx.assetProcessingTechniqueId,
          "processing-technique-id": _ctx.processingTechniqueId
        }, null, 8, ["asset-processing-technique-properties", "asset-processing-technique-id", "processing-technique-id"]))
      : _createCommentVNode("", true),
    (!!_ctx.assetProcessingTechniqueProperties.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assetProcessingTechniqueProperties, (property) => {
            return (_openBlock(), _createBlock(AssetProcessingTechniqueProperty, {
              key: property.id,
              "asset-processing-technique-property": property,
              "asset-processing-technique-id": _ctx.assetProcessingTechniqueId,
              "processing-technique-id": _ctx.processingTechniqueId
            }, null, 8, ["asset-processing-technique-property", "asset-processing-technique-id", "processing-technique-id"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})