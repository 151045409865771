<template>
   <template v-if="!onEdit">
      <div class="w-full">
      <div class="flex">
         <p class="label mr-3">
            {{ assetQuantitativeProperty.quantitativeProperty.name }}
            <span v-if="assetQuantitativeProperty.quantitativeProperty.category">({{
            assetQuantitativeProperty.quantitativeProperty.category }})</span>
         </p>
         <PencilSquareIcon class="icon" @click="onEdit = true" v-if="!onEdit"/>
      </div>
      <!-- Different displays based on unit type -->
      <template v-if="assetQuantitativeProperty.valueType">
         <p v-if="assetQuantitativeProperty.valueType.name == 'Between'" class="value">
            {{ assetQuantitativeProperty.value1 }} - {{ assetQuantitativeProperty.value2 }}
            <span v-if="assetQuantitativeProperty.valueUnit" class="lowercase">{{
               assetQuantitativeProperty.valueUnit.abbreviation }}</span>
         </p>
         <template v-else-if="assetQuantitativeProperty.valueType.name == 'Boolean'">
            <p v-if="assetQuantitativeProperty.value1 && assetQuantitativeProperty.value1 == 'true'" class="value">Yes</p>
            <p v-else class="value">No</p>
         </template>
         <p v-else class="value">
            {{ assetQuantitativeProperty.valueType.symbol }} {{ assetQuantitativeProperty.value1 }}
            <span v-if="assetQuantitativeProperty.valueUnit" class="lowercase">{{
               assetQuantitativeProperty.valueUnit.abbreviation }}</span>
         </p>
      </template>
      <p v-else class="value">
         {{ assetQuantitativeProperty.value1 }}
         <span v-if="assetQuantitativeProperty.valueUnit" class="lowercase">{{
            assetQuantitativeProperty.valueUnit.abbreviation }}</span>
      </p>
   </div>
   <div class="w-full" v-if="assetQuantitativeProperty.extraInformation">
      <p class="label">Extra Information</p>
      <p class="text-sm italic mb-4" v-html=" assetQuantitativeProperty.extraInformation"></p>
   </div>   
   </template>
   <template v-else>
      <AssetQuantitativePropertyEditForm :asset-quantitative-property="assetQuantitativeProperty" @cancel-edition="onEdit = false"/>
      
   </template>
   
</template>
<script setup lang="ts">
import { AssetQuantitativePropertyInterface } from '@/models/assetAssessmentInterfaces';
import { defineProps, ref } from 'vue';
import { PencilSquareIcon } from '@heroicons/vue/24/outline';
import AssetQuantitativePropertyEditForm from './AssetQuantitativePropertyEditForm.vue';


defineProps<{ 
   assetQuantitativeProperty: AssetQuantitativePropertyInterface; 
}>();

const onEdit = ref(null);

</script>

