import { useStore } from "vuex";
import { computed } from "vue";

export const useFormFieldsCreationNewProgramAndVersion = (formFieldOptions: {
  fields: string[];
  base: string;
  mutation: string;
}) => {
  const store = useStore();
  const object = {};

  formFieldOptions.fields.map((fieldName) => {
    object[fieldName] = computed({
      get() {
        return store.state.programsCreationModule[formFieldOptions.base][
          fieldName
        ];
      },
      set(value) {
        store.commit(formFieldOptions.mutation, {
          programField: fieldName,
          value: value,
        });
      },
    });
  });

  return object;
};

export const useFormFieldsToEditProgramAndVersion = (formFieldOptions: {
  fields: string[];
  base: string;
  mutation: string;
}) => {
  const store = useStore();
  const object = {};

  formFieldOptions.fields.map((fieldName) => {
    object[fieldName] = computed({
      get() {
        return store.state.programVersionDetailModule[formFieldOptions.base][
          fieldName
        ];
      },
      set(value) {
        store.commit(formFieldOptions.mutation, {
          programField: fieldName,
          value: value,
        });
      },
    });
  });

  return object;
}