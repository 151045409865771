import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "ai-history" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "view-title mb-4 flex justify-between items-center" }
const _hoisted_4 = { class: "text-sm" }
const _hoisted_5 = { class: "main-content" }

import {computed} from "vue";
import { useStore } from 'vuex';
import {v4 as uuid} from 'uuid';
import AIStart from "@/components/ai/AIStart.vue";
import AIChat from "@/components/ai/AIChat.vue";
import { ChevronDoubleRightIcon,ChevronDoubleLeftIcon } from '@heroicons/vue/24/solid';
import { CpuChipIcon } from '@heroicons/vue/24/outline';
import AIChatsHistory from "@/components/ai/AIChatsHistory.vue";
import AIChatHeader from "@/components/ai/AIChatHeader.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AIView',
  setup(__props) {

const store = useStore();

// get and check if AI is started (question(s) asked)
const showStart = computed(()=> !store.state.aiModule.isStarted);

// set subscription id
store.commit("aiModule/setSubscriptionId",uuid());

// subscribe to ai response
store.dispatch("aiModule/subscribe");


const historyPannelOpen = computed(() => store.getters['aiModule/getHistoryPannelStatus']);
const toggleHistoryPannel = () => store.commit('aiModule/toggleHistoryPannel')

const currentChatId = computed(()=> store.state.aiModule.currentChatId);



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ai-container", {'pannel-close': !historyPannelOpen.value}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (!historyPannelOpen.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "history-close",
            onClick: toggleHistoryPannel
          }, [
            _createVNode(_unref(ChevronDoubleRightIcon), { class: "icon icon-small internal-pannel" }),
            _cache[0] || (_cache[0] = _createElementVNode("p", null, "AI CHATS", -1))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("h1", _hoisted_3, [
              _createElementVNode("span", null, [
                _createVNode(_unref(CpuChipIcon), { class: "icon icon-m" })
              ]),
              _createVNode(_unref(ChevronDoubleLeftIcon), {
                class: "icon icon-small internal-pannel",
                onClick: toggleHistoryPannel
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(AIChatsHistory)
            ])
          ]))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(AIChatHeader),
      (showStart.value && !currentChatId.value)
        ? (_openBlock(), _createBlock(AIStart, { key: 0 }))
        : (_openBlock(), _createBlock(AIChat, {
            key: 1,
            currentChatId: currentChatId.value
          }, null, 8, ["currentChatId"]))
    ])
  ], 2))
}
}

})