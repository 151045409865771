import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "p-4" }
const _hoisted_2 = { class: "capitalize" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "ml-6" }
const _hoisted_6 = {
  key: 0,
  class: "ml-6"
}
const _hoisted_7 = { class: "pl-6" }

import { computed} from "vue";
import { useStore } from "vuex";




export default /*@__PURE__*/_defineComponent({
  __name: 'ResearchPaperAutoFillExtractInfo',
  setup(__props) {

const store = useStore();

const extractResult = computed(()=> store.state.researchPaperAutofillModule.extractResult);
const filteredResult = computed(()=> {
    const {tempUuid, uid, ...result} = extractResult.value;
    return result;
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("dl", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredResult.value, (value, key) => {
        return (_openBlock(), _createElementBlock("div", { key: key }, [
          _createElementVNode("dt", _hoisted_2, [
            _createElementVNode("strong", null, _toDisplayString(key) + ":", 1)
          ]),
          (key.toString() != 'authors')
            ? (_openBlock(), _createElementBlock("dd", _hoisted_3, _toDisplayString(value), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("dl", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value, (author, akey) => {
                    return (_openBlock(), _createElementBlock("div", { key: akey }, [
                      _createElementVNode("dt", null, [
                        _createElementVNode("strong", null, _toDisplayString(author.firstname) + " " + _toDisplayString(author.lastname), 1)
                      ]),
                      (author.party && author.party?.name)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _cache[0] || (_cache[0] = _createElementVNode("dd", null, [
                              _createElementVNode("strong", null, "Party"),
                              _createTextVNode(":")
                            ], -1)),
                            _createElementVNode("ul", _hoisted_7, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(author.party, (party, pk) => {
                                return (_openBlock(), _createElementBlock("div", { key: pk }, [
                                  _createElementVNode("li", null, [
                                    _createElementVNode("strong", null, _toDisplayString(pk) + ":", 1),
                                    _createTextVNode(" " + _toDisplayString(party), 1)
                                  ])
                                ]))
                              }), 128))
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ])
              ]))
        ]))
      }), 128))
    ])
  ]))
}
}

})