import gql from "graphql-tag";


export const SEARCH_RESEARCH_PAPERS_QUERY = gql`
    query searchResearchPapers($searchField: String!, $searchValue: [String]!) {
        searchResearchPapers (searchField: $searchField, searchValue: $searchValue) {
            uid
            title
            authors
        }
    }
` 




