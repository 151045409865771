<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="$emit('closeModal')" class="relative z-50">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100" leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">
        <div class="backdrop"></div>
      </TransitionChild>
      <div class="fixed inset-0 flex items-center justify-center p-4">
        <DialogPanel class="modal-slots transition-all" :class="dialogClass">
          <header class="modal-header" id="modalTitle">
            <slot name="header"> </slot>
            <XMarkIcon class="icon icon-small thick" @click="handleClose" aria-label="Close Modal"/>
          </header>
          <main class="modal-body" id="modalDescription">
            <slot name="body"> Default body content </slot>
          </main>
          <footer v-if="!hideFooter" class="modal-footer two-buttons">
            <slot name="footer">            
              <button class="button cancel" @click="handleClose">{{cancelText ?? "cancel" }}</button>
              <button class="button bg-black text-white" @click="$emit('clickCreate')" :disabled="createInProgress" :class="{ 'cursor-not-allowed hover:shadow-none opacity-50': createInProgress }">{{createText ?? "create"}}</button>
            </slot>
          </footer>
        </DialogPanel>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script setup lang="ts">
import { TransitionRoot, TransitionChild, Dialog, DialogPanel} from "@headlessui/vue";
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { defineProps, defineEmits } from "vue";

defineProps(['isOpen', 'dialogClass', 'hideFooter','cancelText','createText',"createInProgress"]);
const emits = defineEmits(['closeModal','clickCreate','resetModal'])

const handleClose = ()=>{
  emits('closeModal')
  emits('resetModal')
}
</script>

