<template>
    <div class="form-container">
        <form class="add-asset-form" v-if="formInizializationValues">
            <div class="grid grid-cols-3 gap-y-2.5 gap-x-4">
                <div class="form-separator col-span-3">
                    <p class="form-subtitle">Patent Info</p>
                </div>
                <div :class="{ 'error-form-field': errorsFields.includes('nickname') && formSubmited }">
                    <label>Nickname *</label>
                    <input class="w-full" type="text" v-model="formFields['nickname'].value">
                </div>
                <div class="col-span-2" :class="{ 'error-form-field': errorsFields.includes('title') && formSubmited }">
                    <label>Title *</label>
                    <input class="w-full" type="text" v-model="formFields['title'].value">
                </div>
                <div class="col-span-3 grid"
                    :class="{ 'error-form-field': errorsFields.includes('abstract') && formSubmited }">
                    <label>Abstract *</label>
                    <QuillEditor :content="formFields['abstract'].value ? formFields['abstract'].value : '<p><br></p>'"
                        content-type="html" @update:content="content => formFields['abstract'].value = content"
                        @ready="onEditorReady($event)" />
                </div>
                <div :class="{ 'error-form-field': errorsFields.includes('patentId') && formSubmited }">
                    <label>Patent ID *</label>
                    <input class="w-full" type="text" v-model="formFields['patentId'].value">
                </div>
                <div :class="{ 'error-form-field': errorsFields.includes('status') && formSubmited }">
                    <label>Status *</label>
                    <select class="w-full" v-model="formFields['status'].value">
                        <option selected :value=null></option>
                        <option v-for="status in formInizializationValues['patentStatusesOptions']"
                            :value="Number(status.id)" :key="status.id">{{ status.name }}</option>
                    </select>
                </div>
                <div>
                    <label>Patent IDs <span>(Press Enter to add an ID)</span></label>
                    <input class="w-full" type="text" v-model="tempPatentsIds"
                        @keyup.enter="formFields['patentIds'].value.push(handleInputChange($event)); tempPatentsIds = ''">
                    <div class="selected-results" v-if="formFields['patentIds'].value.length">
                        <span v-for="(link, index) in formFields['patentIds'].value" :key="index">
                            {{ link }}
                            <XMarkIcon class="icon icon-x-small thick inline"
                                @click="formFields['patentIds'].value.splice(index, 1)" />
                        </span>
                    </div>
                </div>
                <div>
                    <label>Sources <span>(One or more)</span></label>
                    <select class="w-full"
                        @input="formFields['sources'].value = useMultipleSelectForSources(Number(handleInputChange($event)), 'add', formFields['sources'].value)">
                        <option selected disabled></option>
                        <option v-for="source in formInizializationValues['sourcesOptions']" :value="source.id"
                            :key="source.id">{{ source.name }}</option>
                    </select>
                    <div class="selected-results" v-if="formFields['sources'].value.length">
                        <template v-for="(sourceOption, index) in formInizializationValues['sourcesOptions']" :key="index">
                            <template v-for="(source, index) in formFields['sources'].value" :key="index">
                                <span v-if="source.source == sourceOption.id">
                                    {{ sourceOption.name }}
                                    <XMarkIcon class="icon icon-x-small thick inline"
                                        @click="formFields['sources'].value = useMultipleSelectForSources(source.source, 'remove', formFields['sources'].value)" />
                                </span>
                            </template>
                        </template>
                    </div>
                </div>
                <div class="col-span-2">
                    <label>Url</label>
                    <input class="w-full" type="text" v-model="formFields['url'].value">
                </div>
                <div class="col-span-2">
                    <label>Links <span>(Press Enter to add a link)</span></label>
                    <input class="w-full" v-model="tempLinks" type="text"
                        @keyup.enter="formFields['links'].value.push(handleInputChange($event)); tempLinks = ''" />
                    <div class="selected-results" v-if="formFields['links'].value.length">
                        <span v-for="(link, index) in formFields['links'].value" :key="index">
                            {{ link }}
                            <XMarkIcon class="icon icon-x-small thick inline"
                                @click="formFields['links'].value.splice(index, 1)" />
                        </span>
                    </div>
                </div>
                <div class="flex mt-4 items-center">
                    <div class="flex mr-4">
                        <input class="shrink-0" type="checkbox" v-model="formFields['isPrepatent'].value" :true-value="true"
                            :false-value=null />
                        <label>Prepatent</label>
                    </div>
                    <div class="flex">
                        <input class="shrink-0" type="checkbox" v-model="formFields['isPrepatent'].value"
                            :true-value="false" :false-value=null />
                        <label>Not Prepatent</label>
                    </div>
                </div>
                <div class="col-span-3 grid">
                    <label>Description</label>
                    <QuillEditor
                        :content="formFields['description'].value ? formFields['description'].value : '<p><br></p>'"
                        content-type="html" @update:content="content => formFields['description'].value = content"
                        @ready="onEditorReady($event)" />
                </div>
                <div class="col-span-3 ">
                    <div class="col-span-3 grid">
                        <div class="label-with-icon">
                            <label>Claims <span>(Click on add button to create a list)</span></label>
                            <span @click="formFields['claims'].value.push(null)" class="icon-span">+ Add claim</span>
                        </div>
                        <QuillEditor
                            :content="formFields['claims'].value[0] ? formFields['claims'].value[0] : '<p><br></p>'"
                            content-type="html" @update:content="content => formFields['claims'].value[0] = content"
                            @ready="onEditorReady($event)" />
                    </div>
                    <div v-for="(claim, index) in formFields['claims'].value" :key="index" class="col-span-3 mb-2">
                        <template v-if="index != 0">
                            <div class="label-with-icon justify-end">
                                <span @click="formFields['claims'].value.splice(index, 1)" class="icon-span remove">x Remove
                                    this claim</span>
                            </div>
                            <QuillEditor v-model:content="formFields['claims'].value[index]" content-type="html"
                                @ready="onEditorReady($event)" />
                        </template>

                    </div>
                </div>
                <div class="form-separator col-span-3">
                    <p class="form-subtitle">Patent Assessment</p>
                </div>

                <div>
                    <label>Materials <span>(One or more)</span></label>
                    <DropdownSearchableMultiple v-if="formInizializationValues['materialsOptions']"
                        :options-list="formInizializationValues['materialsOptions']"
                        v-model="formFields['materials'].value" />
                </div>
                <div>
                    <label>Properties <span>(One or more)</span></label>
                    <DropdownSearchableMultiple v-if="formInizializationValues['propertiesOptions']"
                        :options-list="formInizializationValues['propertiesOptions']"
                        v-model="formFields['properties'].value" />
                </div>
                <div>
                    <label>Applications <span>(One or more)</span> </label>
                    <DropdownSearchableMultiple v-if="formInizializationValues['applicationTypesOptions']"
                        :options-list="formInizializationValues['applicationTypesOptions']"
                        v-model="formFields['applicationTypes'].value" />
                </div>
                <div>
                    <label>Certifications & standards <span>(One or more)</span> </label>
                    <DropdownSearchableMultiple v-if="formInizializationValues['standardsOptions']"
                        :options-list="formInizializationValues['standardsOptions']"
                        v-model="formFields['standards'].value" />
                </div>
                <div>
                    <label>Processing Techniques <span>(One or more)</span> </label>
                    <DropdownSearchableMultiple v-if="formInizializationValues['processingTechniquesOptions']"
                        :options-list="formInizializationValues['processingTechniquesOptions']"
                        v-model="formFields['processingTechniques'].value" />
                </div>
                <!-- <div>
                    <label>Tags <span>(One or more)</span></label>
                    <DropdownSearchableMultiple v-if="formInizializationValues['tagsOptions']"
                        :options-list="formInizializationValues['tagsOptions']" v-model="formFields['tags'].value"
                        select-other-than-id="name" />
                    <div class="label-with-icon">
                        <span @click="showTagInput = true" class="icon-span">+ Create new tag</span>
                        <div v-if="showTagInput" class="tooltip">
                            <InformationCircleIcon class="icon" />
                            <span class="tooltiptext info">Type the new tag and press enter to create and add it</span>
                        </div>
                    </div>
                    <input v-if="showTagInput" class="w-full" type="text" v-model="tempNewTag"
                        @keyup.enter="formFields['tags'].value.push(handleInputChange($event)); tempNewTag = ''" />
                </div> -->
                <div>
                    <label>TRL <span>Number between 1 and 9</span></label>
                    <input class="w-full" type="number" min="1" max="9" v-model.number="formFields['trl'].value">
                </div>
                <div class="col-span-2 row-span-2 grid">
                    <label>Notes</label>
                    <QuillEditor :content="formFields['notes'].value ? formFields['notes'].value : '<p><br></p>'"
                        content-type="html" @update:content="content => formFields['notes'].value = content"
                        @ready="onEditorReady($event)" />
                </div>
                <div class="col-span-3 mt-2">
                    <FilesUploadFormField asset-type="patent" />
                </div>
                <div class="form-separator col-span-3">
                    <p class="form-subtitle">Patent Dates</p>
                </div>
                <div>
                    <label>Publication Date</label>
                    <Datepicker class="w-full" :upper-limit="new Date()" :clearable="true" :input-format="'dd-MMM-yyyy'"
                        v-model="formFields['publicationDate'].value" />
                </div>
                <div>
                    <label>Priority Date</label>
                    <Datepicker class="w-full" :upper-limit="new Date()" :clearable="true" :input-format="'dd-MMM-yyyy'"
                        v-model="formFields['priorityDate'].value" />
                </div>
                <div>
                    <label>Expiry Date</label>
                    <Datepicker class="w-full" :clearable="true" :input-format="'dd-MMM-yyyy'"
                        v-model="formFields['expiryDate'].value" />
                </div>
                <div>
                    <label>Granted Date</label>
                    <Datepicker class="w-full" :upper-limit="new Date()" :clearable="true" :input-format="'dd-MMM-yyyy'"
                        v-model="formFields['grantedDate'].value" />
                </div>
                <div class="form-separator col-span-3">
                    <p class="form-subtitle">Patent Participants</p>
                </div>
                <div>
                    <DropdownSearchableWithFilter v-model="formFields['inventors'].value" :filter-criteria="'partyType'"
                        :options-list="formInizializationValues['partiesOptions']" :select-label="'Inventors'"
                        :filter-options="[{ id: 1, name: 'Individual' }, { id: 2, name: 'University' }, { id: 3, name: 'Organization' }]"
                        :filter-label="'Filter Inventors by:'" :two-columns="false" :is-multiple="true" />
                    <PartyAddNew party-type="inventor" />
                </div>
                <div>
                    <DropdownSearchableWithFilter v-model="formFields['assignees'].value" :filter-criteria="'partyType'"
                        :options-list="formInizializationValues['partiesOptions']" :select-label="'Assignees'"
                        :filter-options="[{ id: 1, name: 'Individual' }, { id: 2, name: 'University' }, { id: 3, name: 'Organization' }]"
                        :filter-label="'Filter Assignees by:'" :two-columns="false" :is-multiple="true" />
                    <PartyAddNew party-type="assignee" />
                </div>
                <div>
                    <DropdownSearchableWithFilter v-model="formFields['owners'].value" :filter-criteria="'partyType'"
                        :options-list="formInizializationValues['partiesOptions']" :select-label="'Owners'"
                        :filter-options="[{ id: 1, name: 'Individual' }, { id: 2, name: 'University' }, { id: 3, name: 'Organization' }]"
                        :filter-label="'Filter Owners by:'" :two-columns="false" :is-multiple="true" />
                    <PartyAddNew party-type="owner" />
                </div>
                <div class="form-separator col-span-3">
                    <p class="form-subtitle">Patent Territories</p>
                </div>
                <div>
                    <label>Pending Territories <span>(One or more)</span> </label>
                    <select class="w-full"
                        @input="formFields['pendingTerritories'].value = useMultipleSelect(Number(handleInputChange($event)), 'add', formFields['pendingTerritories'].value)">
                        <option disabled selected></option>
                        <option v-for="country in formInizializationValues['countriesOptions']" :value="country.id"
                            :key="country.id">{{ country.name }} ({{ country.code }})</option>
                    </select>
                    <div class="selected-results" v-if="formFields['pendingTerritories'].value.length">
                        <template v-for="(country, index) in formInizializationValues['countriesOptions']" :key="index">
                            <span v-if="formFields['pendingTerritories'].value.includes(Number(country.id))">
                                {{ country.code }}
                                <XMarkIcon class="icon icon-x-small thick inline"
                                    @click="formFields['pendingTerritories'].value = useMultipleSelect(Number(country.id), 'remove', formFields['pendingTerritories'].value)" />
                            </span>
                        </template>
                    </div>
                </div>
                <div>
                    <label>Granted Territories <span>(One or more)</span> </label>
                    <select class="w-full"
                        @input="formFields['grantedTerritories'].value = useMultipleSelect(Number(handleInputChange($event)), 'add', formFields['grantedTerritories'].value)">
                        <option disabled selected></option>
                        <option v-for="country in formInizializationValues['countriesOptions']" :value="country.id"
                            :key="country.id">{{ country.name }} ({{ country.code }})</option>
                    </select>
                    <div class="selected-results" v-if="formFields['grantedTerritories'].value.length">
                        <template v-for="(country, index) in formInizializationValues['countriesOptions']" :key="index">
                            <span v-if="formFields['grantedTerritories'].value.includes(Number(country.id))">
                                {{ country.code }}
                                <XMarkIcon class="icon icon-x-small thick inline"
                                    @click="formFields['grantedTerritories'].value = useMultipleSelect(Number(country.id), 'remove', formFields['grantedTerritories'].value)" />
                            </span>
                        </template>
                    </div>
                </div>
                <div>
                    <label>Expired Territories <span>(One or more)</span> </label>
                    <select class="w-full"
                        @input="formFields['expiredTerritories'].value = useMultipleSelect(Number(handleInputChange($event)), 'add', formFields['expiredTerritories'].value)">
                        <option disabled selected></option>
                        <option v-for="country in formInizializationValues['countriesOptions']" :value="country.id"
                            :key="country.id">{{ country.name }} ({{ country.code }})</option>
                    </select>
                    <div class="selected-results" v-if="formFields['expiredTerritories'].value.length">
                        <template v-for="(country, index) in formInizializationValues['countriesOptions']" :key="index">
                            <span v-if="formFields['expiredTerritories'].value.includes(Number(country.id))">
                                {{ country.code }}
                                <XMarkIcon class="icon icon-x-small thick inline"
                                    @click="formFields['expiredTerritories'].value = useMultipleSelect(Number(country.id), 'remove', formFields['expiredTerritories'].value)" />
                            </span>
                        </template>
                    </div>
                </div>
            </div>
        </form>
        <div class="form-error-toast">
            <FormErrorToast :is-showing="!!errorsDuringMutation" title="Warning!" :subtitle="errorsDuringMutation"
                toast-type="warning" />
            <FormErrorToast :is-showing="formSubmited && !formIsValid" title="The following fields are mandatory:"
                :subtitle="errorsFields.join(', ')" />
        </div>
    </div>
</template>
<script setup lang="ts">
import { ASSET_ADD_FORM_VALUES } from '@/grapql/assetAddFormValues';
import { useQuery } from '@vue/apollo-composable';
import { computed, defineProps, ref } from 'vue';
import { XMarkIcon, InformationCircleIcon } from '@heroicons/vue/24/outline';
import { useMultipleSelect, useMultipleSelectForSources } from '@/composables/useMultipleSelect';
import Datepicker from 'vue3-datepicker';
import { NEW_ASSET_FIELDS } from '@/models/newAssetFormFields';
import { useFormFieldsCreationNewAsset } from '@/composables/useFormFieldsCreationNewAsset';
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import FormErrorToast from '@/components/FormErrorToast.vue';
import DropdownSearchableMultiple from '@/components/DropdownSearchableMultiple.vue';
import DropdownSearchableWithFilter from './DropdownSearchableWithFilter.vue';
import FilesUploadFormField from './FilesUploadFormField.vue';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import PartyAddNew from '@/components/PartyAddNew.vue';


defineProps(['errorsFields', 'formIsValid', 'formSubmited', 'errorsDuringMutation'])
const tempLinks = '';
const tempPatentsIds = '';
const tempNewTag = ref('');
const showTagInput = ref(false);

// Inizialize the form
const { result } = useQuery(ASSET_ADD_FORM_VALUES);

const inizilizedData = computed(() => result?.value ?? null)
const formInizializationValues = computed<{ [key: string]: DropdownSearchableOptionInterface[] }>(() => useFormInizializationNewAsset(inizilizedData.value, NEW_ASSET_FIELDS.PATENT.fieldsToQuery));

// Create Fields for the form
const handleInputChange = (event: Event) => (event.target as HTMLInputElement).value;

const formFieldOptions = {
    fields: NEW_ASSET_FIELDS.PATENT.formFileds,
    base: NEW_ASSET_FIELDS.PATENT.graphqlValue,
    mutation: 'assetCreationModule/setSingleAssetValue'
};
const formFields = useFormFieldsCreationNewAsset(formFieldOptions);
const onEditorReady = (event: Event) => { return event };

</script>