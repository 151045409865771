import gql from "graphql-tag";


export const ASSET_PROGRAM_LINK_CREATE = gql`
    mutation assetProgramLinkCreate($input: AssetProgramLinkCreateMutationInput!) {
        assetProgramLinkCreate (input: $input) {
            assetProgramLink {
                id
            }
        }
    }
` 

export const ASSET_PROGRAM_LINK_UPDATE = gql`
    mutation assetProgramLinkUpdate($input: AssetProgramLinkUpdateMutationInput!) {
        assetProgramLinkUpdate (input: $input) {
            assetProgramLink {
                id
            }
        }
    }
` 