import router from "@/router";
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  fromPromise,
  gql,
  InMemoryCache,
  split
} from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { useMutation } from "@vue/apollo-composable";

import { getMainDefinition } from "@apollo/client/utilities"
import { WebSocketLink } from "@apollo/client/link/ws" // <-- This one uses subscriptions-transport-ws

const wsLink = new WebSocketLink({
  uri: process.env.VUE_APP_WEBSOCKET,
  options: {
    reconnect: true
  }
})

// const subscriptionMiddleware = {
//   applyMiddleware(options, next) {
//     options.setContext({
//       headers: {
//         authorization: 'some token here',
//       },
//     });
//     next();
//   },
// };



// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_ROOT_API,
});

// Cache implementation
const cache = new InMemoryCache();

// Set authorization token from local storage
const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = localStorage.getItem("auth_token");

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `jwt ${token}` : "",
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

// Handle errors
const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  console.log(graphQLErrors);
  const isTokenExpired =
    graphQLErrors &&
    graphQLErrors.find((error) => {
      const { message } = error;
      return message.includes("Error decoding signature") || message.includes("Signature has expired")
    });
  if (isTokenExpired) {
    return fromPromise(
      getNewToken()
        // eslint-disable-next-line
        .then((resolvedData: any) => {
          localStorage.setItem("auth_token", resolvedData.token);
          localStorage.setItem("refresh_token", resolvedData.refreshToken);
          return resolvedData;
        })
        .catch((error) => {
          console.log(error);
          return;
        })
    )
      .filter((value) => Boolean(value))
      .flatMap(() => {
        // retry the request, returning the new observable
        return forward(operation);
      });
  }
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    )
  },
  wsLink,
  errorLink.concat(authLink.concat(httpLink))
)


// Create the apollo client
const defaultApolloClient = new ApolloClient({
  link: link,
  cache,
});

// Refresh Token whenever call return expired error
const getNewToken = async () => {
  return new Promise((resolve) => {
    const { mutate: refreshToken, onDone, onError } = useMutation(gql`
      mutation refreshToken($refreshToken: String!) {
        refreshToken(refreshToken: $refreshToken) {
          token
          refreshToken
          refreshExpiresIn
        }
      }
    `, {
      context: {
        uri: process.env.VUE_APP_ROOT_API + '/auth'
      }
    });
    const localStorageRefreshToken = localStorage.getItem("refresh_token");
    localStorage.removeItem("auth_token");
    refreshToken({ refreshToken: localStorageRefreshToken });
    onDone((result) => resolve(result.data.refreshToken));
    onError(error => {
      console.log(error.message);
      localStorage.clear();
      defaultApolloClient.clearStore();
      router.push('/')
    })
  })
}

export default defaultApolloClient;