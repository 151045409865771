
<template>      
    <div class="flex items-center px-4">
        <div class="flex">
            <p class="flex items-center"><ArrowsUpDownIcon class="icon icon-small" /> Sort By</p>
            <select :value="sortValue" @input="handleSortValue" class="filter-select">
                <option :value="null"></option>
                <template v-for="column in sortableColumns" :key="column.name">
                    <option v-if="!column.hidden" :value="column.field">{{ column.label }}</option> 
                </template>
            </select>
        </div> 
        <div class="sort-direction">
            <div class="sort-option" :class="{selected: selectedDirection == ''}" @click="handleChangeDirection('')">
                <ArrowLongDownIcon class="icon icon-small thick" /> <p>From A to Z</p>
            </div>
            <div class="sort-option" :class="{selected: selectedDirection == '-'}" @click="handleChangeDirection('-')">
                <ArrowLongUpIcon class="icon icon-small thick" /> <p>From Z to A</p>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ColumnAssetInterface } from '@/models/technologyAssetsInterface';
import { ArrowsUpDownIcon, ArrowLongDownIcon, ArrowLongUpIcon } from '@heroicons/vue/24/outline'
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const columns = computed<ColumnAssetInterface[]>(() => store.getters['assetsModule/getAssetsTableColumns']);

const sortableColumns = computed(() => columns.value.filter(column => column.sortable))
const sortValue = computed(()=> store.state.assetsModule.sortValue )
const selectedDirection = computed(()=> store.state.assetsModule.sortDirection );

const handleChangeDirection = (direction: string) => store.commit('assetsModule/setSortDirection', {sortDirection: direction})
const handleSortValue = (event: Event) => store.commit('assetsModule/setSortValue', {sortValue: (event.target as HTMLInputElement).value}) 

</script>
<style lang="scss" scoped>
.sort-direction {
    display: flex;
    align-items: center;
    background: $grey-light;
    padding: 4px;
    border-radius: 7px;
    font-size: 12px;
    .sort-option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 7px;
        padding: 3px 15px;
        cursor: pointer;
        &.selected {
            background: $font-color;
            color: $grey-super-light;
            > svg { stroke: $grey-super-light}
        }
    }
}

</style>