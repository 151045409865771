<template>
  <div  class="action-icons">
    <RunAddNew @on-created="$emit('onCreated')" />
    <SearchAsYouType @search-text="onSearchText" />
  </div>
</template>
<script setup lang="ts">
import { defineEmits,defineProps } from "vue";
import SearchAsYouType from "@/components/SearchAsYouType.vue";
import RunAddNew from "@/tea/components/runs/RunAddNew.vue";
import { useStore } from "vuex";
const store = useStore();

const props= defineProps({"runGroupId":{type:Number}})
const emit = defineEmits(["onSearchText", "onCreated"]);
store.commit("runCreateModule/SET_RUN_GROUP_ID", props.runGroupId);

const onSearchText = (searchValue: string) => emit("onSearchText", searchValue);
</script>
