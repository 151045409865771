import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "program-container" }
const _hoisted_2 = { class: "program-name" }
const _hoisted_3 = {
  key: 0,
  class: "content"
}
const _hoisted_4 = { class: "content-values" }
const _hoisted_5 = { class: "link-type" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "actions" }

import { ref, type PropType, computed } from 'vue';
import { LinkedProgramInterface } from '@/models/linkedProgramInterface';
import { useStore } from 'vuex';
import { PencilSquareIcon } from '@heroicons/vue/24/outline';
import AssetDetailAssessmentProgramsLinksEditable from './AssetDetailAssessmentProgramsLinkEditable.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssetDetailAssessmentProgramsLinkDetail',
  props: { 
    linkedProgram: Object as PropType<LinkedProgramInterface>,
},
  setup(__props) {

const store = useStore();


const programAddOn = computed<boolean>(() => store.getters['assetDetailModule/isAssetAssessmentProgramsAddition']);
const programEditFlag = ref(false);


const handleEdit = () => {
    // store.commit('assetDetailModule/setEditableOn', {sectionToMakeEditbale: 'programsEdit' });
    programEditFlag.value = true; 
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(__props.linkedProgram.program.name), 1),
    (!programEditFlag.value || programAddOn.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", {
              class: "status",
              style: _normalizeStyle({ backgroundColor: __props.linkedProgram.linkStatus.colorCode || '#F99672' })
            }, _toDisplayString(__props.linkedProgram.linkStatus.name), 5),
            _createElementVNode("p", _hoisted_5, _toDisplayString(__props.linkedProgram.linkType.name), 1),
            _createElementVNode("p", {
              class: "status-reason",
              innerHTML: __props.linkedProgram.statusReason
            }, null, 8, _hoisted_6)
          ]),
          _createElementVNode("div", _hoisted_7, [
            (!programAddOn.value)
              ? (_openBlock(), _createBlock(_unref(PencilSquareIcon), {
                  key: 0,
                  class: "icon icon-x-small",
                  onClick: handleEdit
                }))
              : _createCommentVNode("", true)
          ])
        ]))
      : (_openBlock(), _createBlock(AssetDetailAssessmentProgramsLinksEditable, {
          key: 1,
          onCancelEdition: _cache[0] || (_cache[0] = ($event: any) => (programEditFlag.value = false)),
          "linked-program": __props.linkedProgram
        }, null, 8, ["linked-program"]))
  ]))
}
}

})