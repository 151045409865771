export const useFormInizializationNewRun = (queryResult, fields: string[]) => {
  const object = {};
  if (queryResult) {
    fields
      .filter((item) => item !== "ocsGoodCombos" && item !== 'shmGoodCombos')
      .map((field) => {
        object[`${field}Options`] = queryResult[field].edges.map((edge: { node: unknown }) => edge.node);
      });
    object["ocsGoodCombos"] = queryResult["ocsGoodCombos"].edges.map((edge: any) => {
      const oId = edge.node.organism.id;
      const csId = edge.node.cultivationSystem.id;
      return [oId, csId];
    });
    object["shmGoodCombos"] = queryResult["shmGoodCombos"].edges.map((edge: any) => {
      const sId = edge.node.species.id;
      const hmId = edge.node.harvestingMethod.id;
      return [sId, hmId];
    });
    object["biomassTargetsOptions"] = [
      { id: "Target compound", name: "Target compound" },
      { id: "Substrate", name: "Substrate" },
    ];
  }

  return object;
};
