<template>
    <div class="title-menu-header">
        <h1 class="view-title">{{ programVersion.name }}</h1>
        <div class="action-icons flex">
            <div class="round-action-icon" @click="toggleForm('edit')">
                <PencilIcon class="icon icon-small" />
            </div>
            <div class="relative">
                <div class="round-action-icon p-2" @click="toggleForm('benchmark')">
                    <BenchmarkIcon class="icon icon-small fill-white" />
                </div>
                <div v-if="showBenchmarkOptions" class="absolute top-12 left-1 one-five-blue rounded-md">
                    <p class="text-xs font-light text-white border-b border-white py-1 px-4 cursor-pointer" @click="isBenchmarkFormOpen = !isBenchmarkFormOpen; showBenchmarkOptions = false">New</p>
                    <p class="text-xs font-light text-white py-1 px-4 cursor-pointer" @click="isBenchmarkListOpen = !isBenchmarkListOpen; showBenchmarkOptions = false">Open</p>
                </div>
            </div>
            <div class="round-action-icon p-2 opacity-30 cursor-not-allowed" @click="toggleForm('design')">
                <LayerIcon />
            </div>
            <div class="round-action-icon" @click="$router.go(-1)">
                <ArrowUturnLeftIcon class="icon icon-small" />
            </div>

        </div>
    </div>
    <ProgramVersionDesignForm :is-open="isDesignFormOpen" @close-modal="isDesignFormOpen = false"/>
    <ProgramVersionsForm :is-open="isEditFormOpen" :on-edit="true" @close-edit="isEditFormOpen = false"/>
    <ProgramVersionBenchmarkForm :is-open="isBenchmarkFormOpen" @close-modal="isBenchmarkFormOpen = false" :is-on-edit="false" :program-name="programVersion.program.name"/>
    <ProgramVersionBenchmarkList :is-open="isBenchmarkListOpen" @close-modal="isBenchmarkListOpen = false" />
</template>
<script setup lang="ts">
import { ArrowUturnLeftIcon, PencilIcon } from '@heroicons/vue/24/outline';
import ProgramVersionDesignForm from './ProgramVersionDesignForm.vue';
import { defineProps, ref, computed } from 'vue';
import ProgramVersionsForm from './ProgramVersionsForm.vue';
import { useStore } from 'vuex';
import BenchmarkIcon from '@/components/icons/BenchmarkIcon.vue';
import LayerIcon from '@/components/icons/LayerIcon.vue';
import ProgramVersionBenchmarkForm from './ProgramVersionBenchmarkForm.vue';
import ProgramVersionBenchmarkList from './ProgramVersionBenchmarkList.vue';

defineProps(['programVersion'])

const store = useStore();
const isDesignFormOpen = ref(false);
const isEditFormOpen = ref(false);
const isBenchmarkFormOpen = ref(false);
const isBenchmarkListOpen = ref(false);
const showBenchmarkOptions = ref(false);

const collapsed = computed(() => store.getters['authModule/isSidebarCollapsed']);
const toggleSidebar = () => store.commit('authModule/toogleSidebar');

const toggleForm = (formType: string) => {
    if( formType == "design") {
        isDesignFormOpen.value = !isDesignFormOpen.value
    }
    if( formType == "edit") {
        isEditFormOpen.value = !isEditFormOpen.value
    }
    if( formType == "benchmark") {
        showBenchmarkOptions.value = !showBenchmarkOptions.value
    }
    if (!collapsed.value) {
        toggleSidebar();
    }
}

</script>
