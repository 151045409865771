import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "section-container" }
const _hoisted_2 = { class: "search-container" }
const _hoisted_3 = {
  key: 0,
  class: "search-condition first"
}
const _hoisted_4 = ["onUpdate:modelValue"]
const _hoisted_5 = ["value"]
const _hoisted_6 = ["onUpdate:modelValue"]
const _hoisted_7 = ["value"]
const _hoisted_8 = ["onUpdate:modelValue"]
const _hoisted_9 = {
  key: 1,
  class: "search-condition"
}
const _hoisted_10 = ["onUpdate:modelValue"]
const _hoisted_11 = ["onUpdate:modelValue"]
const _hoisted_12 = ["value"]
const _hoisted_13 = ["onUpdate:modelValue"]
const _hoisted_14 = ["value"]
const _hoisted_15 = ["onUpdate:modelValue"]
const _hoisted_16 = ["onClick"]

import { onMounted, ref, PropType } from 'vue';
import { TrashIcon } from '@heroicons/vue/24/outline';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
// import DropdownSearchableSingle from '@/components/DropdownSearchableSingle.vue'

interface formConditionInterface {
    condition: string;
    property: string;
    relation: string;
    value: string | number
}
interface relationInterface {
    operator: string,
    name: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PolymersSearch',
  props: {propertiesList: { type: Array as PropType<DropdownSearchableOptionInterface[]>}, },
  emits: ['applyFilter', 'clearFilter'],
  setup(__props, { emit: __emit }) {

const emit = __emit


const relationsList:relationInterface[] = [
    {
        operator: 'includes',
        name: 'Contains',
    },
    {
        operator: 'Notincludes',
        name: 'Does not contain',
    },
    {
        operator: '==',
        name: 'Is',
    },
    {
        operator: '!==',
        name: 'Is no',
    },
    {
        operator: '>=',
        name: 'Is bigger than',
    },
    {
        operator: '<=',
        name: 'Is lower than',
    }  
]

    // 'Does not contains','Is', 'Is not', 'Is bigger than', 'Is lower than']
const conditionsList:string[] = ['and', 'or']

const formConditions = ref<formConditionInterface[]>([]);
const firstCondition:formConditionInterface = {
        condition: '',
        property: '',
        relation: '',
        value: '',
}

const addCondition = () => {
    const newCondition:formConditionInterface = {
        condition: '',
        property: '',
        relation: '',
        value: '',
    }
    formConditions.value.push(newCondition)
}
const removeCondition = (position:number) => formConditions.value.splice(position, 1)
const clearAllFields = () => {
    formConditions.value = []; 
    addCondition();
    emit('clearFilter')
}

const applyFilters = () => emit('applyFilter', formConditions.value)

onMounted(() => {
    formConditions.value.push(firstCondition)
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formConditions.value, (condition, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
          (index == 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _cache[2] || (_cache[2] = _createElementVNode("p", null, "Filter polymers where:", -1)),
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": ($event: any) => ((condition.property) = $event),
                  class: ""
                }, [
                  _cache[0] || (_cache[0] = _createElementVNode("option", {
                    disabled: "",
                    value: ""
                  }, "Property", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.propertiesList, (option, index) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: index,
                      value: option.name
                    }, _toDisplayString(option.name), 9, _hoisted_5))
                  }), 128))
                ], 8, _hoisted_4), [
                  [_vModelSelect, condition.property]
                ]),
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": ($event: any) => ((condition.relation) = $event),
                  class: "auto"
                }, [
                  _cache[1] || (_cache[1] = _createElementVNode("option", {
                    disabled: "",
                    value: ""
                  }, "Condition", -1)),
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(relationsList, (option, index) => {
                    return _createElementVNode("option", {
                      key: index,
                      value: option.operator
                    }, _toDisplayString(option.name), 9, _hoisted_7)
                  }), 64))
                ], 8, _hoisted_6), [
                  [_vModelSelect, condition.relation]
                ]),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": ($event: any) => ((condition.value) = $event),
                  placeholder: "Value",
                  class: "w-6/12"
                }, null, 8, _hoisted_8), [
                  [_vModelText, condition.value]
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": ($event: any) => ((condition.condition) = $event),
                  class: "auto"
                }, [
                  _cache[3] || (_cache[3] = _createElementVNode("option", {
                    disabled: "",
                    value: ""
                  }, "And/Or", -1)),
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(conditionsList, (option, index) => {
                    return _createElementVNode("option", { key: index }, _toDisplayString(option), 1)
                  }), 64))
                ], 8, _hoisted_10), [
                  [_vModelSelect, condition.condition]
                ]),
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": ($event: any) => ((condition.property) = $event),
                  class: ""
                }, [
                  _cache[4] || (_cache[4] = _createElementVNode("option", {
                    disabled: "",
                    value: ""
                  }, "Property", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.propertiesList, (option, index) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: index,
                      value: option.name
                    }, _toDisplayString(option.name), 9, _hoisted_12))
                  }), 128))
                ], 8, _hoisted_11), [
                  [_vModelSelect, condition.property]
                ]),
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": ($event: any) => ((condition.relation) = $event),
                  class: "auto"
                }, [
                  _cache[5] || (_cache[5] = _createElementVNode("option", {
                    disabled: "",
                    value: ""
                  }, "Condition", -1)),
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(relationsList, (option, index) => {
                    return _createElementVNode("option", {
                      key: index,
                      value: option.operator
                    }, _toDisplayString(option.name), 9, _hoisted_14)
                  }), 64))
                ], 8, _hoisted_13), [
                  [_vModelSelect, condition.relation]
                ]),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  "onUpdate:modelValue": ($event: any) => ((condition.value) = $event),
                  placeholder: "Value"
                }, null, 8, _hoisted_15), [
                  [_vModelText, condition.value]
                ]),
                _createElementVNode("div", {
                  class: "delete-icon",
                  onClick: ($event: any) => (removeCondition(index))
                }, [
                  _createVNode(_unref(TrashIcon), { class: "icon icon-x-small red" })
                ], 8, _hoisted_16)
              ]))
        ], 64))
      }), 128)),
      _createElementVNode("div", { class: "actions-container" }, [
        _createElementVNode("div", { onClick: addCondition }, "+ Add new condition")
      ])
    ]),
    _createElementVNode("div", {
      class: "apply-filter mr-2",
      onClick: applyFilters
    }, "Filter"),
    _createElementVNode("div", {
      class: "apply-filter clear",
      onClick: clearAllFields
    }, "Clear")
  ]))
}
}

})