<template>
  <div>
    <!-- Navbar -->
    <nav class="one-five-blue shadow-lg" id="tea-nav">
      <div class="container mx-auto px-6 py-3">
        <div class="flex items-center justify-between">
          <!-- Logo -->
          <img src="@/assets/images/one-five-white-trademark.png" alt="one-five-logo" class="logo-trademark" @click="$router.push({ name: 'Tea.Home' })" style="cursor: pointer;width: :auto; height:35px;">
          <!-- Menu Items -->
          <div class="flex space-x-6">
            <a href="#" >About</a>
            <a href="#" @click="openAuthModal" >{{ Dashboard }}</a>
            <a href="#" >Contact</a>
          </div>
        </div>
      </div>
    </nav>

    <!-- Hero Section -->
    <section class="one-five-green text-white " id="tea-hero-section" style="padding: 140px 0;">
      <div class="container mx-auto text-center">
        <h1 class="text-4xl font-semibold mb-4">Uncover Insights with Techno Economic Analysis</h1>
        <p class="text-lg mb-8">Empower your business decisions with data-driven strategies.</p>
        <a
          href="#"
          @click="openAuthModal"
          class="one-five-blue hover:bg-sky-900 text-white py-3 px-8 rounded-full text-lg font-semibold transition duration-300"
          >Get Started</a
        >
      </div>
    </section>
    <AuthModal :AuthModalVisible="authModalVisible" @closeModal="closeAuthModal" />

    <!-- Interactive Section -->
    <section class="bg-gray-100 py-16">
      <div class="container mx-auto text-center">
        <h2 class="text-3xl font-semibold mb-6">Why Choose Techno Economic Analysis?</h2>
        <p class="text-gray-700 leading-relaxed mb-8">
          Our expertise in analyzing technological and economic factors enables you to make informed decisions that impact your bottom line.
          From optimizing resources to predicting market trends, we provide actionable insights that drive success.
        </p>
        <a href="#" class="one-five-blue  text-white py-2 px-6 rounded-full text-lg font-semibold transition duration-300"
          >Learn More</a
        >
      </div>
    </section>

    <!-- Services Section -->
    <section class="py-16 bg-gray-100">
      <div class="container mx-auto">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <!-- Service 1: Cost Analysis -->
          <div class="bg-white p-6 rounded-lg shadow">
            <div class="flex items-center justify-center w-12 h-12 bg-blue-600 text-white rounded-full mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="calculator w-6 h-6">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 20v-3h6v3h-6zm6-12v-3h-6v3h6zm-6 3V3a2 2 0 00-2-2 1 1 0 00-1 1v9H2a2 2 0 00-2 2 1 1 0 001 1h14v9a1 1 0 001 1 2 2 0 002-2v-9h4a1 1 0 001-1 2 2 0 00-2-2h-4z"
                ></path>
              </svg>
            </div>
            <h3 class="text-xl font-semibold mb-2">Cost Analysis</h3>
            <p class="text-gray-600">Evaluate the economic feasibility of your projects.</p>
          </div>
          <!-- Service 2: Market Research -->
          <div class="bg-white p-6 rounded-lg shadow">
            <div class="flex items-center justify-center w-12 h-12 bg-blue-600 text-white rounded-full mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="chart-bar w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16M4 18h16"></path>
              </svg>
            </div>
            <h3 class="text-xl font-semibold mb-2">Market Research</h3>
            <p class="text-gray-600">Identify potential markets and demand trends.</p>
          </div>
          <!-- Service 3: ROI Analysis -->
          <div class="bg-white p-6 rounded-lg shadow">
            <div class="flex items-center justify-center w-12 h-12 bg-blue-600 text-white rounded-full mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="currency-dollar w-6 h-6">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 1v22M17 5H7a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2z"
                ></path>
              </svg>
            </div>
            <h3 class="text-xl font-semibold mb-2">ROI Analysis</h3>
            <p class="text-gray-600">Calculate the return on investment for your initiatives.</p>
          </div>
        </div>
      </div>
    </section>

    <!-- New Section: Techno Economic Analysis Steps -->
    <section class="py-16 bg-gray-100">
      <div class="container mx-auto">
        <h2 class="text-2xl font-semibold mb-8 text-center">Get Your Techno Economic Analysis in 3 Simple Steps</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <!-- Step 1: Cultivation -->
          <div class="bg-white p-6 rounded-lg shadow">
            <div class="flex items-center justify-center w-12 h-12 bg-blue-600 text-white rounded-full mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="seedling w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5z"></path>
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 17a9 9 0 01-6.742-3.071A8.962 8.962 0 002 12a9 9 0 019-9v2a7 7 0 00-7 7 5 5 0 005 5v2z"
                ></path>
              </svg>
            </div>
            <h3 class="text-xl font-semibold mb-2">Cultivation</h3>
            <p class="text-gray-600">Lay the foundation for your analysis with data collection and preparation.</p>
          </div>
          <!-- Step 2: Harvesting -->
          <div class="bg-white p-6 rounded-lg shadow">
            <div class="flex items-center justify-center w-12 h-12 bg-blue-600 text-white rounded-full mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="scissors w-6 h-6">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 6l3 3-2 2L4 8l2-2zM6 6l6 6M6 6l6 6M6 6l6 6"
                ></path>
              </svg>
            </div>
            <h3 class="text-xl font-semibold mb-2">Harvesting</h3>
            <p class="text-gray-600">Gather relevant insights and data from your cultivated sources.</p>
          </div>
          <!-- Step 3: Extraction and Drying -->
          <div class="bg-white p-6 rounded-lg shadow">
            <div class="flex items-center justify-center w-12 h-12 bg-blue-600 text-white rounded-full mb-4">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="flask w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14"></path>
              </svg>
            </div>
            <h3 class="text-xl font-semibold mb-2">Extraction and Drying</h3>
            <p class="text-gray-600">Extract valuable insights and dry out unnecessary noise for accurate analysis.</p>
          </div>
        </div>
      </div>
    </section>

    <!-- Footer -->
    <footer class="one-five-blue text-white pt-7 pb-1 text-sm">
      <div  class="container mx-auto pt-4">
        <p class="text-base">Information:</p>
      </div>
      <div class="container mx-auto md:text-center pt-2 pb-5">
        <div class="md:flex md:justify-center">
          <div class="sm:w-full">
          <a href="https://www.one-five.com/privacy-policy" class="px-5">Privacy Policy</a>
          </div>
          <div class="sm:w-full">
          <a href="https://www.one-five.com/cookies-policy" class="px-5">Cookies Policy</a>
        </div>
        <div class="sm:w-full">
          <a href="https://www.one-five.com/terms-of-use" class="px-5">Terms of Agreement for Website Use</a>
        </div>
        <div class="sm:w-full">
          <a href="https://www.one-five.com/impressum" class="px-5">Impressum</a>
        </div>
        </div>
      </div>
      <div class="container mx-auto text-center">
        <p class="border-t border-blue-700 py-6">Copyright 2023 <a href="https://one-five.com">one-five.com</a> All Rights Reserved</p>
      </div>
    </footer>
  </div>
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import AuthModal from "../components/auth/AuthModal.vue";
import store from "@/store";
import { useRouter } from "vue-router";

const router = useRouter();
const authModalVisible = ref(false);
const Dashboard = computed(()=>{
  return store.getters["authModule/isAuthenticated"] ? "Dashboard":"login";
});

const openAuthModal = () => {
  if (store.getters["authModule/isAuthenticated"]) {
    router.push("dashboard");
  } else {
    authModalVisible.value = true;
  }
};
const closeAuthModal = () => (authModalVisible.value = false);
</script>
<style lang="scss" scoped>
.btn-primary {
  background-color: $one-five-blue;
  color: white;
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
nav a, footer a{
  color: white;
}
nav a:hover, footer a:hover{
  color: black;
}
#tea-hero-section{
  background-color: $one-five-blue;
  background-image: url('~@/assets/images/tea-hero-blue.jpg');
  background-size: cover; /* This property ensures the image covers the entire section */
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

#tea-hero-section h1,
#tea-hero-section p{
  // color: $one-five-green;
  text-shadow: 2px 2px 1px rgba(0,0,0, 0.5); 
}

@media (max-width: 1023px) {
  #tea-hero-section {
    padding: 100px 0 !important;
  }
}

@media (max-width: 768px) {
  #tea-hero-section {
    padding: 70px 0 !important;
  }
}
</style>
