<template>
    <div v-if="layers" class="flex justify-between mt-4">
        <template v-for="(layer, index) in layers" :key="index">
            <div v-if="layer.type == 'substrate'" class="w-full">
                <FilterSubstrate :substrate="layer" :layer-index="index" @filter-layer="hanldleFilter" />
            </div>
            <div v-if="layer.name == 'Barrier Technology'" class="w-full ml-3">
                <FilterPrimers :layer-index="index" @filter-layer="hanldleFilter"/>
            </div>
            <div v-if="layer.name == 'Heat Seals'" class="w-full ml-3">
                <FilterHeatSeals :layer-index="index" @filter-layer="hanldleFilter"/>
            </div>
        </template>
    </div>
</template>
<script setup>
import { defineProps, defineEmits } from 'vue';
import FilterSubstrate from './FilterSubstrate.vue';
import FilterHeatSeals from './FilterHeatSeals.vue';
import FilterPrimers from './FilterPrimers.vue';

defineProps(['layers']);
const emit = defineEmits(['filterLayer']);

const hanldleFilter = (filterLayer) => emit('filterLayer', filterLayer)
</script>