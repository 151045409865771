<template>
    <div class="title-menu-header">
        <h1 class="view-title">{{ title }}</h1>
        <div class="action-icons flex">
            <div v-if="showFilter" class="round-action-icon" @click="emit('toogleFilter')">
                <FunnelIcon class="icon icon-small" />
            </div>
            <div v-if="showAdd" class="round-action-icon">
                <PlusIcon class="icon icon-small" />
            </div>
            <div v-if="showEdit" class="round-action-icon" @click="$emit('toogleEdit')">
                <PencilIcon class="icon icon-small" />
            </div>
            <div v-if="showSave" class="round-action-icon" @click="$emit('toogleSave')">
                <BookmarkIcon class="icon icon-small" />
            </div>
            <div class="round-action-icon" @click="$router.go(-1)">
                <ArrowUturnLeftIcon class="icon icon-small" />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ArrowUturnLeftIcon, FunnelIcon, PencilIcon, PlusIcon, BookmarkIcon } from '@heroicons/vue/24/outline'
import { defineProps, defineEmits,} from 'vue';

defineProps(['title', 'showFilter', 'showEdit', 'showAdd', 'changeButtonOnAction', 'showSave']);
const emit = defineEmits(['toogleFilter', 'toogleEdit', 'toogleSave'])



</script>
