import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "filter-box shadow-md rounded-lg mb-6 bg-nude" }
const _hoisted_2 = { class: "filter-title flex justify-between px-4 py-3" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "subtitle" }
const _hoisted_5 = {
  key: 0,
  class: "w-full text-center"
}
const _hoisted_6 = {
  key: 1,
  class: "w-full text-center"
}
const _hoisted_7 = {
  key: 2,
  class: "w-full text-center"
}
const _hoisted_8 = {
  key: 0,
  class: "filter-options flex flex-col px-3 py-4 text-sm"
}
const _hoisted_9 = { class: "flex justify-between pr-4" }
const _hoisted_10 = { class: "flex justify-between mt-1 mb-3" }
const _hoisted_11 = ["value"]
const _hoisted_12 = { class: "flex justify-between pr-4" }
const _hoisted_13 = { class: "flex mt-1 mb-3" }
const _hoisted_14 = ["value"]
const _hoisted_15 = { class: "flex justify-between pr-4" }
const _hoisted_16 = { class: "flex mt-1 mb-3" }
const _hoisted_17 = ["value"]
const _hoisted_18 = {
  key: 0,
  class: "bg-white shadow-md border p-2 mb-4 rounded-lg relative min-h-96 w-full"
}

import { EllipsisHorizontalIcon, ChartBarIcon } from '@heroicons/vue/20/solid';
import { ChartBarSquareIcon, MagnifyingGlassCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';
import ChartHeatMapPaper from './ChartHeatMapPaper.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterSubstrate',
  props: ['substrate', 'layerIndex'],
  emits: ['filterLayer'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const showFilterOptions = ref(false);
const creatingHeatMap = ref(false);
const showHeatMap = ref(false);

const colorOptions = [{ label: 'Transparent', value: 13 }, { label: 'Unbleached', value: 10 }, { label: 'Bleached', value: 61 }]
const selectedColors = ref([]);

const grammageOptions = [{ label: '50', value: 1 }, { label: '60', value: 2 }, { label: '70', value: 3 }, { label: '80', value: 4 }, { label: '90', value: 5 }, { label: '100', value: 6 }]
const selectedGrammages = ref([]);

const roughnessOptions = [{ label: '< 0.8', value: 1 }, { label: '< 1', value: 2 }, { label: '< 1.2', value: 3 }, { label: '< 1.5', value: 4 }]
const selectedroughness = ref([]);

const filterColor = ref(null);
const filterGrammage = ref(null);
const filterRaughness = ref(null);

const hanldeshowHeatMap = () => {
    creatingHeatMap.value = true;
    setTimeout(() => {
        showHeatMap.value = true;
        creatingHeatMap.value = false
    }, 1300)
}

const onFilterLayer = (filterType: string) => {
    if (filterType == 'color') {
        const newTotal = selectedColors.value.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        setTimeout(() => {
            filterColor.value = newTotal
            emit('filterLayer', { layerIndex: props.layerIndex, newTotal: filterColor.value, rowNumber: 2 })
        }, 1000)
    }
    if (filterType == 'grammage') {
        setTimeout(() => {
            filterGrammage.value = 21
            emit('filterLayer', { layerIndex: props.layerIndex, newTotal: filterGrammage.value, rowNumber: 3 })
        }, 700)
    }
    if (filterType == 'roughness') {
        const newTotal = selectedroughness.value.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        setTimeout(() => {
            filterRaughness.value = newTotal
            emit('filterLayer', { layerIndex: props.layerIndex, newTotal: filterRaughness.value, rowNumber: 4 })
        }, 500)
    }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _cache[9] || (_cache[9] = _createElementVNode("p", { class: "title" }, "SUBSTRATE", -1)),
            _createElementVNode("p", _hoisted_4, _toDisplayString(__props.substrate.name) + " filtering", 1)
          ]),
          _createVNode(_unref(ChartBarSquareIcon), {
            class: "icon icon-m white thick ml-4",
            onClick: hanldeshowHeatMap
          })
        ]),
        _createVNode(_unref(EllipsisHorizontalIcon), {
          class: "icon icon-small white",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (showFilterOptions.value = !showFilterOptions.value))
        })
      ]),
      _createElementVNode("div", {
        class: "filter-numbers flex cursor-pointer",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (showFilterOptions.value = !showFilterOptions.value))
      }, [
        _cache[10] || (_cache[10] = _createElementVNode("p", { class: "w-full text-center" }, "84", -1)),
        (filterColor.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(filterColor.value), 1))
          : _createCommentVNode("", true),
        (filterGrammage.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, "21"))
          : _createCommentVNode("", true),
        (filterRaughness.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(filterRaughness.value), 1))
          : _createCommentVNode("", true)
      ]),
      (showFilterOptions.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _cache[11] || (_cache[11] = _createElementVNode("p", { class: "uppercase font-medium" }, "Color", -1)),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(colorOptions, (color) => {
                  return _createElementVNode("div", {
                    key: color.value,
                    class: "filter-option flex items-center"
                  }, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      value: color.value,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((selectedColors).value = $event))
                    }, null, 8, _hoisted_11), [
                      [_vModelCheckbox, selectedColors.value]
                    ]),
                    _createElementVNode("label", null, _toDisplayString(color.label), 1)
                  ])
                }), 64))
              ]),
              _createVNode(_unref(MagnifyingGlassCircleIcon), {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (onFilterLayer('color'))),
                class: "icon icon-m"
              })
            ]),
            _cache[12] || (_cache[12] = _createElementVNode("p", { class: "uppercase font-medium" }, "Grammage (gsm)", -1)),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(grammageOptions, (grammage) => {
                  return _createElementVNode("div", {
                    key: grammage.value,
                    class: "filter-option flex items-center"
                  }, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      value: grammage.value,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((selectedGrammages).value = $event))
                    }, null, 8, _hoisted_14), [
                      [_vModelCheckbox, selectedGrammages.value]
                    ]),
                    _createElementVNode("label", null, _toDisplayString(grammage.label), 1)
                  ])
                }), 64))
              ]),
              _createVNode(_unref(MagnifyingGlassCircleIcon), {
                onClick: _cache[5] || (_cache[5] = ($event: any) => (onFilterLayer('grammage'))),
                class: "icon icon-m"
              })
            ]),
            _cache[13] || (_cache[13] = _createElementVNode("p", { class: "uppercase font-medium" }, "Surface roughness", -1)),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(roughnessOptions, (roughness) => {
                  return _createElementVNode("div", {
                    key: roughness.value,
                    class: "filter-option flex items-center"
                  }, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      value: roughness.value,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((selectedroughness).value = $event))
                    }, null, 8, _hoisted_17), [
                      [_vModelCheckbox, selectedroughness.value]
                    ]),
                    _createElementVNode("label", null, _toDisplayString(roughness.label), 1)
                  ])
                }), 64))
              ]),
              _createVNode(_unref(MagnifyingGlassCircleIcon), {
                onClick: _cache[7] || (_cache[7] = ($event: any) => (onFilterLayer('roughness'))),
                class: "icon icon-m"
              })
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    (creatingHeatMap.value || showHeatMap.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
          (creatingHeatMap.value)
            ? (_openBlock(), _createBlock(_unref(ChartBarIcon), {
                key: 0,
                class: "animate-pulse spin-chart heat"
              }))
            : (showHeatMap.value)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createVNode(_unref(XCircleIcon), {
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (showHeatMap.value = false)),
                    class: _normalizeClass(["icon icon-m absolute top-3 z-10 left-3 duration-1000 delay-300", { 'opacity-0': !showHeatMap.value, 'opacity-100': showHeatMap.value }])
                  }, null, 8, ["class"]),
                  _createVNode(ChartHeatMapPaper, {
                    class: _normalizeClass(["transition-opacity duration-1000 delay-300", { 'opacity-0': !showHeatMap.value, 'opacity-100': showHeatMap.value }])
                  }, null, 8, ["class"])
                ], 64))
              : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})