import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "filter-container" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = {
  key: 0,
  class: "font-medium text-base"
}
const _hoisted_4 = {
  key: 0,
  class: "filter-values"
}
const _hoisted_5 = { class: "filter-title" }
const _hoisted_6 = ["id", "value"]
const _hoisted_7 = ["for"]

import { ChevronLeftIcon, AdjustmentsHorizontalIcon } from '@heroicons/vue/20/solid';
import { ref, computed } from 'vue';
import { ProgramSimpleInterface } from '@/models/programsInterface';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramsDashboardSidebarFilter',
  props: {
    programList: {},
    isFilterClosed: { type: Boolean }
  },
  emits: ['filteredProgramsChange', 'filterToggle'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit

const filteredPrograms = computed(() => props.programList.filter(program => ['Ferrari', 'BMW', 'Ford'].includes(program.name)))
const checkedPrograms = ref([])

const filterToggle = () => {
    emits('filterToggle');
}

const clearFilter = () => {
    checkedPrograms.value = [];
    emits('filteredProgramsChange', checkedPrograms.value)
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["programs-sidebar", { 'closed': _ctx.isFilterClosed }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "flex justify-between",
        onClick: filterToggle
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(AdjustmentsHorizontalIcon), { class: "icon icon-x-small mr-2" }),
          (!_ctx.isFilterClosed)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Filters"))
            : _createCommentVNode("", true)
        ]),
        (!_ctx.isFilterClosed)
          ? (_openBlock(), _createBlock(_unref(ChevronLeftIcon), {
              key: 0,
              class: "icon icon-x-small black"
            }))
          : _createCommentVNode("", true)
      ]),
      (!_ctx.isFilterClosed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[2] || (_cache[2] = _createElementVNode("p", null, "Programmes", -1)),
              (checkedPrograms.value.length)
                ? (_openBlock(), _createElementBlock("p", {
                    key: 0,
                    class: "clear",
                    onClick: clearFilter
                  }, "Clear all"))
                : _createCommentVNode("", true)
            ]),
            (filteredPrograms.value.length)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(filteredPrograms.value, (program) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: program.id,
                    class: "filter-option"
                  }, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      id: program.name,
                      value: program.id,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((checkedPrograms).value = $event)),
                      onChange: _cache[1] || (_cache[1] = ($event: any) => (emits('filteredProgramsChange', checkedPrograms.value)))
                    }, null, 40, _hoisted_6), [
                      [
                        _vModelCheckbox,
                        checkedPrograms.value,
                        void 0,
                        { number: true }
                      ]
                    ]),
                    _createElementVNode("label", {
                      for: program.name
                    }, _toDisplayString(program.name), 9, _hoisted_7)
                  ]))
                }), 128))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})