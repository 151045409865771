import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "page-padding" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "flex flex-col w-4/5 justify-center" }
const _hoisted_5 = { class: "uppercase font-semibold mb-2 text-gray-700" }
const _hoisted_6 = { class: "text-sm text-gray-700" }
const _hoisted_7 = ["src"]

import TitleGoBackHeader from '@/components/TitleGoBackHeader.vue';
import { BRIEFS_DATA } from '@/components/playgroundDemo/briefsData';
import router from '@/router';


export default /*@__PURE__*/_defineComponent({
  __name: 'PlaygroundView',
  setup(__props) {

const briefs = BRIEFS_DATA;
const goToDetail = (briefId: number) => router.push({ name: 'PlaygroundDetail', params: { id: briefId } });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(TitleGoBackHeader, {
      title: "product Playground",
      "show-add": true
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(briefs), (brief) => {
        return (_openBlock(), _createElementBlock("div", {
          key: brief.id,
          onClick: ($event: any) => (goToDetail(brief.id)),
          class: "bg-white cursor-pointer flex items-center shadow-md justify-between border px-6 py-6 mb-6 rounded-lg"
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(brief.title), 1),
            _createElementVNode("p", _hoisted_6, _toDisplayString(brief.description), 1)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(`image-container ${brief.imageColor}`)
          }, [
            _createElementVNode("img", {
              src: require(`@/assets/images/${brief.image}`),
              class: "max-w-16"
            }, null, 8, _hoisted_7)
          ], 2)
        ], 8, _hoisted_3))
      }), 128))
    ])
  ]))
}
}

})