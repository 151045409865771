<template>
    <div class="flex relative" :class="{'flex-row-reverse': showInput }">
        <div class="round-action-icon" :class="{green: showInput }" @click="handleQuickSearch">
            <MagnifyingGlassIcon class="icon icon-small" />
        </div>
        <input class="search-input" :class="{grown: showInput }" v-model="searchText"  @keyup.enter="handleQuickSearch"/> 
        <ChevronDoubleRightIcon class="hidden" :class="{'close-search': showInput}" @click="handleClose"/>
        <XMarkIcon class="icon icon-small hidden" :class="{'restore-search': showInput}" @click="handleRestore"/>
    </div>
    
</template>

<script setup lang="ts">
import { ref, defineEmits } from 'vue';
import { MagnifyingGlassIcon, XMarkIcon, ChevronDoubleRightIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';

const emit = defineEmits(['quickSearchRefetch']);

const store = useStore();
const showInput = ref(false);
const searchText = ref('');

const handleQuickSearch = () => {
    showInput.value = true;
    if (showInput.value && searchText.value != '') { 
        store.commit('assetsModule/setQuickSearchFilterCriteria', {searchText: searchText.value});
        emit('quickSearchRefetch');
    }
}

const handleClose = () => {
    showInput.value = false; 
    handleRestore();
}
const handleRestore = () => {
    searchText.value = '';
    store.commit('assetsModule/restoreQuickSearchFilterCriteria');
    emit('quickSearchRefetch')
}

</script>

<style lang="scss" scoped>
    .search-input {
        background-color: $one-five-green;
        width: 0;
        opacity: 0;
        border-radius: 40px;
        position: relative;
        right: -11px;
        z-index: 1;
        font-size: 0.9rem;
        padding-left: 45px;
        color: $grey-super-light;
        font-weight: 300;
        transition: all 0.5s ease-out;
        &.grown{
            width: 450px;
            opacity: 1;
        }
        &:focus-visible, &:focus {
            outline: none;
            border: none;
        }
    }
    .round-action-icon {
        z-index: 3;
    }
    .round-action-icon {
        transition: all 0.5s ease-out;
        &.green  {
            background-color: $one-five-green;
        }
    }
    .close-search {
        display: block;
        position: absolute;
        height: 18px;
        color: #fafafa;
        top: 13px;
        left: 24px;
        z-index: 2;
        cursor: pointer;
    }
    .restore-search {
        stroke: #fafafa;
        z-index: 2;
        position: absolute;
        top: 10px;
        display: block;
        right: 64px;
        stroke-width: 2;
    }
</style>