import gql from "graphql-tag";

export const ASSETS_COMPARISON_CRITERIA_OPTIONS = gql`
query getComparisonCriteriaOptions {
  properties(orderBy: "name") {
    edges {
      node {
        id
        name
        className
        category
      }
    }
  }
  quantitativeProperties(orderBy: "name") {
    edges {
      node {
        id
        name
        category
      }
    }
  }
}`

export const ALL_ASSETS = gql`
query getAssets ($filter:  OtherTechnologyNodeFilterInputType) {
  otherTechnologies(filter: $filter,
    orderBy: "nickname"
  ) {
    edges {
      node {
        id
        nickname
        title
      }
    }
  }
}`

export const LAYER_TYPES = gql`
query layerTypes {
  layerTypes{
    edges {
      node {
        id
        name
      }
    }
  }
}`

