
<template>
    <div class="round-action-icon" :class="{active: isOpen}" @click="openModal">
        <FunnelIcon class="icon icon-small"/>
    </div>
    <ModalSlots :show="isOpen" @close-modal="closeModal" dialogClass="asset-filter-modal">
        <template v-slot:header> <p>view options</p></template>
        <template v-slot:body>
            <div class="flex flex-col">
                <!-- Restore View -->
                <div class="default-view" @click="handleRestoreValues">
                    <input type="checkbox" class="round-checkbox" :class="{selected: defaultView}" :disabled="defaultView">
                    <p>Restore values</p>
                
                </div>
                <!-- Sort By -->
                <div class="form-separator"><p class="form-subtitle">sort</p></div>
                <TechnologyAssetsFilterSortBy />

                <!-- Filter Options -->
                <div class="form-separator"><p class="form-subtitle">filter</p></div>
                <TechnologyAssetsFilterFilterOptions />

                <!-- Status Options -->
                <div class="form-separator"><p class="form-subtitle">Layer functionality</p></div>
                <TechnologyAssetsFilterByLayerType />

                <!-- Programme Options -->
                <div class="form-separator"><p class="form-subtitle">Programmes</p></div>
                <TechnologyAssetsFilterByPrograms />

                <!-- Columns Configuration -->
                <div class="form-separator"><p class="form-subtitle">Columns</p></div>
                <TechnologyAssetsFilterColumnsConfiguration />
               
            </div>
        </template>
        <template v-slot:footer>
            <button class="button bg-stone-800 text-white" @click="handleApplyChanges">Apply</button>  
        </template>
    </ModalSlots>
</template>
<script setup lang="ts">
import { FunnelIcon } from '@heroicons/vue/24/outline'
import { ref, defineEmits, computed  } from 'vue';
import ModalSlots from "@/components/ModalSlots.vue";
import { useStore } from 'vuex';
import TechnologyAssetsFilterSortBy from './TechnologyAssetsFilterSortBy.vue';
import TechnologyAssetsFilterColumnsConfiguration from './TechnologyAssetsFilterColumnsConfiguration.vue';
import TechnologyAssetsFilterFilterOptions from './TechnologyAssetsFilterFilterOptions.vue';
import TechnologyAssetsFilterByLayerType from './TechnologyAssetsFilterByLayerType.vue';
import TechnologyAssetsFilterByPrograms from './TechnologyAssetsFilterByPrograms.vue';

const emit = defineEmits(['filterRefetch']);
const store = useStore();

const isOpen = computed(() => store.state.assetsModule.filterModalIsOpen);

const closeModal = () => store.commit('assetsModule/closeModal');
const openModal = () => store.commit('assetsModule/openModal');

const defaultView = ref(false);

const handleRestoreValues = () => {
    defaultView.value = !defaultView.value;
    store.commit('assetsModule/restoreAllValues');
}

const handleApplyChanges = () => {
    if (!defaultView.value) {
        store.commit('assetsModule/transfromFormConditionsIntoFilters');
        store.commit('assetsModule/addSelectedLayerTypesAsFilter');
        store.commit('assetsModule/addSelectedProgramsAsFilter');
    }
    defaultView.value = false;

    emit('filterRefetch'); 
    closeModal();
}

</script>
<style lang="scss" scoped>
.form-separator{
    margin: 1rem 0;
    &::after{
        background: $grey-medium;
    }
}
.form-subtitle{
    background-color: white;
    padding-right: 1rem;
}
.default-view { 
    display: flex;
    justify-content: start; 
    cursor: pointer;
    padding: 2rem 1.5rem 0; 
}
.sort-by,  .filter-by, .filter-text{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    p {
        display: flex;
        align-items: center;
        .icon { margin-right: 5px; }
    }
    select {
        color: $font-color-light;
        font-weight: 600;
        text-transform: uppercase;
        text-align: right;
        padding-right: 0.5rem;
        margin: 0 1rem;
        cursor: pointer;
        min-width: 178px;
        &:focus-visible {
            outline: none;
            color: $one-five-green;
        }
    }
    input {
        margin: 0 1rem;
        width: 100%;
    }
}
.filter-text {
    padding-left: 2.35rem;
    padding-top: 0rem;
}
.sort-direction {
    display: flex;
    align-items: center;
    background: $grey-light;
    padding: 4px;
    border-radius: 7px;
    font-size: 12px;
    .sort-option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 7px;
        padding: 3px 15px;
        cursor: pointer;
        &.selected {
            background: $font-color;
            color: $grey-super-light;
            > svg { stroke: $grey-super-light}
        }
    }
}
.showing-columns, .hiding-columns {
    padding: 0 2rem 2rem;
    width: 50%;
    .header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
        text-transform: uppercase;
        color: $font-color-light;
        font-size: 0.8rem;
        p:last-child {
            cursor: pointer;
        }
    }
    ul {
        display: flex;
        flex-direction: column;
        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: .5rem 1.1rem 0 .5rem;
            text-transform: uppercase;
            font-size: 0.8rem;
        }
    }
}
</style>