import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

import { useStore } from 'vuex';
  import SidebarMenu from './components/SidebarMenu.vue';
  import TeaSidebarMenu from '@/tea/components/SidebarMenu.vue';
  import { computed } from 'vue';
  import { useRecaptchaProvider } from "vue-recaptcha";
  import domain  from "@/utils/appDomain";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

  const store = useStore();
  const collapsed = computed(() => store.getters['authModule/isSidebarCollapsed'])
  
  let AppSidebarMenu=null
  if(domain.isTea){
    useRecaptchaProvider();
    AppSidebarMenu=TeaSidebarMenu
  }else{
    AppSidebarMenu = SidebarMenu;
  }


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.$route.meta.hideSidebar)
      ? (_openBlock(), _createBlock(_unref(AppSidebarMenu), { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass({ 'page-content': !_ctx.$route.meta.hideSidebar, 'collapsed': collapsed.value })
    }, [
      _createVNode(_component_router_view)
    ], 2)
  ]))
}
}

})