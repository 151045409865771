<template>
  <div class="col-span-4">
    <div class="grid grid-cols-4 gap-y-4 gap-x-4">
      <!-- Organism -->

      <!-- Cultivation System -->

      <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('compoundPct') && formSubmited }">
        <label>Target compound (% dry weight biomass) *</label>
        <input class="w-full" type="number" min="0.00" max="100.00" v-model="formFields['compoundPct'].value" />
        <span class="text-xs text-gray-400"
          >Note: if you chose Solid-state fermentor (SSF) as cultivation system the term 'biomass' stands for 'substrate'</span
        >
      </div>

      <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('biomassTarget') && formSubmited }">
        <label>I know the target compound or biomass capacity? *</label>
        <select class="w-full" v-model="formFields['biomassTarget'].value">
          <option v-for="biomassTarget in biomassTargets" :value="biomassTarget" :key="biomassTarget">
            {{ biomassTarget }}
          </option>
        </select>
      </div>

      <div v-if="fields.compoundPct" class="col-span-4 grid grid-cols-3 gap-y-4 gap-x-4">
        <div
          v-if="fields.biomassTarget == 'Target compound'"
          class="col-span-3"
          :class="{ 'error-form-field': errorsFields.includes('targetCompoundCapacity') && formSubmited }"
        >
          <label>Target compound capacity (ton/year dry weight) *</label>
          <input class="w-full" type="number" min="0.00" max="100.00" v-model="formFields['targetCompoundCapacity'].value" />
        </div>
        <div v-else-if="fields.biomassTarget == 'Biomass'" class="col-span-3" :class="{ 'error-form-field': errorsFields.includes('biomass') && formSubmited }">
          <label>Biomass capacity (ton/year dry weight) *</label>
          <input class="w-full" type="number" min="0.00" max="100.00" v-model="formFields['biomass'].value" />
        </div>
      </div>

      <div v-if="showFirstInfo" class="col-span-4 bg-gray-100 p-2 py-5 rounded-md">
        <p v-if="fields.biomassTarget == 'Target compound'">
          <NewspaperIcon class="icon icon-small inline-block" /> Considering your target compound production you will need
          <b>{{ calcTargetCompoundPerYear }}</b> tons of biomass per year
        </p>
        <p v-if="fields.biomassTarget == 'Biomass'">
          <NewspaperIcon class="icon icon-small inline-block" /> Considering your substrate availability you will be able to have
          <b>{{ calcBiomassPerYear }}</b> tons of target compound per year
        </p>
      </div>

      <RadioGroup
        v-model="formFields['bycompounds'].value"
        class="col-span-4 mb-0"
        :class="{ 'error-form-field': errorsFields.includes('bycompounds') && formSubmited }"
      >
        <RadioGroupLabel class="mb-1">How many bycompounds are you going to have?</RadioGroupLabel>
        <div class="flex w-full items-center justify-stretch">
          <RadioGroupOption as="template" v-for="bycompound in bycompounds" :key="bycompound" :value="bycompound" v-slot="{ active, checked }">
            <div
              :class="[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white ']"
              class="relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"
            >
              <div class="flex w-full items-center justify-between">
                <div class="flex items-center">
                  <div class="text-sm">
                    <RadioGroupLabel as="p" :class="checked ? 'text-white' : 'text-gray-500'" class="font-base">
                      {{ bycompound }}
                    </RadioGroupLabel>
                  </div>
                </div>
                <div v-show="checked" class="shrink-0 text-white items-right">
                  <svg class="h-5 w-5" viewBox="0 0 24 24" fill="none">
                    <circle cx="12" cy="12" r="12" fill="#fff" fill-opacity="0.2" />
                    <path d="M7 13l3 3 7-7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>

      <div v-if="showbycompoundX" class="col-span-4 flex border border-gray-100 border-solid p-1 pt-3 pb-0 -mt-3 bg-gray-50">
        <div v-if="showbycompoundX" class="grow mb-4" :class="{ 'error-form-field': errorsFields.includes('bycompoundXPct') && formSubmited }">
          <label>Bycompound x (% dry weight biomass)</label>
          <input class="w-full" type="number" v-model="formFields['bycompoundXPct'].value" />
        </div>

        <div v-if="showbycompoundY" class="grow mb-4 ml-4" :class="{ 'error-form-field': errorsFields.includes('bycompoundYPct') && formSubmited }">
          <label>Bycompound y (% dry weight biomass)</label>
          <input class="w-full" type="number" v-model="formFields['bycompoundYPct'].value" />
        </div>

        <div v-if="showbycompoundZ" class="grow mb-4 ml-4" :class="{ 'error-form-field': errorsFields.includes('bycompoundZPct') && formSubmited }">
          <label>Bycompound z (% dry weight biomass)</label>
          <input class="w-full" type="number" v-model="formFields['bycompoundZPct'].value" />
        </div>
      </div>

      <!-- ########operating days and cycle length######## -->
      <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('operatingDays') && formSubmited }">
        <label>Operating days (in one year) *</label>
        <input class="w-full" type="number" v-model="formFields['operatingDays'].value" />
      </div>

      <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('costBiomass') && formSubmited }">
        <label>Price residual biomass (€/ton) *</label>
        <input class="w-full" type="number" v-model="formFields['costBiomass'].value" />
      </div>

      <!-- ########land and land estimation######## -->
      <RadioGroup
        v-model="formFields['landCapex'].value"
        class="col-span-4 mb-0 mt-1"
        :class="{ 'error-form-field': errorsFields.includes('landCapex') && formSubmited }"
      >
        <RadioGroupLabel class="mb-1">Are you buying or renting the land? *</RadioGroupLabel>
        <div class="flex w-full items-center justify-stretch">
          <RadioGroupOption as="template" v-for="landCapex in landCapexes" :key="landCapex.key" :value="landCapex.key" v-slot="{ active, checked }">
            <div
              :class="[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white ']"
              class="relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"
            >
              <div class="flex w-full items-center justify-between">
                <div class="flex items-center">
                  <div class="text-sm">
                    <RadioGroupLabel as="p" :class="checked ? 'text-white' : 'text-gray-500'" class="font-base">
                      {{ landCapex.value }}
                    </RadioGroupLabel>
                  </div>
                </div>
                <div v-show="checked" class="shrink-0 text-white items-right">
                  <svg class="h-5 w-5" viewBox="0 0 24 24" fill="none">
                    <circle cx="12" cy="12" r="12" fill="#fff" fill-opacity="0.2" />
                    <path d="M7 13l3 3 7-7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, defineProps } from "vue";
import { useFormFieldsCreationNewRun } from "@/tea/composables/runs/useFormFieldsCreationNewRun";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { useStore } from "vuex";
import { NEW_RUN_FIELDS } from "@/tea/models/run";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import { NewspaperIcon } from "@heroicons/vue/24/outline";

const biomassTargets = ["Target compound", "Biomass"];
const bycompounds = [0, 1, 2, 3];
const landCapexes = [
  { key: "Buying", value: "Buying" },
  { key: "Renting", value: "Renting" },
];

const store = useStore();

const props = defineProps(["errorsFields", "formSubmited", "formInizializationValues"]);

const fields = computed(() => store.getters["runCreateModule/fields"]);
const ocsGoodCombo = computed(() => {
  const combo = [fields.value.organism, fields.value.cultivationSystem];
  if (combo[0] != null && combo[1] != null) {
    const isGoodCombo = props.formInizializationValues["ocsGoodCombos"].some((tuple) => tuple[0] === combo[0] && tuple[1] === combo[1]);
    return isGoodCombo;
  }
  return null;
});

const showFirstInfo = computed(() => {
  if (fields.value.compoundPct) {
    if (fields.value.biomassTarget == "Target compound" && fields.value.targetCompoundCapacity) {
      return true;
    } else if (fields.value.biomassTarget == "Biomass" && fields.value.biomass) {
      return true;
    }
  }
  if (ocsGoodCombo.value && fields.value.targetCompoundCapacity) {
    return true;
  }
  return false;
});

const showbycompoundX = computed(() => {
  const bc = Number(fields.value.bycompounds);
  if (props.formInizializationValues["organismsOptions"] && bc > 0) {
    return true;
  }
  return false;
});
const showbycompoundY = computed(() => {
  const bc = Number(fields.value.bycompounds);
  if (props.formInizializationValues["organismsOptions"] && bc > 1) {
    return true;
  }
  return false;
});
const showbycompoundZ = computed(() => {
  const bc = Number(fields.value.bycompounds);
  if (props.formInizializationValues["organismsOptions"] && bc == 3) {
    return true;
  }
  return false;
});

const calcTargetCompoundPerYear = computed(() => {
  const tcc = fields.value.targetCompoundCapacity;
  const cpct = fields.value.compoundPct;
  return Math.round(tcc / (cpct / 100));
});
const calcBiomassPerYear = computed(() => {
  const biomass = fields.value.biomass;
  const cpct = fields.value.compoundPct;
  return Math.round(biomass * (cpct / 100));
});

const getFormFields = Object.keys(NEW_RUN_FIELDS.formFields).map((key) => {
  const fieldConfig = NEW_RUN_FIELDS.formFields[key];
  return {
    [key]: Array.isArray(fieldConfig.onChangeResetFields) ? fieldConfig.onChangeResetFields : [],
  };
});
const formFieldOptions = {
  fields: getFormFields,
  mutation: "runCreateModule/SET_SINGLE_RUN_VALUE",
};
const formFields = useFormFieldsCreationNewRun(formFieldOptions);
</script>
