export const uploadFiles = async (filesToUpload, assetId) => {

    const formData = new FormData();
    const operations = `{"variables":{"files":null},"query":"mutation assetFileCreate($files:[Upload]!) {assetFileCreate(input: {asset:${assetId}, files:$files}) { assetFiles { id, file {name}}}}"}`
    formData.append("operations", operations);

    const map = `{"files": ["variables.files"]}`;
    formData.append("map", map);

    filesToUpload.map(file => {
        formData.append("files", file)
    })
    const token = localStorage.getItem("auth_token");
    const uploadResponse = await fetch(process.env.VUE_APP_ROOT_API, {
        body: formData,
        method: "post",
        headers: {
            authorization: token ? `jwt ${token}` : "",
        },
    })

    return uploadResponse;
    
}