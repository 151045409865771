import gql from "graphql-tag";

export const RUN_CREATE_MUTATION = gql`
  mutation runCreate($input: RunCreateMutationInput!) {
    runCreate(input: $input) {
      run {
        id
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
        species
        compound
      }
    }
  }
`;

export const RUN_SENSITIVITY_CALCULATE_MUTATION = gql`
  mutation runSensitivityCalculate($input: RunSensitivityCalculateMutationInput!) {
    runSensitivityCalculate(input: $input) {
      sensitivity {
        element
        value
      }
    }
  }
`;

export const RUN_UPDATE_MUTATION = gql`
  mutation runUpdate($input: RunUpdateMutationInput!) {
    runUpdate(input: $input) {
      run {
        id
      }
    }
  }
`;

export const RUN_DELETE_MUTATION = gql`
  mutation runDelete($input: RunDeleteMutationInput!) {
    runDelete(input: $input) {
      run {
        id
      }
    }
  }
`;
