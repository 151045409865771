import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "relative" }
const _hoisted_5 = {
  key: 0,
  class: "selected-results"
}
const _hoisted_6 = {
  key: 1,
  class: "flex items-center mt-3 sorry-text"
}
const _hoisted_7 = {
  key: 2,
  class: "mt-4"
}
const _hoisted_8 = { class: "flex flex-wrap" }
const _hoisted_9 = ["onChange", "value", "id"]
const _hoisted_10 = ["for"]

import { ref, PropType, watch, computed, onMounted } from 'vue';
import { XMarkIcon, MagnifyingGlassIcon, FaceFrownIcon } from '@heroicons/vue/24/outline';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchInputWithResultSelection',
  props: {
	optionsList: { type: Array as PropType<DropdownSearchableOptionInterface[]> },
	modelValue: { type: Array, required: true },
	searchLabel: { type: String, required: false },
	resultCount: {type: Number, required: true },
	isMultiple: { type: Boolean, required: true },
	selectedValues: {type: Array, required: false},
	newAssetCreated: {type: Boolean, required: false}
},
  emits: ['update:modelValue', 'searchValueChange'],
  setup(__props, { emit: __emit }) {

const props = __props

const emit = __emit;

const searchableValue = ref('');
const valuesToDisplay = ref([]);
const selectedOptions = ref([]);
const valueSubmited = ref(false);
const message = computed(() => 
					props.resultCount == 0 ? `Sorry, we could not find any results for ${searchableValue.value}. Maybe try again or create a new one` 
                    : props.resultCount >= 1000 ? 'There are too many result for thi search. Could you please be more specific?' 
					: ''
)

const store = useStore();
const newCreatedParty = ref<{id: number, nickname: string}>(store.state.partyModule.createdParty);

watch(
  () => newCreatedParty.value.id,
  () => {
	if (newCreatedParty.value.id && !selectedOptions.value.includes(newCreatedParty.value.id)) {
		const newParty = {id: newCreatedParty.value.id, name: newCreatedParty.value.nickname}
		valuesToDisplay.value.push(newParty);
		selectedOptions.value.push(newParty.id);		
		emit('update:modelValue', selectedOptions.value);
		valueSubmited.value = false;
		store.commit('partyModule/restoreCreatedParty');
	}
  }
)

watch(() => props.newAssetCreated, (newValue) => {
	if (newValue) {
		resetValues();
	}
})

const resetValues = () => {
	selectedOptions.value = [];
	valuesToDisplay.value = [];
	valueSubmited.value = false;
	searchableValue.value = '';
}

const handleSelection = (selectedOption: DropdownSearchableOptionInterface) => {
	emit('update:modelValue', selectedOptions.value);
	valuesToDisplay.value.push(selectedOption);
}

const removeValue = (idToRemove: number, index: number) => {
	valuesToDisplay.value.splice(index, 1);
	selectedOptions.value = selectedOptions.value.filter(value => value != idToRemove)
	emit('update:modelValue', selectedOptions.value)
}

onMounted(() => {
	resetValues();
	if (props.modelValue && props.modelValue.length > 0) {
		selectedOptions.value = props.modelValue
	}
	if(props.selectedValues) {
		let newSelectedValue = {id: null, name: null};
		props.selectedValues.map((value: {id:number, nickname:string}) => {
			newSelectedValue = {id: value.id, name: value.nickname};
			valuesToDisplay.value.push(newSelectedValue);
		})
	}
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (__props.searchLabel)
        ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
            _createTextVNode(_toDisplayString(__props.searchLabel), 1),
            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "ml-2" }, "Write your search criteria and HIT ENTER", -1))
          ]))
        : (_openBlock(), _createElementBlock("label", _hoisted_3, _cache[4] || (_cache[4] = [
            _createTextVNode("Search:"),
            _createElementVNode("span", { class: "ml-2" }, "Write your search criteria and HIT ENTER", -1)
          ]))),
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "w-full",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchableValue).value = $event)),
          onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => {emit('searchValueChange', searchableValue.value); valueSubmited.value = true}, ["enter"]))
        }, null, 544), [
          [_vModelText, searchableValue.value]
        ]),
        _createVNode(_unref(MagnifyingGlassIcon), { class: "icon icon-small absolute top-0.5 right-2" })
      ])
    ]),
    (valuesToDisplay.value.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(valuesToDisplay.value, (value, index) => {
            return (_openBlock(), _createElementBlock("span", { key: index }, [
              _createTextVNode(_toDisplayString(value.name) + " ", 1),
              _createVNode(_unref(XMarkIcon), {
                class: "icon icon-x-small thick inline",
                onClick: ($event: any) => (removeValue(value.id, index))
              }, null, 8, ["onClick"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (message.value !== '' && valueSubmited.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_unref(FaceFrownIcon), { class: "icon icon-small mr-3 black thick" }),
          _createElementVNode("p", null, _toDisplayString(message.value), 1)
        ]))
      : (__props.optionsList.length > 0 && valueSubmited.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _cache[5] || (_cache[5] = _createElementVNode("label", null, "Posible Results:", -1)),
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.optionsList, (option) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: option.id
                }, [
                  (!valuesToDisplay.value.includes(option))
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _withDirectives(_createElementVNode("input", {
                          type: "checkbox",
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((selectedOptions).value = $event)),
                          onChange: ($event: any) => (handleSelection(option)),
                          value: option.id,
                          id: option.id.toString(),
                          class: "hidden peer"
                        }, null, 40, _hoisted_9), [
                          [_vModelCheckbox, selectedOptions.value]
                        ]),
                        _createElementVNode("label", {
                          for: option.id.toString(),
                          class: "transition-colors duration-200 ease-in-out cursor-pointer border m-1 px-2 py-1 rounded-2xl"
                        }, _toDisplayString(option.name), 9, _hoisted_10)
                      ], 64))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true)
  ], 64))
}
}

})