<template v-if="fields && inizilizedData && errorsFields && formSubmited"> 
    <FormErrorToast :is-showing="showErrors" title="The following fields are mandatory:" :subtitle="errorsFields.join(', ')" /> 
    <FormErrorToast v-if="errorsFromMutation" :is-showing="showMutationErrors" :title="errorsFromMutation" toastType="warning" /> 
    <div class="grid grid-cols-2 gap-y-3 gap-x-4 mt-6 mb-6">
        <template v-for="(field, index) in fields" :key="index">
            <div v-if="field.type === 'select'" class="col-span-2" :class="{'error-form-field': errorsFields.includes(field.mutationValue) && formSubmited }"> 
                <label>{{ field.label }} <span v-if="field.required">*</span> </label>
                <select class="w-full" v-model="vModels[field.mutationValue].value">
                    <option selected value=null></option>
                    <option v-for="option in formInizializationValues[`${field.queryValue}Options`]" :value="Number(option.id)" :key="option.id">{{option.name}}</option>
                </select>
            </div>
            <div v-else-if="field.type === 'mulitple-select'">
                <label>{{ field.label }} <span v-if="field.required">*</span> </label>
                <DropdownSearchableMultiple v-if="formInizializationValues[`${field.queryValue}Options`]" :options-list="formInizializationValues[`${field.queryValue}Options`]" v-model="vModels[field.mutationValue].value"/>
            </div>
            <div v-else-if="field.type === 'textarea'" class="col-span-2 grid">
                <label>{{field.label}}<span v-if="field.required">*</span></label>
                <QuillEditor v-if="field.type === 'textarea'" content-type="html" @ready="onEditorReady($event)" v-model:content="vModels[field.mutationValue].value"/>
            </div>
            <div v-else-if="field.type === 'multiple-text'" class="col-span-2">
                <label>{{ field.label }} <span v-if="field.required">*</span> <span>(Press Enter to add)</span></label> 
                <input class="w-full" v-model="tempLinks" type="text" @keyup.enter="vModels[field.mutationValue].value.push(handleInputChange($event)); tempLinks = ''" />
                <div class="selected-results" v-if="vModels[field.mutationValue].value.length">
                    <span v-for="(link, index) in vModels[field.mutationValue].value" :key="index">
                        {{link}}
                        <XMarkIcon class="icon icon-x-small thick inline" @click="vModels[field.mutationValue].value.splice(index, 1)"/>
                    </span>
                </div>
            </div>
            <div v-else class="col-span-2" :class="{'error-form-field': errorsFields.includes(field.mutationValue) && formSubmited }">
                <label>{{ field.label }} <span v-if="field.required">*</span> </label>
                <input v-if="field.type === 'text'" class="w-full" type="text" v-model="vModels[field.mutationValue].value">
            </div>
        </template>
    </div>
</template>
<script setup lang="ts">
import { NEW_PARTY_FIELDS } from '@/models/newPartyFormFields';
import {computed, defineProps} from 'vue';
import { useStore } from 'vuex';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { PARTY_ADD_FORM_VALUES } from '@/grapql/partyAddFormValues';
import { useQuery } from '@vue/apollo-composable';
import DropdownSearchableMultiple from '@/components/DropdownSearchableMultiple.vue';
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import FormErrorToast from '@/components/FormErrorToast.vue';

const store = useStore();
const props = defineProps<{ 
    partyTypeSelected: string,
    errorsFields: string[],
    formIsValid: boolean,
    formSubmited: boolean,
    errorsFromMutation?: string | null,
}>()
const partyType = computed(() => props.partyTypeSelected.toUpperCase());
const showErrors = computed(() => !props.formIsValid && props.formSubmited );
const showMutationErrors = computed(() => props.formSubmited && !!props.errorsFromMutation);

const tempLinks = '';
const handleInputChange = (event: Event) => (event.target as HTMLInputElement).value;

// Inizialize values for select
const { result } = useQuery(PARTY_ADD_FORM_VALUES);
const inizilizedData = computed(() => result?.value ?? null);
const formInizializationValues = computed<{[key: string]: DropdownSearchableOptionInterface[]}>(() => useFormInizializationNewAsset(inizilizedData.value, NEW_PARTY_FIELDS[partyType.value].fieldsToQuery));


// Init rich text editor
const onEditorReady = (event: Event) => {return event};


// Create Fields for the form
const fields = computed<{label:string, type:string, mutationValue:string, queryValue:string, required: boolean}[]>(() => NEW_PARTY_FIELDS[partyType.value].formFields)

const fieldsFromConstant = computed<string[]>(() => NEW_PARTY_FIELDS[partyType.value].formFields.map(formField =>  { return formField.mutationValue} ));

const formFieldOptions = {
    fields: fieldsFromConstant.value,
    base: NEW_PARTY_FIELDS[partyType.value].graphqlValue,
    mutation: 'partyModule/setSinglePartyValue'
};

const formFields = (formFieldOptions:{fields:string[], base:string, mutation:string}) => {
    const object = {};
    formFieldOptions.fields.map(fieldName => {
        object[fieldName] = computed({
            get() {
                return store.state.partyModule.party[formFieldOptions.base][fieldName];
            },
            set(value) {
                store.commit(formFieldOptions.mutation, { partyType: formFieldOptions.base, partyField: fieldName , value: value});
            }
        })
    });
    return object;
}

const vModels = formFields(formFieldOptions);


</script>
