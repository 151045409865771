<template>
  <div class="border p-0 m-4">
    <div @click="toggleNewAnalysis" class="hover:bg-gray-50 p-4 pl-2 pr-2 border-b flex items-center cursor-pointer">
      <ChevronRightIcon class="icon icon-small inline-block mr-2" :class="{ 'rotate-90': !newAnalysisToggled }" />
      NEW DOUBLE SENSITIVITY ANALYSIS
    </div>
    <div :class="{ hidden: newAnalysisToggled }" class="p-4">
      <div>
        <FormErrorToast
          style="margin: 0 0 1rem 0"
          class="block"
          :is-showing="!!calculateError"
          title="Warning!"
          :subtitle="calculateError"
          toast-type="warning"
        />
      </div>
      <div>
        <FormErrorToast
          style="margin: 0 0 1rem 0"
          class="block"
          :is-showing="formSubmited && !formIsValid"
          title="The following fields have validation errors:"
          :subtitle="errorsFields.join(', ')"
        />
      </div>
      <form class="form-container" v-if="formInizializationValues">
        <div class="grid grid-cols-4 gap-y-4 gap-x-4">
          <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('firstVariable') && formSubmited }">
            <label>First Variable to explore *</label>
            <select class="w-full" v-model="formFields['firstVariable'].value">
              <option v-for="opt in formInizializationValues['variablesOptions']" :value="opt.name" :key="opt.id">
                {{ opt.name }}
              </option>
            </select>
          </div>
          <div v-if="fields.firstVariable" class="col-span-4 border border-gray-100 border-solid p-1 pt-3 pb-1 -mt-4 bg-gray-50">
            <div v-if="isFirstVariableEWC" class="w-full" :class="{ 'error-form-field': errorsFields.includes('firstValue') && formSubmited }">
              <label>Reference price you want to use for {{firstValueText}} *</label>
              <input class="w-full" type="number" min="0.00" max="100.00" v-model="formFields['firstValue'].value" />
            </div>
            <div v-else class="w-full flex">
              <div class="w-1/2" :class="{ 'error-form-field': errorsFields.includes('firstValueRangeMin') && formSubmited }">
                <label>Minimum price you want to use for {{fields.firstVariable}} *</label>
                <input class="w-full" type="number" min="0.00" max="100.00" v-model="formFields['firstValueRangeMin'].value" />
              </div>
              <div class="w-1/2 ml-4" :class="{ 'error-form-field': errorsFields.includes('firstValueRangeMax') && formSubmited }">
                <label>Maximum price you want to use for {{fields.firstVariable}} *</label>
                <input class="w-full" type="number" min="0.00" max="100.00" v-model="formFields['firstValueRangeMax'].value" />
              </div>
            </div>
          </div>

          <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('secondVariable') && formSubmited }">
            <label>Second Variable to explore *</label>
            <select class="w-full" v-model="formFields['secondVariable'].value">
              <option v-for="opt in formInizializationValues['variablesOptions']" :value="opt.name" :key="opt.id">
                {{ opt.name }}
              </option>
            </select>
          </div>
          <div v-if="fields.secondVariable" class="col-span-4 border border-gray-100 border-solid p-1 pt-3 pb-1 -mt-4 bg-gray-50">
            <div v-if="isSecondVariableEWC" class="w-full" :class="{ 'error-form-field': errorsFields.includes('secondValue') && formSubmited }">
              <label>Reference price you want to use for {{secondValueText}} *</label>
              <input class="w-full" type="number" min="0.00" max="100.00" v-model="formFields['secondValue'].value" />
            </div>
            <div v-else class="w-full flex">
              <div class="w-1/2" :class="{ 'error-form-field': errorsFields.includes('secondValueRangeMin') && formSubmited }">
                <label>Minimum price you want to use for {{fields.secondVariable}} *</label>
                <input class="w-full" type="number" min="0.00" max="100.00" v-model="formFields['secondValueRangeMin'].value" />
              </div>
              <div class="w-1/2 ml-4" :class="{ 'error-form-field': errorsFields.includes('secondValueRangeMax') && formSubmited }">
                <label>Maximum price you want to use for {{fields.secondVariable}} *</label>
                <input class="w-full" type="number" min="0.00" max="100.00" v-model="formFields['secondValueRangeMax'].value" />
              </div>
            </div>
          </div>

          <div class="col-span-4 text-center mt-2">
            <div v-if="!isReadyForOutput" class="col-span-4">
              <span v-if="isOutputLoading" class="button green-accept cursor-not-allowed opacity-75 hover:shadow-none" disabled>{{
                isOutputLoading ? "Loading Outputs . . ." : "Show Outputs"
              }}</span>
              <span v-else @click="showOutputs" class="button green-accept cursor-pointer">Show Outputs</span>
            </div>
          </div>
          <div v-if="isReadyForOutput && doubleSensitivityOutput" class="col-span-4 text-center mt-2">
            <div class="overflow-hidden">
              <DoubleSensitivityChart class="w-full border" :data="doubleSensitivityOutput" />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, defineProps, onMounted } from "vue";
import { useStore } from "vuex";
import { ChevronRightIcon } from "@heroicons/vue/24/solid";
import { RUN_DOUBLE_SENSITIVITY_ADD_FORM_VALUES } from "@/tea/graphql/queries/runQueries";
import { useTeaQuery } from "@/tea/composables/apollo/useTeaQuery";
import { NEW_RUN_DOUBLE_SENSITIVITY_FIELDS } from "@/tea/models/runDoubleSensitivity";
import { useFormFieldsCreationNewRunDoubleSensitivity } from "@/tea/composables/runs/useFormFieldsCreationNewRunDoubleSensitivity";
import { useFormInizializationNewRunDoubleSensitivity } from "@/tea/composables/runs/useFormInizializationNewRunDoubleSensitivity";
import { DropdownSearchableOptionInterface } from "@/models/dropdownSearchableMultipleOptionInterface";
import FormErrorToast from "@/components/FormErrorToast.vue";
import DoubleSensitivityChart from "@/tea/components/runs/charts/DoubleSensitivityChart.vue";

const yesNos = [
  { key: true, value: "Yes" },
  { key: false, value: "No" },
];

const props = defineProps(["runId"]);
const store = useStore();

onMounted(() => {
  store.commit("runDoubleSensitivityModule/SET_RUN_ID", props.runId);
});

const newAnalysisToggled = ref(false);
const toggleNewAnalysis = () => {
  newAnalysisToggled.value = !newAnalysisToggled.value;
};

// Inizialize the form
const { result } = useTeaQuery(RUN_DOUBLE_SENSITIVITY_ADD_FORM_VALUES, { id: props.runId });
store.commit("runDoubleSensitivityModule/SET_SINGLE_RUN_VALUE",{field:"firstValue",value:"asdf",resetFields:[]})
const inizilizedData = computed(() => result?.value ?? null);
const formInizializationValues = computed<{ [key: string]: DropdownSearchableOptionInterface[] }>(() =>
  useFormInizializationNewRunDoubleSensitivity(inizilizedData.value, NEW_RUN_DOUBLE_SENSITIVITY_FIELDS.fieldsToQuery)
);
const getFormFields = Object.keys(NEW_RUN_DOUBLE_SENSITIVITY_FIELDS.formFields).map((key) => {
  const fieldConfig = NEW_RUN_DOUBLE_SENSITIVITY_FIELDS.formFields[key];
  return {
    [key]: Array.isArray(fieldConfig.onChangeResetFields) ? fieldConfig.onChangeResetFields : [],
  };
});

const formFieldOptions = {
  fields: getFormFields,
  mutation: "runDoubleSensitivityModule/SET_SINGLE_RUN_VALUE",
};

const formFields = useFormFieldsCreationNewRunDoubleSensitivity(formFieldOptions);

const create = computed(() => store.getters["runDoubleSensitivityModule/create"]);
const fields = computed(() => create.value.fields);
const formSubmited = computed(() => create.value.isFormSubmitted);
const isReadyForOutput = computed(() => create.value.isReadyForOutput);
const isOutputLoading = computed(() => create.value.isOutputLoading);
const calculateError = computed(() => create.value.calculateError);
const errorsDuringMutation = computed(() => create.value.createError);

const doubleSensitivityOutput = computed(() => create.value.doubleSensitivityOutput);

const isFirstVariableEWC = computed(() => {
  const vals = ["Electricity", "Water", "CO2"];
  return vals.includes(fields.value.firstVariable);
});
const isSecondVariableEWC = computed(() => {
  const vals = ["Electricity", "Water", "CO2"];
  return vals.includes(fields.value.secondVariable);
});
const firstValueText = computed(() => {
  switch (fields.value.firstVariable) {
    case "Electricity":
      return "Electricity (€/kwh)";
    case "Water":
      return "Water (€/m3)";
    case "CO2":
      return "CO2 (€/kg)";
    default:
      return "";
  }
});

const secondValueText = computed(() => {
  switch (fields.value.secondVariable) {
    case "Electricity":
      return "Electricity (€/kwh)";
    case "Water":
      return "Water (€/m3)";
    case "CO2":
      return "CO2 (€/kg)";
    default:
      return "";
  }
});

const valuesSubmitted = computed(() => fields.value);

function getMissingFields(fieldName, fieldValue, validation) {
  const missingFields = [];
  // 1. check if required
  if (validation.required === true) {
    if (fieldValue === undefined || fieldValue === null || fieldValue === "") {
      missingFields.push(fieldName);
    }
  }

  // 2. check if validtion has valueValidation property set and aloso field has value, then check if value is truthy
  if (validation.valueValidation && fieldValue !== undefined && fieldValue !== null && fieldValue !== "") {
    const { type, values } = validation.valueValidation;
    if (type === "array") {
      if (!values.includes(fieldValue)) {
        missingFields.push(fieldName);
      }
    } else if (type === "range") {
      if (fieldValue < values[0] || fieldValue > values[1]) {
        missingFields.push(fieldName);
      }
    } else if (type === "equal") {
      if (fieldValue !== values) {
        missingFields.push(fieldName);
      }
    } else if (type === "min") {
      if (fieldValue < values) {
        missingFields.push(fieldName);
      }
    }
  }

  // 3. check dependants
  if (validation.dependants) {
    const { dependants } = validation;
    for (const condition of dependants) {
      let doCheckUp = false;
      const { ifValue, ifValueType, ifValueReference, fields, not, is } = condition;
      // Check if the field associated with the condition is truthy
      //check if there is ifValueType equals to options
      //check if it has not property
      //check if it has is property
      if (ifValueType === "option") {
        if (formInizializationValues.value[ifValueReference]) {
          let selectedItem = formInizializationValues.value[ifValueReference].find((item) => item.name === ifValue);
          if (selectedItem) {
            if (selectedItem.id == fieldValue) {
              doCheckUp = true;
            }
          }
        }
      } else if (fieldValue === ifValue) {
        doCheckUp = true;
      }

      if (not && doCheckUp) {
        for (const notCondition of not) {
          let notIfFieldValue = valuesSubmitted.value[notCondition.ifField];
          if (notCondition.ifValueType === "option") {
            if (formInizializationValues.value[notCondition.ifValueReference]) {
              let selectedItem = formInizializationValues.value[notCondition.ifValueReference].find((item) => item.name === notCondition.ifValue);
              if (selectedItem) {
                if (selectedItem.id === notIfFieldValue) {
                  //do not do checkup because it does not passes the not condition
                  doCheckUp = false;
                }
              }
            }
          } else if (notIfFieldValue === notCondition.ifValue) {
            //do not do checkup because it does not passes the not condition
            doCheckUp = false;
          }
        }
      }

      if (is && doCheckUp) {
        for (const isCondition of is) {
          let isIfFieldValue = valuesSubmitted.value[isCondition.ifField];
          if (isCondition.ifValueType === "option") {
            if (formInizializationValues.value[isCondition.ifValueReference]) {
              let selectedItem = formInizializationValues.value[isCondition.ifValueReference].find((item) => item.name === isCondition.ifValue);
              if (selectedItem) {
                if (selectedItem.id !== isIfFieldValue) {
                  //do not do checkup because it does not passes the not condition
                  doCheckUp = false;
                }
              }
            }
          } else if (isIfFieldValue !== isCondition.ifValue) {
            //do not do checkup because it does not passes the not condition
            doCheckUp = false;
          }
        }
      }

      if (doCheckUp) {
        for (const dependantField of fields) {
          // Check if the field is missing
          const dependantValue = valuesSubmitted.value[dependantField];
          if (dependantValue === undefined || dependantValue === null || dependantValue === "") {
            missingFields.push(dependantField);
          }
        }
      }
    }
  }

  // Add more validation logic here if needed.
  return Array.from(new Set(missingFields)); //Remove duplicates
}
const errorsFields = computed(() => {
  const missingFields = [];
  for (const fieldName of Object.keys(NEW_RUN_DOUBLE_SENSITIVITY_FIELDS.formFields)) {
    const validation = NEW_RUN_DOUBLE_SENSITIVITY_FIELDS.formFields[fieldName];
    const fieldValue = valuesSubmitted.value[fieldName];
    missingFields.push(...getMissingFields(fieldName, fieldValue, validation));
  }
  return Array.from(new Set(missingFields)); //Remove duplicates
});

const formIsValid = computed(() => {
  return errorsFields.value.length === 0;
});

const showOutputs = async () => {
  store.commit("runDoubleSensitivityModule/SET_CREATE_MUTATION", { key: "isFormSubmitted", value: true });
  if (formIsValid.value) {
    // get outputs now
    const mutation = NEW_RUN_DOUBLE_SENSITIVITY_FIELDS.calculateMutation;
    await store
      .dispatch(mutation)
      .then(() => {
        store.commit("runDoubleSensitivityModule/SET_CREATE_MUTATION", { key: "isReadyForOutput", value: true });
        store.commit("runDoubleSensitivityModule/SET_CREATE_MUTATION", { key: "canSubmit", value: true });
        store.commit("runDoubleSensitivityModule/SET_CREATE_MUTATION", { key: "createError", value: true });
      })
      .catch((error) => {
        console.log(error);
      });
  }
};
</script>
<style scoped lang="scss"></style>
