<template>
	<div v-if="loading" class="loading-gif"></div>
	<template v-else>
		<div class="indepth-section mb-5">
			<AssetDetailAssessmentInDepthQuantitativeProperties
				:quantitative-properties-data="quantitativePropertiesData" />
		</div>
		<div class="indepth-section mb-5">
			<Disclosure v-slot="{ open }">
				<DisclosureButton
					class="flex w-full py-2 text-left text-sm font-medium disclosure-button">
					<ChevronRightIcon :class="open ? 'chevron-rotate' : ''" class="h-5 w-5" />
					<p class="section-title">Processing Techniques</p>
				</DisclosureButton>
				<DisclosurePanel class="pl-4">
					<AssetDetailAssessmentInDepthProcessingTechniques
						v-if="processingTechniquesData && !!processingTechniquesData.length"
						:processing-techniques-data="processingTechniquesData" />
					<p v-else class="mb-4 text-sm italic text-neutral-400">
						No processing techniques assigned to this technology yet
					</p>
				</DisclosurePanel>
			</Disclosure>
		</div>
		<div class="indepth-section mb-5">
			<Disclosure v-slot="{ open }">
				<DisclosureButton
					class="flex w-full py-2 text-left text-sm font-medium disclosure-button">
					<ChevronRightIcon :class="open ? 'chevron-rotate' : ''" class="h-5 w-5" />
					<p class="section-title">Materials</p>
				</DisclosureButton>
				<DisclosurePanel class="pl-4">
					<AssetDetailAssessmentInDepthMaterials v-if="materialsData && !!materialsData.length"
						:materials-data="materialsData" />
					<p v-else class="mb-6 text-sm italic text-neutral-400">No materials assigned to this technology yet</p>
				</DisclosurePanel>
			</Disclosure>
		</div>
	</template>
</template>
<script setup lang="ts">
import { ASSET_ASSESSMENT_IN_DEPTH } from '@/grapql/assessmentInDepthQueries';
import { AssetMaterialInterface, AssetProcessingTechniqueInterface, AssetProcessingTechniquePropertyInterface, AssetQuantitativePropertyInterface, ProcessingTechniqueInterface } from '@/models/assetAssessmentInterfaces';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import AssetDetailAssessmentInDepthMaterials from './AssetDetailAssessmentInDepthMaterials.vue';
import AssetDetailAssessmentInDepthProcessingTechniques from './AssetDetailAssessmentInDepthProcessingTechniques.vue';
import AssetDetailAssessmentInDepthQuantitativeProperties from './AssetDetailAssessmentInDepthQuantitativeProperties.vue';

const store = useStore();
const assetId = computed(() => store.getters['assetDetailModule/getAssetId']);

const { result, loading } = useQuery(ASSET_ASSESSMENT_IN_DEPTH, { id: assetId },{fetchPolicy: 'cache-and-network'});
const inDepthData = computed(() => result?.value ?? null);

const materialsData = computed<AssetMaterialInterface[]>(() => inDepthData?.value?.asset?.assetMaterials?.edges.map((edge: { node: unknown; }) => edge.node) ?? null)

const processingTechniquesData = computed<AssetProcessingTechniqueInterface[]>(() => inDepthData?.value?.asset?.assetProcessingTechniques?.edges.map((edge: { node: { id: number; numberOfRuns: number | null; processingTechnique: ProcessingTechniqueInterface; assetProcessingTechniqueProperties: { edges: unknown[]; }; }; }) => {
	const object = {
		'id': edge.node.id,
		'numberOfRuns': edge.node.numberOfRuns,
		'processingTechnique': edge.node.processingTechnique,
		'assetProcessingTechniqueProperties': edge.node.assetProcessingTechniqueProperties.edges.map((edge: { node: AssetProcessingTechniquePropertyInterface; }) => edge.node)
	}
	return object
}) ?? null)

const quantitativePropertiesData = computed<AssetQuantitativePropertyInterface[]>(() => inDepthData?.value?.asset?.assetQuantitativeProperties?.edges.map((edge: { node: unknown; }) => edge.node) ?? null)
</script>
