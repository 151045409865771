<template lang="">
    <div class="ai-container" :class="{'pannel-close': !historyPannelOpen}">
        <div class="ai-history">
            <div class="history-close" v-if="!historyPannelOpen" @click="toggleHistoryPannel">
                <ChevronDoubleRightIcon class="icon icon-small internal-pannel" />
                <p>AI CHATS</p>
            </div>
            <div v-else>
                <h1 class="view-title mb-4 flex justify-between items-center">
                    <span>
                        <CpuChipIcon class="icon icon-m" /></span> 
                    <ChevronDoubleLeftIcon class="icon icon-small internal-pannel" @click="toggleHistoryPannel" />
                </h1>
                <div class="text-sm" >
                    <AIChatsHistory />
                </div>
            </div>
        </div>
        <div class="main-content">
            <AIChatHeader />
            <AIStart v-if="showStart && !currentChatId"/>
            <AIChat v-else :currentChatId="currentChatId" />
        </div>
    </div>
</template>
<script lang="ts" setup>
import {computed} from "vue";
import { useStore } from 'vuex';
import {v4 as uuid} from 'uuid';
import AIStart from "@/components/ai/AIStart.vue";
import AIChat from "@/components/ai/AIChat.vue";
import { ChevronDoubleRightIcon,ChevronDoubleLeftIcon } from '@heroicons/vue/24/solid';
import { CpuChipIcon } from '@heroicons/vue/24/outline';
import AIChatsHistory from "@/components/ai/AIChatsHistory.vue";
import AIChatHeader from "@/components/ai/AIChatHeader.vue";

const store = useStore();

// get and check if AI is started (question(s) asked)
const showStart = computed(()=> !store.state.aiModule.isStarted);

// set subscription id
store.commit("aiModule/setSubscriptionId",uuid());

// subscribe to ai response
store.dispatch("aiModule/subscribe");


const historyPannelOpen = computed(() => store.getters['aiModule/getHistoryPannelStatus']);
const toggleHistoryPannel = () => store.commit('aiModule/toggleHistoryPannel')

const currentChatId = computed(()=> store.state.aiModule.currentChatId);


</script>
<style lang="scss" scoped>
/* Optionally add some custom styles */
.menu {
    z-index: 10;
}
.ai-container {
    display: flex;
    justify-content: space-between;
    .main-content {
        width: 80%;
        justify-content: space-around;
        padding: 3rem 0rem;
        z-index: 8;
    }
    .ai-history {
        width:18%;
        padding: 3rem 1rem;
        background-color: $grey-modal;
        min-height: 100vh;
        border-right: $border;
        position: sticky;
        top: 0;
        max-height: 100vh;
        overflow-y: scroll;
        transition: width 0.1s ease-in-out;
        z-index: 9;
        overflow-x: visible;
    }
    &.pannel-close {
        .main-content {
            width: calc(100% - 80px);
        }
        .ai-history {
            width: 82px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .history-close {
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 100%;
        cursor: pointer;
        .icon {
            position: absolute;
            top: 58px;
            padding: 5px;
            width: 2rem;
            height: 2rem;
        }
        p {
            transform: rotate(-180deg);
            writing-mode: tb-lr;
            writing-mode: vertical-lr;
            font-size: 4rem;
            text-transform: uppercase;
            color: #e7e7e7;
            font-weight: 900;
        }
        
    }
}
</style>