import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, unref as _unref, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-6 pb-8" }
const _hoisted_2 = { class: "filter-values flex-wrap grid grid-cols-2" }
const _hoisted_3 = { class: "flex flex-col leading-4" }
const _hoisted_4 = { class: "text-sm uppercase font-medium text-black" }
const _hoisted_5 = { class: "text-xs text-neutral-400" }
const _hoisted_6 = ["id", "value"]
const _hoisted_7 = {
  key: 1,
  class: "flex items-center"
}

import { useQuery } from "@vue/apollo-composable";
import ModalSlots from "./ModalSlots.vue";
import { computed, ref} from 'vue';
import { FaceFrownIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';
import { BENCHMARK_LIST } from "@/grapql/userWorkspaceQueryMutations";
import { WorkspaceFromBE } from '@/models/benchmarkModels';
import BenchmarkIcon from '@/components/icons/BenchmarkIcon.vue';
import moment from 'moment';
import router from "@/router";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramVersionBenchmarkList',
  props: {
    isOpen: { type: Boolean }
  },
  emits: ['closeModal'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;

const store = useStore();

const { result } = useQuery(BENCHMARK_LIST, {filter: {workspaceType: {exact: "ASSET_BENCHMARK"}}}, () => ({ enabled: props.isOpen,}));
const inizilizedData = computed(() => result?.value ?? null);
const benchmarkList = computed<WorkspaceFromBE[]>(() => inizilizedData?.value?.userWorkspaces?.edges.map((edge: {node: WorkspaceFromBE}) => edge.node)  ?? null);
const selectedTable = ref();
const handleOpenBenchmarkTable = () => {
    if(selectedTable.value) {
        store.commit('programVersionDetailModule/setBenchmarkValues', {id: selectedTable.value.id, tableData: JSON.parse(selectedTable.value.value), tableTitle: selectedTable.value.title});
        emits('closeModal');
        const title = (selectedTable.value.title).replaceAll(' ', '-');
        router.push({ name: 'Benchmark', params: { title: `${selectedTable.value.id}-${title}`} });
    }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalSlots, {
    isOpen: _ctx.isOpen,
    dialogClass: "m-modal",
    onCloseModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeModal'))),
    createText: "open",
    onClickCreate: handleOpenBenchmarkTable
  }, {
    header: _withCtx(() => _cache[2] || (_cache[2] = [
      _createElementVNode("p", null, "Saved Benchmarks", -1)
    ])),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (benchmarkList.value)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _cache[3] || (_cache[3] = _createElementVNode("p", { class: "font-normal mb-4" }, "Please select the benchamark you want to see", -1)),
              _createElementVNode("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(benchmarkList.value, (table) => {
                  return (_openBlock(), _createElementBlock("label", {
                    key: table.id,
                    class: _normalizeClass(["filter-option hidden-check mr-4", {'border-on': selectedTable.value == table}])
                  }, [
                    _createVNode(BenchmarkIcon, {
                      class: _normalizeClass(["w-2/3 max-w-12 pr-4 pl-1 fill-black", [selectedTable.value != table ? 'opacity-50' : 'opacity-100']])
                    }, null, 8, ["class"]),
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("p", _hoisted_4, _toDisplayString(table.title), 1),
                      _createElementVNode("p", _hoisted_5, "Created by " + _toDisplayString(table.createdBy.username) + " on " + _toDisplayString(_unref(moment)(table.createdAt).format('DD MMM YYYY')), 1)
                    ]),
                    _withDirectives(_createElementVNode("input", {
                      type: "radio",
                      id: table.title,
                      value: table,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedTable).value = $event))
                    }, null, 8, _hoisted_6), [
                      [_vModelRadio, selectedTable.value]
                    ])
                  ], 2))
                }), 128))
              ])
            ], 64))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_unref(FaceFrownIcon), { class: "stroke-1 stroke-black w-12 mr-4" }),
              _cache[4] || (_cache[4] = _createElementVNode("p", { class: "text-black" }, [
                _createTextVNode("Ops! There are no tables created yet. "),
                _createElementVNode("span", { class: "block" }, "You can click in the create option and start creating your benchmarking tables")
              ], -1))
            ]))
      ])
    ]),
    _: 1
  }, 8, ["isOpen"]))
}
}

})