import { propertiesInterface } from "@/models/assetAssessmentInterfaces";

export function useNodeContentAsList(edgesList: any[], paramToExtract:string) {
    return edgesList.length != 0 ?  edgesList.map(edge => edge.node[paramToExtract]) : null
} 

export function useNodeContentAsListWithConcat(edgesList: any[], paramToExtract: string, paramToConcat: string, concatenateAtBeginning?: boolean) {
    return edgesList.length !== 0 ? edgesList.map(edge => {
      if (concatenateAtBeginning) {
          return edge.node[paramToConcat] ? `(${edge.node[paramToConcat]}) - ${edge.node[paramToExtract]}` : edge.node[paramToExtract];
      } else {
          return edge.node[paramToConcat] ? `${edge.node[paramToExtract]} - (${edge.node[paramToConcat]})` : edge.node[paramToExtract];
      } 
    }) : null;
}

export function useParsingSourcesToDisplay(edgesList: any[])  {
    return edgesList.length != 0 ?  edgesList.map(edge => {
       return edge.node.externalSourceId != "" && edge.node.externalSourceId != null ? `${edge.node.source.name} (ID: ${edge.node.externalSourceId})` : edge.node.source.name
    }) : null
}

export function useNodeContentToProperties(edgesList: any[]): propertiesInterface[] {
    const propertiesMap = {};
    edgesList.forEach((edge) => {
      const { className, category, name: propertiesNames } = edge.node;
      if (!propertiesMap[className]) {
        propertiesMap[className] = {};
      }
      if (!propertiesMap[className][category]) {
        propertiesMap[className][category] = [];
      }
      propertiesMap[className][category].push(propertiesNames);
    });
  
    const properties = Object.keys(propertiesMap).map((className) => ({
      className,
      category: Object.keys(propertiesMap[className]).map((category) => ({
        categoryName: category,
        propertiesNames: propertiesMap[className][category],
      })),
    }));
    return properties;
  } 