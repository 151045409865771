<template>
   <div class="asset-pt"> 
      <p>processing technique PROPERTIES</p>
      <PlusCircleIcon class="icon" @click="onAddition = true" v-if="!onAddition"/>
   </div>
   <template v-if="onAddition">
      <AssetProcessingTechniquePropertyAddForm 
         @cancel-addition="onAddition = false" 
         :asset-processing-technique-properties="assetProcessingTechniqueProperties"
         :asset-processing-technique-id="assetProcessingTechniqueId"
         :processing-technique-id="processingTechniqueId"
         />
   </template>
   <div v-if="!!assetProcessingTechniqueProperties.length" class="flex justify-between items-center flex-wrap mb-4">
      <template v-for="property in assetProcessingTechniqueProperties" :key="property.id">
         <AssetProcessingTechniqueProperty 
            :asset-processing-technique-property="property"
            :asset-processing-technique-id="assetProcessingTechniqueId"
            :processing-technique-id="processingTechniqueId" />
      </template>
   </div>
</template>
<script setup lang="ts">
import { AssetProcessingTechniquePropertyInterface } from '@/models/assetAssessmentInterfaces';
import AssetProcessingTechniqueProperty from './AssetProcessingTechniqueProperty.vue';
import { PlusCircleIcon } from '@heroicons/vue/24/outline';
import { ref, defineProps } from 'vue';
import AssetProcessingTechniquePropertyAddForm from './AssetProcessingTechniquePropertyAddForm.vue';

defineProps<{
   assetProcessingTechniqueProperties: AssetProcessingTechniquePropertyInterface[];
   assetProcessingTechniqueId: number;
   processingTechniqueId: number
}>();
const onAddition = ref(false)

</script>
<style lang="scss" scoped>
   .asset-pt {
      display: flex;
      align-items: center;
      justify-content: space-between;  
      border-top: 1px dashed #ddd;
      border-bottom: 1px dashed #ddd;
      padding: 0.5rem 0; 
      margin: 1rem 0;
      > p {
         font-weight: 400;
         font-size: 0.9rem;
         text-transform: uppercase;
         color: $font-color-light;
      }
   }
   .icon {
        stroke-width: 1;
        width: 22px;
        height: 22px;
        stroke: #999;
    }
    
    .label {
		font-size: 0.9rem;
		color: $font-color-super-light;
		font-weight: 300;
	}

	.value {
		font-size: 0.9rem;
		color: rgb(109, 109, 109);
		font-weight: 500;
		padding-bottom: 0.5rem;
		overflow: auto;
		text-transform: uppercase;
		overflow: visible;
	}
   .edit-assessment{
      position: relative;
   }
   .button-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: -1rem;
      margin-bottom: 1rem;
   }
</style>