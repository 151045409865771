import { POSITION, useToast } from "vue-toastification";
import { RUN_DOUBLE_SENSITIVITY_CALCULATE_MUTATION } from "@/tea/graphql/mutations/runDoubleSensitivityMutations";
import { useTeaMutation } from "@/tea/composables/apollo/teaUseMutation";
import { useTeaQuery } from "@/tea/composables/apollo/useTeaQuery";
import { RUNS_QUERY } from "@/tea/graphql/queries/runQueries";
import { RUN_DELETE_MUTATION, RUN_UPDATE_MUTATION } from "@/tea/graphql/mutations/runMutations";
import { useRawRunToStateType } from "@/tea/composables/runs/useRawRunToStateType";

const getDefaultCreateState = () => {
  return {
    fields: {
      run: null,
      firstVariable: null,
      secondVariable: null,
      firstValue: null,
      secondValue: null,
      firstValueRangeMin: null,
      firstValueRangeMax: null,
      secondValueRangeMin: null,
      secondValueRangeMax: null,
    },
    error: null,
    isReadyForOutput: false,
    canSubmit: false,
    doubleSensitivityOutput: null,
    calculateError: null,
    createError: null,
    isOutputLoading: false,
    isFormSubmitted: false,
  };
};
const getDefaultState = () => {
  return {
    runId: null,
    repo: {
      runs: [],
      totalCount: 0,
      edgeCount: 0,
      loading: true,
      pageInfo: null,
      error: null,
    },
    create: getDefaultCreateState(),
    error: null,
  };
};

const state = getDefaultState();

const mutations = {
  SET_RUN_ID(state, id) {
    state.runId = id;
    state.create.fields.run = id;
  },
  SET_SINGLE_RUN_VALUE(state, payload: { field: string; value: Date | number | string | number[] | null; resetFields: [] }) {
    state.create.fields[payload.field] = payload.value;
    state.create.isReadyForOutput = false;
    state.create.canSubmit = false;
    state.create.isOutputLoading = false;
    payload.resetFields.forEach((field) => {
      state.create.fields[field] = getDefaultState().create.fields[field];
    });
  },
  SET_CREATE_MUTATION(state, payload: { key: string; value: Date | number | string | number[] | null }) {
    state.create[payload.key] = payload.value;
  },
  RESET_CREATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_DOUBLE_SENSITIVITY_OUTPUT(state, sensitivity) {
    state.create.doubleSensitivityOutput = sensitivity;
  },
};

const actions = {
  // RUN SENSITIVITY SHOW OUTPUT
  async runDoubleSensitivityCalculate({ state, commit, dispatch }) {
    commit("SET_CREATE_MUTATION", { key: "calculateError", value: null });
    commit("SET_CREATE_MUTATION", { key: "isOutputLoading", value: true });
    return new Promise<void>((resolve, reject) => {
      const { mutate: runSensitivityCalculate, onDone, onError } = useTeaMutation(RUN_DOUBLE_SENSITIVITY_CALCULATE_MUTATION);
      const runInput = Object.assign({}, state.create.fields);
      if (runInput.firstValueRangeMin && runInput.firstValueRangeMax) {
        runInput.firstValueRange = [runInput.firstValueRangeMin, runInput.firstValueRangeMax];
      }
      if (runInput.secondValueRangeMin && runInput.secondValueRangeMax) {
        runInput.secondValueRange = [runInput.secondValueRangeMin, runInput.secondValueRangeMax];
      }

      // Remove the original "Min" and "Max" fields
      delete runInput.firstValueRangeMin;
      delete runInput.firstValueRangeMax;
      delete runInput.secondValueRangeMin;
      delete runInput.secondValueRangeMax;
      runSensitivityCalculate({ input: runInput }, { fetchPolicy: "no-cache" });
      onDone((result) => {
        
        commit("SET_DOUBLE_SENSITIVITY_OUTPUT", result.data.runDoubleSensitivityCalculate.doubleSensitivity);
        commit("SET_CREATE_MUTATION", { key: "isOutputLoading", value: false });
        resolve();
      });
      onError((error) => {
        commit("SET_DOUBLE_SENSITIVITY_OUTPUT", null);
        commit("SET_CREATE_MUTATION", { key: "calculateError", value: error.message });
        commit("SET_CREATE_MUTATION", { key: "isOutputLoading", value: false });
        reject(error.message);
      });
    });
  },
};

const getters = {
  create(state) {
    return state.create;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
