import gql from 'graphql-tag';

export const PATENT_QUERY = gql`
    query getPatent($id: ID!) {
        patent(id:$id) {
            id
            createdAt
            createdBy { firstName lastName }
            updatedAt
            updatedBy { firstName lastName}
            nickname
            title
            abstract
            description
            assetType
            publicationDate
            url
            links
            trl
            notes
            patentId
            patentIds
            claims {edges {node {id claim}}}
            priorityDate
            expiryDate
            grantedDate
            isPrepatent
            status { id name }
            inventors {edges {node {id nickname partyType}}}
            assignees {edges {node {id nickname partyType}}}
            owners {edges {node {id nickname partyType}}}
            pendingTerritories {edges {node {id name}}}
            grantedTerritories {edges {node {id name}}}
            expiredTerritories {edges {node {id name}}}
            assetSources {edges {node {externalSourceId source {id name}}}}
            applicationTypes {edges {node {id name}}}
            tags 
            materials {edges {node {id name acronym}}}
            properties{edges {node {id name category className}}}
            assetFiles {edges {node {id file {name url} deleted}}} 
            processingTechniques{ edges {node {id name}}}
            standards {edges {node {id code title}}}
            assetStatus {id name}
            statusReason
            assetLinkedPrograms {
                edges {
                    node {
                        id
                        program {id name}
                        linkType {id name}
                        linkStatus {id name colorCode}
                        statusReason
                    }
                }
            }
            assetPrice {id price quantity unitType{id name} unitTypeSize notes}
        }
    }`
