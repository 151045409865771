<template>
  <div class="col-span-4">
    <div class="grid grid-cols-4 gap-y-3 gap-x-4">
      <!-- show output button -->
      <div v-if="!isReadyForOutput" class="col-span-4">
        <span v-if="isOutputLoading" class="button green-accept cursor-not-allowed opacity-75 hover:box-shadow:none" disabled>Loading Outputs . . .</span>
        <span v-else @click="$emit('showOutputs')" :disabled="isOutputLoading" class="button green-accept" style="cursor: pointer;">Show Outputs</span>
      </div>
      <!-- show CAPEX -->
      <div v-if="isReadyForOutput && teaOutput" class="md:col-span-2 sm:col-span-4">
        <div class="mb-4 p-4 bg-gray-100 border">
          Your CAPEX is: &nbsp;<span class="font-bold"> {{ teaOutput.capex.toLocaleString() }} €</span>
        </div>
        <table class="min-w-full border mb-4">
          <thead>
            <tr>
              <th colspan="2" class="bg-gray-100 border py-2 px-4 text-left">Fixed Capital Investment</th>
            </tr>
            <tr class="bg-gray-100">
              <th class="py-2 px-4 text-left">Type of Cost</th>
              <th class="py-2 px-4 text-left">EUR</th>
            </tr>
          </thead>
          <tbody>
            <tr class="border border-dashed" v-for="(fci, index) in fixedCapitalInvestment" :key="index" :class="{ a: index % 2 === 0 }">
              <td class="py-2 px-4">{{ fci.typeOfCost }}</td>
              <td class="py-2 px-4">{{ fci.cost.toLocaleString() }}</td>
            </tr>
          </tbody>
        </table>
        <div class="overflow-hidden">
          <MecChart class="w-full border" :data="teaOutput.majorEquipmentCosts" />
        </div>
      </div>
      <!-- show OPEX -->
      <div v-if="isReadyForOutput && teaOutput" class="md:col-span-2 sm:col-span-4">
        <div class="mb-4 p-4 bg-gray-100 border">
          Your OPEX is: &nbsp;<span class="font-bold"> {{ teaOutput.opex.toLocaleString() }} €</span>
        </div>
        <div class="overflow-hidden">
          <OeChart class="w-full border" :data="teaOutput.operatingExpenses" />
        </div>
      </div>
      <!-- show INCOME -->
      <div v-if="isReadyForOutput && teaOutput" class="md:col-span-4 sm:col-span-4">
        <div class="mb-0 mt-4 p-4 bg-gray-100 border">
          Expected yearly income &nbsp;<span class="font-bold"> {{ teaOutput.income.yearlyIncome.toLocaleString() }} €</span>
        </div>
        <div class="mb-4 p-4 bg-gray-50 border">{{ teaOutput.income.incomeMessage }}</div>
        <div class="overflow-hidden">
          <NpvChart class="w-full border" :data="teaOutput.income.npvs" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, defineProps, defineEmits } from "vue";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { useStore } from "vuex";
import MecChart from "@/tea/components/runs/charts/MecChart.vue";
import OeChart from "@/tea/components/runs/charts/OeChart.vue";
import NpvChart from "@/tea/components/runs/charts/NpvChart.vue";

const store = useStore();
const props = defineProps(["isReadyForOutput"]);
const emits = defineEmits(["showOutputs"]);

const isOutputLoading = computed(() => store.getters["runCreateModule/isOutputLoading"]);
const ShowOutputButtonText = computed(() => (isOutputLoading.value ? "Loading Outputs . . ." : "Show Outputs"));

const teaOutput = computed(() => store.getters["runCreateModule/teaOutput"]);

const fixedCapitalInvestment = computed(() => {
  const fci = teaOutput.value.fixedCapitalInvestment.map((item) => {
    return { typeOfCost: item.typeOfCost, cost: item.cost };
  });
  return fci;
});
</script>
