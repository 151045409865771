import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "add-on-disclosure" }
const _hoisted_2 = {
  key: 1,
  class: "mb-4 text-sm italic text-neutral-400"
}

import { AssetQuantitativePropertyInterface } from '@/models/assetAssessmentInterfaces';
import { ref } from 'vue';
import { PlusCircleIcon } from '@heroicons/vue/24/outline';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import AssetQuantitativeProperty from './AssetQuantitativeProperty.vue';
import AssetQuantitativePropertyAddForm from './AssetQuantitativePropertyAddForm.vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'AssetDetailAssessmentInDepthQuantitativeProperties',
  props: {
    quantitativePropertiesData: {}
  },
  setup(__props: any) {



const onAddition = ref(false);



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Disclosure), { defaultOpen: true }, {
    default: _withCtx(({ open }) => [
      _createVNode(_unref(DisclosureButton), { class: "flex w-full py-2 text-left text-sm font-medium disclosure-button" }, {
        default: _withCtx(() => [
          _createVNode(_unref(ChevronRightIcon), {
            class: _normalizeClass([open ? 'chevron-rotate' : '', "h-5 w-5"])
          }, null, 8, ["class"]),
          _cache[2] || (_cache[2] = _createElementVNode("p", { class: "section-title" }, "Quantitative Properties", -1))
        ]),
        _: 2
      }, 1024),
      _createVNode(_unref(DisclosurePanel), { class: "pl-4" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_unref(PlusCircleIcon), {
              class: "icon",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (onAddition.value = true))
            })
          ]),
          (!!_ctx.quantitativePropertiesData.length)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.quantitativePropertiesData, (quantitativeProperty) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: quantitativeProperty.id,
                  class: "quantitative-info"
                }, [
                  _createVNode(AssetQuantitativeProperty, { "asset-quantitative-property": quantitativeProperty }, null, 8, ["asset-quantitative-property"])
                ]))
              }), 128))
            : (_openBlock(), _createElementBlock("p", _hoisted_2, " No quantitative properties assigned to this technology yet ")),
          (onAddition.value)
            ? (_openBlock(), _createBlock(AssetQuantitativePropertyAddForm, {
                key: 2,
                "quantitative-properties-data": _ctx.quantitativePropertiesData,
                onCancelAddition: _cache[1] || (_cache[1] = ($event: any) => (onAddition.value = false))
              }, null, 8, ["quantitative-properties-data"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})