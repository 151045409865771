import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white cursor-pointer flex overflow-hidden shadow-md justify-between border px-6 py-3 mb-4 rounded-lg -mt-3" }
const _hoisted_2 = { class: "flex flex-col w-4/5 justify-center" }
const _hoisted_3 = { class: "text-sm text-gray-700" }
const _hoisted_4 = ["src"]


import FunnelCard from './FunnelCard.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PlaygroundPage',
  props: ['briefData'],
  setup(__props) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("p", { class: "uppercase font-semibold mb-1 text-gray-700" }, "Package Description", -1)),
        _createElementVNode("p", _hoisted_3, _toDisplayString(__props.briefData.description), 1)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(`image-container ${__props.briefData.imageColor}`)
      }, [
        _createElementVNode("img", {
          src: require(`@/assets/images/${__props.briefData.image}`),
          class: "max-w-12"
        }, null, 8, _hoisted_4)
      ], 2)
    ]),
    _createVNode(FunnelCard, { "brief-data": __props.briefData }, null, 8, ["brief-data"])
  ], 64))
}
}

})