import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "program-detail-container"
}
const _hoisted_2 = { class: "stages-sidebar" }
const _hoisted_3 = { class: "program-detail-section" }
const _hoisted_4 = {
  key: 1,
  class: "page-padding"
}

import { computed, ref } from 'vue';
import ProgramDetailStageSidebar from '@/components/ProgramDetailStagesSidebar.vue';
import ProgramDetailOverview from '@/components/ProgramDetailOverview.vue';
import { useRoute } from 'vue-router';
import { PROGRAMS_INFO } from '@/models/programsInfo';
import { PROGRAM_VERSION_QUERY } from '@/grapql/programVersionQuery';
import { useQuery } from '@vue/apollo-composable';
import store from '@/store';



export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramDetail',
  setup(__props) {

const route = useRoute()
const programId = route.params.id

const isMockup = computed(() => !route.name.toString().includes('WIP'))

const programDetailMockup = computed(() => programs.find(program => program.id === programId))
const programs = PROGRAMS_INFO;

const programVersion = ref(null)
const { onResult } = useQuery(PROGRAM_VERSION_QUERY, { id: programId });
onResult(queryResult => {
    if(queryResult.data && queryResult.data.programVersion) {
        programVersion.value = queryResult?.data.programVersion;
        store.commit('programVersionDetailModule/setProgramVersionValuesFromBE', {programVersion: programVersion.value})
    }
    
})




return (_ctx: any,_cache: any) => {
  return (isMockup.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(ProgramDetailStageSidebar, {
            "active-stage": programDetailMockup.value.stage
          }, null, 8, ["active-stage"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(ProgramDetailOverview, {
            "program-detail-info-mockup": programDetailMockup.value,
            "is-mockup": isMockup.value
          }, null, 8, ["program-detail-info-mockup", "is-mockup"])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        (programVersion.value)
          ? (_openBlock(), _createBlock(ProgramDetailOverview, {
              key: 0,
              "program-version": programVersion.value,
              "is-mockup": isMockup.value
            }, null, 8, ["program-version", "is-mockup"]))
          : _createCommentVNode("", true)
      ]))
}
}

})