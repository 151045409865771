<template>
  <div>
    <!-- <SidebarMenu v-if="!$route.meta.hideSidebar" /> -->
    <AppSidebarMenu v-if="!$route.meta.hideSidebar" />
    <div :class="{ 'page-content': !$route.meta.hideSidebar, 'collapsed': collapsed }">
      <router-view/>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { useStore } from 'vuex';
  import SidebarMenu from './components/SidebarMenu.vue';
  import TeaSidebarMenu from '@/tea/components/SidebarMenu.vue';
  import { computed } from 'vue';
  import { useRecaptchaProvider } from "vue-recaptcha";
  import domain  from "@/utils/appDomain";

  const store = useStore();
  const collapsed = computed(() => store.getters['authModule/isSidebarCollapsed'])
  
  let AppSidebarMenu=null
  if(domain.isTea){
    useRecaptchaProvider();
    AppSidebarMenu=TeaSidebarMenu
  }else{
    AppSidebarMenu = SidebarMenu;
  }

</script>

<style lang="scss">
  #app {
    font-family: $primary-font;
  }
  .page-content {
    margin-left: 280px;
    background-color: $grey-light;
    min-height: 100vh;
    &.collapsed {
      margin-left: 80px;
    }
  }
</style>