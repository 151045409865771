import gql from "graphql-tag";

export const ASSET_MAIN_CONTENT = gql`
query getAssetMainContentQueries {
    patentStatuses {
        edges {
            node {
                id
                name
            }
        }
    }
    sources {
        edges {
            node {
                id
                name
            }
        }
    }
    parties (orderBy: "nickname") {
        edges {
            node {
                id
                nickname
                partyType
            }
        }
    }
    countries {
        edges {
            node {
                id
                name
                code
            }
        }
    }
    otherTechnologyTypes { 
        edges {
            node {
                id 
                name
            }
        }
    }
    unitTypes {
        edges {
            node {
                id
                name
            }
        }   
    }
}`