import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-6" }

import { SparklesIcon } from '@heroicons/vue/24/outline';
import ModalSlots from "@/components/ModalSlots.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import ResearchPaperAutoFill from '@/components//ResearchPaperAutoFill.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ResearchPaperAutofillModal',
  emits: ["researchPaperAutofilled"],
  setup(__props, { emit: __emit }) {

const store = useStore();
const emit = __emit;

const isOpen = ref(false);

const closeModal = () => { isOpen.value = false;}
const openModal = () => {
    store.commit("researchPaperAutofillModule/resetStore");
    isOpen.value = true; 
}


const showExtractResult = computed(()=> store.state.researchPaperAutofillModule.showExtractResult);
const extractResult = computed(()=> store.state.researchPaperAutofillModule.extractResult);
const resetExtractResult=()=>{
    store.commit("researchPaperAutofillModule/resetExtractResult");
}
const confirmExtractResult= async()=>{
    
    await store.dispatch("assetCreationModule/researchPaperAutofill",extractResult.value.tempUuid)
        .catch((error)=>{
            console.log('error in autofill')
        })
        .then(()=>{
            closeModal();
            emit("researchPaperAutofilled");
        });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: "button green-accept flex items-center",
        onClick: _withModifiers(openModal, ["prevent"])
      }, [
        _createVNode(_unref(SparklesIcon), { class: "icon icon-small mr-4 stroke-white" }),
        _cache[0] || (_cache[0] = _createTextVNode(" Use Auto Fill Service "))
      ])
    ]),
    _createVNode(ModalSlots, {
      show: isOpen.value,
      dialogClass: "create-asset-modal",
      onCloseModal: closeModal,
      "hide-footer": !showExtractResult.value
    }, {
      header: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("p", null, null, -1)
      ])),
      body: _withCtx(() => [
        _createVNode(ResearchPaperAutoFill)
      ]),
      footer: _withCtx(() => [
        _createElementVNode("button", {
          class: "button cancel",
          onClick: resetExtractResult
        }, "Reject Extracted Info"),
        _createElementVNode("button", {
          class: "button green-accept",
          onClick: confirmExtractResult
        }, "Confirm and Fill The Form")
      ]),
      _: 1
    }, 8, ["show", "hide-footer"])
  ]))
}
}

})