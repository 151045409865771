import { useMutation } from "@vue/apollo-composable";
import { provideApolloClient } from "@vue/apollo-composable";
import apolloClient from "@/utils/apolloClient";
import { POSITION, useToast } from "vue-toastification";
import { format } from "date-fns";
import { NewProgramVersionInterface } from "@/models/programVersionsInterface";
import { PROGRAM_CREATE, PROGRAM_VERSION_CREATE_WITH_TEAM_AND_TARGETS } from "@/grapql/programsAndVersionCreateMutation";
import { newProgramInputInterface } from "@/models/programsInterface";


provideApolloClient(apolloClient);
const toast = useToast();

const getDefaultState = () => {
  return {
    program: {
      name: null,
      goal: null,
      creationDate: new Date,
      description: null,
    },
    programVersion: {
      goal: null,
      creationDate: new Date,
      description: null,
      program: null,
      version: null,
      applicationTypes: [],
      programVersionTeam: [],
      programVersionTargets: [],
    },
    errorDuringMutation: {
      newProgramVersion: null,
      newProgram: null,
    },
    
  };
};
const state = getDefaultState();

const mutations = {
  setSingleProgramValue(state, payload: {programField: string, value: Date | string | null }) {
      state.program[payload.programField] = payload.value;
  },

  setSingleProgramVersionValue(state, payload: {programField: string, value: Date | string | null | []}) { 
    state.programVersion[payload.programField] = payload.value;
  },
  clearErrors(state, payload: {type: string}) {
    state.errorDuringMutation[payload.type] = null;
  },
  setErrorOnCreationResponse(state, payload: {error: string, type: string}) {
    if (payload.error.includes('already exists') ) {
      state.errorDuringMutation[payload.type] = 'Ops! what you are trying to create already exists'   
    } else {
        state.errorDuringMutation[payload.type] = payload.error
    }
  },
  resetProgramValues(state) {
    state.program = getDefaultState().program
  },
  resetProgramVersionValues(state) {
    state.programVersion = getDefaultState().programVersion
  }
};

const actions = {

    // CREATE NEW PROGRAMME
    programCreate({state, commit}) {
      return new Promise<void>((resolve) => {
          const { mutate: programCreate, onDone, onError } = useMutation(PROGRAM_CREATE);
          const programInput: newProgramInputInterface = Object.assign({}, state.program);
          programInput.creationDate = format(state.program.creationDate, 'yyyy-MM-dd');
          programCreate({input: programInput});
          onDone( async () => { 
              await commit('clearErrors', {type: 'newProgram'});
              toast.success(`New programme created`, { position: POSITION.BOTTOM_LEFT, timeout: 1524 });
              await apolloClient.refetchQueries({include: ['getProgramVersions']});
              resolve()
          });
          onError( async (error) => {
              commit('setErrorOnCreationResponse', {error: error.message, type: 'newProgram'})
              resolve() 
          })
      })
    },

    // CREATE NEW PROGRAM VERSION WITH TEAM AND TARGETS
    programVersionCreateWithTargetsAndTeam({state, commit}) {
      return new Promise<void>((resolve) => {
          const { mutate: programCreate, onDone, onError } = useMutation(PROGRAM_VERSION_CREATE_WITH_TEAM_AND_TARGETS);
          const programVersionInput: NewProgramVersionInterface = Object.assign({}, state.programVersion);
          programVersionInput.creationDate = format(state.programVersion.creationDate, 'yyyy-MM-dd');
          if (programVersionInput.programVersionTargets.length > 0) {
            programVersionInput.programVersionTargets.map(target => target.creationDate = format(target.creationDate, 'yyyy-MM-dd') )
          }
          programCreate({input: programVersionInput});
          onDone( async () => { 
              await commit('clearErrors', {type: 'newProgramVersion'});
              toast.success(`New programme version created`, { position: POSITION.BOTTOM_LEFT, timeout: 1524 });
              await apolloClient.refetchQueries({include: ['getProgramVersions']});
              resolve()
          });
          onError( async (error) => {
              commit('setErrorOnCreationResponse', {error: error.message, type: 'newProgramVersion'})
              resolve() 
          })
      })
    },

}

const getters = {
  programValues()  {
    return state.program
  },
  newProgramErrorOnMutation() {
    return state.errorDuringMutation.newProgram
  } 
}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
