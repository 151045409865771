<template>
   <template v-if="!onEdit">
      <div class="w-full">
      <div class="flex">
         <p class="label mr-3">
            {{ assetProcessingTechniqueProperty.processingTechniqueProperty.name }}
            <span v-if="assetProcessingTechniqueProperty.processingTechniqueProperty.category">({{
            assetProcessingTechniqueProperty.processingTechniqueProperty.category }})</span>
         </p>
         <PencilSquareIcon class="icon" @click="onEdit = true" v-if="!onEdit"/>
      </div>
      <!-- Different displays based on unit type -->
      <template v-if="assetProcessingTechniqueProperty.valueType">
         <p v-if="assetProcessingTechniqueProperty.valueType.name == 'Between'" class="value">
            {{ assetProcessingTechniqueProperty.value1 }} - {{ assetProcessingTechniqueProperty.value2 }}
            <span v-if="assetProcessingTechniqueProperty.valueUnit" class="lowercase">{{
               assetProcessingTechniqueProperty.valueUnit.abbreviation }}</span>
         </p>
         <template v-else-if="assetProcessingTechniqueProperty.valueType.name == 'Boolean'">
            <p v-if="assetProcessingTechniqueProperty.value1 && assetProcessingTechniqueProperty.value1 == 'true'" class="value">Yes</p>
            <p v-else class="value">No</p>
         </template>
         <p v-else class="value">
            {{ assetProcessingTechniqueProperty.valueType.symbol }} {{ assetProcessingTechniqueProperty.value1 }}
            <span v-if="assetProcessingTechniqueProperty.valueUnit" class="lowercase">{{
               assetProcessingTechniqueProperty.valueUnit.abbreviation }}</span>
         </p>
      </template>
      <p v-else class="value">
         {{ assetProcessingTechniqueProperty.value1 }}
         <span v-if="assetProcessingTechniqueProperty.valueUnit" class="lowercase">{{
            assetProcessingTechniqueProperty.valueUnit.abbreviation }}</span>
      </p>
   </div>
   <div class="w-full" v-if="assetProcessingTechniqueProperty.extraInformation">
      <p class="label">Extra Information</p>
      <p class="text-sm italic mb-4" v-html=" assetProcessingTechniqueProperty.extraInformation"></p>
   </div>   
   </template>
   <template v-else>
      <AssetProcessingTechniquePropertyEditForm 
         :asset-processing-technique-property="assetProcessingTechniqueProperty" 
         :asset-processing-technique-id="assetProcessingTechniqueId"
         :processing-technique-id="processingTechniqueId"
         @cancel-edition="onEdit = false" /> 
   </template>
   
</template>
<script setup lang="ts">
import { AssetProcessingTechniquePropertyInterface } from '@/models/assetAssessmentInterfaces';
import { defineProps, ref } from 'vue';
import { PencilSquareIcon } from '@heroicons/vue/24/outline';
import AssetProcessingTechniquePropertyEditForm from './AssetProcessingTechniquePropertyEditForm.vue';

defineProps<{ 
   assetProcessingTechniqueProperty: AssetProcessingTechniquePropertyInterface; 
   assetProcessingTechniqueId: number;
   processingTechniqueId?: number
}>();
const onEdit = ref(null);

</script>

