import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-4" }

import { computed } from "vue";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import accessibilityInit from "highcharts/modules/accessibility"; // Import the accessibility module
import exportingInit from "highcharts/modules/exporting";
// Initialize the accessibility module

export default /*@__PURE__*/_defineComponent({
  __name: 'MecChart',
  props: ["data"],
  setup(__props) {

accessibilityInit(Highcharts);
// Initialize the exporting module
exportingInit(Highcharts);

const props = __props;
const chartData = computed(() => {
  return props.data.map((item) => {
    return { name: item.element, y: item.percentages, sliced: item.percentages < 20 };
  });
});
const chartOptions = {
  chart: {
    type: "pie",
  },
  title: {
    text: "Major Equipments Cost",
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: true,
        format: "<b>{point.name}</b>: {point.percentage:.1f} %",
      },
    },
  },
  series: [
    {
      name: "",
      colorByPoint: true,
      data: chartData.value,
      showInLegend: true,
    },
  ],
  exporting: {
    enabled: true, // Enable exporting
  },
  tooltip: {
    formatter: function () {
      const formattedY = Highcharts.numberFormat(this.point.y, 1);
      return `<b>${this.point.name}</b>: ${formattedY} %`;
    },
  },
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Chart), { options: chartOptions })
  ]))
}
}

})