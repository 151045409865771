import { SIGN_IN_MUTATION, SIGN_UP_MUTATION, RE_VERIFY_MUTATION, RESET_MUTATION} from "@/tea/graphql/mutations/authMutations";
import { ME_QUERY } from "@/tea/graphql/queries/authQueries";
import { useTeaAuthMutation } from "@/tea/composables/apollo/teaUseMutation";
import { useTeaAuthQuery } from "@/tea/composables/apollo/useTeaQuery";

const state = () => ({
  user: null,
  isSidebarCollapsed: true,
});

export const mutations = {
  setUser(state, payload) {
    state.user = payload;
  },
  logoutUser(state) {
    state.user = null;
  },
  toogleSidebar(state) {
    state.isSidebarCollapsed = !state.isSidebarCollapsed;
  },
};

const actions = {
  async signIn({ commit }, authParams: { email: string; password: string }) {
    return new Promise<string>((resolve, reject) => {
      const {
        mutate: tokenAuth,
        onDone,
        onError,
      } = useTeaAuthMutation(SIGN_IN_MUTATION);
      try {
        localStorage.removeItem("auth_token");
        localStorage.removeItem("refresh_token");
        tokenAuth(authParams);
        onDone(async (result) => {
          localStorage.setItem("auth_token", result.data.tokenAuth.token);
          localStorage.setItem("refresh_token", result.data.tokenAuth.refreshToken);
          commit("setUser", result.data.tokenAuth.user);
          resolve("resolved");
        });
        onError((error) => {
          reject(error.message);
        });
      } catch (error) {
        reject(error.message);
      }
    });
  },
  async signUp({ commit }, signUpParams: { email: string; password: string, firstName:string, lastName:string, organizationName:string }) {
    return new Promise<string>((resolve, reject) => {
      const {
        mutate: register,
        onDone,
        onError,
      } = useTeaAuthMutation(SIGN_UP_MUTATION);
      try {
        register(signUpParams);
        onDone(async (result) => {
          resolve("resolved");
        });
        onError((error) => {
          reject(error.message);
        });
      } catch (error) {
        reject(error.message);
      }
    });
  },
  async reVerify({ commit }, verificationParams: { email: string; password: string }) {
    return new Promise<string>((resolve, reject) => {
      const {
        mutate: resendVerificationEmail,
        onDone,
        onError,
      } = useTeaAuthMutation(RE_VERIFY_MUTATION);
      try {
        resendVerificationEmail(verificationParams);
        onDone(async (result) => {
          resolve("resolved");
        });
        onError((error) => {
          reject(error.message);
        });
      } catch (error) {
        reject(error.message);
      }
    });
  },
  async reset({ dispatch }, email: string) {
    return new Promise<string>((resolve, reject) => {
      const {
        mutate: resetPassword,
        onDone,
        onError,
      } = useTeaAuthMutation(RESET_MUTATION);
      try {
        resetPassword({ email });
        onDone(async (result) => {
          resolve("resolved");
        });
        onError((error) => {
          reject(error.message);
        });
      } catch (error) {
        reject(error.message);
      }
    });
  },

  async meQuery(context) {
    return new Promise<void>((resolve, reject) => {
      const { onResult, onError } = useTeaAuthQuery(ME_QUERY,null,{fetchPolicy: 'network-only'});
      onResult((queryResult) => {
        if (queryResult.data && queryResult.data.me) {
          context.commit("setUser", queryResult.data.me);
          resolve();
        } else {
          reject();
        }
      });
      onError((error) => {
        reject(error);
      });
    });
  },

  async refreshUser(context) {
    
    try {
      await context.dispatch("meQuery");
    } catch (err) {
      console.log(err);
    }
  },

  async logout({ commit }) {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("refresh_token");
    commit("logoutUser");
  },
};
const getters = {
  isAuthenticated(state) {
    return !!state.user && !!localStorage.auth_token;
  },
  isSidebarCollapsed(state) {
    return state.isSidebarCollapsed;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
