<template>
    <div v-if="infoContent" class="info-box md:flex">
        <template v-for="item in infoContent" :key="item.title" > 
            <template v-if="!editable && !!item.content">
                <div v-if="item.type === 'textarea'" class="card-content w-full  md:w-2/3 px-4 mb-4">
                    <p class="card-title">{{item.title}}</p>
                    {{item.content}} 
                </div>
                <div v-else class="card-content md:w-1/3 px-4 mb-4">
                    <p class="card-title">{{item.title}}</p>
                    {{item.content}} 
                </div>
            </template>
            <!-- Editable section -->
            <template v-else-if="editable && ( !item.mutationValue || fields.includes(item.mutationValue) )">
                <div v-if="item.editable && item.type === 'text'" class="card-content w-full  md:w-1/3 px-4 mb-4">
                    <p class="card-title">{{item.title}}</p>
                    <input :value="item.content" class="w-full" type="text" @keyup="item.content = handleInputChange($event); commitValue(item)" />
                </div>
                <div v-else-if="item.editable && item.type === 'textarea'" class="card-content w-full  md:w-2/3 px-4 mb-4">
                    <p class="card-title">{{item.title}}</p>
                    <textarea :disabled="false" :value="item.content" class="w-full" @keyup="item.content = handleInputChange($event); commitValue(item)"></textarea>
                </div>
                <div v-else-if="!!item.content" class="card-content w-full  md:w-1/3 px-4 mb-4">
                    <p class="card-title">{{item.title}}</p>
                    {{item.content}}
                </div>
            </template>
        </template>
    </div>
</template>
<script setup lang="ts">
import { computed, defineProps } from 'vue';
import { useStore } from 'vuex';
import { NEW_RUN_GROUP_FIELDS } from "@/tea/models/runGroup";
defineProps(['infoContent', 'editable']);
const store = useStore();

const fields = computed(() => Object.values(NEW_RUN_GROUP_FIELDS.formFields).map(f => f.mutationValue));

const handleInputChange = (event: Event) => (event.target as HTMLInputElement).value;

const commitValue = (item) => {
    store.commit('runGroupModule/SET_UPDATE_SINGLE_RUN_GROUP_VALUE', {mutationValue: item.mutationValue, content: item.content })
}

</script>
<style lang="scss" scoped>
.info-box{
    // display: flex;
    // flex-wrap: wrap;
    margin-top: 1rem;
    > div {
        // width: 25%; 
        // flex-shrink: 0; 
        border-left: 1px solid #ddd;
        text-align: initial;
        padding: 0 1.5rem 1rem 1rem;  
    }
}
.card-title {
    font-size: 0.9rem;
    color: $font-color-super-light;
    font-weight: 300;
    text-transform: none;
    position: relative;
    span {
        font-size: 0.7rem;
        color: #999;
    }
    &::after{
        content: "";
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #eeeeee;
        border: 1px solid #dddddd;
        position: absolute;
        left: -22px;
        top: 4px;
    }
    
}
.card-content {
    font-size: 0.9rem; 
    color: $font-color-light; 
    font-weight: 500;
    padding-bottom: 0.5rem;
    overflow: auto;
    text-transform: uppercase;
    overflow: visible;
    .url {
        text-decoration: underline;
        cursor: pointer;
        text-transform: none;
        &.linkItem {
            display: block;
            max-width: 200px;

        }
    }
    input[type=text],textarea {
        background-color: $grey-modal!important;
        &:focus {
            border: 2px solid $blue-bg-text;
        }
    }
}
.selected-results span {
    text-transform: none;
    color: white;
    background-color: $blue-bg-text;
    font-weight: 300;
    svg {
        stroke: white;
    }
}
img {
    display: inline-block;
    margin-left: 10px;
    width: 16px;
    opacity: 0.8;
}
    
</style>
