import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, vModelText as _vModelText, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "border p-0 m-4" }
const _hoisted_2 = {
  key: 0,
  class: "form-container"
}
const _hoisted_3 = { class: "grid grid-cols-3 gap-y-3 gap-x-4" }
const _hoisted_4 = { class: "col-span-1 sm:col-span-3 md:col-span-1" }
const _hoisted_5 = { class: "flex w-full items-center justify-stretch" }
const _hoisted_6 = { class: "flex w-full items-center justify-between" }
const _hoisted_7 = { class: "flex items-center" }
const _hoisted_8 = { class: "text-sm" }
const _hoisted_9 = { class: "shrink-0 text-white items-right" }
const _hoisted_10 = { class: "col-span-4 border border-gray-100 border-solid p-1 pt-3 pb-1 -mt-3 bg-gray-50" }
const _hoisted_11 = { class: "text-xs text-gray-500 py-2 block" }
const _hoisted_12 = { class: "col-span-1 sm:col-span-3 md:col-span-1" }
const _hoisted_13 = { class: "flex w-full items-center justify-stretch" }
const _hoisted_14 = { class: "flex w-full items-center justify-between" }
const _hoisted_15 = { class: "flex items-center" }
const _hoisted_16 = { class: "text-sm" }
const _hoisted_17 = { class: "shrink-0 text-white items-right" }
const _hoisted_18 = { class: "col-span-4 border border-gray-100 border-solid p-1 pt-3 pb-1 -mt-3 bg-gray-50" }
const _hoisted_19 = { class: "text-xs text-gray-500 py-2 block" }
const _hoisted_20 = { class: "col-span-1 sm:col-span-3 md:col-span-1" }
const _hoisted_21 = { class: "flex w-full items-center justify-stretch" }
const _hoisted_22 = { class: "flex w-full items-center justify-between" }
const _hoisted_23 = { class: "flex items-center" }
const _hoisted_24 = { class: "text-sm" }
const _hoisted_25 = { class: "shrink-0 text-white items-right" }
const _hoisted_26 = { class: "col-span-4 border border-gray-100 border-solid p-1 pt-3 pb-1 -mt-3 bg-gray-50" }
const _hoisted_27 = { class: "text-xs text-gray-500 py-2 block" }
const _hoisted_28 = { class: "col-span-3 text-center mt-2" }
const _hoisted_29 = {
  key: 0,
  class: "col-span-4"
}
const _hoisted_30 = {
  key: 0,
  class: "button green-accept cursor-not-allowed opacity-75 hover:shadow-none",
  disabled: ""
}
const _hoisted_31 = {
  key: 0,
  class: "col-span-3 text-center mt-2"
}
const _hoisted_32 = { class: "overflow-hidden" }

import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { ChevronRightIcon } from "@heroicons/vue/24/solid";
import { RUN_SENSITIVITY_ADD_FORM_VALUES } from "@/tea/graphql/queries/runQueries";
import { useTeaQuery } from "@/tea/composables/apollo/useTeaQuery";
import { NEW_RUN_SENSITIVITY_FIELDS } from "@/tea/models/runSensitivity";
import { useFormFieldsCreationNewRunSensitivity } from "@/tea/composables/runs/useFormFieldsCreationNewRunSensitivity";
import { useFormInizializationNewRunSensitivity } from "@/tea/composables/runs/useFormInizializationNewRunSensitivity";
import { DropdownSearchableOptionInterface } from "@/models/dropdownSearchableMultipleOptionInterface";
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import FormErrorToast from "@/components/FormErrorToast.vue";
import SensitivityChart from "@/tea/components/runs/charts/SensitivityChart.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'RunSensitivityCreate',
  props: ["runId"],
  setup(__props) {

const yesNos = [
  { key: true, value: "Yes" },
  { key: false, value: "No" },
];

const props = __props;
const store = useStore();

onMounted(() => {
  store.commit("runSensitivityModule/SET_RUN_ID", props.runId);
});

const newAnalysisToggled = ref(false);
const toggleNewAnalysis = () => {
  newAnalysisToggled.value = !newAnalysisToggled.value;
};

// Inizialize the form
const { result } = useTeaQuery(RUN_SENSITIVITY_ADD_FORM_VALUES, { id: props.runId });

const inizilizedData = computed(() => result?.value ?? null);
const formInizializationValues = computed<{ [key: string]: DropdownSearchableOptionInterface[] }>(() =>
  useFormInizializationNewRunSensitivity(inizilizedData.value, NEW_RUN_SENSITIVITY_FIELDS.fieldsToQuery)
);
const getFormFields = Object.keys(NEW_RUN_SENSITIVITY_FIELDS.formFields).map((key) => {
  const fieldConfig = NEW_RUN_SENSITIVITY_FIELDS.formFields[key];
  return {
    [key]: Array.isArray(fieldConfig.onChangeResetFields) ? fieldConfig.onChangeResetFields : [],
  };
});

const formFieldOptions = {
  fields: getFormFields,
  mutation: "runSensitivityModule/SET_SINGLE_RUN_VALUE",
};

const formFields = useFormFieldsCreationNewRunSensitivity(formFieldOptions);

const create = computed(() => store.getters["runSensitivityModule/create"]);
const fields = computed(() => create.value.fields);
const formSubmited = computed(() => create.value.isFormSubmitted);
const isReadyForOutput = computed(() => create.value.isReadyForOutput);
const isOutputLoading = computed(() => create.value.isOutputLoading);
const calculateError = computed(() => create.value.calculateError);
const errorsDuringMutation = computed(() => create.value.createError);

const sensitivityOutput = computed(() => create.value.sensitivityOutput);

const valuesSubmitted = computed(() => fields.value);

function getMissingFields(fieldName, fieldValue, validation) {
  const missingFields = [];
  // 1. check if required
  if (validation.required === true) {
    if (fieldValue === undefined || fieldValue === null || fieldValue === "") {
      missingFields.push(fieldName);
    }
  }

  // 2. check if validtion has valueValidation property set and aloso field has value, then check if value is truthy
  if (validation.valueValidation && fieldValue !== undefined && fieldValue !== null && fieldValue !== "") {
    const { type, values } = validation.valueValidation;
    if (type === "array") {
      if (!values.includes(fieldValue)) {
        missingFields.push(fieldName);
      }
    } else if (type === "range") {
      if (fieldValue < values[0] || fieldValue > values[1]) {
        missingFields.push(fieldName);
      }
    } else if (type === "equal") {
      if (fieldValue !== values) {
        missingFields.push(fieldName);
      }
    } else if (type === "min") {
      if (fieldValue < values) {
        missingFields.push(fieldName);
      }
    }
  }

  // 3. check dependants
  if (validation.dependants) {
    const { dependants } = validation;
    for (const condition of dependants) {
      let doCheckUp = false;
      const { ifValue, ifValueType, ifValueReference, fields, not, is } = condition;
      // Check if the field associated with the condition is truthy
      //check if there is ifValueType equals to options
      //check if it has not property
      //check if it has is property
      if (ifValueType === "option") {
        if (formInizializationValues.value[ifValueReference]) {
          let selectedItem = formInizializationValues.value[ifValueReference].find((item) => item.name === ifValue);
          if (selectedItem) {
            if (selectedItem.id == fieldValue) {
              doCheckUp = true;
            }
          }
        }
      } else if (fieldValue === ifValue) {
        doCheckUp = true;
      }

      if (not && doCheckUp) {
        for (const notCondition of not) {
          let notIfFieldValue = valuesSubmitted.value[notCondition.ifField];
          if (notCondition.ifValueType === "option") {
            if (formInizializationValues.value[notCondition.ifValueReference]) {
              let selectedItem = formInizializationValues.value[notCondition.ifValueReference].find((item) => item.name === notCondition.ifValue);
              if (selectedItem) {
                if (selectedItem.id === notIfFieldValue) {
                  //do not do checkup because it does not passes the not condition
                  doCheckUp = false;
                }
              }
            }
          } else if (notIfFieldValue === notCondition.ifValue) {
            //do not do checkup because it does not passes the not condition
            doCheckUp = false;
          }
        }
      }

      if (is && doCheckUp) {
        for (const isCondition of is) {
          let isIfFieldValue = valuesSubmitted.value[isCondition.ifField];
          if (isCondition.ifValueType === "option") {
            if (formInizializationValues.value[isCondition.ifValueReference]) {
              let selectedItem = formInizializationValues.value[isCondition.ifValueReference].find((item) => item.name === isCondition.ifValue);
              if (selectedItem) {
                if (selectedItem.id !== isIfFieldValue) {
                  //do not do checkup because it does not passes the not condition
                  doCheckUp = false;
                }
              }
            }
          } else if (isIfFieldValue !== isCondition.ifValue) {
            //do not do checkup because it does not passes the not condition
            doCheckUp = false;
          }
        }
      }

      if (doCheckUp) {
        for (const dependantField of fields) {
          // Check if the field is missing
          const dependantValue = valuesSubmitted.value[dependantField];
          if (dependantValue === undefined || dependantValue === null || dependantValue === "") {
            missingFields.push(dependantField);
          }
        }
      }
    }
  }

  // Add more validation logic here if needed.
  return Array.from(new Set(missingFields)); //Remove duplicates
}
const errorsFields = computed(() => {
  const missingFields = [];
  for (const fieldName of Object.keys(NEW_RUN_SENSITIVITY_FIELDS.formFields)) {
    const validation = NEW_RUN_SENSITIVITY_FIELDS.formFields[fieldName];
    const fieldValue = valuesSubmitted.value[fieldName];
    missingFields.push(...getMissingFields(fieldName, fieldValue, validation));
  }
  return Array.from(new Set(missingFields)); //Remove duplicates
});

const formIsValid = computed(() => {
  return errorsFields.value.length === 0;
});

const isAtleastOneValueSelected = computed(() => {
  return create.value.fields.electricity || create.value.fields.water || create.value.fields.co2;
});

const showOutputs = async () => {
  store.commit("runSensitivityModule/SET_CREATE_MUTATION", { key: "isFormSubmitted", value: true });
  if (formIsValid.value && isAtleastOneValueSelected.value) {
    // get outputs now
    const mutation = NEW_RUN_SENSITIVITY_FIELDS.calculateMutation;
    await store
      .dispatch(mutation)
      .then(() => {
        store.commit("runSensitivityModule/SET_CREATE_MUTATION", { key: "isReadyForOutput", value: true });
        store.commit("runSensitivityModule/SET_CREATE_MUTATION", { key: "canSubmit", value: true });
        store.commit("runSensitivityModule/SET_CREATE_MUTATION", { key: "createError", value: true });
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: toggleNewAnalysis,
      class: "hover:bg-gray-50 p-4 pl-2 pr-2 border-b flex items-center cursor-pointer"
    }, [
      _createVNode(_unref(ChevronRightIcon), {
        class: _normalizeClass(["icon icon-small inline-block mr-2", { 'rotate-90': !newAnalysisToggled.value }])
      }, null, 8, ["class"]),
      _cache[6] || (_cache[6] = _createTextVNode(" NEW SENSITIVITY ANALYSIS "))
    ]),
    _createElementVNode("div", {
      class: _normalizeClass([{ hidden: newAnalysisToggled.value }, "p-4"])
    }, [
      _createElementVNode("div", null, [
        _createVNode(FormErrorToast, {
          style: {"margin":"0 0 1rem 0"},
          class: "block",
          "is-showing": !!calculateError.value,
          title: "Warning!",
          subtitle: calculateError.value,
          "toast-type": "warning"
        }, null, 8, ["is-showing", "subtitle"])
      ]),
      _createElementVNode("div", null, [
        _createVNode(FormErrorToast, {
          style: {"margin":"0 0 1rem 0"},
          class: "block",
          "is-showing": formSubmited.value && !formIsValid.value,
          title: "The following fields have validation errors:",
          subtitle: errorsFields.value.join(', ')
        }, null, 8, ["is-showing", "subtitle"])
      ]),
      (formInizializationValues.value)
        ? (_openBlock(), _createElementBlock("form", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_unref(RadioGroup), {
                  modelValue: _unref(formFields)['electricity'].value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(formFields)['electricity'].value) = $event)),
                  class: _normalizeClass(["col-span-4 mb-0 mt-1", { 'error-form-field': errorsFields.value.includes('electricity') && formSubmited.value }])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(RadioGroupLabel), { class: "mb-1" }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode("Try different price for Electricity ?")
                      ])),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_5, [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(yesNos, (yesNo) => {
                        return _createVNode(_unref(RadioGroupOption), {
                          as: "template",
                          key: yesNo.value,
                          value: yesNo.key
                        }, {
                          default: _withCtx(({ active, checked }) => [
                            _createElementVNode("div", {
                              class: _normalizeClass([[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white '], "relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"])
                            }, [
                              _createElementVNode("div", _hoisted_6, [
                                _createElementVNode("div", _hoisted_7, [
                                  _createElementVNode("div", _hoisted_8, [
                                    _createVNode(_unref(RadioGroupLabel), {
                                      as: "p",
                                      class: _normalizeClass([checked ? 'text-white' : 'text-gray-500', "font-base"])
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(yesNo.value), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["class"])
                                  ])
                                ]),
                                _withDirectives(_createElementVNode("div", _hoisted_9, _cache[8] || (_cache[8] = [
                                  _createElementVNode("svg", {
                                    class: "h-5 w-5",
                                    viewBox: "0 0 24 24",
                                    fill: "none"
                                  }, [
                                    _createElementVNode("circle", {
                                      cx: "12",
                                      cy: "12",
                                      r: "12",
                                      fill: "#fff",
                                      "fill-opacity": "0.2"
                                    }),
                                    _createElementVNode("path", {
                                      d: "M7 13l3 3 7-7",
                                      stroke: "#fff",
                                      "stroke-width": "1.5",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round"
                                    })
                                  ], -1)
                                ]), 512), [
                                  [_vShow, checked]
                                ])
                              ])
                            ], 2)
                          ]),
                          _: 2
                        }, 1032, ["value"])
                      }), 64))
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "class"]),
                _createElementVNode("div", _hoisted_10, [
                  (fields.value.electricity)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(["w-full", { 'error-form-field': errorsFields.value.includes('electricityPrice') && formSubmited.value }])
                      }, [
                        _cache[9] || (_cache[9] = _createElementVNode("label", null, "New Electricity Price *", -1)),
                        _withDirectives(_createElementVNode("input", {
                          class: "w-full",
                          type: "number",
                          min: "0.00",
                          max: "100.00",
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(formFields)['electricityPrice'].value) = $event))
                        }, null, 512), [
                          [_vModelText, _unref(formFields)['electricityPrice'].value]
                        ])
                      ], 2))
                    : _createCommentVNode("", true),
                  _createElementVNode("p", _hoisted_11, "The reference price for electricity used is " + _toDisplayString(formInizializationValues.value["electricity"]), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_unref(RadioGroup), {
                  modelValue: _unref(formFields)['water'].value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(formFields)['water'].value) = $event)),
                  class: _normalizeClass(["col-span-4 mb-0 mt-1", { 'error-form-field': errorsFields.value.includes('water') && formSubmited.value }])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(RadioGroupLabel), { class: "mb-1" }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode("Try different price for Water ?")
                      ])),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_13, [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(yesNos, (yesNo) => {
                        return _createVNode(_unref(RadioGroupOption), {
                          as: "template",
                          key: yesNo.value,
                          value: yesNo.key
                        }, {
                          default: _withCtx(({ active, checked }) => [
                            _createElementVNode("div", {
                              class: _normalizeClass([[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white '], "relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"])
                            }, [
                              _createElementVNode("div", _hoisted_14, [
                                _createElementVNode("div", _hoisted_15, [
                                  _createElementVNode("div", _hoisted_16, [
                                    _createVNode(_unref(RadioGroupLabel), {
                                      as: "p",
                                      class: _normalizeClass([checked ? 'text-white' : 'text-gray-500', "font-base"])
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(yesNo.value), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["class"])
                                  ])
                                ]),
                                _withDirectives(_createElementVNode("div", _hoisted_17, _cache[11] || (_cache[11] = [
                                  _createElementVNode("svg", {
                                    class: "h-5 w-5",
                                    viewBox: "0 0 24 24",
                                    fill: "none"
                                  }, [
                                    _createElementVNode("circle", {
                                      cx: "12",
                                      cy: "12",
                                      r: "12",
                                      fill: "#fff",
                                      "fill-opacity": "0.2"
                                    }),
                                    _createElementVNode("path", {
                                      d: "M7 13l3 3 7-7",
                                      stroke: "#fff",
                                      "stroke-width": "1.5",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round"
                                    })
                                  ], -1)
                                ]), 512), [
                                  [_vShow, checked]
                                ])
                              ])
                            ], 2)
                          ]),
                          _: 2
                        }, 1032, ["value"])
                      }), 64))
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "class"]),
                _createElementVNode("div", _hoisted_18, [
                  (fields.value.water)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(["w-full", { 'error-form-field': errorsFields.value.includes('waterPrice') && formSubmited.value }])
                      }, [
                        _cache[12] || (_cache[12] = _createElementVNode("label", null, "New Water Price *", -1)),
                        _withDirectives(_createElementVNode("input", {
                          class: "w-full",
                          type: "number",
                          min: "0.00",
                          max: "100.00",
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(formFields)['waterPrice'].value) = $event))
                        }, null, 512), [
                          [_vModelText, _unref(formFields)['waterPrice'].value]
                        ])
                      ], 2))
                    : _createCommentVNode("", true),
                  _createElementVNode("p", _hoisted_19, "The reference price for electricity used is " + _toDisplayString(formInizializationValues.value["water"]), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createVNode(_unref(RadioGroup), {
                  modelValue: _unref(formFields)['co2'].value,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(formFields)['co2'].value) = $event)),
                  class: _normalizeClass(["col-span-4 mb-0 mt-1", { 'error-form-field': errorsFields.value.includes('co2') && formSubmited.value }])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(RadioGroupLabel), { class: "mb-1" }, {
                      default: _withCtx(() => _cache[13] || (_cache[13] = [
                        _createTextVNode("Try different price for CO2 ?")
                      ])),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_21, [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(yesNos, (yesNo) => {
                        return _createVNode(_unref(RadioGroupOption), {
                          as: "template",
                          key: yesNo.value,
                          value: yesNo.key
                        }, {
                          default: _withCtx(({ active, checked }) => [
                            _createElementVNode("div", {
                              class: _normalizeClass([[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white '], "relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"])
                            }, [
                              _createElementVNode("div", _hoisted_22, [
                                _createElementVNode("div", _hoisted_23, [
                                  _createElementVNode("div", _hoisted_24, [
                                    _createVNode(_unref(RadioGroupLabel), {
                                      as: "p",
                                      class: _normalizeClass([checked ? 'text-white' : 'text-gray-500', "font-base"])
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(yesNo.value), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["class"])
                                  ])
                                ]),
                                _withDirectives(_createElementVNode("div", _hoisted_25, _cache[14] || (_cache[14] = [
                                  _createElementVNode("svg", {
                                    class: "h-5 w-5",
                                    viewBox: "0 0 24 24",
                                    fill: "none"
                                  }, [
                                    _createElementVNode("circle", {
                                      cx: "12",
                                      cy: "12",
                                      r: "12",
                                      fill: "#fff",
                                      "fill-opacity": "0.2"
                                    }),
                                    _createElementVNode("path", {
                                      d: "M7 13l3 3 7-7",
                                      stroke: "#fff",
                                      "stroke-width": "1.5",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round"
                                    })
                                  ], -1)
                                ]), 512), [
                                  [_vShow, checked]
                                ])
                              ])
                            ], 2)
                          ]),
                          _: 2
                        }, 1032, ["value"])
                      }), 64))
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "class"]),
                _createElementVNode("div", _hoisted_26, [
                  (fields.value.co2)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(["w-full", { 'error-form-field': errorsFields.value.includes('co2Price') && formSubmited.value }])
                      }, [
                        _cache[15] || (_cache[15] = _createElementVNode("label", null, "New CO2 Price *", -1)),
                        _withDirectives(_createElementVNode("input", {
                          class: "w-full",
                          type: "number",
                          min: "0.00",
                          max: "100.00",
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(formFields)['co2Price'].value) = $event))
                        }, null, 512), [
                          [_vModelText, _unref(formFields)['co2Price'].value]
                        ])
                      ], 2))
                    : _createCommentVNode("", true),
                  _createElementVNode("p", _hoisted_27, "The reference price for electricity used is " + _toDisplayString(formInizializationValues.value["co2"]), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_28, [
                (!isReadyForOutput.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                      (isOutputLoading.value || !isAtleastOneValueSelected.value)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString(isOutputLoading.value ? "Loading Outputs . . ." : "Show Outputs"), 1))
                        : (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            onClick: showOutputs,
                            class: "button green-accept cursor-pointer"
                          }, "Show Outputs"))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (isReadyForOutput.value && sensitivityOutput.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                    _createElementVNode("div", _hoisted_32, [
                      _createVNode(SensitivityChart, {
                        class: "w-full border",
                        data: sensitivityOutput.value
                      }, null, 8, ["data"])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ]))
}
}

})