import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "uppercase font-medium text-m mb-2" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "flex items-end justify-end" }
const _hoisted_4 = { class: "bg-red rounded-full text-right uppercase text-sm bg-nyanza px-4 py-1" }

import { PropType } from 'vue';
import { ProductBriefInterface } from '@/models/productBriefsInterface';
import router from '@/router';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductBriefCard',
  props: {
    productBrief: {
        type: Object as PropType<ProductBriefInterface>,
        required: false,
    }
},
  setup(__props) {


const props = __props

const goToProductDetail = () => router.push({ name: 'productBriefDetail', params: { id: props.productBrief.id } });


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "border shadow-sm bg-white py-6 px-5 flex flex-col justify-between cursor-pointer",
    onClick: goToProductDetail
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_1, _toDisplayString(__props.productBrief.product), 1),
      (__props.productBrief.objective)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: "text-gray-500 font-light text-sm mb-4",
            innerHTML: __props.productBrief.objective
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(__props.productBrief.client.nickname), 1)
    ])
  ]))
}
}

})