<template> 
    <div class="mb-4"> 
        <label>Link Type *</label>
        <select v-model="linkType" class="w-full">
            <option v-for="linkType in optionsListForDropdown['assetProgramLinkTypesOptions']" :key="linkType.id" :value="linkType.id">
                {{ linkType.name }}
            </option>
        </select>
    </div>
    <div class="mb-4">
        <label>Link Status *</label>
        <select v-model="linkStatus" class="w-full">
            <option v-for="status in optionsListForDropdown['assetEvaluationStatusesOptions']" :key="status.id" :value="status.id">
                {{ status.name }}
            </option>
        </select>
    </div>
    <div class="mb-4 assessment-editor">
        <label>Status Reason</label>
        <QuillEditor :content="statusReason.toString()" content-type="html" @ready="onEditorReady($event)" @update:content="onEditorUpdate($event)"/>
    </div>
    <div class="edit-assessment-buttons internal"> 
        <div @click="handleEdit" class="on-green"><CheckIcon class="icon"/><p>Save Changes</p></div>
        <div @click="$emit('cancelEdition')"><XMarkIcon class="icon"/><p>cancel</p></div> 
    </div> 
</template>
    
<script setup lang="ts">
import { defineProps, defineEmits, type PropType, computed, ref } from 'vue';
import { LinkedProgramInterface } from '@/models/linkedProgramInterface';
import { useStore } from 'vuex';
import { CheckIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { ASSET_PROGRAM_LINK_FORM_VALUES } from '@/grapql/assetProgramLinkFormQueries';
import { useQuery } from '@vue/apollo-composable';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import { QuillEditor } from '@vueup/vue-quill';

const props = defineProps({ linkedProgram: Object as PropType<LinkedProgramInterface> });
const emit = defineEmits(['cancelEdition']);

const store = useStore();
const assetId = computed(() => store.getters["assetDetailModule/getAssetId"]);
const onEditorReady = (event: Event) => { return event }

const { result } = useQuery(ASSET_PROGRAM_LINK_FORM_VALUES);
const inizilizedData = computed(() => result?.value ?? null );
const optionsListForDropdown = computed<{[key: string]: DropdownSearchableOptionInterface[]}>(() => useFormInizializationNewAsset(inizilizedData.value, ['assetProgramLinkTypes', 'assetEvaluationStatuses']));

const linkType = props.linkedProgram ? ref(props.linkedProgram.linkType.id) : ref(null);
const linkStatus = props.linkedProgram ? ref(props.linkedProgram.linkStatus.id) : ref(null)
const statusReason = props.linkedProgram && props.linkedProgram.statusReason ? ref(props.linkedProgram.statusReason) : ref('');

const onEditorUpdate = (event) => statusReason.value = event;

const handleEdit = async () => {
    const editLinkPayload = ref({
        asset: Number(assetId.value),
        program: Number(props.linkedProgram.program.id),
        linkType: Number(linkType.value),
        id: Number(props.linkedProgram.id),
        linkStatus: Number(linkStatus.value),
        statusReason: statusReason.value
    })
    await store.dispatch('assetDetailModule/assetProgramLinkUpdate', editLinkPayload.value).then( () => emit('cancelEdition'))
}





</script>
<style lang="scss">
    
</style>