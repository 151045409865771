import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/images/world-map.svg'


const _hoisted_1 = { class: "grid grid-flow-row gap-4" }
const _hoisted_2 = { class: "bg-white border p-5 rounded-md min-h-32 relative overflow-hidden" }
const _hoisted_3 = { class: "relative h-4/5 flex flex-col justify-center items-center mb-4 text-neutral-500" }
const _hoisted_4 = { class: "font-medium" }


import { CountriesInterface } from '@/models/productBriefsInterface';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductBriefMarketsCard',
  props: {
    markets: {}
  },
  setup(__props: any) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("p", { class: "uppercase font-medium text-sm mb-3" }, "Markets", -1)),
      _cache[1] || (_cache[1] = _createElementVNode("img", {
        src: _imports_0,
        class: "opacity-50 absolute position-x-center -top-14"
      }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.markets.edges, (country) => {
          return (_openBlock(), _createElementBlock("p", {
            key: country.node.id,
            class: "text-sm font-normal uppercase mb-2"
          }, [
            _createElementVNode("span", _hoisted_4, "(" + _toDisplayString(country.node.code) + ")", 1),
            _createTextVNode(" - " + _toDisplayString(country.node.name), 1)
          ]))
        }), 128))
      ])
    ])
  ]))
}
}

})