<template>
  <div>
    <form @submit.prevent="resetPassword">
      <p class="text-red-500 text-center">{{ errorMessage }}</p>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm" for="email"> Email </label>
        <input v-model="email" class="border rounded w-full py-1 px-3 focus:outline-none focus:shadow-outline" id="email" type="email" required />
      </div>
      <div class="mb-4">
        <div>
          <small>I'm not a robot</small>
          <Checkbox v-model="checkboxResponse" />
        </div>
      </div>
      <div class="flex justify-stretch">
        <button
          type="submit"
          :class="{
            'w-full bg-emerald-400 text-white font-bold py-2 px-4 rounded': true,
            'hover:bg-emerald-600  focus:outline-none focus:shadow-outline': isFormValid,
            'cursor-not-allowed opacity-50 ': (!isFormValid || buttonDisabled),
          }"
          :disabled="!isFormValid || buttonDisabled"
        >
          {{ buttonText }}
        </button>
      </div>
    </form>
    <hr class="my-4" />
    <div class="text-right">
      Remember password? &nbsp;
      <button @click.prevent="$emit('showSignIn')" class="text-emerald-400 hover:underline cursor-pointer">Sign In Here</button>
    </div>
  </div>
</template>

<script setup></script>

<script setup>
import { computed, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
const { emit } = getCurrentInstance();

import { Checkbox } from "vue-recaptcha";

const checkboxResponse = ref();
const checkboxVerified = computed(() => !!checkboxResponse.value);

const store = useStore();
const buttonText = ref("Send Reset Email");
const buttonDisabled = ref(false);

const email = ref("");
const errorMessage = ref("");

const isFormValid = computed(() => {
  return email.value && checkboxVerified.value;
});

const resetPassword = async () => {
  try {
    buttonText.value = "Sending Reset Email . . .";
    buttonDisabled.value = true;
    await store
      .dispatch("authModule/reset", email.value)
      .then(() => {
        emit("showSuccess");
        buttonText.value = "Send Reset Email";
        buttonDisabled.value = false;
      })
      .catch((error) => {
        errorMessage.value = error;
        buttonText.value = "Send Reset Email";
        buttonDisabled.value = false;
      });
  } catch (error) {
    errorMessage.value = "An error occurred while resetting password.";
    buttonText.value = "Send Reset Email";
    buttonDisabled.value = false;
  }
};
</script>

<style scoped>
/* Add your custom styling here */
</style>
