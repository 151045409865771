<template>
  <div v-if="AuthModalVisible">
    <AuthModalSlot v-if="AuthModalVisible" @closeModal="$emit('closeModal')" :title="title">
      <!-- Content of your modal goes here -->
      <template v-if="currentForm === 'signIn'">
        <SignInForm @showSignUp="showSignUpForm" @showForgotPassword="showForgotPasswordForm" @closeModal="$emit('closeModal')" />
      </template>
      <template v-else-if="currentForm === 'signUp'">
        <SignUpForm @showSignIn="showSignInForm" @closeModal="$emit('closeModal')" @showEmail="handleSignUpEmail"
      /></template>
      <template v-else-if="currentForm === 'forgotPassword'">
        <ForgotPasswordForm @showSignIn="showSignInForm" @closeModal="$emit('closeModal')" @showSuccess="showResetSuccess" />
      </template>
      <template v-else-if="currentForm === 'resetSuccess'">
        <PasswordResetConfirmation @showSignIn="showSignInForm" @closeModal="$emit('closeModal')" />
      </template>
      <template v-else-if="currentForm === 'signUpSuccess'">
        <SignUpConfirmation @showSignIn="showSignInForm" @closeModal="$emit('closeModal')" :email="signUpEmail" />
      </template>
    </AuthModalSlot>
  </div>
</template>

<script setup lang="ts">
import SignInForm from "./SignInForm.vue";
import SignUpForm from "./SignUpForm.vue";
import ForgotPasswordForm from "./ForgotPasswordForm.vue";
import PasswordResetConfirmation from "./PasswordResetConfirmation.vue";
import SignUpConfirmation from "./SignUpConfirmation.vue";
import AuthModalSlot from "./AuthModalSlot.vue";
import { ref, defineProps, watch } from "vue";

const SIGN_IN_TITLE = "Sign In!";
const SIGN_UP_TITLE = "Sign Up!";
const FORGOT_PASSWORD_TITLE = "Reset Password!";
const FORGOT_PASSWORD_SUCCESS_TITLE = "Reset Email Sent!";
const SIGN_UP_SUCCESS_TITLE = "Confirmation Email Sent!";

const currentForm = ref("signIn");
const title = ref("Sign In!");

const showSignUpForm = () => {
  currentForm.value = "signUp";
  title.value = SIGN_UP_TITLE;
};
const showSignInForm = () => {
  currentForm.value = "signIn";
  title.value = SIGN_IN_TITLE;
};
const showForgotPasswordForm = () => {
  currentForm.value = "forgotPassword";
  title.value = FORGOT_PASSWORD_TITLE;
};
const showResetSuccess = () => {
  currentForm.value = "resetSuccess";
  title.value = FORGOT_PASSWORD_SUCCESS_TITLE;
};
const signUpEmail = ref(null);
const handleSignUpEmail = (email) => {
  signUpEmail.value = email;
  currentForm.value = "signUpSuccess";
  title.value = SIGN_UP_SUCCESS_TITLE;
};

const props = defineProps({
  AuthModalVisible: Boolean,
});

watch(
  () => props.AuthModalVisible,
  (newValue, oldValue) => {
    if (!newValue) {
      currentForm.value = "signIn";
      title.value = SIGN_IN_TITLE;
    }
  }
);
</script>
