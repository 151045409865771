import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "relative w-full cursor-default overflow-hidden" }
const _hoisted_3 = {
  key: 0,
  class: "relative cursor-default select-none py-2 px-4"
}
const _hoisted_4 = {
  key: 0,
  class: "selected-results"
}
const _hoisted_5 = { key: 0 }

import { ref, computed, PropType} from 'vue';
import {Combobox, ComboboxInput, ComboboxButton, ComboboxOptions, ComboboxOption, TransitionRoot} from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import {DropdownSearchableOptionInterface} from '@/models/dropdownSearchableMultipleOptionInterface'



export default /*@__PURE__*/_defineComponent({
  __name: 'DropdownSearchableMultiple',
  props: {
  optionsList: { type: Array as PropType<DropdownSearchableOptionInterface[]>},
  modelValue: { type: Array},
  selectOtherThanId: { type: String, required: false }

},
  emits: ['update:modelValue'],
  setup(__props, { emit: __emit }) {

const props = __props

const emit = __emit;
let query = ref('');
const valueToSelect = computed(()=> props.selectOtherThanId ? props.selectOtherThanId : 'id')

const removeValue = (optionId) => { 
  const newValue = props.modelValue.filter((value) => value != optionId);
  emit('update:modelValue', newValue)
}

let filteredOption = computed(() =>
  query.value === ''
    ? props.optionsList
    : props.optionsList.filter((option) =>
        option.name
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.value.toLowerCase().replace(/\s+/g, ''))
      )
)

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Combobox), {
    modelValue: __props.modelValue,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = value => emit('update:modelValue', value)),
    multiple: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(ComboboxInput), {
            class: "w-full border-none py-2 pl-3 pr-10 text-sm leading-5 focus:ring-0",
            onChange: _cache[0] || (_cache[0] = ($event: any) => (_isRef(query) //@ts-ignore
 ? query.value = $event.target.value : query = $event.target.value))
          }),
          _createVNode(_unref(ComboboxButton), { class: "absolute inset-y-0 right-0 flex items-center pr-2" }, {
            default: _withCtx(() => [
              _createVNode(_unref(ChevronUpDownIcon), {
                class: "h-5 w-5 text-gray-400",
                "aria-hidden": "true"
              })
            ]),
            _: 1
          })
        ]),
        _createVNode(_unref(TransitionRoot), {
          leave: "transition ease-in duration-100",
          leaveFrom: "opacity-100",
          leaveTo: "opacity-0",
          onAfterLeave: _cache[1] || (_cache[1] = ($event: any) => (_isRef(query) //@ts-ignore
 ? query.value = '' : query = ''))
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(ComboboxOptions), { class: "options-border z-10 text-xs absolute mt-1 max-h-60 w-full overflow-auto bg-white py-1 focus:outline-none" }, {
              default: _withCtx(() => [
                (_unref(filteredOption).length === 0 && _unref(query) !== '')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Nothing found. "))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filteredOption), (option) => {
                  return (_openBlock(), _createBlock(_unref(ComboboxOption), {
                    as: "template",
                    key: option.id,
                    value: option[valueToSelect.value]
                  }, {
                    default: _withCtx(({ selected, active }) => [
                      _createElementVNode("li", {
                        class: _normalizeClass(["relative cursor-default select-none py-0.5 pl-10 pr-4", {'hover-option': active}])
                      }, [
                        _createElementVNode("span", {
                          class: _normalizeClass(["block truncate", { 'font-medium': selected, 'font-normal': !selected }])
                        }, _toDisplayString(option.name), 3),
                        selected
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              class: _normalizeClass(["absolute inset-y-0 left-0 flex items-center pl-3", { 'text-white': active, 'green-color': !active }])
                            }, [
                              _createVNode(_unref(CheckIcon), {
                                class: "h-5 w-5",
                                "aria-hidden": "true"
                              })
                            ], 2))
                          : _createCommentVNode("", true)
                      ], 2)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      (__props.modelValue.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (__props.selectOtherThanId)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(__props.modelValue, (value, index) => {
                  return (_openBlock(), _createElementBlock("span", { key: index }, [
                    _createTextVNode(_toDisplayString(value) + " ", 1),
                    _createVNode(_unref(XMarkIcon), {
                      class: "icon icon-x-small thick inline",
                      onClick: ($event: any) => (removeValue(value))
                    }, null, 8, ["onClick"])
                  ]))
                }), 128))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(__props.optionsList, (option, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                    (__props.modelValue.includes(option[valueToSelect.value]))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                          _createTextVNode(_toDisplayString(option.name) + " ", 1),
                          _createVNode(_unref(XMarkIcon), {
                            class: "icon icon-x-small thick inline",
                            onClick: ($event: any) => (removeValue(option[valueToSelect.value]))
                          }, null, 8, ["onClick"])
                        ]))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})