<template>
    <div class="p-4">
        <dl>
            <div v-for="(value, key) in filteredResult" :key="key">
                <dt class="capitalize"><strong>{{ key }}:</strong></dt>
                <dd v-if="key.toString() != 'authors'">{{ value }}</dd>
                <div v-else>
                    <dl class="ml-6">
                        <div v-for="(author,akey) in value" :key="akey">
                            <dt><strong>{{ author.firstname }} {{ author.lastname }}</strong></dt>
                             <div v-if="author.party && author.party?.name" class="ml-6">
                                <dd><strong>Party</strong>:</dd>
                                <ul class="pl-6">
                                    <div v-for="(party,pk) in author.party" :key="pk">
                                        <li><strong>{{pk}}:</strong> {{ party }}</li>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </dl>
                </div>
            </div>
        </dl>
  </div>
</template>
<script setup lang="ts">
import { computed} from "vue";
import { useStore } from "vuex";



const store = useStore();

const extractResult = computed(()=> store.state.researchPaperAutofillModule.extractResult);
const filteredResult = computed(()=> {
    const {tempUuid, uid, ...result} = extractResult.value;
    return result;
})

</script>