import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "info-box"
}
const _hoisted_2 = {
  key: 0,
  class: "card-content full"
}
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = ["onUpdate:modelValue"]
const _hoisted_5 = {
  key: 1,
  class: "card-content full"
}
const _hoisted_6 = { class: "card-title" }
const _hoisted_7 = ["onUpdate:modelValue"]
const _hoisted_8 = {
  key: 2,
  class: "card-content full"
}
const _hoisted_9 = { class: "card-title" }
const _hoisted_10 = {
  key: 3,
  class: "card-content"
}
const _hoisted_11 = { class: "card-title" }

import { computed } from 'vue';
import { useStore } from 'vuex';
import { NEW_ASSET_FIELDS } from '@/models/newAssetFormFields';
import { PriceContentType } from '@/models/technologyAssetInterface';
import { useQuery } from '@vue/apollo-composable';
import { ASSET_MAIN_CONTENT } from '@/grapql/assetMainContentQueries';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import DropdownSearchableSingle from './DropdownSearchableSingle.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssetDetailPriceContentEditable',
  props: {
    priceContent: {}
  },
  setup(__props: any) {

const props = __props;

const store = useStore();

const assetType = computed(() => store.getters["assetDetailModule/getAssetType"]);
const fieldsInThisAssetType = computed(() => Object.values(NEW_ASSET_FIELDS).find(naf => naf.graphqlValue === assetType.value).formFileds)

// Inizialize the form
const { result } = useQuery(ASSET_MAIN_CONTENT);
const inizilizedData = computed(() => result?.value ?? null)

// Creat option list for drpdown
const fieldsForOptionsList = computed(() => props.priceContent.reduce((acc, obj) => [...acc, obj.queryName], []).filter(value => value !== undefined));
const optionsListForDropdown = computed<{[key: string]: DropdownSearchableOptionInterface[]}>(() => useFormInizializationNewAsset(inizilizedData.value, fieldsForOptionsList.value));
                    
const fieldsForVModels = computed(() => 
    props.priceContent.filter(field => 
            field.editable && 
            (field.inputType == 'multipleSelect' || field.inputType == 'simpleSelect' || field.inputType == 'text' || field.inputType == "number") && 
            fieldsInThisAssetType.value.includes(field.mutationValue)
        ).map(field => {return field.mutationValue}).filter(value => value !== undefined)
    )

const formFieldOptions = {
    fields: fieldsForVModels.value,
    base: assetType.value,
    mutation: 'assetDetailModule/updateMainAssetValue'
};

const formFieldsForSearchableMultipleDropdown = (formFieldOptions) => {
    console.log(formFieldOptions)
    const object = {};
    formFieldOptions.fields.map(fieldName => {
        object[fieldName] = computed({
            get() {
                if(fieldName.includes('.')){
                    const [base, field] = fieldName.split('.');
                    return store.state.assetDetailModule.asset[formFieldOptions.base][base][field] || [];
                }else{
                    return store.state.assetDetailModule.asset[formFieldOptions.base][fieldName] || [];
                }
            },
            set(value) {
                // if(fieldName.includes('.')){
                //     const [base, field] = fieldName.split('.');
                //     store.commit(formFieldOptions.mutation, { mutationValue: base, content: { [field]: value }});
                // }else{
                    store.commit(formFieldOptions.mutation, { mutationValue: fieldName, content: value});
                // }
            }
        })
    });
    return object;
}

const vModels = formFieldsForSearchableMultipleDropdown(formFieldOptions);



return (_ctx: any,_cache: any) => {
  return (_ctx.priceContent)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.priceContent, (item) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: item.title
          }, [
            (( !item.mutationValue || fieldsInThisAssetType.value.includes(item.mutationValue) ))
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (item.editable && item.inputType === 'text')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createElementVNode("p", _hoisted_3, _toDisplayString(item.title), 1),
                        _withDirectives(_createElementVNode("input", {
                          class: "w-full",
                          type: "text",
                          "onUpdate:modelValue": ($event: any) => ((_unref(vModels)[item.mutationValue].value) = $event)
                        }, null, 8, _hoisted_4), [
                          [_vModelText, _unref(vModels)[item.mutationValue].value]
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (item.editable && item.inputType === 'number')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("p", _hoisted_6, _toDisplayString(item.title), 1),
                        _withDirectives(_createElementVNode("input", {
                          class: "w-full",
                          type: "number",
                          "onUpdate:modelValue": ($event: any) => ((_unref(vModels)[item.mutationValue].value) = $event)
                        }, null, 8, _hoisted_7), [
                          [_vModelText, _unref(vModels)[item.mutationValue].value]
                        ])
                      ]))
                    : (item.editable && item.inputType == 'simpleSelect')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createElementVNode("p", _hoisted_9, _toDisplayString(item.title), 1),
                          (optionsListForDropdown.value[item.queryOptions] && _unref(vModels)[item.mutationValue] !== undefined)
                            ? (_openBlock(), _createBlock(DropdownSearchableSingle, {
                                key: 0,
                                "options-list": optionsListForDropdown.value[item.queryOptions],
                                modelValue: _unref(vModels)[item.mutationValue].value,
                                "onUpdate:modelValue": ($event: any) => ((_unref(vModels)[item.mutationValue].value) = $event)
                              }, null, 8, ["options-list", "modelValue", "onUpdate:modelValue"]))
                            : _createCommentVNode("", true)
                        ]))
                      : (!item.editable && !!item.content)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _createElementVNode("p", _hoisted_11, _toDisplayString(item.title), 1),
                            (item.content === 'RESEARCH PROJECT')
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createTextVNode("External Opportunity")
                                ], 64))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  _createTextVNode(_toDisplayString(item.content), 1)
                                ], 64))
                          ]))
                        : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})