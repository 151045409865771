import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between mt-8" }
const _hoisted_2 = {
  href: "https://lookerstudio.google.com/u/0/reporting/f774df22-377b-44b8-b2ca-94113ba69ca0/page/p_f0lzfkoydd",
  target: "_blank"
}
const _hoisted_3 = { class: "flex items-center rounded-full px-4 py-2 bg-moss shadow-md cursor-pointer" }
const _hoisted_4 = { class: "flex items-start mt-3" }
const _hoisted_5 = {
  key: 0,
  class: "grid grid-cols-3 gap-x-4 gap-y-8 mt-5"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "border-t px-4 py-3 flex justify-between items-center" }
const _hoisted_8 = { class: "uppercase font-semibold nude" }

import { CircleStackIcon, StarIcon } from '@heroicons/vue/24/outline';



export default /*@__PURE__*/_defineComponent({
  __name: 'DesingsCards',
  props: ['solutions'],
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (__props.solutions)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createElementVNode("div", _hoisted_1, [
          _cache[1] || (_cache[1] = _createElementVNode("p", { class: "uppercase font-semibold text-gray-700" }, [
            _createTextVNode(" PROPOSED SOLUTIONS "),
            _createElementVNode("span", { class: "text-sm normal-case font-normal" }, "(Possible solutions based on the filtered materials and performance)")
          ], -1)),
          _createElementVNode("a", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_unref(CircleStackIcon), { class: "icon icon-small thick white mr-2" }),
              _cache[0] || (_cache[0] = _createElementVNode("p", { class: "text-white text-xs uppercase" }, "Explore Material Data", -1))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          (__props.solutions)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.solutions, (solution) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: solution.text,
                    class: "flex flex-col border bg-white rounded-md shadow-sm"
                  }, [
                    _createElementVNode("img", {
                      src: require(`@/assets/images/${solution.img}`),
                      class: "blur p-8"
                    }, null, 8, _hoisted_6),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", null, [
                        _createElementVNode("p", _hoisted_8, _toDisplayString(solution.text), 1),
                        _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-xs normal-case font-normal text-gray-400" }, "Click the icon to save this solution", -1))
                      ]),
                      _createVNode(_unref(StarIcon), { class: "top-2 right-2 icon icon-small thick star mr-2" })
                    ])
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ])
      ], 64))
    : _createCommentVNode("", true)
}
}

})