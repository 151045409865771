<template>
    <div class="heartbeat-circle"></div>
</template>
<script lang="ts" setup>
</script>
<style scoped>
@keyframes heartbeat {
  0%, 100% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.6);
  }
}

.heartbeat-circle {
  width: 13px;
  height: 13px;
  margin-bottom: -2px;
  border-radius: 50%;
  background-color: black;
  animation: heartbeat 0.4s infinite alternate;
}
</style>