export const useFormInizializationNewRunSensitivity = (queryResult, fields: string[]) => {
  const object = {};
  if (queryResult) {
    fields
      .map((field) => {
        object[`${field}`] = queryResult.runSensitivityPrices[field]
      });
  }
  return object;
};
