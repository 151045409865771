<template>
    <div class="page-padding">
        <TitleGoBackHeader :title="briefData.title" />
        <PlaygroundPage :brief-data="briefData"/>
        <!-- <template v-if="briefId == 1">
            <TitleGoBackHeader title="PFAS-free paper-based pet food packaging" />
            <DashboardDetailViewPetFood />
        </template>
        <template v-if="briefId == 2">
            <TitleGoBackHeader title="Dish Washer (ADW) doypacks" />
            <DashboardDetailViewDishwasher />
        </template> -->
    </div>
</template>
<script setup lang="ts">
import { useRoute } from 'vue-router';
import TitleGoBackHeader from '@/components/TitleGoBackHeader.vue';
import DashboardDetailViewDishwasher from '@/components/playgroundDemo/DashboardDetailViewDishwasher.vue';
import DashboardDetailViewPetFood from '@/components/playgroundDemo/DashboardDetailViewPetFood.vue';
import { computed } from 'vue';
import { BRIEFS_DATA } from '@/components/playgroundDemo/briefsData';
import PlaygroundPage from '@/components/playgroundDemo/PlaygroundPage.vue';

const route = useRoute()
const briefId = Number(route.params.id);

const briefs = BRIEFS_DATA;
const briefData = computed(() => briefs.find(brief => brief.id === briefId));

</script>