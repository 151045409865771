import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "program-detail-card" }
const _hoisted_2 = { class: "my-3" }
const _hoisted_3 = { class: "subtitle" }
const _hoisted_4 = { class: "value" }

import {PropType} from 'vue';
interface productionTechniquesInterface {
    label: string, 
    value: string
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramTechniquesCard',
  props: {
    productionTechniques: Array as PropType<productionTechniquesInterface[]>
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("p", { class: "cards-title" }, "production Techniques", -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.productionTechniques, (technique) => {
        return (_openBlock(), _createElementBlock("div", {
          key: technique.label,
          class: "px-3 py-2"
        }, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(technique.label), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(technique.value), 1)
        ]))
      }), 128))
    ])
  ]))
}
}

})