import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "col-span-4" }
const _hoisted_2 = { class: "grid grid-cols-4 gap-y-3 gap-x-4" }
const _hoisted_3 = {
  key: 0,
  class: "col-span-4"
}
const _hoisted_4 = {
  key: 0,
  class: "button green-accept cursor-not-allowed opacity-75 hover:box-shadow:none",
  disabled: ""
}
const _hoisted_5 = ["disabled"]
const _hoisted_6 = {
  key: 1,
  class: "md:col-span-2 sm:col-span-4"
}
const _hoisted_7 = { class: "mb-4 p-4 bg-gray-100 border" }
const _hoisted_8 = { class: "font-bold" }
const _hoisted_9 = { class: "min-w-full border mb-4" }
const _hoisted_10 = { class: "py-2 px-4" }
const _hoisted_11 = { class: "py-2 px-4" }
const _hoisted_12 = { class: "overflow-hidden" }
const _hoisted_13 = {
  key: 2,
  class: "md:col-span-2 sm:col-span-4"
}
const _hoisted_14 = { class: "mb-4 p-4 bg-gray-100 border" }
const _hoisted_15 = { class: "font-bold" }
const _hoisted_16 = { class: "overflow-hidden" }
const _hoisted_17 = {
  key: 3,
  class: "md:col-span-4 sm:col-span-4"
}
const _hoisted_18 = { class: "mb-0 mt-4 p-4 bg-gray-100 border" }
const _hoisted_19 = { class: "font-bold" }
const _hoisted_20 = { class: "mb-4 p-4 bg-gray-50 border" }
const _hoisted_21 = { class: "overflow-hidden" }

import { computed } from "vue";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { useStore } from "vuex";
import MecChart from "@/tea/components/runs/charts/MecChart.vue";
import OeChart from "@/tea/components/runs/charts/OeChart.vue";
import NpvChart from "@/tea/components/runs/charts/NpvChart.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'RunOutputs',
  props: ["isReadyForOutput"],
  emits: ["showOutputs"],
  setup(__props, { emit: __emit }) {

const store = useStore();
const props = __props;
const emits = __emit;

const isOutputLoading = computed(() => store.getters["runCreateModule/isOutputLoading"]);
const ShowOutputButtonText = computed(() => (isOutputLoading.value ? "Loading Outputs . . ." : "Show Outputs"));

const teaOutput = computed(() => store.getters["runCreateModule/teaOutput"]);

const fixedCapitalInvestment = computed(() => {
  const fci = teaOutput.value.fixedCapitalInvestment.map((item) => {
    return { typeOfCost: item.typeOfCost, cost: item.cost };
  });
  return fci;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!__props.isReadyForOutput)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (isOutputLoading.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Loading Outputs . . ."))
              : (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('showOutputs'))),
                  disabled: isOutputLoading.value,
                  class: "button green-accept",
                  style: {"cursor":"pointer"}
                }, "Show Outputs", 8, _hoisted_5))
          ]))
        : _createCommentVNode("", true),
      (__props.isReadyForOutput && teaOutput.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _cache[1] || (_cache[1] = _createTextVNode(" Your CAPEX is:  ")),
              _createElementVNode("span", _hoisted_8, _toDisplayString(teaOutput.value.capex.toLocaleString()) + " €", 1)
            ]),
            _createElementVNode("table", _hoisted_9, [
              _cache[2] || (_cache[2] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", {
                    colspan: "2",
                    class: "bg-gray-100 border py-2 px-4 text-left"
                  }, "Fixed Capital Investment")
                ]),
                _createElementVNode("tr", { class: "bg-gray-100" }, [
                  _createElementVNode("th", { class: "py-2 px-4 text-left" }, "Type of Cost"),
                  _createElementVNode("th", { class: "py-2 px-4 text-left" }, "EUR")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fixedCapitalInvestment.value, (fci, index) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    class: _normalizeClass(["border border-dashed", { a: index % 2 === 0 }]),
                    key: index
                  }, [
                    _createElementVNode("td", _hoisted_10, _toDisplayString(fci.typeOfCost), 1),
                    _createElementVNode("td", _hoisted_11, _toDisplayString(fci.cost.toLocaleString()), 1)
                  ], 2))
                }), 128))
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(MecChart, {
                class: "w-full border",
                data: teaOutput.value.majorEquipmentCosts
              }, null, 8, ["data"])
            ])
          ]))
        : _createCommentVNode("", true),
      (__props.isReadyForOutput && teaOutput.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _cache[3] || (_cache[3] = _createTextVNode(" Your OPEX is:  ")),
              _createElementVNode("span", _hoisted_15, _toDisplayString(teaOutput.value.opex.toLocaleString()) + " €", 1)
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createVNode(OeChart, {
                class: "w-full border",
                data: teaOutput.value.operatingExpenses
              }, null, 8, ["data"])
            ])
          ]))
        : _createCommentVNode("", true),
      (__props.isReadyForOutput && teaOutput.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _cache[4] || (_cache[4] = _createTextVNode(" Expected yearly income  ")),
              _createElementVNode("span", _hoisted_19, _toDisplayString(teaOutput.value.income.yearlyIncome.toLocaleString()) + " €", 1)
            ]),
            _createElementVNode("div", _hoisted_20, _toDisplayString(teaOutput.value.income.incomeMessage), 1),
            _createElementVNode("div", _hoisted_21, [
              _createVNode(NpvChart, {
                class: "w-full border",
                data: teaOutput.value.income.npvs
              }, null, 8, ["data"])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})