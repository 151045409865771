const host = window.location.host;
const parts = host.split(".");
parts[0] === "www" ? parts.shift() : true;

const teaTlds:string[] = (process.env.VUE_APP_TEA_TLD_DOMAINS)?.split(':').map(item => item.trim())

const isTea = teaTlds?.includes(parts[0])

export default {
  isTea,
};
