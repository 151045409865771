<template>
  <div class="title-menu-header">
    <h1 class="view-title">{{ title }}</h1>
    <div class="action-icons">
      <div class="round-action-icon" @click="openModal('edit')">
        <PencilIcon class="icon icon-small" />
      </div>
      <!-- Edit in the nexs ones to be changed for publish and save once the functionality exists -->
      <div class="round-action-icon tooltip disable" @click="openModal('edit')">
        <span class="tooltiptext danger">Save for later</span>
        <BookmarkIcon class="icon icon-small" />
      </div>
      <div class="round-action-icon tooltip disable" @click="openModal('edit')">
        <span class="tooltiptext danger">Publish this table</span>
        <RocketLaunchIcon class="icon icon-small" />
      </div>
      <div class="round-action-icon" @click="$router.go(-1)">
        <ArrowUturnLeftIcon class="icon icon-small" />
      </div>
    </div>
  </div>
  <TechnologyAssetsComparisonCriteriaModal :action="action"/>
</template>
<script setup lang="ts">
import { ArrowUturnLeftIcon, BookmarkIcon, PencilIcon, RocketLaunchIcon } from '@heroicons/vue/24/outline';
import TechnologyAssetsComparisonCriteriaModal from '@/components/TechnologyAssetsComparisonCriteriaModal.vue';
import { defineProps, ref } from 'vue';
import { useStore } from 'vuex';

defineProps({ title: String })
const store = useStore();

const action = ref('compare');


const openModal = (actionToSet: string) => {
  action.value = actionToSet;
  store.commit('assetsModule/openComparisonCriteriaModal')
}

</script>
<style lang="scss" scoped>

.tooltiptext {
  font-size: 0.8rem!important;
  &::after {
    border-top: solid transparent 8px;
    border-bottom: solid transparent 8px;
    border-right: solid #F99672 8px;
    bottom: 7px;
    content: " ";
    height: 0;
    top: -10px;
    left: 48%;
    position: absolute;
    width: 0;
    transform: rotate(90deg);
  }
}
.disable {
  opacity: 0.3;
  pointer-events: none;
  
}
</style>