<template>
    <svg class="layers" viewBox="0 0 100 90" xmlns="http://www.w3.org/2000/svg">
        <g v-for="(layer, index) in layersDataWithOffset" :key="index" class="layer" :style="`--offset-x:0px; --offset-y:-${layer.offset}px; --offset-z:0px; --size:100;`"> 
            <path :d="`M0,${startingPoint} v-${layer.thickness} L50,${startingPoint - layer.thickness - inclination} L100,${startingPoint - layer.thickness} v${layer.thickness} L50,${startingPoint + inclination} Z`" :fill="layer.color" :filter="layer.color"></path>
            <path :d="`M0,${startingPoint} v-${layer.thickness} L50,${startingPoint + inclination - layer.thickness} v${layer.thickness} Z`"></path>
            <path :d="`M50,${startingPoint + inclination} v-${layer.thickness} L100,${startingPoint - layer.thickness} v${layer.thickness} Z`"></path>
        </g>
     </svg>
</template>

<script setup lang="ts">
import { layerDataInterface } from '@/models/programVersionsInterface';
import { computed, defineProps} from 'vue';

const props = defineProps<{ 
    layersData: layerDataInterface[]; 
    isMockup?: boolean 
}>();

const colors = ['#1c70b2', '#badfd9', '#5fa2a6', '#d4c7c1', '#2a87d3', '#7d91bc']

const layersDataWithDefault = computed<layerDataInterface[]>(() => props.layersData.map(({id, color, thickness, position}) => ({
    id: id,
    color: !color ? colors[position] : color,
    thickness: thickness,
    position: position
})).sort((a, b) => a.position - b.position))


const startingPoint = 60;
const inclination = 20;

const layersDataWithOffset = computed(() => {
    let acc = 0;
    let newArray = [];
    let reverseArray = []
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    reverseArray = !props.isMockup ? layersDataWithDefault.value.reverse() : layersDataWithDefault.value;
    newArray = reverseArray.map((obj, index) => {
        acc = index != 0 ? acc + reverseArray[index - 1].thickness + 5 : 0;
        return {...obj, offset: acc}
    })
    return newArray;
})

</script>
<style lang="scss" scoped>
    .layers {
        width: 300px;
        max-width: 100%;
        display: inline-flex;
        overflow: visible;
        padding: 0.5rem 1.5rem;
    }

    g {
        transform: translateX(var(--offset-x)) translateY(var(--offset-y)) translateZ(var(--offset-z));
    }

    text {
        font-weight: normal;
        transform: skew(-68deg, 23deg) translate(calc(75px * var(--size) / 100), calc(18px * var(--size) / 100)) scaleY(0.5);
        fill: rgba(255 255 255 / 80%);
    }

    path:nth-child(2) {
        fill: rgba(0 0 0 / 20%);
    }

    path:nth-child(3) {
        fill: rgba(255 255 255 / 20%);
    }

</style>