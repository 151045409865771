import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "accordion-card-title" }
const _hoisted_2 = { class: "img-container" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "title-text" }
const _hoisted_5 = { class: "assessment-description" }



export default /*@__PURE__*/_defineComponent({
  __name: 'AccordionCardHeader',
  props: ['imgName', 'title', 'subtitle', 'status'],
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["status-dot", __props.status])
    }, null, 2),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: require(`@/assets/icons/${__props.imgName}`),
        alt: __props.subtitle
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", null, _toDisplayString(__props.title), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(__props.subtitle), 1)
    ])
  ]))
}
}

})