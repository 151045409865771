<template>
  <div class="mb-4">
    <Chart :options="chartOptions" />
  </div>
</template>
<script setup lang="ts">
import { defineProps, computed } from "vue";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import accessibilityInit from "highcharts/modules/accessibility"; // Import the accessibility module
import exportingInit from "highcharts/modules/exporting";

// Initialize the accessibility module
accessibilityInit(Highcharts);
// Initialize the exporting module
exportingInit(Highcharts);

const props = defineProps(["data"]);
const chartData = computed(() => {
  return props.data.map((item) => ({
    x: item.index,
    y: item.npv,
    color: item.npv < 0 ? "red" : "green",
  }));
});
const chartOptions = {
  chart: {
    type: "column",
  },
  title: {
    text: "Net Present Values",
  },
  xAxis: {
    categories: chartData.value.x,
  },
  yAxis: {
    title: {
      text: "Npvs",
    },
  },
  series: [
    {
      name: "Years",
      data: chartData.value,
    },
  ],
  exporting: {
    enabled: true,
  },
  tooltip: {
    formatter: function () {
      // Customize the tooltip format here
      return `year ${this.x}: <b>${this.y}</b>`;
    },
  },
};
</script>
