<template>
  <div>
    <div v-if="loading" class="loading-gif"></div>
    <vue-good-table v-else
      :columns="columns"
      :rows="rows"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
        perPageDropdown: [5, 10,20],
        dropdownAllowAll: false,
      }"
      v-on:page-change="$emit('onPageChange')"
      v-on:per-page-change="$emit('onPageChange')"
      :row-style-class="rowStyleClass"
      styleClass="vgt-table my-table"
      v-on:row-click="onRowClick"
      class="vgt-holder"
      :search-options="{
        enabled: false,
        externalQuery: searchTerm,
      }"
    >
      <template #emptystate> You have no run groups. </template>
    </vue-good-table>

    <hr class="mt-10" />
  </div>
</template>

<script setup>
import { useQuery } from "@vue/apollo-composable";
import { ref, computed, defineProps,defineEmits } from "vue";
import { VueGoodTable } from "vue-good-table-next";
import "vue-good-table-next/dist/vue-good-table-next.css";
// in Query it should always be more than perPage, I have used perPage*2
const perPage = ref(5);

const rowStyleClass = (row) => {
  return "hover:bg-gray-100";
  //   const proxy = new Proxy(row, {
  //     get: function (target, prop) {
  //       return target[prop];
  //     },
  //   });
  //   return proxy.originalIndex % 2 === 0 ? "bg-gray-50 table-row hover:bg-gray-200" : "bg-white  table-row hover:bg-gray-200";
};
const onRowClick = (params) => {
  emits("onRowClick",params.row.id);
};

const props = defineProps(["columns", "rows", "searchTerm","loading"]);
const emits = defineEmits(["onRowClick","onPageChange"]);
// const VueGoodTableComponent = VueGoodTable;
</script>

<style>
.vgt-holder .vgt-wrap__footer {
  background: none;
}
.my-table {
  border: none;
}
.my-table tbody tr {
  cursor: pointer;
}
.my-table tbody tr td {
  padding: 15px !important;
}
.my-table thead,
.my-table thead tr,
.my-table thead tr th {
  background: #eee;
  padding: 15px;
  text-transform: uppercase;
}

.round-tooltip-icon {
  border-radius: 50%;
  background: #e3e3e3;
  border: solid 1px #e0e0e0;
  padding: 1px 10px;
  cursor: pointer;
  font-size: 25px;
}
</style>
