import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex items-center px-4" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = ["value"]
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "sort-direction" }

import { ColumnAssetInterface } from '@/models/technologyAssetsInterface';
import { ArrowsUpDownIcon, ArrowLongDownIcon, ArrowLongUpIcon } from '@heroicons/vue/24/outline'
import { computed } from 'vue';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'TechnologyAssetsFilterSortBy',
  setup(__props) {

const store = useStore();
const columns = computed<ColumnAssetInterface[]>(() => store.getters['assetsModule/getAssetsTableColumns']);

const sortableColumns = computed(() => columns.value.filter(column => column.sortable))
const sortValue = computed(()=> store.state.assetsModule.sortValue )
const selectedDirection = computed(()=> store.state.assetsModule.sortDirection );

const handleChangeDirection = (direction: string) => store.commit('assetsModule/setSortDirection', {sortDirection: direction})
const handleSortValue = (event: Event) => store.commit('assetsModule/setSortValue', {sortValue: (event.target as HTMLInputElement).value}) 


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, [
        _createVNode(_unref(ArrowsUpDownIcon), { class: "icon icon-small" }),
        _cache[2] || (_cache[2] = _createTextVNode(" Sort By"))
      ]),
      _createElementVNode("select", {
        value: sortValue.value,
        onInput: handleSortValue,
        class: "filter-select"
      }, [
        _cache[3] || (_cache[3] = _createElementVNode("option", { value: null }, null, -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortableColumns.value, (column) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: column.name
          }, [
            (!column.hidden)
              ? (_openBlock(), _createElementBlock("option", {
                  key: 0,
                  value: column.field
                }, _toDisplayString(column.label), 9, _hoisted_5))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ], 40, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", {
        class: _normalizeClass(["sort-option", {selected: selectedDirection.value == ''}]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (handleChangeDirection('')))
      }, [
        _createVNode(_unref(ArrowLongDownIcon), { class: "icon icon-small thick" }),
        _cache[4] || (_cache[4] = _createTextVNode()),
        _cache[5] || (_cache[5] = _createElementVNode("p", null, "From A to Z", -1))
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["sort-option", {selected: selectedDirection.value == '-'}]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (handleChangeDirection('-')))
      }, [
        _createVNode(_unref(ArrowLongUpIcon), { class: "icon icon-small thick" }),
        _cache[6] || (_cache[6] = _createTextVNode()),
        _cache[7] || (_cache[7] = _createElementVNode("p", null, "From Z to A", -1))
      ], 2)
    ])
  ]))
}
}

})