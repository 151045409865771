import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withKeys as _withKeys, createVNode as _createVNode, isRef as _isRef, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/icons/checked.png'
import _imports_1 from '@/assets/icons/remove.png'


const _hoisted_1 = {
  key: 0,
  class: "info-box"
}
const _hoisted_2 = { class: "card-content full" }
const _hoisted_3 = {
  key: 0,
  class: "card-content full"
}
const _hoisted_4 = { class: "card-title" }
const _hoisted_5 = ["onUpdate:modelValue"]
const _hoisted_6 = {
  key: 1,
  class: "card-content full"
}
const _hoisted_7 = { class: "card-title" }
const _hoisted_8 = ["disabled", "value", "onKeyup"]
const _hoisted_9 = {
  key: 0,
  class: "selected-results"
}
const _hoisted_10 = {
  key: 2,
  class: "card-content full"
}
const _hoisted_11 = { class: "card-title" }
const _hoisted_12 = ["onKeyup"]
const _hoisted_13 = {
  key: 0,
  class: "selected-results"
}
const _hoisted_14 = {
  key: 3,
  class: "card-content full"
}
const _hoisted_15 = { class: "card-title" }
const _hoisted_16 = { class: "mt-3" }
const _hoisted_17 = {
  key: 4,
  class: "card-content full"
}
const _hoisted_18 = { class: "card-title" }
const _hoisted_19 = {
  key: 5,
  class: "card-content full"
}
const _hoisted_20 = { class: "card-title" }
const _hoisted_21 = { class: "card-title" }
const _hoisted_22 = {
  key: 7,
  class: "card-content"
}
const _hoisted_23 = { class: "card-title" }
const _hoisted_24 = {
  key: 0,
  src: _imports_0,
  alt: "item.title"
}
const _hoisted_25 = {
  key: 1,
  src: _imports_1,
  alt: "item.title"
}
const _hoisted_26 = {
  key: 8,
  class: "card-content"
}
const _hoisted_27 = { class: "card-title" }

import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { NEW_ASSET_FIELDS } from '@/models/newAssetFormFields';
import { InfoContentType } from '@/models/technologyAssetInterface';
import { useQuery } from '@vue/apollo-composable';
import { ASSET_MAIN_CONTENT } from '@/grapql/assetMainContentQueries';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import DropdownSearchableMultiple from './DropdownSearchableMultiple.vue';
import DropdownSearchableSingle from './DropdownSearchableSingle.vue';
import { PARTIES_QUERY } from '@/grapql/assetAddFormValues';
import PartyAddNew from '@/components/PartyAddNew.vue';
import SearchInputWithResultSelection from './SearchInputWithResultSelection.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'AssetDetailInfoContentEditable',
  props: {
    infoContent: {}
  },
  setup(__props: any) {

const props = __props;

let templinks = '';
const store = useStore();

const assetType = computed(() => store.getters["assetDetailModule/getAssetType"]);
const fieldsInThisAssetType = computed(() => Object.values(NEW_ASSET_FIELDS).find(naf => naf.graphqlValue === assetType.value).formFileds);


// Inizialize the form
const { result } = useQuery(ASSET_MAIN_CONTENT);
const inizilizedData = computed(() => result?.value ?? null)

// Creat option list for drpdown
const fieldsForOptionsList = computed(() => props.infoContent.reduce((acc, obj) => [...acc, obj.queryName], []).filter(value => value !== undefined));
const optionsListForDropdown = computed<{ [key: string]: DropdownSearchableOptionInterface[] }>(() => useFormInizializationNewAsset(inizilizedData.value, fieldsForOptionsList.value));

const fieldsForVModels = computed(() =>
    props.infoContent.filter(field =>
        field.editable &&
        (field.inputType == 'multipleSelect' || field.inputType == 'simpleSelect' || field.inputType == 'text') &&
        fieldsInThisAssetType.value.includes(field.mutationValue)
    ).map(field => { return field.mutationValue }).filter(value => value !== undefined).concat('nickname')
)

const formFieldOptions = {
    fields: fieldsForVModels.value,
    base: assetType.value,
    mutation: 'assetDetailModule/updateMainAssetValue'
};

const formFieldsForSearchableMultipleDropdown = (formFieldOptions) => {
    const object = {};
    formFieldOptions.fields.map(fieldName => {
        object[fieldName] = computed({
            get() {
                return store.state.assetDetailModule.asset[formFieldOptions.base][fieldName] || [];
            },
            set(value) {
                store.commit(formFieldOptions.mutation, { mutationValue: fieldName, content: value });
            }
        })
    });
    return object;
}

const vModels = formFieldsForSearchableMultipleDropdown(formFieldOptions);

const handleInputChange = (event: Event) => (event.target as HTMLInputElement).value;

const addLinkToLinkList = (event: Event, item) => {
    const selectedValue = (event.target as HTMLInputElement).value;
    const newArray = item.content ? [...item.content] : [];
    newArray.push(selectedValue);
    item.content = newArray;
    commitValue(item);
}

const removeLinkToLinkList = (index, item) => {
    const newArray = item.content ? [...item.content] : [];
    newArray.splice(index, 1);
    item.content = newArray;
    commitValue(item)
}
const commitValue = (item) => {
    store.commit('assetDetailModule/updateMainAssetValue', { mutationValue: item.mutationValue, content: item.content })
}


// HANDLE PARTIES FOR OTHER TECHNOLOGIES - TO BE REMOVED THEN - 
// Parties 
const enableQuery = ref(false)
const queryOption = ref({ filter: {} });
const searchCriteria = ref()
// Filtered parties
const { result: parties, refetch: refetchParties } = useQuery(PARTIES_QUERY, queryOption, () => ({ enabled: enableQuery.value, }));
const partiesQueryResults = computed(() => parties?.value ?? null);
const foundParties = computed<DropdownSearchableOptionInterface[]>(() => partiesQueryResults?.value && partiesQueryResults.value?.parties ? partiesQueryResults.value?.parties?.edges.map((edge: { node }) => edge.node) : null);
const foundPartiesCount = computed<number | null>(() => partiesQueryResults?.value && partiesQueryResults.value?.parties ? partiesQueryResults.value.parties.totalCount : -1);

const partiesForDropdown = computed(() => foundParties.value ? foundParties.value.map(({ id, nickname }) => ({ id, name: nickname })) : []);

const onPartySearchValueChange = (searchValue: string) => {
    queryOption.value.filter = { nickname: { icontains: searchValue } };
    enableQuery.value = true;
    searchCriteria.value = searchValue
    refetchParties();
}


return (_ctx: any,_cache: any) => {
  return (_ctx.infoContent)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("p", { class: "card-title" }, "Nickname", -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "w-full mb-2",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(vModels)['nickname'].value) = $event))
          }, null, 512), [
            [_vModelText, _unref(vModels)['nickname'].value]
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoContent, (item) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: item.title
          }, [
            ((!item.mutationValue || fieldsInThisAssetType.value.includes(item.mutationValue)))
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (item.editable && item.inputType === 'text')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createElementVNode("p", _hoisted_4, _toDisplayString(item.title), 1),
                        _withDirectives(_createElementVNode("input", {
                          class: "w-full",
                          type: "text",
                          "onUpdate:modelValue": ($event: any) => ((_unref(vModels)[item.mutationValue].value) = $event)
                        }, null, 8, _hoisted_5), [
                          [_vModelText, _unref(vModels)[item.mutationValue].value]
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (item.editable && item.type === 'link')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createElementVNode("p", _hoisted_7, [
                          _createTextVNode(_toDisplayString(item.title) + " ", 1),
                          _cache[3] || (_cache[3] = _createElementVNode("span", null, "(Only one value)", -1))
                        ]),
                        _createElementVNode("input", {
                          disabled: item.content != null,
                          value: item.content,
                          class: "w-full",
                          type: "text",
                          onKeyup: _withKeys(($event: any) => {item.content = handleInputChange($event); commitValue(item)}, ["enter"])
                        }, null, 40, _hoisted_8),
                        (item.content)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                              _createElementVNode("span", null, [
                                _createVNode(_unref(XMarkIcon), {
                                  class: "icon icon-x-small thick inline shrink-0",
                                  onClick: ($event: any) => {item.content = null; commitValue(item)}
                                }, null, 8, ["onClick"])
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : (item.editable && item.type === 'linkList')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createElementVNode("p", _hoisted_11, [
                            _createTextVNode(_toDisplayString(item.title) + " ", 1),
                            _cache[4] || (_cache[4] = _createElementVNode("span", null, " (Press Enter to add a link)", -1))
                          ]),
                          _withDirectives(_createElementVNode("input", {
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(templinks) ? (templinks).value = $event : templinks = $event)),
                            class: "w-full",
                            type: "text",
                            onKeyup: _withKeys(($event: any) => {_isRef(templinks) //@ts-ignore
 ? templinks.value = '' : templinks = ''; addLinkToLinkList($event, item)}, ["enter"])
                          }, null, 40, _hoisted_12), [
                            [_vModelText, _unref(templinks)]
                          ]),
                          (item.content)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.content, (selectedOption, index) => {
                                  return (_openBlock(), _createElementBlock("span", { key: index }, [
                                    _createTextVNode(_toDisplayString(selectedOption) + " ", 1),
                                    _createVNode(_unref(XMarkIcon), {
                                      class: "icon icon-x-small thick inline shrink-0",
                                      onClick: ($event: any) => (removeLinkToLinkList(index, item))
                                    }, null, 8, ["onClick"])
                                  ]))
                                }), 128))
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : (item.editable && item.inputType == 'multipleSelect' && assetType.value == 'otherTechnology')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                            _createElementVNode("p", _hoisted_15, _toDisplayString(item.title), 1),
                            _createVNode(SearchInputWithResultSelection, {
                              modelValue: _unref(vModels)[item.mutationValue].value,
                              "onUpdate:modelValue": ($event: any) => ((_unref(vModels)[item.mutationValue].value) = $event),
                              "options-list": partiesForDropdown.value,
                              "search-label": 'Search for suppliers:',
                              "result-count": foundPartiesCount.value,
                              "is-multiple": true,
                              "selected-values": item.rawContent,
                              onSearchValueChange: onPartySearchValueChange
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "options-list", "result-count", "selected-values"]),
                            _createElementVNode("div", _hoisted_16, [
                              _createVNode(PartyAddNew, { "party-type": "supplier" })
                            ])
                          ]))
                        : (item.editable && item.inputType == 'multipleSelect')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                              _createElementVNode("p", _hoisted_18, _toDisplayString(item.title), 1),
                              (optionsListForDropdown.value[item.queryOptions] && _unref(vModels)[item.mutationValue] !== undefined)
                                ? (_openBlock(), _createBlock(DropdownSearchableMultiple, {
                                    key: 0,
                                    "options-list": optionsListForDropdown.value[item.queryOptions],
                                    modelValue: _unref(vModels)[item.mutationValue].value,
                                    "onUpdate:modelValue": ($event: any) => ((_unref(vModels)[item.mutationValue].value) = $event)
                                  }, null, 8, ["options-list", "modelValue", "onUpdate:modelValue"]))
                                : _createCommentVNode("", true)
                            ]))
                          : (item.editable && item.inputType == 'simpleSelect')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                _createElementVNode("p", _hoisted_20, _toDisplayString(item.title), 1),
                                (optionsListForDropdown.value[item.queryOptions] && _unref(vModels)[item.mutationValue] !== undefined)
                                  ? (_openBlock(), _createBlock(DropdownSearchableSingle, {
                                      key: 0,
                                      "options-list": optionsListForDropdown.value[item.queryOptions],
                                      modelValue: _unref(vModels)[item.mutationValue].value,
                                      "onUpdate:modelValue": ($event: any) => ((_unref(vModels)[item.mutationValue].value) = $event)
                                    }, null, 8, ["options-list", "modelValue", "onUpdate:modelValue"]))
                                  : _createCommentVNode("", true)
                              ]))
                            : (!item.editable && item.type && item.type === 'list' && !!item.content)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 6,
                                  class: _normalizeClass(["card-content", { 'extra-width': item.extraWidth }])
                                }, [
                                  (item.content && item.content.length)
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        _createElementVNode("p", _hoisted_21, _toDisplayString(item.title), 1),
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.content, (itemInList) => {
                                          return (_openBlock(), _createElementBlock("p", { key: itemInList }, _toDisplayString(itemInList), 1))
                                        }), 128))
                                      ], 64))
                                    : _createCommentVNode("", true)
                                ], 2))
                              : (!item.editable && item.type && item.type === 'boolean' && !!item.content)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                                    _createElementVNode("span", _hoisted_23, _toDisplayString(item.title), 1),
                                    (item.content)
                                      ? (_openBlock(), _createElementBlock("img", _hoisted_24))
                                      : (_openBlock(), _createElementBlock("img", _hoisted_25))
                                  ]))
                                : (!item.editable && !!item.content)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                      _createElementVNode("p", _hoisted_27, _toDisplayString(item.title), 1),
                                      (item.content === 'RESEARCH PROJECT')
                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                            _createTextVNode("External Opportunity")
                                          ], 64))
                                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                            _createTextVNode(_toDisplayString(item.content), 1)
                                          ], 64))
                                    ]))
                                  : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})