import { useMutation, useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { provideApolloClient } from "@vue/apollo-composable";
import apolloClient from "@/utils/apolloClient";
import { POSITION, useToast } from "vue-toastification";


provideApolloClient(apolloClient);
const toast = useToast()

const state = () => ({
    user: null,
    isSidebarCollapsed: true,
    usersWithExtraInfo: ['marta', 'martin', 'paula']
})

export const mutations = {
    setUser(state, payload) {
        state.user = payload;
        console.log(state.user.username)
    },
    logoutUser(state) {
        state.user = null;
    },
    toogleSidebar(state) {
        state.isSidebarCollapsed = !state.isSidebarCollapsed
    }
}

const actions = {

    async login({ dispatch }, accessToken: string) {
        return new Promise<void>((resolve, reject) => {
            const { mutate: socialAuth, onDone, onError } = useMutation(gql`
                mutation socialAuth($accessToken: String!, $provider: String!) {
                    socialAuth (accessToken: $accessToken, provider: $provider) {
                        token
                        refreshToken
                    }
            } `, {
                context: {
                    uri: process.env.VUE_APP_ROOT_API + '/auth'
                }
            });
            const provider = 'google-oauth2';
            try {
                localStorage.removeItem('auth_token');
                localStorage.removeItem('refresh_token');
                socialAuth({ accessToken, provider });
                onDone(async (result) => {
                    localStorage.setItem('auth_token', result.data.socialAuth.token);
                    localStorage.setItem('refresh_token', result.data.socialAuth.refreshToken);
                    await dispatch('meQuery');
                    resolve();
                })
                onError((error) => {
                    if (error.message.toLowerCase().includes('allowed')) {
                        toast.error("Unauthorized credentials", { position: POSITION.TOP_CENTER })
                    }
                })
            } catch (error) {
                console.log(error);
                reject();
            }
        })
    },

    async meQuery(context) {
        return new Promise<void>((resolve, reject) => {
            const { onResult, onError } = useQuery(gql`
            query getUserInfo { 
                me{
                    id
                    lastLogin
                    firstName
                    lastName
                    dateJoined
                    email
                    isStaff
                    username
                }}`
                );
            onResult(
                queryResult => {
                    if (queryResult.data && queryResult.data.me) {
                        context.commit("setUser", queryResult.data.me);
                        resolve();
                      } else {
                        // Handle the case when the data is missing in the result
                        // reject();
                      }
                }
            );
            onError(error => {
                console.log(error);
                reject();
            })
        })
    },

    async refreshUser(context) {
        try {
            await context.dispatch('meQuery');

        } catch (err) {
            console.log(err);
        }
    },

    async logout({ commit }) {
        localStorage.removeItem('auth_token');
        localStorage.removeItem('refresh_token')
        commit('logoutUser');
        apolloClient.clearStore();
    }

}
const getters = {
    isAuthenticated(state) {
        return !!state.user && state.user.isStaff
    },
    isSidebarCollapsed(state) {
        return state.isSidebarCollapsed
    },
    getUsersWithExtraInfo(state){
        return state.usersWithExtraInfo;
    },
    isUserAllowToSeeExtraInfo(state) {
        return !!state.user && state.user.username ? state.usersWithExtraInfo.includes(state.user.username) : false 
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}