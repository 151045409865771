import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "px-6 pt-8" }
const _hoisted_5 = { class: "px-6 pt-4" }
const _hoisted_6 = { class: "px-6 pt-4" }
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "px-6 pt-4" }
const _hoisted_9 = { class: "px-6 sticky bottom-5 z-10" }

import { ref, computed, onMounted } from 'vue';
import ModalSlots from "@/components/ModalSlots.vue";
import FormErrorToast from '@/components/FormErrorToast.vue';
import { QuillEditor } from '@vueup/vue-quill';
import { useStore } from 'vuex';
import { useQuery } from '@vue/apollo-composable';
import { calcThickness } from '@/composables/useLayerThicknessToVisualThickness';
import ProgramVersionDesignLayersForm from './ProgramVersionDesignLayersForm.vue';
import { DESIGN_STATUSES_QUERY } from '@/grapql/designStatusesQuery';
import { DesignForForm, LayerCreateInterface, LayersFromBEInterface } from '@/models/programVersionsInterface';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramVersionDesignForm',
  props: {
    isOpen: { type: Boolean },
    onEdit: { type: Boolean },
    designId: {}
  },
  emits: ['closeModal'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emits = __emit;
const store = useStore();

const newDesign = computed<DesignForForm>(() => store.state['programVersionDetailModule'].newDesign);
const onEditionDesign = computed<DesignForForm>(() => store.state['programVersionDetailModule'].designForEdition);
const design = computed<DesignForForm>(() => props.onEdit ? onEditionDesign.value : newDesign.value);


const { result } = useQuery(DESIGN_STATUSES_QUERY) ;
const inizilizedData = computed(() => result?.value ?? null);
const statuses = computed(() => inizilizedData.value && inizilizedData.value.designStatuses ? inizilizedData.value.designStatuses.edges.map(edge => edge.node) : []);

const onEditorReady = (event: Event) => { return event };

// Validate form 
const formSubmited = ref(false);
const valuesSubmitted = computed(() => !props.onEdit ? store.state['programVersionDetailModule'].newDesign : store.state['programVersionDetailModule'].designForEdition);
const formIsValid = computed(() => valuesSubmitted.value.nickname && valuesSubmitted.value.layers.every((layer: LayerCreateInterface) => !!layer.thicknessUnitType && !!layer.layerType && !!layer.thicknessValueType && !!layer.thicknessValue1));

const errorsFromMutation = computed(() => store.state['programVersionDetailModule']['errorDuringMutation']['designCreate']);
const showMutationErrors = computed(() => formSubmited.value && !!errorsFromMutation.value);

const addThicknessToLayers = () =>  store.state['programVersionDetailModule'].newDesign.layers.map((layer: LayerCreateInterface) => layer.visualThickness = calcThickness(layer.thicknessValue1));
const colors = ['#1c70b2', '#badfd9', '#5fa2a6', '#d4c7c1', '#2a87d3', '#7d91bc']
const addDefaultColors = () =>  store.state['programVersionDetailModule'].newDesign.layers.map((layer: LayerCreateInterface, index:number) => layer.colorCode = colors[index]);
const setOrderToLayers = () => design.value.layers.map((layer, index: number) => layer.layerOrder = index+1)

const handleCreateOrUpdate = async () => {
    formSubmited.value = true;
    if(!props.onEdit && formIsValid.value) {
        addThicknessToLayers();
        addDefaultColors();
        setOrderToLayers();
        await store.dispatch('programVersionDetailModule/designCreateWithLayers')
            .then(() => {
                if (!errorsFromMutation.value) {
                    formSubmited.value = false;
                    store.commit('programVersionDetailModule/resetNewDesignValues');
                    emits('closeModal')
                }
            });
    }
    if(props.onEdit && formIsValid.value) {
        setOrderToLayers();
        console.log(store.state['programVersionDetailModule'].designForEdition.layers)
        await store.dispatch('programVersionDetailModule/layerDelete')
            .then(async () => { await store.dispatch('programVersionDetailModule/designUpdatePartial')})
            .then(() => {
                if (!errorsFromMutation.value) {
                    formSubmited.value = false;
                    emits('closeModal')
                }
            }); 
    }
}


onMounted(() => {
    if (props.onEdit && props.designId) {
        store.commit('programVersionDetailModule/parseBeDesignForEdition', { id: props.designId });
    }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalSlots, {
    "create-text": _ctx.onEdit ? 'Update' : 'Create',
    show: _ctx.isOpen,
    onCloseModal: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('closeModal'))),
    onClickCreate: handleCreateOrUpdate,
    dialogClass: "xs-modal"
  }, {
    header: _withCtx(() => [
      (_ctx.onEdit)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, "Edit design"))
        : (_openBlock(), _createElementBlock("p", _hoisted_2, "Create new design"))
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[7] || (_cache[7] = _createElementVNode("label", null, "Nickname*", -1)),
          _withDirectives(_createElementVNode("input", {
            class: "w-full",
            type: "text",
            required: "",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((design.value.nickname) = $event))
          }, null, 512), [
            [_vModelText, design.value.nickname]
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[8] || (_cache[8] = _createElementVNode("label", null, "Description", -1)),
          _createVNode(_unref(QuillEditor), {
            content: design.value.description ? design.value.description : '<p><br></p>',
            "content-type": "html",
            "onUpdate:content": _cache[1] || (_cache[1] = content => content == '<p><br></p>' ? design.value.description = null : design.value.description = content),
            onReady: _cache[2] || (_cache[2] = ($event: any) => (onEditorReady($event)))
          }, null, 8, ["content"])
        ]),
        (_ctx.onEdit && statuses.value)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", _hoisted_6, [
                _cache[9] || (_cache[9] = _createElementVNode("label", null, "Status", -1)),
                _withDirectives(_createElementVNode("select", {
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((design.value.status) = $event)),
                  class: "w-full"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(statuses.value, (status) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: status.id,
                      value: status.id
                    }, _toDisplayString(status.name), 9, _hoisted_7))
                  }), 128))
                ], 512), [
                  [_vModelSelect, design.value.status]
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _cache[10] || (_cache[10] = _createElementVNode("label", null, "Status Reason", -1)),
                _createVNode(_unref(QuillEditor), {
                  content: design.value.statusReason ? design.value.statusReason : '<p><br></p>',
                  "content-type": "html",
                  "onUpdate:content": _cache[4] || (_cache[4] = content => content == '<p><br></p>' ? design.value.statusReason = null : design.value.statusReason = content),
                  onReady: _cache[5] || (_cache[5] = ($event: any) => (onEditorReady($event)))
                }, null, 8, ["content"])
              ])
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _createVNode(ProgramVersionDesignLayersForm, {
        "on-edit": _ctx.onEdit,
        "design-id": _ctx.designId
      }, null, 8, ["on-edit", "design-id"]),
      _createElementVNode("div", _hoisted_9, [
        (errorsFromMutation.value)
          ? (_openBlock(), _createBlock(FormErrorToast, {
              key: 0,
              "is-showing": showMutationErrors.value,
              title: errorsFromMutation.value,
              toastType: "warning"
            }, null, 8, ["is-showing", "title"]))
          : _createCommentVNode("", true),
        _createVNode(FormErrorToast, {
          "is-showing": formSubmited.value && !formIsValid.value,
          title: "There are some mandatory fieds you need to complete to proceed"
        }, null, 8, ["is-showing"])
      ])
    ]),
    _: 1
  }, 8, ["create-text", "show"]))
}
}

})