import gql from "graphql-tag";

export const PROGRAM_VERSION_TARGETS_FORM = gql`
  query getPrgramVersionTargetsValues {
    targetCategoryTypes {
        edges {
            node {
                id
                name
            }
        }
    }
    properties {
        edges {
            node {
                category
                className
                id
                name
            }
        }
    }
    unitTypes(orderBy: "abbreviation", filter: {not: {family: {iexact: "Boolean"}}}) {
    edges {
      node {
        id
        name
        abbreviation
      }
    }
  }
  valueTypes {
    edges {
      node {
        id
        name
        symbol
        description
      }
    }
  }
  }

`;
