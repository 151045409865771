import gql from 'graphql-tag';

export const ASSET_PROCESSING_TECHNIQUES_FORM_VALUES = gql`
    query getProcessingTechniqueFormValues {
		unitTypes(orderBy: "abbreviation", filter: {not: {family: {iexact: "Boolean"}}}) {
			edges {
				node {
					id
					abbreviation
					name
					}
				}
      	}
		valueTypes {
			edges {
				node {
					id
					name
					symbol
					description
				}
			}
		}
		processingTechniqueProperties(orderBy: "name") {
			edges {
				node {
					id
					name
				}
			}
		}
	}`
