import { defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelRadio as _vModelRadio, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "flex justify-between"
}
const _hoisted_2 = { class: "flex flex-col items-center mr-3" }
const _hoisted_3 = { class: "text-xs font-light" }
const _hoisted_4 = { class: "flex flex-col items-center ml-3" }
const _hoisted_5 = { class: "text-xs font-light" }
const _hoisted_6 = {
  key: 2,
  class: "flex flex-col items-center"
}
const _hoisted_7 = { class: "text-xs font-light" }
const _hoisted_8 = { key: 3 }
const _hoisted_9 = {
  key: 4,
  class: "flex absolute z-10 top-1 right-1"
}

import {computed, ref} from 'vue';
import { BenchamarkValue } from '@/models/benchmarkModels';


export default /*@__PURE__*/_defineComponent({
  __name: 'BenchmarkTableCel',
  props: {
    onEdit: { type: Boolean },
    celValue: {}
  },
  emits: ['colorChange', 'valueChange'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const celColour = ref(props.celValue.colour);
const celValueToChange = ref(props.celValue.value);

const bgColour = computed(() => celColour.value ?? '#fff');

const handleColorSelection = () => emit('colorChange', celColour.value);
const handleValueChange = () => emit('valueChange', celValueToChange.value);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "relative flex justify-center items-center h-14 p-2 border-r",
    style: _normalizeStyle({backgroundColor: bgColour.value })
  }, [
    (_ctx.celValue.criteria.category == 'Commercial Value' && _ctx.onEdit)
      ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 0,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((celValueToChange).value = $event)),
          class: "h-full w-full text-center z-10 bg-transparent",
          onChange: handleValueChange
        }, null, 544)), [
          [_vModelText, celValueToChange.value]
        ])
      : (_ctx.celValue.value && _ctx.celValue.value2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", null, _toDisplayString(_ctx.celValue.value), 1),
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.celValue.conditions), 1)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", null, _toDisplayString(_ctx.celValue.value2), 1),
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.celValue.conditions2), 1)
            ])
          ]))
        : (_ctx.celValue.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("p", null, _toDisplayString(_ctx.celValue.value), 1),
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.celValue.conditions), 1)
            ]))
          : (_openBlock(), _createElementBlock("p", _hoisted_8, "-")),
    (_ctx.onEdit && (_ctx.celValue.value || _ctx.celValue.criteria.category == 'Commercial Value'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _withDirectives(_createElementVNode("input", {
            type: "radio",
            class: "white",
            value: "#ffffff",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((celColour).value = $event)),
            onChange: _cache[2] || (_cache[2] = ($event: any) => (celColour.value = null))
          }, null, 544), [
            [_vModelRadio, celColour.value]
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "radio",
            class: "green",
            value: "#b8d9d6",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((celColour).value = $event)),
            onChange: handleColorSelection
          }, null, 544), [
            [_vModelRadio, celColour.value]
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "radio",
            class: "yellow",
            value: "#fbeabd",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((celColour).value = $event)),
            onChange: handleColorSelection
          }, null, 544), [
            [_vModelRadio, celColour.value]
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "radio",
            class: "red",
            value: "#D585B4",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((celColour).value = $event)),
            onChange: handleColorSelection
          }, null, 544), [
            [_vModelRadio, celColour.value]
          ])
        ]))
      : _createCommentVNode("", true)
  ], 4))
}
}

})