<template>
    <div class="title-menu-header">
        <h1 class="view-title">PROGRAMMES versions</h1>
        <div class="action-icons">
            <div class="round-action-icon" @click="openNewForm = !openNewForm">
                <PlusIcon class="icon icon-small" />
            </div>
            <div class="round-action-icon" @click="$router.go(-1)">
                <ArrowUturnLeftIcon class="icon icon-small" />
            </div>
        </div>
    </div>
    <ProgramVersionsForm :is-open="openNewForm" @close-new="openNewForm = false"/>
</template>
  
<script setup lang="ts">
import { ArrowUturnLeftIcon, PlusIcon } from '@heroicons/vue/24/outline';
import ProgramVersionsForm from '@/components/ProgramVersionsForm.vue';
import { ref } from 'vue';

const openNewForm = ref(false);
</script>
