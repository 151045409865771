<template>
    <div  class="accordion-card">
      <div @click="toggleAccordion">
        <slot name="title"></slot>
      </div>
      
        <div v-show="isOpen" class="accordion-card-content">
          <slot name="content"></slot>
      </div>
    </div>
  </template>
  
<script setup lang="ts">
    import { ref, defineProps } from 'vue';
    const props = defineProps({startOpen: Boolean})
    const isOpen =  ref(props.startOpen);
    const toggleAccordion = () => isOpen.value = !isOpen.value;
</script>
<style lang="scss" scoped>
.accordion-card {
  // padding: 1.5rem 1rem;
  margin-bottom: 1rem;
  border: $border;
  border-radius: 4px;
  .accordion-card-content {
    font-size: 0.9rem;
    font-weight: 300;
    padding: 2rem 1.5rem;
    border-top: $border;
    position: relative;
  }
}
</style>