import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-span-5"
}
const _hoisted_2 = { class: "team-member-container" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = {
  key: 0,
  class: "flex trash"
}
const _hoisted_5 = { class: "flex flex-col" }
const _hoisted_6 = { class: "name" }
const _hoisted_7 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_8 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_9 = { class: "col-span-2" }
const _hoisted_10 = { class: "col-span-2" }
const _hoisted_11 = { class: "col-sapn-1" }
const _hoisted_12 = { class: "col-span-2" }
const _hoisted_13 = { class: "col-span-2" }
const _hoisted_14 = { class: "col-sapn-1" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = {
  key: 2,
  class: "col-span-5"
}
const _hoisted_17 = { class: "disclaimer-team items-center flex" }

import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { XCircleIcon, CheckIcon, InformationCircleIcon, PencilSquareIcon, ArrowUturnLeftIcon, TrashIcon } from '@heroicons/vue/24/outline'
import { TeamMemberInterface } from '@/models/programVersionsInterface';
import DropdownSearchableSingleVue from '@/components/DropdownSearchableSingle.vue'
import { ref, computed } from 'vue';
import { useStore } from 'vuex';



export default /*@__PURE__*/_defineComponent({
  __name: 'TeamMembersForm',
  props: {
    teamRoleTypesOptions: {},
    employeesOptions: {},
    onEdit: { type: Boolean }
  },
  setup(__props: any) {

const props = __props

const store = useStore();

const newMember = ref({
    teamRoleType: null,
    user: null
})

const programVersionTeam = computed<TeamMemberInterface[]>(() => props.onEdit ? store.state.programVersionDetailModule['programVersionTeamForEdition'] : store.state.programsCreationModule['programVersion']['programVersionTeam']);
const teamToDisplay = computed(() => { 
    const members = [];
    if (programVersionTeam.value.length) {
        programVersionTeam.value.map(teamMember => {
            const displayMember = {
                id: teamMember.id ? teamMember.id : null,
                role: props.teamRoleTypesOptions.find(teamRole => teamRole.id == teamMember.teamRoleType).name,
                personName: props.employeesOptions.find(employee => employee.id == teamMember.user).name,
            }
            members.push(displayMember);
        })
    }
    return members;
})
const teamMemberIndexToEdit = ref(null);
const teamMembersToDelete = computed<number[]>(() => store.state.programVersionDetailModule.teamMembersToDelete );

const setTeamMemberToEdit = (id: number) => {
    const teamMemberToEdit = programVersionTeam.value.find(member => member.id == id);
    teamMemberIndexToEdit.value = programVersionTeam.value.indexOf(teamMemberToEdit)
}

const addMemberToDelete = (id: number) => {
    teamMembersToDelete.value.push(id);
    teamMemberIndexToEdit.value = null;
};

const removeMemberToDelete = (id: number) => teamMembersToDelete.value.splice(teamMembersToDelete.value.indexOf(id), 1)

const isTeamMemberComplete = computed(() => {
    return newMember.value.teamRoleType !== null && newMember.value.user !== null;
});

const isTeamMemberDisabled = computed(() => {
    return !newMember.value.teamRoleType || !newMember.value.user;
});

const updateTeamMember = (memberId: number) => {
    const displayedTeamMember = teamToDisplay.value.find(member => member.id == memberId);
    const indexToModify =  teamToDisplay.value.indexOf(displayedTeamMember);
    teamToDisplay.value[indexToModify].role = props.teamRoleTypesOptions.find(teamRole => teamRole.id == programVersionTeam.value[teamMemberIndexToEdit.value].teamRoleType).name;
    teamToDisplay.value[indexToModify].personName = props.employeesOptions.find(employee => employee.id == programVersionTeam.value[teamMemberIndexToEdit.value].user).name;
    teamMemberIndexToEdit.value = null;
}

const addMemberToTheTeam = () => {
    if (newMember.value.teamRoleType && newMember.value.user) {
        programVersionTeam.value.push({
            user: newMember.value.user,
            teamRoleType: newMember.value.teamRoleType
        })
        newMember.value.teamRoleType = null;
        newMember.value.user = null;
    }
}

const removeTeamMember = (index: number) => {
    programVersionTeam.value.splice(index, 1);
}




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (teamToDisplay.value.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(teamToDisplay.value, (member, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass(["team-member-card shadow-md", {'to-delete': _ctx.onEdit && teamMembersToDelete.value.includes(member.id)}])
              }, [
                _createElementVNode("div", _hoisted_3, [
                  (_ctx.onEdit && teamMembersToDelete.value.includes(member.id))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(_unref(TrashIcon), { class: "icon icon-small" })
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("p", _hoisted_6, _toDisplayString(member.personName), 1),
                    _createElementVNode("p", null, _toDisplayString(member.role), 1)
                  ])
                ]),
                (_ctx.onEdit && teamMembersToDelete.value.includes(member.id))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_unref(ArrowUturnLeftIcon), {
                        class: "icon icon-small ml-3",
                        onClick: ($event: any) => (removeMemberToDelete(member.id))
                      }, null, 8, ["onClick"])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      (_ctx.onEdit && member.id)
                        ? (_openBlock(), _createBlock(_unref(PencilSquareIcon), {
                            key: 0,
                            class: "icon icon-small mr-4",
                            onClick: ($event: any) => (setTeamMemberToEdit(member.id))
                          }, null, 8, ["onClick"]))
                        : _createCommentVNode("", true),
                      (_ctx.onEdit && member.id)
                        ? (_openBlock(), _createBlock(_unref(XCircleIcon), {
                            key: 1,
                            class: "icon icon-small",
                            onClick: ($event: any) => (addMemberToDelete(member.id))
                          }, null, 8, ["onClick"]))
                        : (_openBlock(), _createBlock(_unref(XCircleIcon), {
                            key: 2,
                            class: "icon icon-small",
                            onClick: ($event: any) => (removeTeamMember(index))
                          }, null, 8, ["onClick"]))
                    ]))
              ], 2))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.onEdit && teamMemberIndexToEdit.value !=  null)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_9, [
            _cache[5] || (_cache[5] = _createElementVNode("label", null, "Role ", -1)),
            _createVNode(DropdownSearchableSingleVue, {
              modelValue: programVersionTeam.value[teamMemberIndexToEdit.value].teamRoleType,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((programVersionTeam.value[teamMemberIndexToEdit.value].teamRoleType) = $event)),
              "options-list": _ctx.teamRoleTypesOptions
            }, null, 8, ["modelValue", "options-list"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[6] || (_cache[6] = _createElementVNode("label", null, "Member", -1)),
            _createVNode(DropdownSearchableSingleVue, {
              modelValue: programVersionTeam.value[teamMemberIndexToEdit.value].user,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((programVersionTeam.value[teamMemberIndexToEdit.value].user) = $event)),
              "options-list": _ctx.employeesOptions
            }, null, 8, ["modelValue", "options-list"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("button", {
              class: "black-button-with-icon",
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (updateTeamMember(programVersionTeam.value[teamMemberIndexToEdit.value].id)), ["prevent"]))
            }, [
              _createVNode(_unref(CheckIcon), { class: "icon icon-x-small light thick mr-2" }),
              _cache[7] || (_cache[7] = _createTextVNode(" Save this Changes "))
            ])
          ])
        ], 64))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_12, [
      _cache[8] || (_cache[8] = _createElementVNode("label", null, "Role ", -1)),
      _createVNode(DropdownSearchableSingleVue, {
        modelValue: newMember.value.teamRoleType,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((newMember.value.teamRoleType) = $event)),
        "options-list": _ctx.teamRoleTypesOptions
      }, null, 8, ["modelValue", "options-list"])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _cache[9] || (_cache[9] = _createElementVNode("label", null, "Member", -1)),
      _createVNode(DropdownSearchableSingleVue, {
        modelValue: newMember.value.user,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((newMember.value.user) = $event)),
        "options-list": _ctx.employeesOptions
      }, null, 8, ["modelValue", "options-list"])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("button", {
        class: "black-button-with-icon",
        onClick: _withModifiers(addMemberToTheTeam, ["prevent"]),
        disabled: isTeamMemberDisabled.value
      }, [
        _createVNode(_unref(CheckIcon), { class: "icon icon-x-small light thick mr-2" }),
        _cache[10] || (_cache[10] = _createTextVNode(" Add to the team "))
      ], 8, _hoisted_15)
    ]),
    (isTeamMemberComplete.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_unref(InformationCircleIcon), { class: "icon icon-small thick mr-2" }),
            _cache[11] || (_cache[11] = _createElementVNode("p", null, " Click the button to add this member to the team", -1))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})