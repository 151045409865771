
<template>
    <div class="flex justify-between">
        <div class="showing-columns">
            <div class="header">
                <p>Shown</p><p @click="handleHideAll">Hide All</p>
            </div>   
            <ul>
                <li v-for="shownCol in showingColumns" :key="shownCol.label">
                    {{shownCol.label}} 
                    <EyeSlashIcon class="icon icon-small" @click="handleShowHideColum(shownCol)"/>
                </li>
            </ul>
        </div>
        <div class="hiding-columns">
            <div class="header">
                <p>Hidden</p><p @click="handleShowAll">Show All</p>
            </div>   
            <ul>
                <li v-for="hiddenCol in hidingColumns" :key="hiddenCol.label">
                    {{hiddenCol.label}} 
                    <EyeIcon class="icon icon-small" @click="handleShowHideColum(hiddenCol)" />
                </li>
            </ul>
        </div>
    </div>
</template>
    
<script setup lang="ts">
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/outline'
import { computed } from 'vue';
import { ColumnAssetInterface } from '@/models/technologyAssetsInterface';
import { useStore } from 'vuex';

const store = useStore();
const columns = computed<ColumnAssetInterface[]>(() => store.getters['assetsModule/getAssetsTableColumns']);

const showingColumns = computed(() => columns.value.filter((col:ColumnAssetInterface) => !col.hidden));
const hidingColumns = computed(() => columns.value.filter((col:ColumnAssetInterface) => col.hidden));

const handleShowHideColum = (col:ColumnAssetInterface) => { store.commit('assetsModule/showHideColumn', {columnValue: col.label}) };
const handleShowAll = () => store.commit('assetsModule/showAllColumns');
const handleHideAll = () => store.commit('assetsModule/hideAllColumns');

</script>
<style lang="scss" scoped>
.showing-columns, .hiding-columns {
    padding: 0 2rem 2rem;
    width: 50%;
    .header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
        text-transform: uppercase;
        color: $font-color-light;
        font-size: 0.8rem;
        p:last-child {
            cursor: pointer;
        }
    }
    ul {
        display: flex;
        flex-direction: column;
        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: .1rem 1.6rem 0 .9rem;
            text-transform: uppercase;
            font-size: 0.8rem;
        }
    }
}
</style>