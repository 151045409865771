import { ApolloClient, createHttpLink } from "@apollo/client/core";
import router from "@/router";
import { ApolloLink, fromPromise, InMemoryCache, Observable } from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import refreshTokenIfNeeded from "@/tea/composables/apollo/refreshTokenIfNeeded";

// Cache implementation
export const cache = new InMemoryCache();

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_TEA_ROOT_API,
});

// Set authorization token from local storage
const authLink = new ApolloLink((operation, forward) => {
  if (operation && operation.operationName) {
    if (operation.operationName.toLowerCase() != "refreshtoken") {
      // Retrieve the authorization token from local storage.
      const token = localStorage.getItem("auth_token");

      // Use the setContext method to set the HTTP headers.
      operation.setContext({
        headers: {
          authorization: token ? `jwt ${token}` : "",
        },
      });
    }
  }
  // Call the next link in the middleware chain.
  return forward(operation);
});

// handle errors
const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  const isTokenExpired =
    graphQLErrors &&
    graphQLErrors.some((error) => {
      const { message } = error;
      return message.toLowerCase().includes("signature");
    });

  if (isTokenExpired && operation.operationName.toLowerCase() !== "refreshtoken") {
    console.log("Token expired, attempting refresh...");

    const refreshObservable = fromPromise(refreshTokenIfNeeded());

    return refreshObservable.flatMap((refreshResult) => {
      if (refreshResult) {
        console.log("Refresh token successful. Retrying operation.");
        // Retry the original operation by forwarding it
        return forward(operation);
      } else {
        console.log("Refresh token failed. Returning to home page.");
        // Replace this with your logic to redirect to the home page
        router.push("/");
        return Observable.of(null); // This is a dummy return value
      }
    });
  }
});

// Create the apollo client
const apolloClient = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache,
});
export default apolloClient;
