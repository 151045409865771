<template>
  <div>
    <div class="title-menu-header mb-0">
      <h1 class="view-title">{{ title }}</h1>
      <div class="action-icons">
        <div v-if="!editModeOn" class="round-action-icon" @click="handleBack">
          <ArrowUturnLeftIcon class="icon icon-small" />
        </div>
        <div v-if="!editModeOn" class="round-action-icon" @click="handleEdit">
          <PencilIcon class="icon icon-small" />
        </div>
        
        <RunHeaderActions v-if="!editModeOn" @on-created="$emit('onRunCreated')" @on-search-text="handleOnRunSearchText" :runGroupId="runGroupId"/>
        
        <div v-else class="flex items-center">
          <div v-if="isDeletable" class="round-action-icon danger tooltip mr-2" @click="deleteRunGroup">
            <span class="tooltiptext danger">Remove asset from repository</span>
            <TrashIcon class="icon icon-small" />
          </div>
          <button class="header-action-button action" @click="saveChanges"><CheckIcon class="icon icon-small" />Save changes</button>
          <button class="header-action-button cancel" @click="cancelChanges"><XMarkIcon class="icon icon-small" />Cancel</button>
        </div>
      </div>
    </div>
    <!-- <div v-if="editModeOn" class="name-editable">
      <label>Name</label>
      <input class="w-full" type="text" :value="title" @input="handleNewValue($event, 'name')" />
    </div> -->
    <AvatarsActivity :orientation="'left'" :users-activity="usersActivity" style="margin-bottom: 10px;" />
    <FormErrorToast :is-showing="queryError ? true : false" title="Error" :subtitle="queryError" />
  </div>
</template>
<script setup lang="ts">
import router from "@/router";
import { ArrowUturnLeftIcon, PencilIcon, TrashIcon, CheckIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { computed, defineProps,defineEmits } from "vue";
import { useStore } from "vuex";
import AvatarsActivity from "@/components/AvatarsActivity.vue";
import FormErrorToast from "@/components/FormErrorToast.vue";
import RunHeaderActions from "@/tea/components/runs/RunHeaderActions.vue";

defineProps(["title", "usersActivity","runGroupId"]);
const emit = defineEmits(["onRunCreated", "onRunSearchText"]);

const store = useStore();
const update = computed(()=>store.getters["runGroupModule/update"]);
const queryError = computed(() => update.value.error);
const editModeOn = computed(() => update.value.editable);
const isDeletable = computed(()=> update.value.deletable);
const handleNewValue = (event: Event, mutationValue: string) => {
  const content = (event.target as HTMLInputElement).value;
  store.commit("runGroupModule/SET_UPDATE_SINGLE_RUN_GROUP_VALUE", { mutationValue: mutationValue, content: content });
};
const handleBack = () => {
  store.commit("runGroupModule/SET_UPDATE_EDITABLE_OFF");
  router.push({ name:"Tea.Dashboard" });
};
const handleEdit = () => store.commit("runGroupModule/SET_UPDATE_EDITABLE_ON");

const deleteRunGroup = () => {
  store.dispatch("runGroupModule/deleteRunGroup",update.value.runGroup.id).then(()=>{
    handleBack();
  });
}

const saveChanges = async () => await store.dispatch("runGroupModule/runGroupUpdate");
const cancelChanges = () => store.commit("runGroupModule/SET_UPDATE_EDITABLE_OFF");

const handleOnRunSearchText=(value)=> emit('onRunSearchText',value);
</script>
