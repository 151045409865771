<template>
    <div class="dropzone-container" @dragover="dragover" @dragleave="dragleave" @drop="drop">
        <input type="file" multiple name="uploadFiles" class="hidden-input" id="uploadFiles" @change="getFiles"  :accept="acceptExtensions ?? '.pdf,.txt,.csv,.doc,.docx,.ppt,.pptx,.xsl,.xlsx,.png,.jpg,.jpeg,.gif,.bmp,.webp'"/>

    
        <label class="file-label" for="uploadFiles">
            <div class="input-text">              
                <CloudArrowUpIcon class="icon icon-small icon-upload"/>
                <div v-if="isDragging">Release to drop files here.</div>
                <div v-else>
                    <p>Drop files here or <u>click here</u> to upload.</p> 
                    <span>{{acceptText ?? "PDF, TXT, CSV, DOC, PPT, XLS, PNG, JPG or GIF"}}</span>
                </div> 
            </div>
        </label>
    </div> 
    <div v-if="filesToUpload.length" class="mt-4">
        <label v-if="labelText">{{ labelText }}</label>
        <label v-else>Selected Files</label>
        <div class="file-list-container">
            <div v-for="(file, index) in filesToUpload" :key="index" class="upload-file-preview">
                <div class="card-content">
                    <DocumentPlusIcon class="icon thick icon-file add" />
                    <div>
                        <p class="break-all">{{ file.name }}</p>
                        <p class="file-size">{{ Math.round(file.size / 1000) + "kb" }}</p>
                    </div>
                </div>
                <XMarkIcon class="icon icon-small shrink-0" @click="removeFile(index)"/>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, defineEmits, defineProps, computed, watch } from 'vue';
import { CloudArrowUpIcon, XMarkIcon, DocumentPlusIcon } from '@heroicons/vue/24/outline';

const props = defineProps(['labelText', 'preselectedFiles','acceptExtensions','acceptText'])
const emit = defineEmits(['filesToUpload']);

const isDragging = ref(false);

const savedFiles = computed(()=> props.preselectedFiles ? [...props.preselectedFiles] : []);

const filesToUpload = ref(savedFiles.value);

const getFiles = (e) => {
    filesToUpload.value = !filesToUpload.value.length ? [...e.target.files] : [...filesToUpload.value, ...e.target.files];
    emit('filesToUpload', filesToUpload.value)
};
const dragover = (e:Event) => {
    e.preventDefault();
    isDragging.value = true;
}
const dragleave = () => isDragging.value = false;
const drop = (e) => {
    e.preventDefault();
    filesToUpload.value = !filesToUpload.value.length ? [...e.dataTransfer.files] : [...filesToUpload.value, ...e.dataTransfer.files];
    isDragging.value = false;
    emit('filesToUpload', filesToUpload.value)
}
const removeFile = (i: number) => {
    filesToUpload.value.splice(i, 1);
    emit('filesToUpload', filesToUpload.value)
}

watch(savedFiles, (newSavedFiles) => {
  filesToUpload.value = newSavedFiles.length > 0 ? newSavedFiles : [];
})

</script>
<style lang="scss" scoped>
.dropzone-container {
    border: 1px dashed $grey-medium;
    padding: 1.2rem;
    border-radius: 4px;
    background-color: white;
}
.input-text {
    display: flex;
    align-items: center;
    .icon-upload {
        background: #f7f7f7;
        border-radius: 1px;
        outline: 9px solid #f7f7f7;
        margin-right: 1.5rem;
        stroke: #999;
        width: 2rem;
        height: 2rem;
        stroke-width: 1;
        flex-shrink: 0;
    }
}

.hidden-input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
}

.file-label {
    cursor: pointer;
}

.file-list-container {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
}

</style>