import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "forms-container" }

import { PlusIcon } from "@heroicons/vue/24/outline";
import { ref, computed,onMounted } from "vue";
import { useStore } from "vuex";
import ModalSlots from "@/components/ModalSlots.vue";
import RunAddNewForm from "./RunAddNewForm.vue";
import { NEW_RUN_FIELDS } from "@/tea/models/run";


export default /*@__PURE__*/_defineComponent({
  __name: 'RunAddNew',
  emits: ["onCreated"],
  setup(__props, { emit: __emit }) {

const createText = ref("Save Run")
const createInProgress = ref(false)


onMounted(()=>{
  createInProgress.value=false
  createText.value="Save Run";
});

const store = useStore();
const isOpen = ref(false);
const hideFooter = computed(()=>!store.getters["runCreateModule/canSubmit"]);
const collapsed = computed(() => store.getters["authModule/isSidebarCollapsed"]);
const toggleSidebar = () => store.commit("authModule/toogleSidebar");
const closeModal = () => {
  isOpen.value = false;
  // formSubmited.value = false;
};

const openModal = () => {
  if (!collapsed.value) {
    toggleSidebar();
  }
  isOpen.value = true;
};

const resetModal=async ()=>{
  store.commit('runCreateModule/SET_IS_FORM_SUBMITTED',false);
  store.commit("runCreateModule/RESET_CREATE");
}

const emit = __emit;

// const queryError = computed(() => store.getters["runCreateModule/fields"]);
const canSubmit = computed(()=> store.getters["runCreateModule/canSubmit"])
const createNewRun =async () => {
  if (canSubmit.value) {
    createText.value = "Saving Run . . .";
    createInProgress.value=true;
    const mutation = NEW_RUN_FIELDS.createMutation;
    await store
      .dispatch(mutation)
      .then(() => {
        closeModal();
        resetModal();
        emit("onCreated");
        createInProgress.value=false
        createText.value="Save Run";
      })
      .catch((error) => {
        createText.value = "Save Run";
        createInProgress.value=false;
        console.log(error);
      });
  }
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["round-action-icon", { active: isOpen.value }])
    }, [
      _createVNode(_unref(PlusIcon), {
        class: "icon icon-small",
        onClick: openModal
      })
    ], 2),
    _createVNode(ModalSlots, {
      show: isOpen.value,
      onCloseModal: closeModal,
      onResetModal: resetModal,
      onClickCreate: createNewRun,
      dialogClass: "mx-auto max-w-[80%] min-w-[80%]",
      hideFooter: hideFooter.value,
      "create-text": createText.value,
      "create-in-progress": createInProgress.value
    }, {
      header: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("p", null, "NEW TEA", -1)
      ])),
      body: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[1] || (_cache[1] = _createElementVNode("p", null, [
            _createElementVNode("br"),
            _createTextVNode(" Under no circumstances can Karen replace a detailed analysis of each technology, but it is rather a comparative tool helping in the selection process of biotechnology projects ")
          ], -1)),
          _createVNode(RunAddNewForm)
        ])
      ]),
      _: 1
    }, 8, ["show", "hideFooter", "create-text", "create-in-progress"])
  ]))
}
}

})