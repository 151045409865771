<template>
    <!-- TITLE AND ADD FUNCTION -->
    <div class="flex justify-between items-center mt-3">
        <p class="uppercase font-semibold text-gray-700">
            Technology Assets Repository
            <span class="text-sm normal-case font-normal">(Select some assets to start the process)</span>
        </p>
        <div class="flex">
            <Popover class="relative mr-4">
                <PopoverButton class="flex items-center">
                    <div class="flex items-center rounded-full px-4 py-2 bg-moss shadow-md cursor-pointer">
                        <LayerIcon class="icon icon-small white mr-2" />
                        <p class="text-white text-xs uppercase">Add layer</p>
                    </div>
                </PopoverButton>
                <PopoverPanel class="absolute z-10 bg-moss rounded top-12 right-0 min-w-72 text-white">
                    <div class="grid grid-cols-1 divide-y">
                        <div>
                            <p class="uppercase py-2 font-medium text-xs pl-2 border-b"> substrate</p>
                            <div class="flex items-center justify-between">
                                <p @click="addLayer(1)"
                                    class="w-1/2 py-3 font-normal text-xs text-center cursor-pointer border-r"> Paper
                                </p>
                                <p @click="addLayer(2)"
                                    class="w-1/2 py-3 font-normal text-xs text-center cursor-pointer"> Plastic </p>
                            </div>
                        </div>
                        <div>
                            <p class="uppercase py-2 font-medium text-xs pl-2 border-b border-gray-300"> coating</p>
                            <div class="flex items-center justify-between">
                                <p @click="addLayer(3)"
                                    class="w-1/2 py-3 font-normal text-xs text-center cursor-pointer border-r"> Barrier Technology
                                </p>
                                <p @click="addLayer(4)"
                                    class="w-1/2 py-3 font-normal text-xs text-center cursor-pointer"> Heat Seals </p>
                            </div>
                        </div>
                    </div>
                </PopoverPanel>
            </Popover>
            <div class="flex items-center rounded-full p-2 bg-moss shadow-md cursor-pointer">
                <FunnelIcon @click="showFilters = !showFilters" class="icon thick icon-small white" />
            </div>
        </div>
    </div>

    <!-- ASSETS FUNNEL --> 
    <div v-if="layers.length > 0" class="funnel-container bg-white shadow-md border px-2 py-2 mt-4 rounded-lg w-full">
        <div class="flex items-center justify-center mt-1">
            <template v-for="(layer, index) in layers" :key="index">
                <div :class="`flex items-center w-full rounded-full p-1  m-2 uppercase font-funnel relative ${layer.color}`">
                    <span class="number">{{ layer.total }}</span>
                    <p>{{ layer.name }}</p>
                    <XMarkIcon @click="removeLayer(index)" class="absolute right-3 icon white thick icon-small" />
                </div>
            </template>
        </div>
        <div v-if="secondRow" class="flex items-center justify-center mt-1">
            <template v-for="(layer, index) in secondRow" :key="index">
                <div v-if="layer" :class="`flex items-center w-1/3 rounded-full p-1 m-2 uppercase font-funnel relative ${layer.color}`">
                    <span class="number">{{ layer.total }}</span>
                    <p>{{ layer.name }}</p>
                    <XMarkIcon @click="removeLayer(index)" class="absolute right-3 icon white thick icon-small" />
                </div>
                <div v-else class="w-1/3 m-2"></div>
            </template>
        </div>
        <div v-if="thirdRow" class="flex items-center justify-center mt-1">
            <template v-for="(layer, index) in thirdRow" :key="index">
                <div v-if="layer" :class="`flex items-center w-1/4 rounded-full p-1 m-2 uppercase font-funnel relative ${layer.color}`">
                    <span class="number">{{ layer.total }}</span>
                    <p>{{ layer.name }}</p>
                    <XMarkIcon @click="removeLayer(index)" class="absolute right-3 icon white thick icon-small" />
                </div>
                <div v-else class="w-1/4 m-2"></div>
            </template>
        </div>
        <div v-if="fourthRow" class="flex items-center justify-center mt-1">
            <template v-for="(layer, index) in fourthRow" :key="index">
                <div v-if="layer" :class="`flex items-center w-1/5 rounded-full p-1 m-2 uppercase font-funnel relative ${layer.color}`">
                    <span class="number">{{ layer.total }}</span>
                    <p>{{ layer.name }}</p>
                    <XMarkIcon @click="removeLayer(index)" class="absolute right-3 icon white thick icon-small" />
                </div>
                <div v-else class="w-1/5 m-2"></div>
            </template>
        </div>
        <div class="flex items-center justify-center">
            <div class="flex items-center rounded-full text-white bg-moss p-1 w-1/4 m-2 uppercase font-funnel">
                <span class="number final font-bold mr-3">{{ finalCombinations }}</span>
                <p>final combinations</p>
            </div>
        </div>
    </div>
    <!-- FUNNEL FILTERS -->
    <FunnelFilters v-if="showFilters" :layers="layers" @filter-layer="handleFilter"/>
    
    <!-- PERFORMANCE CARDS -->
    <div class="transition-opacity duration-1000 delay-300" :class="[layers.length > 0 ? 'opacity-100' : 'opacity-0']">
        <PerformanceCards  :brief-data="briefData" />
    </div>
</template>
<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import LayerIcon from '@/components/icons/LayerIcon.vue';
import { computed, ref, defineProps } from 'vue';
import { FUNNEL_LAYERS } from './briefsData';
import { FunnelIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import FunnelFilters from './FunnelFilters.vue';
import PerformanceCards from './PerformanceCards.vue';
import { LayerInterface } from './playgroundModels';

defineProps(['briefData']);

const funnelLayers = FUNNEL_LAYERS;
const layers = ref<LayerInterface[]>([]);
const showFilters = ref(false);

const secondRow = ref(null);
const thirdRow = ref(null);
const fourthRow = ref(null);

const handleFilter = (filterLayer: {layerIndex: number, newTotal: number, rowNumber: number}) => {
    if (filterLayer.rowNumber == 2) {
        secondRow.value = secondRow.value ? secondRow.value : Array(layers.value.length);
        layers.value.map(({total, ...rest}, index) => {
            if (index == filterLayer.layerIndex) {
                secondRow.value[filterLayer.layerIndex] = {
                    total: filterLayer.newTotal,
                    ...rest
                }
            }
        })
    }
    if (filterLayer.rowNumber == 3) {
        thirdRow.value = thirdRow.value ? thirdRow.value : Array(layers.value.length);
        layers.value.map(({total, ...rest}, index) => {
            if (index == filterLayer.layerIndex) {
                thirdRow.value[filterLayer.layerIndex] = {
                    total: filterLayer.newTotal,
                    ...rest
                }
            }
        })
    }
    if (filterLayer.rowNumber == 4) {
        fourthRow.value = fourthRow.value ? fourthRow.value : Array(layers.value.length);
        layers.value.map(({total, ...rest}, index) => {
            if (index == filterLayer.layerIndex) {
                fourthRow.value[filterLayer.layerIndex] = {
                    total: filterLayer.newTotal,
                    ...rest
                }
            }
        })
    }
}

const addLayer = (layerId: number) => {
    const selectedLayer = funnelLayers.find(layer => layer.id == layerId);
    layers.value.push(selectedLayer)
}

const removeLayer = (index: number) => layers.value.splice(index, 1)

const finalCombinations = computed(() => {
    if (fourthRow.value) {
        return fourthRow.value.reduce((accumulator, currentValue) => accumulator * currentValue.total,1)
    }
    if (thirdRow.value) {
        return thirdRow.value.reduce((accumulator, currentValue) => accumulator * currentValue.total,1)
    }
    if (secondRow.value) {
        return secondRow.value.reduce((accumulator, currentValue) => accumulator * currentValue.total,1)
    }
    else {
        return layers.value.reduce((accumulator, currentValue) => accumulator * currentValue.total,1) 
    }
})

</script>
<style lang="scss" scoped>
.number {
    background: rgb(255 255 255 / 25%);
    padding: 0.5rem;
    border-radius: 50%;
    width: 33px;
    text-align: center;
    height: 33px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;

    &.final {
        min-width: 33px;
        width: auto;
    }

}
</style>