import gql from "graphql-tag";


export const RUN_DOUBLE_SENSITIVITY_CALCULATE_MUTATION = gql`
  mutation runDoubleSensitivityCalculate($input: RunDoubleSensitivityCalculateMutationInput!) {
    runDoubleSensitivityCalculate(input: $input) {
      doubleSensitivity{
          x
          y
          heatMap{x y value}
          note
          message
      }
    }
  }
`;

export const RUN_UPDATE_MUTATION = gql`
  mutation runUpdate($input: RunUpdateMutationInput!) {
    runUpdate(input: $input) {
      run {
        id
      }
    }
  }
`;