import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "not-found" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "not-found-text" }




export default /*@__PURE__*/_defineComponent({
  __name: 'NotFound',
  props: {
    imageName: {},
    text: {},
    subtitle: {},
    cta1: {},
    cta2: {}
  },
  emits: ['ctaMessage'],
  setup(__props: any) {





    

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: require(`@/assets/icons/${_ctx.imageName}`)
    }, null, 8, _hoisted_2),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.text), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.subtitle), 1),
    _createElementVNode("div", {
      class: _normalizeClass(["cta mt-4", {twoButtons: _ctx.cta1 && _ctx.cta2}])
    }, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('ctaMessage', 'cta1')))
      }, _toDisplayString(_ctx.cta1), 1),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('ctaMessage', 'cta2')))
      }, _toDisplayString(_ctx.cta2), 1)
    ], 2)
  ]))
}
}

})