import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "programs-dashboard"
}
const _hoisted_2 = {
  key: 1,
  class: "flex"
}
const _hoisted_3 = {
  key: 0,
  class: "loading-gif"
}
const _hoisted_4 = {
  key: 1,
  class: "empty-box add"
}
const _hoisted_5 = {
  key: 2,
  class: "programs-dashboard"
}

import ProgramDashboardCard from './ProgramDashboardCard.vue';
import { computed, ref } from 'vue';
import { ProgramVersionsInterface } from '@/models/programVersionsInterface';
import { PROGRAM_VERSIONS_QUERY } from '@/grapql/programVersionsQuery';
import { useQuery } from '@vue/apollo-composable';
import { PROGRAMS_INFO } from '@/models/programsInfo';
import ProgramsDashboardSidebarFilter from './ProgramsDashboardSidebarFilter.vue';
import { ProgramSimpleInterface } from '@/models/programsInterface';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramsDashboard',
  props: {
    isMockup: {
        type: Boolean,
        required: true
    }
},
  setup(__props) {



// To be removded after develop is done
const programs = PROGRAMS_INFO;

const { result, loading } = useQuery(PROGRAM_VERSIONS_QUERY);
const queryResult = computed(() => result?.value ?? null);

const programVersions = computed<ProgramVersionsInterface[]>(() => queryResult?.value && queryResult?.value?.programVersions?.edges.length > 0 ? queryResult?.value?.programVersions?.edges.map((program: { node: ProgramVersionsInterface }) => program.node) : []);

const programList = computed<ProgramSimpleInterface[]>(() => queryResult?.value && queryResult?.value?.programs?.edges.length > 0 ? queryResult?.value?.programs?.edges.map((program: { node: ProgramSimpleInterface }) => program.node) : []);

const idsToFilterBy = ref([]);
const handleFilterPrograms = (updatedIds) => {
    idsToFilterBy.value = updatedIds
}

const isFilterClosed = ref(false);

const handleFilterToggle = () => {
    isFilterClosed.value = !isFilterClosed.value;
};

const filteredProgramVersions = computed(() => idsToFilterBy.value.length > 0 ? programVersions.value.filter(programVerion => idsToFilterBy.value.includes(programVerion.program.id)) : programVersions.value);


return (_ctx: any,_cache: any) => {
  return (__props.isMockup)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(programs), (program) => {
          return (_openBlock(), _createBlock(ProgramDashboardCard, {
            key: program.id,
            "program-mockup": program
          }, null, 8, ["program-mockup"]))
        }), 128))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(ProgramsDashboardSidebarFilter, {
          "program-list": programList.value,
          onFilteredProgramsChange: handleFilterPrograms,
          "is-filter-closed": isFilterClosed.value,
          onFilterToggle: handleFilterToggle
        }, null, 8, ["program-list", "is-filter-closed"]),
        _createElementVNode("div", {
          class: _normalizeClass(["programs-dashboard-container", { 'closed': isFilterClosed.value }])
        }, [
          (_unref(loading))
            ? (_openBlock(), _createElementBlock("div", _hoisted_3))
            : (filteredProgramVersions.value.length === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4))
              : (programVersions.value && programVersions.value.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredProgramVersions.value, (program) => {
                      return (_openBlock(), _createBlock(ProgramDashboardCard, {
                        key: program.id,
                        "program-version": program,
                        "program-version-applications": program.applicationTypes
                      }, null, 8, ["program-version", "program-version-applications"]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
        ], 2)
      ]))
}
}

})