<template>
	<ModalSlots :isOpen="isOpen" dialogClass="xs-modal" @close-modal="$emit('closeModal')">
		<template v-slot:header>
			<p>Save as</p>
		</template>
		{{ benchmarkTableName }}
		<template v-slot:body>
			<div class="p-6 pb-8">
				<FormErrorToast v-if="errorsFromMutation" :is-showing="errorsFromMutation" :title="errorsFromMutation" toastType="warning" /> 
				<template v-if="!benchmarkId">
					<p class="font-normal mb-4">Please complete the title field to save the table</p>
					<label>Title *</label>
					<input v-model="benchmarkTableName" class="w-full p-2" />
				</template>
				<template v-else>
					<div> 
						<p class="mb-4">This tables has changes, click on save to make them permanent. You can also chenge the name if you want to.</p>
						<label>Title *</label>
						<input v-model="benchmarkTableName" class="w-full p-2" />
					</div>
				</template>
			</div>
		</template>
		<template v-slot:footer>
			<button @click="saveBenchmarkTable" class="button bg-black text-white" :disabled="saveInProgress"
				:class="{ 'cursor-not-allowed hover:shadow-none opacity-50': saveInProgress }">save</button>
		</template>
	</ModalSlots>

</template>
<script setup lang="ts">
import ModalSlots from "./ModalSlots.vue";
import { ref, defineProps, defineEmits, computed, watch } from 'vue';
import { useStore } from 'vuex';
import router from "@/router";
import FormErrorToast from '@/components/FormErrorToast.vue';
import { StoreBenchmarkData } from "@/models/benchmarkModels";

const props = defineProps<{ isOpen: boolean }>();
const emits = defineEmits(['closeModal']);

const store = useStore();

const benchmarkTableName = ref();
const saveInProgress = ref(false);
const benchmarkId = computed(() => store.state.programVersionDetailModule.benchmarkId)
const errorsFromMutation = computed(() => benchmarkId.value ? store.state.programVersionDetailModule.errorDuringMutation.benchmarkUpdate : store.state.programVersionDetailModule.errorDuringMutation.benchmarkCreate);


const saveBenchmarkTable = async () => {
	if(benchmarkId.value) {
		store.commit('programVersionDetailModule/setBenchmarkTableTitle', { title: benchmarkTableName.value });
		await store.dispatch('programVersionDetailModule/benchmarkTableUpdate')
			.then(() => {
				if (!errorsFromMutation.value) {
					emits('closeModal');
					router.push({ name: 'Benchmark', params: { title: `${benchmarkId.value}-${benchmarkTableName.value.replaceAll(' ', '-')}` } });
				}
			});
	}
	else if (benchmarkTableName.value) {
		store.commit('programVersionDetailModule/setBenchmarkTableTitle', { title: benchmarkTableName.value });
		await store.dispatch('programVersionDetailModule/benchmarkTableCreate')
			.then(() => {
				if (!errorsFromMutation.value) {
					emits('closeModal');
					if (benchmarkId.value) {
						router.push({ name: 'Benchmark', params: { title: `${benchmarkId.value}-${benchmarkTableName.value.replaceAll(' ', '-')}` } });
					}

				}
			});
	}
}

watch(() => props.isOpen, () => {
	if(props.isOpen) {
		const benchmarkData = computed<StoreBenchmarkData>(() => store.getters['programVersionDetailModule/getBenchmarkData']);
		benchmarkTableName.value = benchmarkData.value.benchmarkTitle;
	}
	
})

</script>
<style lang="scss" scoped>
input {
	border: 2px solid $flaming-light;

	&:focus {
		border-radius: 4px;
		border: 2px solid $flaming-light;
	}
}
</style>