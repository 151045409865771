import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "text-red-500 text-center" }
const _hoisted_2 = { class: "grid grid-cols-2 gap-y-3 gap-x-4 mb-2" }
const _hoisted_3 = { class: "col-span-1 sm:col-span-2 md:col-span-1" }
const _hoisted_4 = {
  key: 0,
  class: "text-red-500"
}
const _hoisted_5 = { class: "col-span-1 sm:col-span-2 md:col-span-1" }
const _hoisted_6 = {
  key: 0,
  class: "text-red-500"
}
const _hoisted_7 = { class: "mb-2" }
const _hoisted_8 = {
  key: 0,
  class: "text-red-500"
}
const _hoisted_9 = { class: "mb-2" }
const _hoisted_10 = {
  key: 0,
  class: "text-red-500"
}
const _hoisted_11 = { class: "mb-2" }
const _hoisted_12 = {
  key: 0,
  class: "text-red-500"
}
const _hoisted_13 = {
  key: 1,
  class: "text-red-500"
}
const _hoisted_14 = {
  key: 2,
  class: "text-red-500"
}
const _hoisted_15 = { class: "mb-2" }
const _hoisted_16 = {
  key: 0,
  class: "text-red-500"
}
const _hoisted_17 = { class: "mb-3" }
const _hoisted_18 = { class: "mb-3" }
const _hoisted_19 = {
  class: "block text-sm font-bold flex items-center",
  for: "terms"
}
const _hoisted_20 = {
  key: 0,
  class: "text-red-500 ml-2"
}
const _hoisted_21 = { class: "flex justify-stretch" }
const _hoisted_22 = ["disabled"]
const _hoisted_23 = { class: "text-right" }

import { computed, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { Checkbox } from "vue-recaptcha";


export default /*@__PURE__*/_defineComponent({
  __name: 'SignUpForm',
  setup(__props) {

// import TermsModal from "../TermsModal.vue";
const { emit } = getCurrentInstance();

const checkboxResponse = ref();
const checkboxVerified = computed(() => !!checkboxResponse.value);

const store = useStore();

const buttonText = ref("Sign Up");
const buttonDisabled = ref(false);

const firstName = ref("");
const lastName = ref("");
const organizationName = ref("");
const email = ref("");
const password = ref("");
const confirmPassword = ref("");
const acceptTerms = ref(false);
const errorMessage = ref("");

const passwordMismatch = computed(() => password.value !== confirmPassword.value && password.value && confirmPassword.value);

const passwordTooShort = computed(() => password.value.length < 6 && password.value);
const passwordTooLong = computed(() => password.value.length > 12 && password.value);

const hasUppercase = computed(() => /[A-Z]/.test(password.value));
const hasLowercase = computed(() => /[a-z]/.test(password.value));
const hasDigit = computed(() => /\d/.test(password.value));
const hasSpecialChar = computed(() => /[!@#$&*]/.test(password.value));

const isPasswordComplex = computed(() => {
  return hasUppercase.value && hasLowercase.value && hasDigit.value && hasSpecialChar.value;
});
const isEmailValid = computed(() => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value));

const isfirstNameValid = computed(() => firstName.value);
const isLastNameValid = computed(() => lastName.value);
const isOrganizationNameValid = computed(() => organizationName.value);

const isFormValid = computed(() => {
  return (
    firstName.value &&
    organizationName.value &&
    email.value &&
    password.value &&
    confirmPassword.value &&
    checkboxVerified.value &&
    !passwordMismatch.value &&
    !passwordTooShort.value &&
    !passwordTooLong.value &&
    isPasswordComplex.value &&
    isEmailValid.value &&
    acceptTerms.value
  );
});

const signUp = async () => {
  try {
    buttonText.value = "Signing Up . . .";
    buttonDisabled.value = true;
    await store
      .dispatch("authModule/signUp", {
        email: email.value,
        password: password.value,
        firstName: firstName.value,
        lastName: lastName.value,
        organizationName: organizationName.value,
      })
      .then(() => {
        emit("showEmail", email.value);
        buttonText.value = "Sign Up";
        buttonDisabled.value = false;
      })
      .catch((error) => {
        errorMessage.value = error;
        buttonText.value = "Sign Up";
        buttonDisabled.value = false;
      });
  } catch (error) {
    errorMessage.value = "An error occurred while registering.";
    buttonText.value = "Sign Up";
    buttonDisabled.value = false;
  }
};

// const showTerms = ref(false);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("form", {
      onSubmit: _withModifiers(signUp, ["prevent"])
    }, [
      _createElementVNode("p", _hoisted_1, _toDisplayString(errorMessage.value), 1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[9] || (_cache[9] = _createElementVNode("label", {
            class: "block text-gray-700 text-sm",
            for: "firstName"
          }, " First Name ", -1)),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((firstName).value = $event)),
            class: _normalizeClass({
              'border rounded w-full py-1 px-3 focus:outline-none focus:shadow-outline': true,
              'border-red-500': !isfirstNameValid.value && firstName.value,
            }),
            id: "firstName",
            type: "text",
            required: ""
          }, null, 2), [
            [_vModelText, firstName.value]
          ]),
          (!isfirstNameValid.value && firstName.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, "Enter a valid first name."))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[10] || (_cache[10] = _createElementVNode("label", {
            class: "block text-gray-700 text-sm",
            for: "lastName"
          }, " Last Name ", -1)),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((lastName).value = $event)),
            class: _normalizeClass({
              'border rounded w-full py-1 px-3 focus:outline-none focus:shadow-outline': true,
              'border-red-500': !isLastNameValid.value && lastName.value,
            }),
            id: "lastName",
            type: "text",
            required: ""
          }, null, 2), [
            [_vModelText, lastName.value]
          ]),
          (!isLastNameValid.value && lastName.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, "Enter a valid last name."))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[11] || (_cache[11] = _createElementVNode("label", {
          class: "block text-gray-700 text-sm",
          for: "organizationName"
        }, " Organization Name ", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((organizationName).value = $event)),
          class: _normalizeClass({
            'border rounded w-full py-1 px-3 focus:outline-none focus:shadow-outline': true,
            'border-red-500': !isOrganizationNameValid.value && organizationName.value,
          }),
          id: "organizationName",
          type: "text",
          required: ""
        }, null, 2), [
          [_vModelText, organizationName.value]
        ]),
        (!isOrganizationNameValid.value && organizationName.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, "Enter a valid organization name."))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _cache[12] || (_cache[12] = _createElementVNode("label", {
          class: "block text-gray-700 text-sm",
          for: "email"
        }, " Email ", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((email).value = $event)),
          class: _normalizeClass({
            'border rounded w-full py-1 px-3 focus:outline-none focus:shadow-outline': true,
            'border-red-500': !isEmailValid.value && email.value,
          }),
          id: "email",
          type: "email",
          required: ""
        }, null, 2), [
          [_vModelText, email.value]
        ]),
        (!isEmailValid.value && email.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, "Enter a valid email address."))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _cache[13] || (_cache[13] = _createElementVNode("label", {
          class: "block text-gray-700 text-sm",
          for: "password"
        }, " Password ", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((password).value = $event)),
          class: _normalizeClass({
            'border rounded w-full py-1 px-3 focus:outline-none focus:shadow-outline': true,
            'border-red-500': passwordTooShort.value || (!isPasswordComplex.value && password.value) || passwordTooLong.value,
          }),
          id: "password",
          type: "password",
          required: ""
        }, null, 2), [
          [_vModelText, password.value]
        ]),
        (passwordTooShort.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, "Password should be at least 6 characters long."))
          : _createCommentVNode("", true),
        (passwordTooLong.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, "Password should not exceed 12 characters."))
          : _createCommentVNode("", true),
        (!isPasswordComplex.value && password.value && !passwordTooShort.value && !passwordTooLong.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, " Password should include at least one uppercase letter, one lowercase letter, one digit, and one of the special characters: !@#$&* "))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_15, [
        _cache[14] || (_cache[14] = _createElementVNode("label", {
          class: "block text-gray-700 text-sm",
          for: "confirmPassword"
        }, " Confirm Password ", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((confirmPassword).value = $event)),
          class: _normalizeClass({
            'border rounded w-full py-1 px-3 focus:outline-none focus:shadow-outline': true,
            'border-red-500': passwordMismatch.value,
          }),
          id: "confirmPassword",
          type: "password",
          required: ""
        }, null, 2), [
          [_vModelText, confirmPassword.value]
        ]),
        (passwordMismatch.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, "Passwords do not match"))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", null, [
          _cache[15] || (_cache[15] = _createElementVNode("small", null, "I'm not a robot", -1)),
          _createVNode(_unref(Checkbox), {
            modelValue: checkboxResponse.value,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((checkboxResponse).value = $event))
          }, null, 8, ["modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("label", _hoisted_19, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((acceptTerms).value = $event)),
            id: "terms",
            class: "mr-1"
          }, null, 512), [
            [_vModelCheckbox, acceptTerms.value]
          ]),
          _cache[16] || (_cache[16] = _createElementVNode("span", { class: "align-middle ml-1 hover:cursor-pointer" }, [
            _createTextVNode("I accept the "),
            _createElementVNode("a", {
              target: "_blank",
              href: "https://www.one-five.com/privacy-policy",
              class: "text-blue-500 hover:underline cursor-pointer"
            }, "Privacy Policy"),
            _createTextVNode(", "),
            _createElementVNode("a", {
              target: "_blank",
              href: "https://www.one-five.com/cookies-policy",
              class: "text-blue-500 hover:underline cursor-pointer"
            }, "Cookies Policy"),
            _createTextVNode(", "),
            _createElementVNode("a", {
              target: "_blank",
              href: "https://www.one-five.com/terms-of-use",
              class: "text-blue-500 hover:underline cursor-pointer"
            }, "Terms of Use")
          ], -1))
        ]),
        (!acceptTerms.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_20, _cache[17] || (_cache[17] = [
              _createElementVNode("small", null, "You must accept the Terms, Conditions and Traceability to proceed.", -1)
            ])))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("button", {
          type: "submit",
          class: _normalizeClass({
            'w-full bg-emerald-400 text-white font-bold py-2 px-4 rounded': true,
            'hover:bg-emerald-600  focus:outline-none focus:shadow-outline': isFormValid.value,
            'cursor-not-allowed opacity-50 ': !isFormValid.value || buttonDisabled.value,
          }),
          disabled: !isFormValid.value || buttonDisabled.value
        }, _toDisplayString(buttonText.value), 11, _hoisted_22)
      ])
    ], 32),
    _cache[19] || (_cache[19] = _createElementVNode("hr", { class: "my-2" }, null, -1)),
    _createElementVNode("div", _hoisted_23, [
      _cache[18] || (_cache[18] = _createTextVNode(" Already registered?   ")),
      _createElementVNode("button", {
        onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (_ctx.$emit('showSignIn')), ["prevent"])),
        class: "text-emerald-400 hover:underline cursor-pointer"
      }, "Sign In Here")
    ])
  ]))
}
}

})