import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full grid grid-cols-3 items-center pt-1 pb-1" }
const _hoisted_2 = { class: "col-span-1" }
const _hoisted_3 = { class: "view-title" }
const _hoisted_4 = { class: "view-subtitle runs-loaded" }
const _hoisted_5 = { class: "col-span-2 text-right" }
const _hoisted_6 = { class: "flex justify-end" }

import { ref, computed, watch } from "vue";
import RunTable from "@/tea/components/runs/RunTable.vue";
import { RUN_TABLE_COLUMS } from "@/tea/models/run";
import { useStore } from "vuex";
import RunPlotting from "@/tea/components/runs/RunPlotting.vue";
import RunSensitivityRepo from "@/tea/components/runs/RunSensitivityRepo.vue";
import RunDoubleSensitivityRepo from "@/tea/components/runs/RunDoubleSensitivityRepo.vue";
import { ChartPieIcon, PresentationChartLineIcon } from "@heroicons/vue/24/outline";

const HEADER_TITLE = "Runs";


export default /*@__PURE__*/_defineComponent({
  __name: 'RunRepo',
  props: ["runGroupId", "searchTerm", "runCreated", "runGroupId"],
  setup(__props) {

const store = useStore();
const props = __props;

store.commit("runModule/SET_RUN_GROUP_ID", props.runGroupId);

const columns = ref(RUN_TABLE_COLUMS);
const repo = computed(() => store.getters["runModule/repo"]);
const runs = computed(() => {
  const loadedRuns = repo.value.runs.map((node) => {
    return { ...node, createdAt: new Date(node.createdAt).toLocaleString() };
  });
  return { loadedRuns, totalCount: repo.value.totalCount, edgeCount: repo.value.edgeCount, loading: repo.value.loading };
});

store.dispatch("runModule/loadRuns");

const hasNextPage = computed(() => {
  if (repo.value.pageInfo) {
    return repo.value.pageInfo.hasNextPage;
  }
  return false;
});

const loadMore = async () => {
  await store.dispatch("runModule/loadMoreRuns");
};

watch(
  () => props.runCreated,
  (newValue, oldValue) => {
    onCreated();
  }
);
const onCreated = async () => {
  loadMore();
};
const onPageChange = () => {
  if (hasNextPage.value) {
    loadMore();
  }
};

const showPlott = ref(false);
const showPlotting = () => {
  if (!showPlottingDisabled.value) {
    showPlott.value = true;
  }
};

const showSensitive = ref(false);
const showSensitivity = () => {
  if (!showSensitivityDisabled.value) {
    showSensitive.value = true;
  }
};

const showDoubleSensitive = ref(false);
const showDoubleSensitivity = () => {
  if (!showDoubleSensitivityDisabled.value) {
    showDoubleSensitive.value = true;
  }
};

const onRowClick = (id) => {
  console.log(id);
  //   router.push({ name: "Tea.RunGroupDetail", params: { id } });
};

const selectedIds = ref([]);
const showPlottingDisabled = computed(() => selectedIds.value.length < 1);
const showSensitivityDisabled = computed(() => {
  const record = repo.value.runs.find((obj) => obj.id == selectedIds.value[0]);
  return selectedIds.value.length != 1 || record.residualBiomass;
});
const showDoubleSensitivityDisabled = computed(() => {
  const record = repo.value.runs.find((obj) => obj.id == selectedIds.value[0]);
  return selectedIds.value.length != 1 || record.residualBiomass;
});
const onSelectedRowsChange = (ids) => {
  showPlott.value = false;
  selectedIds.value = ids;
  store.commit("runSensitivityModule/RESET_CREATE");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, [
          _cache[3] || (_cache[3] = _createTextVNode(" RUNS  ")),
          _createElementVNode("span", _hoisted_4, "  loaded " + _toDisplayString(runs.value.edgeCount) + " of " + _toDisplayString(runs.value.totalCount) + " runs", 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", {
            class: _normalizeClass(["round-action-icon one-five-green", { disabled: showPlottingDisabled.value }]),
            onClick: showPlotting,
            title: "Plotting"
          }, [
            _createVNode(_unref(ChartPieIcon), {
              class: "icon icon-small",
              title: "Plotting"
            })
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["round-action-icon one-five-green", { disabled: showSensitivityDisabled.value }]),
            onClick: showSensitivity,
            title: "Sensitivity Analysis"
          }, [
            _createVNode(_unref(PresentationChartLineIcon), {
              class: "icon icon-small",
              title: "Sensitivity Analysis"
            })
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["round-action-icon one-five-green", { disabled: showDoubleSensitivityDisabled.value }]),
            onClick: showDoubleSensitivity,
            title: "Double Sensitivity Analysis"
          }, [
            _createVNode(_unref(PresentationChartLineIcon), {
              class: "icon icon-small ml-1",
              title: "Double Sensitivity Analysis"
            }),
            _createVNode(_unref(PresentationChartLineIcon), {
              class: "icon icon-small mr-1",
              title: "Double Sensitivity Analysis"
            })
          ], 2)
        ])
      ])
    ]),
    _createVNode(RunTable, {
      columns: columns.value,
      rows: runs.value.loadedRuns,
      onOnPageChange: onPageChange,
      onOnRowClick: onRowClick,
      onOnSelectedRowsChange: onSelectedRowsChange,
      searchTerm: __props.searchTerm,
      loading: runs.value.loading
    }, null, 8, ["columns", "rows", "searchTerm", "loading"]),
    (showPlott.value)
      ? (_openBlock(), _createBlock(RunPlotting, {
          key: 0,
          showPlott: showPlott.value,
          selectedIds: selectedIds.value,
          onClosePlott: _cache[0] || (_cache[0] = ($event: any) => (showPlott.value = false))
        }, null, 8, ["showPlott", "selectedIds"]))
      : _createCommentVNode("", true),
    (showSensitive.value)
      ? (_openBlock(), _createBlock(RunSensitivityRepo, {
          key: 1,
          showSensitive: showSensitive.value,
          selectedId: selectedIds.value[0],
          onCloseSensitive: _cache[1] || (_cache[1] = ($event: any) => (showSensitive.value = false))
        }, null, 8, ["showSensitive", "selectedId"]))
      : _createCommentVNode("", true),
    (showDoubleSensitive.value)
      ? (_openBlock(), _createBlock(RunDoubleSensitivityRepo, {
          key: 2,
          showDoubleSensitive: showDoubleSensitive.value,
          selectedId: selectedIds.value[0],
          onCloseDoubleSensitive: _cache[2] || (_cache[2] = ($event: any) => (showDoubleSensitive.value = false))
        }, null, 8, ["showDoubleSensitive", "selectedId"]))
      : _createCommentVNode("", true)
  ]))
}
}

})