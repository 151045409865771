<template>
    <template v-if="isMockup"> 
        <div class="program-detail-container"> 
            <div class="stages-sidebar">
                <ProgramDetailStageSidebar :active-stage="programDetailMockup.stage" />
            </div>
            <div class="program-detail-section">
                <ProgramDetailOverview :program-detail-info-mockup="programDetailMockup" :is-mockup="isMockup"/>
            </div>
        </div>
    </template>
    <template v-else>
        <div class="page-padding">
            <ProgramDetailOverview v-if="programVersion" :program-version="programVersion" :is-mockup="isMockup"/>
        </div>
    </template>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import ProgramDetailStageSidebar from '@/components/ProgramDetailStagesSidebar.vue';
import ProgramDetailOverview from '@/components/ProgramDetailOverview.vue';
import { useRoute } from 'vue-router';
import { PROGRAMS_INFO } from '@/models/programsInfo';
import { PROGRAM_VERSION_QUERY } from '@/grapql/programVersionQuery';
import { useQuery } from '@vue/apollo-composable';
import store from '@/store';


const route = useRoute()
const programId = route.params.id

const isMockup = computed(() => !route.name.toString().includes('WIP'))

const programDetailMockup = computed(() => programs.find(program => program.id === programId))
const programs = PROGRAMS_INFO;

const programVersion = ref(null)
const { onResult } = useQuery(PROGRAM_VERSION_QUERY, { id: programId });
onResult(queryResult => {
    if(queryResult.data && queryResult.data.programVersion) {
        programVersion.value = queryResult?.data.programVersion;
        store.commit('programVersionDetailModule/setProgramVersionValuesFromBE', {programVersion: programVersion.value})
    }
    
})



</script>
<style lang="scss">
.program-detail-container {
    display: flex;
    justify-content: space-between;
    .program-detail-section {
        width: 78%;
        justify-content: space-around;
        padding-right: 1.5rem;
        padding: 3rem 2rem;
    }
    .stages-sidebar{
        width: 22%;
        padding: 3rem 0rem;
        background-color: #f4f4f4;
        min-height: 100vh;
        border-right: $border;
        position: sticky;
        top: 0;
        max-height: 100vh;
        overflow: scroll;
        
    }
}
    
</style>