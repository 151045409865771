import gql from 'graphql-tag';

export const OTHER_TECHNOLOGY_QUERY = gql`
    query getOtherTechnology($id: ID!) {
        otherTechnology(id:$id) {
            id
            createdAt
            createdBy { firstName lastName }
            updatedAt
            updatedBy { firstName lastName}
            nickname
            title
            abstract
            description
            assetType
            publicationDate
            url
            links
            trl
            notes
            assetSources {edges {node {externalSourceId source {id name}}}}
            applicationTypes {edges {node {id name}}}
            tags
            materials {edges {node {id name acronym}}}
            properties{edges {node {id name category className}}}
            assetFiles {edges {node {id file {name url} deleted}}} 
            processingTechniques{ edges {node {id name}}}
            standards {edges {node {id code title}}}
            assetStatus {id name}
            statusReason
            assetLinkedPrograms {
                edges {
                    node {
                        id
                        program {id name}
                        linkType {id name}
                        linkStatus {id name colorCode}
                        statusReason
                    }
                }
            }
            targetLayers {edges {node {id name}}}
            formTypes {edges {node {id name}}}
            otherTechnologyType {id name}
            previouslyProcured
            owners {edges {node {id nickname partyType}}}
            assetPrice {id price quantity unitType{id name} unitTypeSize notes}
        }
    }`
