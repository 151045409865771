
export function useMultipleSelect(value:(string|number), opertaion:string, acumulator:(string|number)[]) {
    if (opertaion === 'add') {
        if (!acumulator.includes(value)) {
            acumulator.push(value)
        }
    }
    if(opertaion === 'remove') {
        acumulator.indexOf(value) > -1 ? acumulator.splice(acumulator.indexOf(value), 1) : false
    }
    return acumulator
}

export interface SourceInterface {
    source: number,
    externalSourceId: string
}

export function useMultipleSelectForSources(sourceId:number, opertaion:string, acumulator:Array<SourceInterface>,  externalSourceId?: string) {
    
    if (opertaion === 'add' && !externalSourceId) {
        const newSource:SourceInterface = {
            source: sourceId,
            externalSourceId: ""
        };
        acumulator.push(newSource);
    }

    if (opertaion == 'remove') {
        acumulator = acumulator.filter(source => source.source != sourceId);
    }

    return acumulator
    
}