import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, renderList as _renderList, Fragment as _Fragment, vModelRadio as _vModelRadio, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { class: "flex items-center rounded-full p-2 bg-moss shadow-md cursor-pointer" }
const _hoisted_3 = {
  key: 0,
  class: "flex items-start"
}
const _hoisted_4 = { class: "w-3/5 filter-box shadow-md rounded-lg mb-6 bg-acqua mr-6" }
const _hoisted_5 = { class: "filter-title flex justify-between px-4 py-3" }
const _hoisted_6 = { class: "grid grid-cols-1 divide-y" }
const _hoisted_7 = { class: "filter-numbers flex cursor-pointer" }
const _hoisted_8 = {
  key: 0,
  class: "w-1/3 text-center font-normal leading-6"
}
const _hoisted_9 = {
  key: 0,
  class: "w-1/3 text-center font-normal leading-6"
}
const _hoisted_10 = {
  key: 0,
  class: "w-1/3 text-center font-normal leading-6"
}
const _hoisted_11 = {
  key: 0,
  class: "w-1/3 text-center font-normal leading-6"
}
const _hoisted_12 = {
  key: 0,
  class: "filter-options"
}
const _hoisted_13 = { class: "flex" }
const _hoisted_14 = { class: "filter-options w-1/2 flex flex-col px-3 py-3 text-sm border-r border-white" }
const _hoisted_15 = { class: "mt-1 mb-3 flex" }
const _hoisted_16 = ["value"]
const _hoisted_17 = { class: "mb-3 flex" }
const _hoisted_18 = ["value"]
const _hoisted_19 = { class: "mt-1 flex" }
const _hoisted_20 = ["value"]
const _hoisted_21 = { class: "filter-options w-1/2 flex flex-col px-3 py-3 text-sm relative" }
const _hoisted_22 = { class: "mb-3 mt-1 flex" }
const _hoisted_23 = ["value"]
const _hoisted_24 = { class: "mb-3 mt-1 flex" }
const _hoisted_25 = ["value"]
const _hoisted_26 = { class: "mt-1 flex" }
const _hoisted_27 = ["value"]
const _hoisted_28 = { class: "w-2/5 filter-box shadow-md rounded-lg mb-6 bg-peach" }
const _hoisted_29 = { class: "filter-title flex justify-between px-4 py-3" }
const _hoisted_30 = { class: "grid grid-cols-1 divide-y" }
const _hoisted_31 = { class: "filter-numbers flex cursor-pointer" }
const _hoisted_32 = {
  key: 0,
  class: "w-1/3 text-center font-normal leading-6"
}
const _hoisted_33 = {
  key: 0,
  class: "w-1/3 text-center font-normal leading-6"
}
const _hoisted_34 = {
  key: 0,
  class: "filter-options flex-col text-sm"
}
const _hoisted_35 = { class: "filter-options flex flex-col px-3 py-3 text-sm relative" }
const _hoisted_36 = { class: "mb-3 flex" }
const _hoisted_37 = ["value"]
const _hoisted_38 = { class: "mt-1 mb-3 flex flex-wrap" }
const _hoisted_39 = ["value"]
const _hoisted_40 = { class: "mt-1 flex" }
const _hoisted_41 = ["value"]
const _hoisted_42 = { class: "grid grid-cols-2 gap-4" }
const _hoisted_43 = {
  key: 0,
  class: "bg-white shadow-md border p-2 mb-4 rounded-lg relative col-span-1 order-1 mr-5 min-h-96"
}
const _hoisted_44 = {
  key: 1,
  class: "bg-white shadow-md border p-2 mb-4 rounded-lg relative min-h-96 col-span-1 order-2"
}
const _hoisted_45 = {
  key: 2,
  class: "bg-white shadow-md border p-2 mb-4 rounded-lg relative min-h-96 col-span-1 order-2"
}

import { computed, ref } from 'vue';
import { EllipsisHorizontalIcon, ChartBarIcon } from '@heroicons/vue/20/solid';
import { FunnelIcon, ChartBarSquareIcon } from '@heroicons/vue/24/outline';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import ChartHS from './ChartHS.vue';
import ChartHSFiltered from './ChartHSFiltered.vue';
import ChartHSFilteredPet from './ChartHSFilteredPet.vue';
import ChartOTR from './ChartOTR.vue';
import ChartOTRFiltered from './ChartOTRFiltered.vue';
import ChartOTRFilteredPet from './ChartOTRFilteredPet.vue';
import ChartHeatMap from './ChartHeatMap.vue';
import DesingsCards from './DesingsCards.vue';
import { XCircleIcon } from '@heroicons/vue/24/outline';
import LayerIcon from '@/components/icons/LayerIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PerformanceCards',
  props: ['briefData'],
  setup(__props) {



const showDesigns = ref(false);
const showBoxes = ref(false);

const showHsChart = ref(false);
const showHsFilteredChart = ref(false);
const showOTRChart = ref(false);
const showOTRFilteredChart = ref(false);
const creatingOTRPlot = ref(false);
const creatingHSPlot = ref(false);
const showHeatMap = ref(false);
const creatingHeatMap = ref(false);

const showBarrierFilters = ref(false);
const showHSFilters = ref(false);
const showOTRPlayButton = computed<boolean>(() => !!selectedOtr.value && !!selectedOtrHum.value && !!selectedOtrTemp.value && !!selectedWvtr.value && !!selectedWvtrHum.value && !!selectedWvtrTemp.value)
const showHSPlayButton = computed<boolean>(() => hsPress.value.length > 0 && hsTemp.value.length > 0 && hsTime.value.length > 0);

const hanldeshowHeatMap = () => {
    creatingHeatMap.value = true;
    setTimeout(() => {
        showHeatMap.value = true;
        creatingHeatMap.value = false
    }, 1300)
}


const handleShowHShart = () => {
    creatingHSPlot.value = true;
    setTimeout(() => {
        showHsChart.value = true;
        creatingHSPlot.value = false
    }, 900)
}

const handleShowHSFilteredChart = () => {
    creatingHSPlot.value = true;
    showHsChart.value = false;
    showHSFilters.value = false;
    setTimeout(() => {
        showHsFilteredChart.value = true;
        creatingHSPlot.value = false
    }, 1500)
}

const hanldeShowOTRFilteredChart = () => {
    creatingOTRPlot.value = true;
    showOTRChart.value = false;
    showBarrierFilters.value = false;
    setTimeout(() => {
        showOTRFilteredChart.value = true;
        creatingOTRPlot.value = false
    }, 1500)
}

const handleShowOTRChart = () => {
    creatingOTRPlot.value = true;
    setTimeout(() => {
        showOTRChart.value = true;
        creatingOTRPlot.value = false
    }, 1000)
}

const handleShowDesign = () => {
    showBarrierFilters.value = false;
    showHSFilters.value = false;
    setTimeout(() => {
        showDesigns.value = true;
    }, 1200)

}

const tempOptions = [{ label: '23°C', value: 1 }, { label: '38°C', value: 2 }]
const selectedOtrTemp = ref(null);
const selectedWvtrTemp = ref(null)

const humOptions = [{ label: '50%', value: 2 }, { label: '90%', value: 3 }]
const humOptionsOtr = [{ label: '0%', value: 1 }, { label: '50%', value: 2 }, { label: '90%', value: 3 }]
const selectedOtrHum = ref(null);
const selectedWvtrHum = ref(null);

const otrOptions = [{ label: '<= 1', value: 2 }, { label: '<= 10', value: 3 }, { label: '<= 30', value: 6 }, { label: '<= 100', value: 15 }]
const wvtrOptions = [{ label: '<= 1', value: 3 }, { label: '<= 10', value: 20 }, { label: '<= 30', value: 6 }, { label: '<= 100', value: 15 }]
const selectedOtr = ref(null);
const selectedWvtr = ref(null);

const timeOptions = [{ label: '0.2s', value: 1 }, { label: '0.5s', value: 2 }, { label: '1s', value: 3 }, { label: '1.5s', value: 4 }];
const pressureOptions = [{ label: '150Kpa', value: 1 }, { label: '300Kpa', value: 2 }, { label: '350Kpa', value: 3 }, { label: '400Kpa', value: 4 }, { label: '450Kpa', value: 5 }, { label: '500Kpa', value: 6 }];
const tempHSOptions = [{ label: '130°C', value: 1 }, { label: '140°C', value: 2 }, { label: '150°C', value: 3 }, { label: '160°C', value: 4 }, { label: '170°C', value: 5 }, { label: '180°C', value: 6 }]

const hsTime = ref([]);
const hsTemp = ref([]);
const hsPress = ref([]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[17] || (_cache[17] = _createElementVNode("p", { class: "uppercase font-semibold mb-2 text-gray-700 mt-8" }, [
        _createTextVNode(" Performance "),
        _createElementVNode("span", { class: "text-sm normal-case font-normal" }, "(Filter values to see some solutions)")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_unref(FunnelIcon), {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (showBoxes.value = !showBoxes.value)),
          class: "icon thick icon-small white"
        })
      ])
    ]),
    (showBoxes.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[20] || (_cache[20] = _createElementVNode("div", null, [
                _createElementVNode("p", { class: "title" }, "Barrier Properties"),
                _createElementVNode("p", { class: "subtitle" }, "Performance evaluation")
              ], -1)),
              _createVNode(_unref(Popover), { class: "relative" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(PopoverButton), { class: "flex items-center" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(EllipsisHorizontalIcon), { class: "icon icon-small white" })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(PopoverPanel), { class: "absolute z-10 bg-moss rounded top-6 right-0 min-w-32 text-white" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", {
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (showBarrierFilters.value = !showBarrierFilters.value)),
                          class: "py-2 pl-3 pr-6 font-normal text-xs cursor-pointer flex items-center justify-center"
                        }, [
                          _createVNode(_unref(FunnelIcon), { class: "icon icon-x-small mr-4 white thick-2" }),
                          _cache[18] || (_cache[18] = _createElementVNode("p", null, "Filter", -1))
                        ]),
                        _createElementVNode("div", {
                          onClick: handleShowOTRChart,
                          class: "py-2 pl-3 pr-6 font-normal text-xs cursor-pointer flex items-center justify-center"
                        }, [
                          _createVNode(_unref(ChartBarIcon), { class: "icon icon-x-small mr-4 no-stroke" }),
                          _cache[19] || (_cache[19] = _createElementVNode("p", null, "Perform", -1))
                        ])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_7, [
              _cache[25] || (_cache[25] = _createElementVNode("p", { class: "w-full text-center font-normal leading-6" }, [
                _createElementVNode("span", { class: "otr" }, "OTR"),
                _createTextVNode(" 446 "),
                _createElementVNode("span", { class: "text-white block text-xs" }, "Measurements")
              ], -1)),
              _createVNode(_Transition, null, {
                default: _withCtx(() => [
                  (__props.briefData.id == 1 && selectedOtr.value && selectedOtrHum.value && selectedOtrTemp.value)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_8, _cache[21] || (_cache[21] = [
                        _createTextVNode(" 28 "),
                        _createElementVNode("span", { class: "text-white block text-xs" }, "Measurements", -1)
                      ])))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_Transition, null, {
                default: _withCtx(() => [
                  (__props.briefData.id == 2 && selectedOtr.value && selectedOtrHum.value && selectedOtrTemp.value)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_9, _cache[22] || (_cache[22] = [
                        _createTextVNode(" 17 "),
                        _createElementVNode("span", { class: "text-white block text-xs" }, "Measurements", -1)
                      ])))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _cache[26] || (_cache[26] = _createElementVNode("p", { class: "w-full text-center font-normal leading-6" }, [
                _createElementVNode("span", { class: "otr" }, "WVTR"),
                _createTextVNode(" 355 "),
                _createElementVNode("span", { class: "text-white block text-xs" }, "Measurements")
              ], -1)),
              _createVNode(_Transition, null, {
                default: _withCtx(() => [
                  (__props.briefData.id == 1 && selectedWvtr.value && selectedWvtrHum.value && selectedWvtrTemp.value)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_10, _cache[23] || (_cache[23] = [
                        _createTextVNode(" 26 "),
                        _createElementVNode("span", { class: "text-white block text-xs" }, "Measurements", -1)
                      ])))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_Transition, null, {
                default: _withCtx(() => [
                  (__props.briefData.id == 2 && selectedWvtr.value && selectedWvtrHum.value && selectedWvtrTemp.value)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_11, _cache[24] || (_cache[24] = [
                        _createTextVNode(" 11 "),
                        _createElementVNode("span", { class: "text-white block text-xs" }, "Measurements", -1)
                      ])))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            (showBarrierFilters.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _cache[33] || (_cache[33] = _createElementVNode("p", { class: "border-t uppercase font-medium px-4 py-1 text-white text-center text-sm" }, "testing conditions ", -1)),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _cache[27] || (_cache[27] = _createElementVNode("p", { class: "uppercase font-medium" }, "Temperature", -1)),
                      _createElementVNode("div", _hoisted_15, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(tempOptions, (temp) => {
                          return _createElementVNode("div", {
                            key: temp.value,
                            class: "filter-option flex items-center"
                          }, [
                            _withDirectives(_createElementVNode("input", {
                              type: "radio",
                              value: temp.value,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((selectedOtrTemp).value = $event))
                            }, null, 8, _hoisted_16), [
                              [_vModelRadio, selectedOtrTemp.value]
                            ]),
                            _createElementVNode("label", null, _toDisplayString(temp.label), 1)
                          ])
                        }), 64))
                      ]),
                      _cache[28] || (_cache[28] = _createElementVNode("p", { class: "uppercase font-medium" }, "Relative humidity", -1)),
                      _createElementVNode("div", _hoisted_17, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(humOptionsOtr, (humidity) => {
                          return _createElementVNode("div", {
                            key: humidity.value,
                            class: "filter-option flex items-center mt-1"
                          }, [
                            _withDirectives(_createElementVNode("input", {
                              type: "radio",
                              value: humidity.value,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((selectedOtrHum).value = $event))
                            }, null, 8, _hoisted_18), [
                              [_vModelRadio, selectedOtrHum.value]
                            ]),
                            _createElementVNode("label", null, _toDisplayString(humidity.label), 1)
                          ])
                        }), 64))
                      ]),
                      _cache[29] || (_cache[29] = _createElementVNode("p", { class: "uppercase font-medium" }, "OTR", -1)),
                      _createElementVNode("div", _hoisted_19, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(otrOptions, (otr) => {
                          return _createElementVNode("div", {
                            key: otr.value,
                            class: "filter-option flex items-center"
                          }, [
                            _withDirectives(_createElementVNode("input", {
                              type: "radio",
                              value: otr.value,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((selectedOtr).value = $event))
                            }, null, 8, _hoisted_20), [
                              [_vModelRadio, selectedOtr.value]
                            ]),
                            _createElementVNode("label", null, _toDisplayString(otr.label), 1)
                          ])
                        }), 64))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_21, [
                      _cache[30] || (_cache[30] = _createElementVNode("p", { class: "uppercase font-medium" }, "Temperature", -1)),
                      _createElementVNode("div", _hoisted_22, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(tempOptions, (temp) => {
                          return _createElementVNode("div", {
                            key: temp.value,
                            class: "filter-option flex items-center"
                          }, [
                            _withDirectives(_createElementVNode("input", {
                              type: "radio",
                              value: temp.value,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((selectedWvtrTemp).value = $event))
                            }, null, 8, _hoisted_23), [
                              [_vModelRadio, selectedWvtrTemp.value]
                            ]),
                            _createElementVNode("label", null, _toDisplayString(temp.label), 1)
                          ])
                        }), 64))
                      ]),
                      _cache[31] || (_cache[31] = _createElementVNode("p", { class: "uppercase font-medium" }, "Relative humidity", -1)),
                      _createElementVNode("div", _hoisted_24, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(humOptions, (kit) => {
                          return _createElementVNode("div", {
                            key: kit.value,
                            class: "filter-option flex items-center"
                          }, [
                            _withDirectives(_createElementVNode("input", {
                              type: "radio",
                              value: kit.value,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((selectedWvtrHum).value = $event))
                            }, null, 8, _hoisted_25), [
                              [_vModelRadio, selectedWvtrHum.value]
                            ]),
                            _createElementVNode("label", null, _toDisplayString(kit.label), 1)
                          ])
                        }), 64))
                      ]),
                      _cache[32] || (_cache[32] = _createElementVNode("p", { class: "uppercase font-medium" }, "WVTR", -1)),
                      _createElementVNode("div", _hoisted_26, [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(wvtrOptions, (wvtr) => {
                          return _createElementVNode("div", {
                            key: wvtr.value,
                            class: "filter-option flex items-center"
                          }, [
                            _withDirectives(_createElementVNode("input", {
                              type: "radio",
                              value: wvtr.value,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((selectedWvtr).value = $event))
                            }, null, 8, _hoisted_27), [
                              [_vModelRadio, selectedWvtr.value]
                            ]),
                            _createElementVNode("label", null, _toDisplayString(wvtr.label), 1)
                          ])
                        }), 64))
                      ]),
                      (showOTRPlayButton.value)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            onClick: hanldeShowOTRFilteredChart,
                            class: "chart-icon"
                          }, [
                            _createVNode(_unref(ChartBarIcon), { class: "icon icon-small" })
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("div", _hoisted_29, [
              _cache[37] || (_cache[37] = _createElementVNode("div", null, [
                _createElementVNode("p", { class: "title" }, "Heat Sealability"),
                _createElementVNode("p", { class: "subtitle" }, "Performance evaluation")
              ], -1)),
              _createVNode(_unref(Popover), { class: "relative" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(PopoverButton), { class: "flex items-center" }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(EllipsisHorizontalIcon), { class: "icon icon-small white" })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(PopoverPanel), { class: "absolute z-10 bg-peach-strong rounded top-6 right-0 min-w-32 text-white" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_30, [
                        _createElementVNode("div", {
                          onClick: _cache[8] || (_cache[8] = ($event: any) => (showHSFilters.value = !showHSFilters.value)),
                          class: "py-2 pl-3 pr-6 font-normal text-xs cursor-pointer flex items-center justify-center"
                        }, [
                          _createVNode(_unref(FunnelIcon), { class: "icon icon-x-small mr-4 white thick-2" }),
                          _cache[34] || (_cache[34] = _createElementVNode("p", null, "Filter", -1))
                        ]),
                        _createElementVNode("div", {
                          onClick: handleShowHShart,
                          class: "py-2 pl-3 pr-6 font-normal text-xs cursor-pointer flex items-center justify-center"
                        }, [
                          _createVNode(_unref(ChartBarIcon), { class: "icon icon-x-small mr-4 no-stroke" }),
                          _cache[35] || (_cache[35] = _createElementVNode("p", null, "Perform", -1))
                        ]),
                        _createElementVNode("div", {
                          onClick: hanldeshowHeatMap,
                          class: "py-2 pl-3 pr-6 font-normal text-xs cursor-pointer flex items-center justify-center"
                        }, [
                          _createVNode(_unref(ChartBarSquareIcon), { class: "icon icon-x-small mr-4 white thick-2" }),
                          _cache[36] || (_cache[36] = _createElementVNode("p", null, "Explore", -1))
                        ])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_31, [
              _cache[40] || (_cache[40] = _createElementVNode("p", { class: "w-full text-center font-normal border-0 leading-6" }, [
                _createTextVNode("1415 "),
                _createElementVNode("span", { class: "text-white block text-xs" }, "Measurements")
              ], -1)),
              _createVNode(_Transition, null, {
                default: _withCtx(() => [
                  (__props.briefData.id == 1 && hsTime.value.length > 0 && hsTemp.value.length > 0 && hsPress.value.length > 0)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_32, _cache[38] || (_cache[38] = [
                        _createTextVNode(" 7 "),
                        _createElementVNode("span", { class: "text-white block text-xs" }, "Measurements", -1)
                      ])))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_Transition, null, {
                default: _withCtx(() => [
                  (__props.briefData.id == 2 && hsTime.value.length > 0 && hsTemp.value.length > 0 && hsPress.value.length > 0)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_33, _cache[39] || (_cache[39] = [
                        _createTextVNode(" 7 "),
                        _createElementVNode("span", { class: "text-white block text-xs" }, "Measurements", -1)
                      ])))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            (showHSFilters.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                  _cache[44] || (_cache[44] = _createElementVNode("p", { class: "uppercase font-medium px-4 py-1 text-white text-center text-sm" }, "testing conditions", -1)),
                  _createElementVNode("div", _hoisted_35, [
                    _cache[41] || (_cache[41] = _createElementVNode("p", { class: "uppercase font-medium" }, "Time", -1)),
                    _createElementVNode("div", _hoisted_36, [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(timeOptions, (time) => {
                        return _createElementVNode("div", {
                          key: time.value,
                          class: "filter-option flex items-center mt-1"
                        }, [
                          _withDirectives(_createElementVNode("input", {
                            type: "checkbox",
                            value: time.value,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((hsTime).value = $event))
                          }, null, 8, _hoisted_37), [
                            [_vModelCheckbox, hsTime.value]
                          ]),
                          _createElementVNode("label", null, _toDisplayString(time.label), 1)
                        ])
                      }), 64))
                    ]),
                    _cache[42] || (_cache[42] = _createElementVNode("p", { class: "uppercase font-medium" }, "Pressure", -1)),
                    _createElementVNode("div", _hoisted_38, [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(pressureOptions, (pressure) => {
                        return _createElementVNode("div", {
                          key: pressure.value,
                          class: "filter-option flex items-center"
                        }, [
                          _withDirectives(_createElementVNode("input", {
                            type: "checkbox",
                            value: pressure.value,
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((hsPress).value = $event))
                          }, null, 8, _hoisted_39), [
                            [_vModelCheckbox, hsPress.value]
                          ]),
                          _createElementVNode("label", null, _toDisplayString(pressure.label), 1)
                        ])
                      }), 64))
                    ]),
                    _cache[43] || (_cache[43] = _createElementVNode("p", { class: "uppercase font-medium" }, "Temperature", -1)),
                    _createElementVNode("div", _hoisted_40, [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(tempHSOptions, (temp) => {
                        return _createElementVNode("div", {
                          key: temp.value,
                          class: "filter-option flex items-center"
                        }, [
                          _withDirectives(_createElementVNode("input", {
                            type: "checkbox",
                            value: temp.value,
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((hsTemp).value = $event))
                          }, null, 8, _hoisted_41), [
                            [_vModelCheckbox, hsTemp.value]
                          ]),
                          _createElementVNode("label", null, _toDisplayString(temp.label), 1)
                        ])
                      }), 64))
                    ]),
                    (showHSPlayButton.value)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          onClick: handleShowHSFilteredChart,
                          class: "chart-icon"
                        }, [
                          _createVNode(_unref(ChartBarIcon), { class: "icon icon-small" })
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_42, [
      (creatingOTRPlot.value || showOTRChart.value || showOTRFilteredChart.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
            (creatingOTRPlot.value)
              ? (_openBlock(), _createBlock(_unref(ChartBarIcon), {
                  key: 0,
                  class: "animate-pulse spin-chart"
                }))
              : (showOTRChart.value)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createVNode(_unref(XCircleIcon), {
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (showOTRChart.value = false)),
                      class: _normalizeClass(["icon icon-m absolute top-3 z-10 left-3 duration-1000 delay-300", { 'opacity-0': !showOTRChart.value, 'opacity-100': showOTRChart.value }])
                    }, null, 8, ["class"]),
                    _createVNode(ChartOTR, {
                      class: _normalizeClass(["transition-opacity duration-1000 delay-300", { 'opacity-0': !showOTRChart.value, 'opacity-100': showOTRChart.value }])
                    }, null, 8, ["class"])
                  ], 64))
                : (showOTRFilteredChart.value)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createVNode(_unref(XCircleIcon), {
                        onClick: _cache[13] || (_cache[13] = ($event: any) => (showOTRFilteredChart.value = false)),
                        class: _normalizeClass(["icon icon-m absolute top-3 z-10 left-3 duration-1000 delay-300", { 'opacity-0': !showOTRFilteredChart.value, 'opacity-100': showOTRFilteredChart.value }])
                      }, null, 8, ["class"]),
                      (__props.briefData.id == 2)
                        ? (_openBlock(), _createBlock(ChartOTRFiltered, {
                            key: 0,
                            class: _normalizeClass(["transition-opacity duration-1000 delay-300", { 'opacity-0': !showOTRFilteredChart.value, 'opacity-100': showOTRFilteredChart.value }])
                          }, null, 8, ["class"]))
                        : _createCommentVNode("", true),
                      (__props.briefData.id == 1)
                        ? (_openBlock(), _createBlock(ChartOTRFilteredPet, {
                            key: 1,
                            class: _normalizeClass(["transition-opacity duration-1000 delay-300", { 'opacity-0': !showOTRFilteredChart.value, 'opacity-100': showOTRFilteredChart.value }])
                          }, null, 8, ["class"]))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (creatingHeatMap.value || showHeatMap.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
            (creatingHeatMap.value)
              ? (_openBlock(), _createBlock(_unref(ChartBarIcon), {
                  key: 0,
                  class: "animate-pulse spin-chart hs"
                }))
              : (showHeatMap.value)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createVNode(_unref(XCircleIcon), {
                      onClick: _cache[14] || (_cache[14] = ($event: any) => (showHeatMap.value = false)),
                      class: _normalizeClass(["icon icon-m absolute top-3 z-10 left-3 duration-1000 delay-300", { 'opacity-0': !showHeatMap.value, 'opacity-100': showHeatMap.value }])
                    }, null, 8, ["class"]),
                    _createVNode(ChartHeatMap, {
                      class: _normalizeClass(["transition-opacity duration-1000 delay-300", { 'opacity-0': !showHeatMap.value, 'opacity-100': showHeatMap.value }])
                    }, null, 8, ["class"])
                  ], 64))
                : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (creatingHSPlot.value || showHsChart.value || showHsFilteredChart.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
            (creatingHSPlot.value)
              ? (_openBlock(), _createBlock(_unref(ChartBarIcon), {
                  key: 0,
                  class: "animate-pulse spin-chart hs"
                }))
              : (showHsChart.value)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createVNode(_unref(XCircleIcon), {
                      onClick: _cache[15] || (_cache[15] = ($event: any) => (showHsChart.value = false)),
                      class: _normalizeClass(["icon icon-m absolute top-3 z-10 left-3 duration-1000 delay-300", { 'opacity-0': !showHsChart.value, 'opacity-100': showHsChart.value }])
                    }, null, 8, ["class"]),
                    _createVNode(ChartHS, {
                      class: _normalizeClass(["transition-opacity duration-1000 delay-300", { 'opacity-0': !showHsChart.value, 'opacity-100': showHsChart.value }])
                    }, null, 8, ["class"])
                  ], 64))
                : (showHsFilteredChart.value)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createVNode(_unref(XCircleIcon), {
                        onClick: _cache[16] || (_cache[16] = ($event: any) => (showHsFilteredChart.value = false)),
                        class: _normalizeClass(["icon icon-m absolute top-3 z-10 left-3 duration-1000 delay-300", { 'opacity-0': !showHsFilteredChart.value, 'opacity-100': showHsFilteredChart.value }])
                      }, null, 8, ["class"]),
                      (__props.briefData.id == 2)
                        ? (_openBlock(), _createBlock(ChartHSFiltered, {
                            key: 0,
                            class: _normalizeClass(["transition-opacity duration-1000 delay-300", { 'opacity-0': !showHsFilteredChart.value, 'opacity-100': showHsFilteredChart.value}])
                          }, null, 8, ["class"]))
                        : _createCommentVNode("", true),
                      (__props.briefData.id == 1)
                        ? (_openBlock(), _createBlock(ChartHSFilteredPet, {
                            key: 1,
                            class: _normalizeClass(["transition-opacity duration-1000 delay-300", { 'opacity-0': !showHsFilteredChart.value, 'opacity-100': showHsFilteredChart.value && __props.briefData.id == 1 }])
                          }, null, 8, ["class"]))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    (showHsFilteredChart.value && showOTRFilteredChart.value && !showDesigns.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          onClick: handleShowDesign,
          class: _normalizeClass(["fixed flex items-center rounded-full px-4 py-2 bg-moss shadow-md bottom-7 right-8 transition-opacity duration-1000 delay-1000 cursor-pointer", { 'opacity-0': !showHsFilteredChart.value || !showHsFilteredChart.value, 'opacity-100': showHsFilteredChart.value && showOTRFilteredChart.value }])
        }, [
          _createVNode(LayerIcon, { class: "icon icon-m white mr-2" }),
          _cache[45] || (_cache[45] = _createElementVNode("p", { class: "text-white text-xs uppercase" }, "Show solution", -1))
        ], 2))
      : _createCommentVNode("", true),
    (showDesigns.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["transition-opacity duration-1000 delay-1000 cursor-pointer", [showDesigns.value ? 'opacity-100' : 'opacity-0']])
        }, [
          _createVNode(DesingsCards, {
            solutions: __props.briefData.solutions
          }, null, 8, ["solutions"])
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}
}

})