<template>
  <svg
    version="1.1"
    id="fi_777502"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512.07 512.07"
    style="enable-background: new 0 0 512.07 512.07"
    xml:space="preserve"
  >
    <g transform="translate(0 -1)">
      <g>
        <g>
          <path
            d="M76.834,368.003h-51.2c-14.132,0.015-25.585,11.468-25.6,25.6v110.933c0,4.713,3.82,8.533,8.533,8.533H93.9
				c4.713,0,8.533-3.82,8.533-8.533V393.603C102.418,379.471,90.966,368.019,76.834,368.003z M85.367,496.003H17.1v-102.4
				c0.006-4.71,3.823-8.527,8.533-8.533h51.2c4.71,0.006,8.527,3.823,8.533,8.533V496.003z"
          ></path>
          <path
            d="M213.367,248.537h-51.2c-14.132,0.015-25.585,11.468-25.6,25.6v230.4c0,4.713,3.82,8.533,8.533,8.533h85.333
				c4.713,0,8.533-3.82,8.533-8.533v-230.4C238.951,260.004,227.499,248.552,213.367,248.537z M221.9,496.003h-68.267V274.137
				c0.006-4.71,3.823-8.527,8.533-8.533h51.2c4.71,0.006,8.527,3.823,8.533,8.533V496.003z"
          ></path>
          <path
            d="M349.9,299.737h-51.2c-14.132,0.015-25.585,11.468-25.6,25.6v179.2c0,4.713,3.82,8.533,8.533,8.533h85.333
				c4.713,0,8.533-3.82,8.533-8.533v-179.2C375.485,311.204,364.032,299.752,349.9,299.737z M358.434,496.003h-68.267V325.337
				c0.006-4.71,3.823-8.527,8.533-8.533h51.2c4.71,0.006,8.527,3.823,8.533,8.533V496.003z"
          ></path>
          <path
            d="M486.434,231.47h-51.2c-14.132,0.015-25.585,11.468-25.6,25.6v247.467c0,4.713,3.82,8.533,8.533,8.533H503.5
				c4.713,0,8.533-3.82,8.533-8.533V257.07C512.018,242.938,500.566,231.485,486.434,231.47z M494.967,496.003H426.7V257.07
				c0.006-4.71,3.823-8.527,8.533-8.533h51.2c4.71,0.006,8.527,3.823,8.533,8.533V496.003z"
          ></path>
          <path
            d="M422.613,103.288c15.633,17.596,41.254,22.258,62.083,11.296c20.829-10.962,31.493-34.72,25.84-57.569
				c-5.653-22.849-26.165-38.893-49.702-38.879c-17.088-0.013-33.054,8.505-42.557,22.706c-9.504,14.201-11.29,32.209-4.761,48
				l-50.994,31.875c-9.175-10.401-22.194-16.613-36.051-17.203s-27.356,4.495-37.381,14.079l-55.852-41.889
				c11.808-22.839,4.844-50.893-16.274-65.559c-21.118-14.666-49.838-11.394-67.115,7.647c-17.277,19.041-17.751,47.943-1.108,67.54
				l-71.542,85.018c-22.94-13.498-52.397-7.167-67.766,14.564s-11.525,51.614,8.845,68.747c20.37,17.133,50.469,15.801,69.246-3.064
				c18.777-18.865,19.967-48.971,2.738-69.26l71.542-85.018c19.643,11.685,44.669,8.831,61.177-6.977l55.852,41.889
				c-10.87,21.055-5.868,46.819,12.091,62.277s44.181,16.568,63.383,2.684c19.202-13.884,26.365-39.132,17.314-61.031
				L422.613,103.288z M460.834,35.203c18.851,0,34.133,15.282,34.133,34.133c-0.022,18.842-15.291,34.112-34.133,34.133
				c-18.851,0-34.133-15.282-34.133-34.133S441.982,35.203,460.834,35.203z M51.234,248.537c-18.851,0-34.133-15.282-34.133-34.133
				c0-18.851,15.282-34.133,34.133-34.133s34.133,15.282,34.133,34.133C85.345,233.246,70.076,248.515,51.234,248.537z
				M187.767,86.403c-18.851,0-34.133-15.282-34.133-34.133s15.282-34.133,34.133-34.133S221.9,33.419,221.9,52.27
				C221.879,71.112,206.609,86.382,187.767,86.403z M324.3,188.803c-18.851,0-34.133-15.282-34.133-34.133
				s15.282-34.133,34.133-34.133c18.851,0,34.133,15.282,34.133,34.133C358.412,173.512,343.143,188.782,324.3,188.803z"
          ></path>
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>
