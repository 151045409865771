import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, normalizeClass as _normalizeClass, vModelText as _vModelText, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "col-span-4" }
const _hoisted_2 = { class: "grid grid-cols-4 gap-y-4 gap-x-4" }
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]
const _hoisted_5 = {
  key: 0,
  class: "text-xs text-black rounded-md block bg-gray-100 p-4 mt-4"
}
const _hoisted_6 = {
  key: 2,
  class: "col-span-4"
}

import { computed } from "vue";
import { useFormFieldsCreationNewRun } from "@/tea/composables/runs/useFormFieldsCreationNewRun";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { useStore } from "vuex";
import { NEW_RUN_FIELDS } from "@/tea/models/run";
import {InformationCircleIcon} from '@heroicons/vue/24/outline';

export default /*@__PURE__*/_defineComponent({
  __name: 'RunNvp',
  props: ["errorsFields", "formSubmited", "formInizializationValues"],
  setup(__props) {

const yesNos = [
  { key: true, value: "Yes" },
  { key: false, value: "No" },
];
const knowTargetCompoundPrices = [
  { key: true, value: "Yes, I know the selling price" },
  { key: false, value: "No, I don't, Suggest me!" },
];

const store = useStore();

const props = __props;

const fields = computed(() => store.getters["runCreateModule/fields"]);

const showbycompounds = computed(() => {
  const ktcp = fields.value.knowTargetCompoundPrice;
  const bc = Number(fields.value.bycompounds);
  const kbp = fields.value.knowBycompoundsPrice;
  if ((ktcp == true && bc > 0) || (ktcp == false && kbp == true)) {
    return true;
  }
  return false;
});

const showbycompoundsPrice = computed(() => {
  const ktcp = fields.value.knowTargetCompoundPrice;
  const bc = Number(fields.value.bycompounds);
  if (ktcp == false && bc > 0) {
    return true;
  }
  return false;
});

const showbycompoundX = computed(() => {
  if (showbycompounds.value == false) {
    return false;
  }
  const bc = Number(fields.value.bycompounds);
  if (props.formInizializationValues["organismsOptions"] && bc > 0) {
    return true;
  }
  return false;
});
const showbycompoundY = computed(() => {
  if (showbycompounds.value == false) {
    return false;
  }
  const bc = Number(fields.value.bycompounds);
  if (props.formInizializationValues["organismsOptions"] && bc > 1) {
    return true;
  }
  return false;
});
const showbycompoundZ = computed(() => {
  if (showbycompounds.value == false) {
    return false;
  }
  const bc = Number(fields.value.bycompounds);
  if (props.formInizializationValues["organismsOptions"] && bc == 3) {
    return true;
  }
  return false;
});

const getFormFields = Object.keys(NEW_RUN_FIELDS.formFields).map((key) => {
  const fieldConfig = NEW_RUN_FIELDS.formFields[key];
  return {
    [key]: Array.isArray(fieldConfig.onChangeResetFields) ? fieldConfig.onChangeResetFields : [],
  };
});
const formFieldOptions = {
  fields: getFormFields,
  mutation: "runCreateModule/SET_SINGLE_RUN_VALUE",
};
const formFields = useFormFieldsCreationNewRun(formFieldOptions);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('knowTargetCompoundPrice') && __props.formSubmited }])
      }, [
        _cache[6] || (_cache[6] = _createElementVNode("label", null, "Do you know the price you need to sell target compound? *", -1)),
        _withDirectives(_createElementVNode("select", {
          class: "w-full",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(formFields)['knowTargetCompoundPrice'].value) = $event))
        }, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(knowTargetCompoundPrices, (yesNo) => {
            return _createElementVNode("option", {
              value: yesNo.key,
              key: yesNo.key
            }, _toDisplayString(yesNo.value), 9, _hoisted_3)
          }), 64))
        ], 512), [
          [_vModelSelect, _unref(formFields)['knowTargetCompoundPrice'].value]
        ]),
        _cache[7] || (_cache[7] = _createElementVNode("span", { class: "text-xs text-gray-400" }, "Note: NPV is calculated considering a tax rate of 20% and a discount rate of 15%", -1))
      ], 2),
      (fields.value.knowTargetCompoundPrice)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('targetCompoundPrice') && __props.formSubmited }])
          }, [
            _cache[8] || (_cache[8] = _createElementVNode("label", null, "Price target compound (€/kg) *", -1)),
            _withDirectives(_createElementVNode("input", {
              class: "w-full",
              type: "number",
              min: "0.00",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(formFields)['targetCompoundPrice'].value) = $event))
            }, null, 512), [
              [_vModelText, _unref(formFields)['targetCompoundPrice'].value]
            ])
          ], 2))
        : _createCommentVNode("", true),
      (showbycompoundsPrice.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["col-span-4", { 'error-form-field': __props.errorsFields.includes('knowBycompoundsPrice') && __props.formSubmited }])
          }, [
            _cache[11] || (_cache[11] = _createElementVNode("label", null, "Do you know the price you need to sell bycompound(s)? *", -1)),
            _withDirectives(_createElementVNode("select", {
              class: "w-full",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(formFields)['knowBycompoundsPrice'].value) = $event))
            }, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(yesNos, (yesNo) => {
                return _createElementVNode("option", {
                  value: yesNo.key,
                  key: yesNo.key
                }, _toDisplayString(yesNo.value), 9, _hoisted_4)
              }), 64))
            ], 512), [
              [_vModelSelect, _unref(formFields)['knowBycompoundsPrice'].value]
            ]),
            (!fields.value.knowBycompoundsPrice)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                  _createVNode(_unref(InformationCircleIcon), { class: "icon icon-small inline-block" }),
                  _cache[9] || (_cache[9] = _createTextVNode(" Karen is doing its best to help you, but at this stage it's still a bit limited. It can only ")),
                  _cache[10] || (_cache[10] = _createElementVNode("b", null, "suggest you the selling price of your main compound", -1))
                ]))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true),
      (showbycompounds.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (showbycompoundX.value)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(["mb-4", { 'error-form-field': __props.errorsFields.includes('bycompoundXPrice') && __props.formSubmited }])
                }, [
                  _cache[12] || (_cache[12] = _createElementVNode("label", null, "Price bycompound x (€/kg)", -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: "w-full",
                    type: "number",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(formFields)['bycompoundXPrice'].value) = $event))
                  }, null, 512), [
                    [_vModelText, _unref(formFields)['bycompoundXPrice'].value]
                  ])
                ], 2))
              : _createCommentVNode("", true),
            (showbycompoundY.value)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(["mb-4", { 'error-form-field': __props.errorsFields.includes('bycompoundYPrice') && __props.formSubmited }])
                }, [
                  _cache[13] || (_cache[13] = _createElementVNode("label", null, "Price bycompound y (€/kg)", -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: "w-full",
                    type: "number",
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(formFields)['bycompoundYPrice'].value) = $event))
                  }, null, 512), [
                    [_vModelText, _unref(formFields)['bycompoundYPrice'].value]
                  ])
                ], 2))
              : _createCommentVNode("", true),
            (showbycompoundZ.value)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: _normalizeClass(["mb-4", { 'error-form-field': __props.errorsFields.includes('bycompoundZPrice') && __props.formSubmited }])
                }, [
                  _cache[14] || (_cache[14] = _createElementVNode("label", null, "Price bycompound z (€/kg)", -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: "w-full",
                    type: "number",
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(formFields)['bycompoundZPrice'].value) = $event))
                  }, null, 512), [
                    [_vModelText, _unref(formFields)['bycompoundZPrice'].value]
                  ])
                ], 2))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})