import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref } from 'vue';
import { MagnifyingGlassIcon, XMarkIcon, ChevronDoubleRightIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchAsYouType',
  emits: ['quickSearchRefetch'],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const store = useStore();
const showInput = ref(false);
const searchText = ref('');

const handleQuickSearch = () => {
    showInput.value = true;
    if (showInput.value && searchText.value != '') { 
        store.commit('assetsModule/setQuickSearchFilterCriteria', {searchText: searchText.value});
        emit('quickSearchRefetch');
    }
}

const handleClose = () => {
    showInput.value = false; 
    handleRestore();
}
const handleRestore = () => {
    searchText.value = '';
    store.commit('assetsModule/restoreQuickSearchFilterCriteria');
    emit('quickSearchRefetch')
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex relative", {'flex-row-reverse': showInput.value }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["round-action-icon", {green: showInput.value }]),
      onClick: handleQuickSearch
    }, [
      _createVNode(_unref(MagnifyingGlassIcon), { class: "icon icon-small" })
    ], 2),
    _withDirectives(_createElementVNode("input", {
      class: _normalizeClass(["search-input", {grown: showInput.value }]),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchText).value = $event)),
      onKeyup: _withKeys(handleQuickSearch, ["enter"])
    }, null, 34), [
      [_vModelText, searchText.value]
    ]),
    _createVNode(_unref(ChevronDoubleRightIcon), {
      class: _normalizeClass(["hidden", {'close-search': showInput.value}]),
      onClick: handleClose
    }, null, 8, ["class"]),
    _createVNode(_unref(XMarkIcon), {
      class: _normalizeClass(["icon icon-small hidden", {'restore-search': showInput.value}]),
      onClick: handleRestore
    }, null, 8, ["class"])
  ], 2))
}
}

})