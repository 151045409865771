<template>
  <div>
    <div class="w-full grid grid-cols-3 items-center pt-1 pb-1">
      <div class="col-span-1">
        <h1 class="view-title">
          RUNS &nbsp;<span class="view-subtitle runs-loaded">&nbsp; loaded {{ runs.edgeCount }} of {{ runs.totalCount }} runs</span>
        </h1>
      </div>
      <div class="col-span-2 text-right">
        <div class="flex justify-end">
          <div class="round-action-icon one-five-green" @click="showPlotting" :class="{ disabled: showPlottingDisabled }" title="Plotting">
            <ChartPieIcon class="icon icon-small" title="Plotting" />
          </div>
          <div class="round-action-icon one-five-green" @click="showSensitivity" :class="{ disabled: showSensitivityDisabled }" title="Sensitivity Analysis">
            <PresentationChartLineIcon class="icon icon-small" title="Sensitivity Analysis" />
          </div>
          <div
            class="round-action-icon one-five-green"
            @click="showDoubleSensitivity"
            :class="{ disabled: showDoubleSensitivityDisabled }"
            title="Double Sensitivity Analysis"
          >
            <PresentationChartLineIcon class="icon icon-small ml-1" title="Double Sensitivity Analysis" />
            <PresentationChartLineIcon class="icon icon-small mr-1" title="Double Sensitivity Analysis" />
          </div>
        </div>
      </div>
    </div>

    <RunTable
      :columns="columns"
      :rows="runs.loadedRuns"
      @on-page-change="onPageChange"
      @on-row-click="onRowClick"
      @on-selected-rows-change="onSelectedRowsChange"
      :searchTerm="searchTerm"
      :loading="runs.loading"
    />

    <RunPlotting v-if="showPlott" :showPlott="showPlott" :selectedIds="selectedIds" @closePlott="showPlott = false" />
    <RunSensitivityRepo v-if="showSensitive" :showSensitive="showSensitive" :selectedId="selectedIds[0]" @closeSensitive="showSensitive = false" />
    <RunDoubleSensitivityRepo
      v-if="showDoubleSensitive"
      :showDoubleSensitive="showDoubleSensitive"
      :selectedId="selectedIds[0]"
      @closeDoubleSensitive="showDoubleSensitive = false"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, defineProps, watch } from "vue";
import RunTable from "@/tea/components/runs/RunTable.vue";
import { RUN_TABLE_COLUMS } from "@/tea/models/run";
import { useStore } from "vuex";
import RunPlotting from "@/tea/components/runs/RunPlotting.vue";
import RunSensitivityRepo from "@/tea/components/runs/RunSensitivityRepo.vue";
import RunDoubleSensitivityRepo from "@/tea/components/runs/RunDoubleSensitivityRepo.vue";
import { ChartPieIcon, PresentationChartLineIcon } from "@heroicons/vue/24/outline";

const store = useStore();
const props = defineProps(["runGroupId", "searchTerm", "runCreated", "runGroupId"]);

store.commit("runModule/SET_RUN_GROUP_ID", props.runGroupId);

const HEADER_TITLE = "Runs";

const columns = ref(RUN_TABLE_COLUMS);
const repo = computed(() => store.getters["runModule/repo"]);
const runs = computed(() => {
  const loadedRuns = repo.value.runs.map((node) => {
    return { ...node, createdAt: new Date(node.createdAt).toLocaleString() };
  });
  return { loadedRuns, totalCount: repo.value.totalCount, edgeCount: repo.value.edgeCount, loading: repo.value.loading };
});

store.dispatch("runModule/loadRuns");

const hasNextPage = computed(() => {
  if (repo.value.pageInfo) {
    return repo.value.pageInfo.hasNextPage;
  }
  return false;
});

const loadMore = async () => {
  await store.dispatch("runModule/loadMoreRuns");
};

watch(
  () => props.runCreated,
  (newValue, oldValue) => {
    onCreated();
  }
);
const onCreated = async () => {
  loadMore();
};
const onPageChange = () => {
  if (hasNextPage.value) {
    loadMore();
  }
};

const showPlott = ref(false);
const showPlotting = () => {
  if (!showPlottingDisabled.value) {
    showPlott.value = true;
  }
};

const showSensitive = ref(false);
const showSensitivity = () => {
  if (!showSensitivityDisabled.value) {
    showSensitive.value = true;
  }
};

const showDoubleSensitive = ref(false);
const showDoubleSensitivity = () => {
  if (!showDoubleSensitivityDisabled.value) {
    showDoubleSensitive.value = true;
  }
};

const onRowClick = (id) => {
  console.log(id);
  //   router.push({ name: "Tea.RunGroupDetail", params: { id } });
};

const selectedIds = ref([]);
const showPlottingDisabled = computed(() => selectedIds.value.length < 1);
const showSensitivityDisabled = computed(() => {
  const record = repo.value.runs.find((obj) => obj.id == selectedIds.value[0]);
  return selectedIds.value.length != 1 || record.residualBiomass;
});
const showDoubleSensitivityDisabled = computed(() => {
  const record = repo.value.runs.find((obj) => obj.id == selectedIds.value[0]);
  return selectedIds.value.length != 1 || record.residualBiomass;
});
const onSelectedRowsChange = (ids) => {
  showPlott.value = false;
  selectedIds.value = ids;
  store.commit("runSensitivityModule/RESET_CREATE");
};
</script>
<style lang="scss" scoped>
.runs-loaded {
  font-family: Arial, Helvetica, sans-serif;
}

.round-action-icon.disabled,
.round-action-icon.disabled * {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
