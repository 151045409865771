<template>
    <div class="flex items-center justify-center">      
        <div class="grid grid-cols-1">
            <div class="text-center  bg-red-100  border border-red-100 p-3 rounded ">
                <p class="m-4 uppercase text-gray-500">{{store.state.aiModule.error}}</p>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useStore } from 'vuex';

const store = useStore();

</script>