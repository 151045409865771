import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/images/one-five-icon-white.png'
import _imports_1 from '@/assets/images/one-five-blue-trademark.png'
import _imports_2 from '@/assets/icons/polymer.svg'
import _imports_3 from '@/assets/icons/polymer-gray.svg'
import _imports_4 from '../assets/images/clippy-white.svg'
import _imports_5 from '../assets/images/clippy-gray.svg'


const _hoisted_1 = { class: "sidebar-content" }
const _hoisted_2 = { class: "sidebar-links" }
const _hoisted_3 = {
  key: 0,
  class: "tooltip-content"
}
const _hoisted_4 = {
  key: 1,
  class: "ml-4"
}
const _hoisted_5 = {
  key: 0,
  class: "tooltip-content"
}
const _hoisted_6 = {
  key: 1,
  class: "ml-4"
}
const _hoisted_7 = {
  key: 0,
  class: "tooltip-content"
}
const _hoisted_8 = {
  key: 1,
  class: "ml-4"
}
const _hoisted_9 = {
  key: 0,
  class: "tooltip-content"
}
const _hoisted_10 = {
  key: 1,
  class: "ml-4"
}
const _hoisted_11 = {
  key: 0,
  class: "tooltip-content"
}
const _hoisted_12 = {
  key: 1,
  class: "ml-4"
}
const _hoisted_13 = {
  key: 0,
  class: "tooltip-content"
}
const _hoisted_14 = {
  key: 1,
  class: "ml-4"
}
const _hoisted_15 = {
  key: 0,
  src: _imports_2,
  class: "icon icon-small polymer"
}
const _hoisted_16 = {
  key: 1,
  src: _imports_3,
  class: "icon icon-small polymer"
}
const _hoisted_17 = {
  key: 2,
  class: "tooltip-content"
}
const _hoisted_18 = {
  key: 3,
  class: "ml-4"
}
const _hoisted_19 = {
  key: 0,
  class: "tooltip-content"
}
const _hoisted_20 = {
  key: 1,
  class: "ml-4"
}
const _hoisted_21 = {
  key: 0,
  class: "tooltip-content"
}
const _hoisted_22 = {
  key: 1,
  class: "ml-4"
}
const _hoisted_23 = { key: 0 }
const _hoisted_24 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_25 = {
  key: 0,
  class: "sidebar-bottom"
}
const _hoisted_26 = { class: "user-detail" }
const _hoisted_27 = { class: "initials-icon" }
const _hoisted_28 = {
  key: 0,
  class: "user-name-mail"
}
const _hoisted_29 = { class: "name" }

import { ArrowRightOnRectangleIcon, ChevronDoubleLeftIcon, PresentationChartBarIcon, AcademicCapIcon, TableCellsIcon, WrenchScrewdriverIcon, ClipboardDocumentCheckIcon, QuestionMarkCircleIcon, Cog6ToothIcon, PlayPauseIcon,CpuChipIcon } from '@heroicons/vue/24/outline'
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import router from '@/router';
import { useInitialsToUserLogo } from '@/composables/useInitialsToUserLogo';
import ModalRequestForm from '@/components/ModalRequestForm.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SidebarMenu',
  setup(__props) {

const store = useStore();
const user = computed(() => { return store.state.authModule.user });
const collapsed = computed(() => store.getters['authModule/isSidebarCollapsed']);
const toggleSidebar = () => store.commit('authModule/toogleSidebar');
const isOpen = ref(false);

const isUserAllowToSeeExtraInfo = computed(() => store.getters['authModule/isUserAllowToSeeExtraInfo'])

const closeModal = () => isOpen.value = false;
const openModal = () => {
    if (!collapsed.value) {
        toggleSidebar();
    }
    isOpen.value = true;
};

const returnFirst = (names: string) => {
    const spacePosition = names.indexOf(' ');
    return spacePosition === -1 ? names : names.slice(0, spacePosition);
}
const userName = computed(() => returnFirst(user.value.firstName));
const lastName = computed(() => returnFirst(user.value.lastName));
const email = computed(() => user.value.email)
const initials = computed(() => useInitialsToUserLogo(userName?.value, lastName?.value))
const handleLogout = (async () => {
    await store.dispatch('authModule/logout')
    router.push('/')
})


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sidebar", { collapsed: collapsed.value }])
  }, [
    (collapsed.value)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _imports_0,
          alt: "one-five-logo",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'TechnologyAssetsRepo' })))
        }))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _imports_1,
          alt: "one-five-logo",
          class: "logo-trademark",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: 'TechnologyAssetsRepo' })))
        })),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, {
          to: "/playground",
          class: "flex items-center tooltip-wrapper"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(PlayPauseIcon), { class: "icon icon-small" }),
            (collapsed.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Playground"))
              : _createCommentVNode("", true),
            (!collapsed.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, "Playground"))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: "/product-briefs",
          class: "flex items-center tooltip-wrapper"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(ClipboardDocumentCheckIcon), { class: "icon icon-small" }),
            (collapsed.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Product Briefs"))
              : _createCommentVNode("", true),
            (!collapsed.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_6, "Product Briefs"))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (isUserAllowToSeeExtraInfo.value)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: "/programmes",
              class: "flex items-center tooltip-wrapper"
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(Cog6ToothIcon), { class: "icon icon-small" }),
                (collapsed.value)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, "Programmes"))
                  : _createCommentVNode("", true),
                (!collapsed.value)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_8, "Programmes"))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_router_link, {
          to: "/programmes-wip",
          class: "flex items-center tooltip-wrapper"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(WrenchScrewdriverIcon), { class: "icon icon-small" }),
            (collapsed.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, "Programmes wip"))
              : _createCommentVNode("", true),
            (!collapsed.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_10, "Programmes wip"))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: "/research-projects",
          class: "flex items-center tooltip-wrapper"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(AcademicCapIcon), { class: "icon icon-small" }),
            (collapsed.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_11, "Research Projects"))
              : _createCommentVNode("", true),
            (!collapsed.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_12, "Research Projects"))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: "/technology-assets-repo",
          class: "flex items-center tooltip-wrapper"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(TableCellsIcon), { class: "icon icon-small" }),
            (collapsed.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_13, "Technology Assets Repo"))
              : _createCommentVNode("", true),
            (!collapsed.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_14, "Technology Assets Repo"))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: "/polymers",
          class: "flex items-center tooltip-wrapper"
        }, {
          default: _withCtx(() => [
            (collapsed.value)
              ? (_openBlock(), _createElementBlock("img", _hoisted_15))
              : _createCommentVNode("", true),
            (!collapsed.value)
              ? (_openBlock(), _createElementBlock("img", _hoisted_16))
              : _createCommentVNode("", true),
            (collapsed.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_17, "Polymers Repository"))
              : _createCommentVNode("", true),
            (!collapsed.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_18, "Polymers Repository"))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: "/tea-dashboard",
          class: "flex items-center tooltip-wrapper"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(PresentationChartBarIcon), { class: "icon icon-small" }),
            (collapsed.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_19, "TEA"))
              : _createCommentVNode("", true),
            (!collapsed.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_20, "TEA"))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: "/ai",
          class: "flex items-center tooltip-wrapper"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(CpuChipIcon), { class: "icon icon-small" }),
            (collapsed.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_21, "AI"))
              : _createCommentVNode("", true),
            (!collapsed.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_22, "Artificial Intelligence"))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createElementVNode("div", {
          class: "flex items-center tooltip-wrapper",
          onClick: openModal
        }, [
          (collapsed.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                _createVNode(_unref(QuestionMarkCircleIcon), { class: "question icon-small" }),
                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "clippy-tooltip tooltip-content" }, [
                  _createTextVNode("Send Request"),
                  _createElementVNode("img", {
                    class: "clippy-white",
                    src: _imports_4
                  })
                ], -1))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_24, _cache[3] || (_cache[3] = [
                _createElementVNode("img", {
                  class: "clippy-gray",
                  src: _imports_5
                }, null, -1),
                _createElementVNode("p", { class: "clippy-request" }, "Send request", -1)
              ]))),
          _createVNode(ModalRequestForm, {
            isOpen: isOpen.value,
            onCloseModal: closeModal
          }, null, 8, ["isOpen"])
        ])
      ]),
      (user.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
            _createVNode(_unref(ChevronDoubleLeftIcon), {
              class: _normalizeClass(["collapse-icon icon thick", { 'rotate-180': collapsed.value }]),
              onClick: toggleSidebar
            }, null, 8, ["class"]),
            _createElementVNode("div", _hoisted_26, [
              _createElementVNode("div", _hoisted_27, _toDisplayString(initials.value), 1),
              (!collapsed.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                    _createElementVNode("p", _hoisted_29, _toDisplayString(userName.value) + " " + _toDisplayString(lastName.value), 1),
                    _createElementVNode("p", null, _toDisplayString(email.value), 1)
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_unref(ArrowRightOnRectangleIcon), {
                onClick: handleLogout,
                class: "logout icon icon-small"
              })
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})