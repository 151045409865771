export const ASSET_TYPES = {
    PATENT: {
      key: 'PATENT',
      graphqlValue: 'patent',
      urlValue: 'patent', 
      displayValue: 'Patent',
      allQueryName: 'patents',
    },
    RESEARCH_PAPER: {
      key: 'RESEARCH_PAPER',
      graphqlValue: 'researchPaper',
      urlValue: 'research_paper', 
      displayValue: 'Research paper',
      allQueryName: 'researchPapers',
    },
    RESEARCH_PROJECT: {
      key: 'RESEARCH_PROJECT',
      graphqlValue: 'researchProject',
      urlValue: 'research_project', 
      displayValue: 'External Opportunity',
      allQueryName: 'researchProjects',
    },
    OTHER: {
      key: 'OTHER',
      graphqlValue: 'otherTechnology',
      urlValue: 'other', 
      displayValue: 'Commercial & Other',
      allQueryName: 'otherTechnologies',
    }
    
  }
  
