import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "design-feedback" }
const _hoisted_2 = {
  key: 0,
  class: "feedback-cloud"
}
const _hoisted_3 = { class: "initials-icon" }

import { designFeedbackInterface } from '@/models/programsInterface';
import {PropType, computed } from 'vue'; 
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'LayersDesignFeedback',
  props: {
    prototypeDesignId: Number,
    allFeedbacks: {type: Array as PropType<designFeedbackInterface[]>},
},
  setup(__props) {



const route = useRoute();
const programId = computed(() => route.params.id)


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.allFeedbacks, (feed, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
          (feed.designId == __props.prototypeDesignId && feed.programId == programId.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(feed.who), 1),
                _createElementVNode("p", null, _toDisplayString(feed.feedback), 1)
              ]))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ])
  ]))
}
}

})