<template>
    <div class="flex justify-center px-20">
        <button  v-if="!showChangeForm" class="button cancel mx-4 active:shadow-inner" @click="handleConfirm">
            <FaceSmileIcon class="icon icon-small inline-block " /> continue with response
        </button>
        <button  v-if="!showChangeForm" class="button cancel mx-4 active:shadow-inner" @click="handleChange">
            <PencilSquareIcon class="icon icon-small inline-block"/> change some variables
        </button>

        <div v-if="showChangeForm" class="border border-gray-200 shadow-md rounded p-4 mb-4 w-full">
            <div class="mb-2" :class="{ 'error-form-field': errorFields.includes('paperContentPercentage') && formSubmited }">
                <label class="block">Paper Content %</label>
                <input type="number" class="w-full" v-model="vModels['paperContentPercentage'].value" min="0" max="100" />
            </div>
            <div class="mb-2 mt-5">
                <div class="flex">
                    <button @click="handleAsk" class="ml-auto py-2 px-5 rounded-none  hover:shadow-md active:shadow-inner button cancel" type="button">
                        <PlayIcon class="icon icon-small" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { FaceSmileIcon, PencilSquareIcon, PlayIcon  } from '@heroicons/vue/24/outline';
import { computed, defineProps,ref } from 'vue';
import { useStore } from 'vuex';
import { REGULATIONS_MODIFICATION_FORM_FIELDS } from '@/models/aiModels';
import { isNullOrEmptyArray } from "@/utils/utils";


const store = useStore();

const handleConfirm = async ()=>{
    await store.dispatch("aiModule/askRegulationsConfirm");
}

const showChangeForm = ref(false);
const handleChange = ()=>{
    showChangeForm.value = true;
}


// Create Fields for the form
const fieldsFromConstant = computed<string[]>(() => REGULATIONS_MODIFICATION_FORM_FIELDS.formFields.map(formField =>  { return formField.mutationValue} ));
const formFieldOptions = {
    fields: fieldsFromConstant.value,
    base: REGULATIONS_MODIFICATION_FORM_FIELDS.graphqlValue,
    mutation: 'aiModule/setRegulationsModifySingleValue',
};
const formFields = (formFieldOptions:{fields:string[], base:string, mutation:string}) => {
    const object = {};
    formFieldOptions.fields.map(fieldName => {
            object[fieldName] = computed({
                get() {
                    return store.state.aiModule.ask.regulationsModify[fieldName];
                },
                set(value) {
                    store.commit(formFieldOptions.mutation, { field: fieldName , value: value});
                }
            })
    });
    return object;
}
const vModels = formFields(formFieldOptions);


const formSubmited = ref(false);
const valuesSubmitted = computed(() => store.state.aiModule.ask.regulationsModify);
const formIsValid = computed(() => REGULATIONS_MODIFICATION_FORM_FIELDS.mandatoryFormFields.every(mf => {
    return !isNullOrEmptyArray(valuesSubmitted.value[mf]);
}));
const errorFields = computed(() => REGULATIONS_MODIFICATION_FORM_FIELDS.mandatoryFormFields.filter(mf => {
    return isNullOrEmptyArray(valuesSubmitted.value[mf]);
}) );


const handleAsk = async () => {
    formSubmited.value = true;
    if(formIsValid.value){
        formSubmited.value=false;
        await store.dispatch("aiModule/askRegulationsModify");
    }
}

</script>