<template v-if="fields && errorsFields && formSubmited">
    <div class="p-4">
      <FormErrorToast :is-showing="showErrors" title="The following fields are mandatory:" :subtitle="errorsFields.join(', ')" />
      <p style="max-width: 500px;"><FormErrorToast :is-showing="(queryError?true:false)" title="Error" :subtitle="queryError" /></p>
      <div class="grid gap-y-3 gap-x-4 mt-6 mb-6">
        <div v-for="(field, index) in fields" :key="index">
          <div v-if="field.type === 'textarea'" class="col-span-2 grid">
            <label>{{ field.label }}<span v-if="field.required">*</span></label>
            <textarea
              class="w-full"
              v-model="vModels[field.mutationValue].value"
            ></textarea>
          </div>
          <div v-else class="col-span-2" :class="{ 'error-form-field': errorsFields.includes(field.mutationValue) && formSubmited }">
            <label>{{ field.label }} <span v-if="field.required">*</span> </label>
            <input v-if="field.type === 'text'" class="w-full" type="text" v-model="vModels[field.mutationValue].value" />
          </div>
        </div>
      </div>
    </div>
  </template>
  
<script setup lang="ts">
import { NEW_RUN_GROUP_FIELDS } from "@/tea/models/runGroup";
import { computed, defineProps } from "vue";
import { useStore } from "vuex";
import FormErrorToast from "@/components/FormErrorToast.vue";

const store = useStore();
const props = defineProps({
  errorsFields: Array,
  formIsValid: Boolean,
  formSubmited: Boolean,
  queryError: String,
});
const showErrors = computed(() => !props.formIsValid && props.formSubmited);

// Create Fields for the form
const fields = computed<{ label: string; type: string; mutationValue: string; queryValue: string; required: boolean }[]>(
  () => NEW_RUN_GROUP_FIELDS.formFields
);

const fieldsFromConstant = computed<string[]>(() =>
  NEW_RUN_GROUP_FIELDS.formFields.map((formField) => {
    return formField.mutationValue;
  })
);

const formFieldOptions = {
  fields: fieldsFromConstant.value,
  base: NEW_RUN_GROUP_FIELDS.graphqlValue,
  mutation: "runGroupModule/SET_CREATE_SINGLE_RUN_GROUP_VALUE",
};

const formFields = (formFieldOptions: { fields: string[]; base: string; mutation: string }) => {
  const object = {};
  formFieldOptions.fields.map((fieldName) => {
    object[fieldName] = computed({
      get() {
        return store.state.runGroupModule.create.runGroup[fieldName];
      },
      set(value) {
        store.commit(formFieldOptions.mutation, { runGroupField: fieldName, value: value });
      },
    });
  });
  return object;
};

const vModels = formFields(formFieldOptions);
</script>
