<template>
  <div class="col-span-4">
    <div class="grid grid-cols-4 gap-y-4 gap-x-4">
      <!-- Organism -->
      <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('organism') && formSubmited }">
        <label>Organism *</label>
        <select class="w-full" v-model="formFields['organism'].value">
          <option selected :value="null"></option>
          <option v-for="organism in formInizializationValues['organismsOptions']" :value="Number(organism.id)" :key="organism.id">
            {{ organism.name }}
          </option>
        </select>
      </div>

      <!-- Cultivation System -->
      <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('cultivationSystem') && formSubmited }">
        <label>Cultivation System *</label>
        <select class="w-full" v-model="formFields['cultivationSystem'].value">
          <option selected :value="null"></option>
          <option
            v-for="cultivationSystem in formInizializationValues['cultivationSystemsOptions']"
            :value="Number(cultivationSystem.id)"
            :key="cultivationSystem.id"
          >
            {{ cultivationSystem.name }}
          </option>
        </select>
      </div>

      <div v-if="ocsGoodCombo == false" class="col-span-4 bg-red-100 p-5 rounded-md">
        <p class="text-red-700">
          <ExclamationTriangleIcon class="icon icon-small inline-block red" /> The organism you selected cannot be cultivated in the cultivation system you
          selected. Please choose a more suitable one.
        </p>
      </div>
      <div v-else-if="ocsGoodCombo == true" class="col-span-4 grid grid-cols-3 gap-y-4 gap-x-4">
        <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('compoundPct') && formSubmited }">
          <label>Target compound (% dry weight biomass) *</label>
          <input class="w-full" type="number" min="0.00" max="100.00" v-model="formFields['compoundPct'].value" />
          <span class="text-xs text-gray-400"
            >Note: if you chose Solid-state fermentor (SSF) as cultivation system the term 'biomass' stands for 'substrate'</span
          >
        </div>

        <div v-if="isSystemSSF == true" class="col-span-4 grid grid-cols-3 gap-y-4 gap-x-4">
          <div class="col-span-3" :class="{ 'error-form-field': errorsFields.includes('biomassTarget') && formSubmited }">
            <label>I know the target compound or substrate capacity? *</label>
            <select class="w-full" v-model="formFields['biomassTarget'].value">
              <option v-for="biomassTarget in formInizializationValues['biomassTargetsOptions']" :value="biomassTarget.id" :key="biomassTarget.id">
                {{ biomassTarget.name }}
              </option>
            </select>
          </div>

          <div
            v-if="fields.biomassTarget == 'Target compound'"
            class="col-span-3"
            :class="{ 'error-form-field': errorsFields.includes('targetCompoundCapacity') && formSubmited }"
          >
            <label>Target compound capacity (ton/year dry weight) *</label>
            <input class="w-full" type="number" min="0.00" max="100.00" v-model="formFields['targetCompoundCapacity'].value" />
          </div>
          <div
            v-else-if="fields.biomassTarget == 'Substrate'"
            class="col-span-3"
            :class="{ 'error-form-field': errorsFields.includes('biomass') && formSubmited }"
          >
            <label>Substrate capacity (ton/year dry weight) *</label>
            <input class="w-full" type="number" min="0.00" max="100.00" v-model="formFields['biomass'].value" />
          </div>

          <div class="col-span-3" :class="{ 'error-form-field': errorsFields.includes('costBiomass') && formSubmited }">
            <label>Price substrate (€/ton) *</label>
            <input class="w-full" type="number" v-model="formFields['costBiomass'].value" />
          </div>
        </div>
        <div v-else-if="isSystemSSF == false" class="col-span-4 grid grid-cols-3 gap-y-4 gap-x-4">
          <div class="col-span-3" :class="{ 'error-form-field': errorsFields.includes('targetCompoundCapacity') && formSubmited }">
            <label>Target compound capacity (ton/year dry weight) *</label>
            <input class="w-full" type="number" min="0.00" max="100.00" v-model="formFields['targetCompoundCapacity'].value" />
          </div>
        </div>

        <div v-if="showFirstInfo" class="col-span-4 bg-gray-100 p-3 rounded-md">
          <p v-if="fields.biomassTarget == 'Target compound'" class="text-sm text-black py-2">
            <NewspaperIcon class="icon icon-small inline-block" /> Considering your target compound production you will need
            {{ calcTargetCompoundPerYear }} tons of biomass per year
          </p>
          <p v-if="fields.biomassTarget == 'Substrate'" class="text-sm text-black py-2">
            <NewspaperIcon class="icon icon-small inline-block" /> Considering your substrate availability you will be able to have
            {{ calcSubstratePerYear }} tons of target compound per year
          </p>
        </div>

        <RadioGroup
          v-model="formFields['bycompounds'].value"
          class="col-span-4 mb-0"
          :class="{ 'error-form-field': errorsFields.includes('bycompounds') && formSubmited }"
        >
          <RadioGroupLabel class="mb-1">How many bycompounds are you going to have?</RadioGroupLabel>
          <div class="flex w-full items-center justify-stretch">
            <RadioGroupOption as="template" v-for="bycompound in bycompounds" :key="bycompound" :value="bycompound" v-slot="{ active, checked }">
              <div
                :class="[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white ']"
                class="relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"
              >
                <div class="flex w-full items-center justify-between">
                  <div class="flex items-center">
                    <div class="text-sm">
                      <RadioGroupLabel as="p" :class="checked ? 'text-white' : 'text-gray-500'" class="font-base">
                        {{ bycompound }}
                      </RadioGroupLabel>
                    </div>
                  </div>
                  <div v-show="checked" class="shrink-0 text-white items-right">
                    <svg class="h-5 w-5" viewBox="0 0 24 24" fill="none">
                      <circle cx="12" cy="12" r="12" fill="#fff" fill-opacity="0.2" />
                      <path d="M7 13l3 3 7-7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>

        <div v-if="showbycompoundX" class="col-span-4 flex border border-gray-100 border-solid p-1 pt-3 pb-0 -mt-3 bg-gray-50">
          <div v-if="showbycompoundX" class="grow mb-4" :class="{ 'error-form-field': errorsFields.includes('bycompoundXPct') && formSubmited }">
            <label>Bycompound x (% dry weight biomass)</label>
            <input class="w-full" type="number" v-model="formFields['bycompoundXPct'].value" />
          </div>

          <div v-if="showbycompoundY" class="grow mb-4 ml-4" :class="{ 'error-form-field': errorsFields.includes('bycompoundYPct') && formSubmited }">
            <label>Bycompound y (% dry weight biomass)</label>
            <input class="w-full" type="number" v-model="formFields['bycompoundYPct'].value" />
          </div>

          <div v-if="showbycompoundZ" class="grow mb-4 ml-4" :class="{ 'error-form-field': errorsFields.includes('bycompoundZPct') && formSubmited }">
            <label>Bycompound z (% dry weight biomass)</label>
            <input class="w-full" type="number" v-model="formFields['bycompoundZPct'].value" />
          </div>
        </div>

        <!-- ########land and land estimation######## -->
        <RadioGroup
          v-model="formFields['landCapex'].value"
          class="col-span-4 mb-0 mt-1"
          :class="{ 'error-form-field': errorsFields.includes('landCapex') && formSubmited }"
        >
          <RadioGroupLabel class="mb-1">Are you buying or renting the land? *</RadioGroupLabel>
          <div class="flex w-full items-center justify-stretch">
            <RadioGroupOption as="template" v-for="landCapex in landCapexes" :key="landCapex.key" :value="landCapex.key" v-slot="{ active, checked }">
              <div
                :class="[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white ']"
                class="relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"
              >
                <div class="flex w-full items-center justify-between">
                  <div class="flex items-center">
                    <div class="text-sm">
                      <RadioGroupLabel as="p" :class="checked ? 'text-white' : 'text-gray-500'" class="font-base">
                        {{ landCapex.value }}
                      </RadioGroupLabel>
                    </div>
                  </div>
                  <div v-show="checked" class="shrink-0 text-white items-right">
                    <svg class="h-5 w-5" viewBox="0 0 24 24" fill="none">
                      <circle cx="12" cy="12" r="12" fill="#fff" fill-opacity="0.2" />
                      <path d="M7 13l3 3 7-7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>

        <div
          v-if="isSystemGreenhouse != true"
          class="col-span-4 border border-gray-100 border-solid p-1 pt-3 pb-0 -mt-3 bg-gray-50"
          :class="{ 'error-form-field': errorsFields.includes('land') && formSubmited }"
        >
          <label>Estimated volume per hectare (m3) *</label>
          <input class="w-full" type="number" v-model="formFields['land'].value" />
          <span class="text-xs text-gray-500 py-2 block"
            ><LightBulbIcon class="icon icon-small inline-block" /> If you have no idea 5,000 m3/ha is a good estimation!</span
          >
        </div>
        <div
          v-else
          class="col-span-4 border border-gray-100 border-solid p-1 pt-3 pb-0 -mt-3 bg-gray-50"
          :class="{ 'error-form-field': errorsFields.includes('land') && formSubmited }"
        >
          <label>Estimated plant density per hectare (#plants/ha) *</label>
          <input class="w-full" type="number" v-model="formFields['land'].value" />
          <span class="text-xs text-gray-500 py-2 block"
            ><LightBulbIcon class="icon icon-small inline-block" /> If you have no idea 350,000 plants/ha is a good estimation!</span
          >
        </div>

        <!-- ########sugar and light depending on the organism - i) sugar_conc, ii) other_sugar_price (if valid), iii) light_yn, iv) hs_light######## -->

        <div v-if="isOrganismAutotroph == true" class="col-span-4 grid grid-cols-3 gap-y-4 gap-x-4">
          <RadioGroup
            v-model="formFields['artificialLight'].value"
            class="col-span-4 mb-0 mt-1"
            :class="{ 'error-form-field': errorsFields.includes('artificialLight') && formSubmited }"
          >
            <RadioGroupLabel class="mb-1">Are you going to use artificial light? *</RadioGroupLabel>
            <div class="flex w-full items-center justify-stretch">
              <RadioGroupOption as="template" v-for="yesNo in yesNos" :key="yesNo.value" :value="yesNo.key" v-slot="{ active, checked }">
                <div
                  :class="[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white ']"
                  class="relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"
                >
                  <div class="flex w-full items-center justify-between">
                    <div class="flex items-center">
                      <div class="text-sm">
                        <RadioGroupLabel as="p" :class="checked ? 'text-white' : 'text-gray-500'" class="font-base">
                          {{ yesNo.value }}
                        </RadioGroupLabel>
                      </div>
                    </div>
                    <div v-show="checked" class="shrink-0 text-white items-right">
                      <svg class="h-5 w-5" viewBox="0 0 24 24" fill="none">
                        <circle cx="12" cy="12" r="12" fill="#fff" fill-opacity="0.2" />
                        <path d="M7 13l3 3 7-7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </div>
                  </div>
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>

          <div
            v-if="fields.artificialLight == true"
            class="col-span-4 border border-gray-100 border-solid p-1 pt-3 pb-0 -mt-3 bg-gray-50"
            :class="{ 'error-form-field': errorsFields.includes('hsLight') && formSubmited }"
          >
            <label>Light period (hours per day) *</label>
            <input class="w-full" type="number" v-model="formFields['hsLight'].value" />
          </div>
        </div>
        <div v-else-if="isOrganismHetrotroph == true && isSystemSSF != true" class="col-span-4 grid grid-cols-3 gap-y-4 gap-x-4">
          <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('sugarConc') && formSubmited }">
            <label>What is the preferred sugar concentration? (kg/kg biomass) *</label>
            <input class="w-full" type="number" v-model="formFields['sugarConc'].value" />
            <span class="text-xs text-gray-500 py-2 block"
              ><LightBulbIcon class="icon icon-small inline-block" /> The reference price for sugar is 0.44 €/kg. If this reference does not correspond please
              select "Another price"</span
            >
          </div>
          <div class="col-span-4 flex items-center" :class="{ 'error-form-field': errorsFields.includes('isOtherSugarPrice') && formSubmited }">
            <label class="inline-block mr-2">Another Price</label>
            <Switch
              v-model="formFields['isOtherSugarPrice'].value"
              :class="formFields['isOtherSugarPrice'].value ? 'bg-emerald-700' : 'bg-gray-200'"
              class="relative inline-flex h-6 w-11 items-center rounded-full"
            >
              <span class="sr-only">Another Price</span>
              <span
                :class="formFields['isOtherSugarPrice'].value ? 'translate-x-6' : 'translate-x-1'"
                class="inline-block h-4 w-4 transform rounded-full bg-white transition"
              ></span>
            </Switch>
          </div>

          <div
            v-if="fields.isOtherSugarPrice == true"
            class="col-span-4"
            :class="{ 'error-form-field': errorsFields.includes('otherSugarPrice') && formSubmited }"
          >
            <label>Price sugar? (€/kg) *</label>
            <input class="w-full" type="number" v-model="formFields['otherSugarPrice'].value" />
          </div>
        </div>
        <div v-else-if="isOrganismMixotroph == true" class="col-span-4 grid grid-cols-3 gap-y-4 gap-x-4">
          <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('sugarConc') && formSubmited }">
            <label>What is the preferred sugar concentration? (kg/kg biomass) *</label>
            <input class="w-full" type="number" v-model="formFields['sugarConc'].value" />
            <span class="text-xs text-gray-500 py-2 block"
              ><LightBulbIcon class="icon icon-small inline-block" /> The reference price for sugar is 0.44 €/kg. If this reference does not correspond please
              select "Another price"</span
            >
          </div>
          <div class="col-span-4 flex items-center" :class="{ 'error-form-field': errorsFields.includes('isOtherSugarPrice') && formSubmited }">
            <label class="inline-block mr-2">Another Price</label>
            <Switch
              v-model="formFields['isOtherSugarPrice'].value"
              :class="formFields['isOtherSugarPrice'].value ? 'bg-emerald-700' : 'bg-gray-200'"
              class="relative inline-flex h-6 w-11 items-center rounded-full"
            >
              <span class="sr-only">Another Price</span>
              <span
                :class="formFields['isOtherSugarPrice'].value ? 'translate-x-6' : 'translate-x-1'"
                class="inline-block h-4 w-4 transform rounded-full bg-white transition"
              ></span>
            </Switch>
          </div>

          <div
            v-if="fields.isOtherSugarPrice == true"
            class="col-span-4"
            :class="{ 'error-form-field': errorsFields.includes('otherSugarPrice') && formSubmited }"
          >
            <label>Price sugar? (€/kg) *</label>
            <input class="w-full" type="number" v-model="formFields['otherSugarPrice'].value" />
          </div>

          <RadioGroup
            v-model="formFields['artificialLight'].value"
            class="col-span-4 mb-0 mt-1"
            :class="{ 'error-form-field': errorsFields.includes('artificialLight') && formSubmited }"
          >
            <RadioGroupLabel class="mb-1">Are you going to use artificial light? *</RadioGroupLabel>
            <div class="flex w-full items-center justify-stretch">
              <RadioGroupOption as="template" v-for="yesNo in yesNos" :key="yesNo.value" :value="yesNo.key" v-slot="{ active, checked }">
                <div
                  :class="[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white ']"
                  class="relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"
                >
                  <div class="flex w-full items-center justify-between">
                    <div class="flex items-center">
                      <div class="text-sm">
                        <RadioGroupLabel as="p" :class="checked ? 'text-white' : 'text-gray-500'" class="font-base">
                          {{ yesNo.value }}
                        </RadioGroupLabel>
                      </div>
                    </div>
                    <div v-show="checked" class="shrink-0 text-white items-right">
                      <svg class="h-5 w-5" viewBox="0 0 24 24" fill="none">
                        <circle cx="12" cy="12" r="12" fill="#fff" fill-opacity="0.2" />
                        <path d="M7 13l3 3 7-7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </div>
                  </div>
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>

          <div
            v-if="fields.artificialLight == true"
            class="col-span-4 border border-gray-100 border-solid p-1 pt-3 pb-0 -mt-3 bg-gray-50"
            :class="{ 'error-form-field': errorsFields.includes('hsLight') && formSubmited }"
          >
            <label>Light period (hours per day) *</label>
            <input class="w-full" type="number" v-model="formFields['hsLight'].value" />
          </div>
        </div>

        <!-- ########heating cooling######## -->
        <RadioGroup
          v-model="formFields['heatCoolSystem'].value"
          class="col-span-4 mb-0 mt-1"
          :class="{ 'error-form-field': errorsFields.includes('heatCoolSystem') && formSubmited }"
        >
          <RadioGroupLabel class="mb-1">Are you going to use cooling/heating systems? *</RadioGroupLabel>
          <div class="flex w-full items-center justify-stretch">
            <RadioGroupOption as="template" v-for="yesNo in yesNos" :key="yesNo.value" :value="yesNo.key" v-slot="{ active, checked }">
              <div
                :class="[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white ']"
                class="relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"
              >
                <div class="flex w-full items-center justify-between">
                  <div class="flex items-center">
                    <div class="text-sm">
                      <RadioGroupLabel as="p" :class="checked ? 'text-white' : 'text-gray-500'" class="font-base">
                        {{ yesNo.value }}
                      </RadioGroupLabel>
                    </div>
                  </div>
                  <div v-show="checked" class="shrink-0 text-white items-right">
                    <svg class="h-5 w-5" viewBox="0 0 24 24" fill="none">
                      <circle cx="12" cy="12" r="12" fill="#fff" fill-opacity="0.2" />
                      <path d="M7 13l3 3 7-7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>
        <div
          v-if="fields.heatCoolSystem == true"
          class="col-span-4 border border-gray-100 border-solid p-1 pt-3 pb-0 -mt-3 bg-gray-50"
          :class="{ 'error-form-field': errorsFields.includes('hsHeatCool') && formSubmited }"
        >
          <label>Cooling/heating period (hours per day) *</label>
          <input class="w-full" type="number" v-model="formFields['hsHeatCool'].value" />
        </div>

        <!-- ########operating days and cycle length######## -->
        <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('operatingDays') && formSubmited }">
          <label>Operating days (in one year) *</label>
          <input class="w-full" type="number" v-model="formFields['operatingDays'].value" />
        </div>

        <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('cycleLength') && formSubmited }">
          <label>Cycle duration (days) *</label>
          <input class="w-full" type="number" v-model="formFields['cycleLength'].value" />
        </div>

        <!-- ########supplements######## -->
        <RadioGroup
          v-model="formFields['suppliments'].value"
          class="col-span-4 mb-0"
          :class="{ 'error-form-field': errorsFields.includes('suppliments') && formSubmited }"
        >
          <RadioGroupLabel class="mb-1">How many specific supplements are you going to use (i.e. vitamins)? *</RadioGroupLabel>
          <div class="flex w-full items-center justify-stretch">
            <RadioGroupOption as="template" v-for="suppliment in suppliments" :key="suppliment" :value="suppliment" v-slot="{ active, checked }">
              <div
                :class="[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white ']"
                class="relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"
              >
                <div class="flex w-full items-center justify-between">
                  <div class="flex items-center">
                    <div class="text-sm">
                      <RadioGroupLabel as="p" :class="checked ? 'text-white' : 'text-gray-500'" class="font-base">
                        {{ suppliment }}
                      </RadioGroupLabel>
                    </div>
                  </div>
                  <div v-show="checked" class="shrink-0 text-white items-right">
                    <svg class="h-5 w-5" viewBox="0 0 24 24" fill="none">
                      <circle cx="12" cy="12" r="12" fill="#fff" fill-opacity="0.2" />
                      <path d="M7 13l3 3 7-7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>
        <div v-if="showsupplimentX" class="col-span-4 flex border border-gray-100 border-solid p-1 pt-3 pb-0 -mt-3 bg-gray-50">
          <div v-if="showsupplimentX" class="grow grid grid-cols-1 gap-y-4 gap-x-4">
            <div class="col-span-1" :class="{ 'error-form-field': errorsFields.includes('supplimentXConc') && formSubmited }">
              <label>Concentration supplement x (kg/kg biomass or L/kg biomass) *</label>
              <input class="w-full" type="number" v-model="formFields['supplimentXConc'].value" />
            </div>
            <div class="col-span-1" :class="{ 'error-form-field': errorsFields.includes('supplimentXPrice') && formSubmited }">
              <label>Price supplement x (€/kg or €/L) *</label>
              <input class="w-full" type="number" v-model="formFields['supplimentXPrice'].value" />
            </div>
          </div>
          <div v-if="showsupplimentY" class="grow grid grid-cols-1 gap-y-4 gap-x-4 ml-4">
            <div class="col-span-1" :class="{ 'error-form-field': errorsFields.includes('supplimentYConc') && formSubmited }">
              <label>Concentration supplement y (kg/kg biomass or L/kg biomass) *</label>
              <input class="w-full" type="number" v-model="formFields['supplimentYConc'].value" />
            </div>
            <div class="col-span-1" :class="{ 'error-form-field': errorsFields.includes('supplimentYPrice') && formSubmited }">
              <label>Price supplement y (€/kg or €/L) *</label>
              <input class="w-full" type="number" v-model="formFields['supplimentYPrice'].value" />
            </div>
          </div>
          <div v-if="showsupplimentZ" class="grow grid grid-cols-1 gap-y-4 gap-x-4 ml-4">
            <div class="col-span-1" :class="{ 'error-form-field': errorsFields.includes('supplimentZConc') && formSubmited }">
              <label>Concentration supplement z (kg/kg biomass or L/kg biomass) *</label>
              <input class="w-full" type="number" v-model="formFields['supplimentZConc'].value" />
            </div>
            <div class="col-span-1" :class="{ 'error-form-field': errorsFields.includes('supplimentZPrice') && formSubmited }">
              <label>Price supplement z (€/kg or €/L) *</label>
              <input class="w-full" type="number" v-model="formFields['supplimentZPrice'].value" />
            </div>
          </div>
        </div>

        <!-- ##### Harvesting -->

        <div class="form-separator col-span-4"><p class="form-subtitle">Harvesting</p></div>

        <div v-if="!isSystemGreenhouse && !isSystemSSF" class="col-span-4 grid grid-cols-3 gap-y-4 gap-x-4">
          <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('harvestingConc') && formSubmited }">
            <label>Culture concentration for harvesting (kg/m3 or g/L) *</label>
            <input class="w-full" type="number" v-model="formFields['harvestingConc'].value" />
          </div>
          <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('harvestingPct') && formSubmited }">
            <label>Harvesting (%) *</label>
            <input class="w-full" type="number" v-model="formFields['harvestingPct'].value" />
          </div>
        </div>
        <div v-else-if="isSystemGreenhouse" class="col-span-4 grid grid-cols-3 gap-y-4 gap-x-4">
          <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('harvestingConc') && formSubmited }">
            <label>Plant aerial biomass at harvesting (kg/plant) *</label>
            <input class="w-full" type="number" v-model="formFields['harvestingConc'].value" />
          </div>
          <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('harvestingPct') && formSubmited }">
            <label>Germination efficiency (%) *</label>
            <input class="w-full" type="number" v-model="formFields['harvestingPct'].value" />
          </div>
        </div>
        <div v-else-if="isSystemSSF" class="col-span-4 grid grid-cols-3 gap-y-4 gap-x-4">
          <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('harvestingConc') && formSubmited }">
            <label>Substrate occupancy (kg/m3) *</label>
            <input class="w-full" type="number" v-model="formFields['harvestingConc'].value" />
            <span class="text-xs text-gray-500 py-2 block"
              ><LightBulbIcon class="icon icon-small inline-block" /> If you have no idea 400 kg/m3 is a good estimation!</span
            >
          </div>
          <!-- harvestingPct=100 -->
        </div>

        <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('facilityUsagePct') && formSubmited }">
          <label>Facility usage (%) *</label>
          <input class="w-full" type="number" v-model="formFields['facilityUsagePct'].value" />
        </div>

        <div v-if="showSecondInfo" class="col-span-4 bg-gray-100 p-3 py-5 rounded-md">
          <p v-if="!isSystemGreenhouse">
            <NewspaperIcon class="icon icon-small inline-block" />
            You will need <b>{{ calcVolumeRealPerCycle.volume_real_per_cyc }}</b> m3 per cycle, and considering the harvesting and facility usage %s you will
            need <b>{{ calcVolumeRealPerCycle.towrite[0] }}</b> {{ calcVolumeRealPerCycle.towrite[1] }} {{ selectedCultivationSystem }} and
            <b>{{ calcVolumeRealPerCycle.towrite2[0] }}</b> {{ calcVolumeRealPerCycle.towrite2[1] }}
          </p>
          <p v-else>
            <NewspaperIcon class="icon icon-small inline-block" /> Considering your target compound production you will need
            <b>{{ calcVolumeRealPerCycle.towrite2[0] }}</b> {{ calcVolumeRealPerCycle.towrite2[1] }}
          </p>
        </div>

        <!-- ########harvesting methods######## -->
        <div v-if="!isSystemSSF" class="col-span-4 grid grid-cols-4 gap-y-4 gap-x-4">
          <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('harvestMethod') && formSubmited }">
            <label>Which harvesting method are you going to use? *</label>
            <select class="w-full" v-model="formFields['harvestMethod'].value">
              <option selected :value="null"></option>
              <option v-for="harvestMethod in formInizializationValues['harvestingMethodsOptions']" :value="Number(harvestMethod.id)" :key="harvestMethod.id">
                {{ harvestMethod.name }}
              </option>
            </select>
          </div>

          <!-- ########check if combinations are fine######## -->
          <div v-if="shmGoodCombo == false" class="col-span-4 bg-red-100 p-5 rounded-md">
            <ExclamationTriangleIcon class="icon icon-small inline-block red" /> The organism you selected cannot be harvested with the harvesting method you
            selected. Please choose a more suitable one.
          </div>
          <div v-else class="col-span-4">
            <!-- ########flocculants######## -->
            <div v-if="isHarvestingMethodFlocculation" class="w-full grid grid-cols-4 gap-y-4 gap-x-4">
              <RadioGroup
                v-model="formFields['flocculant'].value"
                class="col-span-4 mb-0"
                :class="{ 'error-form-field': errorsFields.includes('flocculant') && formSubmited }"
              >
                <RadioGroupLabel class="mb-1">Which flocculant are you going to use? *</RadioGroupLabel>
                <div class="flex w-full items-center justify-stretch">
                  <RadioGroupOption
                    as="template"
                    v-for="flocculant in formInizializationValues['flocculantsOptions']"
                    :key="flocculant.id"
                    :value="flocculant.id"
                    v-slot="{ active, checked }"
                  >
                    <div
                      :class="[active ? 'ring-offset-emerald-600' : 'ring-offset-gray-100', checked ? 'bg-emerald-600 bg-opacity-75 text-white ' : 'bg-white ']"
                      class="relative flex cursor-pointer px-4 py-1 shadow-md focus:outline-none w-full ring-2 ring-white ring-opacity-60 ring-offset-2"
                    >
                      <div class="flex w-full items-center justify-between">
                        <div class="flex items-center">
                          <div class="text-sm">
                            <RadioGroupLabel as="p" :class="checked ? 'text-white' : 'text-gray-500'" class="font-base">
                              {{ flocculant.name }}
                            </RadioGroupLabel>
                          </div>
                        </div>
                        <div v-show="checked" class="shrink-0 text-white items-right">
                          <svg class="h-5 w-5" viewBox="0 0 24 24" fill="none">
                            <circle cx="12" cy="12" r="12" fill="#fff" fill-opacity="0.2" />
                            <path d="M7 13l3 3 7-7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </RadioGroupOption>
                </div>
              </RadioGroup>
              <div v-if="fields.flocculant !== null" class="col-span-4 flex border border-gray-100 border-solid p-1 pt-3 pb-1 -mt-3 mb-3 bg-gray-50">
                <div v-if="isFlocculantAluminium" class="grow grid grid-cols-1 gap-y-4 gap">
                  <p class="text-xs text-gray-500 py-2 block">
                    <LightBulbIcon class="icon icon-small inline-block" />
                    Aluminium is used at a concentration of 0.27 kg/kg biomass and has the price of 1.5 €/kg. If these references do not correspond please
                    select "Another flocculant"
                  </p>
                </div>
                <div v-else-if="isFlocculantChitosan" class="grow grid grid-cols-1 gap-y-4 gap-x-4">
                  <p class="text-xs text-gray-500 py-2 block">
                    <LightBulbIcon class="icon icon-small inline-block" />
                    Chitosan is used at a concentration of 0.18 kg/kg biomass and has the price of 40 €/kg. If these references do not correspond please select
                    "Another flocculant"
                  </p>
                </div>
                <div v-else-if="isFlocculantAnotherFlocculant" class="grow grid grid-cols-1 gap-y-4 gap-x-4">
                  <div class="col-span-1" :class="{ 'error-form-field': errorsFields.includes('otherFlocculantConc') && formSubmited }">
                    <label>Concentration flocculant (kg/kg biomass) *</label>
                    <input class="w-full" type="number" v-model="formFields['otherFlocculantConc'].value" />
                  </div>
                  <div class="col-span-1" :class="{ 'error-form-field': errorsFields.includes('otherFlocculantPrice') && formSubmited }">
                    <label>Price flocculant (€/kg) *</label>
                    <input class="w-full" type="number" v-model="formFields['otherFlocculantPrice'].value" />
                  </div>
                </div>
              </div>
            </div>

            <div v-if="isHarvestingMethodFlocculation || isHarvestingMethodCFF" class="col-span-4">
              <div class="col-span-4" :class="{ 'error-form-field': errorsFields.includes('concFactorHarvesting') && formSubmited }">
                <label>Concentration factor harvesting *</label>
                <input class="w-full" type="number" v-model="formFields['concFactorHarvesting'].value" />
                <span class="text-xs text-gray-500 py-2 block"
                  ><LightBulbIcon class="icon icon-small inline-block" /> As concentraction factor 80 is a good estimation!</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, defineProps } from "vue";
import { useFormFieldsCreationNewRun } from "@/tea/composables/runs/useFormFieldsCreationNewRun";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { useStore } from "vuex";
import { NEW_RUN_FIELDS } from "@/tea/models/run";
import { RadioGroup, RadioGroupLabel, RadioGroupOption, Switch } from "@headlessui/vue";
import { NewspaperIcon, ExclamationTriangleIcon, LightBulbIcon } from "@heroicons/vue/24/outline";
const bycompounds = [0, 1, 2, 3];
const landCapexes = [
  { key: "Buying", value: "Buying" },
  { key: "Renting", value: "Renting" },
];
const yesNos = [
  { key: true, value: "Yes" },
  { key: false, value: "No" },
];
const suppliments = [0, 1, 2, 3];

const store = useStore();

const props = defineProps(["errorsFields", "formSubmited", "formInizializationValues"]);

const fields = computed(() => store.getters["runCreateModule/fields"]);
const ocsGoodCombo = computed(() => {
  const combo = [fields.value.organism, fields.value.cultivationSystem];
  if (combo[0] != null && combo[1] != null) {
    const isGoodCombo = props.formInizializationValues["ocsGoodCombos"].some((tuple) => tuple[0] === combo[0] && tuple[1] === combo[1]);
    return isGoodCombo;
  }
  return null;
});
const shmGoodCombo = computed(() => {
  const species = props.formInizializationValues["cultivationSystemsOptions"].find((item) => item.id == fields.value.cultivationSystem).species.id;
  const combo = [species, fields.value.harvestMethod];
  if (combo[0] != null && combo[1] != null) {
    const isGoodCombo = props.formInizializationValues["shmGoodCombos"].some((tuple) => tuple[0] === combo[0] && tuple[1] === combo[1]);
    return isGoodCombo;
  }
  return null;
});
const isSystemSSF = computed(() => {
  const csId = fields.value.cultivationSystem;
  if (props.formInizializationValues["cultivationSystemsOptions"] && csId != null) {
    if (props.formInizializationValues["cultivationSystemsOptions"].find((item) => item.name == "Solid-state fermentor (SSF)").id == csId) {
      return true;
    } else {
      // set biomass_target to 'Target compound'
      return false;
    }
  }
  return null;
});

const isSystemGreenhouse = computed(() => {
  const csId = fields.value.cultivationSystem;
  if (props.formInizializationValues["cultivationSystemsOptions"] && csId != null) {
    if (props.formInizializationValues["cultivationSystemsOptions"].find((item) => item.name == "Greenhouse").id == csId) {
      return true;
    } else {
      // set biomass_target to 'Target compound'
      return false;
    }
  }
  return null;
});

const isOrganismAutotroph = computed(() => {
  const oId = fields.value.organism;
  if (props.formInizializationValues["organismsOptions"] && oId != null) {
    if (props.formInizializationValues["organismsOptions"].find((item) => item.name == "Autotroph").id == oId) {
      return true;
    } else {
      // set biomass_target to 'Target compound'
      return false;
    }
  }
  return null;
});

const isOrganismHetrotroph = computed(() => {
  const oId = fields.value.organism;
  if (props.formInizializationValues["organismsOptions"] && oId != null) {
    if (props.formInizializationValues["organismsOptions"].find((item) => item.name == "Heterotroph").id == oId) {
      return true;
    } else {
      // set biomass_target to 'Target compound'
      return false;
    }
  }
  return null;
});
const isOrganismMixotroph = computed(() => {
  const oId = fields.value.organism;
  if (props.formInizializationValues["organismsOptions"] && oId != null) {
    if (props.formInizializationValues["organismsOptions"].find((item) => item.name == "Mixotroph").id == oId) {
      return true;
    } else {
      // set biomass_target to 'Target compound'
      return false;
    }
  }
  return null;
});

const showFirstInfo = computed(() => {
  if (ocsGoodCombo.value && fields.value.compoundPct) {
    if (fields.value.biomassTarget == "Target compound" && fields.value.targetCompoundCapacity) {
      return true;
    } else if (fields.value.biomassTarget == "Substrate" && fields.value.biomass) {
      return true;
    }
  }
  if (ocsGoodCombo.value && fields.value.targetCompoundCapacity) {
    return true;
  }
  return false;
});

const showbycompoundX = computed(() => {
  const bc = Number(fields.value.bycompounds);
  if (props.formInizializationValues["organismsOptions"] && bc > 0) {
    return true;
  }
  return false;
});
const showbycompoundY = computed(() => {
  const bc = Number(fields.value.bycompounds);
  if (props.formInizializationValues["organismsOptions"] && bc > 1) {
    return true;
  }
  return false;
});
const showbycompoundZ = computed(() => {
  const bc = Number(fields.value.bycompounds);
  if (props.formInizializationValues["organismsOptions"] && bc == 3) {
    return true;
  }
  return false;
});
const showsupplimentX = computed(() => {
  const s = Number(fields.value.suppliments);
  if (props.formInizializationValues["organismsOptions"] && s > 0) {
    return true;
  }
  return false;
});
const showsupplimentY = computed(() => {
  const s = Number(fields.value.suppliments);
  if (props.formInizializationValues["organismsOptions"] && s > 1) {
    return true;
  }
  return false;
});
const showsupplimentZ = computed(() => {
  const s = Number(fields.value.suppliments);
  if (props.formInizializationValues["organismsOptions"] && s == 3) {
    return true;
  }
  return false;
});

const calcTargetCompoundPerYear = computed(() => {
  const tcc = fields.value.targetCompoundCapacity;
  const cpct = fields.value.compoundPct;
  return Math.round(tcc / (cpct / 100));
});
const calcSubstratePerYear = computed(() => {
  const biomass = fields.value.biomass;
  const cpct = fields.value.compoundPct;
  return Math.round(biomass * (cpct / 100));
});

const showSecondInfo = computed(() => {
  if (fields.value.land && fields.value.harvestingConc) {
    return true;
  }
  return false;
});
const calcVolumeRealPerCycle = computed(() => {
  const n_cycles = fields.value.operatingDays / fields.value.cycleLength;
  const biomass = fields.value.targetCompoundCapacity / (fields.value.compoundPct / 100);

  if (!isSystemGreenhouse.value) {
    const volume_real_per_cyc = (biomass * 1000) / n_cycles / fields.value.harvestingConc;
    const volume_tot = (biomass * 1000) / n_cycles / fields.value.harvestingConc / (fields.value.harvestingPct / 100) / (fields.value.facilityUsagePct / 100);
    const land_estim = volume_tot / fields.value.land;
    let towrite,
      towrite2 = [];
    if (volume_tot >= 1000) {
      towrite = [Math.round(volume_tot / 1000), "1,000 liters"];
    } else if (volume_tot >= 100 && volume_tot < 1000) {
      towrite = [Math.round(volume_tot / 100), "100 liters"];
    } else {
      towrite = [Math.round(volume_tot / 10), "10 liters"];
    }
    if (land_estim > 0.1) {
      towrite2 = [Number(land_estim).toFixed(1), "hectares of land"];
    } else {
      towrite2 = [Number(land_estim * 10000).toFixed(1), "m2 of land"];
    }

    return { volume_real_per_cyc: Math.round(volume_real_per_cyc), towrite: towrite, towrite2: towrite2 };
  } else {
    const volume =
      (biomass * 1000) /
      n_cycles /
      fields.value.harvestingConc /
      (fields.value.harvestingPct / 100) /
      (fields.value.facilityUsagePct / 100) /
      fields.value.land;
    let towrite2 = [];
    if (volume > 0.1) {
      towrite2 = [Number(volume).toFixed(1), "hectares of land"];
    } else {
      towrite2 = [Number(volume * 10000).toFixed(1), "m2 of land"];
    }

    return { towrite2: towrite2 };
  }
});

const isHarvestingMethodFlocculation = computed(() => {
  const fId = fields.value.harvestMethod;
  if (props.formInizializationValues["harvestingMethodsOptions"] && fId != null) {
    if (props.formInizializationValues["harvestingMethodsOptions"].find((item) => item.name == "Flocculation").id == fId) {
      return true;
    } else {
      return false;
    }
  }
  return null;
});
const isHarvestingMethodCFF = computed(() => {
  const fId = fields.value.harvestMethod;
  if (props.formInizializationValues["harvestingMethodsOptions"] && fId != null) {
    if (props.formInizializationValues["harvestingMethodsOptions"].find((item) => item.name == "Cross flow filtration").id == fId) {
      return true;
    } else {
      return false;
    }
  }
  return null;
});

const selectedCultivationSystem = computed(() => {
  if (props.formInizializationValues["cultivationSystemsOptions"]) {
    return props.formInizializationValues["cultivationSystemsOptions"].find((item) => item.id == fields.value.cultivationSystem).name;
  }
  return null;
});

const isFlocculantAluminium = computed(() => {
  const fId = fields.value.flocculant;
  if (props.formInizializationValues["flocculantsOptions"] && fId != null) {
    if (props.formInizializationValues["flocculantsOptions"].find((item) => item.name == "Aluminium").id == fId) {
      return true;
    } else {
      return false;
    }
  }
  return null;
});
const isFlocculantChitosan = computed(() => {
  const fId = fields.value.flocculant;
  if (props.formInizializationValues["flocculantsOptions"] && fId != null) {
    if (props.formInizializationValues["flocculantsOptions"].find((item) => item.name == "Chitosan").id == fId) {
      return true;
    } else {
      return false;
    }
  }
  return null;
});
const isFlocculantAnotherFlocculant = computed(() => {
  const fId = fields.value.flocculant;
  if (props.formInizializationValues["flocculantsOptions"] && fId != null) {
    if (props.formInizializationValues["flocculantsOptions"].find((item) => item.name == "Another flocculant").id == fId) {
      return true;
    } else {
      return false;
    }
  }
  return null;
});

const getFormFields = Object.keys(NEW_RUN_FIELDS.formFields).map((key) => {
  const fieldConfig = NEW_RUN_FIELDS.formFields[key];
  return {
    [key]: Array.isArray(fieldConfig.onChangeResetFields) ? fieldConfig.onChangeResetFields : [],
  };
});
const formFieldOptions = {
  fields: getFormFields,
  mutation: "runCreateModule/SET_SINGLE_RUN_VALUE",
};
const formFields = useFormFieldsCreationNewRun(formFieldOptions);
</script>
