import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/one-five-blue-trademark.png'


const _hoisted_1 = { class: "welcome-container" }
const _hoisted_2 = { class: "second-half" }

import GoogleLogin from "../components/GoogleLogin.vue";  

export default /*@__PURE__*/_defineComponent({
  __name: 'WelcomeLoginView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "first-half" }, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "one-five logo",
        class: "logo"
      }),
      _createElementVNode("p", { class: "dsp" }, [
        _createElementVNode("span", null, "Q"),
        _createTextVNode("uartermaster")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("p", null, "Please sign in to continue", -1)),
      _createVNode(GoogleLogin)
    ])
  ]))
}
}

})