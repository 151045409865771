const formatRunGroup = (data) => {
  const formatedRunGroup = {
    id: data.id,
    name: data.name,
    description: data.description,
  };
  return formatedRunGroup;
};

export const useRawRunGroupToStateType = (rawData: undefined) => {
  return formatRunGroup(rawData);
};
