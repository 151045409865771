import { createApp, provide, h } from "vue";
import App from "@/App.vue";
import vue3GoogleLogin from "vue3-google-login";
import store from "./store";
import router from "./router";
import './assets/scss/tailwind.css';
import apolloClient from '@/utils/apolloClient';
// import { DefaultApolloClient } from "@vue/apollo-composable";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { createHead } from "@unhead/vue";
import { VueRecaptchaPlugin } from "vue-recaptcha";
import domain from "@/utils/appDomain";
import '@cyhnkckali/vue3-color-picker/dist/style.css';
import { ApolloClients } from '@vue/apollo-composable'
import { hasuraApolloClient } from "./utils/hasuraApolloClient";

const gauthClientId:string = process.env.VUE_APP_GOOGLE_CLIENT_ID;
const gauthOptions = {
  clientId: gauthClientId,
  idConfiguration: null,
  buttonConfig: null,
  error: null, 
  callback: null
}

const app = createApp({
  setup () {
    provide(ApolloClients, {
      default: apolloClient,
      hasuraClient: hasuraApolloClient
    })
    // provide(DefaultApolloClient, apolloClient)
  },
  render: () => h(App),
})

app
  .use(store)
  .use(router)
  .use(vue3GoogleLogin, gauthOptions)
  .use(Toast, {
    transition: "Vue-Toastification__fade",
    maxToasts: 20,
    newestOnTop: true
  })
if(domain.isTea){
  app
  .use(createHead())
  .use(VueRecaptchaPlugin, {
    v2SiteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY,
  })
}

app.mount("#app");


