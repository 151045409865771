import { useMutation } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { provideApolloClient } from "@vue/apollo-composable";
import apolloClient from "@/utils/apolloClient";
import { POSITION, useToast } from 'vue-toastification';
import { OtherTechnologyAssetType, PatentAssetType, ResearchPaperAssetType } from "@/models/technologyAssetInterface";
import { useRawAssetResponseToStateType } from "@/composables/useRawAssetResponseToStateType";
import { ASSETS_QUERY } from "@/grapql/assetsQuery";
import { ASSET_FILE_DELETE } from "@/grapql/assetFileDelete";
import { uploadFiles } from "@/composables/useUploadFiles";

provideApolloClient(apolloClient);
const toast = useToast()

const getDefaultState = () => { return { 
    asset: {
        patent: null,
        researchPaper: null,
        researchProject: null,
        otherTechnology: null
    },
    assetType: null,
    edditableMode: null,
    errorsDuringMutation: {
        patent: null,
        researchPaper: null,
        researchProject: null,
        otherTechnology: null
    },
    filesToRemove: [],
    filesToAdd: [],
    showFilesUploadLoader: false,
    }
}
const state = getDefaultState();

const mutations = { 
    setEditableModeOn(state) {
        state.edditableMode = true
    },
    setEditableModeOff(state) {
        state.edditableMode = false
    },
    async setAssetValues(state, payload:{data, assetType: string }){
        state.edditableMode = false;
        state.assetType = payload.assetType;
        state.asset[payload.assetType] = useRawAssetResponseToStateType(payload.data, payload.assetType);
        console.log(state.asset[payload.assetType])
    },
    updateAssetValue(state, payload:{mutationValue:string, content:string}) {
        state.asset[state.assetType][payload.mutationValue] = payload.content; 
    },
    resetValues(state) {
        Object.assign(state, getDefaultState());
    },
    setErrorOnCreationResponse(state, payload:{type:string, error:string}) {
        if (payload.error.includes('already exists')) {
            state.errorsDuringMutation[payload.type] = 'This asset already exists'
        }
    },
    setFilesToRemove(state, payload:[number]) {
        state.filesToRemove = payload;
    },
    setFilesToAdd(state, payload) {
        state.filesToAdd = payload;
    },
    toggleShowFilesUploadLoader(state) {
        state.showFilesUploadLoader = !state.showFilesUploadLoader;
    }
    
}

const actions = { 

    // REMOVE FILES WHEN NEEDED
   async removeFiles({state}) {
        if (state.filesToRemove.length) {
            const { mutate: assetFileDelete } = useMutation(ASSET_FILE_DELETE); 
            state.filesToRemove.map(async fileId => {
                await assetFileDelete({ input: { id: fileId } })})
        }
   },

   // ADD FILES TO ASSET
   async addFiles({state, commit}) {
        if(state.filesToAdd.length) {
            await commit('toggleShowFilesUploadLoader');
           await uploadFiles(state.filesToAdd, state.asset[state.assetType].id);
        }
   },

    // CHANGE TO PATENT UPDATE
    async patentUpdate({state, commit, dispatch}) {
        return new Promise<void>((resolve, reject) => {
            const { mutate: patentUpdate, onDone, onError } = useMutation(gql`
                mutation patentUpdate($input: PatentUpdateMutationInput! ) {
                    patentUpdate (input: $input) {
                        patent { id }
                    }
                } `, { refetchQueries: [{ query: ASSETS_QUERY}] } );
            try {
                const patentInput: PatentAssetType = Object.assign({}, state.asset['patent']);
                patentUpdate({input: patentInput});
                onDone( async ()  => { 
                    await dispatch('addFiles').then(() =>  commit('toggleShowFilesUploadLoader'));
                    await dispatch('removeFiles');
                    await apolloClient.refetchQueries({include: ['getPatent']}).then(
                        () => toast.success("Patent Updated", { position: POSITION.BOTTOM_LEFT, timeout: 1524 }));
                   
                    await commit('setEditableModeOff')
                    resolve();
                });
                onError((error) => commit('setErrorOnCreationResponse', {type: 'patent', error: error.message}) )
            } catch(error) {
                console.log(error);
                reject();
            }
        })
        
    }, 

    async researchPaperUpdate({state, commit, dispatch}) {
        await dispatch('addFiles');
        await dispatch('removeFiles');
        return new Promise<void>((resolve, reject) => {
            const { mutate: researchPaperUpdate, onDone } = useMutation(gql`
                mutation researchPaperUpdate($input: ResearchPaperUpdateMutationInput! ) {
                    researchPaperUpdate (input: $input) {
                        researchPaper { id }
                    }
                } `, { refetchQueries: [{ query: ASSETS_QUERY}] } );
            try {
                dispatch('removeFiles');
                const researchPaperInput: ResearchPaperAssetType = Object.assign({}, state.asset['researchPaper']);
                researchPaperUpdate({input: researchPaperInput});
                onDone(async (result) => { 
                    await dispatch('addFiles');
                    await dispatch('removeFiles');
                    await apolloClient.refetchQueries({include: ['getResearchPaper']}).then(
                        () => toast.success("Patent Updated", { position: POSITION.BOTTOM_LEFT, timeout: 1524 }));
                   
                    await commit('setEditableModeOff')
                    resolve();
                })
            } catch(error) {
                console.log(error);
                reject();
            }
        })
    },

    async researchProjectUpdate({state, commit, dispatch}) {
        await dispatch('addFiles');
        await dispatch('removeFiles');
        return new Promise<void>((resolve, reject) => {
            const { mutate: researchProjectUpdate, onDone } = useMutation(gql`
                mutation researchProjectUpdate($input: ResearchProjectUpdateMutationInput! ) {
                    researchProjectUpdate (input: $input) {
                        researchProject { id }
                    }
                } `, { refetchQueries: [{ query: ASSETS_QUERY}] } );
            try {
                dispatch('removeFiles');
                const researchProjectInput: ResearchPaperAssetType = Object.assign({}, state.asset['researchProject']);
                researchProjectUpdate({input: researchProjectInput});
                onDone(async (result) => { 
                    await dispatch('addFiles');
                    await dispatch('removeFiles');
                    await apolloClient.refetchQueries({include: ['getResearchProject']}).then(
                        () => toast.success("Patent Updated", { position: POSITION.BOTTOM_LEFT, timeout: 1524 }));
                   
                    await commit('setEditableModeOff')
                    resolve();
                })
            } catch(error) {
                console.log(error);
                reject();
            }
        })
    },

    async otherTechnologyUpdate({state, commit, dispatch}) {
        await dispatch('addFiles');
        await dispatch('removeFiles');
        return new Promise<void>((resolve, reject) => {
            const { mutate: otherTechnologyUpdate, onDone } = useMutation(gql`
                mutation otherTechnologyUpdate($input: OtherTechnologyUpdateMutationInput! ) {
                    otherTechnologyUpdate (input: $input) {
                        otherTechnology { id }
                    }
                } `, { refetchQueries: [{ query: ASSETS_QUERY}] } );
                try {
                    dispatch('removeFiles');
                    const otherTechnologyInput: OtherTechnologyAssetType = Object.assign({}, state.asset['otherTechnology']);
                    otherTechnologyUpdate({input: otherTechnologyInput});
                    onDone(async (result) => { 
                        await dispatch('addFiles');
                        await dispatch('removeFiles');
                        await apolloClient.refetchQueries({include: ['getOtherTechnology']}).then(
                            () => toast.success("Patent Updated", { position: POSITION.BOTTOM_LEFT, timeout: 1524 }));
                    
                        await commit('setEditableModeOff')
                        resolve();
                    })
                } catch(error) {
                    console.log(error);
                    reject();
                }
            })
    }



}
const getters = { 
    isAssetEditable(state) {
        return state.edditableMode
    },
    getAssetType(state) { 
        return state.assetType 
    },
    getAssetValues(state) {
        return state.asset[state.assetType]
    },
    getShowFilesUploadLoader(state) {
        return state.showFilesUploadLoader
    }
}

export default {
    namespaced: true,
    state, 
    mutations,
    getters,
    actions
}
