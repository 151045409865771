<template>
    <div>
        <div v-if="dataToShow && dataToShow.length > 0" class="pt-4 pb-6 mx-3">
            <div class="flex justify-end">
                <div class="w-3/5"></div>
                <div class="w-1/5 table-title text-right mr-5">Target</div>
                <div v-if="isUserAllowToSeeExtraInfo" class="w-1/5 table-title text-left ml-5">Current</div>
            </div>
            <div class="flex flex-col border-b last:border-0" v-for="dataValue in dataToShow" :key="dataValue.id">
                <div class="flex items-center pt-2 pb-1 leading-4">
                    <div class="w-3/5 table-title">
                        <!-- Show target & material-->
                        <template v-if="dataValue.property">
                            {{ dataValue.property.name }}
                            <p class="target-info">{{ dataValue.property.className }} / {{ dataValue.property.category }}</p>
                        </template>
                    </div>
                    <div :class="isUserAllowToSeeExtraInfo ? 'w-1/5' : 'w-2/5'" class="text-right mr-5 font-medium">
                        <p v-if="dataValue.valueType.name == 'Between'" class="value"> {{ dataValue.value1 }} - {{ dataValue.value2 }}
                            <span v-if="dataValue.valueUnit" class="lowercase">
                                {{ dataValue.valueUnit.abbreviation }}
                            </span>
                        </p>
                        <template v-else-if="dataValue.valueType.name == 'Boolean'">
                            <p v-if="dataValue.value1 && dataValue.value1 == 'true'" class="value">Yes</p>
                            <p v-else class="value">No</p>
                        </template>
                        <p v-else class="value">{{ dataValue.valueType.symbol }} {{ dataValue.value1 }}
                            <span v-if="dataValue.valueUnit" class="lowercase">
                                {{ dataValue.valueUnit.abbreviation }}
                            </span>
                        </p>
                    </div>
                    <!-- Current Value -->
                    <div v-if="isUserAllowToSeeExtraInfo && targetData" class="w-1/5 font-medium ml-5 text-left">
                        <p v-if="dataValue.currentValue !== null"> {{ dataValue.currentValue }}</p>
                        <p v-else class="value-tbd">TBD</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="empty-box no-value"></div>
    </div>
</template>

<script setup lang="ts">

import { defineProps, computed } from 'vue';
import { TargetInterface } from '@/models/programVersionsInterface';
import { MaterialInterface } from '@/models/productBriefsInterface';
import { useStore } from 'vuex';


export interface TableDataInterface {
    id: number,
    property: {
        id: number,
        name: string,
        className: string,
        category: string,
    }
    value1: string,
    value2?: string | null
    valueType: {
        id: number,
        name: string,
        symbol: string,
        description: string,
    },
    valueUnit: {
        id: number,
        abbreviation: string,
        name: string
    }
    currentValue?: string
}
const props = defineProps<{
    materialData?: MaterialInterface[],
    targetData?: TargetInterface[]
}>()

const store = useStore();
const dataToShow = computed<TableDataInterface[]>(() => props.materialData ? parsedMaterialData.value : props.targetData ? parsedTargetData.value : []);

const parsedMaterialData = computed(() => props.materialData && props.materialData.length > 0 ? props.materialData.map(({ material, ...rest }) => ({ property: material, ...rest })) : [])

const parsedTargetData = computed(() => props.targetData && props.targetData.length > 0 ? props.targetData.map(({ targetProperty, targetValue1, targetValue2, targetValueType, targetValueUnit, ...rest }) => ({
    property: targetProperty,
    value1: targetValue1,
    value2: targetValue2,
    valueType: targetValueType,
    valueUnit: targetValueUnit,
    ...rest
})) : [])

const isUserAllowToSeeExtraInfo = computed(() => store.getters['authModule/isUserAllowToSeeExtraInfo'])

</script>

<style lang="scss" scoped>
.table-title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9rem;
    color: $label-blue-strong;
}

.target-info {
    font-weight: 300;
    font-size: 0.8rem;
    color: $font-color-super-light;
    text-transform: capitalize;
}

.value,
.value-tbd {
    font-size: 0.9rem;
    color: $font-color-light;
}
</style>