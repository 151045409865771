import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/images/one-five-icon-white.png'
import _imports_1 from '@/assets/images/one-five-blue-trademark.png'


const _hoisted_1 = { class: "sidebar-content" }
const _hoisted_2 = { class: "sidebar-links" }
const _hoisted_3 = {
  key: 0,
  class: "tooltip-content"
}
const _hoisted_4 = {
  key: 1,
  class: "ml-4"
}
const _hoisted_5 = {
  key: 0,
  class: "sidebar-user"
}
const _hoisted_6 = { class: "user-detail" }
const _hoisted_7 = { class: "initials-icon" }
const _hoisted_8 = {
  key: 0,
  class: "user-name-mail"
}
const _hoisted_9 = { class: "name custom-truncate-w-42" }
const _hoisted_10 = { class: "custom-truncate-w-42" }

import { ArrowRightOnRectangleIcon, ChevronDoubleLeftIcon, PresentationChartBarIcon } from "@heroicons/vue/24/outline";
import { useStore } from "vuex";
import { computed } from "vue";
import router from "@/router";
import { useInitialsToUserLogo } from "@/tea/composables/useInitialsToUserLogo";


export default /*@__PURE__*/_defineComponent({
  __name: 'SidebarMenu',
  setup(__props) {

const store = useStore();
const user = computed(() => {
  return store.state.authModule.user;
});
const collapsed = computed(() => store.getters["authModule/isSidebarCollapsed"]);
const toggleSidebar = () => store.commit("authModule/toogleSidebar");

const returnFirst = (names: string) => {
  const spacePosition = names.indexOf(" ");
  return spacePosition === -1 ? names : names.slice(0, spacePosition);
};
const fullName = computed(()=> user.value.fullName);
const firstName = computed(() => returnFirst(user.value.firstName));
const lastName = computed(() => returnFirst(user.value.lastName));
const email = computed(() => user.value.email);
const initials = computed(() => useInitialsToUserLogo(firstName?.value, lastName?.value));
const handleLogout = async () => {
  await store.dispatch("authModule/logout");
  router.push("/");
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sidebar", { collapsed: collapsed.value }])
  }, [
    _createVNode(_unref(ChevronDoubleLeftIcon), {
      class: _normalizeClass(["icon thick collapse-icon", { 'rotate-180': collapsed.value }]),
      onClick: toggleSidebar
    }, null, 8, ["class"]),
    (collapsed.value)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _imports_0,
          alt: "one-five-logo",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'Tea.Home' })))
        }))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _imports_1,
          alt: "one-five-logo",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: 'Tea.Home' })))
        })),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, {
          to: "/dashboard",
          class: "flex items-center tooltip-wrapper"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(PresentationChartBarIcon), { class: "icon icon-small" }),
            (collapsed.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Dashboard"))
              : _createCommentVNode("", true),
            (!collapsed.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, "Dashboard"))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      (user.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(initials.value), 1),
              (!collapsed.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("p", _hoisted_9, _toDisplayString(fullName.value), 1),
                    _createElementVNode("p", _hoisted_10, _toDisplayString(email.value), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_unref(ArrowRightOnRectangleIcon), {
              onClick: handleLogout,
              class: "icon icon-small"
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})