import { IChatHistroy, IChatMessage, IQuestion,IAnswer,IError } from "@/models/aiInterfaces";
import { ContentType, MessageType } from "@/models/aiModels";

export const useEdgesToChats = (edges) => {
    return edges.map(edge =>{
        const chat:IChatHistroy = {
            id: edge.node.id,
            name: edge.node.name,
            subscriptionId: edge.node.subscriptionId,
            messages: [],
        };

        return chat;

    });
}
export const useEdgesToMessages = (edges) => {
    console.log(edges);
    
    // Initialize a map to group messages by their messageId
    const messageMap = new Map();

    // Process each message
    edges.forEach(edge => {
        const { messageId, messageType, content,contentType } = edge.node;

        // create unique id for grouping
        const groupId = messageId + contentType;

        // Initialize the group if not already present
        if (!messageMap.has(groupId)) {
            messageMap.set(groupId, {
                messageId:null,
                messageType: null,
                question: null,
                answer:[],
                error: null,
            });
        }

        // Group messages based on their type
        const messageGroup = messageMap.get(groupId);
        messageGroup.messageId = messageId;
        messageGroup.messageType = messageType;

        switch (messageType) {
            case MessageType.QUESTION:
                messageGroup.question = {content:content};
                break;
            case MessageType.ANSWER:
                messageGroup.answer.push({content:content,contentType:contentType});
                break;
            case MessageType.ERROR:
                messageGroup.error = {content:content};
                break;
            default:
                console.warn(`Unknown message type: ${messageType}`);
                break;
        }
    });

    // Convert the map to an array
    const result = Array.from(messageMap.values());
    return result;
}

