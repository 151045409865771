import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/icons/pacman.gif'


const _hoisted_1 = { class: "loader-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }


  
export default /*@__PURE__*/_defineComponent({
  __name: 'LoaderPage',
  props: ['loaderText', 'gifPosition'],
  setup(__props) {

  const props = __props

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["loader-page", __props.gifPosition])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)),
      (__props.loaderText)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(__props.loaderText), 1))
        : (_openBlock(), _createElementBlock("p", _hoisted_3, "Uploading files and data"))
    ])
  ], 2))
}
}

})