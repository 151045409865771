<template>
  <div class="sidebar" :class="{ collapsed: collapsed }">
    <ChevronDoubleLeftIcon class="icon thick collapse-icon" :class="{ 'rotate-180': collapsed }" @click="toggleSidebar" />
    <img v-if="collapsed" src="@/assets/images/one-five-icon-white.png" alt="one-five-logo" @click="$router.push({ name: 'Tea.Home' })" />
    <img v-else src="@/assets/images/one-five-blue-trademark.png" alt="one-five-logo" @click="$router.push({ name: 'Tea.Home' })" />
    <div class="sidebar-content">
      <div class="sidebar-links">
        <router-link to="/dashboard" class="flex items-center tooltip-wrapper">
          <PresentationChartBarIcon class="icon icon-small" />
          <span v-if="collapsed" class="tooltip-content">Dashboard</span>
          <p class="ml-4" v-if="!collapsed">Dashboard</p>
        </router-link>
      </div>

      <div class="sidebar-user" v-if="user">
        <div class="user-detail">
          <div class="initials-icon">{{ initials }}</div>
          <div v-if="!collapsed" class="user-name-mail">
            <p class="name custom-truncate-w-42">{{ fullName }}</p>
            <p class="custom-truncate-w-42">{{ email }}</p>
          </div>
        </div>
        <ArrowRightOnRectangleIcon @click="handleLogout" class="icon icon-small" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ArrowRightOnRectangleIcon, ChevronDoubleLeftIcon, PresentationChartBarIcon } from "@heroicons/vue/24/outline";
import { useStore } from "vuex";
import { computed } from "vue";
import router from "@/router";
import { useInitialsToUserLogo } from "@/tea/composables/useInitialsToUserLogo";

const store = useStore();
const user = computed(() => {
  return store.state.authModule.user;
});
const collapsed = computed(() => store.getters["authModule/isSidebarCollapsed"]);
const toggleSidebar = () => store.commit("authModule/toogleSidebar");

const returnFirst = (names: string) => {
  const spacePosition = names.indexOf(" ");
  return spacePosition === -1 ? names : names.slice(0, spacePosition);
};
const fullName = computed(()=> user.value.fullName);
const firstName = computed(() => returnFirst(user.value.firstName));
const lastName = computed(() => returnFirst(user.value.lastName));
const email = computed(() => user.value.email);
const initials = computed(() => useInitialsToUserLogo(firstName?.value, lastName?.value));
const handleLogout = async () => {
  await store.dispatch("authModule/logout");
  router.push("/");
};
</script>
<style lang="scss" scoped>
.sidebar {
  background-color: $grey-super-light;
  position: fixed;
  height: 100vh;
  box-shadow: $right-shadow;
  padding-top: 2.5rem;
  border-right: 1px solid $grey-medium;
  width: 280px;
  z-index: 99;

  img {
    padding: 1rem 2rem 2rem;
    cursor: pointer;
  }

  &.collapsed {
    background-color: $one-five-blue;
    width: 80px;

    img {
      padding: 0 0.5rem 2.5rem;
    }
    .clippy-white {
      padding-bottom: 0;
    }
    .sidebar-links a {
      justify-content: center;
      padding: 0.5rem;
      margin: 1rem;
      border-radius: 8px;

      svg {
        stroke: #fff;
      }

      &.router-link-active,
      &:hover {
        background-color: $blue-medium;
        transition: background-color 0.3s ease-out;
      }
    }

    .initials-icon {
      background: $blue-medium;
      color: $grey-super-light;
    }
  }
}

.sidebar-content {
  display: flex;
  height: calc(100% - 6rem);
  justify-content: space-between;
  flex-direction: column;
}

.sidebar-links {
  display: flex;
  flex-direction: column;
  color: $font-color-light;

  a {
    padding: 1.5rem 1rem;
    font-weight: 200;
    &.router-link-active {
      background-color: $grey-light;
      font-weight: 400;
    }
    .polymer {
      padding: 0;
      width: 1.5rem;
      height: 1.5rem;
      opacity: 0.7;
    }
  }
}
.sidebar-user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $grey-medium;
  padding: 1rem;

  .user-detail {
    display: flex;

    .user-name-mail {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $font-color-super-light;
      font-size: 0.8rem;
      line-height: 1.2;
      .name {
        font-size: 0.9rem;
        font-weight: 500;
      }
    }
  }
}
.collapse-icon {
  position: absolute;
  bottom: 8rem;
  left: 260px;
  transition: 0.2s linear;
  stroke: $one-five-blue;
  background: $grey-super-light;
  border: $border;
  border-radius: 50%;
  padding: 3px;
  margin-left: 4px;
  width: 2rem;
  height: 2rem;
}

.rotate-180 {
  transform: rotate(180deg);
  left: 20px;
  stroke: $grey-super-light;
  background: transparent;
  border: none;
  transition: 0.2s linear;
}
.question {
  stroke: $grey-medium;
  stroke-width: 1.5px;
  padding: 5px;
  margin: 20px 23px;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  &:hover {
    border-radius: 8px;
    background-color: $blue-medium;
    transition: background-color 0.3s ease-out;
  }
}
.clippy-request {
  position: relative;
  top: 70px;
  right: 40px;
  color: $font-color-light;
  font-weight: 200;
  cursor: pointer;
}
.clippy-gray {
  position: relative;
  top: 75px;
  right: 20px;
  height: 5rem;
  stroke: $grey-super-light;
}
.tooltip-wrapper {
  position: relative;

  .tooltip-content {
    background: $blue-medium;
    text-transform: uppercase;
    bottom: 6%;
    color: $grey-super-light;
    display: block;
    left: 57px;
    opacity: 0;
    padding: 4px 6px;
    pointer-events: none;
    position: absolute;
    width: auto;
    transform: translateY(10px);
    transition: opacity 0.25s ease-out 1.5s;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    border-radius: 8px;
    font-size: 0.8rem;
    font-weight: 400;
    visibility: hidden;
    &.clippy-tooltip {
      text-align: center;
      bottom: 30%;
      left: 65px;
    }

    &::after {
      border-top: solid transparent 8px;
      border-bottom: solid transparent 8px;
      border-right: solid #2a87d4 8px;
      bottom: 7px;
      content: " ";
      height: 0;
      left: 20px;
      margin-left: -26px;
      position: absolute;
      width: 0;
    }
  }
}
.tooltip-wrapper:hover .tooltip-content {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  transform: translateY(0px);
}

// Truncate user email if very long
.custom-truncate-w-42 {
  width: 10.5rem; /* 42 characters * 0.25rem (default spacing unit in Tailwind) */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
