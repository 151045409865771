import gql from 'graphql-tag';

export const ASSET_MATERIAL_UPDATE = gql`
    mutation assetMaterialUpdate($input: AssetMaterialUpdateMutationInput!) {
        assetMaterialUpdate (input: $input) {
            assetMaterial {
                id
            }
        }
    }`;

export const ASSET_PROCESSING_TECHNIQUE_UPDATE = gql`
    mutation assetProcessingTechniqueUpdate($input: AssetProcessingTechniqueUpdateMutationInput!) {
        assetProcessingTechniqueUpdate (input: $input) {
            assetProcessingTechnique {
                id
            }
        }
    }`;

export const ASSET_QUANTITATIVE_PROPERTY_UPDATE = gql`
mutation assetQuantitativePropertyUpdate($input: AssetQuantitativePropertyUpdateMutationInput!) {
    assetQuantitativePropertyUpdate (input: $input) {
        assetQuantitativeProperty {
            id
        }
    }
}`;

export const ASSET_QUANTITATIVE_PROPERTY_CREATE = gql`
mutation assetQuantitativePropertyCreate($input: AssetQuantitativePropertyCreateMutationInput!) {
    assetQuantitativePropertyCreate (input: $input) {
        assetQuantitativeProperty {
            id
        }
    }
}`;