<template>
    <div class="form-container">
        <form class="add-asset-form" v-if="formInizializationValues">
            <div class="grid grid-cols-3 gap-y-2.5 gap-x-4">
                <div class="form-separator col-span-3">
                    <p class="form-subtitle">Commercial & Other technology Info</p>
                </div>
                <div>
                    <label>Layer functionality  *</label>
                    <select class="w-full" v-model="formFields['targetLayers'].value" @change="handleNicknameProposal()">
                        <option selected value=null></option>
                        <option v-for="layer in formInizializationValues['layerTypesOptions']" :value="layer.id"
                            :key="layer.id">{{ layer.name }}</option>
                    </select>
                </div>
                <div>
                    <label>Materials <span>(One or more)</span></label>
                    <DropdownSearchableMultiple v-if="formInizializationValues['materialsOptions']"
                        :options-list="formInizializationValues['materialsOptions']"
                        v-model="formFields['materials'].value" />
                </div>
                <div :class="{ 'error-form-field': errorsFields.includes('otherTechnologyType') && formSubmited }">
                    <label>Type *</label>
                    <select class="w-full" v-model="formFields['otherTechnologyType'].value">
                        <option selected value=null></option>
                        <option v-for="technologyType in formInizializationValues['otherTechnologyTypesOptions']"
                            :value="Number(technologyType.id)" :key="technologyType.id">{{ technologyType.name }}</option>
                    </select>
                </div>
                <div class="col-span-2" :class="{ 'error-form-field': errorsFields.includes('nickname') && formSubmited }">
                    <label>Nickname *</label>
                    <input :disabled="nicknameDisable" class="w-full" type="text" required v-model="formFields['nickname'].value">
                </div>
                <div class="col-span-1 text-xs flex items-center mt-4 text-neutral-900 font-normal">
                    <img src="@/assets/icons/position.png" class="mr-2" />
                    <p>{{helpTextForNickname }}</p>
                </div>
               
                <div class="col-start-auto col-span-3"
                    :class="{ 'error-form-field': errorsFields.includes('title') && formSubmited }">
                    <label>Title *</label>
                    <input class="w-full" type="text" required v-model="formFields['title'].value">
                </div>
                <div class="col-span-3 grid"
                    :class="{ 'error-form-field': errorsFields.includes('abstract') && formSubmited }">
                    <label>Abstract *</label>
                    <QuillEditor :content="formFields['abstract'].value ? formFields['abstract'].value : '<p><br></p>'"
                        content-type="html" @update:content="content => formFields['abstract'].value = content"
                        @ready="onEditorReady($event)" />
                </div>
                <div class="col-span-3 grid">
                    <label>Description</label>
                    <QuillEditor
                        :content="formFields['description'].value ? formFields['description'].value : '<p><br></p>'"
                        content-type="html" @update:content="content => formFields['description'].value = content"
                        @ready="onEditorReady($event)" />
                </div>
                <div class="col-span-3">
                    <label>Url</label>
                    <input class="w-full" type="text" v-model="formFields['url'].value">
                </div>
                <div class="col-span-2">
                    <label>Sources <span>(One or more)</span></label>
                    <select class="w-full"
                        @input="formFields['sources'].value = useMultipleSelectForSources(Number(handleInputChange($event)), 'add', formFields['sources'].value)">
                        <option selected disabled></option>
                        <option v-for="source in formInizializationValues['sourcesOptions']" :value="source.id"
                            :key="source.id">{{ source.name }}</option>
                    </select>
                    <div class="selected-results" v-if="formFields['sources'].value.length">
                        <template v-for="(sourceOption, index) in formInizializationValues['sourcesOptions']" :key="index">
                            <template v-for="(source, index) in formFields['sources'].value" :key="index">
                                <div v-if="source.source == sourceOption.id" class="flex justify-between items-center">
                                    <span>
                                        {{ sourceOption.name }}
                                        <XMarkIcon class="icon icon-x-small thick inline"
                                            @click="formFields['sources'].value = useMultipleSelectForSources(source.source, 'remove', formFields['sources'].value)" />
                                    </span>
                                    <div>
                                        <div :id="index.toString()" v-show="!showSourceIDInput.includes(index)"
                                            class="label-with-icon" @click="showSourceIDInput.push(index)"><span
                                                class="icon-span">+ Add ID</span></div>
                                        <input type="text" placeholder="ID" v-show="showSourceIDInput.includes(index)"
                                            :value="source.externalSourceId"
                                            @input="source.externalSourceId = handleInputChange($event)" />
                                    </div>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
                <div>
                    <label>Links <span>(Press Enter to add a link)</span></label>
                    <input class="w-full" v-model="tempLinks" type="text"
                        @keyup.enter="formFields['links'].value.push(handleInputChange($event)); tempLinks = ''" />
                    <div class="selected-results" v-if="formFields['links'].value.length">
                        <span v-for="(link, index) in formFields['links'].value" :key="index">
                            {{ link }}
                            <XMarkIcon class="icon icon-x-small thick inline"
                                @click="formFields['links'].value.splice(index, 1)" />
                        </span>
                    </div>
                </div>
                <div class="form-separator col-span-3">
                    <p class="form-subtitle">Commercial & Other technology Participants</p>
                </div> 
                <div class="col-span-2"> 
                    <SearchInputWithResultSelection 
                        v-model="formFields['owners'].value"  
                        :options-list="partiesForDropdown" 
                        :search-label="'Search for suppliers:'" 
                        :result-count="foundPartiesCount"
                        :is-multiple="true" 
                        :new-asset-created="otherAssetCreated"
                        @search-value-change="onPartySearchValueChange" />
                </div>
                <div class="mt-3">
                    <PartyAddNew party-type="supplier"/>
                </div>
                <div class="flex mt-3">
                    <input type="checkbox" v-model="formFields['previouslyProcured'].value" :true-value="true"
                        :false-value="null" />
                    <label>Previously procured</label>
                </div>
                <div class="form-separator col-span-3">
                    <p class="form-subtitle">Commercial & Other technology Assessment</p>
                </div>
                <div>
                    <label>Properties <span>(One or more)</span></label>
                    <DropdownSearchableMultiple v-if="formInizializationValues['propertiesOptions']"
                        :options-list="formInizializationValues['propertiesOptions']"
                        v-model="formFields['properties'].value" />
                </div>
                <div>
                    <label>Applications <span>(One or more)</span> </label>
                    <DropdownSearchableMultiple v-if="formInizializationValues['applicationTypesOptions']"
                        :options-list="formInizializationValues['applicationTypesOptions']"
                        v-model="formFields['applicationTypes'].value" />
                </div>
                <div>
                    <label>Certifications & standards <span>(One or more)</span> </label>
                    <DropdownSearchableMultiple v-if="formInizializationValues['standardsOptions']"
                        :options-list="formInizializationValues['standardsOptions']"
                        v-model="formFields['standards'].value" />
                </div>
                <div>
                    <label>Processing Techniques <span>(One or more)</span> </label>
                    <DropdownSearchableMultiple v-if="formInizializationValues['processingTechniquesOptions']"
                        :options-list="formInizializationValues['processingTechniquesOptions']"
                        v-model="formFields['processingTechniques'].value" />
                </div>
                <!-- <div>
                    <label>Tags <span>(One or more)</span></label>
                    <DropdownSearchableMultiple v-if="formInizializationValues['tagsOptions']"
                        :options-list="formInizializationValues['tagsOptions']" v-model="formFields['tags'].value"
                        select-other-than-id="name" />
                    <div class="label-with-icon">
                        <span @click="showTagInput = true" class="icon-span">+ Create new tag</span>
                        <div v-if="showTagInput" class="tooltip">
                            <InformationCircleIcon class="icon" />
                            <span class="tooltiptext info">Type the new tag and press enter to create and add it</span>
                        </div>
                    </div>
                    <input v-if="showTagInput" class="w-full" type="text" v-model="tempNewTag"
                        @keyup.enter="formFields['tags'].value.push(handleInputChange($event)); tempNewTag = ''" />
                </div> -->
                <div>
                    <label>Form Type <span>(One or more)</span> </label>
                    <select class="w-full"
                        @input="formFields['formTypes'].value = useMultipleSelect(Number(handleInputChange($event)), 'add', formFields['formTypes'].value)">
                        <option disabled selected></option>
                        <option v-for="formType in formInizializationValues['assetFormTypesOptions']" :value="formType.id"
                            :key="formType.id">{{ formType.name }}</option>
                    </select>
                    <div class="selected-results" v-if="formFields['formTypes'].value.length">
                        <template v-for="(formType, index) in formInizializationValues['assetFormTypesOptions']"
                            :key="index">
                            <span v-if="formFields['formTypes'].value.includes(Number(formType.id))">
                                {{ formType.name }}
                                <XMarkIcon class="icon icon-x-small thick inline"
                                    @click="formFields['formTypes'].value = useMultipleSelect(Number(formType.id), 'remove', formFields['formTypes'].value)" />
                            </span>
                        </template>
                    </div>
                </div>
                <div>
                    <label>TRL <span>Number between 1 and 9</span></label>
                    <input class="w-full" type="number" min="1" max="9" v-model.number="formFields['trl'].value">
                </div>
                <div class="col-span-3 row-span-2 grid">
                    <label>Notes</label>
                    <QuillEditor :content="formFields['notes'].value ? formFields['notes'].value : '<p><br></p>'"
                        content-type="html" @update:content="content => formFields['notes'].value = content"
                        @ready="onEditorReady($event)" />
                </div>
                <div class="col-span-3 mt-2">
                    <FilesUploadFormField asset-type="otherTechnology" />
                </div>
                <div class="form-separator col-span-3">
                    <p class="form-subtitle">Commercial & Other technology Dates</p>
                </div>
                <div>
                    <label>Publication Date</label>
                    <Datepicker class="w-full" :clearable="true" :input-format="'dd-MMM-yyyy'"
                        v-model="formFields['publicationDate'].value" :upper-limit="new Date()" />
                </div>
            </div>
        </form>
        <div class="form-error-toast">
            <FormErrorToast :is-showing="formSubmited && !formIsValid" title="The following fields are mandatory:"
                :subtitle="errorsFields.join(', ')" />
        </div>
    </div>
</template>
<script setup lang="ts">
import { ASSET_ADD_FORM_VALUES, PARTIES_QUERY } from '@/grapql/assetAddFormValues';
import { useQuery } from '@vue/apollo-composable';
import { computed, defineProps, ref, watch, watchEffect } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { useMultipleSelect, useMultipleSelectForSources } from '@/composables/useMultipleSelect';
import Datepicker from 'vue3-datepicker';
import { NEW_ASSET_FIELDS } from '@/models/newAssetFormFields';
import { useFormFieldsCreationNewAsset } from '@/composables/useFormFieldsCreationNewAsset';
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import FormErrorToast from '@/components/FormErrorToast.vue';
import DropdownSearchableMultiple from '@/components/DropdownSearchableMultiple.vue';
import SearchInputWithResultSelection from './SearchInputWithResultSelection.vue'
import FilesUploadFormField from './FilesUploadFormField.vue';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import PartyAddNew from '@/components/PartyAddNew.vue';
import {OTHER_LAST_NUMBERS_BENCHLING} from '@/grapql/hasuraQueries';
import {useHasuraAndQToProposeNicknames} from '@/composables/useHasuraAndQToProposeNicknames'
import {useQToGetLasNumbersForAssets} from '@/composables/useQToGetLasNumbersForAssets';
import {useStore} from 'vuex';
const props = defineProps(['errorsFields', 'formIsValid', 'formSubmited']);
const tempLinks = '';
// temporarily removed
// const tempNewTag = ref('');
// const showTagInput = ref(false);
const showSourceIDInput = ref([]);

// Inizialize the form
const { result } = useQuery(ASSET_ADD_FORM_VALUES);

const inizilizedData = computed(() => result?.value ?? null)
const formInizializationValues = computed<{ [key: string]: DropdownSearchableOptionInterface[] }>(() => useFormInizializationNewAsset(inizilizedData.value, NEW_ASSET_FIELDS.OTHER.fieldsToQuery));


// Create Fields for the form
const handleInputChange = (event: Event) => (event.target as HTMLInputElement).value;
const formFieldOptions = {
    fields: NEW_ASSET_FIELDS.OTHER.formFileds,
    base: NEW_ASSET_FIELDS.OTHER.graphqlValue,
    mutation: 'assetCreationModule/setSingleAssetValue'
};
const formFields = useFormFieldsCreationNewAsset(formFieldOptions);
const onEditorReady = (event: Event) => { return event };


// Parties 
const enableQuery = ref(false)
const queryOption = ref({ filter: {}});
const searchCriteria = ref()
// Filtered parties
const { result:parties, refetch:refetchParties } = useQuery(PARTIES_QUERY, queryOption, () => ({ enabled: enableQuery.value,}));
const partiesQueryResults = computed(() => parties?.value ?? null);
const foundParties = computed<DropdownSearchableOptionInterface[]>(() => partiesQueryResults?.value && partiesQueryResults.value?.parties ? partiesQueryResults.value?.parties?.edges.map((edge: { node }) => edge.node) : null);
const foundPartiesCount = computed<number | null>(() => partiesQueryResults?.value && partiesQueryResults.value?.parties ? partiesQueryResults.value.parties.totalCount : -1);

const partiesForDropdown = computed(() => foundParties.value ? foundParties.value.map(({id, nickname}) => ({id, name: nickname})) : []);
const otherAssetCreated = computed(() => props.formSubmited && props.errorsFields.length == 0);

const onPartySearchValueChange = (searchValue: string) => {
        queryOption.value.filter = {nickname: {icontains: searchValue}};
        enableQuery.value = true;
        searchCriteria.value = searchValue;
        refetchParties();
}


// ****** NICKNAME RECOMMENDER ******
// Get last values from datawarehouse to propose nicknames
const options = ref({clientId: 'hasuraClient'});
const { result:lastNumbersBenchling } = useQuery(OTHER_LAST_NUMBERS_BENCHLING, null, options);
const lastNumbersForAssetFromBenchling = computed(() => lastNumbersBenchling.value && lastNumbersBenchling.value.west1prod_dim_ndn_incrementals ? lastNumbersBenchling.value.west1prod_dim_ndn_incrementals[0].last_numbers : null)

// Get las values from Q to propose nicknames based on layer type selection
const selectedLayerName = computed(() => formFields['targetLayers'].value && formInizializationValues?.value['layerTypesOptions'] ? formInizializationValues.value['layerTypesOptions'].filter((layer: {id: number, name:string}) => layer.id == formFields['targetLayers'].value)[0].name : null);

const selectedMaterials = computed(() => formFields['materials'].value.length > 0 ? formInizializationValues.value['materialsOptions'].filter(material => formFields['materials'].value.includes(material.id)) : []);

const selectedMaterialsNames = computed(() => selectedMaterials.value.length > 0 ? selectedMaterials.value.map(material => material.name) : []);

const lastNumbersForAssetFromQ = computed(() => useQToGetLasNumbersForAssets(selectedLayerName.value))

// Change proposed nickanme based on material selection when substrate is selected
watch(formFields['materials'], () => {
    handleNicknameProposal()
})  

const helpTextForNickname = ref('');
const nicknameDisable = ref(true);

const handleNicknameProposal = () => {
    const {nickname, helpText, disableInput} = useHasuraAndQToProposeNicknames(lastNumbersForAssetFromBenchling.value, lastNumbersForAssetFromQ.value, selectedLayerName.value, selectedMaterialsNames.value)
    helpTextForNickname.value = helpText.value;
    nicknameDisable.value = disableInput.value;
    formFields['nickname'].value = nickname.value;
}

watchEffect(() => {
    handleNicknameProposal()
})

 
</script>