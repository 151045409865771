<template>
    <ModalSlots :create-text="onEdit ? 'Update' : 'Create'" :isOpen="isOpen" dialogClass="l-modal"
        @closeModal="onEdit ? $emit('closeEdit') : $emit('closeNew')" @click-create="createNewProduct">
        <template v-slot:header>
            <p v-if="onEdit">edit product brief</p>
            <p v-else>new product brief</p>
        </template>
        <template v-slot:body>
            <form class="px-8 pb-16">
                <FormErrorToast v-if="errorsFromMutation" :is-showing="showMutationErrors" :title="errorsFromMutation"
                    toastType="warning" />
                <FormErrorToast :is-showing="formSubmited && !formIsValid" title="The following fields are mandatory:"
                    :subtitle="missingFields.join(', ')" />

                <div class="grid grid-cols-6 gap-y-2.5 gap-x-4">
                    <div class="form-separator col-span-5">
                    </div>

                    <div class="col-span-2">
                        <label>Client *</label>
                        <DropdownSearchableSingle v-model="formFields['client'].value"
                            :options-list="formInizializationValues['partiesOptions']" />
                             <div class="label-with-icon">
                        <span @click="showClientInput = true" class="icon-span mt-3">+ Create new client</span>
                    </div>
                    </div>
                    <!-- <div class="mt-3">
                        <ClientAddNewForm />
                    </div> -->

                    <div class="col-span-2">
                        <label>Product *</label>
                        <input class="w-full" type="text" required v-model="formFields['product'].value">
                    </div>

                    <div class="col-span-2">
                        <label>Country <span>(One or more)</span></label>
                        <DropdownSearchableMultiple v-if="formInizializationValues['countriesOptions']"
                            v-model="formFields['markets'].value"
                            :options-list="formInizializationValues['countriesOptions']" />
                    </div>

                    <div class="col-span-6 row-span-2 grid mb-3">
                        <label>Objective</label>
                        <QuillEditor
                            :content="formFields['objective'].value ? formFields['objective'].value : '<p><br></p>'"
                            content-type="html"
                            @update:content="content => content == '<p><br></p>' ? formFields['objective'].value = null : formFields['objective'].value = content"
                            @ready="onEditorReady($event)" />
                    </div>

                </div>
            </form>
        </template>
    </ModalSlots>
</template>

<script setup lang="ts">
import ModalSlots from '@/components/ModalSlots.vue';
import { useQuery } from '@vue/apollo-composable';
import { QuillEditor } from '@vueup/vue-quill';
import DropdownSearchableSingle from '@/components/DropdownSearchableSingle.vue';
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import DropdownSearchableMultiple from '@/components/DropdownSearchableMultiple.vue';
import { useStore } from 'vuex';
import { ref, computed, defineProps, defineEmits } from 'vue';
import FormErrorToast from '@/components/FormErrorToast.vue';
import { CLIENT_QUERY } from '@/grapql/clientQuery';
import { NEW_PRODUCT_BRIEFS_FIELDS } from '@/models/newProductBriefsFormFields';
// import ClientAddNewForm from '@/components/ClientAddNewForm.vue';

const props = defineProps({
    onEdit: { type: Boolean, required: false },
    isOpen: { type: Boolean, required: false },
})
const emit = defineEmits(['closeEdit', 'closeNew']);
const store = useStore();

const showClientInput = ref(false);

const mandatoryFormFields = NEW_PRODUCT_BRIEFS_FIELDS.mandatoryFormFields;

// Inizialize the form
const { result } = useQuery(CLIENT_QUERY);
const inizilizedData = computed(() => result?.value ?? null);

const formInizializationValues = computed<{ [key: string]: DropdownSearchableOptionInterface[] }>(() => {
    return useFormInizializationNewAsset(inizilizedData.value, NEW_PRODUCT_BRIEFS_FIELDS.fieldsToQuery);
});

const formFieldsCreate = (formFieldOptions: {
    fields: string[];
    base: string;
    mutation: string;
}) => {
    const object = {};

    formFieldOptions.fields.map((fieldName) => {
        object[fieldName] = computed({
            get() {
                return store.state.productBriefsModule[formFieldOptions.base][
                    fieldName
                ];
            },
            set(value) {
                store.commit(formFieldOptions.mutation, {
                    productBriefsField: fieldName,
                    value: value,
                });
            },
        });
    });

    return object;
};

const formFields = formFieldsCreate({
    fields: NEW_PRODUCT_BRIEFS_FIELDS.formFields,
    base: NEW_PRODUCT_BRIEFS_FIELDS.storeValue,
    mutation: NEW_PRODUCT_BRIEFS_FIELDS.storeMutation,
})

// Form validation
const formSubmited = ref(false);
const valueSubmitted = computed(() => store.state['productBriefsModule']['productBriefs']);

const formIsValid = computed(() => mandatoryFormFields.every(fields => !!formFields[fields].value));
const missingFields = computed(() => formIsValid.value ? [] : mandatoryFormFields.filter(fields => !formFields[fields].value) || valueSubmitted.value);

const errorsFromMutation = computed(() => store.state.productBriefsModule.errorDuringMutation.newProductBrief);
const showMutationErrors = computed(() => formSubmited.value && !!errorsFromMutation.value);

const onEditorReady = (event: Event) => { return event };

const createNewProduct = async () => {
    formSubmited.value = true;
    if (formIsValid.value) {
        const mutation = NEW_PRODUCT_BRIEFS_FIELDS.createMutation;

        await store.dispatch(mutation).then(() => {
            if(!errorsFromMutation.value) {
                formSubmited.value = false;
                store.commit('productBriefsModule/resetProductBriefsValues');
                emit('closeNew')
            }
        })
    }
}

</script>
