import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-start space-x-4 mb-6" }
const _hoisted_2 = { class: "w-10 flex-shrink-0" }
const _hoisted_3 = { class: "flex items-center justify-center bg-gray-100 border border-gray-200 rounded-full h-10 w-10" }
const _hoisted_4 = { class: "flex-1 overflow-auto ml-4 p-3 border bg-gray-100 border-gray-200" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 0,
  class: "list-disc list-inside ml-4"
}
const _hoisted_7 = {
  key: 1,
  class: "ml-4"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = ["innerHTML"]

import { ref } from 'vue';
import { UserIcon } from '@heroicons/vue/24/outline';
import {IQuestion} from '@/models/aiInterfaces';


export default /*@__PURE__*/_defineComponent({
  __name: 'AIChatQuestion',
  props: {
    question: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(UserIcon), { class: "icon icon-small" })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (Array.isArray(_ctx.question.content))
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.content, (val, key) => {
              return (_openBlock(), _createElementBlock("div", { key: key }, [
                _createElementVNode("h4", null, _toDisplayString(key), 1),
                (Array.isArray(val))
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(val, (item, index) => {
                        return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(item), 1))
                      }), 128))
                    ]))
                  : (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(val), 1))
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("p", {
              innerHTML: _ctx.question.content,
              class: "whitespace-pre-line"
            }, null, 8, _hoisted_9)
          ]))
    ])
  ]))
}
}

})