import { useStore } from "vuex";
import { computed } from "vue";


export const useFormFieldsCreationNewRunDoubleSensitivity = (formFieldOptions:{fields:{[fieldName: string]: any[]}[],  mutation:string}) => {
    const store = useStore();
    const object = {};
    formFieldOptions.fields.map((field) => {
        const [fieldName, onChangeResetFields] = Object.entries(field)[0];
        object[fieldName] = computed({
            get() {
                return store.state.runDoubleSensitivityModule.create.fields[fieldName];
            },
            set(value) {
                store.commit(formFieldOptions.mutation, { field:fieldName , value: value, resetFields:onChangeResetFields});
            }
        })
    });
    return object;
}