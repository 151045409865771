import gql from "graphql-tag";

export const ASSET_DATA_FOR_COMPARISON_TABLE = gql`
    query getAssetsDataForComparisonTable ($id: ID!){
        asset(id: $id) {
            id
            nickname
            title
            assetQuantitativeProperties {
                edges {
                    node {
                        id
                        extraInformation
                        quantitativeProperty {
                            id
                            name
                            category
                            description
                        }
                        value1
                        value2
                        valueType {
                            id
                            name
                            description
                            symbol
                        }
                        valueUnit {
                            id
                            name
                            abbreviation
                            description
                        }
                    }
                }
            }
            assetProperties {
                edges {
                    node {
                        id
                        property {
                            id
                            name
                            category
                            className
                        }
                    }
                }
            }
        }
    }


`