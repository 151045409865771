import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "program-detail-card mb-4" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col items-start justify-evenly p-3 py-3"
}
const _hoisted_3 = {
  key: 1,
  class: "empty-box add"
}

import { ApplicationTypesInterface } from '@/models/programVersionsInterface';



export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramVersionApplicationsCard',
  props: {
    applications: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("p", { class: "cards-title" }, "applications", -1)),
    (_ctx.applications.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.applications, (application) => {
            return (_openBlock(), _createElementBlock("p", {
              key: application.id,
              class: "programs-tags"
            }, _toDisplayString(application.name), 1))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3))
  ]))
}
}

})