<template>
    <ModalSlots
      :show="showDoubleSensitive"
      @close-modal="closeModal"
      @reset-modal="handleReset"
      dialogClass="mx-auto max-w-[80%] min-w-[80%]"
      :hideFooter="true"
    >
      <template v-slot:header><p>RUN DOUBLE SENSITIVITY ANALYSIS</p></template>
      <template v-slot:body>
        <!-- <LoaderPage v-if="showFilesUploadLoader" loader-text="Creating Asset" gif-position="center"/> -->
        <div class="form-container">    
            <RunDoubleSensitivityCreate :run-id="selectedId" />
        </div>
      </template>
    </ModalSlots>
</template>

<script setup lang="ts">
import {ref, defineProps,computed,defineEmits, onMounted} from "vue"
import { useStore } from "vuex";
import ModalSlots from "@/components/ModalSlots.vue";
import RunDoubleSensitivityCreate from "./RunDoubleSensitivityCreate.vue";

const props = defineProps(["selectedId","showDoubleSensitive"]);
const emits = defineEmits(["onCreated","closeDoubleSensitive"]);
const store = useStore();
const collapsed = computed(() => store.getters["authModule/isSidebarCollapsed"]);
const toggleSidebar = () => store.commit("authModule/toogleSidebar");

const closeModal = () => {
    emits("closeDoubleSensitive")
};

onMounted(()=>{
    if(!collapsed.value){
        toggleSidebar()
    }
});

const handleReset = ()=>{
    store.commit("runDoubleSensitivityModule/RESET_CREATE");
}


</script>
<style scoped lang="scss">

</style>