import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-start space-x-4 mb-12 relative" }
const _hoisted_2 = { class: "w-10 flex-shrink-0" }
const _hoisted_3 = { class: "flex items-center justify-center border border-gray-200 rounded-full h-10 w-10" }
const _hoisted_4 = { class: "flex-1 overflow-x-auto ml-4 p-3 border border-gray-200" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "absolute top-full left-10 pr-3 py-1"
}

import { computed, h, ref, watch, onMounted} from 'vue';
import AIChatAnswerRender from './AIChatAnswerRender.vue';
import { CpuChipIcon, HandThumbDownIcon, HandThumbUpIcon, ArrowPathIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';
import {IAnswer} from '@/models/aiInterfaces';
import {ContentType} from '@/models/aiModels';
import AIChatSandwichRenderer from './AIChatSandwichRenderer.vue';
import { renderToString } from '@vue/server-renderer';


export default /*@__PURE__*/_defineComponent({
  __name: 'AIChatAnswer',
  props: {
    answer: {},
    messageId: {},
    isHistory: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const store = useStore();

// get and check if is completed
const isCompleted = computed(()=> store.getters["aiModule/getIsCompleted"]);

const html = ref<string>('');
async function updateHtml(){
    const newHtmlTexts = await Promise.all(props.answer.map(async (item) => {
    const parsedContent = JSON.parse(item.content);

    if (item.contentType === ContentType.TABLE) {
        // set typing speed
        store.commit('aiModule/setTypingSpeed', 15);

      return `<div class="table-container">${parsedContent}</div>`;
    }

    if (item.contentType === ContentType.SANDWICH) {
        // set typing speed
        store.commit('aiModule/setTypingSpeed', 10);
      return await renderToString(h(AIChatSandwichRenderer,{content:parsedContent}))
    }
    
    // set typing speed
    store.commit('aiModule/setTypingSpeed', 25);


    return `<span>${parsedContent}</span>`;
  }));

  html.value = newHtmlTexts.join(' ');
}
// Watch for changes to props.answer and update HTML
watch(() => props.answer, () => {
  updateHtml();
}, { deep: true });

// Ensure updateHtml runs on component mount
onMounted(() => {
  updateHtml();
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(CpuChipIcon), { class: "icon icon-small black" })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.isHistory)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            innerHTML: html.value,
            class: "whitespace-pre-line"
          }, null, 8, _hoisted_5))
        : (_openBlock(), _createBlock(AIChatAnswerRender, {
            key: 1,
            text: html.value,
            "message-id": _ctx.messageId
          }, null, 8, ["text", "message-id"]))
    ]),
    (isCompleted.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_unref(HandThumbUpIcon), { class: "icon w-9 h-9 cursor-pointer hover:bg-gray-200 active:shadow-inner rounded inline-block px-2 hover:stroke-black" }),
          _createVNode(_unref(ArrowPathIcon), { class: "icon w-9 h-9 cursor-pointer hover:bg-gray-200 active:shadow-inner rounded inline-block px-2 hover:stroke-black" }),
          _createVNode(_unref(HandThumbDownIcon), { class: "icon w-9 h-9 cursor-pointer hover:bg-gray-200 active:shadow-inner rounded inline-block px-2 hover:stroke-black" })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})