<template> 
    <Combobox :modelValue="modelValue" @update:modelValue="value => emit('update:modelValue', value)">
      <div class="relative">
        <div class="relative w-full cursor-default overflow-hidden">
          <ComboboxInput class="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 focus:ring-0"  @change="query = $event.target.value"/>
          <ComboboxButton class="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
          </ComboboxButton>
        </div>
        <TransitionRoot leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" @after-leave="query = ''">
          <ComboboxOptions class="options-border z-10 text-xs absolute mt-1 max-h-60 w-full overflow-auto  bg-white py-1 focus:outline-none">
            <div v-if="filteredOption.length === 0 && query !== ''" class="relative cursor-default select-none py-2 px-4">
              Nothing found.
            </div>
            <ComboboxOption v-for="option in filteredOption" as="template" :key="option.id" :value="option[valueToSelect]" v-slot="{ selected, active }" >
              <li class="relative cursor-default select-none py-0.5 pl-10 pr-4" :class="{'hover-option': active}">
                <span class="block truncate" :class="{ 'font-medium': selected, 'font-normal': !selected }">
                  {{ option.name }}
                </span>
                <span v-if="selected" class="absolute inset-y-0 left-0 flex items-center pl-3" :class="{ 'text-white': active, 'green-color': !active }">
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </TransitionRoot>
      </div> 
      <div class="selected-results" v-if="modelValue">
        <template v-for="(option, index) in optionsList" :key="index">
            <span v-if="modelValue == option.id">
                {{option.name}}
            </span>
        </template>
      </div>
    </Combobox>
    
</template>

<script setup lang="ts">
import { ref, computed, defineProps, PropType, defineEmits} from 'vue';
import {Combobox, ComboboxInput, ComboboxButton, ComboboxOptions, ComboboxOption, TransitionRoot} from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';

import {DropdownSearchableOptionInterface} from '@/models/dropdownSearchableMultipleOptionInterface'


const props = defineProps({
  optionsList: { type: Array as PropType<DropdownSearchableOptionInterface[]>},
  modelValue: { type: [String, Number, null]},
  selectOtherThanId: { type: String, required: false }
})

const emit = defineEmits(['update:modelValue']);
let query = ref('');
const valueToSelect = computed(()=> props.selectOtherThanId ? props.selectOtherThanId : 'id')


let filteredOption = computed(() =>
  query.value === ''
    ? props.optionsList
    : props.optionsList.filter((option) =>
        option.name
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.value.toLowerCase().replace(/\s+/g, ''))
      )
)
</script>

<style lang="scss">
  .hover-option {
    background-color: $green-light;
    cursor: pointer;
  }
  .options-border {
    box-shadow: $card-shadow;
    border-radius: 4px;
  }
  .green-color {
    color: $green-light;
  }
</style>