<template> 
    <template v-if="!linkedProgrammes.length && !programAddOn">
        <div>
            <p class="no-assessment">No Programes yet</p>
            <div class="edit-assessment on-green" @click="handleAdd"> 
                <PlusIcon class="icon" /><p>Add program</p>
            </div>
        </div>
    </template> 
    <template v-if="programAddOn">
        <AssetDetailAssessmentProgramsLinkAddition />
    </template>
    <template v-if="!!linkedProgrammes.length">
        <div class="edit-assessment" @click="handleAdd" v-if="!programAddOn && !programEditOn"> 
            <PlusIcon class="icon" /><p>Add new</p>
        </div>
        <div class="mt-4">
            <template v-for="linkedProgram in linkedProgrammes" :key="linkedProgram.id">
                <AssetDetailAssessmentProgramsLinkDetail :linked-program="linkedProgram" />
            </template>            
        </div>
    </template>
</template>

<script setup lang="ts">
import AssetDetailAssessmentProgramsLinkAddition from './AssetDetailAssessmentProgramsLinkAddition.vue';
import AssetDetailAssessmentProgramsLinkDetail from './AssetDetailAssessmentProgramsLinkDetail.vue';
import { PropType, computed, defineProps } from 'vue';
import { PlusIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';
import { LinkedProgramInterface } from '@/models/linkedProgramInterface';

defineProps({linkedProgrammes: Array as PropType<LinkedProgramInterface[]>})

const store = useStore();
const programAddOn = computed<boolean>(() => store.getters['assetDetailModule/isAssetAssessmentProgramsAddition']);
const programEditOn = computed<boolean>(() => store.getters['assetDetailModule/isAssetAssessmentProgramsEditable']);

const handleAdd = () => {
    store.commit('assetDetailModule/setEditableOn', {sectionToMakeEditbale: 'programsAdd' });
    store.commit('assetDetailModule/setEditableProgramsEditOff')
}

</script>
