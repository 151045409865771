import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "stage-ruler" }
const _hoisted_2 = {
  key: 0,
  class: "stage-name"
}

import { PROGRAM_STAGES } from '@/models/programStagesConstant';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramCardStageRuler',
  props: ['programStage'],
  setup(__props) {


const stages = PROGRAM_STAGES;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(stages), (stage) => {
      return (_openBlock(), _createElementBlock("div", {
        key: stage.stageNumber
      }, [
        _createElementVNode("p", {
          class: _normalizeClass(["stage-number", {active: stage.stageNumber == __props.programStage}])
        }, _toDisplayString(stage.stageNumber), 3),
        (stage.stageNumber === __props.programStage)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(stage.stageName), 1))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}
}

})