import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import WelcomeLogin from "@/views/WelcomeLoginView.vue";
import PageNotFound from '@/views/PageNotFound.vue';
// import Dashboard from '@/views/DashboardView.vue';
import ProductBriefs from '@/views/ProductBriefsView.vue'
import Playground from '@/views/PlaygroundView.vue';
import PlaygroundDetail from "@/views/PlaygroundDetail.vue";
// import ProductScoping from '@/views/ProductScopingView.vue'
import Programs from '@/views/ProgramsView.vue'
import ProgramsMockup from '@/views/ProgramsMockupView.vue'
import ResearchProjects from '@/views/ResearchProjectsView.vue'
import TechnologyAssetsRepo from '@/views/TechnologyAssetsRepoView.vue'
import PolymersRepository from '@/views/PolymersRepository.vue'
import AssetDetail from '@/views/AssetDetail.vue'
import ProgramDetail from '@/views/ProgramDetail.vue';
import ProductBriefDetail from '@/views/ProductBriefDetail.vue';
import store from "@/store";
// TEA imports
import TeaDashboardView  from "@/tea/views/DashboardView.vue";
import TeaRunGroupDetailView from "@/tea/views/RunGroupDetailView.vue";
// AI imports
import AIView from "@/views/AIView.vue";
import TechnologyAssetsComparisonView from "@/views/TechnologyAssetsComparisonView.vue";
import BenchmarkView from "@/views/BenchmarkView.vue";



const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Welcome",
    meta: { hideSidebar: true },
    component: WelcomeLogin,
  },
  // REPLACE DASHBOARD FOR PLAYGROUND (DEMO PORPOSE)
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   component: Dashboard,
  // },
  // {
  //   path: "/dashboard/:id",
  //   name: "DashboardDetail",
  //   component: DashboardDetail,
  // },
  {
    path: "/playground",
    name: "Playgorund",
    component: Playground,
  },
  {
    path: "/playground/:id",
    name: "PlaygroundDetail",
    component: PlaygroundDetail,
  },
  {
    path: "/product-briefs",
    name: "ProductBriefs",
    component: ProductBriefs,
  },
  {
    path: "/product-briefs/:id",
    name: "productBriefDetail",
    component: ProductBriefDetail,
  },
  {
    path: "/programmes",
    name: "Programs",
    component: ProgramsMockup,
  },
  {
    path: "/programmes-wip",
    name: "ProgramsWIP",
    component: Programs,
  },
  {
    path: "/programme/:id",
    name: "ProgramDetail",
    component: ProgramDetail,
    props: true,
  },
  {
    path: "/programme-wip/:id",
    name: "ProgramDetailWIP",
    component: ProgramDetail,
    props: true,
  },
  {
    path: "/benchmark-table/:title",
    name: "Benchmark",
    component: BenchmarkView,
    props: true
  },
  {
    path: "/research-projects",
    name: "ResearchProjects",
    component: ResearchProjects,
  },
  {
    path: "/technology-assets-repo",
    name: "TechnologyAssetsRepo",
    component: TechnologyAssetsRepo,
  },
  {
    path: "/technology-assets-repo/:id",
    name: "TechnologyAssetDetail",
    component: AssetDetail,
    props: true,
  },
  {
    path: "/technology-assets-comparison-view",
    name: "TechnologyAssetsComparisonView",
    component: TechnologyAssetsComparisonView,
  },
  {
    path: "/polymers",
    name: "PolymersRepository",
    component: PolymersRepository,
  },
  // TEA routes
  {
    path: "/tea-dashboard",
    name: "Tea.Dashboard",
    meta: { hideSidebar: false },
    component: TeaDashboardView,
  },
  {
      path: "/run-group/:id",
      name: "Tea.RunGroupDetail",
      meta: { hideSidebar: false },
      component: TeaRunGroupDetailView,
  },
  // AI routes
  {
    path:"/ai",
    name:"AI",
    component: AIView,
  },
  {
    path: '/:catchAll(.*)*',
    name: "PageNotFound",
    meta: { hideSidebar: true },
    component: PageNotFound,
  },
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//Redirect User to Welcome Page when non authenticated or not in Login page already
router.beforeEach(async (to) => {
  if (!store.getters["authModule/isAuthenticated"] && !!localStorage.auth_token && to.name !== 'Welcome' ) {
    await store.dispatch('authModule/refreshUser')
  }
  if (!store.getters["authModule/isAuthenticated"] && to.name !== 'Welcome' ) {
    return  { name: "Welcome" }
  }
});

export default router;
