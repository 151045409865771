import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "flex justify-between" }
const _hoisted_4 = {
  key: 0,
  class: "error-form-field"
}
const _hoisted_5 = {
  key: 1,
  class: "error-form-field"
}
const _hoisted_6 = { class: "mb-3" }
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "mb-3 assessment-editor" }
const _hoisted_9 = { class: "edit-assessment-buttons" }

import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import { ASSET_MATERIAL_FORM_VALUES } from '@/grapql/assetMaterialFormQuery';
import { AssetMaterialInterface, AssetMaterialUpdateMutationInput } from '@/models/assetAssessmentInterfaces';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { XMarkIcon, CheckIcon } from '@heroicons/vue/24/outline';
import { useQuery } from '@vue/apollo-composable';
import { ref, computed} from 'vue';
import { useStore } from 'vuex';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssetMaterialPropertiesForm',
  props: {
    assetMaterial: {}
  },
  emits: ['cancelEdition'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const store = useStore();
const onEditorReady = (event: Event) => {return event};

const { result } = useQuery(ASSET_MATERIAL_FORM_VALUES);
const inizilizedData = computed(() => result?.value ?? null );
const unitOptions = computed<{[key: string]: DropdownSearchableOptionInterface[]}>(() => useFormInizializationNewAsset(inizilizedData.value, ['unitTypes']));

const assetId = computed(() => store.getters['assetDetailModule/getAssetId']);
const errorsFromStore = computed(() => store.getters['assetDetailModule/getErrorsOnMaterialUpdate']);
const errorForThisRelation = computed(() =>  errorsFromStore.value.length > 0 ? errorsFromStore.value.filter(error => error.id == props.assetMaterial.id) : [] )
const errorFromRange = computed(() => ((!thicknessFirstValue.value && thicknessSecondValue.value) || (thicknessFirstValue.value && !thicknessSecondValue.value)) ? 'Thickness should be a range, please complete both values' : null);
const formSubmited = ref(false)


const blendPercentage = props.assetMaterial ? ref(props.assetMaterial.blendPercentage) : ref(null);
const notes = props.assetMaterial ? ref(props.assetMaterial.notes) : ref(null);
const thicknessFirstValue = props.assetMaterial && props.assetMaterial.thickness ? ref(props.assetMaterial.thickness[0]) : ref(null);
const thicknessSecondValue = props.assetMaterial && props.assetMaterial.thickness ? ref(props.assetMaterial.thickness[1]) : ref(null);
const thicknessUnit = props.assetMaterial && props.assetMaterial.thicknessUnit ? ref(props.assetMaterial.thicknessUnit.id) : ref(null);

const handleSaveChanges = async () => {
   formSubmited.value = true;
   if (!errorFromRange.value ){
      const materialPayload = ref<AssetMaterialUpdateMutationInput>({
      asset: assetId.value,
      material: props.assetMaterial.material.id,
      thickness: thicknessFirstValue.value && thicknessSecondValue.value ? [thicknessFirstValue.value, thicknessSecondValue.value] : null,
      thicknessUnit: thicknessUnit.value,
      blendPercentage: blendPercentage.value,
      notes: notes.value,
      id: props.assetMaterial.id
    })
    await store.dispatch('assetDetailModule/assetMaterialUpdate', materialPayload.value)
    .then(() => { 
      if (!errorForThisRelation.value.length) {
         emit('cancelEdition')}
    })
   }
    
   
}
  

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _cache[7] || (_cache[7] = _createElementVNode("label", null, "Blend Percentage", -1)),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(blendPercentage) ? (blendPercentage).value = $event : null)),
          type: "number",
          min: "0",
          max: "100",
          class: "w-full mb-4"
        }, null, 512), [
          [_vModelText, _unref(blendPercentage)]
        ])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _cache[9] || (_cache[9] = _createElementVNode("label", null, [
          _createTextVNode("Thickness "),
          _createElementVNode("span", null, "(Range of thickness. Ex: 3-6)")
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(thicknessFirstValue) ? (thicknessFirstValue).value = $event : null)),
            type: "number",
            min: "0",
            class: "w-2/5"
          }, null, 512), [
            [_vModelText, _unref(thicknessFirstValue)]
          ]),
          _cache[8] || (_cache[8] = _createTextVNode(" - ")),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(thicknessSecondValue) ? (thicknessSecondValue).value = $event : null)),
            type: "number",
            min: "0",
            class: "w-2/5"
          }, null, 512), [
            [_vModelText, _unref(thicknessSecondValue)]
          ])
        ]),
        (!!errorForThisRelation.value.length)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
              _createElementVNode("label", null, _toDisplayString(errorForThisRelation.value[0].error), 1)
            ]))
          : _createCommentVNode("", true),
        (formSubmited.value)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
              _createElementVNode("label", null, _toDisplayString(errorFromRange.value), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[10] || (_cache[10] = _createElementVNode("label", null, "Thickness Unit", -1)),
        _withDirectives(_createElementVNode("select", {
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(thicknessUnit) ? (thicknessUnit).value = $event : null)),
          class: "w-full"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(unitOptions.value['unitTypesOptions'], (unit) => {
            return (_openBlock(), _createElementBlock("option", {
              key: unit.id,
              value: unit.id
            }, _toDisplayString(unit.abbreviation) + " - " + _toDisplayString(unit.name), 9, _hoisted_7))
          }), 128))
        ], 512), [
          [_vModelSelect, _unref(thicknessUnit)]
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _cache[11] || (_cache[11] = _createElementVNode("label", null, "Notes", -1)),
        _createVNode(_unref(QuillEditor), {
          content: _unref(notes),
          "onUpdate:content": _cache[4] || (_cache[4] = ($event: any) => (_isRef(notes) ? (notes).value = $event : null)),
          "content-type": "html",
          onReady: _cache[5] || (_cache[5] = ($event: any) => (onEditorReady($event)))
        }, null, 8, ["content"])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", {
        onClick: handleSaveChanges,
        class: "on-green"
      }, [
        _createVNode(_unref(CheckIcon), { class: "icon" }),
        _cache[12] || (_cache[12] = _createElementVNode("p", null, "Save Changes", -1))
      ]),
      _createElementVNode("div", {
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('cancelEdition')))
      }, [
        _createVNode(_unref(XMarkIcon), { class: "icon" }),
        _cache[13] || (_cache[13] = _createElementVNode("p", null, "cancel", -1))
      ])
    ])
  ], 64))
}
}

})