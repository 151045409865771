import { ref } from "vue";

export function useInitialsToUserLogo(completeFirstName:string, completeLastName:string ) :string {
    
    const firstName = ref(null);
    const lastName = ref(null);

    const returnFirst = (names:string) => {
        const spacePosition = names.indexOf(' ');
        return spacePosition === -1 ? names : names.slice(0, spacePosition);
    }

    firstName.value = returnFirst(completeFirstName);
    lastName.value = returnFirst(completeLastName);
    const initials = firstName.value.slice(0,1).concat(lastName.value.slice(0,1))
    
    return initials.length !== 0 ? initials : "U";
}