<template>
    <div class="form-container m-4 mt-0"> 
        <FormErrorToast :is-showing="formSubmited && !formIsValid" title="The following fields are mandatory:" :subtitle="errorFields.join(', ')" /> 
        <FormErrorToast :is-showing="searchError?true:false" title="Error while searching:" :subtitle="searchError" /> 
        <div  v-if="!showExtractResult">
            <div class="grid grid-cols-5 gap-y-2.5 gap-x-4">
                <div class="form-separator col-span-5 m-2"><p class="form-subtitle">SEARCH FOR RESEARCH PAPER</p></div>
                <!-- <div class="col-span-3">
                    <p>
                        <strong>Information and Tips:</strong><br/>
                        <span class="min-100">DoiId:</span>The Digital Object Identified Id of research paper.<br/>
                        <span  class="min-100">Title:</span> The title of the research paper you want to search for.<br/>
                        <span  class="min-100">Title Terms:</span> Search by some key words in title.<br/>
                        <span  class="min-100">Mesh Terms:</span> Search by NLM's controlled vocabulary, Medical Subject Headings (MeSH®), is used to characterize the content of the articles represented by MEDLINE citations.<br/>
                        <span  class="min-100">Raw:</span> If you know eutils.ncbi api, you can write raw term query for search
                    </p>
                </div> -->
                <div class="col-span-1 sm:col-span-5 md:col-span-1" :class="{'error-form-field': errorFields.includes('searchField') && formSubmited }">
                    <label>Search Field</label>
                    <select class="w-full" v-model="vModels['searchField'].value">
                        <option v-for="(option) in searchFieldOptions" :key="option.key" :value="option.key">{{ option.value }}</option>
                    </select>
                </div>
                <div v-if="isSingleValue" class="col-span-3 sm:col-span-5 md:col-span-3"  :class="{'error-form-field': errorFields.includes('searchValue') && formSubmited }">
                    <label>Search Value  <span>(One)</span></label>
                    <input class="w-full" type="text" v-model="vModels['searchValue'].value">
                </div>
                <div v-else class="col-span-3 sm:col-span-5 md:col-span-3"  :class="{'error-form-field': errorFields.includes('searchValue') && formSubmited }">
                    <label>Search Value  <span>(One or more) press enter to add more terms</span></label>
                    <input class="w-full" v-model="tempSearchTerms" type="text" @keyup.enter="handleEnterKey($event)" />
                    <div class="selected-results" v-if="vModels['searchValue'].value.length">
                        <span v-for="(term, index) in vModels['searchValue'].value" :key="index">
                            {{term}}
                            <XMarkIcon class="icon icon-x-small thick inline" @click="handleRemoveSearchTerm(index)"/>
                        </span>
                    </div>
                </div>
                <div class="col-span-1  sm:col-span-5 md:col-span-1">
                    <label>&nbsp;</label>
                    <span class="button green-accept cursor-pointer text-center w-full" :class="{ 'inactive': searchInProgress }" style="padding:4px 20px; display: inline-block;" @click="searchResearchPapers">{{buttonText}}</span>
                </div>
                <div v-if="searchInProgress" class="col-span-5">
                    <div class="text-center p-6">
                        <div class="loading-gif-transparent"></div>
                    </div>
                </div>
                <ResearchPaperAutoFillExtract v-else-if="showSearchResult && !searchInProgress" />

            </div>
        </div>
        <div v-else>
            <ResearchPaperAutoFillExtractInfo />
        </div>
    </div>
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import { useStore } from "vuex";
import FormErrorToast from '@/components/FormErrorToast.vue';
import {RESEARCH_PAPER_AUTOFILL_FIELDS} from '@/models/researchPaperAutofillFormFields';
import { XMarkIcon, InformationCircleIcon } from '@heroicons/vue/24/outline';
import ResearchPaperAutoFillExtract from "@/components/ResearchPaperAutoFillExtract.vue";
import ResearchPaperAutoFillExtractInfo from "@/components/ResearchPaperAutoFillExtractInfo.vue";




const store = useStore();

const formSubmited = ref(false);
const searchFieldOptions = [
  { key: "doi", value: "DoiId" },
  { key: "title", value: "Title" },
  { key: "title_terms", value: "Title Terms" },
  { key: "mesh_terms", value: "Mesh Terms" },
  { key: "raw", value: "Raw" },
];
// Create Fields for the form
const fieldsFromConstant = computed<string[]>(() => RESEARCH_PAPER_AUTOFILL_FIELDS['SEARCH'].formFields.map(formField =>  { return formField.mutationValue} ));


const formFieldOptions = {
    fields: fieldsFromConstant.value,
    base: RESEARCH_PAPER_AUTOFILL_FIELDS['SEARCH'].graphqlValue,
    mutation: 'researchPaperAutofillModule/setSingleValue'
};
const formFields = (formFieldOptions:{fields:string[], base:string, mutation:string}) => {
    const object = {};
    formFieldOptions.fields.map(fieldName => {
        object[fieldName] = computed({
            get() {
                return store.state.researchPaperAutofillModule[formFieldOptions.base][fieldName];
            },
            set(value) {
                store.commit(formFieldOptions.mutation, { field: fieldName , value: value});
            }
        })
    });
    return object;
}

const vModels = formFields(formFieldOptions);

const isSingleValue = computed(()=>['title','doi','raw'].includes(vModels['searchField'].value))

var tempSearchTerms = '';
const handleInputChange = (event: Event) => (event.target as HTMLInputElement).value;
const handleEnterKey = (event) =>{
    const val =handleInputChange(event).trim()
    if(val !== ''){
        vModels['searchValue'].value.push(handleInputChange(event).trim());
        tempSearchTerms = '';
    }
}
const handleRemoveSearchTerm = (index)=>{
    store.commit("researchPaperAutofillModule/removeSearchTerm", index);
}

const valuesSubmitted = computed(() => store.state.researchPaperAutofillModule.search);
const formIsValid = computed(() => RESEARCH_PAPER_AUTOFILL_FIELDS['SEARCH'].mandatoryFormFields.every(mf => {
    if(mf=="searchValue"){
        return !!valuesSubmitted.value[mf].length;
    }
    return !!valuesSubmitted.value[mf]
}));
const errorFields = computed(() => RESEARCH_PAPER_AUTOFILL_FIELDS['SEARCH'].mandatoryFormFields.filter(mf => {
    if(mf=="searchValue"){
        return valuesSubmitted.value[mf].length>0 ? false: true;
    }
    return !valuesSubmitted.value[mf]
}) );

const showSearchResult = computed(()=> store.state.researchPaperAutofillModule.showSearchResult);
const showExtractResult = computed(()=> store.state.researchPaperAutofillModule.showExtractResult);
const searchInProgress = computed(()=> store.state.researchPaperAutofillModule.searchInProgress);
const searchError = computed(()=> store.state.researchPaperAutofillModule.searchError);
const buttonText= computed(()=> searchInProgress.value ? "Searching" : "Search");

const searchResearchPapers = async () => {
    formSubmited.value = true;
    if (formIsValid.value && !searchInProgress.value) {
        const query = RESEARCH_PAPER_AUTOFILL_FIELDS['SEARCH'].searchQuery;
        await store.dispatch(query)
        .catch((error)=>{
            // since the search is throwing error, enable pdf upload
            store.commit("researchPaperAutofillModule/setSearchResult", []);
        })
        .then(()=>{
            formSubmited.value = false;
        });
    }
}
</script>
<style scoped>
.min-100{
    min-width: 100px;
    display: inline-block;
}
</style>