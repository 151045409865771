import gql from "graphql-tag";

export const ASSET_ADD_FORM_VALUES = gql`
query getAssetAddFormValues {
    patentStatuses {
        edges {
            node {
                id
                name
            }
        }
    }
    sources {
        edges {
            node {
                id
                name
            }
        }
    }
    tags (orderBy: "name") {
        edges {
            node {
                id
                name
            }
        }
    }
    materials {
        edges {
            node {
                id
                name
                acronym
            }
        }
    }
    properties {
        edges {
            node {
                id
                name
                category
                className
            }
        }
    }
    applicationTypes {
        edges {
            node {
                id
                name
            }
        }
    }
    countries {
        edges {
            node {
                id
                name
                code
            }
        }
    }
    processingTechniques{ 
        edges {
            node {
                id 
                name
            }
        }
    }
    standards {
        edges {
            node {
                id 
                code 
                title
            }
        }
    }
    assetFormTypes { 
        edges {
            node {
                id 
                name
            }
        }
    }
    layerTypes { 
        edges {
            node {
                id 
                name
            }
        }
    }
    otherTechnologyTypes { 
        edges {
            node {
                id 
                name
            }
        }
    },
    parties (orderBy: "nickname") {
        edges {
            node {
                id
                nickname
                partyType
            }
        }
    }
}`

export const PARTIES_QUERY = gql`
    query getParties ($filter: PartyNodeFilterInputType) {
        parties(filter: $filter, orderBy: "nickname") {
            totalCount
            edges {
                node {
                    id
                    nickname
                }
            }
        }
    }
`