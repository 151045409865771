<template> 
    <div  class="program-container">
        <p class="program-name">{{ linkedProgram.program.name }}</p>
        <template v-if="!programEditFlag || programAddOn">
            <div class="content">
                <div class="content-values">
                    <p class="status" :style="{ backgroundColor: linkedProgram.linkStatus.colorCode || '#F99672' }">{{ linkedProgram.linkStatus.name }}</p>
                    <p class="link-type"> {{  linkedProgram.linkType.name }}</p>    
                    <p class="status-reason" v-html="linkedProgram.statusReason"></p>
                </div>
                <div class="actions">
                    <PencilSquareIcon class="icon icon-x-small" @click="handleEdit" v-if="!programAddOn"/>
                </div>
            </div>
        </template>
        <template v-else>
            <AssetDetailAssessmentProgramsLinksEditable @cancel-edition="programEditFlag = false" :linked-program="linkedProgram"/>
        </template>
    </div>
</template>

<script setup lang="ts">
import { defineProps, ref, type PropType, computed } from 'vue';
import { LinkedProgramInterface } from '@/models/linkedProgramInterface';
import { useStore } from 'vuex';
import { PencilSquareIcon } from '@heroicons/vue/24/outline';
import AssetDetailAssessmentProgramsLinksEditable from './AssetDetailAssessmentProgramsLinkEditable.vue';

const store = useStore();
defineProps({ 
    linkedProgram: Object as PropType<LinkedProgramInterface>,
});

const programAddOn = computed<boolean>(() => store.getters['assetDetailModule/isAssetAssessmentProgramsAddition']);
const programEditFlag = ref(false);


const handleEdit = () => {
    // store.commit('assetDetailModule/setEditableOn', {sectionToMakeEditbale: 'programsEdit' });
    programEditFlag.value = true; 
}

</script>
<style lang="scss" scoped>
.program-container {
    padding: 0.8rem 0 1rem;
    border-bottom: $border;
    &:last-child {
        border: none;
    }
}
.program-name {
    font-weight: 600;
    text-transform: uppercase;
    color: #6d6d6d;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
}  
.content{
    display: flex;
    justify-content: space-between;
}
.link-type {
    display: inline-block;
    padding: 0.2rem 0.8rem;
    border-radius: 50px;
    color: #fff;
    font-weight: 300;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    background-color: $blue-bg-text;
}
.status-reason {
    font-size: 0.9rem;
    color: $font-color-light;
}
.actions{
    .icon {
        stroke-width: 1;
        width: 22px;
        height: 22px;
        stroke: #999;
    }
}
</style>