import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "label-with-icon" }
const _hoisted_2 = { class: "px-8 pb-10" }
const _hoisted_3 = { class: "grid grid-cols-3 gap-y-2.5 gap-x-4 pt-8" }
const _hoisted_4 = { class: "col-span-2" }
const _hoisted_5 = { class: "col-span-3 row-span-2 grid mb-3" }
const _hoisted_6 = { class: "col-span-3 row-span-2 grid" }

import { ref, computed } from 'vue';
import ModalSlots from "@/components/ModalSlots.vue";
import { NEW_PROGRAMS_FIELDS } from '@/models/newProgramsFormFields';
import Datepicker from 'vue3-datepicker';
import FormErrorToast from '@/components/FormErrorToast.vue';
import { QuillEditor } from '@vueup/vue-quill';
import store from '@/store';
import { useFormFieldsCreationNewProgramAndVersion } from '@/composables/useFormFieldsCreateAndEditPrograms';



export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramAddNewForm',
  setup(__props) {

const isOpen = ref(false);

// Fields for the form creation
const formFieldOptions = {
	fields: NEW_PROGRAMS_FIELDS.PROGRAM.formFields,
	base: NEW_PROGRAMS_FIELDS.PROGRAM.graphqlValue,
	mutation: 'programsCreationModule/setSingleProgramValue'
};

const formFields = useFormFieldsCreationNewProgramAndVersion(formFieldOptions);

const formSubmited = ref(false);
const valuesSubmitted = computed(() => store.state['programsCreationModule']['program']);
const formIsValid = computed(() => NEW_PROGRAMS_FIELDS.PROGRAM.mandatoryFormFields.every(mandatoryField => !!valuesSubmitted.value[mandatoryField]));
const missingFields = computed(() => formIsValid.value ? [] : NEW_PROGRAMS_FIELDS.PROGRAM.mandatoryFormFields.filter(mandatoryField => !valuesSubmitted.value[mandatoryField]));
const errorsFromMutation = computed(() => store.state['programsCreationModule']['errorDuringMutation']['newProgram']);
const showMutationErrors = computed(() => formSubmited.value && !!errorsFromMutation.value);

const onEditorReady = (event: Event) => { return event };

const createNewProgram = async () => {
    formSubmited.value = true;
    if (formIsValid.value) {
        const mutation = NEW_PROGRAMS_FIELDS.PROGRAM.createMutation;
        
        await store.dispatch(mutation)
            .then(() => {
                if(!errorsFromMutation.value) {
                    formSubmited.value = false;
                    store.commit('programsCreationModule/resetProgramValues');
                    isOpen.value = false;
                }
            });
    }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (isOpen.value = true)),
        class: "icon-span"
      }, "+ Create new programme")
    ]),
    _createVNode(ModalSlots, {
      show: isOpen.value,
      onCloseModal: _cache[7] || (_cache[7] = ($event: any) => (isOpen.value = false)),
      onClickCreate: createNewProgram,
      dialogClass: "m-modal"
    }, {
      header: _withCtx(() => _cache[8] || (_cache[8] = [
        _createElementVNode("p", null, "Create new programme", -1)
      ])),
      body: _withCtx(() => [
        _createElementVNode("form", _hoisted_2, [
          (errorsFromMutation.value)
            ? (_openBlock(), _createBlock(FormErrorToast, {
                key: 0,
                "is-showing": showMutationErrors.value,
                title: errorsFromMutation.value,
                toastType: "warning"
              }, null, 8, ["is-showing", "title"]))
            : _createCommentVNode("", true),
          _createVNode(FormErrorToast, {
            "is-showing": formSubmited.value && !formIsValid.value,
            title: "The following fields are mandatory:",
            subtitle: missingFields.value.join(', ')
          }, null, 8, ["is-showing", "subtitle"]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[9] || (_cache[9] = _createElementVNode("label", null, "Programme Name *", -1)),
              _withDirectives(_createElementVNode("input", {
                class: "w-full",
                type: "text",
                required: "",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(formFields)['name'].value) = $event))
              }, null, 512), [
                [_vModelText, _unref(formFields)['name'].value]
              ])
            ]),
            _createElementVNode("div", null, [
              _cache[10] || (_cache[10] = _createElementVNode("label", null, "Creation Date *", -1)),
              _createVNode(_unref(Datepicker), {
                class: "w-full",
                "upper-limit": new Date(),
                clearable: true,
                "input-format": 'dd-MMM-yyyy',
                modelValue: _unref(formFields)['creationDate'].value,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(formFields)['creationDate'].value) = $event))
              }, null, 8, ["upper-limit", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _cache[11] || (_cache[11] = _createElementVNode("label", null, "Goal *", -1)),
              _createVNode(_unref(QuillEditor), {
                content: _unref(formFields)['goal'].value,
                "content-type": "html",
                "onUpdate:content": _cache[3] || (_cache[3] = content => content == '<p><br></p>' ?  _unref(formFields)['goal'].value = null : _unref(formFields)['goal'].value = content),
                onReady: _cache[4] || (_cache[4] = ($event: any) => (onEditorReady($event)))
              }, null, 8, ["content"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[12] || (_cache[12] = _createElementVNode("label", null, "Description", -1)),
              _createVNode(_unref(QuillEditor), {
                content: _unref(formFields)['description'].value ? _unref(formFields)['description'].value : '<p><br></p>',
                "content-type": "html",
                "onUpdate:content": _cache[5] || (_cache[5] = content => content == '<p><br></p>' ?  _unref(formFields)['description'].value = null : _unref(formFields)['description'].value = content),
                onReady: _cache[6] || (_cache[6] = ($event: any) => (onEditorReady($event)))
              }, null, 8, ["content"])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}
}

})