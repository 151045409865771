import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "accordion-item editable"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 1,
  class: "accordion-item"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]

import { ref } from 'vue';
    import { ChevronRightIcon } from '@heroicons/vue/24/solid';
    import { useStore } from 'vuex';
    import RunGroupDetailInfoContent from './RunGroupDetailInfoContent.vue';
    import { QuillEditor } from '@vueup/vue-quill'
    import '@vueup/vue-quill/dist/vue-quill.snow.css';

    
export default /*@__PURE__*/_defineComponent({
  __name: 'AccordionListEditable',
  props: ['dataForAccordionList'],
  setup(__props) {

    const props = __props;
    const store = useStore();
    const expandedField = ref(props.dataForAccordionList.filter(item => item.expanded).map(ei => ei.title));
    
    const toggleExpandedField = (title:string) => {
        const titlePos = expandedField.value.indexOf(title);
        titlePos == -1 ? expandedField.value.push(title) : expandedField.value.splice(titlePos, 1);
    }

    const onEditorReady = (event: Event) => {
        console.log(event)
    }
    const onEditorUpdate =  (event:Event, mutationValue:string) => {
        store.commit('assetDetailModule/updateMainAssetValue', {mutationValue: mutationValue, content: event })
    }
    


return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.dataForAccordionList, (item, index) => {
    return (_openBlock(), _createElementBlock("div", { key: index }, [
      (item.editable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              onClick: ($event: any) => (toggleExpandedField(item.title)),
              class: "title"
            }, [
              _createVNode(_unref(ChevronRightIcon), {
                class: _normalizeClass(["icon", {'face-down': expandedField.value.includes(item.title)}])
              }, null, 8, ["class"]),
              _createElementVNode("p", null, _toDisplayString(item.title), 1)
            ], 8, _hoisted_2),
            (item.title === 'Info')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass([{'visible': expandedField.value.includes(item.title)}, "content"])
                }, [
                  _createVNode(RunGroupDetailInfoContent, {
                    "info-content": item.content,
                    editable: true
                  }, null, 8, ["info-content"])
                ], 2))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass([{'visible': expandedField.value.includes(item.title)}, "content mt-4"])
                }, [
                  _createVNode(_unref(QuillEditor), {
                    content: item.content,
                    "content-type": "html",
                    onReady: _cache[0] || (_cache[0] = ($event: any) => (onEditorReady($event))),
                    "onUpdate:content": ($event: any) => (onEditorUpdate($event, item.mutationValue))
                  }, null, 8, ["content", "onUpdate:content"])
                ], 2))
          ]))
        : (item.content)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (item.content)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    onClick: ($event: any) => (toggleExpandedField(item.title)),
                    class: "title"
                  }, [
                    _createVNode(_unref(ChevronRightIcon), {
                      class: _normalizeClass(["icon", {'face-down': expandedField.value.includes(item.title)}])
                    }, null, 8, ["class"]),
                    _createElementVNode("p", null, _toDisplayString(item.title), 1)
                  ], 8, _hoisted_4))
                : _createCommentVNode("", true),
              (item.innerHtml && item.mutationType == 'textList')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass([{'visible': expandedField.value.includes(item.title)}, "content"])
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.content, (claim) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: claim,
                        innerHTML: claim
                      }, null, 8, _hoisted_5))
                    }), 128))
                  ], 2))
                : (item.innerHtml)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      innerHTML: item.content,
                      class: _normalizeClass([{'visible': expandedField.value.includes(item.title)}, "content"])
                    }, null, 10, _hoisted_6))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 3,
                      class: _normalizeClass([{'visible': expandedField.value.includes(item.title)}, "content"])
                    }, _toDisplayString(item.content), 3))
            ]))
          : _createCommentVNode("", true)
    ]))
  }), 128))
}
}

})