import { provideApolloClient } from "@vue/apollo-composable";
import apolloClient from "@/utils/apolloClient";
import { SEARCH_RESEARCH_PAPERS_QUERY } from "@/grapql/researchPaperAutofillQuery";
import { useRpsesQuery } from "@/composables/apollo/useRpsesQuery";

provideApolloClient(apolloClient);

const defaultExtractState = () => {return {
    uid:null,
    pdf:null,
}};
const defaultExtractResultState= () => {return {
    tempUuid:null,
    title:null,
    doiId:null,
    authors:[],
    abstract:null,
    description:null
}};
const getDefaultState = () => { return { 
    search: {
        searchField: "doi",
        searchValue: [],
     },
    searchInProgress: false,
    searchResult:[],
    searchError:null,
    showSearchResult: false,
    extract:defaultExtractState(),
    extractInProgress: false,
    extractResult:defaultExtractResultState(),
    extractError:null,
    showExtractResult:false,
    }
}
const state = getDefaultState();

const mutations = { 
    setSingleValue(state, payload:{field: string , value: Date | number | string | number[] | null}) {
        if(payload.field=='searchValue' && (['title','doi','raw'].includes(state.search.searchField))){
            const val = payload.value.toString().trim();
            if(val!=''){
                state.search[payload.field] = [val]
            }else{
                state.search[payload.field]=[];
            }
        }else{
            state.search[payload.field] = payload.value
        }

        // when any value change, do not show search result
        state.showSearchResult=false;
    },
    removeSearchTerm(state,index){
        state.search.searchValue = state.search.searchValue.filter((_, i) => i !== index);
        // when any value change, do not show search result
        state.showSearchResult=false;
    },
    setSearchResult(state,data){
        state.searchResult = data;
        // when search result set, show it 
        state.showSearchResult = true;
    },
    setSearchInProgress(state,data){
        state.searchInProgress = data;
    },
    setSearchError(state,error){
        state.searchError = error;
    },
    setBeforeSearch(state){
        state.searchInProgress = true;  
        state.searchError=null;
        state.searchResult=[];
        state.showSearchResult = false;

        state.extract=defaultExtractState();
        state.extractInProgress=false;
        state.extractError=null;
        state.extractResult=defaultExtractResultState();
        state.showExtractResult = false;
    },
    setBeforeExtract(state){
        state.extractInProgress = true; 
        state.extractError=null;
        state.extractResult=defaultExtractResultState();
        state.showExtractResult = false;
    },
    setExtractResult(state,data){
        state.extractResult = data;
        // when search result set, show it 
        state.showExtractResult = true;
    },
    setExtractInProgress(state,data){
        state.extractInProgress = data;
    },
    setExtractError(state,error){
        state.extractError = error;
    },
    setSingleValueForExtract(state, payload:{field: string , value: Date | number | string | number[] | null}) {
        state.extract[payload.field] = payload.value
        // when any value change, do not show search result
    },
    resetExtractResult(state){
        state.extractInProgress=false;
        state.extractError=null;
        state.extractResult=defaultExtractResultState();
        state.showExtractResult=false;

        // also rest state.extract object
        state.extract=defaultExtractState();
    },
    resetStore(state){
        Object.assign(state, getDefaultState());
    },
}

const actions = { 
    async searchResearchPapers({state, commit}) {
        commit("setBeforeSearch");
        return new Promise<void>((resolve, reject) => {
            const { onResult, onError } = useRpsesQuery(
                    SEARCH_RESEARCH_PAPERS_QUERY,
                    {
                        searchField: state.search.searchField,
                        searchValue: state.search.searchValue.length>0 ? state.search.searchValue : [''],
                    },
                    { fetchPolicy:'no-cache' },
                );
            onResult(
                queryResult => {
                    if (queryResult.data && queryResult.data.searchResearchPapers) {
                        commit("setSearchResult", queryResult.data.searchResearchPapers);
                        commit("setSearchInProgress",false);
                        resolve();
                      } else {
                        // Handle the case when the data is missing in the result
                        commit("setSearchInProgress",false);
                        reject('Data missing in result');
                      }
                }
            );
            onError(error => {
                commit("setSearchInProgress",false);
                commit("setSearchError",error.message);
                reject(error);
            })
        })
    },
    async extractResearchPaperInfo({state, commit}) {
        commit("setBeforeExtract");
        
        const formData = new FormData();
        const operations = `{"query":"query($uid:String,$pdf:Upload){extractResearchPaperInfo(uid:$uid, pdf:$pdf){tempUuid uid title abstract journal doiId publicationDate authors{ firstname lastname party{ name partyType address email description } } description}}", "variables":{"uid":${state.extract.uid ? '"'+state.extract.uid+'"':null},"pdf":null}}`;
        formData.append("operations", operations);
        if(state.extract.pdf){
            const map = `{"0": ["variables.pdf"]}`;
            formData.append("map", map);
            formData.append("0", state.extract.pdf)
        }
        
        const token = localStorage.getItem("auth_token");
        const response = await fetch(process.env.VUE_APP_RPSES_ROOT_API, {
            body: formData,
            method: "post",
            headers: {
                authorization: token ? `jwt ${token}` : "",
            },
        });

        if(response.ok){
            const responseData = await response.json();
            if (responseData.data && responseData.data.extractResearchPaperInfo) {
                commit("setExtractResult", responseData.data.extractResearchPaperInfo);
                commit("setExtractInProgress",false);


            } else {
                commit("setExtractError","Response got no data");
            }
        }else{
            commit("setExtractError",response.statusText);
        }
        commit("setExtractInProgress",false);
    },
}
const getters = {
}

export default {
    namespaced: true,
    state, 
    mutations,
    getters,
    actions
}
