import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "files-to-remove"
}
const _hoisted_2 = ["href"]

import { computed, ref, unref } from 'vue';
import FilesUpload from './FilesUpload.vue';
import AssetFilesList from './AssetFilesList.vue';
import LoaderPage from '@/components/LoaderPage.vue';
import {  ArrowUturnLeftIcon , DocumentMinusIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssetFilesListEditable',
  props: ['filesToShow'],
  setup(__props) {

const props = __props;
const filesToShowWhithOutRemovals = computed(() => props.filesToShow.filter(file => !filesIdsToRemove.value.includes(file.node.id)))
const filesToRemove = ref([]);
const filesIdsToRemove = ref([]);
const store = useStore();
const showUploadLoader = computed(() => store.getters['assetDetailModule/getShowFilesUploadLoader'])
console.log(showUploadLoader)
const onFilesToRemove = (fileToRemove) => {
    filesIdsToRemove.value.push(fileToRemove.id);
    filesToRemove.value.push(fileToRemove);
    store.commit('assetDetailModule/setFilesToRemove', unref(filesIdsToRemove.value));
}

const undoRemoval = (fileToReAdd) => {
    filesIdsToRemove.value.splice(filesIdsToRemove.value.indexOf(fileToReAdd.id), 1);
    filesToRemove.value.splice(filesToRemove.value.indexOf(fileToReAdd), 1);
    store.commit('assetDetailModule/setFilesToRemove', unref(filesIdsToRemove.value));
} 

const onFilesToUpload = (fileToUpload) => {
    store.commit('assetDetailModule/setFilesToAdd', unref(fileToUpload));
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (showUploadLoader.value)
      ? (_openBlock(), _createBlock(LoaderPage, {
          key: 0,
          "gif-position": "bottom"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createVNode(FilesUpload, {
        "label-text": "Files to Add",
        onFilesToUpload: onFilesToUpload
      }),
      (filesToRemove.value.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _cache[0] || (_cache[0] = _createElementVNode("label", null, "File to Remove", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filesToRemove.value, (file, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "upload-file-preview"
              }, [
                _createElementVNode("a", {
                  href: file.file.url,
                  target: "_blank",
                  class: "card-content line-through"
                }, [
                  _createVNode(_unref(DocumentMinusIcon), { class: "icon shrink-0 icon-file thick remove" }),
                  _createTextVNode(" " + _toDisplayString(file.file.name.replace('assets/', '')), 1)
                ], 8, _hoisted_2),
                _createVNode(_unref(ArrowUturnLeftIcon), {
                  class: "icon icon-x-small shrink-0",
                  onClick: ($event: any) => (undoRemoval(file))
                }, null, 8, ["onClick"])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (__props.filesToShow)
        ? (_openBlock(), _createBlock(AssetFilesList, {
            key: 1,
            "files-to-show": filesToShowWhithOutRemovals.value,
            "files-removal": true,
            onFilesToRemove: onFilesToRemove
          }, null, 8, ["files-to-show"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})