import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  class: "layers",
  viewBox: "0 0 100 90",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["d", "fill", "filter"]
const _hoisted_3 = ["d"]
const _hoisted_4 = ["d"]

import { layerDataInterface } from '@/models/programVersionsInterface';
import { computed} from 'vue';

const startingPoint = 60;
const inclination = 20;


export default /*@__PURE__*/_defineComponent({
  __name: 'LayersDesignGenerator',
  props: {
    layersData: {},
    isMockup: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const colors = ['#1c70b2', '#badfd9', '#5fa2a6', '#d4c7c1', '#2a87d3', '#7d91bc']

const layersDataWithDefault = computed<layerDataInterface[]>(() => props.layersData.map(({id, color, thickness, position}) => ({
    id: id,
    color: !color ? colors[position] : color,
    thickness: thickness,
    position: position
})).sort((a, b) => a.position - b.position))


const layersDataWithOffset = computed(() => {
    let acc = 0;
    let newArray = [];
    let reverseArray = []
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    reverseArray = !props.isMockup ? layersDataWithDefault.value.reverse() : layersDataWithDefault.value;
    newArray = reverseArray.map((obj, index) => {
        acc = index != 0 ? acc + reverseArray[index - 1].thickness + 5 : 0;
        return {...obj, offset: acc}
    })
    return newArray;
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(layersDataWithOffset.value, (layer, index) => {
      return (_openBlock(), _createElementBlock("g", {
        key: index,
        class: "layer",
        style: _normalizeStyle(`--offset-x:0px; --offset-y:-${layer.offset}px; --offset-z:0px; --size:100;`)
      }, [
        _createElementVNode("path", {
          d: `M0,${startingPoint} v-${layer.thickness} L50,${startingPoint - layer.thickness - inclination} L100,${startingPoint - layer.thickness} v${layer.thickness} L50,${startingPoint + inclination} Z`,
          fill: layer.color,
          filter: layer.color
        }, null, 8, _hoisted_2),
        _createElementVNode("path", {
          d: `M0,${startingPoint} v-${layer.thickness} L50,${startingPoint + inclination - layer.thickness} v${layer.thickness} Z`
        }, null, 8, _hoisted_3),
        _createElementVNode("path", {
          d: `M50,${startingPoint + inclination} v-${layer.thickness} L100,${startingPoint - layer.thickness} v${layer.thickness} Z`
        }, null, 8, _hoisted_4)
      ], 4))
    }), 128))
  ]))
}
}

})