<template>
    <div v-if="infoContent" class="info-box">
        <div class="card-content full"> 
            <p class="card-title">Nickname</p>
            <input type="text" class="w-full mb-2" v-model="vModels['nickname'].value">
        </div>

        <template v-for="item in infoContent" :key="item.title">
            <template v-if="(!item.mutationValue || fieldsInThisAssetType.includes(item.mutationValue))">
                <div v-if="item.editable && item.inputType === 'text'" class="card-content full">
                    <p class="card-title">{{ item.title }} </p>
                    <input class="w-full" type="text" v-model="vModels[item.mutationValue].value">
                </div>
                <div v-if="item.editable && item.type === 'link'" class="card-content full">
                    <p class="card-title">{{ item.title }} <span>(Only one value)</span> </p>
                    <input :disabled="item.content != null" :value="item.content" class="w-full" type="text"
                        @keyup.enter="item.content = handleInputChange($event); commitValue(item)" />
                    <div class="selected-results" v-if="item.content">
                        <span>
                            <XMarkIcon class="icon icon-x-small thick inline shrink-0"
                                @click="item.content = null; commitValue(item)" />
                        </span>
                    </div>
                </div>
                <div v-else-if="item.editable && item.type === 'linkList'" class="card-content full">
                    <p class="card-title">{{ item.title }} <span> (Press Enter to add a link)</span> </p>
                    <input v-model="templinks" class="w-full" type="text"
                        @keyup.enter="templinks = ''; addLinkToLinkList($event, item)" />
                    <div class="selected-results" v-if="item.content">
                        <template v-for="(selectedOption, index) in item.content" :key="index">
                            <span>
                                {{ selectedOption }}
                                <XMarkIcon class="icon icon-x-small thick inline shrink-0"
                                    @click="removeLinkToLinkList(index, item)" />
                            </span>
                        </template>
                    </div>
                </div>
                <div v-else-if="item.editable && item.inputType == 'multipleSelect' && assetType == 'otherTechnology'"
                    class="card-content full">
                    <p class="card-title">{{ item.title }}</p> 
                    <SearchInputWithResultSelection v-model="vModels[item.mutationValue].value"
                        :options-list="partiesForDropdown" :search-label="'Search for suppliers:'"
                        :result-count="foundPartiesCount" :is-multiple="true" :selected-values="item.rawContent"
                        @search-value-change="onPartySearchValueChange" />
                    <div class="mt-3">
                        <PartyAddNew party-type="supplier" />
                    </div>
                </div>
                <div v-else-if="item.editable && item.inputType == 'multipleSelect'" class="card-content full">
                    <p class="card-title">{{ item.title }}</p>
                    <DropdownSearchableMultiple
                        v-if="optionsListForDropdown[item.queryOptions] && vModels[item.mutationValue] !== undefined"
                        :options-list="optionsListForDropdown[item.queryOptions]"
                        v-model="vModels[item.mutationValue].value" />
                </div>
                <div v-else-if="item.editable && item.inputType == 'simpleSelect'" class="card-content full">
                    <p class="card-title">{{ item.title }}</p>
                    <DropdownSearchableSingle
                        v-if="optionsListForDropdown[item.queryOptions] && vModels[item.mutationValue] !== undefined"
                        :options-list="optionsListForDropdown[item.queryOptions]"
                        v-model="vModels[item.mutationValue].value" />
                </div>
                <div v-else-if="!item.editable && item.type && item.type === 'list' && !!item.content"
                    class="card-content" :class="{ 'extra-width': item.extraWidth }">
                    <template v-if="item.content && item.content.length">
                        <p class="card-title">{{ item.title }}</p>
                        <p v-for="itemInList in item.content" :key="itemInList">{{ itemInList }}</p>
                    </template>
                </div>
                <div v-else-if="!item.editable && item.type && item.type === 'boolean' && !!item.content"
                    class="card-content">
                    <span class="card-title">{{ item.title }}</span>
                    <img v-if="item.content" src="@/assets/icons/checked.png" alt="item.title" />
                    <img v-else src="@/assets/icons/remove.png" alt="item.title" />
                </div>
                <div v-else-if="!item.editable && !!item.content" class="card-content">
                    <p class="card-title">{{ item.title }}</p>
                    <template v-if="item.content === 'RESEARCH PROJECT'">External Opportunity</template>
                    <template v-else>{{ item.content }}</template>

                </div>
            </template>
        </template>
    </div>
</template>
<script setup lang="ts">
import { computed, defineProps, ref } from 'vue';
import { useStore } from 'vuex';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { NEW_ASSET_FIELDS } from '@/models/newAssetFormFields';
import { InfoContentType } from '@/models/technologyAssetInterface';
import { useQuery } from '@vue/apollo-composable';
import { ASSET_MAIN_CONTENT } from '@/grapql/assetMainContentQueries';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import DropdownSearchableMultiple from './DropdownSearchableMultiple.vue';
import DropdownSearchableSingle from './DropdownSearchableSingle.vue';
import { PARTIES_QUERY } from '@/grapql/assetAddFormValues';
import PartyAddNew from '@/components/PartyAddNew.vue';
import SearchInputWithResultSelection from './SearchInputWithResultSelection.vue'

const props = defineProps<{
    infoContent: Array<InfoContentType>,
}>();

let templinks = '';
const store = useStore();

const assetType = computed(() => store.getters["assetDetailModule/getAssetType"]);
const fieldsInThisAssetType = computed(() => Object.values(NEW_ASSET_FIELDS).find(naf => naf.graphqlValue === assetType.value).formFileds);


// Inizialize the form
const { result } = useQuery(ASSET_MAIN_CONTENT);
const inizilizedData = computed(() => result?.value ?? null)

// Creat option list for drpdown
const fieldsForOptionsList = computed(() => props.infoContent.reduce((acc, obj) => [...acc, obj.queryName], []).filter(value => value !== undefined));
const optionsListForDropdown = computed<{ [key: string]: DropdownSearchableOptionInterface[] }>(() => useFormInizializationNewAsset(inizilizedData.value, fieldsForOptionsList.value));

const fieldsForVModels = computed(() =>
    props.infoContent.filter(field =>
        field.editable &&
        (field.inputType == 'multipleSelect' || field.inputType == 'simpleSelect' || field.inputType == 'text') &&
        fieldsInThisAssetType.value.includes(field.mutationValue)
    ).map(field => { return field.mutationValue }).filter(value => value !== undefined).concat('nickname')
)

const formFieldOptions = {
    fields: fieldsForVModels.value,
    base: assetType.value,
    mutation: 'assetDetailModule/updateMainAssetValue'
};

const formFieldsForSearchableMultipleDropdown = (formFieldOptions) => {
    const object = {};
    formFieldOptions.fields.map(fieldName => {
        object[fieldName] = computed({
            get() {
                return store.state.assetDetailModule.asset[formFieldOptions.base][fieldName] || [];
            },
            set(value) {
                store.commit(formFieldOptions.mutation, { mutationValue: fieldName, content: value });
            }
        })
    });
    return object;
}

const vModels = formFieldsForSearchableMultipleDropdown(formFieldOptions);

const handleInputChange = (event: Event) => (event.target as HTMLInputElement).value;

const addLinkToLinkList = (event: Event, item) => {
    const selectedValue = (event.target as HTMLInputElement).value;
    const newArray = item.content ? [...item.content] : [];
    newArray.push(selectedValue);
    item.content = newArray;
    commitValue(item);
}

const removeLinkToLinkList = (index, item) => {
    const newArray = item.content ? [...item.content] : [];
    newArray.splice(index, 1);
    item.content = newArray;
    commitValue(item)
}
const commitValue = (item) => {
    store.commit('assetDetailModule/updateMainAssetValue', { mutationValue: item.mutationValue, content: item.content })
}


// HANDLE PARTIES FOR OTHER TECHNOLOGIES - TO BE REMOVED THEN - 
// Parties 
const enableQuery = ref(false)
const queryOption = ref({ filter: {} });
const searchCriteria = ref()
// Filtered parties
const { result: parties, refetch: refetchParties } = useQuery(PARTIES_QUERY, queryOption, () => ({ enabled: enableQuery.value, }));
const partiesQueryResults = computed(() => parties?.value ?? null);
const foundParties = computed<DropdownSearchableOptionInterface[]>(() => partiesQueryResults?.value && partiesQueryResults.value?.parties ? partiesQueryResults.value?.parties?.edges.map((edge: { node }) => edge.node) : null);
const foundPartiesCount = computed<number | null>(() => partiesQueryResults?.value && partiesQueryResults.value?.parties ? partiesQueryResults.value.parties.totalCount : -1);

const partiesForDropdown = computed(() => foundParties.value ? foundParties.value.map(({ id, nickname }) => ({ id, name: nickname })) : []);

const onPartySearchValueChange = (searchValue: string) => {
    queryOption.value.filter = { nickname: { icontains: searchValue } };
    enableQuery.value = true;
    searchCriteria.value = searchValue
    refetchParties();
}

</script>
<style lang="scss" scoped>
.info-box {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;

    .card-content {
        width: 25%;
        flex-shrink: 0;
        border-left: 1px solid #ddd;
        text-align: initial;
        padding: 0 1.5rem 0.5rem 1rem;
        font-size: 0.85rem;
        color: $font-color-light;
        font-weight: 500;
        overflow: auto;
        text-transform: uppercase;
        overflow: visible;

        .accordion-item .content ul {
            list-style: none !important;
            padding-left: 0;
        }

        &.extra-width {
            width: 50%;
        }

        &.full {
            width: 100%;
        }

        .url {
            text-decoration: underline;
            cursor: pointer;
            text-transform: none;

            &.linkItem {
                display: block;
                // max-width: 200px;

            }
        }

        input[type=text] {
            background-color: $grey-modal !important;

            &:focus {
                border: 2px solid $blue-bg-text;
            }
        }

    }
}

.card-title {
    font-size: 0.9rem;
    color: $font-color-super-light;
    font-weight: 300;
    text-transform: none;
    position: relative;

    span {
        font-size: 0.7rem;
        color: #999;
    }

    &::after {
        content: "";
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #eeeeee;
        border: 1px solid #dddddd;
        position: absolute;
        left: -22px;
        top: 4px;
    }

}

.selected-results span {
    text-transform: none;
    color: white;
    background-color: $blue-bg-text;
    font-weight: 300;

    svg {
        stroke: white;
    }
}

img {
    display: inline-block;
    margin-left: 10px;
    width: 16px;
    opacity: 0.8;
}
</style>
