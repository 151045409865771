import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fixed inset-0 flex items-center justify-center p-4" }
const _hoisted_2 = {
  class: "modal-header",
  id: "modalTitle"
}
const _hoisted_3 = {
  class: "modal-body",
  id: "modalDescription"
}
const _hoisted_4 = {
  key: 0,
  class: "modal-footer two-buttons"
}
const _hoisted_5 = ["disabled"]

import { TransitionRoot, TransitionChild, Dialog, DialogPanel} from "@headlessui/vue";
import { XMarkIcon } from '@heroicons/vue/24/outline';



export default /*@__PURE__*/_defineComponent({
  __name: 'ModalSlots',
  props: ['isOpen', 'dialogClass', 'hideFooter','cancelText','createText',"createInProgress"],
  emits: ['closeModal','clickCreate','resetModal'],
  setup(__props, { emit: __emit }) {


const emits = __emit

const handleClose = ()=>{
  emits('closeModal')
  emits('resetModal')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(TransitionRoot), {
    appear: "",
    show: __props.isOpen,
    as: "template"
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(Dialog), {
        as: "div",
        onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeModal'))),
        class: "relative z-50"
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(TransitionChild), {
            as: "template",
            enter: "duration-300 ease-out",
            "enter-from": "opacity-0",
            "enter-to": "opacity-100",
            leave: "duration-200 ease-in",
            "leave-from": "opacity-100",
            "leave-to": "opacity-0"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createElementVNode("div", { class: "backdrop" }, null, -1)
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_unref(DialogPanel), {
              class: _normalizeClass(["modal-slots transition-all", __props.dialogClass])
            }, {
              default: _withCtx(() => [
                _createElementVNode("header", _hoisted_2, [
                  _renderSlot(_ctx.$slots, "header"),
                  _createVNode(_unref(XMarkIcon), {
                    class: "icon icon-small thick",
                    onClick: handleClose,
                    "aria-label": "Close Modal"
                  })
                ]),
                _createElementVNode("main", _hoisted_3, [
                  _renderSlot(_ctx.$slots, "body", {}, () => [
                    _cache[3] || (_cache[3] = _createTextVNode(" Default body content "))
                  ])
                ]),
                (!__props.hideFooter)
                  ? (_openBlock(), _createElementBlock("footer", _hoisted_4, [
                      _renderSlot(_ctx.$slots, "footer", {}, () => [
                        _createElementVNode("button", {
                          class: "button cancel",
                          onClick: handleClose
                        }, _toDisplayString(__props.cancelText ?? "cancel"), 1),
                        _createElementVNode("button", {
                          class: _normalizeClass(["button bg-black text-white", { 'cursor-not-allowed hover:shadow-none opacity-50': __props.createInProgress }]),
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clickCreate'))),
                          disabled: __props.createInProgress
                        }, _toDisplayString(__props.createText ?? "create"), 11, _hoisted_5)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 3
            }, 8, ["class"])
          ])
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["show"]))
}
}

})