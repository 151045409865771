import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex items-stretch justify-between mb-3" }
const _hoisted_2 = { class: "flex items-stretch justify-between mb-3" }

import LayersDesignVotingCard from "./LayersDesignVotingCard.vue";
import LayersDesignFeedback from "./LayersDesignFeedback.vue";
import { designFeedbackInterface, prototypesDesignsInfoInterface } from "@/models/programsInterface";
import { PropType, onMounted, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramPrototypeDesignsAndFeedback',
  props: {
    alternativeDesigns: { type: Array as PropType<prototypesDesignsInfoInterface[]> }
},
  setup(__props) {



const allFeedbacks = ref([]);

const handleNewFeedback = (feedback:designFeedbackInterface) => {
    allFeedbacks.value.push(feedback);
    localStorage.setItem('feedback', JSON.stringify(allFeedbacks.value));
    console.log('despues del emit: ' ,allFeedbacks.value)
}

onMounted(() => {
    allFeedbacks.value = JSON.parse(localStorage.getItem('feedback')) ? JSON.parse(localStorage.getItem('feedback')) : [] ;  
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.alternativeDesigns, (protoDesign) => {
        return (_openBlock(), _createBlock(LayersDesignVotingCard, {
          key: protoDesign.id,
          "prototype-design-info": protoDesign,
          onFeedback: handleNewFeedback
        }, null, 8, ["prototype-design-info"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.alternativeDesigns, (protoDesign) => {
        return (_openBlock(), _createBlock(LayersDesignFeedback, {
          key: protoDesign.id,
          "all-feedbacks": allFeedbacks.value,
          "prototype-design-id": protoDesign.id
        }, null, 8, ["all-feedbacks", "prototype-design-id"]))
      }), 128))
    ])
  ]))
}
}

})