<template>
    <ModalSlots :create-text="onEdit ? 'Update' : 'Create'" :show="isOpen" @close-modal="$emit('closeModal')"
        @click-create="handleCreateOrUpdate" dialogClass="xs-modal">
        <template v-slot:header>
            <p v-if="onEdit">Edit design</p>
            <p v-else>Create new design</p>
        </template>
        <template v-slot:body>
            <div class="flex flex-col">
                <div class="px-6 pt-8">
                    <label>Nickname*</label>
                    <input class="w-full" type="text" required v-model="design.nickname">
                </div>
                <div class="px-6 pt-4">
                    <label>Description</label>
                    <QuillEditor :content="design.description ? design.description : '<p><br></p>'" content-type="html"
                        @update:content="content => content == '<p><br></p>' ? design.description = null : design.description = content"
                        @ready="onEditorReady($event)" />
                </div>
                <!-- Extra fields in case of edition -->
                <template v-if="onEdit && statuses">
                    <div class="px-6 pt-4">
                        <label>Status</label>
                        <select v-model="design.status" class="w-full">
                            <option v-for="status in statuses" :key="status.id" :value="status.id">{{ status.name }}
                            </option>
                        </select>
                    </div>
                    <div class="px-6 pt-4">
                        <label>Status Reason</label>
                        <QuillEditor :content="design.statusReason ? design.statusReason : '<p><br></p>'" content-type="html"
                            @update:content="content => content == '<p><br></p>' ? design.statusReason = null : design.statusReason = content"
                            @ready="onEditorReady($event)" />
                    </div>
                </template>
            </div>
            <ProgramVersionDesignLayersForm :on-edit="onEdit" :design-id="designId" />
            <div class="px-6 sticky bottom-5 z-10">
                <FormErrorToast v-if="errorsFromMutation" :is-showing="showMutationErrors" :title="errorsFromMutation"
                    toastType="warning" />
                <FormErrorToast :is-showing="formSubmited && !formIsValid"
                    title="There are some mandatory fieds you need to complete to proceed" />
            </div> 
        </template>
    </ModalSlots>
</template>  


<script setup lang="ts">
import { ref, computed, defineProps, defineEmits, onMounted } from 'vue';
import ModalSlots from "@/components/ModalSlots.vue";
import FormErrorToast from '@/components/FormErrorToast.vue';
import { QuillEditor } from '@vueup/vue-quill';
import { useStore } from 'vuex';
import { useQuery } from '@vue/apollo-composable';
import { calcThickness } from '@/composables/useLayerThicknessToVisualThickness';
import ProgramVersionDesignLayersForm from './ProgramVersionDesignLayersForm.vue';
import { DESIGN_STATUSES_QUERY } from '@/grapql/designStatusesQuery';
import { DesignForForm, LayerCreateInterface, LayersFromBEInterface } from '@/models/programVersionsInterface';

const props = defineProps<{
    isOpen: boolean,
    onEdit?: boolean,
    designId?: number
}>();

const emits = defineEmits(['closeModal']);
const store = useStore();

const newDesign = computed<DesignForForm>(() => store.state['programVersionDetailModule'].newDesign);
const onEditionDesign = computed<DesignForForm>(() => store.state['programVersionDetailModule'].designForEdition);
const design = computed<DesignForForm>(() => props.onEdit ? onEditionDesign.value : newDesign.value);


const { result } = useQuery(DESIGN_STATUSES_QUERY) ;
const inizilizedData = computed(() => result?.value ?? null);
const statuses = computed(() => inizilizedData.value && inizilizedData.value.designStatuses ? inizilizedData.value.designStatuses.edges.map(edge => edge.node) : []);

const onEditorReady = (event: Event) => { return event };

// Validate form 
const formSubmited = ref(false);
const valuesSubmitted = computed(() => !props.onEdit ? store.state['programVersionDetailModule'].newDesign : store.state['programVersionDetailModule'].designForEdition);
const formIsValid = computed(() => valuesSubmitted.value.nickname && valuesSubmitted.value.layers.every((layer: LayerCreateInterface) => !!layer.thicknessUnitType && !!layer.layerType && !!layer.thicknessValueType && !!layer.thicknessValue1));

const errorsFromMutation = computed(() => store.state['programVersionDetailModule']['errorDuringMutation']['designCreate']);
const showMutationErrors = computed(() => formSubmited.value && !!errorsFromMutation.value);

const addThicknessToLayers = () =>  store.state['programVersionDetailModule'].newDesign.layers.map((layer: LayerCreateInterface) => layer.visualThickness = calcThickness(layer.thicknessValue1));
const colors = ['#1c70b2', '#badfd9', '#5fa2a6', '#d4c7c1', '#2a87d3', '#7d91bc']
const addDefaultColors = () =>  store.state['programVersionDetailModule'].newDesign.layers.map((layer: LayerCreateInterface, index:number) => layer.colorCode = colors[index]);
const setOrderToLayers = () => design.value.layers.map((layer, index: number) => layer.layerOrder = index+1)

const handleCreateOrUpdate = async () => {
    formSubmited.value = true;
    if(!props.onEdit && formIsValid.value) {
        addThicknessToLayers();
        addDefaultColors();
        setOrderToLayers();
        await store.dispatch('programVersionDetailModule/designCreateWithLayers')
            .then(() => {
                if (!errorsFromMutation.value) {
                    formSubmited.value = false;
                    store.commit('programVersionDetailModule/resetNewDesignValues');
                    emits('closeModal')
                }
            });
    }
    if(props.onEdit && formIsValid.value) {
        setOrderToLayers();
        console.log(store.state['programVersionDetailModule'].designForEdition.layers)
        await store.dispatch('programVersionDetailModule/layerDelete')
            .then(async () => { await store.dispatch('programVersionDetailModule/designUpdatePartial')})
            .then(() => {
                if (!errorsFromMutation.value) {
                    formSubmited.value = false;
                    emits('closeModal')
                }
            }); 
    }
}


onMounted(() => {
    if (props.onEdit && props.designId) {
        store.commit('programVersionDetailModule/parseBeDesignForEdition', { id: props.designId });
    }
})
</script>