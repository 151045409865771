import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loading-gif"
}
const _hoisted_2 = { class: "indepth-section mb-5" }
const _hoisted_3 = { class: "indepth-section mb-5" }
const _hoisted_4 = {
  key: 1,
  class: "mb-4 text-sm italic text-neutral-400"
}
const _hoisted_5 = { class: "indepth-section mb-5" }
const _hoisted_6 = {
  key: 1,
  class: "mb-6 text-sm italic text-neutral-400"
}

import { ASSET_ASSESSMENT_IN_DEPTH } from '@/grapql/assessmentInDepthQueries';
import { AssetMaterialInterface, AssetProcessingTechniqueInterface, AssetProcessingTechniquePropertyInterface, AssetQuantitativePropertyInterface, ProcessingTechniqueInterface } from '@/models/assetAssessmentInterfaces';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import AssetDetailAssessmentInDepthMaterials from './AssetDetailAssessmentInDepthMaterials.vue';
import AssetDetailAssessmentInDepthProcessingTechniques from './AssetDetailAssessmentInDepthProcessingTechniques.vue';
import AssetDetailAssessmentInDepthQuantitativeProperties from './AssetDetailAssessmentInDepthQuantitativeProperties.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssetDetailAssessmentInDepth',
  setup(__props) {

const store = useStore();
const assetId = computed(() => store.getters['assetDetailModule/getAssetId']);

const { result, loading } = useQuery(ASSET_ASSESSMENT_IN_DEPTH, { id: assetId },{fetchPolicy: 'cache-and-network'});
const inDepthData = computed(() => result?.value ?? null);

const materialsData = computed<AssetMaterialInterface[]>(() => inDepthData?.value?.asset?.assetMaterials?.edges.map((edge: { node: unknown; }) => edge.node) ?? null)

const processingTechniquesData = computed<AssetProcessingTechniqueInterface[]>(() => inDepthData?.value?.asset?.assetProcessingTechniques?.edges.map((edge: { node: { id: number; numberOfRuns: number | null; processingTechnique: ProcessingTechniqueInterface; assetProcessingTechniqueProperties: { edges: unknown[]; }; }; }) => {
	const object = {
		'id': edge.node.id,
		'numberOfRuns': edge.node.numberOfRuns,
		'processingTechnique': edge.node.processingTechnique,
		'assetProcessingTechniqueProperties': edge.node.assetProcessingTechniqueProperties.edges.map((edge: { node: AssetProcessingTechniquePropertyInterface; }) => edge.node)
	}
	return object
}) ?? null)

const quantitativePropertiesData = computed<AssetQuantitativePropertyInterface[]>(() => inDepthData?.value?.asset?.assetQuantitativeProperties?.edges.map((edge: { node: unknown; }) => edge.node) ?? null)

return (_ctx: any,_cache: any) => {
  return (_unref(loading))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(AssetDetailAssessmentInDepthQuantitativeProperties, { "quantitative-properties-data": quantitativePropertiesData.value }, null, 8, ["quantitative-properties-data"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(Disclosure), null, {
            default: _withCtx(({ open }) => [
              _createVNode(_unref(DisclosureButton), { class: "flex w-full py-2 text-left text-sm font-medium disclosure-button" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(ChevronRightIcon), {
                    class: _normalizeClass([open ? 'chevron-rotate' : '', "h-5 w-5"])
                  }, null, 8, ["class"]),
                  _cache[0] || (_cache[0] = _createElementVNode("p", { class: "section-title" }, "Processing Techniques", -1))
                ]),
                _: 2
              }, 1024),
              _createVNode(_unref(DisclosurePanel), { class: "pl-4" }, {
                default: _withCtx(() => [
                  (processingTechniquesData.value && !!processingTechniquesData.value.length)
                    ? (_openBlock(), _createBlock(AssetDetailAssessmentInDepthProcessingTechniques, {
                        key: 0,
                        "processing-techniques-data": processingTechniquesData.value
                      }, null, 8, ["processing-techniques-data"]))
                    : (_openBlock(), _createElementBlock("p", _hoisted_4, " No processing techniques assigned to this technology yet "))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(Disclosure), null, {
            default: _withCtx(({ open }) => [
              _createVNode(_unref(DisclosureButton), { class: "flex w-full py-2 text-left text-sm font-medium disclosure-button" }, {
                default: _withCtx(() => [
                  _createVNode(_unref(ChevronRightIcon), {
                    class: _normalizeClass([open ? 'chevron-rotate' : '', "h-5 w-5"])
                  }, null, 8, ["class"]),
                  _cache[1] || (_cache[1] = _createElementVNode("p", { class: "section-title" }, "Materials", -1))
                ]),
                _: 2
              }, 1024),
              _createVNode(_unref(DisclosurePanel), { class: "pl-4" }, {
                default: _withCtx(() => [
                  (materialsData.value && !!materialsData.value.length)
                    ? (_openBlock(), _createBlock(AssetDetailAssessmentInDepthMaterials, {
                        key: 0,
                        "materials-data": materialsData.value
                      }, null, 8, ["materials-data"]))
                    : (_openBlock(), _createElementBlock("p", _hoisted_6, "No materials assigned to this technology yet"))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ], 64))
}
}

})