import gql from "graphql-tag";

export const PROGRAM_VERSIONS_QUERY = gql`
  query getProgramVersions {
    programs {
      edges {
        node {
          id
          name
        }
      }

    }
    programVersions(orderBy: "-creationDate") {
      edges {
        node {
          id
          name
          version
          goal
          program {
            id
            name
          }
          team {
            edges {
              node {
                id
                user {
                  id
                  firstName
                  lastName
                }
                teamRoleType {
                  id
                  name
                  category
                }
              }
            }
          }
          creationDate
          applicationTypes {
            edges {
              node {
                id
                name
                className
                description
              }
            }
          }
      }
    }
  }
}`;
