import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "new-related-programme" }
const _hoisted_2 = { class: "edit-assessment-buttons" }
const _hoisted_3 = {
  key: 0,
  class: "error-message mt-4"
}
const _hoisted_4 = { class: "mb-4 mt-4" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "mb-4" }
const _hoisted_7 = ["value"]

import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { CheckIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { ASSET_PROGRAM_LINK_FORM_VALUES } from '@/grapql/assetProgramLinkFormQueries';
import { useQuery } from '@vue/apollo-composable';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';



export default /*@__PURE__*/_defineComponent({
  __name: 'AssetDetailAssessmentProgramsLinkAddition',
  setup(__props) {

const store = useStore();
const assetId = computed(() => store.getters["assetDetailModule/getAssetId"]);

const { result } = useQuery(ASSET_PROGRAM_LINK_FORM_VALUES);
const inizilizedData = computed(() => result?.value ?? null );
const optionsListForDropdown = computed<{[key: string]: DropdownSearchableOptionInterface[]}>(() => useFormInizializationNewAsset(inizilizedData.value, ['programs', 'assetProgramLinkTypes', 'assetEvaluationStatuses']));

const program =  ref(null);
const linkType =  ref(null);

const formErrorValue = ref(null);
const newLinkErrorMessagge = computed(() => store.state.assetDetailModule.errorsDuringMutation.programsAddLink)

const handleCancelAdd = () => store.commit('assetDetailModule/setEditableProgramsAddOff' );

const handleAdd = async () => {
    const createNewLinkPayload = ref({
        asset: Number(assetId.value),
        program: Number(program.value),
        linkType: Number(linkType.value)
    })
    if (!createNewLinkPayload.value.program || !createNewLinkPayload.value.linkType) {
        formErrorValue.value = "Oops! You need to complete all fields"
    }
    else {
        formErrorValue.value = null
        await store.dispatch('assetDetailModule/assetProgramLinkCreate', createNewLinkPayload.value);
    }
    
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        onClick: handleAdd,
        class: "on-green"
      }, [
        _createVNode(_unref(CheckIcon), { class: "icon" }),
        _cache[2] || (_cache[2] = _createElementVNode("p", null, "Save Changes", -1))
      ]),
      _createElementVNode("div", { onClick: handleCancelAdd }, [
        _createVNode(_unref(XMarkIcon), { class: "icon" }),
        _cache[3] || (_cache[3] = _createElementVNode("p", null, "cancel", -1))
      ])
    ]),
    (newLinkErrorMessagge.value || formErrorValue.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(newLinkErrorMessagge.value) + " " + _toDisplayString(formErrorValue.value), 1))
      : _createCommentVNode("", true),
    _cache[6] || (_cache[6] = _createElementVNode("p", { class: "title" }, "New related programme", -1)),
    _createElementVNode("div", _hoisted_4, [
      _cache[4] || (_cache[4] = _createElementVNode("label", null, "Programme", -1)),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((program).value = $event)),
        class: "w-full"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(optionsListForDropdown.value['programsOptions'], (program) => {
          return (_openBlock(), _createElementBlock("option", {
            key: program.id,
            value: program.id
          }, _toDisplayString(program.name), 9, _hoisted_5))
        }), 128))
      ], 512), [
        [_vModelSelect, program.value]
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _cache[5] || (_cache[5] = _createElementVNode("label", null, "Link Type", -1)),
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((linkType).value = $event)),
        class: "w-full"
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(optionsListForDropdown.value['assetProgramLinkTypesOptions'], (linkType) => {
          return (_openBlock(), _createElementBlock("option", {
            key: linkType.id,
            value: linkType.id
          }, _toDisplayString(linkType.name), 9, _hoisted_7))
        }), 128))
      ], 512), [
        [_vModelSelect, linkType.value]
      ])
    ])
  ]))
}
}

})