import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "px-8 pb-16" }
const _hoisted_4 = { class: "grid grid-cols-6 gap-y-2.5 gap-x-4" }
const _hoisted_5 = { class: "col-span-2" }
const _hoisted_6 = { class: "label-with-icon" }
const _hoisted_7 = { class: "col-span-2" }
const _hoisted_8 = { class: "col-span-2" }
const _hoisted_9 = { class: "col-span-6 row-span-2 grid mb-3" }

import ModalSlots from '@/components/ModalSlots.vue';
import { useQuery } from '@vue/apollo-composable';
import { QuillEditor } from '@vueup/vue-quill';
import DropdownSearchableSingle from '@/components/DropdownSearchableSingle.vue';
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import DropdownSearchableMultiple from '@/components/DropdownSearchableMultiple.vue';
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import FormErrorToast from '@/components/FormErrorToast.vue';
import { CLIENT_QUERY } from '@/grapql/clientQuery';
import { NEW_PRODUCT_BRIEFS_FIELDS } from '@/models/newProductBriefsFormFields';
// import ClientAddNewForm from '@/components/ClientAddNewForm.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductBriefForm',
  props: {
    onEdit: { type: Boolean, required: false },
    isOpen: { type: Boolean, required: false },
},
  emits: ['closeEdit', 'closeNew'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit;
const store = useStore();

const showClientInput = ref(false);

const mandatoryFormFields = NEW_PRODUCT_BRIEFS_FIELDS.mandatoryFormFields;

// Inizialize the form
const { result } = useQuery(CLIENT_QUERY);
const inizilizedData = computed(() => result?.value ?? null);

const formInizializationValues = computed<{ [key: string]: DropdownSearchableOptionInterface[] }>(() => {
    return useFormInizializationNewAsset(inizilizedData.value, NEW_PRODUCT_BRIEFS_FIELDS.fieldsToQuery);
});

const formFieldsCreate = (formFieldOptions: {
    fields: string[];
    base: string;
    mutation: string;
}) => {
    const object = {};

    formFieldOptions.fields.map((fieldName) => {
        object[fieldName] = computed({
            get() {
                return store.state.productBriefsModule[formFieldOptions.base][
                    fieldName
                ];
            },
            set(value) {
                store.commit(formFieldOptions.mutation, {
                    productBriefsField: fieldName,
                    value: value,
                });
            },
        });
    });

    return object;
};

const formFields = formFieldsCreate({
    fields: NEW_PRODUCT_BRIEFS_FIELDS.formFields,
    base: NEW_PRODUCT_BRIEFS_FIELDS.storeValue,
    mutation: NEW_PRODUCT_BRIEFS_FIELDS.storeMutation,
})

// Form validation
const formSubmited = ref(false);
const valueSubmitted = computed(() => store.state['productBriefsModule']['productBriefs']);

const formIsValid = computed(() => mandatoryFormFields.every(fields => !!formFields[fields].value));
const missingFields = computed(() => formIsValid.value ? [] : mandatoryFormFields.filter(fields => !formFields[fields].value) || valueSubmitted.value);

const errorsFromMutation = computed(() => store.state.productBriefsModule.errorDuringMutation.newProductBrief);
const showMutationErrors = computed(() => formSubmited.value && !!errorsFromMutation.value);

const onEditorReady = (event: Event) => { return event };

const createNewProduct = async () => {
    formSubmited.value = true;
    if (formIsValid.value) {
        const mutation = NEW_PRODUCT_BRIEFS_FIELDS.createMutation;

        await store.dispatch(mutation).then(() => {
            if(!errorsFromMutation.value) {
                formSubmited.value = false;
                store.commit('productBriefsModule/resetProductBriefsValues');
                emit('closeNew')
            }
        })
    }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ModalSlots, {
    "create-text": __props.onEdit ? 'Update' : 'Create',
    isOpen: __props.isOpen,
    dialogClass: "l-modal",
    onCloseModal: _cache[6] || (_cache[6] = ($event: any) => (__props.onEdit ? _ctx.$emit('closeEdit') : _ctx.$emit('closeNew'))),
    onClickCreate: createNewProduct
  }, {
    header: _withCtx(() => [
      (__props.onEdit)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, "edit product brief"))
        : (_openBlock(), _createElementBlock("p", _hoisted_2, "new product brief"))
    ]),
    body: _withCtx(() => [
      _createElementVNode("form", _hoisted_3, [
        (errorsFromMutation.value)
          ? (_openBlock(), _createBlock(FormErrorToast, {
              key: 0,
              "is-showing": showMutationErrors.value,
              title: errorsFromMutation.value,
              toastType: "warning"
            }, null, 8, ["is-showing", "title"]))
          : _createCommentVNode("", true),
        _createVNode(FormErrorToast, {
          "is-showing": formSubmited.value && !formIsValid.value,
          title: "The following fields are mandatory:",
          subtitle: missingFields.value.join(', ')
        }, null, 8, ["is-showing", "subtitle"]),
        _createElementVNode("div", _hoisted_4, [
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "form-separator col-span-5" }, null, -1)),
          _createElementVNode("div", _hoisted_5, [
            _cache[7] || (_cache[7] = _createElementVNode("label", null, "Client *", -1)),
            _createVNode(DropdownSearchableSingle, {
              modelValue: _unref(formFields)['client'].value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(formFields)['client'].value) = $event)),
              "options-list": formInizializationValues.value['partiesOptions']
            }, null, 8, ["modelValue", "options-list"]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (showClientInput.value = true)),
                class: "icon-span mt-3"
              }, "+ Create new client")
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[8] || (_cache[8] = _createElementVNode("label", null, "Product *", -1)),
            _withDirectives(_createElementVNode("input", {
              class: "w-full",
              type: "text",
              required: "",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(formFields)['product'].value) = $event))
            }, null, 512), [
              [_vModelText, _unref(formFields)['product'].value]
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _cache[9] || (_cache[9] = _createElementVNode("label", null, [
              _createTextVNode("Country "),
              _createElementVNode("span", null, "(One or more)")
            ], -1)),
            (formInizializationValues.value['countriesOptions'])
              ? (_openBlock(), _createBlock(DropdownSearchableMultiple, {
                  key: 0,
                  modelValue: _unref(formFields)['markets'].value,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(formFields)['markets'].value) = $event)),
                  "options-list": formInizializationValues.value['countriesOptions']
                }, null, 8, ["modelValue", "options-list"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[10] || (_cache[10] = _createElementVNode("label", null, "Objective", -1)),
            _createVNode(_unref(QuillEditor), {
              content: _unref(formFields)['objective'].value ? _unref(formFields)['objective'].value : '<p><br></p>',
              "content-type": "html",
              "onUpdate:content": _cache[4] || (_cache[4] = content => content == '<p><br></p>' ? _unref(formFields)['objective'].value = null : _unref(formFields)['objective'].value = content),
              onReady: _cache[5] || (_cache[5] = ($event: any) => (onEditorReady($event)))
            }, null, 8, ["content"])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["create-text", "isOpen"]))
}
}

})