import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = { class: "showing-columns" }
const _hoisted_3 = { class: "hiding-columns" }

import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/outline'
import { computed } from 'vue';
import { ColumnAssetInterface } from '@/models/technologyAssetsInterface';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'TechnologyAssetsFilterColumnsConfiguration',
  setup(__props) {

const store = useStore();
const columns = computed<ColumnAssetInterface[]>(() => store.getters['assetsModule/getAssetsTableColumns']);

const showingColumns = computed(() => columns.value.filter((col:ColumnAssetInterface) => !col.hidden));
const hidingColumns = computed(() => columns.value.filter((col:ColumnAssetInterface) => col.hidden));

const handleShowHideColum = (col:ColumnAssetInterface) => { store.commit('assetsModule/showHideColumn', {columnValue: col.label}) };
const handleShowAll = () => store.commit('assetsModule/showAllColumns');
const handleHideAll = () => store.commit('assetsModule/hideAllColumns');


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", { class: "header" }, [
        _cache[0] || (_cache[0] = _createElementVNode("p", null, "Shown", -1)),
        _createElementVNode("p", { onClick: handleHideAll }, "Hide All")
      ]),
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(showingColumns.value, (shownCol) => {
          return (_openBlock(), _createElementBlock("li", {
            key: shownCol.label
          }, [
            _createTextVNode(_toDisplayString(shownCol.label) + " ", 1),
            _createVNode(_unref(EyeSlashIcon), {
              class: "icon icon-small",
              onClick: ($event: any) => (handleShowHideColum(shownCol))
            }, null, 8, ["onClick"])
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", { class: "header" }, [
        _cache[1] || (_cache[1] = _createElementVNode("p", null, "Hidden", -1)),
        _createElementVNode("p", { onClick: handleShowAll }, "Show All")
      ]),
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(hidingColumns.value, (hiddenCol) => {
          return (_openBlock(), _createElementBlock("li", {
            key: hiddenCol.label
          }, [
            _createTextVNode(_toDisplayString(hiddenCol.label) + " ", 1),
            _createVNode(_unref(EyeIcon), {
              class: "icon icon-small",
              onClick: ($event: any) => (handleShowHideColum(hiddenCol))
            }, null, 8, ["onClick"])
          ]))
        }), 128))
      ])
    ])
  ]))
}
}

})