<template>
	<div class="label-with-icon">
		<span @click="isOpen = true" class="icon-span">+ Create new programme</span>
	</div>
	<ModalSlots :show="isOpen" @close-modal="isOpen = false" @click-create="createNewProgram" dialogClass="m-modal">
		<template v-slot:header>
			<p>Create new programme</p>
		</template>
		<template v-slot:body> 
			<form class="px-8 pb-10">
				<FormErrorToast v-if="errorsFromMutation" :is-showing="showMutationErrors" :title="errorsFromMutation" toastType="warning" /> 
				<FormErrorToast :is-showing="formSubmited && !formIsValid" title="The following fields are mandatory:"	:subtitle="missingFields.join(', ')" />
				<div class="grid grid-cols-3 gap-y-2.5 gap-x-4 pt-8">
					<div class="col-span-2">
						<label>Programme Name *</label>
						<input class="w-full" type="text" required v-model="formFields['name'].value">
					</div>
					<div>
						<label>Creation Date *</label>
						<Datepicker class="w-full" :upper-limit="new Date()" :clearable="true" :input-format="'dd-MMM-yyyy'"
							v-model="formFields['creationDate'].value" />
					</div>
					<div class="col-span-3 row-span-2 grid mb-3">
						<label>Goal *</label>
						<QuillEditor 
							:content="formFields['goal'].value"
							content-type="html" 
							@update:content="content => content == '<p><br></p>' ?  formFields['goal'].value = null : formFields['goal'].value = content"
							@ready="onEditorReady($event)" />
					</div>
					<div class="col-span-3 row-span-2 grid">
						<label>Description</label>
						<QuillEditor
							:content="formFields['description'].value ? formFields['description'].value : '<p><br></p>'"
							content-type="html" 
							@update:content="content => content == '<p><br></p>' ?  formFields['description'].value = null : formFields['description'].value = content"
							@ready="onEditorReady($event)" />
					</div>
				</div>
			</form>
		</template>
	</ModalSlots>
</template>  


<script setup lang="ts">
import { ref, computed } from 'vue';
import ModalSlots from "@/components/ModalSlots.vue";
import { NEW_PROGRAMS_FIELDS } from '@/models/newProgramsFormFields';
import Datepicker from 'vue3-datepicker';
import FormErrorToast from '@/components/FormErrorToast.vue';
import { QuillEditor } from '@vueup/vue-quill';
import store from '@/store';
import { useFormFieldsCreationNewProgramAndVersion } from '@/composables/useFormFieldsCreateAndEditPrograms';


const isOpen = ref(false);

// Fields for the form creation
const formFieldOptions = {
	fields: NEW_PROGRAMS_FIELDS.PROGRAM.formFields,
	base: NEW_PROGRAMS_FIELDS.PROGRAM.graphqlValue,
	mutation: 'programsCreationModule/setSingleProgramValue'
};

const formFields = useFormFieldsCreationNewProgramAndVersion(formFieldOptions);

const formSubmited = ref(false);
const valuesSubmitted = computed(() => store.state['programsCreationModule']['program']);
const formIsValid = computed(() => NEW_PROGRAMS_FIELDS.PROGRAM.mandatoryFormFields.every(mandatoryField => !!valuesSubmitted.value[mandatoryField]));
const missingFields = computed(() => formIsValid.value ? [] : NEW_PROGRAMS_FIELDS.PROGRAM.mandatoryFormFields.filter(mandatoryField => !valuesSubmitted.value[mandatoryField]));
const errorsFromMutation = computed(() => store.state['programsCreationModule']['errorDuringMutation']['newProgram']);
const showMutationErrors = computed(() => formSubmited.value && !!errorsFromMutation.value);

const onEditorReady = (event: Event) => { return event };

const createNewProgram = async () => {
    formSubmited.value = true;
    if (formIsValid.value) {
        const mutation = NEW_PROGRAMS_FIELDS.PROGRAM.createMutation;
        
        await store.dispatch(mutation)
            .then(() => {
                if(!errorsFromMutation.value) {
                    formSubmited.value = false;
                    store.commit('programsCreationModule/resetProgramValues');
                    isOpen.value = false;
                }
            });
    }
}

</script>