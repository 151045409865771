import gql from "graphql-tag";

export const NEW_PROGRAM_VERSIONS_DESIGN_QUERIES = gql`
query getProgramVersionsNewDesignQueries  {
    unitTypes {
        edges {
            node {
                id
                name
                abbreviation
            }
        }
    }
    valueTypes (filter: {not: {name: {iexact: "Boolean"}}, and: {not: {name: {iexact: "Not"}}}}) {
        edges {
            node {
                id
                name
                symbol
                description
            }
        }
    }
    layerTypes {
        edges {
            node {
                id
                name
                description
            }
        }
    }
    assets(filter: {assetType: {iexact: "OTHER"}}) {
        edges {
            node {
                id
                nickname
            }
        }
    }
    designStatuses {
        edges {
            node {
                id
                name
                colorCode
                category
            }
        }
    }
  
}`;