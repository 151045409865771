import { POSITION, useToast } from "vue-toastification";
import { RUN_GROUP_CREATE_MUTATION } from "@/tea/graphql/mutations/runGroupMutations";
import { useTeaMutation } from "@/tea/composables/apollo/teaUseMutation";
import { useTeaQuery } from "@/tea/composables/apollo/useTeaQuery";
import { RUN_GROUPS_QUERY } from "@/tea/graphql/queries/runGroupQueries";
import { RUN_GROUP_DELETE_MUTATION,RUN_GROUP_UPDATE_MUTATION } from "@/tea/graphql/mutations/runGroupMutations";
import { useRawRunGroupToStateType } from "@/tea/composables/rungroups/useRawRunGroupToStateType";

const toast = useToast();

const getCreateDefaultState = () => {
  return {
    runGroup: { name: null, description: null },
    error: null,
  };
};
const getUpdateDefaultState=()=>{
  return {
    runGroup: null,
    editable:false,
    deletable:false,
    error: null,
  };
}

const getDefaultState = () => {
  return {
    repo: {
      runGroups: [],
      totalCount: 0,
      edgeCount: 0,
      loading: true,
      pageInfo: null,
      error: null,
    },
    create: getCreateDefaultState(),
    update: getUpdateDefaultState(),
    runGroup: {
      name: null,
      description: null,
    },
    errorsDuringRunGroupCreation: null,
    error: null,
  };
};

const state = getDefaultState();

const mutations = {
  SET_REPO_RUN_GROUPS(state, runGroups) {
    state.repo.error = null;
    state.repo.runGroups = runGroups;
  },
  APPEND_REPO_RUN_GROUPS(state, newRunGroups) {
    state.repo.runGroups = [...state.repo.runGroups, ...newRunGroups];
  },
  SET_REPO_TOTAL_COUNT(state, totalCount) {
    state.repo.totalCount = totalCount;
  },
  SET_REPO_EDGE_COUNT(state) {
    state.repo.edgeCount = state.repo.runGroups.length;
  },
  SET_REPO_PAGE_INFO(state, pageInfo) {
    state.repo.pageInfo = pageInfo;
  },
  SET_REPO_LOADING(state, loading) {
    state.repo.loading = loading;
  },
  SET_REPO_ERROR(state, message) {
    state.repo.error = message;
  },
  DELETE_REPO_RUN_GROUPS_RECORD(state, recordId) {
    state.repo.runGroups = state.repo.runGroups.filter((record) => record.id !== recordId);
    state.repo.totalCount = state.repo.totalCount - 1;
    state.repo.edgeCount = state.repo.edgeCount - 1;
  },
  SET_CREATE_SINGLE_RUN_GROUP_VALUE(state, payload: { runGroupField: string; value: Date | number | string | number[] | null }) {
    state.create.runGroup[payload.runGroupField] = payload.value;
  },
  SET_CREATE_ERROR(state, message) {
    state.create.error = message;
  },
  RESET_CREATE(state) {
    Object.assign(state.create, getCreateDefaultState());
  },
  SET_UPDATE_EDITABLE_ON(state){
    state.update.editable=true;
  },
  SET_UPDATE_EDITABLE_OFF(state){
    state.update.editable=false;
  },
  async SET_UPDATE_RUN_GROUP_VALUES(state,payload){
        state.update.editable = false;
        state.update.deletable= payload.runs?.totalCount==0 ? true:false;
        state.update.runGroup = useRawRunGroupToStateType(payload);
  },
  SET_UPDATE_SINGLE_RUN_GROUP_VALUE(state, payload:{mutationValue:string, content:string}) {
    state.update.runGroup[payload.mutationValue] = payload.content; 
  },
  RESET_UPDATE(state){
      Object.assign(state.update, getUpdateDefaultState());
  },
  SET_UPDATE_ERROR(state, message){
    state.update.error=message;
  },
};

const actions = {
  // CREATE
  async runGroupCreate({ state, commit, dispatch }) {
    return new Promise<void>((resolve, reject) => {
      const { mutate: runGroupCreate, onDone, onError } = useTeaMutation(RUN_GROUP_CREATE_MUTATION);
      const runGroupInput = Object.assign({}, state.create.runGroup);
      runGroupCreate({ input: runGroupInput });
      onDone(async () => {
        await dispatch("runGroupCreateOnDone");
        resolve();
      });
      onError((error) => {
        commit("SET_CREATE_ERROR", error.message);
        reject(error.message);
      });
    });
  },
  // ON DONE CREATION ACTIONS
  async runGroupCreateOnDone({ state, commit, dispatch }) {
    toast.success(`New run group created`, { position: POSITION.BOTTOM_LEFT, timeout: 1524 });
    commit("RESET_CREATE");
    dispatch("loadRunGroups");
  },

  // LOAD RUN GROUPS
  async loadRunGroups({ state, commit }, loadMore = false) {
    commit("SET_REPO_LOADING", true);
    try {
      const { result, onResult, onError } = useTeaQuery(
        RUN_GROUPS_QUERY,
        {
          first: 20,
          after: loadMore && state.repo.pageInfo ? state.repo.pageInfo.endCursor : "",
        },
        { fetchPolicy: "network-only" }
      );

      // Handle if result is not already loaded from cache
      // if (!result.value || state.repo.runGroups.length == 0) {
      onResult((queryResult) => {
        if (queryResult.data && queryResult.data.runGroups) {
          const runGroups = queryResult.data.runGroups.edges.map((edge) => edge.node);
          if (loadMore) {
            commit("APPEND_REPO_RUN_GROUPS", runGroups);
          } else {
            commit("SET_REPO_RUN_GROUPS", runGroups);
          }
          commit("SET_REPO_PAGE_INFO", queryResult.data.runGroups.pageInfo);
          commit("SET_REPO_TOTAL_COUNT", queryResult.data.runGroups.totalCount);
          commit("SET_REPO_EDGE_COUNT");
          commit("SET_REPO_LOADING", false);
        } else {
          // Handle the case when the data is missing in the result
        }
      });
      onError((error) => {
        commit("SET_REPO_ERROR", error.message);
      });
      // }
    } catch (error) {
      commit("SET_REPO_ERROR", error.message);
    } finally {
      commit("SET_REPO_LOADING", false);
    }
  },

  //lOAD MORE RUN GROUPS
  async loadMoreRunGroups({ dispatch }) {
    dispatch("loadRunGroups", true);
  },

  //DELETE RUN GROUP
  async deleteRunGroup({ state, commit, dispatch }, id) {
    const { mutate: runGroupDelete, onDone, onError } = useTeaMutation(RUN_GROUP_DELETE_MUTATION);
    runGroupDelete({ input: { id: id } });
    onDone(async () => {
      state.error = null;
      commit("DELETE_REPO_RUN_GROUPS_RECORD", id);
      commit("SET_UPDATE_EDITABLE_OFF");
      toast.warning("Run Group Deleted", { position: POSITION.BOTTOM_LEFT, timeout: 1524 });
      dispatch("loadRunGroups");
    });
    onError(async (error) => {
      state.error = error.message;
    });
  },

  // RUN GROUP UPDATE
  async runGroupUpdate({state, commit}) {
    const { mutate: runGroupUpdate, onDone, onError } = useTeaMutation(RUN_GROUP_UPDATE_MUTATION); 
    const runGroupInput = Object.assign({}, state.update.runGroup);
    runGroupUpdate({input: runGroupInput})
    onDone( async () => {
        state.update.error = null;
        commit("SET_UPDATE_EDITABLE_OFF");
        toast.success("Run Group Updated", { position: POSITION.BOTTOM_LEFT, timeout: 1524 });
    });
    onError(async(error)=>{
        state.update.error = error.message;
    });
},
};

const getters = {
  repo(state) {
    return state.repo;
  },
  create(state) {
    return state.create;
  },
  update(state){
    return state.update;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
