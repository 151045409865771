<template>
    <div>
        <div class="mt-6">
            <button class="button green-accept flex items-center" @click.prevent="openModal">
                <SparklesIcon class="icon icon-small mr-4 stroke-white"/>
                Use Auto Fill Service
            </button>
        </div>
        <ModalSlots :show="isOpen" dialogClass="create-asset-modal" @close-modal="closeModal" :hide-footer="!showExtractResult">
        <template v-slot:header><p></p></template>
        <template v-slot:body>
            <ResearchPaperAutoFill/>
        </template>
        <template v-slot:footer>
            <button class="button cancel" @click="resetExtractResult">Reject Extracted Info</button>
            <button class="button green-accept" @click="confirmExtractResult">Confirm and Fill The Form</button>
        </template>
    </ModalSlots>
    </div> 
</template>
<script setup lang="ts">
import { SparklesIcon } from '@heroicons/vue/24/outline';
import ModalSlots from "@/components/ModalSlots.vue";
import { computed, ref, defineEmits } from "vue";
import { useStore } from "vuex";
import ResearchPaperAutoFill from '@/components//ResearchPaperAutoFill.vue';

const store = useStore();
const emit = defineEmits(["researchPaperAutofilled"]);

const isOpen = ref(false);

const closeModal = () => { isOpen.value = false;}
const openModal = () => {
    store.commit("researchPaperAutofillModule/resetStore");
    isOpen.value = true; 
}


const showExtractResult = computed(()=> store.state.researchPaperAutofillModule.showExtractResult);
const extractResult = computed(()=> store.state.researchPaperAutofillModule.extractResult);
const resetExtractResult=()=>{
    store.commit("researchPaperAutofillModule/resetExtractResult");
}
const confirmExtractResult= async()=>{
    
    await store.dispatch("assetCreationModule/researchPaperAutofill",extractResult.value.tempUuid)
        .catch((error)=>{
            console.log('error in autofill')
        })
        .then(()=>{
            closeModal();
            emit("researchPaperAutofilled");
        });
}
</script>
