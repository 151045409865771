import gql from "graphql-tag";

export const ASSET_PROGRAM_LINK_FORM_VALUES = gql`
query getAssetProgramLinkFormValues {
    assetProgramLinkTypes {
        edges {
            node {
                id
                name
            }
        }
    }
    assetEvaluationStatuses {
        edges {
            node {
                id
                name
            }
        }
    }
    programs {
        edges {
            node {
                id
                name
            }
        }
    }
    
}`