import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "program-name-version" }
const _hoisted_2 = { class: "name" }
const _hoisted_3 = { class: "version" }
const _hoisted_4 = { class: "programs-leaders" }
const _hoisted_5 = { class: "program-description" }
const _hoisted_6 = { class: "flex flex-col program-name-version" }
const _hoisted_7 = { class: "name" }
const _hoisted_8 = { class: "version" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  key: 0,
  class: "my-5"
}

import { computed, PropType } from 'vue';
import router from '@/router';
import AvatarsActivity from './AvatarsActivity.vue';
import { ProgramVersionsInterface, ApplicationTypesInterface } from '@/models/programVersionsInterface';
import { programMockupIterface } from '@/models/programsInterface';
import ProgramCardStageRuler from './ProgramCardStageRuler.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramDashboardCard',
  props: {
    programVersion: {
        type: Object as PropType<ProgramVersionsInterface>,
        required: false,
    },
    programVersionApplications: {
        type: Object,
        required: false,
    },
    programMockup: {
        type: Object as PropType<programMockupIterface>,
        required: false
    }
},
  setup(__props) {

const props = __props

const goToProgramDetailMockup = (programmeId) => router.push({ name: 'ProgramDetail', params: { id: programmeId } });
const goToProgramDetail = (programmeId) => router.push({ name: 'ProgramDetailWIP', params: { id: programmeId } });

const applications = computed<ApplicationTypesInterface[]>(() => props.programVersion ? props.programVersionApplications.edges.length ? props.programVersionApplications.edges.map((edge) => edge.node) : [] : []);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (__props.programMockup)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "program-card",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (goToProgramDetailMockup(__props.programMockup.id)))
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["program status-dot", __props.programMockup.status.value])
          }, null, 2),
          _createElementVNode("div", {
            class: _normalizeClass(["status-text", __props.programMockup.status.value])
          }, _toDisplayString(__props.programMockup.status.label), 3),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(__props.programMockup.name), 1),
            _createElementVNode("p", _hoisted_3, _toDisplayString(__props.programMockup.version), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(AvatarsActivity, {
              "users-activity": __props.programMockup.leadTeam,
              orientation: "left"
            }, null, 8, ["users-activity"])
          ]),
          _createElementVNode("p", _hoisted_5, _toDisplayString(__props.programMockup.description), 1),
          _createVNode(ProgramCardStageRuler, {
            "program-stage": __props.programMockup.stage
          }, null, 8, ["program-stage"])
        ]))
      : _createCommentVNode("", true),
    (__props.programVersion)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "program-card flex flex-col justify-between",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (goToProgramDetail(__props.programVersion.id)))
        }, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", _hoisted_7, _toDisplayString(__props.programVersion.program.name), 1),
            _createElementVNode("p", _hoisted_8, _toDisplayString(__props.programVersion.name), 1),
            _createElementVNode("p", {
              innerHTML: __props.programVersion.goal,
              class: "program-description mt-5"
            }, null, 8, _hoisted_9)
          ]),
          (applications.value.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(applications.value, (applicationType) => {
                  return (_openBlock(), _createElementBlock("p", {
                    class: "programs-tags",
                    key: applicationType.id
                  }, _toDisplayString(applicationType.name), 1))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})