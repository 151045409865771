import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "page-padding"
}
const _hoisted_2 = {
  key: 1,
  class: "loading-gif"
}

import { computed, ref } from "vue";

import { useRoute } from "vue-router";
import { RUN_GROUP_QUERY } from "@/tea/graphql/queries/runGroupQueries";
import RunGroupDetail from "@/tea/components/rungroups/RunGroupDetail.vue";
import { useStore } from "vuex";
import { useTeaQuery } from "../composables/apollo/useTeaQuery";
import { useRunGroupToRunGroupDetail } from "@/tea/composables/rungroups/useRunGroupToRunGroupDetial";


export default /*@__PURE__*/_defineComponent({
  __name: 'RunGroupDetailView',
  setup(__props) {

const route = useRoute();
const store = useStore();
const errorToDisplay = ref(null);
const runGroupDetail = ref(null);
const id = computed<number>(() => {return +route.params.id});

const { onResult, error } = useTeaQuery(RUN_GROUP_QUERY, { id: id }, { fetchPolicy: "cache-and-network" });
if (error) {
  errorToDisplay.value = error;
}
onResult(async (queryResult) => {
  if (queryResult && queryResult.data) {
    if (queryResult.data.runGroup) {
      store.commit("runGroupModule/RESET_UPDATE");
      store.commit("runGroupModule/SET_UPDATE_RUN_GROUP_VALUES", queryResult.data.runGroup);
      runGroupDetail.value = await useRunGroupToRunGroupDetail(queryResult.data.runGroup);
    }
  }
});

return (_ctx: any,_cache: any) => {
  return (runGroupDetail.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(RunGroupDetail, {
          runGroupDetail: runGroupDetail.value,
          runGroupId: id.value
        }, null, 8, ["runGroupDetail", "runGroupId"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2))
}
}

})