import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "edit-assessment-buttons" }
const _hoisted_2 = {
  key: 0,
  class: "mb-4 mt-2"
}
const _hoisted_3 = ["onInput", "value"]
const _hoisted_4 = {
  key: 1,
  class: "mb-4 mt-3"
}
const _hoisted_5 = ["onInput", "value"]
const _hoisted_6 = ["value"]
const _hoisted_7 = {
  key: 2,
  class: "mb-3 assessment-editor"
}
const _hoisted_8 = {
  key: 3,
  class: "mb-4"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "label-with-icon" }
const _hoisted_11 = {
  key: 0,
  class: "input-with-tooltip mb-4"
}
const _hoisted_12 = {
  key: 0,
  class: "tooltip"
}
const _hoisted_13 = { class: "assessment-select" }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "assessment-select" }

import { computed, ref} from 'vue';
import { useStore } from 'vuex';
import { useQuery } from '@vue/apollo-composable';
import { InformationCircleIcon, CheckIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import DropdownSearchableMultiple from '@/components/DropdownSearchableMultiple.vue'
import AssetFilesListEditable from './AssetFilesListEditable.vue';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { AssetAssessmentHighLevel } from '@/models/assetAssessmentInterfaces';
import { ASSESSMENT_HIGH_LEVEL_OPTIONS } from '@/grapql/assessmentHighLevelQueries';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';

const tempNewTag = '';

export default /*@__PURE__*/_defineComponent({
  __name: 'AssetDetailAssessmentHighLevelEditable',
  props: {
    assetAssessmentHighLevelData: {}
  },
  setup(__props: any) {

const props = __props

const store = useStore();
const showTagInput = ref(false);

const { result } = useQuery(ASSESSMENT_HIGH_LEVEL_OPTIONS);
const inizilizedData = computed(() => result?.value ?? null );

const assetType = computed(() => store.state.assetDetailModule.assetType);

const fieldsForOptionsList = computed(() => props.assetAssessmentHighLevelData.fields.reduce((acc, obj) => [...acc, obj.queryName], []).filter(value => value !== undefined));
const optionsListForDropdown = computed<{[key: string]: DropdownSearchableOptionInterface[]}>(() => useFormInizializationNewAsset(inizilizedData.value, fieldsForOptionsList.value));

                    
const fieldsForVModels = computed(() => props.assetAssessmentHighLevelData.fields.map(field => { if (field.inputType == 'multipleSelect') { return field.mutationValue }}).filter(value => value !== undefined)) ;

const getStatusValueId = (statusName) => optionsListForDropdown.value['assetEvaluationStatusesOptions'].find(status => status.name === statusName).id;

const formFieldOptions = {
    fields: fieldsForVModels.value,
    base: assetType.value,
    mutation: 'assetDetailModule/updateAssessmentHigLevelValue'
};

const formFieldsForSearchableMultipleDropdown = (formFieldOptions) => {
    const object = {};
    formFieldOptions.fields.map(fieldName => {
        object[fieldName] = computed({
            get() {
                return store.state.assetDetailModule.asset[formFieldOptions.base][fieldName] || [];
            },
            set(value) {
                store.commit(formFieldOptions.mutation, { mutationValue: fieldName, content: value});
            }
        })
    });
    return object;
}

const vModels = formFieldsForSearchableMultipleDropdown(formFieldOptions);

const handleNewValue =  (event: Event, mutationValue:string) => {
    const content = (event.target as HTMLFormElement).value;
    store.commit('assetDetailModule/updateAssessmentHigLevelValue', {mutationValue: mutationValue, content: Number(content) })
}

const handleNewTag = (event: Event) => {
    const newTagValue = (event.target as HTMLInputElement).value;
    const storeTagNewValues = [...store.state.assetDetailModule.asset[formFieldOptions.base]['tags'], newTagValue];
    store.commit('assetDetailModule/updateAssessmentHigLevelValue', {mutationValue: 'tags', content: storeTagNewValues })
};

const handleSaveChanges = async () => {
    await store.dispatch(`assetDetailModule/${assetType.value}UpdatePartial`, {type:'assessmentHighLevel'});
    store.commit('assetDetailModule/setEditableAssessmentHighLevelOff')
}

const onEditorReady = (event: Event) => { return event };

const onEditorUpdate =  (event:Event, mutationValue:string) => {
    store.commit('assetDetailModule/updateAssessmentHigLevelValue', {mutationValue: mutationValue, content: event });
}

const cancelEdition = () => store.commit('assetDetailModule/setEditableAssessmentHighLevelOff');




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        onClick: handleSaveChanges,
        class: "on-green"
      }, [
        _createVNode(_unref(CheckIcon), { class: "icon" }),
        _cache[4] || (_cache[4] = _createElementVNode("p", null, "Save Changes", -1))
      ]),
      _createElementVNode("div", { onClick: cancelEdition }, [
        _createVNode(_unref(XMarkIcon), { class: "icon" }),
        _cache[5] || (_cache[5] = _createElementVNode("p", null, "cancel", -1))
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assetAssessmentHighLevelData.fields, (field) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: field.title
      }, [
        (field.inputType && field.inputType === 'number')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("label", null, _toDisplayString(field.title), 1),
              _createElementVNode("input", {
                class: "w-full",
                type: "number",
                min: "1",
                max: "9",
                onInput: ($event: any) => (handleNewValue($event, field.mutationValue)),
                value: field.content
              }, null, 40, _hoisted_3)
            ]))
          : _createCommentVNode("", true),
        (field.inputType === 'select')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("label", null, _toDisplayString(field.title), 1),
              (optionsListForDropdown.value[field.queryOptions])
                ? (_openBlock(), _createElementBlock("select", {
                    key: 0,
                    class: "w-full",
                    onInput: ($event: any) => (handleNewValue($event, field.mutationValue)),
                    value: getStatusValueId(field.content)
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(optionsListForDropdown.value[field.queryOptions], (option) => {
                      return (_openBlock(), _createElementBlock("option", {
                        value: option.id,
                        key: option.id
                      }, _toDisplayString(option.name), 9, _hoisted_6))
                    }), 128))
                  ], 40, _hoisted_5))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (field.inputType === 'textarea')
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("label", null, _toDisplayString(field.title), 1),
              _createVNode(_unref(QuillEditor), {
                content: field.content.toString(),
                "content-type": "html",
                onReady: _cache[0] || (_cache[0] = ($event: any) => (onEditorReady($event))),
                "onUpdate:content": ($event: any) => (onEditorUpdate($event, field.mutationValue))
              }, null, 8, ["content", "onUpdate:content"])
            ]))
          : _createCommentVNode("", true),
        (field.inputType && field.inputType === 'multipleSelect')
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              (field.mutationValue === 'tags')
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("label", null, _toDisplayString(field.title), 1),
                      _createElementVNode("span", {
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (showTagInput.value = true)),
                        class: "icon-span"
                      }, "Create new tag +")
                    ]),
                    (showTagInput.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _withDirectives(_createElementVNode("input", {
                            class: "w-full",
                            type: "text",
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((tempNewTag) = $event)),
                            onKeyup: _cache[3] || (_cache[3] = _withKeys((event) => {handleNewTag(event); tempNewTag = ''}, ["enter"]))
                          }, null, 544), [
                            [_vModelText, tempNewTag]
                          ]),
                          (showTagInput.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                _createVNode(_unref(InformationCircleIcon), { class: "icon" }),
                                _cache[6] || (_cache[6] = _createElementVNode("span", { class: "tooltiptext assess-info" }, "Type the new tag and press enter to create and add it", -1))
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_13, [
                      (optionsListForDropdown.value[field.queryOptions] && _unref(vModels)[field.mutationValue] !== undefined)
                        ? (_openBlock(), _createBlock(DropdownSearchableMultiple, {
                            key: 0,
                            "options-list": optionsListForDropdown.value[field.queryOptions],
                            modelValue: _unref(vModels)[field.mutationValue].value,
                            "onUpdate:modelValue": ($event: any) => ((_unref(vModels)[field.mutationValue].value) = $event),
                            "select-other-than-id": 'name'
                          }, null, 8, ["options-list", "modelValue", "onUpdate:modelValue"]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createElementVNode("label", null, _toDisplayString(field.title), 1),
                    _createElementVNode("div", _hoisted_15, [
                      (optionsListForDropdown.value[field.queryOptions] && _unref(vModels)[field.mutationValue] !== undefined)
                        ? (_openBlock(), _createBlock(DropdownSearchableMultiple, {
                            key: 0,
                            "options-list": optionsListForDropdown.value[field.queryOptions],
                            modelValue: _unref(vModels)[field.mutationValue].value,
                            "onUpdate:modelValue": ($event: any) => ((_unref(vModels)[field.mutationValue].value) = $event)
                          }, null, 8, ["options-list", "modelValue", "onUpdate:modelValue"]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128)),
    _cache[7] || (_cache[7] = _createElementVNode("label", null, "files", -1)),
    _createVNode(AssetFilesListEditable, {
      "files-to-show": _ctx.assetAssessmentHighLevelData.files
    }, null, 8, ["files-to-show"])
  ], 64))
}
}

})