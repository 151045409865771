<template>
    <div v-if="assetDetailAssessmentData">
        <h1 class="view-title mb-4 flex justify-between items-center">
            <span>Assessment</span> 
            <ChevronDoubleLeftIcon class="icon icon-small internal-pannel" @click="togglePannel" /> 
        </h1> 
        <AccordionCard :start-open="true">
            <template v-slot:title>
                <AccordionCardHeader :img-name="'clipboard.png'" title="HIGH LEVEL" subtitle="First general assessment on the technology" status="ok" />
            </template>
            <template v-slot:content>
                <AssetDetailAssessmentHighLevel v-if="!highLevelEditable" :asset-assessment-high-level-data="assetDetailAssessmentData.value.highLevel"/>
                <AssetDetailAssessmentHighLevelEditable v-else :asset-assessment-high-level-data="assetDetailAssessmentData.value.highLevel"/>
            </template>
        </AccordionCard>
        <AccordionCard :start-open="true">
            <template v-slot:title>
                <AccordionCardHeader  @click="isInDepthOopen = !isInDepthOopen" :img-name="'survey.png'" title="IN DEPTH" subtitle="Deep technical assessment on the technology" status="ok" />
            </template>
            <template v-slot:content v-if="isInDepthOopen">
                <AssetDetailAssessmentInDepth />
            </template>
        </AccordionCard>
        <AccordionCard> 
            <template v-slot:title>
                <AccordionCardHeader :img-name="'documentation.png'" title="RELATED PROGRAMMES" subtitle="Programmes associated with this technology " status="ok" />
            </template>
            <template v-slot:content>
                <AssetDetailAssessmentProgramsLinks :linked-programmes="assetDetailAssessmentData.value.programs.programList" />
            </template>
        </AccordionCard>
        <AccordionCard>
            <template v-slot:title>
                <AccordionCardHeader :img-name="'plant.png'" title="PRODUCT READINESS" subtitle="Assessment on whether this belong to research project" status="ko" />
            </template>
            <template v-slot:content><p>Work in progress</p></template>
        </AccordionCard>
        <AccordionCard>
            <template v-slot:title>
                <AccordionCardHeader :img-name="'medical-laboratory.png'" title="TESTING" subtitle="Experimental data from different kinds of test" status="ko" />
            </template>
            <template v-slot:content><p>Work in progress</p></template>
        </AccordionCard>
        
    </div>
</template>
<script setup lang="ts">
import {defineProps, computed, ref} from 'vue';
import AccordionCard from '@/components/AccordionCard.vue';
import AccordionCardHeader from '@/components/AccordionCardHeader.vue';
import AssetDetailAssessmentHighLevel from '@/components/AssetDetailAssessmentHighLevel.vue';
import { ChevronDoubleLeftIcon } from '@heroicons/vue/24/solid';
import { useStore } from 'vuex';
import AssetDetailAssessmentHighLevelEditable from './AssetDetailAssessmentHighLevelEditable.vue';
import AssetDetailAssessmentProgramsLinks from './AssetDetailAssessmentProgramsLinks.vue';
import AssetDetailAssessmentInDepth from './AssetDetailAssessmentInDepth.vue';

defineProps(['assetDetailAssessmentData'])
const store = useStore();
const togglePannel = () => store.commit('assetDetailModule/toggleAssessmentPannel');
const highLevelEditable = computed<boolean>(() => store.getters['assetDetailModule/isAssetAssessmentHighLevelEditable']);
const isInDepthOopen = ref(true)
</script>
