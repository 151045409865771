<template>
   <div class="my-6 w-full"> 
      
      <label>Property</label>
      <input type="text" class="w-full mb-3" disabled :value="assetQuantitativeProperty.quantitativeProperty.name"/>

      <label>Value Type</label>
      <select v-model="valueType" class="w-full mb-3">
         <option v-for="valueType in formValueOptions['valueTypesOptions']" :key="valueType.id" :value="valueType">{{ valueType.description }}</option>
      </select>

      <div class="mb-3" v-if="valueType && valueType.name == 'Boolean'">
         <label >Value</label>
         <div class="flex">
            <input class="shrink-0" type="checkbox" v-model="value1" true-value="true" false-value="false" />
            <label v-if="value1 && value1 == 'true'">Yes</label>
            <label v-else>No</label>
         </div>
      </div>
      <div v-else class="flex justify-between">
         <div :class="valueType && valueType.name == 'Between' ? 'w-2/5' : 'w-full'" class="mb-3">
            <label >Value</label>
            <input type="text" v-model="value1" class="w-full"/>
            <p v-if="valueError" class="error-form-field"><label>{{ valueError }}</label></p>
         </div>
         <div v-if="valueType && valueType.name == 'Between'" class="w-2/5"> 
            <label>Value 2</label>
            <input type="text" v-model="value2" class="w-full mb-3"/>
         </div>
      </div>

      <label>Unit</label>
      <select v-model="valueUnit" class="w-full mb-3">
         <option :value="null"></option>
         <option v-for="unitOption in formValueOptions['unitTypesOptions']" :key="unitOption.id" :value="unitOption.id">{{unitOption.abbreviation}} - {{ unitOption.name }}</option>
      </select>

      <div class="mb-3 assessment-editor">
         <label>Extra Information</label>
         <QuillEditor v-model:content="extraInformation" content-type="html" @ready="onEditorReady($event)" />
      </div>
   
      <div class="edit-assessment-buttons mb-6"> 
         <div @click="handleSaveChanges" class="on-green"><CheckIcon class="icon"/><p>Save Changes</p></div>
         <div @click="$emit('cancelEdition')"><XMarkIcon class="icon"/><p>cancel</p></div> 
      </div> 
   </div>
</template>
<script setup lang="ts">
import { useFormInizializationNewAsset } from '@/composables/useFormInizializationNewAsset';
import { AssetQuantitativePropertyInterface, AssetQuantitativePropertyUpdateMutationInput } from '@/models/assetAssessmentInterfaces';
import { DropdownSearchableOptionInterface } from '@/models/dropdownSearchableMultipleOptionInterface';
import { XMarkIcon, CheckIcon } from '@heroicons/vue/24/outline';
import { useQuery } from '@vue/apollo-composable';
import { defineProps, defineEmits, ref, computed} from 'vue';
import { useStore } from 'vuex';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { ASSET_QUANTITATIVE_PROPERTIES_FORM_VALUES } from '@/grapql/assetQuantitativeFormQueries';

const props = defineProps<{
   assetQuantitativeProperty: AssetQuantitativePropertyInterface;
}>();

const emit = defineEmits(['cancelEdition']);

const store = useStore();
const onEditorReady = (event: Event) => {return event};
const erroFromMutation = computed(() => store.state.assetDetailModule.errorsDuringMutation.assetProcessingTechniquesUpdate)

const { result } = useQuery(ASSET_QUANTITATIVE_PROPERTIES_FORM_VALUES);
const inizilizedData = computed(() => result?.value ?? null );
const formValueOptions = computed<{[key: string]: DropdownSearchableOptionInterface[]}>(() => useFormInizializationNewAsset(inizilizedData.value, ['unitTypes', 'valueTypes']));

const formSubmited = ref(false);
const valueError = computed(() => formSubmited.value && !value1.value ?  "Please assign a value to the property" : null);

const valueType = props.assetQuantitativeProperty.valueType ? ref(props.assetQuantitativeProperty.valueType) : ref<DropdownSearchableOptionInterface>(null);
const value1 = props.assetQuantitativeProperty.value1 ? ref(props.assetQuantitativeProperty.value1) : ref<string>(null);
const value2 = props.assetQuantitativeProperty.value2 ? ref(props.assetQuantitativeProperty.value2) : ref<string>(null);
const valueUnit = props.assetQuantitativeProperty.valueUnit ? ref(props.assetQuantitativeProperty.valueUnit.id) : ref<number>(null);
const extraInformation = props.assetQuantitativeProperty.extraInformation ? ref(props.assetQuantitativeProperty.extraInformation) : ref<string>(null);



const createMutationInput = () => {
   const quantitativePropertyInput = ref<AssetQuantitativePropertyUpdateMutationInput>({
      id: props.assetQuantitativeProperty.id,
      quantitativeProperty: props.assetQuantitativeProperty.quantitativeProperty.id,
      value1: value1.value,
      value2: value2.value,
      valueType: valueType.value ? valueType.value.id : null,
      valueUnit: valueUnit.value,
      extraInformation: extraInformation.value,
   });
   return quantitativePropertyInput;
}

const handleSaveChanges = async () => {
   formSubmited.value = true;
   if (!valueError.value) {
     const mutationInput = createMutationInput();
     await store.dispatch('assetDetailModule/assetQuantitativePropertyUpdate', mutationInput.value)
      .then(() => { 
         if (!erroFromMutation.value) {
            emit('cancelEdition');
         }
      })
   }
}

  
</script>
<style lang="scss" scoped>
   .edit-assessment-buttons {
      margin: 0;
      margin-bottom: 1rem;
   }
   label {
		font-size: 0.9rem;
		color: $font-color-super-light;
		font-weight: 300;
	}

	.value {
		font-size: 0.9rem;
		color: rgb(109, 109, 109);
		font-weight: 500;
		padding-bottom: 0.5rem;
		overflow: auto;
		text-transform: uppercase;
		overflow: visible;
	}
   .error-form-field label {
      font-size: 0.8rem;
      font-weight: 400;
      margin-top: 10px;
   }
</style>