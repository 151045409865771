import gql from "graphql-tag";


export const UNIVERSITY_CREATE = gql`
    mutation universityCreate($input: UniversityCreateMutationInput!) {
        universityCreate (input: $input) {
            university {
                id
                nickname
            }
        }
    }
` 

export const ORGANIZATION_CREATE = gql`
    mutation organizationCreate($input: OrganizationCreateMutationInput!) {
        organizationCreate (input: $input) {
            organization {
                id
                nickname
            }
        }
    }
` 

export const INDIVIDUAL_CREATE = gql`
    mutation individualCreate($input: IndividualCreateMutationInput!) {
        individualCreate (input: $input) {
            individual {
                id
                nickname
            }
        }
    }
` 
