import gql from 'graphql-tag';

export const ASSET_MATERIAL_FORM_VALUES = gql`
    query unitTypes {
  unitTypes(
    filter: {family: {iexact: "Length"}, or: {family: {iexact: ""}}}
    orderBy: "abbreviation"
  ) {
    edges {
      node {
        id
        abbreviation
        name
      }
    }
  }
}`
