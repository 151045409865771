import { computed } from 'vue';
import { isNullOrEmptyArray } from "@/utils/utils";

/**
 * Utility function to validate the form fields.
 * 
 * @param {Array<Object>} formFields - An array of field objects, each containing properties like `required` and `type`.
 * @param {Object} values - The object containing the form values.
 * @returns {Object} - Returns an object with `isFormValid` and `errorFields` computed properties.
 */
export function useFormValidation(formFields, values) {

    const errorFields = computed(() => {

        // Collect invalid mandatory fields
        const invalidMandatoryFields = formFields
            .filter(field => field.required)
            .filter(field => isNullOrEmptyArray(values[field.mutationValue]))
            .map(field => field.mutationValue); // Map to mutationValue;

        // Collect invalid range fields
        const invalidRangeFields = formFields
            .filter(field => field.type === 'range')
            .filter(field => {
                const rangeValues = values[field.mutationValue];
                if (rangeValues && rangeValues.length > 0) {
                    const [startValue, endValue] = rangeValues;
                    return startValue === null || endValue === null || startValue > endValue;
                }
                return false; // If no value provided, consider it valid
            })
            .map(field => field.mutationValue); // Map to mutationValue;

        // Combine the results into a single array
        return [...invalidMandatoryFields, ...invalidRangeFields];
    });

    const isFormValid = computed(() => errorFields.value.length === 0);

    return {
        isFormValid,
        errorFields,
    };
}