<template>
  <div class="flex flex-col"> 
    <div v-if="loading" class="loading-gif"></div>
    <template v-else-if="productBriefs">
      <div v-if="productBriefs.length == 0" class="empty-box add"></div>
      <div v-else class="grid grid-cols-4 gap-3">
        <template v-for="brief in productBriefs" :key="brief.id">
          <ProductBriefCard :product-brief="brief" />
        </template>
      </div>
    </template>

  </div>
</template>

<script setup lang="ts">
import ProductBriefCard from './ProductBriefCard.vue';
import { PRODUCT_BRIEFS_QUERY } from '@/grapql/productBriefsQuery';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import { ProductBriefInterface } from '@/models/productBriefsInterface';

const { result, loading } = useQuery(PRODUCT_BRIEFS_QUERY);
const queryResult = computed(() => result?.value ?? null);

const productBriefs = computed<ProductBriefInterface[]>(() => queryResult?.value && queryResult?.value?.productBriefs.edges.length > 0 ? queryResult.value.productBriefs.edges.map((edge: { node: ProductBriefInterface }) => edge.node) : []);

</script>