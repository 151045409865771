import gql from "graphql-tag";

export const CLIENT_QUERY = gql`
query clients {
    parties(filter: {businessTypes: {name: {exact: "Client"}}}) {
      edges {
        node {
          id
          nickname
        }
      }
    }
    countries {
      edges {
        node {
          id
          name
          code
        }
      }
    }
  }
`;