<template>
	<Disclosure v-slot="{ open }" :defaultOpen="true">
		<DisclosureButton class="flex w-full py-2 text-left text-sm font-medium  disclosure-button">
			<ChevronRightIcon :class="open ? 'chevron-rotate' : ''" class="h-5 w-5" />
			<p class="section-title">Quantitative Properties</p>
		</DisclosureButton>
		<DisclosurePanel class="pl-4">
			<div class="add-on-disclosure" >
				<PlusCircleIcon class="icon" @click="onAddition = true" />
			</div>
			<template v-if="!!quantitativePropertiesData.length">
				<div v-for="quantitativeProperty in quantitativePropertiesData" :key="quantitativeProperty.id"
					class="quantitative-info">
					<AssetQuantitativeProperty :asset-quantitative-property="quantitativeProperty" />
				</div>
			</template>
			<p v-else class="mb-4 text-sm italic text-neutral-400">
				No quantitative properties assigned to this technology yet
			</p>
			<AssetQuantitativePropertyAddForm v-if="onAddition" :quantitative-properties-data="quantitativePropertiesData"
				@cancel-addition="onAddition = false" />
		</DisclosurePanel>
	</Disclosure>
</template>
<script setup lang="ts">
import { AssetQuantitativePropertyInterface } from '@/models/assetAssessmentInterfaces';
import { defineProps, ref } from 'vue';
import { PlusCircleIcon } from '@heroicons/vue/24/outline';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import AssetQuantitativeProperty from './AssetQuantitativeProperty.vue';
import AssetQuantitativePropertyAddForm from './AssetQuantitativePropertyAddForm.vue';


defineProps<{
	quantitativePropertiesData: AssetQuantitativePropertyInterface[];
}>()

const onAddition = ref(false);


</script>