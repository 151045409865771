import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "program-detail-card mt-4 mr-4" }
const _hoisted_2 = { class: "flex justify-between items-center mb-3" }
const _hoisted_3 = { class: "layer-design" }
const _hoisted_4 = { class: "desc-column" }
const _hoisted_5 = { class: "text-column" }
const _hoisted_6 = { class: "name" }
const _hoisted_7 = { class: "grammage" }

import { PropType, ref } from 'vue';
import ProgramPrototypeDesignModal from './ProgramPrototypeDesignModal.vue';
import { layersInfoInterface, programTargetInterface, prototypesDesignsInfoInterface } from '@/models/programsInterface';
import LayersDesignGenerator from './LayersDesignGenerator.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProgramLayersDesign',
  props: {
    layersInfo: { type: Object as PropType<layersInfoInterface>},
    programTargets:  { type: Array as PropType<programTargetInterface[]>},
    alternativeDesigns: { type: Array as PropType<prototypesDesignsInfoInterface[]>},
},
  setup(__props) {



const isOpen = ref(false);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[2] || (_cache[2] = _createElementVNode("p", { class: "cards-title" }, "system design", -1)),
        (__props.alternativeDesigns.length > 1)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (isOpen.value = !isOpen.value)),
              class: "button"
            }, "generate alternatives"))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.layersInfo.layers, (layer) => {
            return (_openBlock(), _createElementBlock("div", {
              key: layer.id,
              class: "layers-desc"
            }, _toDisplayString(layer.description), 1))
          }), 128))
        ]),
        _createVNode(LayersDesignGenerator, {
          "is-mockup": true,
          layersData: __props.alternativeDesigns[0].layersData,
          class: "layers-image"
        }, null, 8, ["layersData"]),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.layersInfo.layers, (layer) => {
            return (_openBlock(), _createElementBlock("div", {
              key: layer.id,
              class: "layers-info"
            }, [
              _createElementVNode("p", _hoisted_6, _toDisplayString(layer.name), 1),
              _createElementVNode("p", _hoisted_7, _toDisplayString(layer.grammage), 1)
            ]))
          }), 128))
        ])
      ])
    ]),
    _createVNode(ProgramPrototypeDesignModal, {
      isOpen: isOpen.value,
      "alternative-designs": __props.alternativeDesigns,
      targets: __props.programTargets,
      onCloseModal: _cache[1] || (_cache[1] = ($event: any) => (isOpen.value = false))
    }, null, 8, ["isOpen", "alternative-designs", "targets"])
  ], 64))
}
}

})