export function useAssetFilesToPdfFilesOnly(files) {
  try {
      if(Array.isArray(files) && files !=null){
        const pdfs = files.filter(item=>{
            const fileName = item.node.file.name;
            return fileName.endsWith('.pdf')
        });
        
        return pdfs.map(item=>{
          const filename = item.node.file.name.split('/').pop();
          return {
            id: item.node.id,
            filename: filename
          }
        });
      }

      return [];
  } catch {
    (error) => console.log(error);
  }
  return [];
}
