<template>
    <div>
        <div class="design-feedback">
             <template v-for="(feed, index) in allFeedbacks" :key="index">
                <div class="feedback-cloud" v-if="feed.designId == prototypeDesignId && feed.programId == programId">
                    <div class="initials-icon">{{ feed.who }}</div>
                   <p>{{ feed.feedback }}</p> 
                </div>
            </template>
        </div>
    </div>
</template>
<script setup lang="ts">
import { designFeedbackInterface } from '@/models/programsInterface';
import {PropType, computed, defineProps } from 'vue'; 
import { useRoute } from 'vue-router';

defineProps({
    prototypeDesignId: Number,
    allFeedbacks: {type: Array as PropType<designFeedbackInterface[]>},
})

const route = useRoute();
const programId = computed(() => route.params.id)

</script>
<style lang="scss" scoped>
    .design-feedback {
        margin-top: 1rem;
        width: 300px;
        display: flex;
        flex-direction: column;
        .feedback-cloud {
            display: flex;
            align-items: flex-start;
            margin-bottom: 0.5rem;
            .initials-icon {
                width: 2.5rem;
                height: 2.5rem;
                line-height: 16px;
            }
            p {
                font-size: 0.85rem;
                font-weight: 300;
                background: #eee;
                padding: 0.2rem 0.5rem;
                border-radius: 0.5rem;
            }
        }
    }
</style>