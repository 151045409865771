export const RESEARCH_PAPER_AUTOFILL_FIELDS = {
    SEARCH: {
        graphqlValue: 'search',
        formFields: [
            {label:'Search Field', type: 'select', mutationValue: 'searchField', required: true }, 
            {label:'Search Value', type: 'text', mutationValue: 'searchValue', required: true },  
        ],
        mandatoryFormFields: ['searchField','searchValue'],
        fieldsToQuery: [],
        searchQuery: 'researchPaperAutofillModule/searchResearchPapers'
    },
    EXTRACT:{
        graphqlValue: 'extract',
        formFields: [
            {label:'Selected Research Paper', type: 'hidden', mutationValue: 'uid', required: false }, 
            {label:'Pdf File', type: 'file', mutationValue: 'pdf', required: false },
        ],
        mandatoryFormFields: [],
        fieldsToQuery: [],
        extractQuery: 'researchPaperAutofillModule/extractResearchPaperInfo'
    }
}




