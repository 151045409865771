import { createStore } from "vuex";
import assetDetailEditModule from "./modules/assetDetailEditModule";
import assetDetailModule from "./modules/assetDetailModule";
import assetCreationModule from "./modules/assetCreationModule";
import authModule from './modules/authModule';
import partyModule from "./modules/partyModule";
import assetsModule from "./modules/assetsModule";
import { teaModules } from "@/tea/store";
import programsCreationModule from "./modules/programsCreationModule";
import researchPaperAutofillModule from "./modules/researchPaperAutofillModule";
import programVersionDetailModule from "./modules/programVersionDetailModule";
import productBriefsModule from "./modules/productBriefsModule";
import assetTdsModule from "./modules/assetTdsModule";
import aiModule from "./modules/aiModule";

const store = createStore({
  modules: {
    authModule,
    assetsModule,
    assetCreationModule,
    assetDetailEditModule,
    assetDetailModule,
    partyModule,
    programsCreationModule,
    programVersionDetailModule,
    productBriefsModule,
    researchPaperAutofillModule,
    assetTdsModule,
    aiModule,
    ...teaModules,
  },
});

export default store;
