<template>
  <div>
    <p class="text-red-500 text-center">{{ errorMessage }}</p>
    <div class="flex items-center justify-center my-6">
      <p class="text-blue-500 text-center">
        An email confirmation link is sent to {{ email }}.
      </p>
    </div>

    <div v-if="!resendEnabled">
      <p class="text-center text-gray-500" v-if="timer > 0">
        Haven't recieved? Resend in {{ timer }} seconds
      </p>
    </div>
    <div v-else>
      <div class="mb-4">
        <label
          class="block text-gray-700 text-sm font-bold mb-2"
          for="password"
        >
          Enter your password
        </label>
        <input
          v-model="password"
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="password"
          type="password"
          required
        />
      </div>
      <div class="mb-4">
        <button
          @click="resendVerificationEmail"
          class="w-full bg-emerald-400 text-white font-bold py-2 px-4 rounded hover:bg-emerald-600 focus:outline-none focus:shadow-outline"
          :disabled="!password"
        >
          Resend Confirmation Email
        </button>
      </div>
    </div>

    <hr class="my-4" />
    <div class="text-right">
      Already confirmed your email? &nbsp;
      <button
        @click.prevent="$emit('showSignIn')"
        class="text-emerald-400 hover:underline cursor-pointer"
      >
        Sign In
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, getCurrentInstance, defineProps, onMounted } from "vue";
import { useStore } from "vuex";
const { emit } = getCurrentInstance();
const store = useStore();

let timerDuration = 60; // in seconds
const timer = ref(timerDuration);
const resendEnabled = ref(false);

const password = ref("");
const errorMessage = ref("");

const countdown = () => {
  if (timer.value > 0) {
    timer.value--;
  } else {
    resendEnabled.value = true;
  }
};

const resendVerificationEmail = async () => {
  try {
    await store
      .dispatch("authModule/reVerify", {
        email: props.email,
        password: password.value,
      })
      .then((value) => {
        // Reset timer and disable button again
        timerDuration = timerDuration * 2;
        timer.value = timerDuration;
        resendEnabled.value = false;
        errorMessage.value = "";
        password.value = "";
      })
      .catch((error) => {
        errorMessage.value = error;
      });
  } catch (error) {
    errorMessage.value = "An error occurred while resending.";
  }
};

onMounted(() => {
  const intervalId = setInterval(countdown, 1000);

  // Cleanup the interval on component unmount
  return () => clearInterval(intervalId);
});

const props = defineProps({
  email: String,
});
</script>

<style scoped>
/* Add your custom styling here */
</style>
