import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-5 pb-20 items-center text-center" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "w-full px-3 py-1" }

import { ArrowPathIcon } from '@heroicons/vue/24/outline';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'AIEnd',
  setup(__props) {

const store = useStore();

const handleReset = ()=>{
    store.commit("aiModule/resetStore");
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("p", null, "End of Response", -1)),
        _createVNode(_unref(ArrowPathIcon), {
          title: "Restart",
          onClick: handleReset,
          class: "icon w-9 h-9 cursor-pointer hover:bg-gray-200 active:shadow-inner rounded inline-block px-2 m-2 hover:stroke-black"
        })
      ])
    ])
  ]))
}
}

})