<template>
    <ModalSlots
      :show="showPlott"
      @close-modal="closeModal"

      dialogClass="mx-auto max-w-[80%] min-w-[80%]"
      :hideFooter="true"
    >
      <template v-slot:header><p>RUNS PLOTTING</p></template>
      <template v-slot:body>
        <!-- <LoaderPage v-if="showFilesUploadLoader" loader-text="Creating Asset" gif-position="center"/> -->
        <div class="forms-container">
            <div class="grid grid-cols-4 gap-y-3 gap-x-4 mt-5">
                <CapexAndMecChart :data="capexAndMecData" class="lg:col-span-4 md:col-span-4 sm:col-span-4 border" />
                <OpexChart :data="OpexData" class="lg:col-span-4 md:col-span-4 sm:col-span-4 border" />
                <SellingPriceChart :data="SellData" class="lg:col-span-4 md:col-span-4 sm:col-span-4 border" />
            </div>
        </div>
      </template>
    </ModalSlots>
</template>

<script setup>
import {ref, defineProps,computed,defineEmits} from "vue"
import { useStore } from "vuex";
import ModalSlots from "@/components/ModalSlots.vue";
import CapexAndMecChart from "@/tea/components/runs/charts/CapexAndMecChart.vue";
import OpexChart from "@/tea/components/runs/charts/OpexChart.vue";
import SellingPriceChart from "@/tea/components/runs/charts/SellingPriceChart.vue";

const props = defineProps(["selectedIds","showPlott"]);
const emits = defineEmits(["onCreated","closePlott"]);
const store = useStore();
const isOpen = ref(props.showPlott);
const collapsed = computed(() => store.getters["authModule/isSidebarCollapsed"]);
const toggleSidebar = () => store.commit("authModule/toogleSidebar");

const closeModal = () => {
    emits("closePlott")
};

const openModal = () => {
  if (!collapsed.value) {
    toggleSidebar();
  }
  isOpen.value = true;
};

const repo =computed(()=> store.getters["runModule/repo"] || []);

const capexAndMecData = computed(()=>{
    const data = repo.value.runs.filter(obj=>props.selectedIds.includes(obj.id)).map(obj=>({id:obj.id,name:obj.id+":"+obj.species,CapEx: obj.finalCapex,MEC:obj.finalMec}))
    const result = data.reduce((acc, item) => {
        // Add the cat to the "categories" array
        acc.categories.push(item.name);
        
        // Loop through the keys "Capex" and "opex"
        ['CapEx', 'MEC'].forEach(key => {
            const existingSeries = acc.series.find(s => s.name === key);
            
            // If the series exists, push the data
            if (existingSeries) {
            existingSeries.data.push(item[key]);
            } else {
            // Otherwise, create a new series
            acc.series.push({
                name: key,
                data: [item[key]],
            });
            }
        });

        return acc;
        }, {
        categories: [],
        series: [],
        });
    return result;
});

const OpexData = computed(()=>{
    const data = repo.value.runs.filter(obj=>props.selectedIds.includes(obj.id))

    const result  = data.map(obj => ({
        id: obj.id,
        name: obj.id+":"+obj.species, 
        y: obj.finalOpex, 
        }));
    return result;
});
const SellData = computed(()=>{
    const data = repo.value.runs.filter(obj=>props.selectedIds.includes(obj.id))
    const result  = data.map(obj => ({
        id: obj.id,
        name: obj.id+":"+obj.species, 
        y: obj.targetCompoundPrice, 
        year: obj.npvYear, 
        }));
    return result;
});



</script>