import gql from "graphql-tag";

export const NEW_PROGRAM_VERSIONS = gql`
query getNewProgramVersions  {
  programs {
    edges {
      node {
        id
        name
      }
    }
  }
  teamRoleTypes {
      edges {
        node {
          id
          name
          category
        }
      }
    }
  employees(filter: {isActive: {exact: true}}) {
      edges {
        node {
          id
          fullName
        }
      }
    }
    applicationTypes {
      edges {
        node {
          id
          name
          description
        }
      }
    }
}
`;